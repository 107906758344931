import React, { Component } from "react";
import { Draggable } from "react-beautiful-dnd";

type Props = { rowData: Object, idx: number };
type State = {};
const CardOrderingWrapper = (CardComponent: any) => {
  class WrappedComponent extends Component<Props, State> {
    render() {
      const {
        rowData: { id },
        idx,
      } = this.props;
      let draggableId = id;
      // This check to handle offline items (on create mode where id prefixed with n e.g: n1,n2,n3).
      if (draggableId) {
        if (Number.isNaN(parseInt(draggableId, 10))) {
          draggableId = parseInt(draggableId.substring(1), 10);
        }
      }
      return (
        // eslint-disable-next-line
        <Draggable draggableId={`droppable-${draggableId}`} index={idx}>
          {draggableProvided => (
            <div
              ref={draggableProvided.innerRef}
              {...draggableProvided.draggableProps}
              {...draggableProvided.dragHandleProps}
            >
              <CardComponent {...this.props} />
            </div>
          )}
        </Draggable>
      );
    }
  }
  return WrappedComponent;
};

export default CardOrderingWrapper;
