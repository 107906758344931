import styled from "styled-components";
import { COLOURS } from "@swan/themer";

const RelatedObjectWrapper = styled.div({
  margin: ".4rem 0",
  padding: ".4rem",
  border: `1px solid ${COLOURS.UI_02}`,
  boxShadow: `0 1px 1px 0 ${COLOURS.UI_02}`,
  borderRadius: ".2rem",
});

const TitleRow = styled("div")`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  .title {
    flex: 1;
  }
  .control-buttons {
  }

  &.no-title {
    justify-content: flex-end;
  }
`;

const CardFormRow = styled("div")`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  .controls {
  }

  .default-card-form {
    flex: 1;
    margin-bottom: 0;
  }
`;

export { TitleRow, RelatedObjectWrapper, CardFormRow };
