import React from "react";
import withAuth from "./withAuth";

/**
 * Wraps a component and waits trigger preloading permissions specified but will not wait for them to render
 *
 * @param {Array<string>} permissions Array of permission names
 */
const preloadPermissions = (preload: {
  groups?: Array<string>,
  permissions?: Array<string>,
}) => (Component: any) => {
  class WrappedComponent extends React.Component<any> {
    componentDidMount() {
      const { auth } = this.props;
      const { permissions, groups } = preload;
      if (permissions) {
        auth.getPermissions(permissions);
      }
      if (groups) {
        if (groups.forEach) {
          groups.forEach(grp => auth.getPermissionsByGroup(grp));
        } else {
          auth.getPermissionsByGroup(groups);
        }
      }
    }

    render() {
      return <Component {...this.props} />;
    }
  }

  return withAuth(WrappedComponent);
};

export default preloadPermissions;
