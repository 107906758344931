import React, { useEffect, useState } from "react";
import { withAuth } from "@swan/auth";
import { connect } from "react-redux";
import { userActions } from "../../../state/reducers/user";
import { Wrapper, NavigationContainer, ProfileContainer } from "./Styled";
import withUserData from "../withUserData";

const getUserOrganizations = (
  activeOrganization,
  userOrganizations,
  switchOrganizationCallBack
): Array<Object> => {
  return (userOrganizations || [])
    .map(({ name, id, code }) => ({
      _name: name,
      code,
      active: id === (activeOrganization || {}).id,
      name: `${code || ""}${code ? "-" : ""}${name}`,
      onClick: () => {
        switchOrganizationCallBack(id, name);
      },
    }))
    .sort((a, b) => {
      if (a.name < b.name) return -1;
      if (a.name > b.name) return 1;
      return 0;
    });
};

type Props = {
  activeOrganization: Object,
  userOrganizations: Array<any>,
  switchOrganization: Function,
  itemRenderer: any,
};

const UserOrganizationMenu = (props: Props) => {
  const {
    activeOrganization,
    userOrganizations: propsUserOrganizations,
    switchOrganization: switchOrganizationCallBack,
    itemRenderer: ItemRenderer,
  } = props;
  const [userOrganizations, setUserOrganizations] = useState([]);

  const switchOrganization = (id, name) => {
    switchOrganizationCallBack(id, name);
  };

  useEffect(
    () => {
      setUserOrganizations(
        getUserOrganizations(
          activeOrganization,
          propsUserOrganizations,
          switchOrganization
        )
      );
    },
    [activeOrganization, propsUserOrganizations]
  );

  return (
    <Wrapper>
      <ProfileContainer>
        <h4 className="product-nav-title">User Organizations</h4>
      </ProfileContainer>
      <NavigationContainer>
        {userOrganizations.map((item: Object) => (
          <ItemRenderer {...item} />
        ))}
      </NavigationContainer>
    </Wrapper>
  );
};

function mapStateToProps({ user }) {
  const activeOrganization =
    ((user || {}).organizations || []).find(
      org => org.id === user.activeOrganization
    ) || {};
  return {
    activeOrganization,
    userOrganizations: user.organizations,
  };
}

export default withUserData(
  withAuth(
    connect(
      mapStateToProps,
      {
        switchOrganization: userActions.switchOrganization,
      }
    )(UserOrganizationMenu)
  )
);
