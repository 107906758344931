import * as React from "react";
import withAuth from "./withAuth";
import { type AuthContextShape } from "../types";

type State = {
  isAllowed: ?boolean,
};

type Props = {
  auth: AuthContextShape,
  Permission: string | Array<string>,
  MatchAll?: boolean,
  Component?: any,
  render?: Function,
  children?: React.Node,
  fallback?: any,
  onReject?: Function,
};

class AuthorizedComponent extends React.Component<Props, State> {
  static defaultProps = {
    MatchAll: true,
    Component: undefined,
    render: undefined,
    fallback: undefined,
    children: null,
    onReject: () => {},
  };

  state = {
    isAllowed: null,
  };

  componentDidMount() {
    this.checkPermission();
  }

  checkPermission() {
    const { auth, Permission, MatchAll } = this.props;
    if (!Permission) {
      this.setState({
        isAllowed: true,
      });
      return;
    }
    new Promise(resolve => {
      if (typeof Permission === "string") {
        return resolve(auth.isAllowed(Permission));
      }
      if (MatchAll) {
        return resolve(auth.isAllowedAll(Permission));
      }
      return resolve(auth.isAllowedAny(Permission));
    }).then(isAllowed => this.setState({ isAllowed }));
  }

  render() {
    const { isAllowed } = this.state;
    const {
      Component,
      render,
      Permission,
      MatchAll,
      fallback,
      onReject,
      ...restProps
    } = this.props;
    if (isAllowed === null) {
      return null;
    }
    if (isAllowed === false) {
      if (onReject) {
        onReject();
      }
      if (fallback) {
        return fallback;
      }
      return null;
    }
    if (Component) {
      return <Component {...restProps} render={render} />;
    }
    if (render) {
      return render(restProps);
    }
    const { children } = this.props;
    return children || null;
  }
}

export default withAuth(AuthorizedComponent);
