export default {
  uid: "email",
  attributeId: "",
  formId: "",
  pencilDef: {
    title: "Email",
    group: "FIELD",
    handleIcon: "FaEnvelope",
    styles: {},
  },
  options: {
    title: "Email Untitled",
    type: "email",
    required: false,
    limits: {
      min: 2,
      max: 100,
    },
    domainFilter: {
      type: false, // false | WHITE | BLACK
      domains: [],
    },
    placeholder: "name@mazruiinternational.ae",
  },
};
