import gender from "./gender";
import martialStatus from "./maritalStatus";
import religion from "./religion";
import title from "./title";
import bloodType from "./bloodType";
import country from "./country";
import currency from "./currency";

export default [
  {
    name: "Gender",
    value: "GENDER",
    data: gender,
  },
  {
    name: "Marital Status",
    value: "MARITAL_STATUS",
    data: martialStatus,
  },
  {
    name: "Religion",
    value: "RELIGION",
    data: religion,
  },
  {
    name: "Title",
    value: "TITLE",
    data: title,
  },
  {
    name: "Blood Type",
    value: "BLOOD_TYPE",
    data: bloodType,
  },
  {
    name: "Country",
    value: "COUNTRY",
    data: country,
  },
  {
    name: "Currency",
    value: "CURRENCY",
    data: currency,
  },
];
