import * as React from "react";

import styled, { withTheme } from "styled-components";
import { Button } from "@swan/themer";
import { Collapse } from "reactstrap";
import { FaCaretDown, FaCaretUp } from "react-icons/fa";

import ElementsList from "./ElementsList";
import { type ElementProps } from "./types";

type ColProps = ElementProps & {
  colCount: number,
};

export const ContainerWithTitleWrapper = styled.div(({ theme }) =>
  Object.assign(
    {},
    theme.ContainerWrapper ? { ...theme.ContainerWrapper } : {},
    { padding: "0.5rem" }
    // colCount ? { flexBasis: `${100 / colCount}%` } : {}
    // { minHeight: "300px" }
  )
);

// export const ContainerTitle = styled.div(({ theme }) =>
//   Object.assign({}, theme.ContainerTitle ? { ...theme.ContainerTitle } : {})
// );

export const ContainerTitle = styled.div`
  margin: 0px;
  padding: 0px;
  font-size: 0.875rem;
  font-weight: bold;
  ${(props: any) =>
    !props.isSectionTitleHidden
      ? `
    border-bottom: 2px solid #767676;
    padding-bottom: 10px;
    padding-top: 11px;
  `
      : ""}
`;

const ContainerElementWrapper = styled.div``;

type State = {
  isOpen: boolean,
};

class ContainerWithTitle extends React.Component<ColProps, State> {
  constructor(props: ColProps) {
    super(props);
    const {
      schema: { options },
    } = this.props;
    this.state = {
      isOpen: !!(options || {}).defaultToggleOpen,
    };
  }

  toggle = () => {
    this.setState(prevState => ({
      isOpen: !prevState.isOpen,
    }));
  };

  render() {
    const { schema, id } = this.props;
    const { isOpen } = this.state;
    const isToggleEnabled = (schema.options || {}).toggleSection || false;
    const isSectionTitleHidden =
      (schema.options || {}).hideSectionTitle || false;

    let classSuffix = "open";
    if (isToggleEnabled) {
      classSuffix = isOpen ? "open" : "closed";
    }
    return (
      <ContainerWithTitleWrapper
        className={`container-with-title cwt--wrapper cwt--wrapper-with-${classSuffix}`}
      >
        <span className="cwt--title-filler" />
        <ContainerTitle
          isSectionTitleHidden={isSectionTitleHidden}
          className={`cwt--title cwt--title-with-${classSuffix}`}
        >
          <span className="cwt--title-filler" />
          {!isSectionTitleHidden ? schema.title : null}
          <>
            {isToggleEnabled && (
              <span className="float-right cwt-toggler">
                <div className="cwt--toggle-button">
                  {` `}
                  <Button
                    outline
                    size="sm"
                    onClick={this.toggle}
                    style={{
                      fontSize: ".875rem",
                      paddingTop: "0.1rem",
                      paddingBottom: "0.1rem",
                    }}
                  >
                    {isOpen && <FaCaretUp />}
                    {!isOpen && <FaCaretDown />}
                  </Button>
                </div>
              </span>
            )}
          </>
        </ContainerTitle>
        {isToggleEnabled ? (
          <Collapse
            isOpen={isOpen}
            className={`cwt--content cwt--content-with-${classSuffix}`}
          >
            <ContainerElementWrapper className="cwt--content-element cwt--content-collapsable">
              <ElementsList id={id} schema={schema} index={0} />
            </ContainerElementWrapper>
          </Collapse>
        ) : (
          <ContainerElementWrapper className="cwt--content-element">
            <ElementsList id={id} schema={schema} index={0} />
          </ContainerElementWrapper>
        )}
      </ContainerWithTitleWrapper>
    );
  }
}

export default withTheme(ContainerWithTitle);
