import React, { Component } from "react";
import Modal from "../Modal";
import FileUpload from "./index";

type Props = {
  data: Object,
  onChange: Function,
  uploadService: Object,
  filters: Array<Object>,
  fileUploadFieldName: string,
  fileUploadFieldLabel: string,
  uploadableRelationshipName: string,
};

type State = {
  files: Array<Object>,
  showPreview: boolean,
  showPrompt: boolean,
  nowPreviewing: string,
  filesToDelete: Array<Object>,
};

const imageTypes = ["jpg", "jpeg", "exif", "tiff", "gif", "bmp", "png", "webp"];

class UploaderCustomComponent extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      files: [],
      showPreview: false,
      nowPreviewing: "",
      showPrompt: false,
      filesToDelete: [],
    };
  }

  componentDidMount() {
    const { data, uploadService, filters } = this.props;
    if (data.id) {
      // get the attachments form database
      uploadService.find(1, filters).then(({ data: filenames }) => {
        const paths = {};
        filenames.forEach(({ filename, id }) => {
          Object.assign(paths, { [filename]: id });
        });
        // get files from paths
        const p = Object.keys(paths);
        if (p.length) {
          uploadService.get("get", { paths: p }).then(({ data: _files }) => {
            const files = _files.map(f => ({
              ...{ id: paths[f.key] },
              ...f,
            }));
            // store it in state as files
            this.setState({
              files: files || [],
            });
          });
        }
      });
    }
  }

  afterSave = (uploadedFiles: Array<Object>) => {
    const { uploadableRelationshipName } = this.props;
    const files = [];
    const filesData = [];

    // @todo this order should be inverse, if autoUpload is disabled
    // 1. uploading first
    uploadedFiles.forEach(({ url, key, size }) => {
      if (url && key) {
        files.push({ url, key, size });
        filesData.push({ filename: key });
      }
    });

    // 2. add to relation second
    this.setState(
      prevFiles => ({
        files: [...prevFiles.files, ...files],
      }),
      () => {
        const { onChange, data } = this.props;
        if (onChange && onChange instanceof Function) {
          onChange(uploadableRelationshipName, [
            ...(data.Uploads || []),
            ...filesData,
          ]);
        }
      }
    );
  };

  onDelete = (filesToDelete: Array<Object>) => {
    const { uploadService, uploadableRelationshipName } = this.props;
    // original files state
    const { files } = this.state;
    // take a copy, to splice
    const newerFiles = [...files];

    // ids and keys to remove
    const keysToDelete = []; // local state
    const idsToDelete = []; // from database
    filesToDelete.forEach(f => {
      if (f.key) keysToDelete.push(f.key);
      if (f.id) idsToDelete.push(f.id);
    });

    // loop through original files
    // find index to remove
    const idx = [];
    for (let i = 0; i < files.length; i += 1) {
      if (idx.length === keysToDelete.length) break;
      if (keysToDelete.includes(files[i].key)) {
        idx.push(i);
      }
    }
    // loop through found indexes
    // remove the items
    idx.forEach(i => {
      newerFiles.splice(i, 1);
    });

    this.setState(
      {
        files: newerFiles,
      },
      () => {
        const { onChange } = this.props;
        // delete from storage
        uploadService.post("delete", { paths: keysToDelete });
        // get new attachments
        // if attachment has no database record
        const newAttachments = [];
        newerFiles.forEach(nf => {
          if (nf.key && !nf.id) {
            newAttachments.push({ filename: nf.key });
          }
        });
        // then, set it to relation
        if (newAttachments.length && onChange && onChange instanceof Function) {
          onChange(uploadableRelationshipName, newAttachments);
        }

        // finally remove the database record
        idsToDelete.forEach(i => {
          uploadService.deleteRequest(i).then(() => {});
        });

        this.togglePrompt();
      }
    );
  };

  getFileExtension = (fileUrl: string) => {
    if (typeof fileUrl === "string" && window.URL) {
      const url = new URL(fileUrl);
      return url.pathname
        .split("/")
        .reverse()[0]
        .split(".")[1];
    }
    return "";
  };

  onPreview = (files: Array<Object>) => {
    let nowPreviewing = "";

    files.forEach(file => {
      if (file.url) {
        const ext = this.getFileExtension(file.url);
        if (ext && imageTypes.indexOf(ext.toLowerCase()) !== -1) {
          nowPreviewing += `<img src=${file.url} />`;
        } else {
          nowPreviewing += `<a href=${
            file.url
          } target="_blank">Preview not available. Please click here to view or download the file</a>`;
        }
      }
    });
    this.setState({
      showPreview: true,
      nowPreviewing,
    });
  };

  toggleModal = () => {
    this.setState({
      showPreview: false,
    });
  };

  promptDelete = (files: Array<Object>) => {
    this.setState({ showPrompt: true, filesToDelete: files });
  };

  togglePrompt = () => {
    this.setState({
      showPrompt: false,
      filesToDelete: [],
    });
  };

  render() {
    const {
      fileUploadFieldName,
      fileUploadFieldLabel,
      uploadService,
      data,
    } = this.props;
    const {
      files,
      showPreview,
      nowPreviewing,
      showPrompt,
      filesToDelete,
    } = this.state;
    return (
      <>
        <FileUpload
          name={fileUploadFieldName}
          label={fileUploadFieldLabel}
          autoUpload
          disabled={data.is_locked}
          disableItemDeletion={data.is_locked}
          multiple
          onAfterSave={this.afterSave}
          service={{ service: uploadService, path: "upload" }}
          files={files}
          showPreview
          onPreview={
            this.onPreview // as stateless component
          }
          onDelete={this.promptDelete}
          hideClear
        />

        <Modal isOpen={showPreview} toggle={this.toggleModal} enableBackdrop>
          <div
            className="image-preview"
            // eslint-disable-next-line
            dangerouslySetInnerHTML={{ __html: nowPreviewing }}
          />
          <style
            // eslint-disable-next-line
            dangerouslySetInnerHTML={{
              __html: `.image-preview > img { width: 100%; }`,
            }}
          />
        </Modal>

        <Modal
          title="Are you sure?"
          isOpen={showPrompt}
          toggle={this.togglePrompt}
          buttons={[
            {
              label: "Delete",
              color: "danger",
              onClick: () => {
                this.onDelete(filesToDelete);
              },
            },
            { label: "Cancel", onClick: this.togglePrompt },
          ]}
          enableBackdrop
        >
          Do you want to delete?
        </Modal>
      </>
    );
  }
}

export default UploaderCustomComponent;
