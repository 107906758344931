import React from "react";
import { connect } from "react-redux";

import { withSwanContext } from "@swan/state";
import workflowActions from "../state/actions";
import { withProvider as withStoreProvider } from "../utils/storeProvider";
import { persistor, store } from "../state/store";

type Props = {
  object: string,
  record: Object,
  workflowId: string,
  overrideWorkflowId: string, // this is temporary until enable forms for different organizations.
  SwanContext: any, // eslint-disable-line
  stats: any, // eslint-disable-line
  loadWorkflowStats: (object: string, id: number, workflowId: string) => void,
};

type State = {
  summary: Array<Object>,
};

class WorkflowStatusSummary extends React.PureComponent<Props, State> {
  static getDerivedStateFromProps(props: Props) {
    const {
      SwanContext: { user },
      stats,
    } = props;
    if (stats && user) {
      const summary = WorkflowStatusSummary.generateSummary(stats, user);
      return {
        summary,
      };
    }
    return {};
  }

  /**
   * Generates the summary from the status of the workflow.
   * The summary is an array of strings
   */
  static generateSummary(
    status: {
      currentSteps?: Array<Object>,
      workflow: Object,
    },
    user
  ): Array<Object> {
    if (!status.currentSteps) {
      return [];
    }
    const summary = [];
    status.currentSteps.forEach(step => {
      const s = {};
      if (step.type === "state") {
        s.text = `Pending: ${step.label}`;
        s.description = step.description || "";
      }
      if (step.approval && step.approval.pending) {
        step.approval.pending.forEach(pendingApproval => {
          if (pendingApproval.user_id === user.id) {
            s.text = `Pending your approval`;
          } else {
            s.text = `Pending approval from ${pendingApproval.name}`;
          }
        });
      }
      if (step.substatus) {
        s.substatus = WorkflowStatusSummary.generateSummary(
          step.substatus,
          user
        );
        s.text = `${step.substatus.workflow.name}:`;
      }
      if (Object.keys(s).length) {
        summary.push(s);
      }
    });
    return summary;
  }

  state = {
    summary: [],
  };

  componentDidMount() {
    this.load();
    this.mounted = true;
    const key = this.getWorkflowKey();
    if (key) {
      window.addEventListener(`${key}::refresh`, this.load);
    }
  }

  componentWillUnmount() {
    this.mounted = false;
    const key = this.getWorkflowKey();
    if (key) {
      window.removeEventListener(`${key}::refresh`, this.load);
    }
  }

  getWorkflowKey() {
    const {
      record: { id },
      workflowId,
    } = this.props;
    if (id) {
      return `workflow:${workflowId}:${id}`;
    }
    return null;
  }

  load = () => {
    const {
      object,
      record: { id },
      workflowId,
      overrideWorkflowId,
      loadWorkflowStats,
    } = this.props;
    if (id) {
      loadWorkflowStats(object, id, overrideWorkflowId || workflowId);
    }
  };

  mounted: boolean;

  renderListOfSteps(steps: Array<Object>, isSub: boolean = false) {
    return (
      <ul className="mb-1">
        {steps.map(s => (
          <li key={s.text}>
            {isSub && <span>&bull;</span>}
            {s.text}
            <div className="ml-2">
              {s.substatus && this.renderListOfSteps(s.substatus, true)}
            </div>
          </li>
        ))}
      </ul>
    );
  }

  render() {
    const { summary } = this.state;
    return <div className="mb-3">{this.renderListOfSteps(summary)}</div>;
  }
}

const mapStateToProps = (state, ownProps) => {
  const {
    object,
    record: { id },
  } = ownProps;
  const {
    workflow: { workflowObjects },
  } = state;
  return {
    stats:
      (workflowObjects &&
        workflowObjects[object] &&
        workflowObjects[object][id] &&
        workflowObjects[object][id].stats) ||
      [],
  };
};

const mapDispatchToProps = dispatch => ({
  loadWorkflowStats: (...defs) =>
    dispatch(workflowActions.loadWorkflowStats(...defs)),
});

export default withStoreProvider(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withSwanContext(WorkflowStatusSummary)),
  {
    store,
    persistor,
  }
);
