import React, { useState } from "react";
import { FaPlayCircle } from "react-icons/fa";

// $FlowFixMe
import ReactPlayer from "react-player";
import Modal from "./Modal";
import Button from "./Button";

const ModalVideo = ({ videoURL }: { videoURL: string }) => {
  const [isModelOpen, setIsModalOpen] = useState(false);
  const toggleModal = () => {
    setIsModalOpen(!isModelOpen);
  };
  return (
    <>
      <Button
        hasHighlight={false}
        outline={false}
        style={{ background: "none", border: "none" }}
        onClick={toggleModal}
      >
        <FaPlayCircle size="3em" />
      </Button>
      <Modal isOpen={isModelOpen} toggle={toggleModal} enableBackdrop>
        <ReactPlayer
          playing
          controls
          url={videoURL}
          width="100%"
          height="100%"
        />
      </Modal>
    </>
  );
};

export default ModalVideo;
