import * as React from "react";

import styled, { withTheme } from "styled-components";

import ElementsList from "./ElementsList";

// utils
import canDisplayElement from "../../utils/canDisplayElement";

import { type ElementProps } from "./types";

type ColProps = ElementProps & {
  colCount: number,
  formProps?: Object,
};

export const ColWrapper = styled(styled("div")`
  ${(props: any) => (props || {}).styles}
`)(({ theme }) =>
  Object.assign({}, theme.ColWrapper ? { ...theme.ColWrapper } : {})
);

const Col = (props: ColProps) => {
  const { schema, id, colCount, formProps } = props;
  const { options } = schema;

  if (canDisplayElement(options, formProps) === false) return null;

  let span;
  if ((options || {}).columnSize) {
    span = parseInt((options || {}).columnSize, 10);
    if (!Number.isNaN(span) || Number.isFinite(span)) {
      span = (options || {}).columnSize;
    }
  }

  return (
    <ColWrapper
      className={`col-${span || 12 / colCount} ${(options || {}).classNames ||
        ""}`}
      styles={(options || {}).customStyles || ""}
    >
      <ElementsList id={id} schema={schema} index={0} />
    </ColWrapper>
  );
};

export default withTheme(Col);
