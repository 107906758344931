import React from "react";

import Element from "./Element";
import Container from "./Container";
import RowContainer from "./RowContainer";
import Grid from "./Grid";
import Row from "./Row";
import Col from "./Col";

import Tabs from "./Tabs";
import Tab from "./Tab";

import SingleLine from "./SingleLine";
import MultiLine from "./MultiLine";
import LongText from "./LongText";
import Phone from "./Phone";
import SingleSelect from "./SingleSelect";
import MultiSelect from "./MultiSelect";
import CountrySelect from "./CountrySelect";
import CurrencyField from "./CurrencyField";
import Email from "./Email";
import NumberElement from "./Number";
import DecimalElement from "./Decimal";
import DateField from "./Date";
import DatetimeField from "./Datetime";
import TimeField from "./Time";
import Percentage from "./Percentage";
import Checkbox from "./Checkbox";
import RadioSelect from "./RadioSelect";
import URL from "./URL";
import ContainerWithTitle from "./ContainerWithTitle";
import MapField from "./Map";

import RelatedObject from "./RelatedObject";
import Lookup from "./Lookup";
import PolymorphParent from "./PolymorphParent";
import PolymorphChild from "./PolymorphChild";

import Color from "./Color";
import ResourceDisplay from "./ResourceDisplay";

import Sequence from "./Sequence";
import Formula from "./Formula";
import SingleOn from "./SingleOn";
import DisplayText from "./DisplayText";
import ObjectPrint from "./ObjectPrint";
import Versions from "./Version";

import VersionsButtons from "./VersionsButtons";
import DuplicationsButtons from "./DuplicationsButtons";

import ChartBar from "./Charts/Bar";
import Timeline from "./Timeline";
import StatusBreadcrumb from "./StatusBreadcrumb";
import BrandCard from "./Charts/BrandCard";

// Workflow

import WorkflowStatusSummary from "./Workflow/workflowStatusSummary";
import WorkflowActionsButtons from "./Workflow/workflowActionsButtons";
import WorkflowTimeline from "./Workflow/workflowTimeline";
import PermissionEditor from "./PermissionEditor";
import LastCall from "./LastCall";
import LastMeeting from "./LastMeeting";
import ChartLoader from "./ChartLoader";

// $FlowFixMe
const Folio = React.lazy(() => import("./Folio"));

// $FlowFixMe
const MultipleFileUpload = React.lazy(() => import("./MultipleFileUpload"));

export default {
  Element,
  container: Container,
  rowContainer: RowContainer,
  grid: Grid,
  row: Row,
  col: Col,
  containerWithTitle: ContainerWithTitle,

  tabs: Tabs,
  tab: Tab,

  // Inputs
  singleLine: SingleLine,
  multiLine: MultiLine,
  longText: LongText,
  phone: Phone,
  singleSelect: SingleSelect,
  multiSelect: MultiSelect,
  email: Email,
  numberField: NumberElement,
  decimalField: DecimalElement,
  currency: CurrencyField,
  dateField: DateField,
  datetimeField: DatetimeField,
  timeField: TimeField,
  percent: Percentage,
  checkbox: Checkbox,
  radioSelect: RadioSelect,

  urlField: URL,

  singleSelectCountries: CountrySelect,
  mapField: MapField,

  relatedObject: RelatedObject,
  versions: Versions,
  lookup: Lookup,
  polymorphParent: PolymorphParent,
  polymorphChild: PolymorphChild,

  folio: Folio,
  multipleFileUpload: MultipleFileUpload,
  permissionEditor: PermissionEditor,
  resourceDisplay: ResourceDisplay,
  sequence: Sequence,
  formula: Formula,
  displayText: DisplayText,
  singleOn: SingleOn,
  objectPrint: ObjectPrint,

  workflowStatusSummary: WorkflowStatusSummary,
  workflowActionsButtons: WorkflowActionsButtons,

  versionsButtons: VersionsButtons,
  duplicationButtons: DuplicationsButtons,
  workflowTimeline: WorkflowTimeline,
  color: Color,
  chartBar: ChartBar,
  brandCard: BrandCard,
  lastCall: LastCall,
  lastMeeting: LastMeeting,
  chartLoader: ChartLoader,
  timeline: Timeline,
  statusBreadcrumb: StatusBreadcrumb,
};
