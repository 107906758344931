import * as React from "react";
import styled, { withTheme } from "styled-components";

const ArtsWrapper = styled.div(({ theme }) => ({
  ...(theme.ArtsWrapper ? { ...theme.ArtsWrapper } : {}),
  ...{
    justifyContent: "center",
    alignItems: "center",
    margin: "0.5rem 0.5rem 0",
    minHeight: "200px",
    border: "1px dashed #333",
  },
}));

const EmptyContainer = () => <ArtsWrapper />;

export default withTheme(EmptyContainer);
