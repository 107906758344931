export const STORABLE_TYPE = "STORABLE";
export const DISPLAYABLE_TYPE = "DISPLAYABLE";

export const PENCIL_BOX_ORDER = [
  "FIELD",
  "RELOBJ",
  "ATTRIB",
  "LAYOUT",
  "WORKFLOW",
  "VERSIONS",
  "CHARTS",
  "DUPLICATIONS",
];
