export default {
  BRAND_01: "#3d70b2",
  BRAND_02: "#5596e6",
  BRAND_03: "#41d6c3",
  UI_01: "#ffffff",
  UI_02: "#f4f7fb",
  UI_03: "#dfe3e6",
  UI_04: "#8897a2",
  UI_05: "#5a6872",
  TEXT_01: "#152935",
  TEXT_02: "#5a6872",
  TEXT_03: "#cdd1d4",
  INVERSE_01: "#ffffff",
  INVERSE_02: "#272d33",
  FIELD_01: "#f4f7fb",
  FIELD_02: "#ffffff",
  HOVER_PRIMARY: "#30588c",
  HOVER_PRIMARY_TEXT: "#294c86",
  HOVER_DANGER: "#bd1427",
  HOVER_SECONDARY: "#3d70b2",
  HOVER_ROW: "rgba(85,150,230,.1)",
  SUPPORT_01: "#e0182d",
  SUPPORT_02: "#5aa700",
  SUPPORT_03: "#efc100",
  SUPPORT_04: "#5aaafa",
};
