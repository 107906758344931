export const MODULE_NAME = "DIGISIGN"; // Module display name
export const MODULE_DISPLAY_NAME = "Digital Signature";
export const MODULE_SHORT_DISPLAY_NAME = "DigiSign";
export const MODULE_URN = "digisign"; // module url prefix
export const MODULE_UNIQUE = "digisign"; // module unique identifier within swan system and this should be unique throught the system

export default {
  MODULE_DISPLAY_NAME,
  MODULE_SHORT_DISPLAY_NAME,
  MODULE_NAME,
  MODULE_URN,
  MODULE_UNIQUE,
};
