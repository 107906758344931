import React from "react";
import { debounce, isEqual } from "lodash";

// utils
import rulesCookedSchema from "../utils/rulesCookedSchema";
import rulesSpicedValues from "../utils/rulesSpicedValues";

// types
import { type Schema } from "../types";

type Props = {
  children: Function,
  schema: Schema,
  data?: Object,
  rules?: Object,
  updateData?: Function,
};

type State = {
  currentData: Object,
  currentRules: Object,
};

const Rulified = class extends React.Component<Props, State> {
  static defaultProps = {
    rules: {},
    data: {},
    updateData: undefined,
  };

  updateSchema = debounce(() => {
    const { data, rules, schema, updateData } = this.props;
    if (rules && Object.keys(rules).length) {
      rulesSpicedValues({
        rules,
        schema,
        data,
        updateData,
      });
    }

    this.cachedSchema =
      rules && Object.keys(rules).length
        ? rulesCookedSchema({
            rules,
            schema,
            data,
            updateData,
          })
        : schema;

    this.setState({
      currentData: data,
      currentRules: rules,
    });
  }, 300);

  constructor(props: Props) {
    super(props);
    this.state = {
      currentData: props.data,
      currentRules: props.rules,
    };
    // set schema to empty
    this.cachedSchema = {};
    // delay it for sometime until rule is get ready
    this.updateSchema();
  }

  componentDidMount(): void {
    this.updateSchema();
  }

  componentDidUpdate() {
    const { rules } = this.props;
    const { currentRules } = this.state;
    if (JSON.stringify(rules) !== JSON.stringify(currentRules)) {
      this.updateSchema();
    }
  }

  getSchema = (data?: Object = {}) => {
    const { currentData } = this.state;
    if (isEqual(data, currentData)) {
      return this.cachedSchema;
    }
    this.updateSchema();
    return this.cachedSchema;
  };

  cachedSchema: Schema;

  render() {
    const { data } = this.props;
    const schema = this.getSchema(data);
    const { children } = this.props;
    return children({
      schema,
    });
  }
};

export default Rulified;
