import { combineReducers } from "redux";
import user, { type State as UserState } from "./user";

export type ReduxState = {
  user: UserState,
};

// $FlowFixMe Should be combineReducers<*, *, *>
export default combineReducers({
  user,
});
