import moment from "moment-timezone";

export const isoDateFormatString: string = "YYYY-MM-DDThh:mm:ssZ";

export default (d?: number | string | Date) => {
  let date;
  // accept Date object
  if (d && typeof d === "object" && d.constructor === Date) {
    date = d;
  }
  // otherwise, we accept only iso string format as above
  if (typeof d === "number") date = String(d);
  if (typeof d === "string") {
    if (moment(d, isoDateFormatString).format(isoDateFormatString) === date) {
      // string is in iso format
      date = moment(d, isoDateFormatString);
    } else {
      date = moment.utc(d);
    }
    date = date.isValid() ? date.toDate() : new Date();
  }

  if (date) {
    const value = moment(date)
      .tz(moment.tz.guess())
      .toDate();
    return value;
  }

  return new Date();
};
