import * as React from "react";
import { RemoteSelect } from "@swan/themer";
import { LookupResourceFactory, ObjectService } from "@swan/api"; // eslint-disable-line
import { get } from "lodash";

import Field from "./partials/Field";
import type { ElementProps } from "./types";
import { RendererContext } from "../Main";

function onSelectChange(onBatchChange, schema, value) {
  if (onBatchChange) {
    const v = {};
    Object.keys(value).forEach(attr => {
      if (attr === "value") {
        v[schema.attributeName || ""] = value[attr];
      } else if (attr === "label") {
        v[`${schema.attributeName || ""}_display`] = value[attr];
      } else {
        v[attr] = value[attr];
      }
    });
    onBatchChange(v);
  }
}

class LookupConfigured extends React.Component<
  ElementProps & {
    object?: string,
    parentRecord?: Object,
    auth?: Object,
  },
  {
    service?: Object,
    labelAttribute?: ?string,
    labelExpression?: ?string,
    creatable?: boolean,
    isCreatePerm?: boolean,
    relationshipDefinition?: Object,
  }
> {
  state = {
    service: null,
    labelAttribute: null,
    labelExpression: null,
    creatable: true,
    isCreatePerm: false,
    relationshipDefinition: null,
  };

  componentDidMount() {
    const { object, schema, auth } = this.props;
    const { isAllowed } = auth || {};
    const lookupObject = ((schema.options || {}).relationMeta || {})
      .childObject;

    const ResClass = LookupResourceFactory(
      object,
      (schema.options || {}).relationName
    );
    const service = new ResClass();
    service.getRelationshipDefinition().then(def => {
      const creatable = def.options.autoCreate !== false;
      if (def.parent_object === object) {
        ObjectService.getObjectByName(def.child_object).then(childDef => {
          this.setState({
            service,
            labelAttribute:
              def.lookup_label_expression || def.lookup_label_attribute
                ? def.lookup_label_attribute
                : childDef.label_attribute,
            labelExpression:
              def.lookup_label_expression || def.lookup_label_attribute
                ? def.lookup_label_expression
                : childDef.label_expression,
            creatable,
            relationshipDefinition: def,
          });
        });
      } else {
        // the case of onetomany reverse (selecting a parent)
        ObjectService.getObjectByName(def.parent_object).then(parentDef => {
          this.setState({
            service,
            labelAttribute:
              def.lookup_label_expression || def.lookup_label_attribute
                ? def.lookup_label_attribute
                : parentDef.label_attribute,
            labelExpression:
              def.lookup_label_expression || def.lookup_label_attribute
                ? def.lookup_label_expression
                : parentDef.label_expression,
            creatable,
            relationshipDefinition: def,
          });
        });
      }
    });

    isAllowed(`${lookupObject}:create`).then(isCreatePerm => {
      this.setState({
        isCreatePerm,
      });
    });
  }

  getFilters() {
    const filters = {};
    const { service, relationshipDefinition } = this.state;
    if (!service) return {};
    const { schema, data, parentRecord } = this.props;
    if (parentRecord && data) {
      data.parent = parentRecord;
    }
    const filtersDef = (schema.options || {}).filters || {}; // Filters definition: an object where index is the filter name and the value is the attribute name in data to be plugged into filter
    Object.keys(filtersDef).forEach(filterAttr => {
      // fallback to schema options value, allows setting default filtration value
      filters[filterAttr] = {
        value:
          (data || {})[filtersDef[filterAttr]] ||
          ((schema.options || {}).filters || {})[filterAttr],
        operator: "=",
      };
    });
    if ((relationshipDefinition || {}).options) {
      if ((relationshipDefinition || {}).options.filters) {
        (relationshipDefinition || {}).options.filters.forEach(filter => {
          if (filter.transparent !== true) return; // This should be ignored, already set in the pencil options
          if (filter.static) {
            filters[filter.attribute] = {
              value: filter.static,
              operator: filter.operator || "=",
            };
          }
          if (filter.accessor) {
            filters[filter.attribute] = {
              value: get(data || {}, filter.accessor || "", ""),
              operator: filter.operator || "=",
            };
          }
        });
      }
    }
    return filters;
  }

  getValueMap() {
    const map = {};
    const { service, relationshipDefinition } = this.state;
    if (!service) return {};
    const { schema } = this.props;
    const mapDef = (schema.options || {}).map || {}; // Filters definition: an object where index is the filter name and the value is the attribute name in data to be plugged into filter
    const customMapDef = (schema.options || {}).customMap || {}; // Filters definition: an object where index is the filter name and the value is the attribute name in data to be plugged into filter
    Object.keys(mapDef).forEach(filterAttr => {
      map[filterAttr] = mapDef[filterAttr];
    });
    Object.keys(customMapDef).forEach(index => {
      const filterAttr = customMapDef[index];
      map[filterAttr.from] = filterAttr.to;
    });
    if ((relationshipDefinition || {}).options) {
      if ((relationshipDefinition || {}).options.map) {
        (relationshipDefinition || {}).options.map.forEach(filter => {
          if (filter.transparent !== true) return; // This should be ignored, already set in the pencil options
          if (filter.destination) {
            map[filter.attribute] = filter.destination;
          }
        });
      }
    }
    return map;
  }

  render() {
    const {
      schema,
      // onChange,
      onBatchChange,
      error,
      data,
      readOnly,
      hideLabel,
    } = this.props;
    const filters = this.getFilters();
    const {
      service,
      labelAttribute,
      labelExpression,
      creatable,
      isCreatePerm,
    } = this.state;
    if (!service) {
      return null;
    }
    return (
      <RemoteSelect
        {...schema.options || {}}
        name={schema.attributeName || ""}
        value={(data || {})[schema.attributeName || ""]}
        displayValue={
          (data || {})[`${schema.attributeName || ""}_display`] ||
          (data || {})[schema.attributeName || ""] ||
          ""
        }
        initialDisplay={
          (data || {})[`${schema.attributeName || ""}_display` || ""]
        }
        label={schema.label}
        hideLabel={hideLabel || (schema.options || {}).hideLabel}
        onChange={e => {
          onSelectChange(onBatchChange, schema, e);
        }}
        passFiltersToCreate={
          (schema.options || {}).passFiltersToCreate || false
        }
        error={(error || {})[schema.attributeName || ""]}
        // lookupRoute={(schema.options || {}).lookupEndpoint}
        labelAttribute={labelAttribute}
        groupAttribute={((schema || {}).options || {}).optionGroupedByKey}
        labelExpression={labelExpression}
        service={service}
        filters={filters}
        valueMap={this.getValueMap()}
        creatable={creatable && isCreatePerm}
        // itemIdAccessor={item => item.value}
        // itemLabelAccessor={item => item.name}
        static={readOnly || (schema.options || {}).readOnly}
        allowCopyText
      />
    );
  }
}

const Lookup = (props: ElementProps) => (
  <RendererContext.Consumer>
    {({ object, parentRecord, auth }) => (
      <LookupConfigured
        {...props}
        object={object}
        parentRecord={parentRecord}
        auth={auth}
      />
    )}
  </RendererContext.Consumer>
);

export default Field(Lookup);
