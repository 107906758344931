export default (name: string, filler?: string = "x") => {
  const parts = (name || "").split(" ");
  let avatar = [];

  if (parts.length === 1) {
    const n = parts[0];
    if (n.length > 0) {
      avatar.push(n[0]);
    }
    if (n.length > 1) {
      avatar.push(n[1]);
    } else {
      avatar.push(filler);
    }
  }

  if (parts.length > 1) {
    avatar = parts.map(part => {
      if (part.length) {
        return part[0];
      }
      return filler;
    });
  }

  if (avatar.length > 1) {
    avatar = avatar.slice(0, 2);
  } else {
    avatar = [filler, filler];
  }
  return avatar.join("").toUpperCase();
};
