import React from "react";
import { Col, Row } from "reactstrap";
import styled from "styled-components";

import { Spinner, InfiniteScroll } from "@swan/themer";
import { Paginator } from "@swan/helpers";
import Chart from "./Chart";

type Props = {
  charts: any,
};

const CustomRow = styled(Row)`
  margin-left: 10px;
`;

const CustomCol = styled(Col)`
  margin-bottom: 40px;

  ${props => props.customStyle}

  min-height: 200px;
`;

const Charts = ({ charts }: Props) => {
  const sortedCharts = [...charts];
  sortedCharts.sort((a, b) =>
    (a.pivot || {}).order > (b.pivot || {}).order ? 1 : -1
  );

  return (
    <Paginator list={sortedCharts} perPage={4}>
      {({ list, loadMore, canLoadMore }) => (
        <InfiniteScroll
          callback={loadMore}
          canLoadMore={canLoadMore}
          loaderComponent={<Spinner className="mt-3" />}
        >
          <CustomRow>
            {list.map(chart => (
              <CustomCol
                key={chart.id}
                md={(chart.pivot || {}).size}
                sm={12}
                customStyle={chart.style}
              >
                <Chart chartMeta={chart} />
              </CustomCol>
            ))}
          </CustomRow>
        </InfiniteScroll>
      )}
    </Paginator>
  );
};

export default Charts;
