import Axios from "axios";
import { ResourceGenerator } from "@swan/api"; // eslint-disable-line

const ObjectAttributeService = ResourceGenerator(
  Axios,
  "administration/objects"
);

/* eslint-disable-next-line func-names */
ObjectAttributeService.prototype.setObject = function(object: string) {
  this.object = object;
  this.prefix = `administration/objects/${this.object}/attributes`;
};

export default ObjectAttributeService;
