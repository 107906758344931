import React, { Component, Fragment } from "react";
import { Input, InputGroupText } from "reactstrap";
import { FaSearch } from "react-icons/fa";

import _ from "lodash";

import { type AddressType } from "./types";

import { getLocationByLatlng } from "./Utils";

type Props = {
  elementId: string,
  className?: string,
  error?: string,
  onSubmit: (address: AddressType) => any,
  defaultLat?: ?number,
  defaultLng?: ?number,
};

type State = {
  lat: ?number,
  lng: ?number,
};

class CoordinatesFields extends Component<Props, State> {
  static defaultProps = {
    className: "",
    error: "",
    defaultLat: undefined,
    defaultLng: undefined,
  };

  searchLocation = _.debounce(() => {
    const { lng, lat } = this.state;
    const { onSubmit } = this.props;
    if (lat && lng) {
      const latlng = {
        lat: parseFloat(lat),
        lng: parseFloat(lng),
      };
      onSubmit(latlng); // In case geo location fails
      getLocationByLatlng(latlng, onSubmit);
    }
  }, 1000);

  constructor(props: Props) {
    super(props);
    this.state = {
      lat: props.defaultLat,
      lng: props.defaultLng,
    };
    this.onLatChange = this.onLatChange.bind(this);
    this.onLngChange = this.onLngChange.bind(this);
    this.searchLocation = this.searchLocation.bind(this);
  }

  componentWillReceiveProps(nextProps: Props) {
    const { lat, lng } = this.state;
    if (nextProps.defaultLat !== lat || nextProps.defaultLng !== lng) {
      this.setState({
        lat: parseFloat(nextProps.defaultLat),
        lng: parseFloat(nextProps.defaultLng),
      });
      return true;
    }
    return false;
  }

  onLatChange = (event: Object) => {
    this.setState(
      {
        lat: parseFloat(event.target.value),
      },
      () => this.searchLocation()
    );
  };

  onLngChange = (event: Object) => {
    this.setState(
      {
        lng: parseFloat(event.target.value),
      },
      () => this.searchLocation()
    );
  };

  render() {
    const { lat, lng } = this.state;
    const { elementId, className, error } = this.props;
    return (
      <Fragment>
        <div className="form-map-control-two-inputs">
          <Input
            type="number"
            id={`${elementId}_lat`}
            placeholder="lat..."
            value={lat || ""}
            onChange={this.onLatChange}
            className={`${className || ""} ${error !== "" ? "is-invalid" : ""}`}
          />
        </div>
        <div className="form-map-control-two-inputs">
          <Input
            type="number"
            id={`${elementId}_lng`}
            placeholder="lng..."
            onChange={this.onLngChange}
            value={lng || ""}
            className={`${className || ""} ${error !== "" ? "is-invalid" : ""}`}
          />
        </div>
        <div className="input-group-prepend filter-map-icon">
          <InputGroupText
            className="input-group-append"
            onClick={this.searchLocation}
          >
            <FaSearch />
          </InputGroupText>
        </div>
      </Fragment>
    );
  }
}

export default CoordinatesFields;
