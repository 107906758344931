export default {
  pencilDef: {
    title: "Grid",
    group: "LAYOUT",
    handleIcon: "FaThLarge",
    styles: {},
  },
  drop: {
    allowTypes: ["row"],
  },
  options: {
    title: "Untitled",
    type: "grid",
    defaultProperties: {
      row1: {
        type: "row",
        properties: {
          col1: {
            type: "col",
            properties: {},
          },
        },
      },
    },
  },
};
