import React from "react";
import { StatusBreadcrumb as Breadcrumb } from "@swan/themer";
import Field from "./partials/Field";

const StatusBreadcrumb = ({ data, schema }: any) => {
  const { statuses, key } = schema.options;
  return (
    <Breadcrumb
      statuses={statuses}
      isActive={currentStatus => currentStatus.id === data[key]}
    />
  );
};

export default Field(StatusBreadcrumb);
