/**
 * A component to ask for user input by calling the function prompt(title, inputs)
 * Where inputs is an object with key being the input name, and value being an object with: label, required and type (text | paragraph)
 */
import React from "react";
import { Alert } from "reactstrap";
import Modal from "./Modal";
import TextArea from "./Form/TextArea";
import Input from "./Form/Input";

type InputType = {
  label: string,
  required: boolean,
  type: "text" | "paragraph",
};

type State = {
  isOpen: boolean,
  title: ?string,
  inputs: ?{ [name: string]: InputType },
  values: Object,
  onSubmit: ?Function,
  onCancel: ?Function,
  errors: Array<string>,
};

type Props = {
  submitLabel?: string,
  cancelLabel?: string,
};

class Prompt extends React.Component<Props, State> {
  static defaultProps = {
    submitLabel: "Submit",
    cancelLabel: "Cancel",
  };

  state = {
    isOpen: false,
    title: undefined,
    inputs: undefined,
    values: {},
    onSubmit: undefined,
    onCancel: undefined,
    errors: [],
  };

  close = () => {
    this.setState({
      isOpen: false,
      values: {},
    });
  };

  submit = () => {
    const { onSubmit, values, inputs } = this.state;
    // Check for required
    const errors = [];
    Object.keys(inputs || {}).forEach(name => {
      if ((inputs || {})[name].required) {
        if (!values[name]) {
          errors.push(`${(inputs || {})[name].label} is required`);
        }
      }
    });
    if (errors.length) {
      this.setState({
        errors,
      });
      return;
    }
    if (onSubmit) {
      onSubmit(values);
    }
    this.close();
  };

  cancel = () => {
    const { onCancel } = this.state;
    if (onCancel) {
      onCancel();
    }
    this.close();
  };

  open(
    title: string,
    inputs: { [name: string]: InputType },
    onSubmit?: Function,
    onCancel?: Function
  ) {
    this.setState({
      isOpen: true,
      values: {},
      errors: [],
      title,
      inputs,
      onSubmit,
      onCancel,
    });
  }

  renderInputs() {
    const { isOpen, inputs, values } = this.state;
    if (!isOpen) {
      return null;
    }
    // $FlowFixMe
    return Object.keys(inputs || {}).map(name => {
      const input = (inputs || {})[name] || {};
      const Component = input.type === "paragraph" ? TextArea : Input;
      return (
        <Component
          key={name}
          name={name}
          label={input.label || ""}
          required={input.required}
          value={values[name]}
          onChange={e => {
            const { value } = e.target;
            this.setState(({ values: currentValues }) => ({
              values: {
                ...currentValues,
                [name]: value,
              },
            }));
          }}
        />
      );
    });
  }

  render() {
    const { isOpen, title, errors } = this.state;
    const { submitLabel, cancelLabel } = this.props;
    if (!isOpen) return null;
    return (
      <Modal
        isOpen={isOpen}
        toggle={this.close}
        title={title || ""}
        buttons={[
          { label: submitLabel || "", color: "primary", onClick: this.submit },
          { label: cancelLabel || "", color: "danger", onClick: this.cancel },
        ]}
      >
        {!!errors.length && (
          <Alert color="danger">
            {errors.map(e => (
              <p key={String(e)} style={{ marginBottom: 0 }}>
                {String(e)}
              </p>
            ))}
          </Alert>
        )}
        {isOpen && this.renderInputs()}
      </Modal>
    );
  }
}

export default Prompt;
