import * as React from "react";
import { WorkflowTimeline } from "@swan/workflow";

import Field from "../partials/Field";
import { RendererContext } from "../../Main";

// types
import { type ElementProps } from "../types";

type Props = ElementProps & {
  object: string,
};

const Summary = ({ schema, data, object }: Props) => (
  <WorkflowTimeline {...schema.options || {}} record={data} object={object} />
);

const Timeline = (props: ElementProps) => (
  <RendererContext.Consumer>
    {({ object }) => <Summary {...props} object={object} />}
  </RendererContext.Consumer>
);

export default Field(Timeline);
