import React, { Component } from "react";
import { connect } from "react-redux";
import { Card, CardBody, CardGroup } from "reactstrap";
import styled from "styled-components";

import QueryString from "query-string";
import { userActions } from "../../../state/reducers/user";

type Props = {
  history: { push: Function },
  loginWithIdToken: Function,
  loggedIn: boolean,
  error: boolean,
  errorMessage: string
};

const Wrapper = styled("div")`
  background: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  min-width: 100vw;

  .content {
    background: #294c86 !important;
    width: 80% !important;

    @media (min-width: 786px) {
      width: 50% !important;
    }
  }
`;

class LoginCheck extends Component<Props> {
  static defaultProps = {
    loggedIn: false,
    error: false,
    errorMessage: ""
  };

  componentDidMount() {
    const params = QueryString.parse(window.location.hash);

    const { history, loginWithIdToken, loggedIn } = this.props;

    if (!params.id_token) {
      history.push("/loginFailed");
    }
    if (loggedIn) {
      this.redirectToLanding();
    }

    loginWithIdToken(params.id_token);
  }

  componentDidUpdate() {
    const { loggedIn } = this.props;
    if (loggedIn) {
      this.redirectToLanding();
    }
  }

  redirectToLanding() {
    const redirect = window.localStorage.getItem("redirect");
    const { history } = this.props;
    if (redirect) {
      history.push(redirect);
    } else {
      history.push("/");
    }
  }

  render() {
    const { error, errorMessage } = this.props;
    return (
      <Wrapper>
        <div className="app flex-row align-items-center">
          <CardGroup>
            <Card
              className={`content text-white py-5 d-md-down-none ${
                error ? "bg-danger" : "bg-primary"
              }`}
              style={{ width: "44%" }}
            >
              <CardBody className="text-center">
                {error ? (
                  <div>
                    <h2>
                      {/* prettier-ignore */}
                      An error occurred
                    </h2>
                    <p>
                      {/* prettier-ignore */}
                      {errorMessage}
                    </p>
                  </div>
                ) : (
                  <div>
                    <h2>
                      {/* prettier-ignore */}
                      We are verifying your login
                    </h2>
                    <p>
                      We are checking your data and you will be redirected
                      shortly...
                    </p>
                  </div>
                )}
              </CardBody>
            </Card>
          </CardGroup>
        </div>
      </Wrapper>
    );
  }
}

function mapStateToProps(state) {
  return {
    loggedIn: state.user.loggedIn,
    error: state.user.error,
    errorMessage: state.user.errorMessage
  };
}

export default connect(
  mapStateToProps,
  {
    loginWithIdToken: userActions.loginWithIdToken
  }
)(LoginCheck);
