// @flow
import React, { Component } from "react";
import _ from "lodash";
import { Button, Modal, Card } from "@swan/themer";
import { FormService } from "@swan/dynamic-ui"; // eslint-disable-line
import { ButtonConfirm } from "@swan/helpers/UI";
import { RelationshipResourceFactory } from "@swan/api"; // eslint-disable-line
import {
  FaPlusCircle,
  FaRegWindowClose,
  FaRegTrashAlt,
  FaRegSave,
} from "react-icons/fa";
import Field from "../partials/Field";
import GridList from "../partials/CRUD/GridList";
import Form from "../partials/CRUD/Form";
import CardListDataProvider from "../partials/CRUD/CardListDataProvider";
import TableRowForm from "../partials/CRUD/TableRowForm";
import LocalDataService from "../partials/CRUD/LocalDataService";

import {
  RelatedObjectWrapper,
  TitleRow,
  CardFormRow,
} from "../partials/CRUD/theme";

// utils
import {
  getRelatedObjectModifiers,
  getRelatedObjectRendererComponents,
} from "../../../utils/modifiers";

// context
import { RendererSubscriber } from "../../Main";

// types
import { type Schema } from "../../../types";

type Props = {
  schema: Schema,
  data: Object,
  customService: Object,
  onChange: (attribute: string, value: string | number | Object) => void,
  isNew: boolean,
  readOnly: boolean,
  // id: string,
  // index: string | number,
};

type State = {
  listMode: string,
  editMode: string,
  isAdding: boolean,
  isEditing: boolean,
  modalOpen: boolean,
  selectedRecord: any,
  form?: Object,
  cardViewForm?: Object,
};

class RelatedObject extends Component<Props, State> {
  mounted = false;

  constructor(props: Props) {
    super(props);

    this.state = {
      selectedRecord: undefined,
      isAdding: false,
      isEditing: false,
      editMode: "inline",
      listMode: "grid",
      modalOpen: false,
      form: undefined,
      cardViewForm: undefined,
    };
  }

  componentWillMount() {
    const {
      schema: {
        options: { listMode, editMode, defaultForm, cardViewForm } = {},
      },
    } = this.props;

    const service = new FormService();

    if (defaultForm || cardViewForm) {
      service
        .getById(defaultForm || cardViewForm)
        .then(response => {
          this.setState(state => ({
            form: response,
            cardViewForm:
              !cardViewForm || cardViewForm === defaultForm
                ? response
                : state.cardViewForm,
          }));
        })
        .catch(() => {});
      if (cardViewForm && cardViewForm !== defaultForm) {
        service
          .getById(cardViewForm)
          .then(response => {
            this.setState({
              cardViewForm: response,
            });
          })
          .catch(() => {});
      }
    }

    this.mounted = true;

    if (this.mounted) {
      this.setState({
        editMode: editMode || "inline",
        listMode: listMode || "grid",
      });
    }
  }

  componentWillReceiveProps(nextProps: Props) {
    const { data: nextData } = nextProps;
    const { data, schema } = this.props;
    const { options: { relationMeta: { name } } = {} } = schema;
    if (
      data[name] &&
      nextData[name] &&
      nextData[name].length !== data[name].length
    ) {
      if (this.service.setData) {
        this.service.setData(nextData[name]);
      }
    }
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  addClickHandler = () => {
    const { editMode } = this.state;
    this.setState(
      Object.assign(
        {},
        {
          isAdding: true,
          isEditing: false,
        },
        editMode === "modal" ? { modalOpen: true } : {}
      )
    );
  };

  rowClickHandler = (rowData: Object) => {
    const { editMode } = this.state;
    this.setState({
      isAdding: false,
      isEditing: true,
      selectedRecord: rowData,
      modalOpen: editMode === "modal",
    });
  };

  setListingDefaults = () => {
    this.setState({
      modalOpen: false,
      isAdding: false,
      isEditing: false,
      selectedRecord: undefined,
    });
  };

  handleSaveFunction = (saveFunction: Function) => {
    this.saveRecord = saveFunction;
  };

  handleDeleteFunction = (deleteFunction: Function) => {
    this.deleteRecord = deleteFunction;
  };

  shouldUseLocalData = () => {
    const { isNew: parentIsNew } = this.props;
    return parentIsNew;
  };

  getService = () => {
    const { schema, data: ParentData, customService } = this.props;
    if (customService) {
      return customService;
    }
    if (this.service) return this.service;
    const { options: { relationMeta: { parentObject, name } } = {} } = schema;
    if (this.shouldUseLocalData()) {
      this.service = new LocalDataService(
        ParentData[name],
        (newData: Array<Object>) => {
          const { onChange } = this.props;
          onChange(name, newData);
        }
      );
    } else {
      const RelService = RelationshipResourceFactory(parentObject, name);
      this.service = new RelService();
    }
    return this.service;
  };

  saveRecord: Function;

  deleteRecord: Function;

  service: Object;

  render() {
    const { schema, data: RecData, readOnly } = this.props;
    // console.log(readOnly, sysObject);
    const {
      options: {
        defaultForm,
        cardViewForm,
        relationMeta: {
          type: relationType,
          parentObject,
          childObject,
          name,
          reverseName,
        },
        showListTitle,
        disableAdding,
        disableFilteration,
        disableSorting,
        listTitle,
      } = {},
    } = schema;
    const listData = this.shouldUseLocalData()
      ? RecData[name] || []
      : undefined;
    const {
      isAdding,
      isEditing,
      editMode,
      listMode,
      modalOpen,
      selectedRecord,
      form,
      cardViewForm: cardViewFormObject,
    } = this.state;

    const triggerModalForm =
      ["grid", "card"].indexOf(listMode) !== -1 &&
      editMode === "modal" &&
      (isAdding || isEditing);
    const triggerDefaultForm = editMode !== "modal" && isAdding && !isEditing;

    const service = this.getService();
    return (
      <RelatedObjectWrapper>
        <RendererSubscriber>
          {({
            ServiceFactory,
            data,
            object,
            modifiers,
            isAddAllowed,
            isEditAllowed,
            isDeleteAllowed,
            rendererComponents,
          }) => {
            const buttons: Array<Object> = [
              ...(!readOnly &&
              !RecData.is_locked &&
              ((isEditing && isEditAllowed) || isAddAllowed)
                ? [
                    {
                      label: `${isEditing ? "Update" : "Save"}`,
                      icon: <FaRegSave />,
                      color: "primary",
                      onClick: () => {
                        this.saveRecord();
                      },
                    },
                  ]
                : []),
              ...(!readOnly &&
              isEditing &&
              !RecData.is_locked &&
              isDeleteAllowed
                ? [
                    {
                      label: "Delete",
                      icon: <FaRegTrashAlt />,
                      color: "danger",
                      type: "ButtonConfirm",
                      onClick: () => this.deleteRecord(),
                    },
                  ]
                : []),
              {
                label: "Close",
                icon: <FaRegWindowClose />,
                onClick: () => this.setListingDefaults(),
                color: "secondary",
              },
            ];
            if (
              (relationType === "OneToMany" ||
                relationType === "Polymorphic") &&
              object === parentObject
            ) {
              // const service = ServiceFactory(childObject, pick(data, ["id"]));
              // console.log(parentObject, childObject, name, reverseName);
              service.setParent(data.id);
              const relatedObjectModifiers = getRelatedObjectModifiers(
                modifiers,
                name
              );

              const relatedObjectRendererComponents = getRelatedObjectRendererComponents(
                rendererComponents,
                name
              );
              const parentClone = _.merge({}, data);
              let defaultValues = Object.assign(
                {},
                { [reverseName.toLowerCase()]: parentClone }
              );
              if (relatedObjectModifiers) {
                if (relatedObjectModifiers.form) {
                  if (relatedObjectModifiers.form.getDefaultValues) {
                    defaultValues = relatedObjectModifiers.form.getDefaultValues(
                      data
                    );
                  }
                }
              }
              const FormComponent = (props: any) => (
                <Form
                  listId={`${childObject}_list`}
                  formMode={isAdding ? "create" : "details"}
                  service={service}
                  object={childObject}
                  formId={defaultForm}
                  type="create"
                  ServiceFactory={ServiceFactory}
                  id={
                    isAdding
                      ? "new"
                      : selectedRecord.id /* should be id of selected row */
                  }
                  noSave
                  disableDelete
                  registerSaveFunction={this.handleSaveFunction}
                  registerDeleteFunction={this.handleDeleteFunction}
                  goBack={this.setListingDefaults}
                  parentRecord={parentClone}
                  modifiers={relatedObjectModifiers}
                  rendererComponents={relatedObjectRendererComponents}
                  defaultValues={defaultValues}
                  {...props}
                  record={{
                    ...props.data,
                    is_locked:
                      RecData.is_locked || (isEditing && !isEditAllowed),
                    ...(reverseName
                      ? { [reverseName.toLowerCase()]: parentClone }
                      : {}),
                  }}
                  readOnly={
                    readOnly ||
                    RecData.is_locked ||
                    (isEditing && !isEditAllowed)
                  }
                />
              );

              return (
                <React.Fragment>
                  <TitleRow className={!showListTitle && "no-title"}>
                    {/* 0. Title */}
                    {showListTitle ? (
                      <h6 className="title">
                        {listTitle || `Add ${name} for ${reverseName}`}
                      </h6>
                    ) : null}

                    {/* 1. Control Buttons */}
                    <div className="clearfix control-buttons">
                      {!disableAdding &&
                        !isAdding &&
                        isAddAllowed &&
                        !isEditing &&
                        !RecData.is_locked && (
                          <Button
                            onClick={this.addClickHandler}
                            className="float-right add-control tag-button"
                          >
                            Add <FaPlusCircle />
                          </Button>
                        )}
                      {(isAdding || isEditing) && listMode !== "card" && (
                        <Button
                          onClick={() => {
                            this.setListingDefaults();
                          }}
                          className="float-right close-control tag-button"
                        >
                          Close
                        </Button>
                      )}
                    </div>
                  </TitleRow>

                  {/* 2. Modal Form */}
                  {triggerModalForm && (
                    <Modal
                      title={
                        isAdding
                          ? `Add ${name} for ${reverseName}`
                          : `Update ${reverseName} ${name} #${
                              selectedRecord.id
                            }`
                      }
                      isOpen={modalOpen}
                      toggle={this.setListingDefaults}
                      buttons={buttons.reverse()}
                      size="xl"
                    >
                      <FormComponent />
                    </Modal>
                  )}

                  {/* 3. Default Form */}
                  {triggerDefaultForm && (
                    <CardFormRow>
                      <Card
                        className="default-card-form"
                        buttonsRight
                        buttons={listMode !== "card" ? buttons : undefined}
                        isMinimal
                      >
                        <FormComponent />
                      </Card>
                      {listMode === "card" ? (
                        <div className="default-card-form-controls">
                          {buttons.map(
                            ({ label, icon, type, ...restBtnProps }) =>
                              type === "ButtonConfirm" ? (
                                <ButtonConfirm
                                  key={`btn${label}`}
                                  {...restBtnProps}
                                >
                                  {icon || label}
                                </ButtonConfirm>
                              ) : (
                                <Button
                                  className="tag-button"
                                  {...restBtnProps}
                                >
                                  {icon || label}
                                </Button>
                              )
                          )}
                        </div>
                      ) : null}
                    </CardFormRow>
                  )}

                  {/* 4. Grid Listing */}
                  {listMode === "grid" && (
                    <GridList
                      TableRowComponent={({
                        row,
                        children,
                        DefaultRow,
                        ...props
                      }) =>
                        isEditing &&
                        editMode === "inline" &&
                        selectedRecord &&
                        selectedRecord.id === row.id ? (
                          <TableRowForm
                            object={childObject}
                            formId={cardViewForm || defaultForm}
                            type="details"
                            service={service}
                            ServiceFactory={ServiceFactory}
                            id={row.id}
                            data={row}
                            record={row} // @todo
                            listId={`${childObject}_list`}
                            columns={children}
                            closeHandler={this.setListingDefaults}
                            form={form}
                            {...props}
                          />
                        ) : (
                          DefaultRow
                        )
                      }
                      defaultForm={form ? form.form : null}
                      loadColumnOnlyOnUpdate
                      service={service}
                      object={childObject}
                      type="details"
                      listId={`${childObject}_list`}
                      gridOptions={{
                        onRowClick:
                          (
                            ((relatedObjectModifiers || {}).list || {})
                              .gridOptions || {}
                          ).onRowClick || this.rowClickHandler,
                        disableAdd: true, // reset the buttons grid options
                        noFiltering: disableFilteration,
                        noSortingControls: disableSorting,
                      }}
                      selectedId={
                        selectedRecord ? selectedRecord.id : undefined
                      }
                      editMode={editMode}
                      useLocalData={this.shouldUseLocalData()}
                      data={listData}
                    />
                  )}

                  {/* 5. Card Listing */}
                  {listMode === "card" && (
                    <CardListDataProvider
                      listId={`${childObject}_list`}
                      service={service}
                      object={childObject}
                      formId={cardViewForm || defaultForm}
                      form={form}
                      listMode={listMode}
                      cardViewForm={cardViewFormObject}
                      ServiceFactory={ServiceFactory}
                      selectedRecord={selectedRecord}
                      buttons={buttons}
                      cardClick
                      reloadRecord={false}
                      editMode={editMode}
                      useLocalData={this.shouldUseLocalData()}
                      data={listData}
                      readOnly={
                        readOnly ||
                        RecData.is_locked ||
                        (isEditing && !isEditAllowed)
                      }
                      // ?? do we need to handle this conditional registration on cardlist component
                    />
                  )}
                </React.Fragment>
              );
            }

            return null;
          }}
        </RendererSubscriber>
      </RelatedObjectWrapper>
    );
  }
}
export default Field(RelatedObject);
