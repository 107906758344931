import * as React from "react";

class SwanContextManager {
  contextId = "_SWANCONTEXT";

  context: Object = {};

  constructor(contextId?: string) {
    if (contextId) {
      this.contextId = contextId;
    }
  }

  getAll() {
    return localStorage.getItem(this.contextId);
  }

  getValues() {
    let context = {};
    // since switchOrganization saga is called, even with refresh this.context holds at least one value i.e. sessionId
    // merge it with localStorage values, to make sure this.context takes presidency
    const storedValues = localStorage.getItem(this.contextId);
    if (storedValues) {
      const values = JSON.parse(storedValues);
      if (values && values.constructor !== Object.prototype.constructor) {
        context = { ...values, ...this.context };
      }
    }
    return context;
  }

  clearValues() {
    this.context = {};
    localStorage.removeItem(this.contextId);
  }

  getValue(key: string) {
    if (this.context[key]) return this.context[key];

    // find it from local storage
    const storedValues = localStorage.getItem(this.contextId);
    if (!storedValues) return null;
    const values = JSON.parse(storedValues);
    if (
      !values ||
      values.constructor !== Object.prototype.constructor ||
      !values[key]
    ) {
      return null;
    }
    return values[key];
  }

  setValue(key: string, value: any) {
    // localStorage
    let values;
    const storage = localStorage.getItem(this.contextId);
    if (storage) {
      values = JSON.parse(storage);
      if (!values || values.constructor !== Object.prototype.constructor) {
        values = {};
      }
      Object.assign(values, { [key]: value });
    } else {
      values = { [key]: value };
    }
    localStorage.setItem(this.contextId, JSON.stringify(values));

    // out of localStorage, we saved it as plain
    this.context[key] = value;
  }

  clearValue(key: string) {
    let values;
    const storage = localStorage.getItem(this.contextId);
    if (storage) {
      values = JSON.parse(storage);
      if (
        values &&
        values.constructor !== Object.prototype.constructor &&
        values[key]
      ) {
        delete values[key];
        localStorage.setItem(this.contextId, JSON.stringify(values));
      }
    }
    // even null values needs to be clear
    if (typeof this.context[key] !== "undefined") {
      delete this.context[key];
    }
  }
}

export const swanContextManager = new SwanContextManager();

const value = {
  swanContextManager,
};

// $FlowFixMe
const SwanModuleContext = React.createContext(value);

export default SwanModuleContext;
