import React, { Fragment } from "react";
import NotFoundEventListener from "./NotFoundEventListener";

// used to listen for events across the platform.
const SwanEventListener = () => {
  return (
    <Fragment>
      <NotFoundEventListener />
    </Fragment>
  );
};

export default SwanEventListener;
