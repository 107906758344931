import React, { Component } from "react";
import { InputGroupText } from "reactstrap";
import { MdMap } from "react-icons/all";
import styled from "styled-components";
import { google } from "@swan/config"; // eslint-disable-line
import FieldWrapper from "../partials/FieldWrapper";
import Options from "./Options";
import CoordinatesFields from "./CoordinatesFields";
import SearchField from "./SearchField";
import URLField from "./URLField";
import { type AddressType } from "./types";
import CurrentLocationButton from "./CurrentLocationButton";
import { addressComponentExtractor } from "./Utils";

// style overide
import "./style.css";

export type MapProps = {
  defaultLat: number,
  defaultLng: number,
  defaultAddress?: string,
  defaultMarkers?: Array<{
    lat: number,
    lng: number,
    name?: string,
    key: string,
  }>,
  id: string,
  onMapLoad?: (map: any) => void,
  minHeight?: number,
  // isMapPointer: boolean,
  onChange: (value: any) => void,
  isInputFields?: boolean,
  className?: string,
  error?: string,
  isHideByDefault?: boolean,
  mapServiceEndpoint?: string,
  filterType?: string,
  label?: string,
  // value?: { lat: number, lng: number, address: string },
  value?: string,
  valueLat?: number,
  valueLng?: number,
  valueMarkers?: Array<{
    lat: number,
    lng: number,
    name?: string,
    key: string,
  }>,
};

type MapState = {
  elementId: string,
  lat?: number,
  lng?: number,
  address: string,
  addressParts: Object,
  isHideByDefault?: boolean,
  filterType?: string,
  marker: Object,
  markers: Object[],
  loaded: boolean,
};

const filterTypes = {
  COORDINATES_TYPE: "coordinates",
  SEARCH_TYPE: "search",
  URL_TYPE: "url",
};

const MapElement = styled.div(({ minHeight }) => ({
  minHeight: minHeight || "400px",
  width: "100%",
}));

class LocationInput extends Component<MapProps, MapState> {
  static defaultProps = {
    isHideByDefault: false,
    onMapLoad: undefined,
    label: undefined,
    error: "",
    className: "",
    minHeight: 400,
    filterType: undefined,
    defaultAddress: "",
    defaultMarkers: [],
    // value: { lat: null, lng: null, address: null },
    value: undefined,
    valueLat: undefined,
    valueLng: undefined,
    valueMarkers: [],
    mapServiceEndpoint: google("maps"),
    isInputFields: true,
  };

  initialLat = 25.2048;

  initialLng = 55.2708;

  constructor(props: MapProps) {
    super(props);
    this.state = {
      elementId: "",
      address: props.value || props.defaultAddress || "",
      addressParts: {},
      lat: props.valueLat || props.defaultLat || undefined,
      lng: props.valueLng || props.defaultLng || undefined,
      markers: props.valueMarkers || props.defaultMarkers || [],
      // @todo: inverse should impact the default toggle behaviour, i.e. isShowByDefault as a prop and false as fallback
      isHideByDefault: props.isHideByDefault || true,
      filterType: props.filterType || filterTypes.SEARCH_TYPE,
      marker: undefined,
      loaded: true,
    };

    this.referenceMarkers = [];
    this.activeMarker = null;
  }

  componentDidMount() {
    const { id, mapServiceEndpoint, isHideByDefault } = this.props;

    this.setState({
      elementId: id || `${Math.floor(Math.random() * Math.floor(100000))}`,
      isHideByDefault: isHideByDefault || false,
    });

    // load map api script
    // on load, initializes location which in return initializes map
    if (!window.google) {
      const s = document.createElement("script");
      s.type = "text/javascript";
      s.src = mapServiceEndpoint || "";
      const x = document.getElementsByTagName("script")[0];
      if (x && x.parentNode) {
        x.parentNode.insertBefore(s, x);
      } else {
        document.getElementsByTagName("head")[0].appendChild(s);
      }
      s.addEventListener("load", () => {
        // this.onScriptLoad(this.initMap);
        this.initLocation(this.initMap);
      });
    } else {
      // this.onScriptLoad(this.initMap);
      this.initLocation(this.initMap);
    }
  }

  // addressing, if markers as passed by __passProps
  componentDidUpdate(prevProps) {
    const {
      valueMarkers,
      valueLng: _valueLng,
      valueLat: _valueLat,
      isHideByDefault,
    } = this.props;
    const {
      valueMarkers: prevValueMarkers,
      valueLat: _prevValueLat,
      valueLng: _prevValueLng,
      isHideByDefault: prevIsHideByDefault,
    } = prevProps;
    const valueLng = LocationInput.getCoordValueFromProp(_valueLng);
    const valueLat = LocationInput.getCoordValueFromProp(_valueLat);
    const prevValueLng = LocationInput.getCoordValueFromProp(_prevValueLng);
    const prevValueLat = LocationInput.getCoordValueFromProp(_prevValueLat);
    if (prevIsHideByDefault !== isHideByDefault) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        isHideByDefault,
      });
    }
    if (prevValueLat !== valueLat || prevValueLng !== valueLng) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState(
        {
          lat: LocationInput.getCoordValueFromProp(valueLat),
          lng: LocationInput.getCoordValueFromProp(valueLng),
        },
        this.updateMarker
      );
    }
    const markersNow = this.prepareMakers(valueMarkers);
    const markersPrev = this.prepareMakers(prevValueMarkers);
    (markersNow.keys || []).forEach(markerKey => {
      if ((markersPrev.keys || []).indexOf(markerKey) === -1) {
        this.setState(
          prevState => ({
            markers: [
              ...prevState.markers,
              ...((markersNow.values || {})[markerKey]
                ? [(markersNow.values || {})[markerKey]]
                : []),
            ],
          }),
          this.updateMarker
        );
      }
    });
  }

  static getCoordValueFromProp(value) {
    if (typeof value === "string") {
      return parseFloat(value);
    }
    if (typeof value === "object" && value) {
      if (value.valueLat) {
        return parseFloat(value.valueLat);
      }
      if (value.valueLng) {
        return parseFloat(value.valueLng);
      }
    }
    return value;
  }

  prepareMakers = (markers?: Array<Object> = []) => {
    const values = {};
    const keys = [];
    markers.forEach(marker => {
      if (marker.key && marker.lat && marker.lng) {
        Object.assign(values, {
          [marker.key]: marker,
        });
        keys.push(marker.key);
      }
    });

    return {
      values,
      keys,
    };
  };

  // initializing location, set the address (parts) to the states
  // on successful address set, init the maps
  // onSuccess: this.initMap
  initLocation = (onSuccess?: Function) => {
    const { address, lat, lng } = this.state;
    if (!navigator.geolocation) {
      this.handleNoGeolocation(true);
    }
    if (!window.google) {
      this.handleNoGeolocation(true);
    }
    if (address || (lat && lng)) {
      this.setAddress({ address, lat, lng }, onSuccess);
    } else {
      navigator.geolocation.getCurrentPosition(
        position => {
          const latLng = new window.google.maps.LatLng(
            position.coords.latitude,
            position.coords.longitude
          );
          this.setAddress({ lat: latLng.lat(), lng: latLng.lng() }, onSuccess);
        },
        () => {
          this.handleNoGeolocation(false);
        }
      );
    }
  };

  handleNoGeolocation = errorFlag => {
    let content = "Error: Your browser doesn't support geolocation.";
    if (errorFlag) {
      content = "Error: The Geolocation service failed.";
    }

    const options = {
      map: this.map,
      position: new window.google.maps.LatLng(25.2048, 55.2708),
      content,
    };
    // eslint-disable-next-line
    const info = new window.google.maps.InfoWindow(options);

    if (this.map) {
      this.map.setCenter(options.position);
      this.updateMarker();
    } else {
      this.initMap(true);
      this.updateMarker();
    }
  };

  setCurrentLocation = () => {
    this.initLocation(this.updateMarker);
    if (!navigator.geolocation) {
      this.handleNoGeolocation(true);
    }
    if (!window.google) {
      this.handleNoGeolocation(true);
    }
    navigator.geolocation.getCurrentPosition(
      position => {
        const latLng = new window.google.maps.LatLng(
          position.coords.latitude,
          position.coords.longitude
        );
        const address = { lat: latLng.lat(), lng: latLng.lng() };
        this.setAddress(address, () => {
          const { address: stateAddress, lat, lng } = this.state;
          this.updateMarker({ address: stateAddress, lat, lng });
        });
      },
      () => {
        this.handleNoGeolocation(false);
      }
    );
  };

  getCoordinateOrDefault = (lat, lng) => {
    if (!lat || !lng) {
      return {
        lat: this.initialLat,
        lng: this.initialLng,
      };
    }
    return {
      lat: typeof lat === "string" ? parseFloat(lat) : lat,
      lng: typeof lng === "string" ? parseFloat(lng) : lng,
    };
  };

  initMap = (noOnChange: ?boolean = false) => {
    const { address, lat, lng } = this.state;
    const { lat: latFloat, lng: lngFloat } = this.getCoordinateOrDefault(
      lat,
      lng
    );

    this.map = new window.google.maps.Map(this.mapRef, {
      center: {
        lat: latFloat,
        lng: lngFloat,
      },
      zoom: 17,
    });

    const { onMapLoad } = this.props;
    this.updateMarker({ address, lat, lng });
    this.map.setCenter({ lat, lng });
    if (onMapLoad) onMapLoad(this.map);
    // initial trigger on onChange
    if (!noOnChange) {
      this.onChange();
    }
  };

  setLocation = (address: AddressType) => {
    this.setAddress(address, this.onChange);
    return this.updateMarker(address);
  };

  onChange = () => {
    const { address, lat, lng, addressParts } = this.state;
    const { onChange } = this.props;
    if (onChange) {
      onChange({
        target: {
          value: {
            address,
            lat,
            lng,
            addressParts: {
              ...addressParts,
              search_string: address,
              latitude: lat,
              longitude: lng,
            },
          },
        },
      });
    }
  };

  updateLocation = () => {
    const { address, lat, lng } = this.state;
    this.setLocation({ address, lat, lng });
  };

  updateMarker = (address?: AddressType) => {
    let lat;
    let lng;
    if (!address) {
      ({ lat, lng } = this.state);
    } else {
      ({ lat, lng } = address);
    }

    const { lat: floatLat, lng: floatLng } = this.getCoordinateOrDefault(
      lat,
      lng
    );
    const { markers } = this.state;
    const { label } = this.props;

    this.clearMarkers();
    // this.clearMarker(() => {
    const marker = { lat: floatLat, lng: floatLng, name: label, key: label };
    this.setState(
      {
        marker,
        markers: markers.length ? [...markers] : [],
      },
      this.setMarker
    );
    // });

    // return marker;
  };

  setMarker = () => {
    const { marker: defaultMarker, markers } = this.state;
    const bounds = new window.google.maps.LatLngBounds();
    // const infoWindow = new window.google.maps.InfoWindow();

    this.clearMarkers();
    [{ ...defaultMarker, ...{ default: true } }, ...markers].forEach(marker => {
      // [...markers].forEach(marker => {
      const position = new window.google.maps.LatLng(marker.lat, marker.lng);
      bounds.extend(position);
      // create a custom marker image
      const icon = {};
      Object.assign(icon, {
        url: `https://maps.google.com/mapfiles/ms/icons/${marker.color ||
          "red"}-dot.png`,
      });
      const pinnedMark = new window.google.maps.Marker({
        position,
        map: this.map,
        draggable: !!(marker.default && marker.default === true),
        title: marker.name,
        icon,
      });

      if (marker.default && marker.default === true) {
        this.activeMarker = pinnedMark;
      } else {
        if (!this.referenceMarkers) this.referenceMarkers = [];
        this.referenceMarkers.push(pinnedMark);
      }
      // click handler
      window.google.maps.event.addListener(pinnedMark, "click", e => {
        // infoWindow.setContent(marker.name);
        // infoWindow.open(map, m);
        const newerLat = e.latLng.lat();
        const newerLng = e.latLng.lng();
        this.setAddress({ lat: newerLat, lng: newerLng }, this.updateLocation);
        if (marker.onClick && marker.onClick instanceof Function) {
          marker.onClick();
        }
      });
      // dragend handler
      window.google.maps.event.addListener(pinnedMark, "dragend", e => {
        const newerLat = e.latLng.lat();
        const newerLng = e.latLng.lng();
        this.setAddress({ lat: newerLat, lng: newerLng }, this.updateLocation);
        if (marker.onDragend && marker.onDragend instanceof Function) {
          marker.onDragend();
        }
      });

      // fit the markers
      // map.fitBounds(bounds);
    });

    const boundsListener = window.google.maps.event.addListener(
      this.map,
      "bounds_changed",
      () => {
        if (markers.length === 1) {
          this.map.setZoom(17);
        }
        window.google.maps.event.removeListener(boundsListener);
      }
    );
    // map.fitBounds(bounds);
    if (markers.length > 1) {
      this.map.fitBounds(bounds);
    }
  };

  clearMarkers = (onClearMarkers?: Function) => {
    if (Array.isArray(this.referenceMarkers) && this.referenceMarkers.length) {
      this.referenceMarkers.forEach(referenceMarker => {
        if (referenceMarker.setMap) referenceMarker.setMap(null);
      });
      this.referenceMarkers = [];
    }

    if (this.activeMarker && this.activeMarker.setMap) {
      this.activeMarker.setMap(null);
      this.activeMarker = null;
    }

    if (onClearMarkers) onClearMarkers();
  };

  clearMarker = (onClearMarker?: Function) => {
    if (this.activeMarker) {
      this.activeMarker.setMap(null);
    }
    if (onClearMarker) onClearMarker();
  };

  getAddressParts = (result: Object) =>
    addressComponentExtractor(result.address_components || []);

  setAddress = (location: Object, onSetAddress?: Function) => {
    if (!window.google) return;

    const geocoder = new window.google.maps.Geocoder();
    // location search string has priority over lat and lng
    if (!(location.lat && location.lng) && location.address) {
      geocoder.geocode({ address: location.address }, (results, status) => {
        if (
          status === ((window.google.maps || {}).GeocoderStatus || {}).OK &&
          results[0]
        ) {
          const result = results[0];
          this.setState(
            {
              addressParts: this.getAddressParts(result),
              address: result.formatted_address,
              lat: ((result.geometry || {}).location || {}).lat(),
              lng: ((result.geometry || {}).location || {}).lng(),
            },
            () => {
              this.refreshComponents();
              if (onSetAddress) onSetAddress();
            }
          );
        }
      });
    }

    // otherwise
    if (location.lat && location.lng) {
      const latLng = new window.google.maps.LatLng(location.lat, location.lng);
      geocoder.geocode({ location: latLng }, (results, status) => {
        if (
          status === ((window.google.maps || {}).GeocoderStatus || {}).OK &&
          results[0]
        ) {
          const result = results[0];
          this.setState(
            {
              addressParts: this.getAddressParts(result),
              address: result.formatted_address,
              lat: location.lat,
              lng: location.lng,
            },
            () => {
              this.refreshComponents();
              if (onSetAddress) onSetAddress();
            }
          );
        }
      });
    }
    // @todo: needs an improvement
  };

  onScriptLoad = (cb?: Function) => {
    this.initLocation(cb);
  };

  onFilterChange = e => {
    if (((e || {}).target || {}).value) {
      this.setState({
        filterType: (e.target.value || {}).value || e.target.value,
      });
    }
  };

  toggleMap = () => {
    const { isHideByDefault } = this.state;
    this.setState({
      isHideByDefault: !isHideByDefault,
    });
  };

  refreshComponents = () => {
    this.setState(
      {
        loaded: false,
      },
      () => {
        this.setState({ loaded: true });
      }
    );
  };

  onLatLngChange = (address?: Object) => {
    if (address && address.lat && address.lng) {
      this.setState(
        {
          lat: address.lat,
          lng: address.lng,
          markers: [],
        },
        () => {
          this.updateMarker();
          this.setAddress(address, this.onChange);
        }
      );
    }
  };

  map: any;

  mapRef: ?HTMLDivElement;

  activeMarker: ?Object;

  referenceMarkers: ?Array<any>;

  render() {
    const {
      elementId,
      filterType,
      marker,
      lat,
      lng,
      address,
      isHideByDefault,
      loaded,
    } = this.state;

    const { className, error, minHeight, isInputFields } = this.props;

    return (
      <>
        {isInputFields ? (
          <div className="map-filters-bar">
            <div className="form-map-control-filter">
              <Options
                name="filteration-options"
                onChange={this.onFilterChange}
                value={filterType}
              />
            </div>
            {filterType === filterTypes.COORDINATES_TYPE ? (
              <CoordinatesFields
                elementId={elementId}
                defaultLat={lat}
                defaultLng={lng}
                onSubmit={this.onLatLngChange}
              />
            ) : null}
            {loaded && filterType === filterTypes.SEARCH_TYPE && this.map ? (
              <SearchField
                onSubmit={this.onLatLngChange}
                marker={marker}
                address={address}
                elementId={elementId}
                map={this.map}
              />
            ) : null}
            {filterType === filterTypes.URL_TYPE ? (
              <URLField onSubmit={this.onLatLngChange} />
            ) : null}
            <CurrentLocationButton
              onSubmit={this.setCurrentLocation}
              map={this.map}
            />
            <div className="input-group-prepend filter-map-icon">
              <InputGroupText
                className="input-group-append"
                onClick={this.toggleMap}
              >
                <MdMap color={isHideByDefault ? "#3d70b2" : "grey"} />
              </InputGroupText>
            </div>
          </div>
        ) : null}
        <MapElement
          className={`${className || ""} ${
            error !== "" ? "is-invalid" : ""
          } form-control-map ${isHideByDefault ? "hide-map" : ""}`}
          ref={el => {
            this.mapRef = el;
            return null;
          }}
          minHeight={minHeight}
        />
      </>
    );
  }
}

export default FieldWrapper(LocationInput);
