import React, { type ComponentType } from "react";
import { type Schema } from "@swan/form-renderer"; //eslint-disable-line
import { withLayout, type Layout } from "@swan/themer";
import FormRenderer from "@swan/form-renderer"; //eslint-disable-line

type Props = {
  object: string,
  type: string,
  ServiceFactory: (object: string, data: Object) => Object,
  layout: Layout,
  id: any,
  record: any,
  websocketSubscribe: (id: number, object: string) => void,
  websocketUnsubscribe: (id: number, object: string) => void,
  FormComponent: ComponentType<*>,
  isNew: boolean,
};

type State = {};

class DynamicForm extends React.Component<Props, State> {
  static defaultProps = {};

  state = {};

  componentDidMount() {
    const { websocketSubscribe, object, isNew, record, id } = this.props;
    if (
      websocketSubscribe &&
      !isNew &&
      !(typeof id === "string" && id.charAt(0) === "n")
    ) {
      websocketSubscribe(record && record.id, object);
    }
  }

  componentWillUnmount(): void {
    const { websocketUnsubscribe, object, isNew, record, id } = this.props;
    // TODO unsubscribe also when user close the page not killing the component.
    if (
      websocketUnsubscribe &&
      !isNew &&
      !(typeof id === "string" && id.charAt(0) === "n")
    ) {
      websocketUnsubscribe(record && record.id, object);
    }
  }

  render() {
    const { FormComponent } = this.props;

    const Component = FormComponent || FormRenderer;
    return <Component {...this.props} />;
  }
}

export default withLayout(DynamicForm);
