import React, { Component } from "react";
import styled from "styled-components";
import { map } from "lodash";
import { LoadMore, Loading } from "@swan/themer";

// components

// types
import {
  type ServiceFactoryType,
  type ButtonsType,
} from "../../../../../types";

type Props = {
  object: string,
  service: Object,
  listId: string,
  CardRow: Object,
  ServiceFactory: ServiceFactoryType,
  buttons?: ButtonsType,
  selectedRecord?: { id: number },
  cardClickHandler?: (data: {}) => void,
  registerSaveFunction: Function,
  registerDeleteFunction: Function,
  goBack: Function,
  form: Object,
  cardViewForm: Object,
  listMode: string,
  // noEdit?: boolean,
  editMode: string,
  useLocalData: boolean,
  data: ?Array<Object>,
  reloadRecord?: boolean,
  isLoadingMore?: boolean,
  isFetching?: boolean,
  loadRecords: (
    page: number,
    filter?: any,
    pageSize?: number,
    sorting?: any,
    previousData: ?Array<Object>
  ) => void,
  loadMoreEnabled?: boolean,
  currentPage: number,
  readOnly: boolean,
};

type State = {};

const Cards = styled.div({
  cursor: "pointer",
});

class CardList extends Component<Props, State> {
  static defaultProps = {
    buttons: [],
    selectedRecord: {},
    cardClick: false,
    cardClickHandler: null,
    // noEdit: false,
    reloadRecord: false,
    isLoadingMore: false,
    loadMoreEnabled: true,
    isFetching: true,
  };

  mounted = false;

  componentDidMount() {
    const { listId } = this.props;

    this.mounted = true;

    this.refresh();

    if (listId) {
      window.addEventListener(`${listId}::refresh`, () => {
        this.refresh();
      });
    }
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  refresh = () => {
    const { useLocalData, loadRecords } = this.props;
    if (useLocalData) {
      return;
    }
    // TODO fix this in the future, not sure what is the cause, assuming it is race condition
    setTimeout(() => {
      loadRecords(1);
    }, 30);
  };

  getMore = () => {
    const { data, loadRecords, currentPage } = this.props;
    loadRecords(currentPage + 1, undefined, undefined, undefined, data);
  };

  render() {
    const {
      buttons,
      selectedRecord,
      editMode,
      registerSaveFunction,
      registerDeleteFunction,
      cardViewForm,
      form,
      service,
      data,
      reloadRecord,
      isLoadingMore,
      loadMoreEnabled,
      goBack,
      object,
      ServiceFactory,
      listId,
      cardClickHandler,
      CardRow,
      isFetching,
      listMode,
      readOnly,
    } = this.props;
    return (
      <React.Fragment>
        {data && data.length > 0 && (
          <Cards>
            {map(data, (rowData, idx: number) => (
              <CardRow
                selectedRecord={selectedRecord}
                registerSaveFunction={registerSaveFunction}
                idx={idx}
                key={`${object}_list_${rowData.id}`}
                buttons={buttons}
                rowData={rowData}
                form={form}
                listMode={listMode}
                object={object}
                goBack={goBack}
                listId={listId}
                editMode={editMode}
                service={service}
                registerDeleteFunction={registerDeleteFunction}
                reloadRecord={reloadRecord}
                cardClickHandler={cardClickHandler}
                cardViewForm={cardViewForm}
                ServiceFactory={ServiceFactory}
                readOnly={readOnly}
              />
            ))}
            {loadMoreEnabled && !isLoadingMore && (
              <LoadMore loadMore={this.getMore} isLoading={isLoadingMore} />
            )}
          </Cards>
        )}
        {isFetching && <Loading />}
      </React.Fragment>
    );
  }
}

export default CardList;
