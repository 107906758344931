import * as React from "react";
import { WorkflowActionsButtons } from "@swan/workflow";

import Field from "../partials/Field";
import { RendererContext } from "../../Main";

// types
import { type ElementProps } from "../types";

type Props = ElementProps & {
  object: string,
  addButtons: Function, // Function to add buttons to the top bar
  removeButtons: Function, // Function to remove buttons from the top bar
};

const Buttons = ({
  schema,
  data,
  object,
  addButtons,
  removeButtons,
}: Props) => (
  <WorkflowActionsButtons
    {...schema.options || {}}
    record={data}
    object={object}
    setButtons={
      (schema.options || {}).inlineWorkflowButtons && addButtons
        ? undefined
        : (buttons: Array<Object>) => {
            // Add the buttons to the top bar
            if (typeof addButtons === "function") {
              addButtons(buttons);
            }
          }
    }
    removeButtons={
      (schema.options || {}).inlineWorkflowButtons && removeButtons
        ? undefined
        : (buttonIds: Array<Object>) => {
            // Add the buttons to the top bar
            if (typeof removeButtons === "function") {
              removeButtons(buttonIds);
            }
          }
    }
  />
);

const WorkflowButtons = (props: ElementProps) => (
  <RendererContext.Consumer>
    {({ object, addButtons, removeButtons }) => (
      <Buttons
        {...props}
        object={object}
        addButtons={addButtons}
        removeButtons={removeButtons}
      />
    )}
  </RendererContext.Consumer>
);

export default Field(WorkflowButtons);
