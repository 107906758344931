// @flow
import React from "react";
import { ThemeProvider } from "styled-components";
// import { debounce } from "lodash";

// components
import Main from "./components/Main";
import ModifiersGate from "./components/ModifiersGate";
import RendererUIController from "./components/RendererUIController";
import Rulified from "./components/Rulified";

// utils
import validator from "./utils/validator";

// themes
import themes from "./themes";

// types
import { type FormRendererProps } from "./types";

type FormRendererState = {
  // schema: Schema,
};

// Note: objectAttrib is not in plural form, sounds like DOS ATTRIB
// Assume that objectAttrib receives array of ojbects

export default class extends React.Component<
  FormRendererProps,
  FormRendererState
> {
  componentDidMount() {
    const { onChange, formId, registerBeforeSaveCallback } = this.props;
    if (onChange) {
      onChange("_form", formId);
    }
    if (registerBeforeSaveCallback) {
      registerBeforeSaveCallback(this.beforeSave);
    }
  }

  beforeSave = (values: Object) => {
    const { formValidationSchema } = this.props;
    if (!formValidationSchema) {
      return true;
    }
    return validator(values, formValidationSchema);
  };

  render() {
    const {
      theme: themeName,
      customTheme,
      schema: schemaPropsProvided,
      rules,
      data,
      object,
      onChange,
      onBatchChange,
      error,
      loading,
      ServiceFactory,
      setValue,
      ...restProps
    } = this.props;

    return (
      <ThemeProvider
        theme={{
          ...(themes || {})[themeName || "default"],
          ...(customTheme || {}),
        }}
      >
        <RendererUIController {...this.props}>
          <ModifiersGate {...this.props}>
            {modifiersProps => (
              <Rulified
                schema={schemaPropsProvided}
                data={data}
                rules={rules}
                updateData={setValue}
              >
                {ruledProps => (
                  <Main
                    schema={ruledProps.schema}
                    {...{
                      rules,
                      data: data || {},
                      object: object || "",
                      onChange,
                      onBatchChange,
                      error,
                      loading,
                      ServiceFactory,
                      ...restProps,
                    }}
                    {...modifiersProps}
                  />
                )}
              </Rulified>
            )}
          </ModifiersGate>
        </RendererUIController>
      </ThemeProvider>
    );
  }
}
