import Axios from "axios";
import { ServiceGenerator } from "@swan/api";
import React, { useState, useEffect } from "react";
// $FlowFixMe
import { BrandCard } from "@swan/themer/src/components/Dashboard/Widgets";
import Field from "../partials/Field";

const BrandCardRenderer = ({
  propsData,
  schema,
  data,
}: {
  propsData: any,
  schema: any,
  data: any,
}) => {
  const {
    options: { objectName, customServicePath, title, backgroundColor } = {},
  } = schema;
  const [cardData, setData] = useState(propsData);
  useEffect(() => {
    const Service = ServiceGenerator(
      Axios,
      customServicePath || `statistics/brand-card/${objectName}`
    );
    let service;
    if (typeof Service === "object") {
      service = Service; // instantiated, so
    } else {
      service = new Service(); // construct, otherwise
    }
    if (data) {
      // handle leafable_id special case
      const objectId = data.id || data._leafable_id; // eslint-disable-line
      service.get(`${objectName}\\${objectId}`).then(response => {
        setData(response);
      });
    }
  }, []);

  return (
    <BrandCard
      data={cardData}
      title={title}
      backgroundColor={backgroundColor}
    />
  );
};

export default Field(BrandCardRenderer);
