export default {
  uid: "singleLine",
  attributeId: "",
  formId: "",
  pencilDef: {
    title: "Single Line",
    group: "FIELD",
    handleIcon: "FaFont",
    styles: {},
  },
  options: {
    type: "singleLine",
    limits: {
      min: 2,
      max: 255,
    },
  },
};
