import Doughnut, { type DoughnutType } from "./Doughnut";
import Bar, { type BarType } from "./Bar";
import CounterStatic from "./CounterStatic";
import BrandCard, { type BrandCardType } from "./BrandCard";
import HorizontalGraphs from "./HorizontalGraphs";
import Graph from "./Graph";
import Table from "./Table";
import StackedBar from "./StackedBar";
import SimpleTable from "./SimpleTable";

export {
  Doughnut,
  CounterStatic,
  Bar,
  StackedBar,
  BrandCard,
  Table,
  SimpleTable,
  Graph,
  HorizontalGraphs,
};

export type BrandCardPropType = BrandCardType;
export type BarPropType = BarType;
export type DoughnutPropType = DoughnutType;

export default {
  Doughnut,
  Bar,
  StackedBar,
  CounterStatic,
  BrandCard,
  Table,
  SimpleTable,
  Graph,
  HorizontalGraphs,
};
