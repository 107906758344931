import * as React from "react";
import Axios from "axios";
import styled from "styled-components";
import { ServiceGenerator } from "@swan/api";
import {
  Bar,
  type BarPropType,
  // $FlowFixMe
} from "@swan/themer/src/components/Dashboard/Widgets";
import Field from "../partials/Field";

const Container = styled.div``;

type Props = {
  schema: Object,
  data: Object,
};

type State = {
  barData: BarPropType,
};

class ChartBar extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    const { schema } = props;
    const { options: { objectName, customServicePath } = {} } = schema;

    const Service = ServiceGenerator(
      Axios,
      customServicePath || `statistics/bar/${objectName}`
    );
    if (typeof Service === "object") {
      this.service = Service; // instantiated, so
    } else {
      this.service = new Service(); // construct, otherwise
    }

    this.state = {
      barData: {},
    };
  }

  componentDidMount() {
    this.loadAndPrepareData();
  }

  loadAndPrepareData = () => {
    const { data, schema } = this.props;
    const { options: { objectName } = {} } = schema;
    // handle leafable_id special case
    const objectId = data.id || data._leafable_id; // eslint-disable-line
    this.service.get(`${objectName}\\${objectId}`).then(barData => {
      this.setState({
        barData,
      });
    });
  };

  service: Object;

  render() {
    const { barData } = this.state;
    return (
      <Container>
        <Bar data={barData} />
      </Container>
    );
  }
}

export default Field(ChartBar);
