// @flow
/* eslint-disable no-underscore-dangle, consistent-return */
import { ObjectService } from "@swan/api"; // eslint-disable-line
import {
  objectDataCascade as objectDataCascadeUtil,
  prepareCascader as prepareCascaderUtil,
} from "./cascaderUtils";
import { resourceFieldTypes } from "./provideResourceTemplate";

type CascadeType = {
  __type: string,
  __relationName?: string,
  label: string,
  value: string,
  children: Array<
    | {|
        __type: "attribute",
        attributeId: number,
        label: string,
        value: string,
        type: string,
      |}
    | CascadeType
  >,
};

// This method provides
// Data structure for cascading Object and its attributes
// i.e. filters
// attributes: [{type: ["singleSelect"]}]
// relationsihps: [{type: ["Polymorphic", "OneToMany"]}]
// eslint-disable-next-line camelcase
export const prepareCascader__DEPRECATED = (data: Object) => {
  const cascade: CascadeType = {
    __type: "object",
    label: data.display_name,
    value: data.name,
    key: data.key,
    children: [],
  };

  ((data || {}).attributes || []).forEach(
    ({ id: attributeId, label, name: value, type }) => {
      cascade.children.push({
        __type: "attribute",
        attributeId,
        label,
        value,
        type, // @todo type shouldn't be pencil definition type,
        // instead, this should be replaced by storage type i.e. string, number
      });
    }
  );

  return cascade;
};

// Get all objects defintions
export const getObjectsDefinitions = async () => {
  const service = new ObjectService();
  const objectsData = await service.get();
  return objectsData;
};

// This method provides
// Structured cascade data for given object(s) or otherwise all objects
export const objectDataCascade = async (
  object: string | Array<string> | Array<Object> = ""
) => {
  const objectsDataCascaded = [];
  let promises = [];
  if (typeof object === "string") {
    if (object === "") {
      const objectsFromService = await ObjectService.get();
      const allObjects = (objectsFromService || {}).data
        ? objectsFromService.data
        : objectsFromService;
      promises = [
        ...promises,
        ...allObjects.map(({ name }) =>
          objectDataCascadeUtil(name).then(cascadedReady => {
            objectsDataCascaded.push(cascadedReady);
          })
        ),
      ];
    } else {
      promises = [
        ...promises,
        ...[object].map(o =>
          objectDataCascadeUtil(o).then(cascadedReady => {
            objectsDataCascaded.push(cascadedReady);
          })
        ),
      ];
    }
  } else if (Array.isArray(object)) {
    promises = [
      ...promises,
      ...object.map(o => {
        if (typeof o === "string") {
          // if items of array are type of string
          return objectDataCascadeUtil(o).then(cascadedReady => {
            objectsDataCascaded.push(cascadedReady);
          });
        }

        // if items of array are object and it has property name in it
        if (typeof o === "object" && (o || {}).name) {
          return objectDataCascadeUtil(o.name, {}, [], {
            __type: "object",
            __key: o.key,
          }).then(cascadedReady => {
            objectsDataCascaded.push(cascadedReady);
          });
        }

        return Promise.resolve();
      }),
    ];
  } else {
    return [];
  }

  await Promise.all(promises);
  return objectsDataCascaded;
};

// @deprecated previous implementation same above
// eslint-disable-next-line camelcase
export const objectDataCascade__DEPRECATED = async (
  object: string | Array<string> | Array<Object> = ""
) => {
  let objectData;
  const objectsDataCascaded = [];

  if (typeof object === "string") {
    if (object === "") {
      const objectsFromService = await ObjectService.get();
      const allObjects = (objectsFromService || {}).data
        ? objectsFromService.data
        : objectsFromService;
      allObjects.forEach(data => {
        objectsDataCascaded.push(prepareCascader__DEPRECATED(data));
      });
    } else {
      objectData = await ObjectService.getObjectByName(object);
      return [prepareCascader__DEPRECATED(objectData)];
    }
  } else {
    // we received array of objects
    objectData = [];
    const promises = (
      object.map(o => {
        // if items of array are type of string
        if (typeof o === "string") {
          return ObjectService.getObjectByName(o).then(dt => {
            objectsDataCascaded.push(prepareCascader__DEPRECATED(dt));
          });
        }
        // if items of array are object and it has property name in it
        if (typeof o === "object" && (o || {}).name) {
          return ObjectService.getObjectByName(o.name).then((dt: Object) => {
            const d = {
              ...dt,
              key: o.key || dt.name, // If no key is provided, default to object name
            };
            objectsDataCascaded.push(prepareCascader__DEPRECATED(d));
          });
        }
        // if otherwise
        return false;
      }) || []
    )
      // filter false values
      .filter(oo => oo !== false);
    await Promise.all(promises);
  }

  return objectsDataCascaded;
};

// This utility provides structured data from selected cascades
export const cascadeToData = (
  selectedOptions: Array<Object>,
  data: Object = {}
) => {
  while (selectedOptions.length) {
    const currentData = data;
    const currentSelection: Object = selectedOptions
      .splice(0, 1)
      .find(o => Object.keys(o).indexOf("__type") !== -1);

    if (currentSelection.__type === "object") {
      currentData.object = currentSelection.value;
      currentData.key = currentSelection.key || "record";
    }

    if (currentSelection.__type === "relatedObject") {
      const isCollection =
        currentSelection.__isCollection &&
        currentSelection.__isCollection === true;
      currentData.relatedObject = {};
      Object.assign(
        currentData.relatedObject,
        {
          object: currentSelection.value,
          key: currentSelection.key || "record",
        },
        isCollection
          ? {
              relationName: currentSelection.__relationName,
            }
          : {}
      );
      if (!isCollection)
        cascadeToData(selectedOptions, currentData.relatedObject);
    }

    if (currentSelection.__type === "attribute") {
      currentData.attributeId = currentSelection.attributeId;
      currentData.attributeName = currentSelection.value;
      currentData.attributeType = currentSelection.type;
    }
  }

  return { data };
};

export const objectDataDefaultSelection = (
  ObjectData: Array<Object>,
  selectedOptions: Array<Object> = [],
  cascaded: Array<string> = []
) => {
  const currentSelection = selectedOptions;
  const currentCascaded = cascaded;

  if (
    Array.isArray(ObjectData) &&
    ObjectData.length &&
    ObjectData.length === 1
  ) {
    currentSelection.push(ObjectData[0]);
    if (ObjectData[0].value) currentCascaded.push(ObjectData[0].value);

    if (ObjectData[0].children) {
      objectDataDefaultSelection(
        ObjectData[0].children,
        currentSelection,
        currentCascaded
      );
    }
  }

  return { selected: currentSelection, cascaded: currentCascaded };
};

// This method reverses the above
// return the selection from structured data cascade
export const resourceDataToCascade = (
  resourceData: Object,
  cascaded: Array<string> = [],
  fieldTypes: Array<string> = [],
  attributeIds: Array<number> = []
) => {
  let data = resourceData;
  let anchors;
  let collection;
  if (
    resourceData.type &&
    resourceData.type === "ATTRIBUTE" &&
    resourceData.data
  ) {
    ({ data } = resourceData);
  }
  if (data) {
    const currentSelection = cascaded;
    const currentAttributes = fieldTypes;
    const attributeIdsNow = attributeIds;

    const dataKeys = Object.keys(data);

    if (dataKeys.indexOf("object") !== -1) {
      currentSelection.push(data.object);
    }

    if (dataKeys.indexOf("relatedObject") !== -1) {
      if (
        (data.relatedObject || {}).relationName &&
        !(data.relatedObject || {}).attributeName
      ) {
        currentSelection.push(data.relatedObject.relationName);
        fieldTypes.push(resourceFieldTypes.COLLECTION);
        anchors = ["#{", "}"];
        if ((data.relatedObject || {}).object)
          collection = data.relatedObject.object;
      } else {
        resourceDataToCascade(
          data.relatedObject,
          currentSelection,
          currentAttributes,
          attributeIdsNow
        );
      }
    }

    if (dataKeys.indexOf("attributeName") !== -1) {
      currentSelection.push(data.attributeName);
    }

    if (dataKeys.indexOf("attributeType") !== -1) {
      fieldTypes.push(data.attributeType);
    }

    if (dataKeys.indexOf("attributeId") !== -1) {
      attributeIds.push(data.attributeId);
    }
  }

  return {
    cascaded,
    fieldTypes,
    attributeIds,
    anchors: anchors || ["!{", "}"],
    collection,
  };
};

export default {
  objectDataCascade,
  prepareCascader: prepareCascaderUtil,
  cascadeToData,
  objectDataDefaultSelection,
  resourceDataToCascade,
};
