import React, { useEffect, useState } from "react";
import { Service } from "@swan/api";
import { FaPhone } from "react-icons/fa/index";
import styled from "styled-components";
import Field from "./partials/Field";

export const Container = styled.div`
  padding: 0 0.5rem;
  h5 {
    font-size: 1rem;
  }
`;

const LastCall = (props: any) => {
  const [call, setCall] = useState(undefined);

  useEffect(() => {
    const { object, data } = props;

    const leafService = new Service(
      `/folio/statistics/latest-activity/${object}/call/${data.id}`
    );

    leafService
      .get()
      .then(response => {
        if (response) setCall(response);
      })
      .catch(error => {
        // eslint-disable-next-line no-console
        console.error(error);
      });
  }, []);

  return (
    <Container>
      <div className="d-flex flex-column mb-2">
        <h5>Last Call</h5>
        <div className="d-flex align-items-baseline mt-2">
          <span className="brand-01--color">
            <FaPhone />
          </span>
          <div className="mx-2 ui-01--d70--color">
            {call ? (
              <>
                <p className="stats-text-sm font-italic">{call.start_date}</p>
                <p className="stats-text-sm">
                  Placed by
                  <span className="stats-text-black ml-1">
                    {call.owner_name}
                  </span>
                </p>
              </>
            ) : (
              <p className="stats-text-sm">There are no calls placed to date</p>
            )}
          </div>
        </div>
      </div>
    </Container>
  );
};

export default Field(LastCall);
