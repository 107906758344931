import config from "@swan/config"; // eslint-disable-line
import AxiosPack from "axios";
import Service from "./service";

const ServiceGenerator = (ProvidedAxios: any, prefix: string = "") => {
  if (ProvidedAxios) {
    AxiosPack.defaults = ProvidedAxios.defaults;
  }
  class NewService extends Service {
    prefix = prefix;

    constructor(customPrefix: ?string) {
      super(customPrefix);
      this.prefix = customPrefix || prefix || "";
    }
  }

  return NewService;
};

export default ServiceGenerator;
