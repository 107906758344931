import * as React from "react";
import styled from "styled-components";

import type { ElementProps } from "./types";

const Container = styled.div({});

const DisplayText = ({ schema }: ElementProps) => {
  const html = (schema.options || {}).allowHtml;
  const content = (schema.options || {}).displayContent || "";
  if (html) {
    return (
      <Container
        className="form-group-alike"
        dangerouslySetInnerHTML={{ __html: content }}
      />
    );
  }

  return (
    <Container className="form-group-alike">
      <p>{content}</p>
    </Container>
  );
};

export default DisplayText;
