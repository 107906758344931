// @flow
import { resourceDataToCascade } from "./provideObjectCascade";

// type
import { type Resource /* , type Widgets */ } from "../flowTypes";

export default (resource: Resource) => {
  const { type, dataType, data } = resource;

  const processedResource: Object = {
    key: undefined,
    widget: undefined,
    fieldTypes: undefined,
    cascaded: undefined,
    inputName: undefined,
    inputValue: undefined,
    inputType: undefined,
  };

  switch (type) {
    case "ATTRIBUTE":
      {
        const { cascaded, fieldTypes, anchors } = resourceDataToCascade(data);
        Object.assign(processedResource, {
          key: data.key || "record",
          widget: "CASCADER",
          fieldTypes,
          cascaded,
          inputName: data.attributeName || "",
          inputValue: `${
            anchors && anchors[0] ? anchors[0] : "!{"
          }${cascaded.join(".")}${anchors && anchors[1] ? anchors[1] : "}"}`,
          inputType: data.attributeType || "", // ???
        });
      }
      break;
    case "CONSTANTS":
      if (["TEXT", "NUMBER", "EMAIL"].indexOf(dataType) !== -1) {
        Object.assign(processedResource, {
          key: data.key || "",
          widget: "INPUT",
          inputName: data.key || "",
          inputValue: data.input || "",
          inputType: dataType,
        });
      }
      if (dataType === "ARRAY") {
        Object.assign(processedResource, {
          key: data.key || "",
          widget: "ARRAY",
          inputName: data.key || "",
          inputValue: data.input || "",
          inputType: dataType,
        });
      }
      break;
    case "CONDITION":
      Object.assign(processedResource, {
        key: data.key || "",
        widget: "CONDITIONS_EDITOR",
        inputName: data.key || "",
        inputValue: data.input || "",
        inputType: dataType,
      });
      break;
    default:
      Object.assign(processedResource, {});
      break;
  }

  return processedResource;
};
