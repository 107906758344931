// @flow
import { SchemaWalker, objectWalker } from "@swan/form-designer/utils"; // eslint-disable-line

// type
import { type Schema } from "@swan/form-renderer"; // eslint-disable-line
import {
  SHOW_FIELD,
  HIDE_FIELD,
  HIDE_LAYOUT,
  REQUIRE_FIELD,
  SET_OPTIONS,
  READONLY_FIELDS,
  READONLY_FIELD,
} from "./verbs";

/**
 * This method accepts two parameters
 * i. Object which contains schema which is effected by rules (cooked schema) and form data
 * ii. initialSchema which comes from form designer, fresh without cooking
 */

// eslint-disable-next-line no-unused-vars
export default (verb: string, template: string) => (
  {
    schema,
    data,
    updateData,
  }: {
    schema: Schema,
    data: Object,
    updateData: Function,
  },
  { record }: { record: Object }
) => {
  if (
    [
      SHOW_FIELD,
      HIDE_FIELD,
      HIDE_LAYOUT,
      REQUIRE_FIELD,
      SET_OPTIONS,
      READONLY_FIELDS,
      READONLY_FIELD,
    ].indexOf(verb) !== -1
  ) {
    switch (verb) {
      case SHOW_FIELD:
        // @todo hide the field by default
        // when condition met show it
        objectWalker(
          schema,
          (
            value,
            objectKey,
            currentPath,
            nestedLevel,
            obj,
            parentKey,
            path
          ) => {
            if (
              value === ((data.resource || {}).data || {}).attributeName &&
              objectKey === "attributeName"
            ) {
              path.reduce((s, val, i) => {
                if (i === path.length - 1) {
                  let opts = {};
                  if (Object.keys(s[val]).indexOf("options") !== -1) {
                    opts = s[val].options;
                  }
                  Object.assign(s[val], {
                    options: {
                      ...opts,
                      hideField: false,
                    },
                  });
                }
                return s[val];
              }, schema);
            }
          }
        );
        break;
      case HIDE_FIELD:
        objectWalker(
          schema,
          (
            value,
            objectKey,
            currentPath,
            nestedLevel,
            obj,
            parentKey,
            path
          ) => {
            if (
              value === ((data.resource || {}).data || {}).attributeName &&
              objectKey === "attributeName"
            ) {
              path.reduce((s, val, i) => {
                const currentS = s;
                if (i === path.length - 1) {
                  delete currentS[val];
                }
                return currentS[val];
              }, schema);
            }
          }
        );
        break;
      case HIDE_LAYOUT:
        {
          const { layouts: schemaLayoutBlueprint } = SchemaWalker(schema);
          const { layoutPath: actionLayoutPath } = data;
          if (actionLayoutPath.charAt(0) === "@") {
            if (
              Object.keys(schemaLayoutBlueprint).indexOf(actionLayoutPath) !==
              -1
            ) {
              let layoutPathParts = actionLayoutPath.split("/"); // .splice(0, 1);
              layoutPathParts.splice(0, 1);
              layoutPathParts = layoutPathParts.join("/properties/");
              layoutPathParts = [
                ...["properties"],
                ...layoutPathParts.split("/"),
              ];
              layoutPathParts.reduce((s, val, i) => {
                const currentS = s;
                if (i === layoutPathParts.length - 1) {
                  if (currentS[val]) delete currentS[val];
                }
                return currentS[val] ? currentS[val] : currentS;
              }, schema);
            }
          } else {
            objectWalker(
              schema,
              (
                value,
                objectKey,
                currentPath,
                nestedLevel,
                obj,
                parentKey,
                path
              ) => {
                if (
                  value === actionLayoutPath &&
                  (objectKey === "id" || objectKey === "formulaOutputName")
                ) {
                  path.reduce((s, val, i) => {
                    const currentS = s;
                    if (objectKey === "formulaOutputName") {
                      currentS[val].hideField = true;
                    } else if (i === path.length - 1) {
                      delete currentS[val];
                    }
                    return currentS[val];
                  }, schema);
                }
              }
            );
          }
        }
        break;
      case REQUIRE_FIELD:
        objectWalker(
          schema,
          (
            value,
            objectKey,
            currentPath,
            nestedLevel,
            obj,
            parentKey,
            path
          ) => {
            if (
              value === ((data.resource || {}).data || {}).attributeName &&
              objectKey === "attributeName"
            ) {
              path.reduce((s, val, i) => {
                if (i === path.length - 1) {
                  let opts = {};
                  if (Object.keys(s[val]).indexOf("options") !== -1) {
                    opts = s[val].options;
                  }
                  Object.assign(s[val], {
                    options: {
                      ...opts,
                      required: true,
                    },
                  });
                }
                return s[val];
              }, schema);
            }
          }
        );
        break;
      case SET_OPTIONS:
        objectWalker(
          schema,
          (
            value,
            objectKey,
            currentPath,
            nestedLevel,
            obj,
            parentKey,
            path
          ) => {
            if (
              value === ((data.resource || {}).data || {}).attributeName &&
              objectKey === "attributeName"
            ) {
              path.reduce((s, val, i) => {
                if (i === path.length - 1) {
                  let opts = {};
                  let items = [];
                  if (Object.keys(s[val]).indexOf("options") !== -1) {
                    opts = s[val].options;
                    if (
                      Object.keys(opts).indexOf("items") &&
                      Array.isArray(opts.items)
                    ) {
                      items = s[val].options.items.filter(item =>
                        data.setOptions && Array.isArray(data.setOptions)
                          ? data.setOptions.indexOf(item.value) !== -1
                          : false
                      );
                      // if value is found on record
                      if (
                        Object.keys(record).indexOf(value) !== -1 &&
                        record[value] !== undefined
                      ) {
                        // check if value is available inside the items
                        if (
                          !(items || []).find(
                            itm =>
                              itm.value === record[value] ||
                              itm.id === record[value]
                          )
                        ) {
                          updateData(value, undefined);
                        }
                      }

                      // if value not found in the record or value is undefined
                      if (
                        Object.keys(record).indexOf(value) === -1 ||
                        (Object.keys(record).indexOf(value) !== -1 &&
                          record[value] === undefined)
                      ) {
                        // if only one item is resulted, then set the value
                        if ((items || []).length === 1) {
                          updateData(value, items[0].value || items[0].id);
                        }
                      }
                    }
                  }
                  Object.assign(s[val], {
                    options: {
                      ...opts,
                      items,
                    },
                  });
                }
                return s[val];
              }, schema);
            }
          }
        );
        break;
      case READONLY_FIELDS:
        objectWalker(
          schema,
          (
            value,
            objectKey,
            currentPath,
            nestedLevel,
            obj,
            parentKey,
            path
          ) => {
            if (
              // value === ((data.resource || {}).data || {}).attributeName &&
              objectKey === "attributeName"
            ) {
              path.reduce((s, val, i) => {
                if (i === path.length - 1) {
                  let opts = {};
                  if (Object.keys(s[val]).indexOf("options") !== -1) {
                    opts = s[val].options;
                  }
                  Object.assign(s[val], {
                    options: {
                      ...opts,
                      readOnly: true,
                    },
                  });
                }
                return s[val];
              }, schema);
            }
          }
        );
        break;
      case READONLY_FIELD:
        objectWalker(
          schema,
          (
            value,
            objectKey,
            currentPath,
            nestedLevel,
            obj,
            parentKey,
            path
          ) => {
            if (
              value === ((data.resource || {}).data || {}).attributeName &&
              objectKey === "attributeName"
            ) {
              path.reduce((s, val, i) => {
                if (i === path.length - 1) {
                  let opts = {};
                  if (Object.keys(s[val]).indexOf("options") !== -1) {
                    opts = s[val].options;
                  }
                  Object.assign(s[val], {
                    options: {
                      ...opts,
                      readOnly: true,
                    },
                  });
                }
                return s[val];
              }, schema);
            }
          }
        );
        break;
      default:
        break;
    }
  }

  return schema;
};
