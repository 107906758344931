import textInput from "./textInput";
import numberInput from "./numberInput";
import emailInput from "./emailInput";
import checkbox from "./checkbox";
import textarea from "./textarea";
import selectSingle from "./selectSingle";
import singleOnWidget from "./singleOnWidget";

// custom data source for selectSingle items
import itemsDataSource from "./itemsDataSource";

// field and widget templates
export { default as FieldTemplate } from "./FieldTemplate";

export default {
  textInput,
  numberInput,
  emailInput,
  checkbox,
  textarea,
  selectSingle,
  itemsDataSource,
  singleOnWidget,
};
