// @flow
/* eslint-disable no-underscore-dangle */
import React, { Component, type ComponentType } from "react";
import Cascader from "rc-cascader";
import { FaArrowRight } from "react-icons/fa";

// utils
import {
  objectDataCascade,
  cascadeToData,
  objectDataDefaultSelection,
} from "../../../utils/provideObjectCascade";
import processResource from "../../../utils/processResource";

// type
import { type Resource } from "../../../flowTypes";

type Props = {
  id?: string,
  label?: string,
  object?: string,
  objectData?: Array<Object> | Promise<Object>,
  filteredAttributes?: Array<{ key: string, value: string }>,
  cascaded?: Array<string>,
  inputValue?: string,
  changeOnSelect?: boolean,
  onChange?: (resource: Resource | mixed) => void,
  onChangePreprocessor?: (Array<any>, Array<any>) => any,
  // resourceAccessor?: ((resource: Resource) => any) | string,
  isResourceToString?: boolean,
  components?: {
    input?: ComponentType<*>,
  },
};

type State = {
  objectData: Array<Object>,
  cascaded: Array<string>,
  isDirty: boolean,
};

class ObjectAttributes extends Component<Props, State> {
  static defaultProps = {
    id: undefined,
    label: undefined,
    object: undefined,
    objectData: undefined,
    filteredAttributes: undefined,
    disabledAttributes: undefined,
    cascaded: undefined,
    inputValue: "",
    onChange: undefined,
    onChangePreprocessor: undefined,
    // resourceAccessor: undefined,
    isResourceToString: false,
    changeOnSelect: false,
    components: undefined,
  };

  constructor(props: Props) {
    super(props);
    this.state = {
      objectData: [],
      cascaded: [],
      isDirty: false,
    };
  }

  componentDidMount() {
    const { objectData, object } = this.props;

    const dataFromProps = objectData;

    if (dataFromProps instanceof Promise) {
      dataFromProps.then(resolvedData => {
        let objectDataResolved = resolvedData;
        if (!Array.isArray(objectDataResolved)) {
          objectDataResolved = [objectDataResolved];
        }

        this.setState(prevState => ({
          objectData: [...prevState.objectData, ...objectDataResolved],
        }));
      });
    }

    // append objectData with resource provided result
    objectDataCascade(object).then(dataFromResource => {
      let data = [...dataFromResource];
      if (Array.isArray(dataFromProps)) {
        data = [...data, ...dataFromProps];
      }
      this.setObjectData(data);
    });
  }

  setObjectData = (objectData: Array<Object>) => {
    const { onChange, cascaded } = this.props;
    const newerObjectDataFiltered = this.objectDataAttributeFilters(objectData);
    const {
      cascaded: defaultCascaded,
      selected: defaultSelected,
    } = objectDataDefaultSelection(newerObjectDataFiltered);
    const { data } = cascadeToData([...defaultSelected]);
    this.setState(
      prevState => ({
        objectData: [...prevState.objectData, ...newerObjectDataFiltered],
        cascaded:
          cascaded && defaultSelected.length < cascaded.length
            ? cascaded
            : defaultCascaded,
      }),
      () => {
        // if (onChange) onChange({ type: "ATTRIBUTE", data });
        if (cascaded && cascaded.length < defaultCascaded.length && onChange) {
          onChange({ type: "ATTRIBUTE", data });
        }
      }
    );
  };

  // filtering with attributeIds and attributeNames
  objectDataAttributeFilters = (objectData: Array<Object>): Array<Object> => {
    const { filteredAttributes } = this.props;
    const newerObjectData = [...objectData];

    if (
      filteredAttributes &&
      Array.isArray(filteredAttributes) &&
      filteredAttributes.length
    ) {
      return newerObjectData.map(od => {
        const newerOd = { ...od };
        if (
          Object.keys(newerOd).indexOf("children") !== -1 &&
          Object.keys(newerOd).indexOf("__type") !== -1 &&
          Array.isArray(newerOd.children) &&
          newerOd.children.length
        ) {
          newerOd.children = newerOd.children.filter(child => {
            // new filter logic
            let filterPass: Array<boolean> = [];
            filterPass = [
              ...filterPass,
              ...filteredAttributes.map(filteredAttribute =>
                ["key", "value"].every(
                  k => Object.keys(filteredAttribute).indexOf(k) !== -1
                )
                  ? child[filteredAttribute.key] === filteredAttribute.value
                  : false
              ),
            ];
            return (
              child.__type === "attribute" && filterPass.indexOf(true) !== -1
            );
          });
        }

        return newerOd;
      });
    }

    return newerObjectData;
  };

  cascadeOnChange = (value: Array<any>, selectedOptions: Array<any>) => {
    const {
      onChange,
      /* resourceAccessor, */ isResourceToString,
      onChangePreprocessor,
    } = this.props;

    let resourceResulted;

    if (onChangePreprocessor) {
      resourceResulted = onChangePreprocessor([...value], [...selectedOptions]);
    }
    // if onChangePreprocessor return nothing
    // this needs to be handled by default resourceProcessing method
    if (!resourceResulted) {
      const { data } = cascadeToData([...selectedOptions]);
      this.setState({ isDirty: true });

      const resource = { type: "ATTRIBUTE", data };
      resourceResulted = { ...resource };

      if (isResourceToString) {
        const resourceProcessed = processResource(resource);
        resourceResulted = (resourceProcessed || {}).inputValue || undefined;
      }
    }

    if (onChange) onChange(resourceResulted);
  };

  render() {
    const {
      id,
      label,
      inputValue,
      cascaded,
      onChange,
      changeOnSelect,
      components,
      ...restProps
    } = this.props;
    const { objectData, cascaded: defaultCascaded, isDirty } = this.state;

    let CustomInputComponent = null;
    if (components && components.input) {
      CustomInputComponent = components.input;
    }

    return (
      <React.Fragment>
        {objectData && (
          <Cascader
            options={objectData}
            // defaultValue={cascaded}
            defaultValue={isDirty ? cascaded : defaultCascaded}
            transitionName="slide-up"
            changeOnSelect={changeOnSelect}
            dropdownMenuColumnStyle={{
              width: 170,
            }}
            popupClassName="object-cascader"
            onChange={this.cascadeOnChange}
            expandIcon={<FaArrowRight />}
            {...restProps}
          >
            {CustomInputComponent ? (
              // eslint-disable-next-line jsx-a11y/anchor-is-valid
              <a href="#">
                <CustomInputComponent />
              </a>
            ) : (
              <div className="form-group">
                <input
                  id={id}
                  className="form-control"
                  type="text"
                  label="Variable Field"
                  placeholder="Please Select an Attribute"
                  // value={inputValue}
                  value={
                    isDirty ? inputValue : `{!${defaultCascaded.join(".")}}`
                  }
                  style={{ width: 170 }}
                  readOnly
                />
                {label && <label htmlFor={id}>{label}</label>}
              </div>
            )}
          </Cascader>
        )}
      </React.Fragment>
    );
  }
}

export default ObjectAttributes;
