import CommonStyles from "./common";
import COLOURS from "../../constants/colours";

const ArtBoardWrapper = {
  boxSizing: "border-box",
  minHeight: "100vh",
  flexGrow: 1,
  padding: "1rem",
};

const ArtsContainer = {
  display: "flex",
  flexDirection: "column",
};

const ArtsContainerLoading = {
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
};

const ArtWrapper = {
  flex: 1,
};

const ArtContainer = {
  display: "flex",
  flexDirection: "column",
  // background: `${COLOURS.CONDITIONAL_DROPPABLE_BG}`,
  padding: "0.5rem",
  // border: "1px dashed #656565",
  borderRadius: ".2rem",
};

const ArtDragHandle = {
  background: "ghostwhite", // @temp
};

const ArtDroppableConditional = {
  display: "flex",
  flexGrow: 1,
  // flexDirection: "column",
  // background: "oldlace", // @temp,
  // flexBasis: "100%",
  boxSizing: "border-box",
};

const ArtDroppableOverWith = {
  background: `${COLOURS.CONDITIONAL_DROPPABLE_HOVERWITH}`,
  // border: "1px dashed #30588c",
};

const RowsWrapper = {
  display: "flex",
  flexDirection: "column",
  boxSizing: "border-box",
};

const RowWrapperEmpty = Object.assign({}, CommonStyles.SetWrapperMinHeight);

const RowWrapper = {
  // position: "relative",
  marginBottom: ".5rem",
  // padding: ".5rem",
  // border: "1px solid white",
};

const RowContainer = {
  flex: 1,
  flexBasis: 1,
  flexGrow: 1,
  display: "flex",
  flexDirection: "row",
  // paddingBottom: ".5rem",
  // padding: ".5rem",
  boxSizing: "border-box",
  border: `1px solid transparent`,
  borderTop: "none",
};

const DragFrameTitle = {
  flexDirection: "row",
  justifyContent: "flex-end",
  alignItems: "center",
  // padding: ".5rem",
  background: "transparent",
  position: "absolute",
  display: "none",
  // position: "absolute",
  // height: "1rem",
  // width: "100%",
  // left: 0,
  // top: 0,
};

const DragFrameTitleHover = {
  display: "flex",
  width: "100%",
  background: COLOURS.DRAGGABLE_FRAME,
  transition: "all 0.2s fade-in",
  marginTop: -17,
};

const ColDragFrameTitleHover = {
  ...DragFrameTitleHover,
  background: COLOURS.DRAGGABLE_FRAME_LIGHT,
  marginTop: 0,
};

const RowCloseHandler = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  // padding: ".5rem",
  padding: ".2rem",
  color: "#3d70b2",
  // height: "1rem",
};

const RowDragHandler = {
  // padding: ".5rem",
  // color: `${COLOURS.DRAGHANDLER_COLOR}`,
  // fontSize: ".8rem",
  // background: `${COLOURS.DRAGGABLE_FRAME}`,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  padding: ".2rem",
  color: "#3d70b2",
  flexGrow: 1,
  // height: "1rem",
};

const RowDragHandlerHover = {};

const ColCloseHandler = {
  ...RowCloseHandler,
  color: `${COLOURS.DRAGGABLE_FRAME_COLOR}`,
};
const ColDragHandler = {
  ...RowDragHandler,
  color: `${COLOURS.DRAGGABLE_FRAME_COLOR}`,
};

const ColsWrapper = {
  boxSizing: "border-box",
  display: "flex",
  flexDirection: "row",
  flexBasis: "100%",
  justifyContent: "flex-start",
  alignItems: "flex-start",
  // padding: ".5rem",
  // minHeight: "300px",
  // alignSelf: "stretch",
  // paddingRight: ".5rem",
  // marginRight: ".5rem",
  border: "1px dashed aliceblue",
  borderRadius: ".2rem",
};

const ColWrapperEmpty = Object.assign({}, CommonStyles.SetWrapperMinHeight);
// const ContainerWrapper = Object.assign({}, CommonStyles.SetWrapperMinHeight);

const ColWrapper = {
  flex: 1,
  flexBasis: "100%",
  flexGrow: 1,
  // minHeight: "100px",
  // padding: ".5rem",
  // display: "flex",
  // flexDirection: "column",
  alignSelf: "stretch",
  boxSizing: "border-box",
  // border: "1px solid #ccc",
  marginLeft: ".5rem",
  marginRight: ".5rem",
  paddingBottom: ".5rem",
  background: "#efe9e9",
  "&:first-child": {
    marginLeft: 0,
  },
  "&:last-child": {
    marginRight: 0,
  },
};
const TabsWrapper = {
  padding: ".5rem",
  boxSizing: "border-box",
};
const TabsNavWrapper = {
  // height: "2rem",
  boxSizing: "border-box",
  padding: "1rem .5rem",
  minHeight: "2rem",
};

const TabContentWrapper = {
  boxSizing: "border-box",
};
const TabWrapper = {
  // display: "flex",
  boxSizing: "border-box",
};

const InkDropWrapper = {
  fontSize: ".875rem",
  boxSizing: "border-box",
  width: "calc(100% - 1rem)",
  border: "1px solid transparent",
  height: "3rem",
  display: "flex",
  flexDirection: "row",
  justifyContent: "flex-start",
  alignItems: "center",
  padding: "0.5rem",
  margin: "0.5rem 0.5rem 0",
  background: "white",
};

const InkDropCol = {
  fontSize: ".8rem",
  flex: 1,
  alignSelf: "center",
  display: "flex",
  justifyContent: "flex-start",
  alignItems: "center",
  // textTransform: "upperscase",
};

const ContainerEmpty = Object.assign({}, CommonStyles.SetWrapperMinHeight);
const ContainerWrapper = {
  background: "#efe9e9",
};
const ContainerTitle = {
  margin: "0px",
  padding: "0px",
  fontSize: "20px",
  fontWeight: "bold",
};

export {
  ArtBoardWrapper,
  ArtsContainer,
  ArtsContainerLoading,
  ArtWrapper,
  ArtContainer,
  ArtDragHandle,
  ArtDroppableConditional,
  ArtDroppableOverWith,
  RowsWrapper,
  RowWrapperEmpty,
  RowWrapper,
  RowContainer,
  DragFrameTitle,
  DragFrameTitleHover,
  ColDragFrameTitleHover,
  RowCloseHandler,
  RowDragHandler,
  RowDragHandlerHover,
  ColsWrapper,
  ColWrapper,
  ColWrapperEmpty,
  ColCloseHandler,
  ColDragHandler,
  TabsWrapper,
  TabsNavWrapper,
  TabContentWrapper,
  TabWrapper,
  InkDropWrapper,
  InkDropCol,
  ContainerEmpty,
  ContainerWrapper,
  ContainerTitle,
};
