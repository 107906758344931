// @flow
import React from "react";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/lib/integration/react";

// Default loader
import type { Node } from "react";
import DefaultLoader from "../components/Loader";

type Props = {
  loader?: Node,
};

export const withProvider = (
  Component: any, // @todo
  config: { store: any, persistor: any }
) => (props: Props = { loader: undefined }) => {
  const { store, persistor } = config;
  const { loader } = props;
  return (
    <Provider store={store}>
      <PersistGate persistor={persistor} loading={loader || <DefaultLoader />}>
        <Component {...props} />
      </PersistGate>
    </Provider>
  );
};

export default { withProvider };
