import React from "react";

import SearchableDropdown from "../SearchableDropdown";

type OptionsProps = {
  name: string,
  value?: string,
  onChange: Function,
};

const Options = (props: OptionsProps) => {
  const { name, value, onChange } = props;
  const data = [
    { key: "url", name: "URL" },
    { key: "search", name: "Search" },
    { key: "coordinates", name: "Coordinates" },
  ];
  const itemLabelAccessor = item => item.name;
  const itemIdAccessor = item => item.key;
  return (
    <SearchableDropdown
      name={name}
      data={data}
      onChange={onChange}
      itemLabelAccessor={itemLabelAccessor}
      itemIdAccessor={itemIdAccessor}
      isClearable={false}
      value={value}
      {...props}
    />
  );
};

Options.defaultProps = {
  value: "search",
};

export default Options;
