import * as React from "react";
import { Navbar } from "reactstrap";
import styled from "styled-components";
import classNames from "classnames";

import { withLayout, type LayoutContext } from "../../utils";

type Props = {
  children: React.Node,
  className?: string,
  isMainTopBar?: boolean,
  layout: LayoutContext,
  spacer?: boolean,
};
type State = {
  isMoving: boolean,
};

const Sticky = styled(Navbar)`
  display: none;
  &.is-mobile {
    padding-left: 72px !important;
    margin-left: -1rem;
    min-height: 58px;
    display: flex;
    &.is-mobile-menu-closed {
      position: fixed;
      left: 0;
      top: 0;
      right: 0;
      margin-top: 0;
      margin-right: 0;

      & + .internal-breadcrumb {
        padding-top: 58px;
      }
    }

    &.spacer {
      position: fixed;
      & + *:first-of-type:not(.spacer-unaware) {
        padding-top: 58px;
      }
      &.is-mobile-menu-closed {
        position: fixed;
        left: 0;
        top: 0;
        right: 0;
        margin-top: 0;
      }
      &.is-mobile-menu-opened {
        width: 100%;
        z-index: -999 !important;
      }
    }
  }
  &.sticky-moving {
    position: fixed;
    left: 0;
    right: 0;
  }
`;

class StickyTopNavbar extends React.Component<Props, State> {
  static defaultProps = {
    className: "",
    isMainTopBar: false,
    spacer: false,
  };

  state = {
    isMoving: false,
  };

  constructor(props: Props) {
    super(props);

    this.navbar = React.createRef();
  }

  componentDidMount() {
    window.addEventListener("scroll", this.onScroll);
    if (this.navbar.current) {
      this.myTop = this.navbar.current.getBoundingClientRect().top;
    }
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.onScroll);
  }

  onScroll = () => {
    // if (window.pageYOffset > this.myTop) {
    //   this.setState({
    //     isMoving: true,
    //   });
    // } else {
    //   this.setState({
    //     isMoving: false,
    //   });
    // }
  };

  myTop: any;

  // $FlowFixMe
  navbar: React.RefObject<any>;

  render() {
    const {
      children,
      className,
      isMainTopBar,
      layout,
      spacer,
      ...restProps
    } = this.props;
    const { isMoving } = this.state;

    return (
      <Sticky
        sticky="top"
        light
        {...restProps}
        // className={`${className || ""} ${isMoving ? "sticky-moving" : ""} ${
        //   isMainTopBar ? "sticky-top-navbar" : ""
        // } ${layout.isMobile ? "is-mobile" : ""}`}
        className={classNames({
          ...(className ? { [`${className}`]: true } : {}),
          ...{
            "actions-container": true,
            "sticky-top-navbar": isMainTopBar,
            "sticky-moving": isMoving,
            "is-mobile": layout.isMobile,
            "is-mobile-menu-closed":
              layout.isMobile && !layout.isMobileMenuOpen,
            "is-mobile-menu-opened": layout.isMobile && layout.isMobileMenuOpen,
            spacer,
          },
        })}
      >
        {children}
      </Sticky>
    );
  }
}

export default withLayout(StickyTopNavbar);
