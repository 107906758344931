import React, { Component } from "react";
import {
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Progress,
  Badge,
} from "reactstrap";
import styled from "styled-components";

import TooltipItem from "./Helpers/TooltipItem";

type Props = {
  title?: string,
  data: any,
};
type State = {};

const HorizontalGraphsWrapper = styled.div`
  margin-top: 20px;
  margin-right: 4px;
  margin-left: 4px;

  .not-available {
    background: #ff9595;
  }
  .progress-group > span {
    -ms-flex-positive: 1;
    flex-grow: 1;
    -ms-flex-item-align: center;
    align-self: center;
  }
  .card-header {
    border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0;
    font-size: 21px;
    letter-spacing: 2px;
    text-transform: uppercase;
    color: #fff;
    background-color: #395b99;
  }
  .progress-group-bars .progress:not(:last-child) {
    margin-bottom: 2px;
  }
  .horizontal-graph-line .progress {
    margin-top: 1px;
  }
  .progress-xs {
    height: 4px;
  }
  .progress-group {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    margin-bottom: 1rem;
  }
  .progress-group-prepend {
    -ms-flex: 0 0 100px;
    flex: 0 0 100px;
    -ms-flex-item-align: center;
    align-self: center;
  }
  .progress-group-text {
    font-size: 0.76562rem;
    color: #73818f;
  }

  .badge-pill {
    border-radius: 10rem;
  }

  .badge-danger {
    color: #fff;
    background-color: #f86c6b;
  }
  .badge-pill {
    padding-right: 0.6em;
    padding-left: 0.6em;
  }
  .badge {
    display: inline-block;
    padding: 0.25em 0.4em;
    font-size: 75%;
    font-weight: 700;
    line-height: 0.7;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: 0.25rem;
    -webkit-transition: color 0.15s ease-in-out,
      background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
      -webkit-box-shadow 0.15s ease-in-out;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
      border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
    -o-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
      border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
      border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
      border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out,
      -webkit-box-shadow 0.15s ease-in-out;
  }
  .pl-1,
  .px-1 {
    padding-left: 0.25rem !important;
  }

  .pr-1,
  .px-1 {
    padding-right: 0.25rem !important;
  }
  sup {
    top: -0.5em;
  }
  sub,
  sup {
    position: relative;
    font-size: 75%;
    line-height: 0;
    vertical-align: baseline;
  }
  .callout-warning {
    border-left-color: #ffc107;
  }
  .callout {
    position: relative;
    padding: 0 1rem;
    margin: 1rem 0;
    border-left: 4px solid #c8ced3;
    border-radius: 0.25rem;
  }
  .callout-success {
    border-left-color: #4dbd74;
  }
  .callout-danger {
    border-left-color: #f86c6b;
  }
  hr {
    margin-top: 1rem;
    margin-bottom: 1rem;
    border: 0;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
  }
`;

class Index extends Component<Props, State> {
  static defaultProps = {
    title: "Graph",
  };

  static getTotal(values: any) {
    let total = 0;
    values.map(value => {
      total += value;
      return false;
    });
    return total;
  }

  static getTooltipTitle(barsNames: any, item: any) {
    const result = [];
    Object.keys(barsNames).map(key =>
      result.push(
        <span key={key} className="clearfix">
          {barsNames[key].name}: {item.values[key]}
        </span>
      )
    );
    return result;
  }

  render() {
    const { data: propsData, title } = this.props;
    const { barsNames, data, titles } = propsData;
    return (
      <HorizontalGraphsWrapper>
        <Card>
          <CardHeader>{title}</CardHeader>
          <CardBody>
            <Row>
              <Col xs="12" md="12" xl="12">
                <Row>
                  {titles.map(item => (
                    <Col key={item.name}>
                      <div className={`callout callout-${item.color}`}>
                        <small className="text-muted">{item.name}</small>
                        <br />
                        <strong className="h4">{item.totalCount}</strong>
                        <div className="chart-wrapper" />
                      </div>
                    </Col>
                  ))}
                </Row>
                <hr className="mt-0" />
                {(data || []).map(item => (
                  <div
                    key={item.title}
                    className="progress-group horizontal-graph-line mb-4"
                  >
                    <div className="progress-group-prepend">
                      <span className="progress-group-text">{item.title}</span>
                    </div>
                    <TooltipItem
                      id={item.title}
                      value={Index.getTooltipTitle(barsNames, item)}
                      classNames="progress-group-bars"
                      item={
                        <div
                          className="progress-group-bars"
                          id={`Tooltip-${item.title}`}
                        >
                          {Object.keys(barsNames).map(key => (
                            <Progress
                              key={item.title + key}
                              className={`progress-xs ${
                                barsNames[key].backgroundColor
                              }`}
                              color={barsNames[key].color}
                              value={`${(item.values[key] /
                                Index.getTotal(item.values)) *
                                100}`}
                            />
                          ))}
                        </div>
                      }
                    />
                  </div>
                ))}
                <div className="legend text-center">
                  <small>
                    {titles.map(item => (
                      <React.Fragment key={item.name}>
                        <sup className="px-1">
                          <Badge pill color={item.color}>
                            &nbsp;
                          </Badge>
                        </sup>
                        {item.name} &nbsp;
                      </React.Fragment>
                    ))}
                  </small>
                </div>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </HorizontalGraphsWrapper>
    );
  }
}
export default Index;
