// @flow
import moment from "moment";

import isValid from "./is_valid";

export default (conditionValue: string) => (fieldValue: string) => {
  if (isValid(conditionValue) && isValid(fieldValue)) {
    return moment(fieldValue).isBefore(moment(conditionValue));
  }
  return false;
};
