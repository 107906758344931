import React from "react";

const contextDefault = {
  objectDef: undefined,
};

// $FlowFixMe
const ObjectDefContext = React.createContext(contextDefault);

const withObjectDef = (Component: any) => (props: any) => (
  <ObjectDefContext.Consumer>
    {objectDef => <Component {...props} objectDef={objectDef} />}
  </ObjectDefContext.Consumer>
);

export { ObjectDefContext, withObjectDef };
