export const SHOW_FIELD: "SHOW_FIELD" = "SHOW_FIELD";
export const HIDE_FIELD: "HIDE_FIELD" = "HIDE_FIELD";
export const HIDE_LAYOUT: "HIDE_LAYOUT" = "HIDE_LAYOUT";
export const REQUIRE_FIELD: "REQUIRE_FIELD" = "REQUIRE_FIELD";
export const SET_VALUE: "SET_VALUE" = "SET_VALUE";
export const SET_OPTIONS: "SET_OPTIONS" = "SET_OPTIONS";
export const READONLY_FIELDS: "READONLY_FIELDS" = "READONLY_FIELDS";
export const READONLY_FIELD: "READONLY_FIELD" = "READONLY_FIELD";

// action's resource data carriers
// pre-defined map for each verb and its associates one or more string
// i.e. for HIDE_LAYOUT verb, possible resource-data-carriers are [{ id: "layoutPath", type: "string"}]
// i.e. for SET_VALUE verb, possible resource-data-carriers are [{ id: "setValue", type: "string"}]
// i.e. for SET_OPTIONS verb, possible resource-data-carriers are [{id: "setOptions", type: "array:mixed"}]

export default {
  SHOW_FIELD,
  HIDE_FIELD,
  HIDE_LAYOUT,
  REQUIRE_FIELD,
  SET_VALUE,
  SET_OPTIONS,
  READONLY_FIELDS,
  READONLY_FIELD,
};
