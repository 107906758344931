export default [
  {
    name: "A+",
    value: "A_POSITIVE",
  },
  {
    name: "A-",
    value: "A_NEGATIVE",
  },
  {
    name: "B+",
    value: "B_POSITIVE",
  },
  {
    name: "B-",
    value: "B_NEGATIVE",
  },
  {
    name: "AB+",
    value: "AB_POSITIVE",
  },
  {
    name: "AB-",
    value: "AB_NEGATIVE",
  },
  {
    name: "O+",
    value: "0_POSITIVE",
  },
  {
    name: "O-",
    value: "O_NEGATIVE",
  },
];
