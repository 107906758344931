import React from "react";
import { AppBreadcrumb } from "@coreui/react"; // eslint-disable-line
import { withRouter, type Location } from "react-router-dom";

type Props = {
  appRoutes?: Object,
  isInternal?: boolean,
  leaf?: string,
  disable?: boolean,
  location: Location,
  customClass?: string,
};

class Breadcrumbs extends React.Component<Props> {
  static defaultProps = {
    appRoutes: [],
    isInternal: false,
    leaf: undefined,
    disable: false,
    customClass: undefined,
  };

  componentDidMount() {
    const { isInternal, disable, customClass } = this.props;
    if (isInternal || disable) {
      const body = document.querySelector("body");
      if (body) {
        if (customClass) {
          body.classList.add(customClass);
        } else {
          body.classList.add("has-internal-breadcrumb");
        }
      }
    }
  }

  componentWillUnmount() {
    const { isInternal, disable, customClass } = this.props;
    if (isInternal || disable) {
      const body = document.querySelector("body");
      if (body) {
        body.classList.remove("has-internal-breadcrumb");
        if (customClass) {
          body.classList.remove(customClass);
        }
      }
    }
  }

  getRoutes = appRoutes => {
    if (!appRoutes) return [];
    const routes = [...appRoutes];
    const { leaf, location } = this.props;
    if (leaf) {
      routes.unshift({
        path: location.pathname,
        name: leaf,
        exact: true,
      });
    }
    return routes;
  };

  render() {
    const { appRoutes, isInternal, disable } = this.props;
    if (disable) return null;
    return (
      <AppBreadcrumb
        appRoutes={this.getRoutes(appRoutes)}
        className={isInternal ? "internal-breadcrumb" : "main-breadcrumb"}
      />
    );
  }
}

export default withRouter(Breadcrumbs);
