export const MODULE_NAME = "PPCTieringTool";
export const MODULE_SHORT_DISPLAY_NAME = "PPC Tiering Tool";
export const MODULE_DISPLAY_NAME = "PPC Tiering Tool"; // Module display name
export const MODULE_URN = "ppctieringtool"; // module url prefix
export const MODULE_UNIQUE = "ppctieringtool"; // module unique identifier within swan system and this should be unique throught the system

export default {
  MODULE_DISPLAY_NAME,
  MODULE_NAME,
  MODULE_URN,
  MODULE_UNIQUE,
};
