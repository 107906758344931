import _pathProcessor from "./_pathProcessor";

export default (
  container: Object,
  path: any,
  defaultValue?: any = undefined
) => {
  const result =
    _pathProcessor(container, path, /[,[\]]+?/) ||
    _pathProcessor(container, path, /[,[\].]+?/);

  if (result === undefined || result === container) return defaultValue;
  return result;
};
