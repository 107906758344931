import React from "react";
import { compose } from "redux";
import { pluralize } from "@swan/helpers/utils";
import ConnectedForm from "./ConnectedForm";

const ConnectedFormFactory = (
  connect: Function,
  actions: { [string]: Function },
  selectors: { [string]: Function },
  ServiceFactory: (object: string, data?: Object) => Object
) => {
  const FormGenerator = (object: string, options?: Object = {}) => {
    let pluralObject = pluralize(object);
    // overrides
    if (options && options.objectPlural) {
      pluralObject = options.objectPlural;
    }

    const Form = () => <span />;

    function mapStateToProps(state: Object, ownProps: Object) {
      return {
        ...selectors[`${pluralObject}Selectors`].getCrudFormState(
          state[pluralObject],
          ownProps
        ),
      };
    }

    return compose(
      connect(
        mapStateToProps,
        {
          ...actions[`${pluralObject}Actions`].getCrudFormActions(),
          ...actions[`${pluralObject}Actions`].getWebsocketFormActions(),
        }
      ),
      ConnectedForm({
        object,
        type: options.formType,
        formId: options.formId,
        ServiceFactory,
      })
    )(Form);
  };

  return FormGenerator;
};

export default ConnectedFormFactory;
