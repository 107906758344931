import Axios from "axios";
import { ServiceGenerator } from "@swan/api";

const WorkflowService = ServiceGenerator(Axios, "workflow");

WorkflowService.prototype.triggerAction = function triggerAction(
  workflow: number,
  actionName: string
) {
  const key = `instances/${workflow}/trigger/${actionName}`;
  return this.post(key, {});
};

WorkflowService.prototype.triggerDecision = function triggerDecision(
  workflow: number,
  nodeId: number,
  option: string,
  input: Object = null
) {
  const key = `instances/${workflow}/decide/${nodeId}`;
  return this.post(key, {
    option,
    input,
  });
};

WorkflowService.prototype.approve = function triggerAction(
  approvalId: number,
  data?: Object = {}
) {
  const key = `approvals/${approvalId}/approve`;
  return this.post(key, data);
};

WorkflowService.prototype.reject = function triggerAction(
  approvalId: number,
  data?: Object = {}
) {
  const key = `approvals/${approvalId}/reject`;
  return this.post(key, data);
};

export default WorkflowService;
