import * as React from "react";
import { DropDown } from "@swan/themer";
// import { schemaDataSource } from "@swan/form-designer";

import Field from "./partials/Field";
import type { ElementProps } from "./types";
import getOptionDisplayValue, {
  getItems,
} from "../../utils/getOptionDisplayValue";

const SingleSelect = ({
  schema,
  onChange,
  error,
  data,
  readOnly,
  hideLabel,
}: ElementProps) => (
  <DropDown
    {...schema.options || {}}
    name={schema.attributeName || ""}
    value={(data || {})[schema.attributeName || ""]}
    label={schema.label}
    hideLabel={hideLabel || (schema.options || {}).hideLabel}
    onChange={e =>
      onChange && onChange(schema.attributeName || "", e.target.value)
    }
    error={(error || {})[schema.attributeName || ""]}
    data={getItems(schema.options)}
    itemIdAccessor={item => item.value}
    itemLabelAccessor={item => item.name}
    static={readOnly || (schema.options || {}).readOnly}
    displayValue={getOptionDisplayValue(
      (data || {})[schema.attributeName || ""],
      schema.options
    )}
  />
);

export default Field(SingleSelect);
