import React, { useEffect, useState } from "react";
import styled from "styled-components";

import PolicyService from "./services/policyAdmin";
import Item from "./Item";

const policyService = new PolicyService();

const ItemsContainer = styled.div`
  .admin-policy-item {
    margin-top: 50px !important;
    padding: 17px;
  }
  .admin-policy-item:first-child {
    margin-top: 0px !important;
  }
  .admin-policy-item {
    border: 1px solid #ccc;
  }
`;

const PolicyUsersList = (props: any) => {
  const [policies, setPolicies] = useState([]);

  useEffect(() => {
    policyService.get("policies-list").then(response => {
      setPolicies((response || {}).data || []);
    });
  }, []);
  return (
    <ItemsContainer>
      {(policies || []).map(policy => (
        <div className="admin-policy-item">
          <Item {...props} key={policy.id} policy={policy} />
        </div>
      ))}
    </ItemsContainer>
  );
};

export default PolicyUsersList;
