import * as React from "react";
import { Radio, Checkbox } from "@swan/themer";

import Field from "./partials/Field";
import type { ElementProps } from "./types";

const RadioSelectField = ({
  schema,
  onChange,
  error,
  data,
  readOnly,
  hideLabel,
}: ElementProps) => {
  const items = [
    {
      value: "1",
      name: (schema.options || {}).singleOnLabel || "Yes",
    },
    {
      value: "0",
      name: (schema.options || {}).singleOffLabel || "No",
    },
  ];

  let stringyfiedValue = "0";
  if ((data || {})[schema.attributeName || ""]) {
    stringyfiedValue = (data || {})[schema.attributeName || ""];
    switch (stringyfiedValue) {
      case "boolean":
        stringyfiedValue = stringyfiedValue === true ? "1" : "0";
        break;
      default:
        stringyfiedValue = String((data || {})[schema.attributeName || ""]);
        break;
    }
  }

  if (
    (schema.options || {}).singleOnDisplayWidget &&
    (schema.options || {}).singleOnDisplayWidget === "CHECKBOX"
  ) {
    return (
      <Checkbox
        name={schema.attributeName || ""}
        value={stringyfiedValue}
        label={schema.label}
        onChange={() => {
          if (onChange)
            onChange(
              schema.attributeName || "",
              stringyfiedValue !== "1" ? "1" : "0"
            );
        }}
        static={readOnly || (schema.options || {}).readOnly}
      />
    );
  }

  return (
    <Radio
      {...schema.options || {}}
      name={schema.attributeName || ""}
      value={stringyfiedValue}
      label={schema.label}
      hideLabel={hideLabel || (schema.options || {}).hideLabel}
      onChange={e =>
        onChange && onChange(schema.attributeName || "", e.target.value)
      }
      error={(error || {})[schema.attributeName || ""]}
      data={items}
      itemIdAccessor={item => item.value}
      itemLabelAccessor={item => item.name}
      static={readOnly || (schema.options || {}).readOnly}
    />
  );
};

export default Field(RadioSelectField);
