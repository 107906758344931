// @flow
import React, { Component, Fragment } from "react";
import { Input, InputGroupText } from "reactstrap";
import { FaSearch } from "react-icons/fa";
import { getLocationByLatlng } from "./Utils";

import { type AddressType } from "./types";

type Props = {
  className?: string,
  error?: string,
  onSubmit: (address: AddressType) => any,
};

type State = {
  url: string,
};

class UrlField extends Component<Props, State> {
  static defaultProps = {
    className: "",
    error: "",
  };

  constructor(props: Props) {
    super(props);
    this.state = {
      url: "",
    };
  }

  getUrlInfo = () => {
    const { onSubmit } = this.props;
    const { url } = this.state;
    const regex = new RegExp("@(.*),(.*),");
    const lngLatMatch: string[] | null = url.match(regex);

    if (!lngLatMatch || lngLatMatch.length < 2) {
      return false;
      // @TODO message that URL not valid
    }
    const latlng = {
      lat: parseFloat(lngLatMatch[1]),
      lng: parseFloat(lngLatMatch[2]),
    };

    onSubmit(latlng); // In case geo location fails
    getLocationByLatlng(latlng, onSubmit);
    return true;
  };

  render() {
    const { className, error } = this.props;
    const { url } = this.state;
    return (
      <Fragment>
        <div className="form-map-control-input-with-button">
          <Input
            type="text"
            placeholder="e.g: https://www.google.com/maps/@24.3752445,54.5382419,15z"
            value={url}
            onChange={(e: Object) => {
              this.setState({
                url: e.target.value,
              });
            }}
            onBlur={this.getUrlInfo}
            className={`${className || ""} ${error !== "" ? "is-invalid" : ""}`}
          />
        </div>
        <div className="input-group-prepend filter-map-icon">
          <InputGroupText
            className="input-group-append"
            onClick={this.getUrlInfo}
          >
            <FaSearch />
          </InputGroupText>
        </div>
      </Fragment>
    );
  }
}

export default UrlField;
