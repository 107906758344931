import * as React from "react";
import type { ComponentType } from "react";
import ElementsList from "./Elements/ElementsList";
import {
  type Schema,
  type Rules,
  type Options,
  type Modifiers,
} from "../types";

type Props = {
  schema: Schema,
  rules: Rules,
  data: Object,
  isNew: boolean,
  object: string,
  parentRecord?: Object,
  onChange: (attribute: string, value: string | number | Object) => void,
  onBatchChange: ({ [attribute: string]: string | number | Object }) => void,
  error: ?{ [string]: string },
  loading: boolean,
  ServiceFactory: (object: string, data: {}) => Object,
  readOnly?: boolean,
  options: Options,
  modifiers: Modifiers,
  rendererComponents?: { [string]: ComponentType<*> },
  auth?: Object,
  addButtons: ?Function,
  duplicateRecord: ?Function,
  setLock: ?Function,
  removeButtons: ?Function,
  fieldsLoading: Array<string>,
  fieldsLocked: Array<string>,
  isAddAllowed: boolean,
  isEditAllowed: boolean,
  isDeleteAllowed: boolean,
};

// $FlowFixMe
export const RendererContext = React.createContext({});

const Main = ({
  schema,
  onChange,
  onBatchChange,
  error,
  loading,
  data,
  isNew,
  parentRecord,
  object,
  ServiceFactory,
  options,
  modifiers,
  rendererComponents,
  auth,
  ...restProps
}: Props) => (
  <RendererContext.Provider
    value={{
      onChange,
      onBatchChange,
      error,
      loading, // loading state of whole form, not an specific field which belongs to an attribute
      data,
      isNew,
      parentRecord,
      object,
      ServiceFactory,
      schema,
      options: options || {},
      modifiers,
      rendererComponents,
      auth,
      ...restProps,
    }}
  >
    <ElementsList id="@" index={0} schema={{ properties: { root: schema } }} />
  </RendererContext.Provider>
);

Main.defaultProps = {
  readOnly: false,
  parentRecord: undefined,
  rendererComponents: {},
  auth: {},
};

export const RendererSubscriber = RendererContext.Consumer;

export default Main;
