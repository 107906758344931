/**
 * A generic service that uses an object name to fetch configuration like the api endpoint
 * and returns a service with the route correctly set
 */
import GenericResource from "./GenericResource";

const ResourceFactory = (name: string /* , config: {} */) => {
  class Res extends GenericResource {
    objectName = name;
  }
  return Res;
};
export default ResourceFactory;
