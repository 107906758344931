// $flow
export default (
  options?: Array<Object> = [],
  idAccessor?: Function = undefined,
  labelAccessor?: Function = undefined
): Array<Object> => {
  const result: Object = {};
  if (options && Array.isArray(options) && options.length) {
    options.forEach(item => {
      const id =
        idAccessor && idAccessor instanceof Function
          ? idAccessor(item)
          : item.id;
      Object.assign(result, {
        [id]: {
          id,
          label:
            labelAccessor && labelAccessor instanceof Function
              ? labelAccessor(item)
              : item.label,
        },
      });
    });
  }

  return Object.values(result);
};
