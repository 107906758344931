import getFormulaResult from "./getFormulaResult";

export default (options: Object, formProps: Object) => {
  if (
    (options || {}).hideOnCreate &&
    Number.parseInt(options.hideOnCreate, 10) === 1 &&
    (formProps || {}).isNew &&
    formProps.isNew === true
  ) {
    return false;
  }

  if (
    (options || {}).hideOnDetail &&
    Number.parseInt(options.hideOnDetail, 10) === 1 &&
    ((formProps || {}).data || {}).id
  ) {
    return false;
  }

  if (
    (options || {}).hideOnCondition &&
    (options || {}).isHideOnCondition === "1"
  ) {
    const doHide = getFormulaResult(
      {
        body: (options || {}).hideOnCondition,
      },
      { record: (formProps || {}).data }
    );

    if (doHide === true) return false;
  }

  return true;
};
