import React, { useEffect } from "react";
import { Card, CardBody, CardGroup, Col, Progress } from "reactstrap";

const OutlookLogin = () => {
  const loginUser = () => {
    setTimeout(() => {
      window.OfficeRuntime.auth
        .getAccessToken()
        .then(res => {
          window.location.href = `/checklogin/#id_token=${res}`;
        })
        .catch(e => {
          console.log(e);
        });
    }, 4000);
  };

  const loadOfficeJs = () => {
    let head = document.getElementsByTagName("head")[0];
    let officeJs = document.createElement("script");
    officeJs.id = "officejsScript";
    officeJs.src =
      "https://appsforoffice.microsoft.com/lib/1.1/hosted/office.js";
    officeJs.type = "text/javascript";

    head.insertBefore(officeJs, document.getElementsByTagName("script")[0]);
  };

  useEffect(() => {
    loadOfficeJs();

    loginUser();
  }, []);

  return (
    <div className="animated fadeIn vh-100 d-flex justify-content-center align-items-center">
      <Col md="8">
        <CardGroup>
          <Card
            className="text-white py-5 d-md-down-none"
            style={{ backgroundColor: "#1f4083" }}
          >
            <CardBody className="text-center">
              <div>
                <h2>Logging you into SWAN</h2>
                <p>Using SSO...</p>
                <br />
                <Progress animated value={100} color="info" />
              </div>
            </CardBody>
          </Card>
        </CardGroup>
      </Col>
    </div>
  );
};

export default OutlookLogin;
