import React from "react";
// $FlowFixMe
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import FieldWrapper, { type FieldProps } from "./partials/FieldWrapper";

export type InputProps = FieldProps & {
  placeholder?: string,
  defaultCountry?: string,
};

const Field = ({
  label,
  name,
  placeholder,
  required,
  value,
  onChange,
  className,
  error,
  defaultCountry,
}: InputProps) => (
  <PhoneInput
    id={name}
    placeholder={placeholder || label || ""}
    required={required || false}
    value={value}
    onChange={newVal =>
      onChange ? onChange({ target: { value: newVal } }) : null
    }
    className={`${className || ""} ${error !== "" ? "is-invalid" : ""}`}
    defaultCountry={defaultCountry} // support local and international formats
    country={defaultCountry}
  />
);

export default FieldWrapper(Field);
