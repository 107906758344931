import React from "react";
import { isEqual } from "lodash";
import Card from "./Card";
import Loading from "../../Loading";
import { Title, Column } from "./Styled";

import { type ColumnConfigType, type ServiceParamsType } from "./types";

type Props = ColumnConfigType;

type State = {
  columnData: Array<Object>,
  loading: boolean,
  page: number,
  pageSize: number,
  currentPageSize: number,
};

class Main extends React.Component<Props, State> {
  static defaultProps = {
    titleVariant: "h5",
    size: 300,
    spacing: { margin: "1rem" },
    backgroundColor: "#eee",
    borderStyle: undefined,
    data: [],
    service: undefined,
    serviceProvider: undefined,
    footer: null,
    cardOptions: {},
    servicePaginatorParams: {
      page: { accessor: "page", initVaue: 1 },
      pageSize: { accessor: "pageSize", initValue: 15 },
    },
  };

  service = null;

  constructor(props: Props) {
    super(props);
    this.state = {
      page:
        (((props || {}).servicePaginatorParams || {}).page || {}).initValue ||
        1,
      pageSize:
        (((props || {}).servicePaginatorParams || {}).pageSize || {})
          .initValue || 15,
      columnData: [],
      currentPageSize: 0,
      loading: false,
    };

    this.pager = React.createRef();
  }

  componentDidMount() {
    const {
      serviceProviderParams, // service provider parameters
    } = this.props;

    if (serviceProviderParams) {
      this.onServiceParamsChange(serviceProviderParams, this.observe);
    }
  }

  componentDidUpdate(prevProps: Props) {
    const { serviceProviderParams, serviceFilters } = this.props;
    if (!isEqual(prevProps.serviceFilters, serviceFilters)) {
      this.onServiceParamsChange(
        serviceProviderParams || [],
        this.observe,
        true
      );
    }
  }

  observe = () => {
    const observer = new IntersectionObserver(this.handleObserver, {
      root: null,
      rootMargin: "20px",
      threshold: 1.0,
    });

    if (this.pager.current) {
      observer.observe(this.pager.current);
    }
  };

  handleObserver = (entities: Array<any>) => {
    const {
      serviceProviderParams, // service provider parameters
    } = this.props;
    const { currentPageSize, pageSize } = this.state;
    const target = entities[0];
    if (target.isIntersecting && currentPageSize === pageSize) {
      this.setState(
        prevState => ({
          page: prevState.page + 1,
        }),
        () => {
          if (serviceProviderParams) {
            this.onServiceParamsChange(serviceProviderParams);
          }
        }
      );
    }
  };

  onServiceParamsChange = (
    serviceProviderParams: Array<ServiceParamsType>,
    callback?: (Array<Object>) => void,
    force?: boolean = false
  ) => {
    const {
      service,
      serviceProvider,
      serviceResponseHandle,
      data,
      servicePaginatorParams,
      serviceFilters,
    } = this.props;
    const { page, pageSize, columnData: columnDataLoaded } = this.state;

    let columnData = [...(data || []), ...columnDataLoaded];
    if (service) this.service = service;

    if (serviceProvider && serviceProvider instanceof Function) {
      this.service = serviceProvider(serviceProviderParams, {
        [((servicePaginatorParams || {}).page || {}).accessor || "page"]: page,
        [((servicePaginatorParams || {}).pageSize || {}).accessor ||
        "pageSize"]: pageSize,
        ...(serviceFilters || {}),
      });
    }

    if (!this.service) return;

    this.setState(
      {
        loading: true,
      },
      () => {
        if (this.service) {
          this.service.then(response => {
            let result = response;
            if (
              serviceResponseHandle &&
              serviceResponseHandle instanceof Function
            ) {
              result = serviceResponseHandle(result);
            }

            if (force) {
              columnData = [...result];
            } else {
              columnData = [...columnData, ...result];
            }
            this.setState(
              {
                columnData,
                currentPageSize: (result || []).length,
              },
              () => {
                this.setState({ loading: false });
                if (callback && callback instanceof Function) {
                  callback(columnData);
                }
              }
            );
          });
        }
      }
    );
  };

  hidePager = () => {
    const { currentPageSize, pageSize } = this.state;
    return currentPageSize !== pageSize;
  };

  pager: { current: null | HTMLDivElement };

  render() {
    const {
      size,
      spacing,
      backgroundColor,
      customStyle,
      borderStyle,
      title,
      titleVariant,
      cardOptions,
      footer,
      components,
    } = this.props;
    const { columnData, loading } = this.state;

    // if it's required to hide empty columns, uncomment below
    // if (!columnData.length) return null;

    return (
      <Column
        size={size}
        spacing={spacing}
        backgroundColor={backgroundColor}
        borderStyle={borderStyle}
        customStyle={
          customStyle && typeof customStyle === "string"
            ? customStyle
            : undefined
        }
        style={
          customStyle && customStyle.constructor === Object
            ? customStyle
            : undefined
        }
      >
        {title ? (
          <Title variant={titleVariant} className="column-title">
            {title}
          </Title>
        ) : null}

        <div className="column-body">
          {(columnData || []).map((d: Object) => {
            let { data, actions, tags } = d;
            const { footer: cardFooter } = d;
            const {
              dataHandle,
              actionsHandle,
              tagsHandle,
              ...options
            } = cardOptions;
            // dataHandle
            if (dataHandle && dataHandle instanceof Function) {
              data = dataHandle(d);
            }
            // actionHandle
            if (actionsHandle && actionsHandle instanceof Function) {
              actions = actionsHandle(d);
            }
            // tagHandle
            if (tagsHandle && tagsHandle instanceof Function) {
              tags = tagsHandle(d);
            }

            const CardComponent = (components || {}).card || Card || null;

            if (!CardComponent) return null;

            return (
              <CardComponent
                {...options}
                data={data}
                actions={actions}
                tags={tags}
                footer={cardFooter}
              />
            );
          })}

          {this.hidePager() ? null : (
            <div className="column-pager" ref={this.pager}>
              Load More
            </div>
          )}
        </div>

        {loading ? <Loading /> : null}

        <div className="column-footer">{footer}</div>
      </Column>
    );
  }
}

export default Main;
