import React, { type Node } from "react";
import { connect } from "react-redux";
import { withAuth } from "@swan/auth";
import { SwanContextManager } from "@swan/state";
import DefaultMenu from "./ProductsNavigation/DefaultMenu";
import OrganizationMenu from "./ProductsNavigation/OrganizationMenu";
import { GiHamburgerMenu, BsBuilding } from "react-icons/all";

import { userActions } from "../../state/reducers/user";
import {
  type switchOrganizationAction,
  type subscribeUser,
} from "../../state/reducers/user/actions";

import BrandHeader from "./BrandHeader";
import PageHeader from "./PageHeader";

import CoreUILayout from "./layoutProvider";
import { NAV_GROUPS } from "./config";

type Props = {
  children: Node,
  activeOrganization: Object,
  userOrganizations: Array<Object>,
  switchOrganization: switchOrganizationAction,
  subscribeUserLogin: subscribeUser,
  unsubscribeUserLogin: subscribeUser,
};

type State = {
  activeNavGroup: string,
};

class Layout extends React.Component<Props, State> {
  static defaultProps = {
    userName: undefined,
    userOrg: undefined,
    auth: undefined,
  };

  state = {
    activeNavGroup: NAV_GROUPS.DEFAULT,
  };

  componentDidMount() {
    const { subscribeUserLogin } = this.props;

    const userProfile = SwanContextManager.getValue("userProfile");
    if (userProfile && userProfile.id) {
      subscribeUserLogin(userProfile.id);
    }
  }

  componentWillUnmount(): void {
    const { unsubscribeUserLogin } = this.props;
    const userProfile = SwanContextManager.getValue("userProfile");

    if (userProfile && userProfile.id) {
      unsubscribeUserLogin(userProfile.id);
    }
  }

  primaryItems = [
    {
      id: NAV_GROUPS.DEFAULT,
      icon: GiHamburgerMenu,
      tooltip: "Home",
      isProductSwitcher: true,
    },
    {
      id: NAV_GROUPS.USER_ORGANIZATIONS,
      icon: BsBuilding,
      tooltip: "Organization Switcher",
    },
  ];

  render() {
    const { activeNavGroup } = this.state;
    const { children, ...restProps } = this.props;
    return (
      <CoreUILayout
        {...restProps}
        globalNavigation={{
          primaryItems: this.primaryItems,
          activeItem: activeNavGroup,
        }}
        sidebarHeader={{ component: BrandHeader }}
        productNavigations={{
          [NAV_GROUPS.DEFAULT]: props => <DefaultMenu {...props} />,
          [NAV_GROUPS.USER_ORGANIZATIONS]: props => (
            <OrganizationMenu {...props} />
          ),
        }}
        pageHeader={{ component: PageHeader }}
      >
        {children}
      </CoreUILayout>
    );
  }
}

function mapStateToProps({ user }) {
  const activeOrganization =
    ((user || {}).organizations || []).find(
      org => org.id === user.activeOrganization
    ) || {};
  return {
    activeOrganization,
    userOrganizations: user.organizations,
  };
}

export default withAuth(
  connect(
    mapStateToProps,
    {
      subscribeUserLogin: userActions.subscribeUserLogin,
      unsubscribeUserLogin: userActions.unsubscribeUserLogin,
    }
  )(Layout)
);
