// import { hasIn, map, filter, head, get } from "lodash";
import { getStore } from "../../state/store";

export const getJsonSchemaForLookup = (
  id: string
): {
  jsonSchema: {},
  jsonUiSchema: {},
} => {
  const store = getStore();
  const {
    app: { attributes, allDefinitions },
    schema: { newAttributes },
  } = store.getState();

  const relatedPen = allDefinitions[id];

  const attributesEnum = [];
  const attributesEnumNames = [];
  attributes.forEach(attr => {
    attributesEnum.push(attr.name);
    attributesEnumNames.push(attr.label);
  });
  newAttributes.forEach(attr => {
    attributesEnum.push(attr.attributeId);
    attributesEnumNames.push(attr.label);
  });

  let filters = {};
  let map = {};
  const { options } =
    (((relatedPen || {}).schema || {}).options || {}).relationMeta || {};
  if (options) {
    if (options.filters) {
      filters = {
        type: "object",
        title: "Filters",
        properties: {},
      };
      options.filters.forEach(filter => {
        if (filter.transparent) return; // Transparent filters are passed through without setup
        filters.properties[filter.attribute] = {
          title: filter.label,
          type: "string",
          enum: attributesEnum,
          enumNames: attributesEnumNames,
        };
      });
    }

    filters.properties.nopaging = {
      title: "Disable Paging",
      type: "boolean",
    };

    if (options.map) {
      map = {
        type: "object",
        title: "Values Map",
        properties: {},
      };
      options.map.forEach(filter => {
        if (filter.transparent) return; // Transparent maps are passed through without setup
        map.properties[filter.attribute] = {
          title: filter.label,
          type: "string",
          enum: attributesEnum,
          enumNames: attributesEnumNames,
        };
      });
    }
  }

  const jsonSchema = {
    type: "object",
    properties: Object.assign(
      {},
      {
        title: {
          type: "string",
          title: "Field Label",
        },
        required: {
          type: "boolean",
          title: "Required",
        },
        hideField: {
          type: "boolean",
          title: "Hide Field",
        },
        hideLabel: {
          type: "boolean",
          title: "Hide Label",
        },
        passFiltersToCreate: {
          type: "boolean",
          title: "Pass filters to create",
        },
        disableCache: {
          type: "boolean",
          title: "Disable Caching",
        },
        readOnly: {
          type: "boolean",
          title: "Read-only",
        },
        listed: {
          type: "boolean",
          title: "Listed",
        },
        columnOrderInCrudList: {
          type: "string",
          title: "Column Order (CRUD List)",
        },
        enableLookupInPopover: {
          type: "boolean",
          title: "Enable Lookup in Popover",
        },
        lookupIcon: {
          type: "string",
          title: "Lookup Icon String",
        },
        optionGroupedByKey: {
          type: "string",
          title: "Grouped by Column",
        },
        filters,
        map,
        customMap: {
          title: "Custom Maps",
          type: "array",
          items: {
            type: "object",
            required: ["from", "to"],
            properties: {
              from: {
                title: "From Field",
                type: "string",
              },
              to: {
                title: "To Field",
                type: "string",
              },
            },
          },
        },
      }
    ),
    required: ["title"],
    dependencies: {
      listMode: {
        oneOf: [
          {
            properties: {
              listMode: {
                enum: ["card"],
              },
              cardViewForm: {
                type: "number",
                title: "Card View Form",
                // enum: formsEnum,
                // enumNames: formsEnumNames,
              },
            },
          },
        ],
      },
    },
  };
  const jsonUiSchema = {};

  return {
    jsonSchema,
    jsonUiSchema,
  };
};

export default {
  getJsonSchemaForLookup,
};
