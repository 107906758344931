// @flow
// @todo this utility is similar to ./layoutProvider.js and need a revise this to avoide duplication if there's
// @todo API document

import React from "react";

// layouts
import layoutsLibrary from "../layouts";

// types
type NavigationConfigType = {
  name: string,
  navItems: Array<{}>,
  moduleName: string,
};

// default values
const defaultConfig = {
  name: "ak3",
  navItems: [],
  moduleName: "Dashboard",
};

export default (navigationConfig: NavigationConfigType = defaultConfig) => (
  navigationProps: any
) => {
  const Component =
    layoutsLibrary[navigationConfig.name].ProductNavigation ||
    layoutsLibrary.defaultLayout || // layoutsLibrary.defaultLayout.ProductNavigation: flow throws Property ProductNavigation is missing
    null;

  if (!Component) return null;
  return (
    <Component
      name={navigationConfig.name}
      navItems={navigationConfig.navItems}
      moduleName={navigationConfig.moduleName || "Dashboard"}
      {...navigationProps}
    />
  );
};
