import * as React from "react";
import withAuth from "./withAuth";

/**
 * Wraps a component and waits trigger preloading permissions specified but will not wait for them to render
 *
 * @param {Array<string>} permissions Array of permission names
 */
type Props = {
  auth: Object,
  children: React.Node,
  groups?: Array<string>,
  permissions?: Array<string>,
};

class PreloadPermissionsGate extends React.Component<Props> {
  static defaultProps = {
    permissions: undefined,
    groups: undefined,
  };

  componentDidMount() {
    const { permissions, groups, auth } = this.props;
    if (permissions) {
      auth.getPermissions(permissions);
    }
    if (groups) {
      if (groups.forEach) {
        groups.forEach(grp => auth.getPermissionsByGroup(grp));
      } else {
        auth.getPermissionsByGroup(groups);
      }
    }
  }

  render() {
    const { children } = this.props;
    return children;
  }
}

export default withAuth(PreloadPermissionsGate);
