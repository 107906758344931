import React, { Component } from "react";
import { FormGroup, Label, Input } from "reactstrap";
// import ReactQuill from "react-quill";
// import RemoteSelect from "./RemoteSelect";

// import "quill/dist/quill.snow.css";

// import { isValidNumber, parse } from "libphonenumber-js";
// import DatePicker from "react-datepicker";
// import "react-datepicker/dist/react-datepicker-cssmodules.css";
// import moment from "moment";

type Props = {
  name: string,
  value?: string | number | Object,
  onChange?: (event: Object) => void,
  label?: string,
  placeholder?: string,
  required?: boolean,
  type?: string,
  error?: string,
  className?: string,
  // defaultValue?: Object, // For date
  // showYear?: boolean,
  static?: boolean,
  // dateProps?: Object,
  checked?: boolean, // Radio button
  displayValue?: string, // Value to be displayed if static
  data?: Array<{
    id: string | number,
    label: string,
  }>, // Array of items for a select element
  style?: { [string]: string },

  itemLabelAccessor?: Object => string, // If provided will be used to get the label of select options
  // itemIdAccessor?: Object => string,
  // loadOptions?: Function,
  component?: Node, // Custom component
  // filter?: Array<any>,
  // Remote select attributes
  // selectedItem?: Object,
  // idAttribute?: string,
  // labelAttribute?: string,
  // service?: Object,

  children?: Node,
};
type State = {
  dateFocused: ?boolean,
};

class Field extends Component<Props, State> {
  static defaultProps = {
    static: false,
    onChange: () => {},
    label: undefined,
    placeholder: undefined,
    required: undefined,
    error: "",
    type: "phone",
    className: undefined,
    style: undefined,
    checked: undefined,
    displayValue: undefined,
    data: [],
    itemLabelAccessor: undefined,
    itemIdAccessor: undefined,
    children: undefined,
    value: undefined,
    component: undefined,
    filter: [],
    selectedItem: {},
    idAttribute: undefined,
    labelAttribute: undefined,
    loadOptions: undefined,
    service: undefined,
    defaultValue: undefined,
    showYear: undefined,
    dateProps: undefined,
  };

  // constructor(props: Props) {
  //   super(props);

  //   this.state = {
  //     dateFocused: false
  //   };

  //   this.onWysiwygChange = this.onWysiwygChange.bind(this);
  // }

  // onWysiwygChange(value) {
  //   const { onChange } = this.props;
  //   onChange({ target: { value } });
  // }

  readOnly() {
    const { label, name, displayValue, value, type, style } = this.props;

    let inputType = "text";
    if (type && type !== "remoteSelect") {
      inputType = type;
    }

    return (
      <FormGroup>
        {label && (
          <Label htmlFor={name}>
            {/* prettier-ignore */}
            {label}
          </Label>
        )}
        <Input
          type={inputType}
          id={name}
          plaintext
          value={displayValue || value}
          onChange={() => {}}
          style={style || {}}
        />
      </FormGroup>
    );
  }

  renderSelect() {
    const {
      type,
      name,
      value,
      placeholder,
      label,
      required,
      onChange,
      className,
      data,
      error,
      itemLabelAccessor,
      children,
      style,
    } = this.props;
    return (
      <Input
        type={type || "text"}
        id={name}
        placeholder={placeholder || label || ""}
        required={required || false}
        defaultValue={value}
        onChange={onChange}
        className={`${className || ""} ${error !== "" ? "is-invalid" : ""}`}
        style={style}
      >
        {data &&
          data.map(item => (
            <option value={item.id} key={item.id}>
              {itemLabelAccessor ? itemLabelAccessor(item) : item.label}
            </option>
          ))}
        {children}
      </Input>
    );
  }

  renderRadio() {
    const {
      name,
      value,
      placeholder,
      label,
      required,
      onChange,
      className,
      error,
      checked,
    } = this.props;
    const id = `${name}_${String(value)
      .replace(" ", "_")
      .toLocaleLowerCase()}`;
    return (
      <FormGroup check>
        <Input
          type="radio"
          id={id}
          name={name}
          placeholder={placeholder || label || ""}
          required={required || false}
          value={value}
          onChange={onChange}
          className={`${className || ""} ${error !== "" ? "is-invalid" : ""}`}
          checked={checked}
        />
        {label && (
          <Label htmlFor={id}>
            {/* prettier-ignore */}
            {label}
          </Label>
        )}
      </FormGroup>
    );
  }

  // renderWysiwyg() {
  //   const modules = {
  //     toolbar: [
  //       ["bold", "italic", "underline", "strike"], // toggled buttons
  //       ["blockquote", "code-block"],
  //       [{ header: 1 }, { header: 2 }], // custom button values
  //       [{ list: "ordered" }, { list: "bullet" }],
  //       [{ script: "sub" }, { script: "super" }], // superscript/subscript
  //       [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
  //       [{ direction: "rtl" }], // text direction
  //       [{ size: ["small", false, "large", "huge"] }], // custom dropdown
  //       [{ header: [1, 2, 3, 4, 5, 6, false] }],
  //       [{ color: [] }, { background: [] }], // dropdown with defaults from theme
  //       [{ font: [] }],
  //       [{ align: [] }],
  //       ["clean"], // remove formatting button
  //       ["link", "video", "image"],
  //     ],
  //   };
  //   const { value } = this.props;

  //   return (
  //     <ReactQuill
  //       value={value}
  //       modules={modules}
  //       onChange={this.onWysiwygChange}
  //     />
  //   );
  // }

  // renderRemoteSelect() {
  //   const {
  //     selectedItem,
  //     value,
  //     onChange,
  //     idAttribute,
  //     labelAttribute,
  //     itemLabelAccessor,
  //     service,
  //     filter,
  //     itemIdAccessor,
  //     loadOptions,
  //   } = this.props;
  //   return (
  //     <RemoteSelect
  //       selectedItem={selectedItem}
  //       onChange={onChange}
  //       value={value}
  //       idAttribute={idAttribute}
  //       idAccessor={itemIdAccessor}
  //       labelAttribute={labelAttribute}
  //       service={service}
  //       loadOptions={loadOptions}
  //       filter={filter}
  //       labelAccessor={itemLabelAccessor}
  //     />
  //   );
  // }

  // renderDate() {
  //   return (
  //     <DatePicker
  //       selected={
  //         this.props.value
  //           ? moment(this.props.value)
  //           : this.props.defaultValue || null
  //       }
  //       onChange={start => this.props.onChange({ target: { value: start } })}
  //       className={`form-control ${this.props.className || ""} ${
  //         this.props.error !== "" ? "is-invalid" : ""
  //       }`}
  //       dateFormat="dddd DD MMM, YYYY"
  //       showYearDropdown={this.props.showYear || false}
  //       showMonthDropdown={this.props.showYear || false}
  //       scrollableYearDropdown={this.props.showYear || false}
  //       yearDropdownItemNumber={100}
  //       utcOffset={0}
  //       {...this.props.dateProps}
  //     />
  //   );
  // }

  // renderDatetime() {
  //   return (
  //     <DatePicker
  //       selected={
  //         this.props.value
  //           ? moment(this.props.value)
  //           : this.props.defaultValue || null
  //       }
  //       onChange={start => this.props.onChange({ target: { value: start } })}
  //       className={`form-control ${this.props.className || ""} ${
  //         this.props.error !== "" ? "is-invalid" : ""
  //       }`}
  //       dateFormat="dddd LLL"
  //       showYearDropdown={this.props.showYear || false}
  //       showMonthDropdown={this.props.showYear || false}
  //       scrollableYearDropdown={this.props.showYear || false}
  //       yearDropdownItemNumber={100}
  //       showTimeSelect
  //       {...this.props.dateProps}
  //     />
  //   );
  // }

  // renderTime() {
  //   return (
  //     <DatePicker
  //       selected={
  //         this.props.value
  //           ? moment(this.props.value)
  //           : this.props.defaultValue || null
  //       }
  //       onChange={start => this.props.onChange({ target: { value: start } })}
  //       className={`form-control ${this.props.className || ""} ${
  //         this.props.error !== "" ? "is-invalid" : ""
  //       }`}
  //       dateFormat="LT"
  //       showTimeSelect
  //       showTimeSelectOnly
  //       {...this.props.dateProps}
  //     />
  //   );
  // }

  render() {
    const {
      static: staticField,
      type,
      label,
      name,
      placeholder,
      required,
      value,
      onChange,
      className,
      error,
      component,
    } = this.props;
    if (staticField) {
      return this.readOnly();
    }
    if (type === "radio") {
      return this.renderRadio();
    }
    let fieldComponent;
    switch (type) {
      case "select":
        fieldComponent = this.renderSelect();
        break;
      // case "phone":
      //   fieldComponent = this.renderPhone();
      //   break;
      // case "date":
      //   fieldComponent = this.renderDate();
      //   break;
      // case "datetime":
      //   fieldComponent = this.renderDatetime();
      //   break;
      // case "time":
      //   fieldComponent = this.renderTime();
      //   break;
      // case "html":
      //   fieldComponent = this.renderWysiwyg();
      //   break;
      case "custom":
        fieldComponent = component;
        break;
      // case "remoteSelect":
      //   fieldComponent = this.renderRemoteSelect();
      //   break;
      default:
        fieldComponent = (
          <Input
            type={type || "text"}
            id={name}
            placeholder={placeholder || label || ""}
            required={required || false}
            value={value}
            onChange={onChange}
            className={`${className || ""} ${error !== "" ? "is-invalid" : ""}`}
          />
        );
    }
    return (
      <FormGroup>
        {label && (
          <Label htmlFor={name}>
            {/* prettier-ignore */}
            {label}
          </Label>
        )}
        {fieldComponent}
      </FormGroup>
    );
  }
}

// Field.propTypes = {
//   children: PropTypes.element,
//   static: PropTypes.bool,
//   name: PropTypes.string.isRequired,
//   value: PropTypes.oneOfType([
//     PropTypes.string,
//     PropTypes.number,
//     PropTypes.object
//   ]),
//   onChange: PropTypes.func,
//   label: PropTypes.string,
//   placeholder: PropTypes.string,
//   required: PropTypes.bool,
//   type: PropTypes.string,
//   error: PropTypes.string,
//   className: PropTypes.string,
//   style: PropTypes.objectOf(PropTypes.string),
//   checked: PropTypes.bool, // Radio button
//   displayValue: PropTypes.string, // Value to be displayed if static
//   data: PropTypes.arrayOf(
//     PropTypes.shape({
//       id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
//       label: PropTypes.string
//     })
//   ), // Array of items for a select element
//   itemLabelAccessor: PropTypes.func, // If provided will be used to get the label of select options
//   itemIdAccessor: PropTypes.func,
//   loadOptions: PropTypes.func,
//   component: PropTypes.element, // Custom component
//   filter: PropTypes.arrayOf(PropTypes.any),
//   // Remote select attributes
//   selectedItem: PropTypes.objectOf(PropTypes.any),
//   idAttribute: PropTypes.string,
//   labelAttribute: PropTypes.string,
//   service: PropTypes.objectOf(PropTypes.any)
// };

export default Field;
