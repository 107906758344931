import React from "react";
import { uriProvider } from "./utils/uriHelpers";
import {
  MODULE_NAME,
  MODULE_DISPLAY_NAME,
  MODULE_SHORT_DISPLAY_NAME,
} from "./config";
import GroupService from "./services/group";

// $FlowFixMe Should be React.lazy<DashboardProps> but causing issue in webpack
const Home = React.lazy(() => import("./Module"));

// https://github.com/ReactTraining/react-router/tree/master/packages/react-router-config
const entry_routes = [
  {
    path: uriProvider(),
    name: MODULE_NAME,
    component: Home,
    Permission: ["module:reporting"],
  },
];

export const getProductNavDefinition = () => {
  const groupService = new GroupService();
  const listOfGroups = [];
  return groupService.find().then(response => {
    if (response && response.data) {
      response.data.map(item => {
        listOfGroups.push({
          name: item.name,
          url: uriProvider(`groups/${item.id}`),
          icon: "fa-cogs",
          permissions: ["report:view"],
        });
        return true;
      });
    }

    return listOfGroups;
  });
};

export default {
  name: MODULE_NAME,
  displayName: MODULE_DISPLAY_NAME,
  mainNav: {
    name: MODULE_DISPLAY_NAME,
    url: uriProvider("dashboard"),
    icon: "fa-cogs",
    permissions: ["module:reporting"],
    color: "#8777D9",
    shortName: MODULE_SHORT_DISPLAY_NAME,
    weight: 90,
  },
  preloadPermissions: {
    groups: ["bi"],
  },
  productNav: [
    {
      name: "Configuration",
      url: uriProvider(`configuration`),
      icon: "fa-cogs",
      permissions: ["reporting-configuration:view"],
    },
    {
      name: "New Report",
      url: uriProvider("reports/new"),
      icon: "fa-cogs",
      permissions: ["report:create"],
    },
    {
      name: "Groups",
      header: true,
    },
    {
      name: "My BI Workspace",
      url: uriProvider("my-bi-workspace"),
      icon: "fa-cogs",
      permissions: ["report:view"],
    },
    {
      name: "Shared With me",
      url: uriProvider("shared-with-me"),
      icon: "fa-cogs",
      permissions: ["report:view"],
    },
  ],
  routes: entry_routes,
};
