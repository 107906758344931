// @flow
export const GROUP_DEFAULT: string = "default";
export const GROUP_ADVANCED: string = "advanced";

export const groupsOrderFallback: [string, string] = [
  GROUP_DEFAULT,
  GROUP_ADVANCED,
]; // first will be the active tab

export const quickActionsDisabled = [
  "relatedObject",
  "resourceDisplay",
  "formula",
  "displayText",
  "singleOn",
  "objectPrint",
];

export const optionsFallbackDisabled = [
  "containerWithTitle",
  "resourceDisplay",
  "formula",
  "displayText",
  "singleOn",
  "workflowActionsButtons",
  "workflowStatusSummary",
  "objectPrint",
];

export const optionsFallback: { [string]: $ReadOnlyArray<mixed> } = {
  // DEFAULT
  title: ["Undefined", GROUP_DEFAULT], // must
  required: [false, GROUP_DEFAULT],
  default: ["", GROUP_DEFAULT],
  placeholder: ["", GROUP_DEFAULT],
  description: ["", GROUP_DEFAULT],
  help: ["", GROUP_DEFAULT],
  searchable: [false, GROUP_DEFAULT],
  listed: [false, GROUP_DEFAULT],
  columnOrderInCrudList: [0, GROUP_DEFAULT],

  // ADVANCED
  classNames: ["", GROUP_ADVANCED],
  disabled: [false, GROUP_ADVANCED],
  autofocus: [false, GROUP_ADVANCED],
  readOnly: [false, GROUP_ADVANCED],
  isJson: [false, GROUP_ADVANCED],
  disableWysiwyg: [false, GROUP_ADVANCED],
  isTiptap: [false, GROUP_ADVANCED],
  hideLabel: [false, GROUP_ADVANCED],
  allowDuplicate: [true, GROUP_ADVANCED],
  // isEncrypted: [false, GROUP_ADVANCED],
  hideField: [false, GROUP_ADVANCED],
  listedOrder: [0, GROUP_DEFAULT],
};

export default {};
