import * as React from "react";
import { LocationInput } from "@swan/themer";

import Field from "./partials/Field";
import type { ElementProps } from "./types";

export type MapFieldProps = ElementProps & {
  onMapLoad?: (map: any) => void,
  minHeight: number,
  defaultLat: number,
  defaultLng: number,
  primaryAddressComponent: string,
};

const MapField = ({
  schema,
  onChange,
  error,
  minHeight,
  onMapLoad,
  data,
  hideLabel,
  primaryAddressComponent,
  ...restProps
}: MapFieldProps) => {
  const mapDef = (schema.options || {}).addressComponentsMap || {};
  return (
    <LocationInput
      {...schema.options || {}}
      {...restProps}
      name={schema.attributeName || ""}
      label={schema.label}
      hideLabel={hideLabel || (schema.options || {}).hideLabel}
      onMapLoad={onMapLoad}
      minHeight={minHeight}
      value={(data || {})[schema.attributeName || ""]}
      valueLat={(data || {})[mapDef.latitude || ""]}
      valueLng={(data || {})[mapDef.longitude || ""]}
      onChange={e => {
        if (onChange) {
          onChange(
            schema.attributeName || "",
            (e.target.value || {})[primaryAddressComponent || "address"]
          );
          if (mapDef) {
            Object.keys(mapDef).forEach(map => {
              // make sure that the value passed is a string
              let addressValue =
                ((e.target.value || {}).addressParts || {})[map] || "";
              if (Array.isArray(addressValue)) {
                addressValue = addressValue.join(" ");
              }
              if (map === "country") {
                onChange(mapDef[map], (addressValue || "").toLowerCase());
              } else {
                onChange(mapDef[map], addressValue || "");
              }
            });
          }
        }
      }}
      error={(error || {})[schema.attributeName || ""]}
    />
  );
};

export default Field(MapField);
