import React, { Component } from "react";

type Props = {
  id?: number | string,
  defaultValues?: Object,
  listId?: string,
  record?: Object,
};

type State = {
  record: Object,
  isFetching: boolean,
  isSaving: boolean,
  error: string,
  saveResult: ?boolean,
  defaultValues: ?{},
};

const RecordProvider = (Service: Object) => (FormComponent: any) => {
  class WrappedComponent extends Component<Props, State> {
    static defaultProps = {
      id: undefined,
      defaultValues: [],
      listId: undefined,
      record: null,
    };

    static resetUpdate() {}

    constructor(props: Props) {
      super(props);

      this.state = {
        record: props.record || null,
        isFetching: false,
        isSaving: false,
        error: "",
        saveResult: null,
        defaultValues: props.defaultValues || {},
      };
      if (typeof Service === "object") {
        this.service = Service;
      } else {
        this.service = new Service();
      }
      this.updateRecord = this.updateRecord.bind(this);
      this.createRecord = this.createRecord.bind(this);
      this.deleteRecord = this.deleteRecord.bind(this);
    }

    getId(receivedId: number | string) {
      const { id } = this.props;
      return id || receivedId;
    }

    loadRecord = (recId: number | string) => {
      const id = this.getId(recId);
      if (id === "new" || !id) {
        const { defaultValues } = this.state;
        this.setState({
          record: {
            id: "new",
            ...defaultValues,
          },
        });
        return;
      }
      this.setState(
        {
          isFetching: true,
        },
        () => {
          this.service
            .get(id)
            .then(response => {
              this.setState({
                isFetching: false,
                record: response,
              });
            })
            .catch(e => {
              this.setState({
                isFetching: false,
                error: e.message,
              });
            });
        }
      );
    };

    updateRecord = (recId: number | string, data: Object) => {
      const { record } = this.state;
      const id = this.getId(recId);
      // $FlowFixMe
      return new Promise((resolve, reject) => {
        this.setState(
          {
            isSaving: true,
            error: "",
            saveResult: null,
            record: {
              ...record,
              ...data,
            },
          },
          () => {
            this.service
              .update(id, data)
              .then(() => {
                this.setState({
                  isSaving: false,
                  saveResult: true,
                });
                this.refreshList();
                resolve();
              })
              .catch(e => {
                this.setState({
                  isSaving: false,
                  error: e.message,
                  saveResult: false,
                });
                reject();
              });
          }
        );
      });
    };

    createRecord = (data: Object) => {
      const { defaultValues } = this.state;
      // $FlowFixMe
      return new Promise((resolve, reject) => {
        this.setState(
          {
            isSaving: true,
            error: "",
            saveResult: null,
            record: {
              ...data,
            },
          },
          () => {
            this.service
              .create({
                ...(defaultValues || {}),
                ...data,
              })
              .then(() => {
                this.setState({
                  isSaving: false,
                  saveResult: true,
                });
                this.refreshList();
                resolve();
              })
              .catch(e => {
                this.setState({
                  isSaving: false,
                  error: e.message,
                  saveResult: false,
                });
                reject();
              });
          }
        );
      });
    };

    deleteRecord = (recId: number | string) => {
      const id = this.getId(recId);
      // $FlowFixMe
      return new Promise((resolve, reject) => {
        this.setState(
          {
            isSaving: true,
            error: "",
            saveResult: null,
          },
          () => {
            this.service
              .delete(id)
              .then(() => {
                this.setState({
                  isSaving: false,
                  saveResult: true,
                });
                this.refreshList();
                resolve();
              })
              .catch(e => {
                this.setState({
                  isSaving: false,
                  error: e.message,
                  saveResult: false,
                });
                reject();
              });
          }
        );
      });
    };

    refreshList() {
      // Check if a listId is provided, trigger a refresh event
      const { listId } = this.props;
      if (listId) {
        const event = new Event(`${listId}::refresh`);
        window.dispatchEvent(event);
      }
    }

    service: Object;

    render() {
      const { record: propsRecord, defaultValues } = this.props;
      const { record, isFetching, isSaving, error, saveResult } = this.state;
      return (
        <FormComponent
          {...this.props}
          record={{ ...(defaultValues || {}), ...propsRecord, ...record }}
          isFetching={isFetching}
          isSaving={isSaving}
          error={error}
          saveResult={saveResult}
          resetUpdate={this.constructor.resetUpdate}
          loadRecord={this.loadRecord}
          updateRecord={this.updateRecord}
          createRecord={this.createRecord}
          deleteRecord={this.deleteRecord}
        />
      );
    }
  }

  return WrappedComponent;
};

export default RecordProvider;
