import React from "react";
import { uriProvider } from "./utils/uriHelpers";
import {
  MODULE_NAME,
  MODULE_DISPLAY_NAME,
  MODULE_SHORT_DISPLAY_NAME,
} from "./config";

// $FlowFixMe Should be React.lazy<DashboardProps> but causing issue in webpack
const Home = React.lazy(() => import("./Module"));

// https://github.com/ReactTraining/react-router/tree/master/packages/react-router-config
const entry_routes = [
  {
    path: "/ppctieringtool",
    name: MODULE_NAME,
    component: Home,
    Permission: ["module:ppctieringtool"],
  },
];

export default {
  name: MODULE_NAME,
  displayName: MODULE_DISPLAY_NAME,
  mainNav: {
    name: MODULE_DISPLAY_NAME,
    url: uriProvider(),
    icon: "fa-cogs",
    permissions: ["module:ppctieringtool"],
    color: "#B3D4FF",
    shortName: MODULE_SHORT_DISPLAY_NAME,
    weight: 70,
  },
  preloadPermissions: {
    groups: ["legal", "core"],
  },
  productNav: [
    {
      name: "Dashboard",
      url: uriProvider("dashboard"),
      collapseOnClick: true,
      permissions: ["module:ppctieringtool"],
    },
    {
      name: "Tiering Tool",
      header: true,
      permissions: ["module:ppctieringtool"],
    },
    {
      name: "Configurations",
      url: uriProvider("configuration"),
      collapseOnClick: true,
      permissions: ["ppcttMasters:view"],
    },
  ],
  routes: entry_routes,
};
