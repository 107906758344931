// @flow
import { SwanContextManager } from "@swan/state";
import { operators as operatorsFunctions } from "../index";

// utils
import processResource from "./processResource";

// types
import { type Condition } from "../flowTypes";

export const conditionEvaluator = (
  conditionBlock: Condition,
  {
    attributeValues,
  }: {
    attributeValues: Object,
  }
) => {
  const { operandLeft, operandRight, operator } = conditionBlock;

  let lOperand: ?string;
  let rOperand: ?string;
  let operatorFunction: ?Function;

  // if operation function is available
  // then the rest will be evaluated
  if (operator && Object.keys(operatorsFunctions).indexOf(operator) !== -1) {
    // assign operator function
    operatorFunction = operatorsFunctions[operator];

    // LHS value
    if (operandLeft) {
      if (operandLeft.type === "ATTRIBUTE") {
        const { inputName: attributeName, key, cascaded } = processResource(
          operandLeft
        );
        // treat it as direct attributeName is given
        if (
          attributeName &&
          ((attributeValues || {})[key] || {})[attributeName]
        ) {
          lOperand = attributeValues[key][attributeName];
          // else, from cascaded, treat it as array of path given
        } else if (Array.isArray(cascaded) && cascaded.length) {
          // splicing the first value = object
          // cascaded.splice(0, 1);
          // lOperand = cascaded.reduce((a, c) => a[c] || a, attributeValues[key]);
        }
      }

      if (operandLeft.type === "PERMISSION") {
        // since we encounter an specific case only, this condition is like plain
        // this needs an improvement
        if (
          operandLeft.data &&
          (operandLeft.data || {}).of &&
          operandLeft.data.of === "_USER"
        ) {
          const whoami = (SwanContextManager.getValue("userProfile") || {}).id;
          if (whoami) lOperand = whoami;
        }
      }
    }

    // RHS value
    if (operandRight) {
      const { inputValue } = processResource(operandRight);
      if (inputValue) rOperand = inputValue;
    }
  }

  // Operator function HOF
  // if (operatorFunction && rOperand && lOperand) {
  if (operatorFunction) {
    return operatorFunction(rOperand)(lOperand);
  }

  return false;
};

// const evalPatternParser = (evalPattern: string) => {
//   let parsedPattern = evalPattern;

//   parsedPattern = parsedPattern.replace(/ AND /gm, " && ");
//   parsedPattern = parsedPattern.replace(/ OR /gm, " || ");

//   return parsedPattern;
// };

export const ruleEvaluator = (
  rule: Object,
  data: Object,
  returnActions: boolean = true
): Array<Object> | boolean => {
  let actionsResulted = returnActions ? [] : false;

  // 1. every rule has set of conditions
  const { conditions: conditionsBlock } = rule;
  if (conditionsBlock && conditionsBlock.length) {
    conditionsBlock.forEach(conditionBlock => {
      // 2. every condition is checked its primary condition before evaluating sub-conditions
      if (
        conditionEvaluator(conditionBlock, {
          attributeValues: data,
        }) === true
      ) {
        const isSubConditionsBlock =
          Object.keys(conditionBlock).indexOf("conditions") !== -1 &&
          conditionBlock.conditions.length;
        const isMasterActions =
          Object.keys(conditionBlock).indexOf("actions") !== -1 &&
          conditionBlock.actions.length;

        // process sub conditions, if there's no master actions
        if (isSubConditionsBlock) {
          const { conditions: subConditionsBlock } = conditionBlock;

          // going over set of sub-conditions
          subConditionsBlock.forEach(subConditionBlock => {
            // 4. every sub-condition
            // has conditions + actions
            const {
              conditions: evalConditions,
              evalPattern,
              actions: evalActions,
            } = subConditionBlock;
            if (evalActions) {
              const subConditionsEvaluated = {};
              evalConditions.forEach(evalCondition => {
                Object.assign(subConditionsEvaluated, {
                  [evalCondition.id]: conditionEvaluator(evalCondition, {
                    attributeValues: data,
                  }),
                });
              });
              // 4.1 once these conditions Array<condition> are evaluated against its evalPattern
              // 4.2 process the actions Array<action>
              if (evalPattern === "ALL_AND") {
                if (
                  Object.values(subConditionsEvaluated).indexOf(false) === -1
                ) {
                  actionsResulted =
                    returnActions && Array.isArray(actionsResulted)
                      ? [...actionsResulted, ...evalActions]
                      : true;
                }
              }
              if (evalPattern === "ALL_OR") {
                if (
                  Object.values(subConditionsEvaluated).indexOf(true) !== -1
                ) {
                  actionsResulted =
                    returnActions && Array.isArray(actionsResulted)
                      ? [...actionsResulted, ...evalActions]
                      : true;
                }
              }
              // @todo for custom eval pattern
              // console.log(subConditionsEvaluated);
            }
          });
        }

        // process master actions and that's it
        if (!isSubConditionsBlock && isMasterActions) {
          const { actions: masterActions } = conditionBlock;
          actionsResulted =
            returnActions && Array.isArray(actionsResulted)
              ? [...actionsResulted, ...masterActions]
              : true;
        }
      }
    });
  }

  return actionsResulted;
};

export default ruleEvaluator;
