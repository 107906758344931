/* eslint-disable react/no-multi-comp */
import * as React from "react";
import { withRouter, type Location } from "react-router-dom";
import memoize from "memoize-one";
import styled from "styled-components";
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import classNames from "classnames";
import * as Icons from "react-icons/fa";

type TabsProps = {
  tabs: Array<{
    label: string,
    component: React.Node,
    route?: string,
    options?: Object,
    nav?: React.Node,
  }>,
  vertical?: boolean,
  pills?: boolean,
  defaultActive?: number,
  location: Location,
  history: Object,
  customClassNames?: string,
};

type TabsState = {
  activeTab: number,
  openedTabs: Array<number>,
};

export const TabsWrapper = styled.div(({ theme, direction }) =>
  Object.assign(
    {},
    theme.TabsWrapper ? { ...theme.TabsWrapper } : {},
    direction === "vertical"
      ? {
          display: "flex",
          flexDirection: "row",
        }
      : {}
  )
);

const TabsNavWrapper = styled(Nav)`
  ${({ theme }) => (theme.TabsNavWrapper ? { ...theme.TabsNavWrapper } : "")}
`;

const TabContentWrapper = styled.div(({ theme, direction }) =>
  Object.assign(
    {},
    theme.TabContentWrapper ? { ...theme.TabContentWrapper } : {},
    direction === "vertical"
      ? {
          flexGrow: 1,
        }
      : {}
  )
);

const TabLink = styled(NavLink)(({ direction, pills }) =>
  Object.assign(
    {},
    direction === "vertical" && pills !== "true"
      ? { paddingLeft: 0, paddingRight: "1rem" }
      : {},
    direction === "vertical" && pills === "true"
      ? {
          paddingLeft: "3rem",
          paddingRight: "3rem",
        }
      : {}
  )
);

class FirstTab extends React.Component<{ handleLoaded: Function }> {
  componentDidMount() {
    const { handleLoaded } = this.props;
    if (handleLoaded && handleLoaded instanceof Function) {
      handleLoaded();
    }
  }

  render() {
    return null;
  }
}

class Tabs extends React.Component<TabsProps, TabsState> {
  static defaultProps = {
    vertical: false,
    pills: false,
    defaultActive: 0,
    customClassNames: undefined,
  };

  constructor(props: TabsProps) {
    super(props);
    this.state = {
      activeTab: 0,
      openedTabs: [0],
    };
  }

  componentDidMount() {
    const { defaultActive } = this.props;
    if (defaultActive) {
      this.setState({
        activeTab: defaultActive,
      });
    }
  }

  getTabIndexFromRoute = memoize((route: string) => {
    const { tabs } = this.props;
    let idx = null;
    tabs.forEach((t, i) => {
      if (t.route === route) {
        idx = i;
      }
    });
    return idx;
  });

  getActiveTab() {
    const { activeTab } = this.state;
    const {
      location: { pathname },
    } = this.props;
    const tab = this.getTabIndexFromRoute(pathname);
    return tab || activeTab;
  }

  handleLoaded = () => {
    const tabs = document.getElementsByClassName("tabs-wrapper");
    if (tabs && tabs[0]) {
      tabs[0].classList.add("tabs-wrapper-first-of-type");
    }

    const tabsNav = document.getElementsByClassName("tabs-nav");
    if (tabsNav && tabsNav[0]) {
      tabsNav[0].classList.add("tabs-nav-first-of-type");

      if (tabsNav[0].clientWidth >= tabsNav[0].scrollWidth) {
        tabsNav[0].classList.add("tabs-nav-compact");
      }
    }
  };

  goToTab = (activeTab: number) => {
    const { tabs, history } = this.props;
    if (tabs[activeTab].route) {
      history.replace(tabs[activeTab].route);
    }
    this.setState(state => {
      const { openedTabs } = state;
      const newOpened = [...openedTabs];
      if (openedTabs.indexOf(activeTab) === -1) {
        newOpened.push(activeTab);
      }
      return { activeTab, openedTabs: newOpened };
    });
  };

  getFirstTab = () => {
    const firstTab = document.getElementsByClassName("tabs-nav-first-of-type");
    if (firstTab && firstTab[0]) {
      return firstTab[0];
    }
    return undefined;
  };

  scrollTop = () => {
    const firstTab = this.getFirstTab();
    if (firstTab) {
      firstTab.scrollLeft = 0;
    }
  };

  scrollBottom = () => {
    const firstTab = this.getFirstTab();
    if (firstTab) {
      firstTab.scrollLeft = firstTab.scrollWidth - firstTab.clientWidth;
    }
  };

  handleKeyPress = () => {};

  render() {
    const { tabs, vertical, pills, customClassNames } = this.props;
    const { openedTabs } = this.state;
    const activeTab = this.getActiveTab();
    return (
      <TabsWrapper
        className={`tabs-wrapper swan-tabs-wrapper ${customClassNames || ""}`}
        direction={vertical ? "vertical" : "horizontal"}
      >
        <FirstTab handleLoaded={this.handleLoaded} />

        <TabsNavWrapper
          className="tabs-nav swan-tabs-nav"
          tabs={!pills}
          pills={pills}
          vertical={vertical}
        >
          {tabs.map((tab, tabIndex) => {
            let Icon;
            if (((tab || {}).options || {}).iconName) {
              Icon = Icons[((tab || {}).options || {}).iconName] || undefined;
            }
            return (
              <NavItem key={`ni-${tab.label}`}>
                <TabLink
                  // className={activeTab === tabIndex ? "active" : ""}
                  className={classNames({
                    "tab-link": true,
                    active: activeTab === tabIndex,
                    "label-only": !Icon,
                  })}
                  onClick={() => this.goToTab(tabIndex)}
                  direction={vertical ? "vertical" : "horizontal"}
                  pills={pills ? "true" : undefined}
                >
                  {Icon ? (
                    <span className="icon">
                      <Icon />
                    </span>
                  ) : null}
                  <span
                    className={classNames({ label: true, "label-only": !Icon })}
                  >
                    {tab.label}
                  </span>
                </TabLink>
              </NavItem>
            );
          })}
        </TabsNavWrapper>

        <div className="mobile-navigator">
          <div className="arrows">
            <span
              id="mobile-tab-navigator-left-arrow"
              className="arrow left-arrow"
              role="button"
              onKeyPress={this.handleKeyPress}
              onClick={this.scrollTop}
            >
              <Icons.FaAngleLeft />
            </span>
            <span
              id="mobile-tab-navigator-right-arrow"
              className="arrow right-arrow"
              role="button"
              onKeyPress={this.handleKeyPress}
              onClick={this.scrollBottom}
            >
              <Icons.FaAngleRight />
            </span>
          </div>
        </div>

        {tabs.map((tab, index) => activeTab === index && tab.nav)}

        <TabContentWrapper
          className="tabs-content swam-tabs-content"
          empty={tabs.length === 0}
          direction={vertical ? "vertical" : "horizontal"}
        >
          <TabContent activeTab={activeTab}>
            {tabs.map((tab, tabIndex) => (
              <TabPane tabId={tabIndex} key={`tabpane-${tab.label}`}>
                {openedTabs.indexOf(tabIndex) !== -1 && tab.component}
              </TabPane>
            ))}
          </TabContent>
        </TabContentWrapper>
      </TabsWrapper>
    );
  }
}

export default withRouter(Tabs);
