import GridComponent from "./Grid";
import {
  type GridProps as GridProperties,
  type GridOptions as GridOpts,
  type Columns as GridCols,
} from "./types";

export const Grid = GridComponent;
export type GridProps = GridProperties;
export type GridOptions = GridOpts;
export type GridColumns = GridCols;
export default GridComponent;
