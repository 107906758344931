import React from "react";

// $FlowFixMe
const Notifications = React.lazy(() => import("../pages/Notifications"));

// https://github.com/ReactTraining/react-router/tree/master/packages/react-router-config
const routes = [
  // { path: "/", name: "Home", component: DefaultLayout, exact: true },
  {
    // TODO remove this in the future as this is moved to userzone added on 08/07/2020, remove the page after one month
    path: "/notifications",
    name: "My Notifications",
    component: Notifications,
  },
];

export default routes;
