// @flow
import React, { PureComponent } from "react";
import { Droppable } from "react-beautiful-dnd";
import { map } from "lodash";

import styled, { withTheme } from "styled-components";

// components
import Pencil from "./Pencil";

// types
import type { PencilType, theme as themeType } from "../types";

const PencilBoxWrapper = styled.div(({ theme }) =>
  theme.PencilBoxWrapper ? { ...theme.PencilBoxWrapper } : {}
);

type Props = {
  pencilsGroup: string,
  pencils: Array<PencilType>,
  theme: themeType,
  rowItems: number,
  pencilsCount: number,
};

const getLastRowIndices = (
  itemsPerRow: number,
  totalPencils: number
): Array<number> => {
  const indices: Array<number> = [];
  let lastRowCount: number = itemsPerRow;
  const lastRowRemainder: number = totalPencils % itemsPerRow;
  if (lastRowRemainder !== 0) lastRowCount = lastRowRemainder;
  for (let i = 1; i < lastRowCount + 1; i += i) {
    indices.push(totalPencils - i);
  }
  return indices;
};

const PencilBox = class PencilBox extends PureComponent<Props> {
  render() {
    const {
      pencilsGroup,
      pencils,
      theme: { PencilClasses },
      rowItems,
      pencilsCount,
    } = this.props;

    return (
      <Droppable droppableId={pencilsGroup} isDropDisabled>
        {(droppableProvided, droppableSnapshot) => (
          <PencilBoxWrapper
            ref={droppableProvided.innerRef}
            {...droppableProvided.droppableProps}
            isDraggingOver={droppableSnapshot.isDraggingOver}
            className={PencilClasses.PencilBoxWrapper || "pencil-box-wrapper"}
          >
            {map(pencils, (pencil: PencilType, index: number) => (
              <Pencil
                key={pencil.id}
                {...{ index, pencil }}
                rowItems={rowItems}
                isLastRow={
                  getLastRowIndices(rowItems, pencilsCount).indexOf(index) !==
                  -1
                }
                isEven={!((index + 1) % rowItems)}
              />
            ))}

            {droppableProvided.placeholder}
          </PencilBoxWrapper>
        )}
      </Droppable>
    );
  }
};

export default withTheme(PencilBox);
