import React from "react";
import styled from "styled-components";

export const Title = (props: any) => {
  const { variant, children, ...restProps } = props;
  const Component = styled(variant || "h1")`
    &.column-title {
      color: #3d70b2;
      padding: 0.875rem 1.1rem;
      font-weight: bold;
      font-size: 0.875rem;
      box-shadow: 0px 1px 1px #e5e5e5;
    }
  `;

  return <Component {...restProps}>{children}</Component>;
};

export const Board = styled.div`
  position: relative;
  display: flex;
  flex: 1 1 auto;
  overflow: auto;
  flex-direction: column;

  .board-container {
    display: flex;
    flex-direction: row;
    // spacing
    margin: ${(props: any) => (props.spacing || {}).margin || 0}rem;
    padding: ${(props: any) => (props.spacing || {}).padding || 0}rem;
  }

  .board-title {
    // spacing
    margin: ${(props: any) => (props.spacing || {}).margin || 0}rem;
    padding: ${(props: any) => (props.spacing || {}).padding || 0}rem;
  }

  // overrides
  ${(props: any) => props.customStyle}
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 1rem;

  // size
  min-width: ${(props: any) => props.size || 300}px;
  max-width: ${(props: any) => props.size || 300}px;

  // spacing
  margin: ${(props: any) => (props.spacing || {}).margin || 0}rem;
  padding: ${(props: any) => (props.spacing || {}).padding || 0}rem;

  border: ${(props: any) => props.borderStyle};

  border-radius: 4px;
  background-color: #eee;

  // overrides
  ${(props: any) => props.customStyle}

  .column-title {
  }

  .column-body {
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow-y: auto;
    margin: 0;
    padding: 0.8rem 0.8rem;
  }

  .column-pager {
    padding: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #f5f5f5;
    font-size: 0.9rem;
  }

  .column-footer {
    padding: 0.9rem;
    font-size: 0.875rem;
    border-top: 1px solid #e5e5e5;
    background-color: #eeeeee;
  }
`;

export const Card = styled.div`
  padding: 0.875rem;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  margin-bottom: 0.875rem;

  .kb-card-title-label {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding-bottom: 0.265rem;
    border-bottom: 1px solid #e5e5e5;

    &.with-label {
      &.without-title {
        align-items: flex-end;
      }
    }

    .kb-card-title {
      line-height: 1rem;
      width: 70%;
      font-size: 0.875rem;
      font-weight: bold;
    }

    .kb-card-label {
      width: 30%;
      line-height: 1rem;
      font-size: 0.625rem;
      text-align: right;
      font-style: italic;
      color: #8a8a8a;

      &.without-title {
        width: 100%;
      }
    }
  }
  .kb-card-body {
    padding: 0.875rem;
    font-size: 0.875rem;
    line-height: 1rem;
  }
  .kb-card-actions {
    margin-top: 0.265rem;
    display: flex;
    flex-direction: row;

    .kb-card-action {
      flex: 1;

      a:hover {
        text-decoration: none;
      }

      button,
      .btn {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 0.675rem;
      }
    }
  }
  .kb-card-footer {
  }

  .kb-card-tags {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-end;
    width: 100%;

    .badge {
      margin: 0.2rem 0 0 0.2rem;
    }
  }
`;
