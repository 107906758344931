import React, { type Node } from "react";
import Axios from "axios"; // eslint-disable-line
import { ResourceGenerator } from "@swan/api"; // eslint-disable-line
import { getLetterAvatar } from "@swan/helpers/utils";
import Styled from "styled-components";
import { SwanContextManager } from "@swan/state"; // eslint-disable-line

type Props = {
  src?: string,
  text?: string,
  size?: number,
  autofit?: boolean,
  background?: string,
  border?: string,
  color?: string,
  children?: Node,
};

const Wrapper = Styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  
  ${(props: any) => `
    width: ${props.autofit ? "100%" : `${props.size || 4}rem`};
    height: ${props.autofit ? "100%" : `${props.size || 4}rem`};
  `}
  border-radius: 50%;
  ${(props: any) => `
    border: 1px solid ${props.border || "#203852"};
  `}
  ${(props: any) => `
    background-color: ${props.background || "#3b5a92"};
  `}
  ${(props: any) => `
    color: ${props.color || "#ffffff"};
  `}
  overflow: hidden;

  .avatar-image {
    width: 100%;
    height: 100%;
  }
`;

const Component = (props: Props) => {
  const {
    src,
    text,
    size,
    autofit,
    background,
    border,
    color,
    children,
  } = props;
  return (
    <Wrapper {...{ size, autofit, background, color, border }}>
      {src ? (
        <img className="avatar-image" src={src} alt={text} />
      ) : (
        children || getLetterAvatar(text || "Unknow User")
      )}
    </Wrapper>
  );
};

Component.defaultProps = {
  src: undefined,
  text: undefined,
  size: 4,
  autofit: false,
  background: "#3b5a92",
  border: "#203852",
  color: "#fff",
  children: null,
};

export default Component;
