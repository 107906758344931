// @flow
export const FIELD = "$FIELD";
export const LAYOUT = "$LAYOUT";
export const ATTRIB = "$ATTRIB"; // Any changes related to attributeName, this is in use, so >>>unavoidable<<<
export const RECORD = "$RECORD";
export const OBJECT = "$OBJECT";
export const FORM = "$FORM";

export default {
  FIELD,
  LAYOUT,
  ATTRIB,
  RECORD,
  OBJECT,
  FORM,
};
