const MainContainer = {
  boxSizing: "border-box",
  background: "whitesmoke",
};

const AppWrapper = {
  boxSizing: "border-box",
  minHeight: "100vh",
  display: "flex",
  flexDirection: "row",
  justifyContent: "flex-start",
  alignItems: "flex-start",
};

const MainClasses = {
  MainContainer: "main-container",
  AppWrapper: "app-wrapper",
};

export { MainContainer, AppWrapper, MainClasses };
