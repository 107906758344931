import { createStore, applyMiddleware, compose } from "redux";
// reducers
import persistedReducers from "./reducers";

let store;

// prepare store and persistor
const configureStore = (initialState: Object = {}) => {
  /* eslint-disable no-underscore-dangle */
  const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
        name: `SWAN | Form Designer`,
      })
    : compose;
  /* eslint-enable */

  // create array of middlewares
  const middlewares = [];
  // $FlowFixMe Should be createStore<*, *, *>
  store = composeEnhancers(applyMiddleware(...middlewares))(createStore)(
    persistedReducers(),
    initialState
  );

  return store;
};

export const getStore = () =>
  // if (!store) {
  //   store = configureStore();
  // }
  store;

export default configureStore;
