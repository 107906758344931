export default {
  uid: "color",
  attributeId: "",
  formId: "",
  pencilDef: {
    title: "Color",
    group: "FIELD",
    handleIcon: "FaHashtag",
    styles: {},
  },
  options: {
    type: "color",
    preventDefaultValue: false,
  },
};
