import React, { Fragment, useEffect, useRef, useState } from "react";
import { Row, Col } from "reactstrap";
import { Button, Input, RemoteSelect, toast } from "@swan/themer";
import styled from "styled-components";
import { debounce } from "lodash";

import PolicyAdminService from "./services/policyAdmin";
import UserService from "./services/user";

type Props = {
  policy: Object,
  userService: any,
};

const policyAdminService = new PolicyAdminService();

const ActionButton = styled(Button)`
  position: absolute;
  top: 1px;
  right: 1px;
`;

const PolicyUsersSearchListContainer = styled.div`
  padding: 10px;
  background-color: #e9ecef;
`;

const UserRow = styled.div`
  margin-top: 10px;
  background: #fff;
  padding: 12px;
  min-height: 39px;
  position: relative;
`;

const PolicyUsersList = ({ policy, userService: propsUserService }: Props) => {
  let userService;
  if (!propsUserService) {
    userService = new UserService();
  } else {
    userService = propsUserService;
  }
  const [policyUsers, setPolicyUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [searchUser, setSearchUser] = useState("");
  const delayedQuery = useRef(
    debounce(searchUserRef => {
      policyAdminService
        .get("", {
          filter: [
            { columnName: "policy_id", value: policy.id, operator: "=" },
            {
              columnName: "user_id_display",
              value: searchUserRef,
              operator: "LIKE",
            },
          ],
        })
        .then(response => {
          if (response && response.data) {
            setPolicyUsers(response.data);
          }
        });
    }, 300)
  ).current;

  useEffect(
    () => {
      delayedQuery(searchUser);
    },
    [policy, isLoading, searchUser]
  );

  return (
    <Fragment>
      <Row />
      <Row>
        <Col>
          <h3>{policy.name}</h3>
        </Col>
      </Row>
      <Row style={{ marginTop: "5px" }}>
        <Col>
          <p>{policy.description}</p>
        </Col>
      </Row>
      <Row>
        <Col>
          <RemoteSelect
            label="Add User"
            service={userService}
            labelAttribute="name"
            isLoading={isLoading}
            onChange={event => {
              if (!event.value) {
                return;
              }
              setIsLoading(true);
              policyAdminService
                .post("assign", { user_id: event.value, policy_id: policy.id })
                .then(() => {
                  setIsLoading(false);
                })
                .catch(e => {
                  setIsLoading(false);
                  toast(
                    e.message || "error occured when trying to assign a policy"
                  );
                });
            }}
            name="users"
          />
        </Col>
      </Row>
      <PolicyUsersSearchListContainer>
        <Row>
          <Col>
            <Input
              type="text"
              name="Search"
              label="Search List"
              hideLabel
              value={searchUser || ""}
              onChange={event => {
                setSearchUser(event.target.value);
              }}
            />
          </Col>
        </Row>
        <Row>
          {policyUsers &&
            policyUsers.map(policyUser => (
              <Col xs={12} md={4}>
                <UserRow>
                  {policyUser.user.name}
                  <ActionButton
                    style={{ float: "right" }}
                    color="danger"
                    onClick={() => {
                      setIsLoading(true);
                      policyAdminService
                        .post("revoke", {
                          user_id: policyUser.user.id,
                          policy_id: policy.id,
                        })
                        .then(() => {
                          setIsLoading(false);
                        })
                        .catch(e => {
                          setIsLoading(true);
                          toast(
                            e.message ||
                              "error occured when trying to revoke a policy"
                          );
                        });
                    }}
                  >
                    Revoke
                  </ActionButton>
                </UserRow>
              </Col>
            ))}
        </Row>
      </PolicyUsersSearchListContainer>
    </Fragment>
  );
};

export default PolicyUsersList;
