// @flow
import { startsWith, lowerCase } from "lodash";

import prepareString from "./prepare_string";

export default (conditionValue: any) => (fieldValue: any) =>
  startsWith(
    lowerCase(prepareString(fieldValue)),
    lowerCase(prepareString(conditionValue))
  );
