// @flow

/**
 * React binding for Jodit Editor
 */
import React, { Component } from "react";
import Jodit from "jodit";

// $FlowFixMe
import "jodit/build/jodit.min.css";
import "./editor.css";

type Props = {
  config?: Object,
  onChange?: (value: any) => void,
  // onObserve?: (e?: SyntheticEvent<*>) => void,
  value?: mixed,
  getEditorRef?: (any, any) => void,
  isJson: boolean,
};

type State = {
  editorLoadedValue: boolean,
};

export default class JoditEditor extends Component<Props, State> {
  static defaultProps = {
    config: Jodit.defaultOptions.buttons,
    onChange: undefined,
    // onObserve: undefined,
    value: "",
    getEditorRef: undefined,
  };

  constructor(props: Props) {
    super(props);
    this.editorRef = React.createRef();
    this.state = {
      editorLoadedValue: false,
    };
  }

  componentDidMount() {
    const { value } = this.props;
    this.createEditor();
    const valueProp = ((value || {}).target || {}).value || value;
    if (valueProp) {
      this.setValue(valueProp);
      this.setState({
        editorLoadedValue: true,
      });
    }
  }

  componentDidUpdate(prevProps: Props) {
    const { value: newValue } = this.props;
    const { value: oldValue } = prevProps;
    const { editorLoadedValue } = this.state;

    if (!editorLoadedValue && oldValue !== newValue) {
      this.setValue(newValue);
      // eslint-disable-next-line
      this.setState({
        editorLoadedValue: true,
      });
    }
  }

  componentWillUnmount() {
    if (this.editor) {
      this.editor.destruct();
    }
  }

  setValue = (value: any) => {
    const { isJson } = this.props;

    if (!this.editor) {
      this.createEditor();
    }

    if (isJson) {
      this.editor.value = JSON.stringify(value);
    } else {
      this.editor.value = value;
    }
  };

  changeListener = (value: any) => {
    const { onChange, isJson } = this.props;
    if (typeof onChange === "function") {
      if (isJson) {
        onChange(JSON.parse(value));
      } else {
        onChange(value);
      }
    }
  };

  editorRef: any;

  createEditor() {
    if (this.editor) {
      this.editor.destruct();
    }
    const { config, getEditorRef, onChange } = this.props;
    this.editor = new Jodit(this.editorRef.current, config);

    if (getEditorRef && typeof getEditorRef === "function") {
      getEditorRef(this.editor, this.editorRef);
    }
    if (onChange) {
      this.editor.events.on("change", this.changeListener);
    }
  }

  editor: Object;

  render() {
    return <textarea ref={this.editorRef} />;
  }
}
