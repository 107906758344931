import { has, isObject, isArray } from "lodash";

import {
  GROUP_DEFAULT,
  // groupsOrderFallback,
} from "../../../definitions/pencils/config";

import jsonSchemaParts from "../../../definitions/parts/vendor/jsonSchema";

import t from "../../../i18n/t";

// types
export type optionPairType = {
  optionProperty: string,
  optionValue: any,
};

const { textInput, numberInput, textarea, checkbox } = jsonSchemaParts;

const jsonSchemaPartConfig = {
  textInput,
  numberInput,
  textarea,
  checkbox,
};

export default (
  optionPair: optionPairType,
  fieldType: string,
  fieldDefault: { fieldLabel: string, fieldValue: string | boolean }
) => {
  const { fieldLabel, fieldValue } = fieldDefault;
  const { optionValue: optionValueFromPair } = optionPair;

  let optionGroup = GROUP_DEFAULT;
  let optionOrder = 0;
  let optionValue = optionValueFromPair;
  if (isArray(optionValueFromPair) && optionValueFromPair.length > 0) {
    [optionValue, optionGroup, optionOrder] = optionValueFromPair;
  }
  if (typeof optionGroup === "undefined") optionGroup = GROUP_DEFAULT;
  if (typeof optionOrder === "undefined") optionOrder = 0;
  const options = {};
  const isOptionProvided = isObject(optionValue) && has(optionValue, "schema");

  if (isOptionProvided) Object.assign(options, { schema: optionValue.schema });
  if (isOptionProvided && has(optionValue, "uiSchema"))
    Object.assign(options, { uiSchema: optionValue.uiSchema });

  const { schema, uiSchema } = has(jsonSchemaPartConfig, fieldType)
    ? jsonSchemaPartConfig[fieldType]
    : textInput;

  let typeCheck: boolean = false;
  if (["textInput", "textarea", "numberInput"].indexOf(fieldType) !== -1)
    typeCheck =
      typeof optionValue === "string" || typeof optionValue === "number";
  if (["checkbox"].indexOf(fieldType) !== -1)
    typeCheck = typeof optionValue === "boolean";

  return {
    schema: {
      ...schema,
      ...{
        title: t(fieldLabel),
        default: typeCheck ? optionValue : fieldValue,
      },
      ...(has(options, "schema") ? options.schema : {}),
    },
    uiSchema: {
      ...uiSchema,
      ...(has(options, "uiSchema") ? options.uiSchema : {}),
    },
    optionOrder,
    optionGroup,
  };
};
