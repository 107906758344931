import { map } from "lodash";
import { getStore } from "../../state/store";

export const getJsonSchemaForVersions = (): // id: string
{
  jsonSchema: {},
  jsonUiSchema: {},
} => {
  const store = getStore();

  // TODO implement ability to select form type for version
  const { formId } = store.getState().app;
  // const relatedPen = head(filter(RELOBJ, relPen => relPen.id === id));
  // console.log(store.getState());
  const formsAvail = [
    {
      formId,
      formName: "Current Form",
    },
  ];
  // if (relatedPen && hasIn(relatedPen, ["__relobj", "child", "forms"])) {
  //   const forms = get(relatedPen, ["__relobj", "child", "forms"]);
  //   map(forms, form => {
  //     formsAvail.push({
  //       formId: form.id,
  //       formName: form.name,
  //       formType: form.type,
  //     });
  //   });
  // }

  const formsEnum = map(formsAvail, formAvail => formAvail.formId);
  const formsEnumNames = map(formsAvail, formAvail => formAvail.formName);

  const jsonSchema = {
    type: "object",
    properties: Object.assign(
      {},
      {
        title: {
          type: "string",
          title: "Field Label",
        },
        // type: {
        //   type: "string",
        //   title: "Relationship Type",
        //   enum: [type],
        //   enumNames: ["One to Many"],
        // },
        // listMode: {
        //   type: "string",
        //   title: "List View",
        //   enum: ["grid", "card"],
        //   enumNames: ["Grid", "Card"],
        // },
        editMode: {
          type: "string",
          title: "Edit Mode",
          enum: ["inline", "modal"],
          enumNames: ["In Line", "Modal"],
        },
        defaultForm: {
          type: "number",
          title: "Edit Form",
          enum: formsEnum,
          enumNames: formsEnumNames,
        },
      }
      // type === "OneToMany"
      //   ? {
      //       maxAllowed: {
      //         type: "number",
      //         title: "Number of Entries Allowed",
      //         help: "Enter 0, negative value or leave it empty for infinite",
      //         default: 0,
      //       },
      //     }
      //   : {}
    ),
    // required: ["title"],
    // dependencies: {
    //   listMode: {
    //     // oneOf: [
    //     //   {
    //     //     properties: {
    //     //       listMode: {
    //     //         enum: ["card"],
    //     //       },
    //     //       // cardViewForm: {
    //     //       //   type: "number",
    //     //       //   title: "Card View Form",
    //     //       //   enum: formsEnum,
    //     //       //   enumNames: formsEnumNames,
    //     //       // },
    //     //     },
    //     //   },
    //     // ],
    //   },
    // },
  };
  const jsonUiSchema = {};

  return {
    jsonSchema,
    jsonUiSchema,
  };
};

export default {
  getJsonSchemaForVersions,
};
