import React, { Component, type Node } from "react";
import { map, isEmpty, has, find } from "lodash";
import { SchemaWalker } from "@swan/form-designer/utils";
import { Button } from "@swan/themer";
import { ButtonConfirm } from "@swan/helpers/UI";

import { FaSave, FaTrash, FaTimesCircle } from "react-icons/fa";

import styled from "styled-components";

// component
import Element from "./components/Elements/Element";

// context
// import { RendererContext } from "./components/Main";

// types
import type { Schema } from "./types";

type Props = {
  schema: Schema,
  pickbyFields: Array<{ name: string, title?: string }>,
  hideLabel?: boolean,
  data: Object,
  onChange: (attribute: string, value: string | number | Object) => void,
  error: ?{ [string]: string },
  loading: boolean,
  readOnly?: boolean,
  // updateRecord?: Function,
  deleteRecord: Function,
  closeHandler: Function,
  save: Function,
};

type State = {
  tableRows: any,
};

// $FlowFixMe
// export const TableRowRendererContext = React.createContext({});

const Tr = styled("tr")`
  position: relative;

  td:last-child {
    position: relative;
  }

  td.controls {
    position: absolute;
    display: block;
    color: red;
    // width: 100px;
    right: 0;
    background: #dfe3e6;
    padding: 0.5rem;
  }

  // &:hover td.controls {
  //   display: block;
  // }
`;

class TableRowRenderer extends Component<Props, State> {
  static defaultProps = {
    type: "details",
    // data: {},
    hideLabel: false,
    readOnly: false,
  };

  constructor(props: any) {
    super(props);
    this.state = {
      tableRows: [],
    };
  }

  // TableRowFields = [];

  componentDidMount() {
    const { pickbyFields, schema } = this.props;
    const walkedSchema = SchemaWalker(schema);

    const tableRows = [];
    // walk through schema to pick by attribute names
    let index = 0;
    if (has(walkedSchema, "fields") && !isEmpty(walkedSchema.fields)) {
      map(walkedSchema.fields, (elementSchema: Schema, key: string) => {
        const item = find(pickbyFields, {
          name: elementSchema.attributeName,
        });
        if (item) {
          index += 1;
          tableRows.push({
            schema: elementSchema,
            id: key,
            index,
          });
        }
      });
    }
    this.setState({
      tableRows,
    });
  }

  render() {
    const { tableRows } = this.state;
    const {
      hideLabel,
      data,
      onChange,
      error,
      loading,
      readOnly,
      save,
      // updateRecord,
      deleteRecord,
      closeHandler,
    } = this.props;

    const buttons: Array<
      | {
          label: string,
          color: string,
          onClick?: Function,
          icon?: Node,
          size?: string,
          buttonConfirm?: boolean,
        }
      | {
          label?: string,
          color: string,
          onClick?: Function,
          icon: Node,
          size?: string,
          buttonConfirm?: boolean,
        }
    > = [
      {
        color: "secondary",
        onClick: closeHandler,
        icon: <FaTimesCircle />,
      },
      {
        color: "primary",
        onClick: () => {
          // updateRecord(data.id, data);
          save();
        },
        icon: <FaSave size="1em" />,
      },
      {
        buttonConfirm: true,
        color: "danger",
        onClick: deleteRecord,
        icon: <FaTrash size="1em" />,
      },
    ];

    return (
      <Tr className="table-row-rendered">
        {!isEmpty(tableRows) &&
          map(tableRows, tableRow => {
            const { schema, id, index } = tableRow;
            return (
              <td key={id} className="table-cell-rendered">
                <Element
                  {...{
                    schema,
                    id,
                    index,
                    hideLabel,
                    data,
                    onChange,
                    error,
                    loading,
                    readOnly,
                  }}
                />
              </td>
            );
          })}
        <td className="controls">
          <div>
            {map(
              buttons,
              ({ icon, label, color, onClick, size, buttonConfirm }) => (
                <React.Fragment key={`${label || ""}_${color}`}>
                  {!buttonConfirm && (
                    <Button size={size || "sm"} {...{ color, onClick }}>
                      {icon || label}
                    </Button>
                  )}
                  {buttonConfirm && (
                    <ButtonConfirm onClick={onClick} color={color} size="sm">
                      {icon || label || ""}
                    </ButtonConfirm>
                  )}
                </React.Fragment>
              )
            )}
          </div>
        </td>
      </Tr>
    );
  }
}

export default TableRowRenderer;
