export default {
  uid: "permissionEditor",
  attributeId: "",
  formId: "",
  pencilDef: {
    title: "Permission Editor",
    group: "FIELD",
    handleIcon: "FaCloudUploadAlt",
    styles: {},
  },
  options: {
    title: "Undefined",
    type: "permissionEditor",
    required: false,
    limits: {
      min: 2,
      max: 255,
    },
    default: "",
    placeholder: "",
    description: "",
    help: "",
    hideLabel: false,
    classNames: "",
    disabled: false,
    autofocus: false,
    readonly: false,
    allowDuplicate: true,
    isEncrypted: false,
  },
};
