import Resource from "./resource";
import ObjectService from "./services/object";
import { type SwanObject } from "./types";

class GenericResource extends Resource {
  objectName: string;

  relationshipName: string;

  objectDef: SwanObject;

  parentId: string;

  setParent(p: string) {
    this.parentId = p;
  }

  setObjectName(name: string) {
    this.objectName = name;
  }

  /**
   * Loads the object definition to read the required relationship def
   */
  loadDefinition() {
    if (this.objectDef) {
      return Promise.resolve(this.objectDef);
    }
    return ObjectService.getObjectByName(this.objectName).then(def => {
      const relName = this.relationshipName.toLowerCase();
      if (!def.relationships) throw new Error("No relationships");
      if (!def.relationships[relName])
        throw new Error(
          `Relationship not defined: ${this.objectName} ${
            this.relationshipName
          }`
        );
      this.objectDef = def;
      this.prefix = def.relationships[relName].lookup_endpoint;
      return this.objectDef;
    });
  }

  getRelationshipDefinition() {
    return this.loadDefinition().then(objectDef => {
      const relName = this.relationshipName.toLowerCase();
      return objectDef.relationships[relName];
    });
  }

  /**
   * Override the get api function to dynamically load object definition
   */
  getApi() {
    if (!this.objectDef) {
      if (!this.objectName) {
        throw new Error("Object name not defined");
      }
      if (!this.objectName) {
        throw new Error("Object name not defined");
      }
      return this.loadDefinition().then(() => super.getApi());
    }
    return super.getApi();
  }
}

export default GenericResource;
