/* eslint-disable no-underscore-dangle */
import * as React from "react";
import styled from "styled-components";
import { /* Skeleton */ Spinner } from "@swan/themer";

import type { ElementProps } from "../../types";
import { RendererContext } from "../../../Main";

const FieldWrapper = styled.div(({ theme }) =>
  Object.assign({}, theme.FieldWrapper ? { ...theme.FieldWrapper } : {}, {})
);

const ComponentElement = styled.div({
  position: "relative",
});

const LoadingOverlay = styled.div({
  position: "absolute",
  right: 0,
  top: 0,
  width: "auto",
  height: "100%",
});

const Field = (Component: any) => (props: ElementProps) => (
  <RendererContext.Consumer>
    {({
      object,
      data,
      onChange,
      onBatchChange,
      error,
      loading,
      readOnly,
      isNew,
      fieldsLoading,
      fieldsLocked,
      modifiers,
    }) => {
      // passProps
      const passProps = {};
      if (
        (props.schema || {}).attributeName &&
        data.__passProps &&
        data.__passProps[props.schema.attributeName] &&
        Array.isArray(data.__passProps[props.schema.attributeName]) &&
        data.__passProps[props.schema.attributeName].length
      ) {
        data.__passProps[props.schema.attributeName].forEach(renderProp => {
          if (renderProp === Object(renderProp)) {
            const propItems = Object.entries(renderProp);
            if (propItems.length) {
              Object.assign(passProps, {
                [propItems[0][0]]: propItems[0][1],
              });
            }
          }
        });
      }
      //
      const isFieldLoading =
        fieldsLoading.indexOf((props.schema || {}).attributeName) !== -1 ||
        fieldsLoading.indexOf(
          ((props.schema || {}).id || "").replace("attrib_", "") // Some pencils don't have attribute name, id can be used (like polymorph parent)
        ) !== -1;
      const isFieldReadOnly =
        fieldsLocked.indexOf((props.schema || {}).attributeName) !== -1;
      let modifiersProps = {};
      if (modifiers) {
        const name = props.schema.attributeName || "";
        if (modifiers.fields) {
          if (modifiers.fields[name]) {
            if (modifiers.fields[name].props) {
              modifiersProps = { ...modifiers.fields[name].props };
            }
          }
        }
      }
      return (
        <FieldWrapper>
          <ComponentElement>
            <Component
              {...props}
              {...passProps}
              object={object}
              data={props.data || data}
              onChange={props.onChange || onChange}
              onBatchChange={props.onBatchChange || onBatchChange}
              error={error}
              formLoading={loading}
              loading={isFieldLoading}
              formReadonly={readOnly}
              readOnly={isFieldReadOnly || readOnly}
              isNew={isNew}
              {...modifiersProps}
            />
            {fieldsLoading.indexOf((props.schema || {}).attributeName) !== -1 ||
            fieldsLoading.indexOf(
              ((props.schema || {}).id || "").replace("attrib_", "") // Some pencils don't have attribute name, id can be used (like polymorph parent)
            ) !== -1 ? (
              <LoadingOverlay>
                <Spinner size="sm" color="primary" />
              </LoadingOverlay>
            ) : null}
          </ComponentElement>
        </FieldWrapper>
      );
    }}
  </RendererContext.Consumer>
);

export default Field;
