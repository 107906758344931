import {
  IS,
  IS_NOT,
  // STARTS_WITH,
  STARTS_WITH_CI,
  IS_EMPTY,
  IS_NOT_EMPTY,
  STARTS_WITHOUT,
  ENDS_WITH,
  ENDS_WITHOUT,
  CONTAINS,
  CONTAINS_NOT,
  CHARACTER_BYTES_MIN,
  CHARACTER_BYTES_MAX,
  E,
  NE,
  LT,
  LTE,
  GT,
  GTE,
  TRUNC,
  FLOOR,
  CEIL,
  DATE_IS,
  DATE_IS_NOT,
  DATE_BEFORE,
  DATE_AFTER,
  DATE_YEAR_IS,
  DATE_YEAR_IS_BEFORE,
  DATE_YEAR_IS_AFTER,
  DATE_YEAR_IS_NOT,
  DATE_MONTH_IS,
  DATE_MONTH_IS_NOT,
  DATE_DATE_IS,
  DATE_DATE_IS_BEFORE,
  DATE_DATE_IS_AFTER,
  DATE_DATE_IS_NOT,
  DATE_WEEK_IS,
  DATE_WEEK_IS_NOT,
  DATE_DAY_IS,
  DATE_DAY_IS_NOT,
  TIME_HOUR_IS,
  TIME_HOUR_IS_NOT,
  TIME_HOUR_IS_BEFORE,
  TIME_HOUR_IS_AFTER,
  PHONE_COUNTRY_IS,
  PHONE_COUNTRY_IS_NOT,
  EMAIL_USERNAME_IS,
  EMAIL_USERNAME_IS_NOT,
  EMAIL_DOMAIN_IS,
  EMAIL_DOMAIN_IS_NOT,
  EMAIL_TLD_IS,
  EMAIL_TLD_IS_NOT,
  ARRAY_INCLUDES,
  ARRAY_INCLUDES_NOT,
  HAS_ONE,
} from "./labels";

const StringOperators = [
  IS,
  IS_NOT,
  IS_EMPTY,
  IS_NOT_EMPTY,
  // STARTS_WITH,
  STARTS_WITH_CI,
  STARTS_WITHOUT,
  ENDS_WITH,
  ENDS_WITHOUT,
  CONTAINS,
  CONTAINS_NOT,
  CHARACTER_BYTES_MIN,
  CHARACTER_BYTES_MAX,
];

const NumberOperators = [E, NE, LT, LTE, GT, GTE, TRUNC, FLOOR, CEIL];

const DateOperators = [
  DATE_IS,
  DATE_IS_NOT,
  DATE_BEFORE,
  DATE_AFTER,
  DATE_YEAR_IS,
  DATE_YEAR_IS_BEFORE,
  DATE_YEAR_IS_AFTER,
  DATE_YEAR_IS_NOT,
  DATE_MONTH_IS,
  DATE_MONTH_IS_NOT,
  DATE_DATE_IS,
  DATE_DATE_IS_BEFORE,
  DATE_DATE_IS_AFTER,
  DATE_DATE_IS_NOT,
  DATE_WEEK_IS,
  DATE_WEEK_IS_NOT,
  DATE_DAY_IS,
  DATE_DAY_IS_NOT,
];

const TimeOperators = [
  TIME_HOUR_IS,
  TIME_HOUR_IS_NOT,
  TIME_HOUR_IS_BEFORE,
  TIME_HOUR_IS_AFTER,
];

const EmailOperators = [
  EMAIL_USERNAME_IS,
  EMAIL_USERNAME_IS_NOT,
  EMAIL_DOMAIN_IS,
  EMAIL_DOMAIN_IS_NOT,
  EMAIL_TLD_IS,
  EMAIL_TLD_IS_NOT,
];

const CollectionOperators = [HAS_ONE];

export default {
  default: [IS, IS_NOT, IS_EMPTY, IS_NOT_EMPTY],
  singleLine: StringOperators,
  multiLine: StringOperators,
  longText: StringOperators,
  phone: [...StringOperators, PHONE_COUNTRY_IS, PHONE_COUNTRY_IS_NOT],
  email: [...StringOperators, ...EmailOperators],
  singleSelect: [IS, IS_NOT, IS_EMPTY, IS_NOT_EMPTY],
  singleSelectCountries: [IS, IS_NOT, IS_EMPTY, IS_NOT_EMPTY],
  multiSelect: [
    IS,
    IS_NOT,
    IS_EMPTY,
    IS_NOT_EMPTY,
    ARRAY_INCLUDES,
    ARRAY_INCLUDES_NOT,
  ],
  numberField: NumberOperators,
  decimalField: NumberOperators,
  currency: NumberOperators,
  dateField: DateOperators,
  datetimeField: [...DateOperators, ...TimeOperators],
  percent: NumberOperators,
  checkbox: [IS, IS_NOT, IS_EMPTY, IS_NOT_EMPTY],
  collection: CollectionOperators,
};
