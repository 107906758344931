import _ from "lodash";
import { getStore } from "../state/store";
import { schemaActions } from "../state/reducers/schema";

export default class AttributesService {
  static createAttribute(label: string, type: string) {
    const store = getStore();
    const { newAttributes, newAttributesCount } = store.getState().schema;
    let attrLabel = label;
    let inc = 0;
    while (_.findIndex(newAttributes, ["label", attrLabel]) >= 0) {
      inc += 1;
      attrLabel = `${label} ${inc}`;
    }
    const attributeId = `new_${newAttributesCount + 1}`;
    store.dispatch(
      schemaActions.addNewAttribute({
        label: attrLabel,
        type,
        attributeId,
      })
    );
    return attributeId;
  }
}
