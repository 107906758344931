import React, { useEffect, useState } from "react";

const LayoutTypeProvider = (Component: any) => (props: any) => {
  const [layoutType, setLayoutType] = useState("web");

  const setupLayoutType = () => {
    if ((sessionStorage.getItem("hostInfoValue") || "").includes("Outlook")) {
      setLayoutType("outlook");
    }
  };

  useEffect(() => {
    setupLayoutType();
  });

  return <Component layoutType={layoutType} {...props} />;
};

export default LayoutTypeProvider;
