import React from "react";
import { Bar, HorizontalBar } from "react-chartjs-2";
import { Card, CardBody, CardHeader } from "reactstrap";
import { type DatasetType } from "./types";

export type BarType = {
  datasets: Array<DatasetType>,
  labels: Array<string>,
  barDirection: string,
};

// types
type Props = {
  data: BarType,
  title?: string,
  exportButton?: any,
};

const Widget = (props: Props) => {
  const { data, title, exportButton } = props;

  return (
    <Card>
      <CardHeader
        tag="h3"
        className="text-white brand-01--bg chart-header d-flex justify-content-between align-items-center"
      >
        {title}
        {exportButton && exportButton}
      </CardHeader>
      <CardBody className="chart-body">
        {data.barDirection === "x" ? (
          <Bar data={data} />
        ) : (
          <HorizontalBar data={data} />
        )}
      </CardBody>
    </Card>
  );
};

Widget.defaultProps = {
  title: "",
  exportButton: undefined,
};

export default Widget;
