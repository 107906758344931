import * as React from "react";
import { withTheme } from "styled-components";
import { Tabs as TabsComponent } from "@swan/themer";
import Tab from "./Tab";
import { RendererContext } from "../Main";

import { type ElementProps } from "./types";
import { type Schema } from "../../types";

// utils
import getFormulaResult from "../../utils/getFormulaResult";

type TabsState = {
  activeTab: number,
};

type GeneratedTab = {
  label: string,
  component: React.Node,
  route?: string,
  options?: Object,
};

class Tabs extends React.Component<ElementProps, TabsState> {
  canShow = (options: Object, formProps: Object) => {
    if (
      (options || {}).hideOnCreate &&
      Number.parseInt(options.hideOnCreate, 10) === 1 &&
      (formProps || {}).isNew &&
      formProps.isNew === true
    ) {
      return false;
    }

    if (
      (options || {}).hideOnDetail &&
      Number.parseInt(options.hideOnDetail, 10) === 1 &&
      ((formProps || {}).data || {}).id
    ) {
      return false;
    }

    if ((options || {}).hideOnCondition) {
      const doHide = getFormulaResult(
        {
          body: (options || {}).hideOnCondition,
        },
        { record: (formProps || {}).data }
      );

      if (doHide === true) return false;
    }

    return true;
  };

  getTabs = (
    properties: ?{ [string]: Schema },
    formProps?: Object
  ): Array<GeneratedTab> => {
    const { id } = this.props;
    const tabs = [];
    if (properties) {
      Object.keys(properties).forEach((tabName: string, index: number) => {
        if (properties) {
          if (
            properties[tabName].type === "tab" &&
            this.canShow(
              (properties[tabName] || {}).options || {},
              formProps
            ) === true
          ) {
            tabs.push({
              // id: `${id}/${tabName}`,
              label: properties[tabName].title || tabName,
              options: properties[tabName].options || {},
              component: (
                <Tab
                  index={index}
                  key={`${id}/${tabName}`}
                  tabsId={id}
                  id={`${id}/${tabName}`}
                  schema={properties[tabName]}
                />
              ),
            });
          }
        }
      });
    }
    return tabs;
  };

  render() {
    const {
      schema: { properties, options },
    } = this.props;
    const { vertical } = options || {};

    return (
      <RendererContext.Consumer>
        {({ isNew, data }) => {
          const tabs = this.getTabs(properties, { isNew, data });
          return <TabsComponent tabs={tabs} vertical={vertical} />;
        }}
      </RendererContext.Consumer>
    );
  }
}

export default withTheme(Tabs);
