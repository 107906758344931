import React, { useState } from "react";

import { Tooltip } from "reactstrap";

type Props = { id: string, item: any, value: any, classNames: any };

const TooltipItem = (props: Props) => {
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const { id, item, value, classNames } = props;
  const toggle = () => {
    setTooltipOpen(!tooltipOpen);
  };
  return (
    <span>
      {item}
      <Tooltip
        isOpen={tooltipOpen}
        target={`Tooltip-${id}`}
        toggle={toggle}
        className={classNames}
      >
        {value}
      </Tooltip>
    </span>
  );
};

export default TooltipItem;
