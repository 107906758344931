import styled from "styled-components";

export const DropzoneContainer = styled("div")``;

export const DropzoneInput = styled.input({});

export const DropzoneUploadMessage = styled("div")`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  > * {
    color: ${({ disabled }: any) => (disabled ? "#f7f7f7" : "#3d70b2")};
  }
`;

export const ProgressBarContainer = styled("div")`
  width: 100%;
  height: 8px;
  background-color: rgb(183, 155, 229);
  border-radius: 5px;
`;

export const ProgressBar = styled("div")`
  background-color: rgba(103, 58, 183, 1);
  height: 100%;
  margin: 0;
  border-radius: 5px;
`;

export const Controls = styled("div")``;

export const Preview = styled("div")`
  display: flex;
  flex-wrap: wrap;
  padding: 1rem;
  flex-direction: ${(props: any) =>
    props.showFileNameOnly ? "column" : "row"};
  .Cell {
    display: flex;
    padding: 0.2rem;
    margin: 0.3rem;
    border: 1px solid gray;
    border-radius: 0.1rem;
  }
`;

// @todo thumbnail via canvas
export const Thumbnail = styled("div")`
  position: relative;
  width: ${(props: any) => props.width || 50}px;
  height: ${(props: any) => props.height || 50}px;
  overflow: hidden;
  display: flex;
  flex-direction: row;

  .controlActions {
    display: none;
    position: absolute;
    width: ${(props: any) => props.width || 50}px;
    height: ${(props: any) => props.height || 50}px;
  }

  &:hover {
    .controlActions {
      display: flex;
      flex-direction: row;
      .controlButton {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        cursor: pointer;
      }

      .clearButton {
        color: red;
      }

      .previewButton {
        color: blue;
      }
    }
  }

  img {
    position: absolute;
    left: 50%;
    top: 50%;
    height: 100%;
    width: auto;
    transform: translate(-50%, -50%);
  }
`;

export default {
  DropzoneContainer,
  DropzoneInput,
  DropzoneUploadMessage,
  ProgressBarContainer,
  ProgressBar,
  Controls,
  Thumbnail,
};
