import type { ResourceState } from "../flowTypes/state";

export default () => {
  const selectors = {
    getRecords: (state: ResourceState) => {
      if (!state) return [];
      return state.data || [];
    },

    getRecord: (
      state: ResourceState,
      id: number | string,
      idField: string = "id"
    ) => {
      if (!id || id === "new" || id === "add") {
        return null;
      }
      if (state.data) {
        for (let i = 0; i < state.data.length; i += 1) {
          if (
            (state.data || [])[i][idField] === Number(id) ||
            (state.data || [])[i][idField] === `${id}`
          ) {
            return (state.data || [])[i];
          }
        }
      }
      return null;
    },

    getRecordDetails: (state: ResourceState, id: number | string) => {
      if (!id || id === "new" || id === "add") {
        return null;
      }
      if (!state.loadingDetails || state.loadingDetails[id]) {
        return null;
      }
      return state.details[id] || null;
    },

    /**
     * Returns a list of data ready to be used in dropdowns
     */
    getLookupList: (
      state: ResourceState,
      labelAttribute: string = "name",
      addEmpty: boolean = true
    ) => {
      if (!state) return [];
      let list = [];
      if (addEmpty) {
        list.push({ id: "", label: "" });
      }
      list = [
        ...list,
        ...(state.data || []).map(i => ({
          id: i.id,
          label: i[labelAttribute],
        })),
      ];
      return list;
    },

    /**
     * A selector that will return all elements needed for crud listing
     */
    getCrudListState: (state: ResourceState) => ({
      data: state.data,
      totalCount: state.total,
      pageSize: state.per_page,
      currentPage: state.current_page,
      sorting: state.currentSorting || [],
      filter: state.currentFilter || [],
      isFetching: state.isFetching,
    }),
    getCrudFormState: Function,
  };
  /**
   * A selector that will return all elements needede for a crud form
   */
  selectors.getCrudFormState = (
    state: ResourceState,
    props: { match: { params: { id: string | number } } }
  ) => {
    const {
      match: {
        params: { id },
      },
    } = props;
    return {
      record: selectors.getRecordDetails(state, id || ""),
      error: state.error,
      statusCode: state.statusCode,
      isFetching:
        !id || id === "new" || id === "add"
          ? false
          : state.loadingDetails && state.loadingDetails[id],
      isSaving: state.isSaving,
      saveResult: state.saveResult,
      params: state.params,
      createdRecord: state.createdRecord,
    };
  };
  return selectors;
};
