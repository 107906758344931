export default [
  {
    name: "Male",
    value: "MALE",
  },
  {
    name: "Female",
    value: "FEMALE",
  },
  {
    name: "Corporate",
    value: "CORPORATE",
  },
];
