import * as React from "react";
import { List, type ListOptions, type ListProps } from "@swan/helpers/CRUD";
import { SchemaWalker } from "@swan/form-designer/utils";
import { withLayout, type Layout } from "@swan/themer";

// services
import FormService from "../services/formService";

// utils
import { getOptionDisplayValue, getDateDisplayValue } from "./utils";

type Props = ListProps & {
  object: string,
  type?: string,
  dynamicOptions?: Object,
  gridOptions?: ListOptions,
  defaultForm?: any, // ability to override the form
  layout: Layout,
  loadColumnOnlyOnUpdate?: boolean,
};

type State = {
  columns: ?Array<{
    name: string,
    title?: string,
    getCellValue?: Function,
    columnOrderInCrudList?: number,
  }>,
};

const Dummy = () => null;

class DynamicList extends React.Component<Props, State> {
  static defaultProps = {
    type: "details",
    gridOptions: {},
    dynamicOptions: {},
    defaultForm: undefined,
    loadColumnOnlyOnUpdate: false,
  };

  mounted = false;

  state = {
    columns: undefined,
  };

  constructor(props: Props) {
    super(props);

    const { gridOptions } = props;
    this.Component = List(gridOptions || {})(this.getColumns)(Dummy);
  }

  componentDidMount() {
    const { loadColumnOnlyOnUpdate } = this.props;
    this.mounted = true;
    if (!loadColumnOnlyOnUpdate) {
      this.loadColumnData();
    }
  }

  componentDidUpdate(prevProps: Props) {
    const {
      layout: { layout },
      defaultForm,
    } = this.props;
    if (
      layout !== prevProps.layout.layout ||
      JSON.stringify(defaultForm) !== JSON.stringify(prevProps.defaultForm)
    ) {
      this.loadColumnData();
    }
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  getCellValue = (value: any, options?: Object = {}, type?: string) => {
    // early exist condition
    if (type === "longText" && window.document) {
      const valueAsElement = window.document.createElement("span");
      valueAsElement.innerHTML = value;
      let idx = 0;
      let result = "";
      let nTimes = 3;
      if (valueAsElement.childNodes.length) {
        nTimes =
          valueAsElement.childNodes.length < nTimes
            ? valueAsElement.childNodes.length
            : nTimes;
        while (idx < nTimes) {
          if (valueAsElement.childNodes[idx].innerText) {
            result = valueAsElement.childNodes[idx].innerText;
            break;
          }
          idx += 1;
        }
      }
      return `${result}...`;
    }

    let newerOptions = { ...options };
    if (type === "checkbox") {
      newerOptions = {
        items: [{ value: "0", name: "No" }, { value: "1", name: "Yes" }],
      };
    }

    if (type === "dateField" || type === "datetimeField") {
      return getDateDisplayValue(type, value, options);
    }

    if (type === "singleSelectCountries") {
      newerOptions = { ...options, standardItems: "COUNTRY" };
    }

    return value
      ? getOptionDisplayValue(String(value), newerOptions) || value
      : undefined;
  };

  loadColumnData = () => {
    const { object, type, dynamicOptions, defaultForm } = this.props;
    if (object && type) {
      const columns = [];
      const {
        layout: { isMobile },
      } = this.props;
      let prefix = "";
      if (isMobile) {
        prefix = "mobile_";
      }
      if (defaultForm) {
        this.handleForm(defaultForm, columns, dynamicOptions);
        return;
      }
      const service = new FormService();
      service
        .getObjectForm(object, `${prefix}${type}`)
        .then(response => {
          if (
            ((response || {}).form || {}).body &&
            response.form.body === Object(response.form.body)
          ) {
            this.handleForm(response.form, columns, dynamicOptions);
          }
        })
        .catch(error => {
          console.log(error); // eslint-disable-line
        });
    }
  };

  handleForm = (form, columns, dynamicOptions) => {
    const { fields } = SchemaWalker(form.body);
    Object.values(fields || {}).forEach((field: Object) => {
      if (
        (field || {}).attributeName &&
        (field || {}).options &&
        (field || {}).label &&
        (field.options || {}).listed
      ) {
        if ((field || {}).type === "polymorphParent") {
          if (!(dynamicOptions || {}).recordParentKnown) {
            const fld = field || {};
            if (fld.attributeName) {
              columns.push({
                name: `${field.attributeName}_display`,
                title: field.label,
                type: field.type,
                columnOrderInCrudList: ((field || {}).options || {})
                  .columnOrderInCrudList,
              });
            }
          }
        } else {
          columns.push({
            ...{
              name:
                field.type === "lookup"
                  ? `${field.attributeName}_display`
                  : field.attributeName,
              title: field.label,
              type: field.type,
              columnOrderInCrudList: ((field || {}).options || {})
                .columnOrderInCrudList,
            },
            ...([
              "radioSelect",
              "singleSelect",
              "singleSelectCountries",
              "checkbox",
              "longText",
              "dateField",
              "datetimeField",
            ].indexOf(field.type) !== -1
              ? {
                  getCellValue: row =>
                    row[field.attributeName]
                      ? this.getCellValue(
                          row[field.attributeName],
                          field.options,
                          field.type
                        )
                      : undefined,
                }
              : {}),
          });
        }
      }
    });
    if (this.mounted) {
      columns.sort((a, b) => {
        if (typeof a.columnOrderInCrudList === "undefined") {
          return 1;
        }
        if (typeof b.columnOrderInCrudList === "undefined") {
          return -1;
        }
        return a.columnOrderInCrudList > b.columnOrderInCrudList ? 1 : -1;
      });
      this.setState({
        columns,
      });
    }
  };

  getColumns = () => {
    const { columns } = this.state;
    return columns || [];
  };

  Component: any;

  render() {
    const { options, ...restProps } = this.props;
    const { columns } = this.state;
    const { Component } = this;
    return <Component {...restProps} columns={columns} />;
  }
}

export default withLayout(DynamicList);
