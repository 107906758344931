export default {
  uid: "longText",
  attributeId: "",
  formId: "",
  pencilDef: {
    title: "Long Text",
    group: "FIELD",
    handleIcon: "FaFileAlt",
    styles: {},
  },
  options: {
    title: "Text Area Untitled",
    type: "longText",
    required: false,
    limits: {
      min: 2000,
      max: 32000,
    },
    rows: 3,
  },
};
