import React, { Fragment } from "react";
import { Card, CardBody, CardHeader, Table } from "reactstrap";
import styled from "styled-components";

const Th = styled("th")`
  font-weight: bold;
  text-align: center;
`;

const Td = styled("td")`
  text-align: center;
`;

const ChartTable = ({
  data: { result, titles },
  tableTitle,
  exportButton,
}: {
  data: Object,
  tableTitle: string,
  exportButton?: any,
}) => (
  <Fragment>
    <Card>
      <CardHeader
        tag="h3"
        className="text-white brand-01--bg chart-header d-flex justify-content-between align-items-center"
      >
        {tableTitle}
        {exportButton && exportButton}
      </CardHeader>
      <CardBody className="chart-body">
        <Table borderless striped hover>
          <thead>
            <tr>
              <th>#</th>
              {titles.map(title => (
                <Th key={title}>{title}</Th>
              ))}
            </tr>
          </thead>
          <tbody>
            {Object.keys(result).map(item => (
              <tr key={item}>
                <td>{item}</td>
                {Object.keys(result[item]).map(colItem => (
                  <Td key={`${colItem}-${result[item][colItem][item]}`}>
                    {result[item][colItem][item]}
                  </Td>
                ))}
              </tr>
            ))}
          </tbody>
        </Table>
      </CardBody>
    </Card>
  </Fragment>
);

ChartTable.defaultProps = {
  exportButton: undefined,
};

export default ChartTable;
