import Col from "./sectionCol";
import Row from "./sectionRow";
import NewField from "./newField";
import Tab from "./sectionTab";

export default {
  Col,
  Row,
  NewField,
  Tab,
};
