import React from "react";

type Props = {
  id?: string,
  dataProperty?: string,
  breakPoint?: number,
  selector: string,
  onScroll?: Function,
  onSetMobile?: Function,
  onUnsetMobile?: Function,
};

type State = {
  layout: string,
};

class Wrapper extends React.Component<Props, State> {
  static defaultProps = {
    id: "mobile",
    dataProperty: "data-layout",
    breakPoint: 767,
    onScroll: undefined,
    onSetMobile: undefined,
    onUnsetMobile: undefined,
  };

  state = {
    layout: "",
  };

  componentDidMount() {
    window.addEventListener("resize", this.checkMobileLayout);
    window.addEventListener("scroll", this.onScroll);
    this.checkMobileLayout();
  }

  componentWillUnmount() {
    this.removeWrapperIds();
    window.removeEventListener("resize", this.checkMobileLayout);
    window.removeEventListener("scroll", this.onScroll);
  }

  onScroll = () => {
    const { onScroll } = this.props;
    if (onScroll && onScroll instanceof Function) {
      onScroll({
        scrollY: window.pageYOffset,
        scrollX: window.pageXOffset,
      });
    }
  };

  checkMobileLayout = () => {
    const { breakPoint, selector, id, dataProperty, onSetMobile } = this.props;
    const width = window.outerWidth;
    if (selector && width <= breakPoint) {
      const element = document.querySelector(selector);
      if (element) element.setAttribute(dataProperty, id);
      this.setState(
        {
          layout: id,
        },
        () => {
          if (onSetMobile && onSetMobile instanceof Function) {
            onSetMobile();
          }
        }
      );
    } else {
      this.removeWrapperIds();
    }
  };

  removeWrapperIds = () => {
    const { selector, dataProperty, onUnsetMobile } = this.props;
    const element = document.querySelector(selector);
    if (element) element.removeAttribute(dataProperty);

    if (onUnsetMobile && onUnsetMobile instanceof Function) {
      onUnsetMobile();
    }
  };

  isValid = () => {
    const { id } = this.props;
    const { layout } = this.state;
    if (layout === id) return true;
    return false;
  };

  render() {
    return null;
  }
}

export default Wrapper;
