import React, { useEffect, useState } from "react";
import Axios from "axios";

import FineUploaderTraditional from "fine-uploader-wrappers";
import Gallery from "react-fine-uploader";
import styled from "styled-components";
// $FlowFixMe
import "react-fine-uploader/gallery/gallery.css";
import "./uploader.css";
import ButtonConfirm from "../ButtonConfirm";
import { Col, Row } from "../Grid";
import { toast } from "../../utils";

type Props = {
  service: any,
  onAllComplete?: Function,
  onComplete?: Function,
  onDeleteComplete?: Function,
  pathFieldName: string,
  isLocked?: boolean,
  params: any,
  endpoint: string,
  files: any,
  fileNameField?: string,
};

const Container = styled(Row)`
  background-color: #ffffff;
  padding: 9px;
  border: 1px solid #e8e8e8;
  margin: 0px;
`;

const DeleteButton = styled(ButtonConfirm)`
  border-radius: 50%;
  padding: 2px;
  font-size: 13px;
  margin-left: 10px;
  line-height: 10px;
  font-weight: bold;
  width: 16px;
  &:after {
    display: none;
  }
`;

const FileName = styled.span`
  color: #5a5a5a;
  line-height: 23px;
  max-width: 249px;
  overflow: hidden;
  float: left;
`;

const FilesNamesList = styled.ul`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: space-between;
  align-items: flex-start;
`;

const FileNamesListItem = styled.li`
  background-color: #f5f5f5;
  padding: 7px;
  border-radius: 6px;
  margin-bottom: 7px;
`;

const DocumentUploader = ({
  service,
  onAllComplete,
  onComplete,
  onDeleteComplete,
  params,
  pathFieldName,
  isLocked,
  files: propFiles,
  fileNameField,
  endpoint,
}: Props) => {
  const [uploader, setUploader] = useState(undefined);
  const [isDeleting, setIsDeleting] = useState(false);
  const [files, setFiles] = useState([...propFiles]);
  const updateUploader = () => {
    const uploaderConfig = new FineUploaderTraditional({
      options: {
        request: {
          endpoint,
          customHeaders: Axios.defaults.headers,
          params,
          inputName: "file",
          filenameParam: fileNameField,
          totalFileSizeName: "size",
        },
        deleteFile: {
          enabled: true,
          customHeaders: Axios.defaults.headers,
          forceConfirm: true,
          endpoint,
        },
        callbacks: {
          onAllComplete: onAllComplete || (() => {}),
          onComplete: (id, fileName, response) => {
            if (onComplete) {
              onComplete(id, fileName, response);
            }
          },
          onDeleteComplete: id => {
            if (onDeleteComplete) {
              onDeleteComplete(id);
            }
          },
        },
        retry: {
          enableAuto: true,
        },
      },
    });
    uploaderConfig.on("submitDelete", () => true);

    setUploader(uploaderConfig);
  };

  useEffect(() => {
    updateUploader();
  }, []);

  useEffect(
    () => {
      setFiles([...propFiles]);
    },
    [propFiles]
  );

  const deleteFile = file => {
    setIsDeleting(true);
    service
      .delete(file.id)
      .then(() => {
        setIsDeleting(false);
        setFiles([...files].filter(item => item.id !== file.id));
      })
      .catch(e => {
        toast(e.message, { autoClose: 8000, type: "error" });
        setIsDeleting(false);
      });
    // here to delete the file
  };
  return (
    <>
      <Container>
        {(files || []).length > 0 && (
          <Col>
            <FilesNamesList>
              {files.map(file => (
                <FileNamesListItem key={file[fileNameField]}>
                  <FileName>
                    <a
                      href={file.fileurl || file[pathFieldName || ""]}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {file[fileNameField]}
                    </a>
                  </FileName>
                  {!isLocked && (
                    <DeleteButton
                      color="danger"
                      disabled={isDeleting}
                      onClick={() => deleteFile(file)}
                    >
                      x
                    </DeleteButton>
                  )}
                </FileNamesListItem>
              ))}
            </FilesNamesList>
          </Col>
        )}
        {!isLocked && <Col>{uploader && <Gallery uploader={uploader} />}</Col>}
      </Container>
    </>
  );
};

export default DocumentUploader;
