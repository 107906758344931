export const LOGIN_SUCCESS = "USER/LOGIN_SUCCESS";
export const LOGIN_WITH_ID_TOKEN = "USER/LOGIN_WITH_ID_TOKEN";
export const LOGIN_WITH_ID_TOKEN_BEGIN = "USER/LOGIN_WITH_ID_TOKEN_BEGIN";
export const LOGIN_WITH_ID_TOKEN_SUCCESS = "USER/LOGIN_WITH_ID_TOKEN_SUCCESS";
export const LOGIN_WITH_ID_TOKEN_FAILED = "USER/LOGIN_WITH_ID_TOKEN_FAILED";

export const LOGOUT = "USER/LOGOUT";
export const LOGOUT_BEGINS = "USER/LOGOUT_BEGINS";
export const LOGOUT_COMPLETED = "USER/LOGOUT_COMPLETED";
export const LOGOUT_FAILED = "USER/LOGOUT_FAILED";

export const ORGANIZATIONS_LOADED = "USER/ORGANIZATIONS_LOADED";

export const SWITCH_ORGANIZATION = "USER/SWITCH_ORGANIZATION";
export const SWITCH_ORGANIZATION_SUCCESS = "USER/SWITCH_ORGANIZATION_SUCCESS";

export const SET_LOCALE = "USER/SET_LOCALE";
export const LOCALE_SAVED = "USER/LOCALE_SAVED";
