/**
 * For parsing, formatting and validating internatioal phone numbers
 * https://github.com/googlei18n/libphonenumber
 * https://github.com/giggsey/libphonenumber-for-php
 * https://github.com/catamphetamine/libphonenumber-js
 */
export default {
  uid: "phone",
  attributeId: "",
  formId: "",
  pencilDef: {
    title: "Phone",
    handleIcon: "FaPhone",
    group: "FIELD",
    styles: {},
  },
  options: {
    title: "Untitled",
    type: "phone",
    required: false,
    limits: {
      min: 0,
      max: 15,
    },
    countryFilter: {
      type: false, // false | WHITE | BLACK
      countries: [],
    },
    placeholder: "971 50 0000 000",
  },
};
