import * as React from "react";

import styled, { withTheme } from "styled-components";

import { Container } from "reactstrap";

import Row from "./Row";

import type { ElementProps } from "./types";

const RowsWrapper = styled(Container)({}, ({ theme }) =>
  Object.assign({}, theme.RowsWrapper ? { ...theme.RowsWrapper } : {})
);

class Grid extends React.Component<ElementProps> {
  getRows = properties => {
    const { id } = this.props;
    if (!properties) return [];
    return Object.keys(properties).map(
      (rowName: string, index: number) =>
        properties[rowName].type === "row" && (
          <Row
            index={index}
            key={`${id}/${rowName}`}
            gridId={id}
            id={`${id}/${rowName}`}
            schema={properties[rowName]}
            rowCount={Object.keys(properties).length}
          />
        )
    );
  };

  render() {
    const {
      schema: { properties },
    } = this.props;
    const rows = this.getRows(properties);
    return <RowsWrapper fluid>{rows}</RowsWrapper>;
  }
}

export default withTheme(Grid);
