export default {
  SCHEMA_LOADING: "SCHEMA/SCHEMA_LOADING",
  SCHEMA_READY: "SCHEMA/SCHEMA_READY",
  SCHEMA_UPDATING: "SCHEMA/SCHEMA_UPDATING",
  SCHEMA_REFRESH: "SCHEMA/SCHEMA_REFRESH",
  SCHEMA_SAVE: "SCHEMA/SCHEMA_SAVE",
  SCHEMA_DESTROY: "SCHEMA/SCHEMA_DESTROY",

  UPDATE_ELEMENT: "SCHEMA/UPDATE_ELEMENT",
  REPLACE_ELEMENT: "SCHEMA/REPLACE_ELEMENT",
  MOVE_ELEMENT: "SCHEMA/MOVE_ELEMENT",
  ADD_ELEMENT: "SCHEMA/ADD_ELEMENT",

  ADD_NEW_ATTRIBUTE: "SCHEMA/ADD_NEW_ATTRIBUTE",

  SCHEMA_WALK: "SCHEMA/SCHEMA_WALK",
};
