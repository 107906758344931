import React from "react";
import { Input } from "reactstrap";
import FieldWrapper, { type FieldProps } from "./partials/FieldWrapper";

// utils
import getUniqueOptions from "../../utils/getUniqueOptions";

export type InputProps = FieldProps & {
  placeholder?: string,
  itemLabelAccessor?: Object => string,
  itemIdAccessor?: Object => string,
  multiple?: boolean,
  data?: Array<{
    id: string | number,
    label: string,
  }>,
};

const Field = ({
  name,
  value,
  placeholder,
  label,
  multiple,
  required,
  onChange,
  className,
  data,
  error,
  itemLabelAccessor,
  itemIdAccessor,
  children,
  style,
}: InputProps) => (
  <Input
    type="select"
    id={name}
    multiple={multiple}
    placeholder={placeholder || label || ""}
    required={required || false}
    value={value || (multiple ? [] : "")}
    onChange={onChange}
    className={`${className || ""} ${error !== "" ? "is-invalid" : ""}`}
    style={style}
    {...(required ? { "data-required": true } : {})}
  >
    <option value="">{`Select ${label || name}`}</option>
    {getUniqueOptions(data, itemIdAccessor, itemLabelAccessor).map(
      (option: Object) => (
        <option value={option.id} key={`${name}-${option.id}`}>
          {option.label}
        </option>
      )
    )}
    {children}
  </Input>
);

export default FieldWrapper(Field);
