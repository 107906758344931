import { type Modifiers } from "../types";

export const getRelatedObjectModifiers = (
  modifiers: Modifiers,
  name: string
): Modifiers => {
  if (!modifiers) return {};
  if (!modifiers.relatedObjects) return {};
  return modifiers.relatedObjects[name] || {};
};

export const getRelatedObjectRendererComponents = (
  rendererComponents: Object,
  name: string
): Object => {
  if (!rendererComponents) return {};
  if (!rendererComponents.relatedObjects) return {};
  return rendererComponents.relatedObjects[name] || {};
};

export const getFieldEventsByType = async (
  modifiers: Modifiers,
  attributeName: string,
  eventName: string
) => {
  if (!modifiers) return [];
  if (!modifiers.fields) return [];
  if (!modifiers.fields[attributeName]) return [];
  if (!modifiers.fields[attributeName].events) return [];
  if (!modifiers.fields[attributeName].events[eventName]) return [];
  const events = modifiers.fields[attributeName].events[eventName];
  if (typeof events !== "object") {
    return [events];
  }
  return events;
};

export const getFormEventsByType = async (
  modifiers: Modifiers,
  eventName: string
) => {
  if (!modifiers) return [];
  if (!modifiers.form) return [];
  if (!modifiers.form.events) return [];
  if (!modifiers.form.events[eventName]) return [];
  const events = modifiers.form.events[eventName];
  if (typeof events !== "object") {
    return [events];
  }
  return events;
};

export default {
  getRelatedObjectModifiers,
  getRelatedObjectRendererComponents,
  getFieldEventsByType,
};
