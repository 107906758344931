import React, { Component } from "react";
import { connect } from "react-redux";
import { type Location } from "react-router-dom";
import { Button } from "reactstrap";
import styled from "styled-components";
import { FaRegLightbulb, FaRegUser, FaShieldAlt } from "react-icons/all";

import config from "@swan/config";

import { IMAGES } from "../../../assets/index";

// $FlowFixMe
import "./login.scss";

type Props = {
  history: {
    push: Function,
  },
  location: Location,
  loggedIn: boolean,
};

const Wrapper = styled("div")`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  box-sizing: border-box;
  background-image: none;

  .login-container {
    width: 100%;
    height: 100%;
    flex: 1;
    display: flex;
    flex-direction: column;

    .login-cell {
      display: flex;
      flex: 1;
      width 100%;
      height: 100%;
      align-items: center;
      padding: 1.8rem;

      h1 {
        font-size: 3rem;
      }

      h1, h2, h3, h4 {
        font-weight: bold;
        margin-bottom: 1rem;
      }

      h4 {
        font-size: 1.6rem;
        margin-bottom: 0.3rem;
      }

      p {
        font-size: 0.9rem;
        margin-bottom: 1rem;

        &.small {
          font-size: 0.7rem;
          margin-bottom: 0.5rem;
        }
      }

      .no-margin {
        margin: 0 !important;
      }

      .mb-sm {
        margin-bottom: 1rem !important;
      }

      .mb-md {
        margin-bottom: 2rem !important;
      }

      .mb-lg {
        margin-bottom: 3rem !important;
      }

      .as-link {
        display: inline;
        padding: 0;
        font-size: 0.9rem;
      }

      .copyright {
        display: block;
        font-size: 0.8rem;
        color: #bfbfbf;

        line-height: 1rem;
        margin-top: 3.6rem;
      }

      .swan-logo {
        display: flex;
        justify-content: center;
        img {
          width: 50%;
        }
      }

      .sso-button {
        a {
          border-radius: 1.6rem;
        }
      }

      .auth-instruction {
        p.condense {
          line-height: 1rem;
          font-size: 0.8rem;
        }
        display: flex;
        flex-direction: row;

        .auth-item {
          display: flex;
          flex-direction: row;
          margin-right: 0.4rem;

          svg {
            margin: 0 0.4rem;
          }
        }

        .auth-desc {
          margin-left: 0.4rem;
        }
      }
    }
  
    .sso-container {
      justify-content: center;
      background: #ffffff;
      color: #152935;

      &> div {
        align-items: center;
      }
      
    }
  
    .desc-container {
      display: none;
      justify-content: center;
      background: #1f4083;
      color: #ffffff;
    }
  }




  @media (min-width: 768px) {
    .login-container {
      flex-direction: row;

      .login-cell {
        padding: 2.5rem;
        &>div {
          width: 80%;
        }

        .swan-logo {
          justify-content: flex-start;
        }
      }

      .sso-container {
        justify-content: flex-end;
        &>div {
          width: 60%;
        }
      }

      .desc-container {
        display: flex;
        justify-content: flex-start;
    }
  }
}
`;

export class Login extends Component<Props> {
  static defaultProps = {
    loggedIn: false,
  };

  componentDidMount() {
    // to identify log page specific
    const html = document.querySelector("html");
    if (html) html.setAttribute("data-login-page", "y");

    const { loggedIn, history, location } = this.props;
    let redirectSet = false;
    if (location.state) {
      const { from } = location.state;
      if (from) {
        redirectSet = true;
        window.localStorage.setItem("redirect", from);
      }
    }
    if (!redirectSet) {
      window.localStorage.removeItem("redirect");
    }
    if (loggedIn) {
      history.push("/");
    }
  }

  componentDidUpdate() {
    const { loggedIn, history } = this.props;
    if (loggedIn) {
      history.push("/");
    }
  }

  onNewAccountRequest = ({
    email,
    headers,
  }: {
    email: string,
    headers: Object,
  }) => {
    let requestLink = `mailto:${email}`;
    if (headers) {
      requestLink += `?${Object.keys(headers)
        .map(key => `${key}=${window.encodeURIComponent(headers[key])}`)
        .join("&")}`;
    }
    window.location.href = requestLink;
  };

  render() {
    const url = config.ssoURL;
    return (
      <Wrapper className="login-page-content">
        <div className="login-container">
          <div className="login-cell sso-container">
            <div className="sso-cell">
              <div className="swan-logo mb-lg">
                <img src={IMAGES.BRAND.LOGO_BLUE.png} alt="SWAN LOGO" />
              </div>

              <h4>Sign In</h4>
              <p className="mb-lg">
                Don't you have a SWAN account?{" "}
                <Button
                  className="as-link"
                  color="link"
                  onClick={() => {
                    this.onNewAccountRequest({
                      email: "ITInfraTeam@mazruiinternational.ae",
                      headers: {
                        subject:
                          "Create an Account on SWAN and Active Directory",
                        body: `Hi, I tried to logon to SWAN using the SSO but I wasn't able to proceed. Appreciate creating or amending the Active Directory SSO.
                    Please keep me posted.
                      
                    Regards,
                    Auto generated from SWAN Instance.`,
                      },
                    });
                  }}
                >
                  Request Access
                </Button>
              </p>

              <div className="auth-instruction">
                <div className="auth-item">
                  <FaRegUser size=".9em" /> <strong>Username</strong>
                </div>
                <p className="auth-desc condense">
                  firstname.lastname@mazrui.ae or firstname_lastname@mazrui.ae
                </p>
              </div>

              <div className="auth-instruction">
                <div className="auth-item">
                  <FaShieldAlt size=".9em" /> <strong>Password</strong>
                </div>
                <p className="auth-desc condense">your computer password</p>
              </div>

              <div className="sso-button">
                <Button
                  color="primary"
                  className="mt-3 login-button"
                  active
                  block
                  size="lg"
                  href={url}
                >
                  Sign in using SSO
                </Button>
              </div>
            </div>
          </div>
          <div className="login-cell desc-container">
            <div className="instruction">
              <h1>Welcome to SWAN</h1>

              <div className="auth-instruction">
                <div className="auth-item">
                  <FaRegLightbulb size="1.4em" />
                </div>
                <p class="auth-desc">
                  Logon using Microsoft Single Sign On, if credentials pop up,
                  you may use your corporate login details and follow the
                  on-screen instruction.
                  <span className="copyright">
                    &copy; SWAN Integrated Platform - A Solution by Mazrui
                    International IT Department
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </Wrapper>
    );
  }
}

function mapStateToProps(state) {
  return {
    loggedIn: state.user.loggedIn,
  };
}

export default connect(mapStateToProps)(Login);
