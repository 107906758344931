// @flow
// import { cloneDeep } from "lodash";
// config
import { optionsFallback, optionsFallbackDisabled } from "./config";

// field pencils
import singleLine from "./singleLine";
import multiLine from "./multiLine";
import longText from "./longText";
import phone from "./phone";
import email from "./email";
import singleSelect from "./singleSelect";
import radioSelect from "./radioSelect";
import singleSelectCountries from "./singleSelectCountires";
import multiSelect from "./multiSelect";
import numberField from "./numberField";
import decimalField from "./decimalField";
import currency from "./currency";
import dateField from "./dateField";
import datetimeField from "./datetimeField";
import percent from "./percent";
import checkbox from "./checkbox";
import urlField from "./urlField";
import folio from "./folio";
import color from "./color";
import multipleFileUpload from "./multipleFileUpload";
import lastCall from "./lastCall";
import lastMeeting from "./lastMeeting";
// import fileUpload from "./fileUpload";
// import pickList from "./pickList";
import mapField from "./mapField";
import timeField from "./timeField";
// import tagSelect from "./tagSelect";
// import dragDropSelect from "./dragDropSelect";
import resourceDisplay from "./resourceDisplay";
import formula from "./formula";
import singleOn from "./singleOn";
import displayText from "./displayText";
import objectPrint from "./objectPrint";

// layout pencils
import container from "./sectionContainer";
import rowContainer from "./sectionRowContainer";
import containerWithTitle from "./sectionContainerWithTitle";
import grid from "./sectionGrid";
import tabs from "./sectionTabs";
import permissionEditor from "./permissionEditor";

import timeline from "./timeline";
import statusBreadcrumb from "./statusBreadcrumb";

export const standardDefinitions = {
  // basic
  singleLine,
  multiLine,
  longText, // wysiwyg
  phone,
  email,
  // select and checkboxes
  checkbox,
  radioSelect,
  singleSelect,
  multiSelect,
  // pickList,
  singleSelectCountries, // composed
  // tagSelect,
  // dragDropSelect,
  // numbers and currency
  numberField,
  decimalField,
  currency,
  percent,
  // datetime
  dateField,
  datetimeField,
  timeField,
  // other
  urlField,
  multipleFileUpload,
  permissionEditor,
  mapField,
  containerWithTitle,
  folio,
  resourceDisplay,
  formula,
  displayText,
  objectPrint,
  singleOn,
  color,
  lastCall,
  lastMeeting,
  timeline,
  statusBreadcrumb,
};

// flat options
// get all the default options and its default value as an flat object
const optionsFallbackFlat = {};

Object.keys(optionsFallback).forEach(option =>
  Object.assign(optionsFallbackFlat, {
    [option]:
      Array.isArray(optionsFallback[option]) && optionsFallback[option].length
        ? optionsFallback[option][0]
        : option,
  })
);

// get all standard definitions
// and assign fallback flat options to it
export const allPencilTypes: Array<string> = Object.keys(standardDefinitions);
allPencilTypes.forEach(pencilType => {
  if (optionsFallbackDisabled.indexOf(pencilType) === -1) {
    Object.assign(standardDefinitions[pencilType].options, optionsFallbackFlat);
  }
});

export const layoutDefinitions = {
  container,
  containerWithTitle,
  rowContainer,
  grid,
  tabs,
};

export const allDefinitions = {
  ...standardDefinitions,
  ...layoutDefinitions,
};

export default {
  standardDefinitions,
  layoutDefinitions,
  allDefinitions,
};
