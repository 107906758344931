import React, {
  Component,
  type ComponentType,
  type ElementRef,
  type Node,
} from "react";
import { Popover, PopoverHeader, PopoverBody } from "reactstrap";
import styled from "styled-components";

import DefaultButton from "./Button";

type PlacementType =
  | "auto"
  | "auto-start"
  | "auto-end"
  | "top"
  | "top-start"
  | "top-end"
  | "right"
  | "right-start"
  | "right-end"
  | "bottom"
  | "bottom-start"
  | "bottom-end"
  | "left"
  | "left-start"
  | "left-end";

type Props = {
  id: string,
  children: Node,
  placement?: PlacementType,
  popsBy?: "hover" | "click",
  components?: {
    Button?: ComponentType<*>,
  },
  bodyLoader?: Function,
  buttonText?: Node,
  body?: Node,
  title?: Node,
};

type State = {
  isOpen: boolean,
};

const Container = styled.div({});

const ButtonWrapper = styled.div({
  display: "inline-block",
});

class SwanPopover extends Component<Props, State> {
  static defaultProps = {
    components: undefined,
    placement: "auto",
    popsBy: "hover",
    buttonText: undefined,
    body: "",
    title: "",
    bodyLoader: undefined,
  };

  constructor(props: Props) {
    super(props);
    // this.buttonRef = React.createRef();
    this.state = {
      isOpen: false,
    };
  }

  componentDidMount() {
    this.mounted = true;
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  openPopover = () => {
    this.toggle(true);
  };

  closePopover = () => {
    this.toggle(false);
  };

  toggle = (isOpen?: boolean) => {
    if (this.mounted) {
      const { bodyLoader } = this.props;
      this.setState(
        prevState => ({
          isOpen: typeof isOpen === "boolean" ? isOpen : !prevState.isOpen,
        }),
        () => {
          if (bodyLoader) bodyLoader();
        }
      );
    }
  };

  buttonRef: ElementRef<typeof HTMLButtonElement>;

  mounted: boolean;

  render() {
    const {
      id,
      placement,
      popsBy,
      buttonText,
      body,
      title,
      children,
      components,
    } = this.props;
    const { isOpen } = this.state;

    const Button = (components || {}).Button || DefaultButton;
    return (
      <Container className="swan-popover">
        <ButtonWrapper
          id={id}
          // ref={this.buttonRef}
          {...(popsBy === "click"
            ? { toggle: this.toggle }
            : {
                onMouseEnter: this.openPopover,
                onMouseLeave: this.closePopover,
              })}
          {...this.props}
        >
          <Button isOpen={isOpen} placement={placement || "auto"}>
            {buttonText}
          </Button>
        </ButtonWrapper>
        <Popover
          placement={placement}
          isOpen={isOpen}
          target={id}
          toggle={this.toggle}
        >
          {title && <PopoverHeader>{title}</PopoverHeader>}
          <PopoverBody>{body || children}</PopoverBody>
        </Popover>
      </Container>
    );
  }
}

export default SwanPopover;
