/* eslint-disable camelcase */
import React, { Component } from "react";

type Props = {
  listId: string,
  extraParameters?: () => {},
  data?: Array<Object>,
  isFetching?: boolean,
};
type State = {
  error?: string | Object,
  isFetching?: boolean,
  data?: Array<Object>,
};

const LocalListDataProvider = (ListComponent: any) => {
  class WrappedComponent extends Component<Props, State> {
    static defaultProps = {
      extraParameters: null,
      data: [],
      isFetching: false,
    };

    static resetUpdate() {}

    state = {
      error: null,
      isFetching: false,
      data: [],
    };

    mounted = false;

    static getDerivedStateFromProps(props: Props) {
      const { data } = props;
      return { data };
    }

    componentDidMount() {
      // If a list ID is provided, register an event listener to trigger update
      const { listId } = this.props;
      this.mounted = true;
      if (listId) {
        window.addEventListener(`${listId}::refresh`, () => {
          this.refresh();
        });
      }
    }

    componentWillUnmount() {
      this.mounted = false;
    }

    loadRecords = () => {
      const { data } = this.props;
      return data;
    };

    /**
     * reorder frontend record in the new index
     */
    reorderLocalRecord = (
      oldOrder: number,
      newOrder: number,
      data: Array<Object>
    ) => {
      const previousData = data;
      if (!previousData || previousData.length === 0) {
        return;
      }
      const item = previousData.splice(oldOrder, 1);
      previousData.splice(newOrder, 0, item[0]);
      if (previousData && previousData.length > 0) {
        this.setState({ data: previousData });
      }
    };

    /**
     * reorder record in the database and frontend
     * @param oldOrder
     * @param objectName
     * @param newOrder
     * @param previousData
     */
    reorderRecord = (
      oldOrder: number,
      objectName: string,
      newOrder: number,
      previousData: Array<Object>
    ) => {
      const id =
        previousData && previousData[oldOrder]
          ? previousData[oldOrder].id
          : null;

      if (!id) {
        return;
      }
      this.reorderLocalRecord(oldOrder, newOrder, previousData);
    };

    refresh() {
      this.setState(
        {
          isFetching: true,
        },
        () => {
          setTimeout(() => {
            this.setState({
              isFetching: false,
            });
          }, 300);
        }
      );
    }

    service: Object;

    render() {
      const { error, isFetching: isFetchingInternal, data } = this.state;
      const { isFetching } = this.props;
      return (
        <ListComponent
          {...this.props}
          data={data}
          totalCount={(data || []).length}
          pageSize={(data || []).length}
          currentPage={1}
          // sorting={sorting}
          // filter={filter}
          isFetching={isFetching || isFetchingInternal}
          error={error}
          reorderRecord={this.reorderRecord}
          loadRecords={this.loadRecords}
        />
      );
    }
  }

  return WrappedComponent;
};

export default LocalListDataProvider;
/* eslint-enable camelcase */
