export default [
  {
    name: "Mr.",
    value: "MR",
  },
  {
    name: "Mrs.",
    value: "MRS",
  },
  {
    name: "Mx.",
    value: "MX",
  },
  {
    name: "Ms.",
    value: "MS",
  },
  {
    name: "Miss",
    value: "MISS",
  },
  {
    name: "Master",
    value: "MASTER",
  },
  {
    name: "Madam",
    value: "MADAM",
  },
  {
    name: "Maid",
    value: "MAID",
  },
  {
    name: "Sheikh",
    value: "SHEIKH",
  },
];
