import * as types from "./types";
export type LoginAction = {
  type: string,
  token: string,
};
export const loginWithIdToken = (token: string): LoginAction => ({
  type: types.LOGIN_WITH_ID_TOKEN,
  token,
});

export type LogoutAction = {
  type: string,
};
export const logout = () => ({
  type: types.LOGOUT,
});

export type LogoutCompletedAction = {
  type: string,
};
export const logoutCompleted = (): LogoutCompletedAction => ({
  type: types.LOGOUT_COMPLETED,
});

export const subscribeUserLogin = (id: any) => ({
  type: "SUBSCRIBE_USER_LOGIN",
  id,
});
export const unsubscribeUserLogin = (id: any) => ({
  type: "UNSUBSCRIBE_USER_LOGIN",
  id,
});

export type SetLocaleAction = {
  type: string,
};
export const setLocale = (locale: string): SetLocaleAction => ({
  type: types.SET_LOCALE,
  locale,
});

export type switchOrganizationAction = (id: number, name: string) => void;
export type subscribeUser = (id: number) => void;
export const switchOrganization = (
  id: number,
  name: string,
  redirectTo?: string
) => ({
  type: types.SWITCH_ORGANIZATION,
  organization: id,
  organizationName: name,
  redirectTo,
});

export type UserAction =
  | LoginAction
  | LogoutAction
  | LogoutCompletedAction
  | SetLocaleAction;
export default loginWithIdToken;
