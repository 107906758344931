// @flow
import memoizeOne from "memoize-one";
import { indexOf, join, concat, remove, map, clone, assign, has } from "lodash";

// Utils
import objectWalker from "../../utils/objectWalker";

// types
import type { Schema } from "../../constants/flowTypes";

// momoize object walker
const schemaWalker = memoizeOne(objectWalker);

// Get schema in Glance
export default (schema: Schema) => {
  const tree = {
    layouts: {},
    fields: {},
  };

  // TODO: This needs to be aggregated from definitions
  const layoutKeys = ["row", "col", "grid", "tabs", "tab", "CONTAINER"];

  // var t0 = performance.now();
  schemaWalker(
    schema,
    (value, key, path, depth, parent, parentKey, parentPath) => {
      // !!!! SCHEMA CONVENTION !!!!!
      // to classify schema whether to be layout or field
      // at any level in the schema, field or layout should contain following three key and its properties
      // type, id and label
      if (key === "type" && has(parent, "id") && has(parent, "label")) {
        // !!!!! IMPORTANT NOTE: no 'type' key should be used inside child-level !!!!!
        // console.log(value, parent);
        // TODO: need no-inclue layouts
        const treeToggled =
          indexOf(layoutKeys, value) !== -1 ? "layouts" : "fields";
        const id = join(
          concat(
            ["@"],
            remove(map(parentPath, clone), ppath => ppath !== "properties")
          ),
          "/"
        );
        if (!has(tree[treeToggled], id))
          assign(tree[treeToggled], { [id]: parent });
      }
    }
  );
  // var t1 = performance.now();
  // console.log("schemaWalker took me" + (t1 - t0) + " milliseconds.");

  return tree;
};
