const Schema: { schema: Object, uiSchema: Object } = {
  schema: {
    type: "string",
    title: "Make Single-on field display as",
    enum: ["RADIO", "CHECKBOX"],
    enumNames: ["Radio Select", "Checkbox"],
  },
  uiSchema: {},
};

export default Schema;
