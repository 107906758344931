import * as React from "react";
import { connect } from "react-redux";

import styled, { withTheme } from "styled-components";
import { Draggable } from "react-beautiful-dnd";
import { keys } from "lodash";
import { Button } from "reactstrap";

import { FaTimes } from "react-icons/fa";
import ConditionalDroppable from "../DragDrop/ConditionalDroppable";
import ElementsList from "./ElementsList";
import type { Props } from "./types";

// import Parts from "../../definitions/parts";
import Pencils from "../../definitions/index";

import { schemaActions } from "../../state/reducers/schema";

const ContainerWrapper = styled.div(({ theme, droppedCount }) =>
  Object.assign(
    {},
    {
      "&:hover": {
        border: "1px solid rgba(61, 112, 178, 0.3)",
      },
      "&:hover .remove-element": {
        display: "block!important;",
      },
    },
    theme.ContainerWrapper ? { ...theme.ContainerWrapper } : {},
    droppedCount === 0
      ? {}
      : {
          paddingBottom: "0.5rem",
        }
  )
);

const Row = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`;

const RemoveDiv = styled.div`
  position: absolute;
  right: 0px;
  top: 0px;
  display: none;
`;

type ContainerProps = Props & {
  removeSchemaNode: (id: any) => void,
};

class RowContainer extends React.Component<ContainerProps> {
  // handler for removing ink-drop from schema
  removeElement = () => {
    const { id, removeSchemaNode } = this.props;
    removeSchemaNode(id);
  };

  render() {
    const { schema, id, index } = this.props;
    return (
      <Draggable
        draggableId={id}
        index={index || 0}
        isDragDisabled={id === "@"}
      >
        {draggableProvided => (
          <ContainerWrapper
            ref={draggableProvided.innerRef}
            {...draggableProvided.draggableProps}
            {...draggableProvided.dragHandleProps}
            droppedCount={keys(schema.properties).length}
          >
            <ConditionalDroppable
              droppableId={id}
              excludeTypes={
                Pencils.pencils.layoutDefinitions.container.drop.excludeTypes
              }
              droppedCount={keys(schema.properties).length}
              droppableWrapper="Container"
            >
              <RemoveDiv className="remove-element">
                <Button color="danger" onClick={this.removeElement}>
                  <FaTimes />
                </Button>
              </RemoveDiv>
              <Row>
                <ElementsList id={id} schema={schema} index={0} />
              </Row>
            </ConditionalDroppable>
          </ContainerWrapper>
        )}
      </Draggable>
    );
  }
}

export default connect(
  null,
  {
    removeSchemaNode: schemaActions.removeSchemaNode,
  }
)(withTheme(RowContainer));
