import React from "react";
import { Input } from "reactstrap";
import {
  withRouter,
  type RouterHistory,
  type Location,
} from "react-router-dom";

export type SearchAttributes = Array<{
  name: string,
  type: "string" | "number" | "date" | "dateRange" | "list",
  options?:
    | Array<{
        id: number,
        label: string,
      }>
    | string,
}>;

type Props = {
  onSearchChange?: (search: string) => void,
  searchRoute?: string,
  // searchAttributes: SearchAttributes,
  history: RouterHistory,
  location: Location,
};
type State = {
  search: string,
};

class SearchBox extends React.Component<Props, State> {
  static defaultProps = {
    onSearchChange: undefined,
    searchRoute: undefined,
  };

  state = {
    search: "",
  };

  componentDidMount() {
    const { location } = this.props;
    if (location.hash) {
      if (location.hash.indexOf("#search/") === 0) {
        this.setState({
          search: location.hash.replace("#search/", ""),
        });
      }
    }
  }

  onChange = ({ target: { value } }) => {
    this.setState({
      search: value,
    });
  };

  submitSearch = () => {
    const { search } = this.state;
    const { onSearchChange, searchRoute } = this.props;
    if (onSearchChange) {
      onSearchChange(search);
    }
    if (searchRoute) {
      const { history } = this.props;
      history.push(`${searchRoute}#search/${search}`);
    }
  };

  onKeyPress = e => {
    if (e.key === "Enter") {
      this.submitSearch();
    }
  };

  render() {
    const { search } = this.state;
    return (
      <Input
        type="text"
        name="search"
        value={search}
        onChange={this.onChange}
        onBlur={this.submitSearch}
        onKeyPress={this.onKeyPress}
      />
    );
  }
}

export default withRouter(SearchBox);
