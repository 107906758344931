import React, { Component, Suspense } from "react";
import { Router, Route, Switch, Redirect } from "react-router-dom";
import { PersistGate } from "redux-persist/integration/react";
import { Provider } from "react-redux";

import { AuthProvider } from "@swan/auth";

// import { renderRoutes } from 'react-router-config';
// $FlowFixMe Can't get flow to recognize module without having parsing errors in CSS
import "./App.scss";

import Modules from "./modules";
// Wrappers
import { IntlProvider } from "./IntlContext";
import { RoutesProvider } from "./routes";
import { NavProvider } from "./nav";
import AuthenticatedRoute from "./HOC/AuthenticatedRoute";

// state
import { store, persistor } from "./state/store";

// utils
import { setupAxios } from "./utils/axios";
import history from "./utils/history";

// consumers
import { RoutesConsumer } from "./routes";

import CoreUILayout from "./containers/CoreUILayout";

// coreui
// import { Container } from "reactstrap";

// types
import type { Route as RouteType } from "./routes/index";
import { SwanContextProvider } from "./HOC/SwanModule";

// event listenrs
import SwanEventListener from "./events/SwanEventListener";

// pages
import {
  Login,
  LoginCheck,
  Page403,
  Page404,
  Page500,
  Register,
  Logout,
  OutlookLogin,
} from "./pages/Utilities";
import SwitchOrganization from "./pages/SwitchOrganization";

// components
import ReloadModal from "./ReloadModal";
import { AppendBreadCrumbButtons } from "@swan/helpers";

const Loading = () => (
  <div className="animated fadeIn pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse" />
  </div>
);

// Component for layout's main content page
const ContainerToLayout = () => (
  <RoutesConsumer>
    {({ routes }) => (
      <React.Fragment>
        <Suspense fallback={<Loading />}>
          <Switch>
            {routes.map((route: RouteType, idx) => {
              return route.component || route.render ? (
                <AuthenticatedRoute
                  {...route}
                  key={idx}
                  path={route.path}
                  exact={route.exact || false}
                  render={
                    route.render
                      ? route.render
                      : // $FlowFixMe
                        props => <route.component {...props} />
                  }
                />
              ) : null;
            })}
            <Redirect to="/userzone" exact={true} />
          </Switch>

          <AppendBreadCrumbButtons Components={<></>} />
        </Suspense>
      </React.Fragment>
    )}
  </RoutesConsumer>
);

type Props = {};
type State = {
  modulesLoaded: boolean,
};

class App extends Component<Props, State> {
  state = {
    modulesLoaded: false,
  };

  onModulesLoaded = () => {
    this.setState({
      modulesLoaded: true,
    });
  };

  render() {
    const { modulesLoaded } = this.state;
    return (
      <Provider store={store}>
        <PersistGate
          loading={null}
          persistor={persistor}
          onBeforeLift={() => setupAxios()}
        >
          <IntlProvider>
            <RoutesProvider>
              <NavProvider>
                <SwanContextProvider>
                  <AuthProvider>
                    <Modules onModulesLoaded={this.onModulesLoaded} />
                    <ReloadModal />
                    {/* $FlowFixMe react-router-dom and history flow types have some inconsistencies */}
                    <Router history={history}>
                      <React.Suspense fallback={<Loading />}>
                        <Switch>
                          <Route exact path="/login" component={Login} />
                          <AuthenticatedRoute
                            exact
                            path="/logout"
                            component={Logout}
                          />
                          <AuthenticatedRoute
                            path="/switch/:targetId"
                            component={SwitchOrganization}
                          />
                          <Route
                            exact
                            path="/checklogin"
                            component={LoginCheck}
                          />
                          <Route
                            exact
                            path="/outlook-login"
                            component={OutlookLogin}
                          />
                          <Route exact path="/register" component={Register} />
                          <Route exact path="/404" component={Page404} />
                          <Route exact path="/403" component={Page403} />
                          <Route exact path="/500" component={Page500} />
                          {modulesLoaded && (
                            <AuthenticatedRoute
                              path="/"
                              render={props => (
                                <div
                                  className="layout-root"
                                  style={{ display: "flex" }}
                                >
                                  <CoreUILayout {...props}>
                                    <SwanEventListener />
                                    <ContainerToLayout />
                                  </CoreUILayout>
                                </div>
                              )}
                            />
                          )}
                        </Switch>
                      </React.Suspense>
                    </Router>
                  </AuthProvider>
                </SwanContextProvider>
              </NavProvider>
            </RoutesProvider>
          </IntlProvider>
        </PersistGate>
      </Provider>
    );
  }
}

export default App;
