export default {
  "Field Label": "Field Label",

  // pencil group lables
  ATTRIB: "Unused Attributes",
  FIELD: "Standard Pencils",
  LAYOUT: "Layout Elements",
  RELOBJ: "Related Objects",
  DUPLICATIONS: "Duplication Pencils",
  CHARTS: "Charts",

  GROUP_LABEL_default: "Default",
  GROUP_LABEL_advanced: "Advanced Options",
};
