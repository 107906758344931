type parameterBagTyep = {
  endpoint: string,
};

const parameterBagas: { [string]: parameterBagTyep } = {
  maps: {
    endpoint: "https://maps.googleapis.com/maps/api/js",
    key: "AIzaSyDwGnJz0W2l7dQLjm5WERVkYWV2Mql4eoM",
    libraries: "places",
  },
  mapfiles: "http://maps.google.com/mapfiles/ms/icons",
};

export default (serviceKey: string): string => {
  if (Object.keys(parameterBagas).indexOf(serviceKey) !== -1) {
    const params = Object.keys(parameterBagas[serviceKey]);
    if (params.indexOf("endpoint") !== -1) {
      const ppair = [];
      params.map(
        (param: string): any =>
          param !== "endpoint" &&
          ppair.push(`${param}=${parameterBagas[serviceKey][param]}`)
      );
      return `${parameterBagas[serviceKey].endpoint}?${ppair.join("&")}`;
    }
  }

  // console.error("No Google service available");
  return null;
};
