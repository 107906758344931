import React from "react";
import { RecordProvider } from "@swan/helpers/CRUD";
import { ConnectedForm } from "@swan/dynamic-ui";

type FormProps = {
  object: string,
  type: string,
  service: Object,
  form: ?Object,
  formId?: any,
  ServiceFactory: (object: string, data: {}) => Object,
  record: Object,
};

class Form extends React.Component<FormProps> {
  static defaultProps = {
    formId: undefined,
  };

  shouldComponentUpdate(nextProps: FormProps) {
    const { form, record } = this.props;
    const { form: nextFormProps, record: nextRecord } = nextProps;
    // TODO find a better way to achieve this this is effecting the drag and drop for ordering
    if (
      form &&
      form.form &&
      form.form.id &&
      nextFormProps &&
      nextFormProps.form &&
      nextFormProps.form.id &&
      nextFormProps.form.id !== form.form.id
    ) {
      return true;
    }
    if (JSON.stringify(record) !== JSON.stringify(nextRecord)) {
      return true;
    }
    return false;
  }

  Component: any;

  render() {
    const {
      object,
      service,
      type,
      formId,
      ServiceFactory,
      ...restProps
    } = this.props;
    const FormConnected = ConnectedForm({
      object,
      type,
      ServiceFactory,
      formId,
    })(() => <span />);
    this.Component = RecordProvider(service)(FormConnected);
    const { Component } = this;
    return (
      <Component
        disableRecordPermission
        {...restProps}
        options={{ recordParentKnown: true }}
      />
    );
  }
}

export default Form;
