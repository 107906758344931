import * as React from "react";

import styled, { withTheme } from "styled-components";

import ElementsList from "./ElementsList";
import { type ElementProps } from "./types";

export const ContainerWrapper = styled.div(({ theme }) =>
  Object.assign({}, theme.ContainerWrapper ? { ...theme.ContainerWrapper } : {})
);

const Container = ({ schema, id }: ElementProps) => (
  <ContainerWrapper>
    <ElementsList id={id} schema={schema} index={0} />
  </ContainerWrapper>
);

export default withTheme(Container);
