const currencies = [
  {
    id: "ADP",
    name: "Andorran Peseta",
    code: "ADP",
    precision: 0,
  },
  {
    id: "AED",
    name: "UAE Dirham",
    code: "AED",
    precision: 2,
  },
  {
    id: "AFA",
    name: "Afghani (old)",
    code: "AFA",
    precision: 2,
  },
  {
    id: "AFN",
    name: "Afghani",
    code: "AFN",
    precision: 2,
  },
  {
    id: "ALL",
    name: "Lek",
    code: "ALL",
    precision: 2,
  },
  {
    id: "AMD",
    name: "Armenian Dram",
    code: "AMD",
    precision: 2,
  },
  {
    id: "ANG",
    name: "Netherlands Antillian Guilder",
    code: "ANG",
    precision: 2,
  },
  {
    id: "AOA",
    name: "Kwanza",
    code: "AOA",
    precision: 2,
  },
  {
    id: "AOK",
    name: "Kwanza (Obsolete)",
    code: "AOK",
    precision: 0,
  },
  {
    id: "AON",
    name: "New Kwanza",
    code: "AON",
    precision: 2,
  },
  {
    id: "ARA",
    name: "Austral",
    code: "ARA",
    precision: 2,
  },
  {
    id: "ARS",
    name: "Argentine Peso",
    code: "ARS",
    precision: 2,
  },
  {
    id: "ATS",
    name: "Schilling",
    code: "ATS",
    precision: 2,
  },
  {
    id: "AUD",
    name: "Australian Dollar",
    code: "AUD",
    precision: 2,
  },
  {
    id: "AWG",
    name: "Aruban Guilder",
    code: "AWG",
    precision: 2,
  },
  {
    id: "AZM",
    name: "Azerbaijanian Manat (old)",
    code: "AZM",
    precision: 2,
  },
  {
    id: "AZN",
    name: "Azerbaijanian Manat",
    code: "AZN",
    precision: 2,
  },
  {
    id: "BAM",
    name: "Convertible Marks",
    code: "BAM",
    precision: 2,
  },
  {
    id: "BBD",
    name: "Barbados Dollar",
    code: "BBD",
    precision: 2,
  },
  {
    id: "BDT",
    name: "Taka",
    code: "BDT",
    precision: 2,
  },
  {
    id: "BEF",
    name: "Belgian Franc",
    code: "BEF",
    precision: 0,
  },
  {
    id: "BGL",
    name: "Lev",
    code: "BGL",
    precision: 2,
  },
  {
    id: "BGN",
    name: "Bulgarian Lev",
    code: "BGN",
    precision: 2,
  },
  {
    id: "BHD",
    name: "Bahraini Dinar",
    code: "BHD",
    precision: 3,
  },
  {
    id: "BIF",
    name: "Burundi Franc",
    code: "BIF",
    precision: 0,
  },
  {
    id: "BMD",
    name: "Bermudian Dollar (Bermuda Dollar)",
    code: "BMD",
    precision: 2,
  },
  {
    id: "BND",
    name: "Brunei Dollar",
    code: "BND",
    precision: 2,
  },
  {
    id: "BOB",
    name: "Boliviano",
    code: "BOB",
    precision: 2,
  },
  {
    id: "BOV",
    name: "Mvdol",
    code: "BOV",
    precision: 2,
  },
  {
    id: "BRC",
    name: "Cruzado",
    code: "BRC",
    precision: 2,
  },
  {
    id: "BRL",
    name: "Brazilian Real",
    code: "BRL",
    precision: 2,
  },
  {
    id: "BSD",
    name: "Bahamian Dollar",
    code: "BSD",
    precision: 2,
  },
  {
    id: "BTN",
    name: "Ngultrum",
    code: "BTN",
    precision: 2,
  },
  {
    id: "BUK",
    name: "Kyat (Obsolete)",
    code: "BUK",
    precision: 2,
  },
  {
    id: "BWP",
    name: "Pula",
    code: "BWP",
    precision: 2,
  },
  {
    id: "BYB",
    name: "Belarussian Ruble (Obsolete)",
    code: "BYB",
    precision: 0,
  },
  {
    id: "BYR",
    name: "Belarussian Ruble",
    code: "BYR",
    precision: 0,
  },
  {
    id: "BZD",
    name: "Belize Dollar",
    code: "BZD",
    precision: 2,
  },
  {
    id: "CAD",
    name: "Canadian Dollar",
    code: "CAD",
    precision: 2,
  },
  {
    id: "CDF",
    name: "Franc Congolais",
    code: "CDF",
    precision: 2,
  },
  {
    id: "CHE",
    name: "WIR Euro",
    code: "CHE",
    precision: 2,
  },
  {
    id: "CHF",
    name: "Swiss Franc",
    code: "CHF",
    precision: 2,
  },
  {
    id: "CHW",
    name: "WIR Franc",
    code: "CHW",
    precision: 2,
  },
  {
    id: "CLF",
    name: "Unidades de formento",
    code: "CLF",
    precision: 0,
  },
  {
    id: "CLP",
    name: "Chilean Peso",
    code: "CLP",
    precision: 0,
  },
  {
    id: "CNY",
    name: "Yuan Renminbi",
    code: "CNY",
    precision: 2,
  },
  {
    id: "COP",
    name: "Colombian Peso",
    code: "COP",
    precision: 2,
  },
  {
    id: "COU",
    name: "Unidad de Valor Real",
    code: "COU",
    precision: 2,
  },
  {
    id: "CRC",
    name: "Costa Rican Colon",
    code: "CRC",
    precision: 2,
  },
  {
    id: "CSK",
    name: "Koruna",
    code: "CSK",
    precision: 2,
  },
  {
    id: "CUC",
    name: "Peso Convertible",
    code: "CUC",
    precision: 2,
  },
  {
    id: "CUP",
    name: "Cuban Peso",
    code: "CUP",
    precision: 2,
  },
  {
    id: "CVE",
    name: "Cape Verde Escudo",
    code: "CVE",
    precision: 2,
  },
  {
    id: "CYP",
    name: "Cyprus Pound",
    code: "CYP",
    precision: 2,
  },
  {
    id: "CZK",
    name: "Czech Koruna",
    code: "CZK",
    precision: 2,
  },
  {
    id: "DEM",
    name: "Deutsche Mark",
    code: "DEM",
    precision: 2,
  },
  {
    id: "DJF",
    name: "Djibouti Franc",
    code: "DJF",
    precision: 0,
  },
  {
    id: "DKK",
    name: "Danish Krone",
    code: "DKK",
    precision: 2,
  },
  {
    id: "DOP",
    name: "Dominican Peso",
    code: "DOP",
    precision: 2,
  },
  {
    id: "DZD",
    name: "Algerian Dinar",
    code: "DZD",
    precision: 2,
  },
  {
    id: "ECS",
    name: "Sucre",
    code: "ECS",
    precision: 2,
  },
  {
    id: "ECV",
    name: "Unidad de Valor Constante (UVC)",
    code: "ECV",
    precision: 2,
  },
  {
    id: "EEK",
    name: "Kroon",
    code: "EEK",
    precision: 2,
  },
  {
    id: "EGP",
    name: "Egyptian Pound",
    code: "EGP",
    precision: 2,
  },
  {
    id: "ERN",
    name: "Nakfa",
    code: "ERN",
    precision: 2,
  },
  {
    id: "ESB",
    name: "Convertible Peseta Accounts",
    code: "ESB",
    precision: 0,
  },
  {
    id: "ESP",
    name: "Spanish Peseta",
    code: "ESP",
    precision: 0,
  },
  {
    id: "ETB",
    name: "Ethiopian Birr",
    code: "ETB",
    precision: 2,
  },
  {
    id: "EUR",
    name: "Euro",
    code: "EUR",
    precision: 2,
  },
  {
    id: "FIM",
    name: "Markka",
    code: "FIM",
    precision: 2,
  },
  {
    id: "FJD",
    name: "Fiji Dollar",
    code: "FJD",
    precision: 2,
  },
  {
    id: "FKP",
    name: "Falkland Islands Pound",
    code: "FKP",
    precision: 2,
  },
  {
    id: "FRF",
    name: "French Franc",
    code: "FRF",
    precision: 2,
  },
  {
    id: "GBP",
    name: "Pound Sterling",
    code: "GBP",
    precision: 2,
  },
  {
    id: "GEK",
    name: "Georgian Coupon",
    code: "GEK",
    precision: 0,
  },
  {
    id: "GEL",
    name: "Lari",
    code: "GEL",
    precision: 2,
  },
  {
    id: "GHC",
    name: "Ghana Cedi (old)",
    code: "GHC",
    precision: 2,
  },
  {
    id: "GHS",
    name: "Ghana Cedi",
    code: "GHS",
    precision: 2,
  },
  {
    id: "GIP",
    name: "Gibraltar Pound",
    code: "GIP",
    precision: 2,
  },
  {
    id: "GMD",
    name: "Dalasi",
    code: "GMD",
    precision: 2,
  },
  {
    id: "GNF",
    name: "Guinea Franc",
    code: "GNF",
    precision: 0,
  },
  {
    id: "GRD",
    name: "Drachma",
    code: "GRD",
    precision: 0,
  },
  {
    id: "GTQ",
    name: "Quetzal",
    code: "GTQ",
    precision: 2,
  },
  {
    id: "GWP",
    name: "Guinea-Bissau Peso",
    code: "GWP",
    precision: 2,
  },
  {
    id: "GYD",
    name: "Guyana Dollar",
    code: "GYD",
    precision: 2,
  },
  {
    id: "HKD",
    name: "Hong Kong Dollar",
    code: "HKD",
    precision: 2,
  },
  {
    id: "HNL",
    name: "Lempira",
    code: "HNL",
    precision: 2,
  },
  {
    id: "HRD",
    name: "Croatian Dinar",
    code: "HRD",
    precision: 0,
  },
  {
    id: "HRK",
    name: "Croatian kuna",
    code: "HRK",
    precision: 2,
  },
  {
    id: "HTG",
    name: "Gourde",
    code: "HTG",
    precision: 2,
  },
  {
    id: "HUF",
    name: "Forint",
    code: "HUF",
    precision: 2,
  },
  {
    id: "IDR",
    name: "Rupiah",
    code: "IDR",
    precision: 2,
  },
  {
    id: "IEP",
    name: "Irish Pound",
    code: "IEP",
    precision: 2,
  },
  {
    id: "ILS",
    name: "New Israeli Sheqel",
    code: "ILS",
    precision: 2,
  },
  {
    id: "INR",
    name: "Indian Rupee",
    code: "INR",
    precision: 2,
  },
  {
    id: "IQD",
    name: "Iraqi Dinar",
    code: "IQD",
    precision: 3,
  },
  {
    id: "IRR",
    name: "Iranian Rial",
    code: "IRR",
    precision: 2,
  },
  {
    id: "ISK",
    name: "Iceland Krona",
    code: "ISK",
    precision: 2,
  },
  {
    id: "ITL",
    name: "Italian Lira",
    code: "ITL",
    precision: 0,
  },
  {
    id: "JMD",
    name: "Jamaican Dollar",
    code: "JMD",
    precision: 2,
  },
  {
    id: "JOD",
    name: "Jordanian Dinar",
    code: "JOD",
    precision: 3,
  },
  {
    id: "JPY",
    name: "Yen",
    code: "JPY",
    precision: 0,
  },
  {
    id: "KES",
    name: "Kenyan Shilling",
    code: "KES",
    precision: 2,
  },
  {
    id: "KGS",
    name: "Som",
    code: "KGS",
    precision: 2,
  },
  {
    id: "KHR",
    name: "Riel",
    code: "KHR",
    precision: 2,
  },
  {
    id: "KMF",
    name: "Comoro Franc",
    code: "KMF",
    precision: 0,
  },
  {
    id: "KPW",
    name: "North Korean Won",
    code: "KPW",
    precision: 2,
  },
  {
    id: "KRW",
    name: "Won",
    code: "KRW",
    precision: 0,
  },
  {
    id: "KWD",
    name: "Kuwaiti Dinar",
    code: "KWD",
    precision: 3,
  },
  {
    id: "KYD",
    name: "Cayman Islands Dollar",
    code: "KYD",
    precision: 2,
  },
  {
    id: "KZT",
    name: "Tenge",
    code: "KZT",
    precision: 2,
  },
  {
    id: "LAK",
    name: "Kip",
    code: "LAK",
    precision: 2,
  },
  {
    id: "LBP",
    name: "Lebanese Pound",
    code: "LBP",
    precision: 2,
  },
  {
    id: "LKR",
    name: "Sri Lanka Rupee",
    code: "LKR",
    precision: 2,
  },
  {
    id: "LRD",
    name: "Liberian Dollar",
    code: "LRD",
    precision: 2,
  },
  {
    id: "LSL",
    name: "Loti",
    code: "LSL",
    precision: 2,
  },
  {
    id: "LTL",
    name: "Lithuanian Litas",
    code: "LTL",
    precision: 2,
  },
  {
    id: "LUC",
    name: "Convertible Franc",
    code: "LUC",
    precision: 0,
  },
  {
    id: "LUF",
    name: "Luxembourg Franc",
    code: "LUF",
    precision: 0,
  },
  {
    id: "LUL",
    name: "Financial Franc",
    code: "LUL",
    precision: 0,
  },
  {
    id: "LVL",
    name: "Latvian Lats",
    code: "LVL",
    precision: 2,
  },
  {
    id: "LVR",
    name: "Latvian Ruble",
    code: "LVR",
    precision: 2,
  },
  {
    id: "LYD",
    name: "Libyan Dinar",
    code: "LYD",
    precision: 3,
  },
  {
    id: "MAD",
    name: "Moroccan Dirham",
    code: "MAD",
    precision: 2,
  },
  {
    id: "MDL",
    name: "Moldovan Leu",
    code: "MDL",
    precision: 2,
  },
  {
    id: "MGA",
    name: "Malagasy Ariary",
    code: "MGA",
    precision: 2,
  },
  {
    id: "MGF",
    name: "Malagasy Franc",
    code: "MGF",
    precision: 0,
  },
  {
    id: "MKD",
    name: "Denar",
    code: "MKD",
    precision: 2,
  },
  {
    id: "MMK",
    name: "Kyat",
    code: "MMK",
    precision: 2,
  },
  {
    id: "MNT",
    name: "Tugrik",
    code: "MNT",
    precision: 2,
  },
  {
    id: "MOP",
    name: "Pataca",
    code: "MOP",
    precision: 2,
  },
  {
    id: "MRO",
    name: "Ouguiya",
    code: "MRO",
    precision: 2,
  },
  {
    id: "MTL",
    name: "Maltese Lira",
    code: "MTL",
    precision: 2,
  },
  {
    id: "MUR",
    name: "Mauritius Rupee",
    code: "MUR",
    precision: 2,
  },
  {
    id: "MVR",
    name: "Rufiyaa",
    code: "MVR",
    precision: 2,
  },
  {
    id: "MWK",
    name: "Kawacha",
    code: "MWK",
    precision: 2,
  },
  {
    id: "MXN",
    name: "Mexican Peso",
    code: "MXN",
    precision: 2,
  },
  {
    id: "MXP",
    name: "Mexican Peso (Obsolete)",
    code: "MXP",
    precision: 0,
  },
  {
    id: "MXV",
    name: "Mexican Unidad de Inversion (UDI)",
    code: "MXV",
    precision: 2,
  },
  {
    id: "MYR",
    name: "Malaysian Ringgit",
    code: "MYR",
    precision: 2,
  },
  {
    id: "MZM",
    name: "Metical (old)",
    code: "MZM",
    precision: 2,
  },
  {
    id: "MZN",
    name: "Metical",
    code: "MZN",
    precision: 2,
  },
  {
    id: "NAD",
    name: "Namibia Dollar",
    code: "NAD",
    precision: 2,
  },
  {
    id: "NGN",
    name: "Naira",
    code: "NGN",
    precision: 2,
  },
  {
    id: "NIC",
    name: "Cordoba",
    code: "NIC",
    precision: 2,
  },
  {
    id: "NIO",
    name: "Cordoba Oro",
    code: "NIO",
    precision: 2,
  },
  {
    id: "NLG",
    name: "Netherlands Guilder",
    code: "NLG",
    precision: 2,
  },
  {
    id: "NOK",
    name: "Norwegian Krone",
    code: "NOK",
    precision: 2,
  },
  {
    id: "NPR",
    name: "Nepalese Rupee",
    code: "NPR",
    precision: 2,
  },
  {
    id: "NZD",
    name: "New Zealand Dollar",
    code: "NZD",
    precision: 2,
  },
  {
    id: "OMR",
    name: "Rial Omani",
    code: "OMR",
    precision: 3,
  },
  {
    id: "PAB",
    name: "Balboa",
    code: "PAB",
    precision: 2,
  },
  {
    id: "PEI",
    name: "Inti",
    code: "PEI",
    precision: 2,
  },
  {
    id: "PEN",
    name: "Nuevo Sol",
    code: "PEN",
    precision: 2,
  },
  {
    id: "PGK",
    name: "Kina",
    code: "PGK",
    precision: 2,
  },
  {
    id: "PHP",
    name: "Philippine Peso",
    code: "PHP",
    precision: 2,
  },
  {
    id: "PKR",
    name: "Pakistan Rupee",
    code: "PKR",
    precision: 2,
  },
  {
    id: "PLN",
    name: "Zloty",
    code: "PLN",
    precision: 2,
  },
  {
    id: "PLZ",
    name: "Zloty (Obsolete)",
    code: "PLZ",
    precision: 2,
  },
  {
    id: "PTE",
    name: "Portuguese Escudo",
    code: "PTE",
    precision: 0,
  },
  {
    id: "PYG",
    name: "Guarani",
    code: "PYG",
    precision: 0,
  },
  {
    id: "QAR",
    name: "Qatari Rial",
    code: "QAR",
    precision: 2,
  },
  {
    id: "ROL",
    name: "Leu",
    code: "ROL",
    precision: 2,
  },
  {
    id: "RON",
    name: "New Romanian Leu",
    code: "RON",
    precision: 2,
  },
  {
    id: "RSD",
    name: "Serbian Dinar",
    code: "RSD",
    precision: 2,
  },
  {
    id: "RUB",
    name: "Russian Ruble",
    code: "RUB",
    precision: 2,
  },
  {
    id: "RUR",
    name: "Russian Ruble (old)",
    code: "RUR",
    precision: 2,
  },
  {
    id: "RWF",
    name: "Rwanda Franc",
    code: "RWF",
    precision: 0,
  },
  {
    id: "SAR",
    name: "Saudi Riyal",
    code: "SAR",
    precision: 2,
  },
  {
    id: "SBD",
    name: "Solomon Islands Dollar",
    code: "SBD",
    precision: 2,
  },
  {
    id: "SCR",
    name: "Seychelles Rupee",
    code: "SCR",
    precision: 2,
  },
  {
    id: "SDD",
    name: "Sudanese Dinar (old)",
    code: "SDD",
    precision: 2,
  },
  {
    id: "SDG",
    name: "Sudanese Dinar",
    code: "SDG",
    precision: 2,
  },
  {
    id: "SDP",
    name: "Sudanese Pound",
    code: "SDP",
    precision: 2,
  },
  {
    id: "SEK",
    name: "Swedish Krona",
    code: "SEK",
    precision: 2,
  },
  {
    id: "SGD",
    name: "Singapore Dollar",
    code: "SGD",
    precision: 2,
  },
  {
    id: "SHP",
    name: "Saint Helena Pound",
    code: "SHP",
    precision: 2,
  },
  {
    id: "SIT",
    name: "Tolar",
    code: "SIT",
    precision: 2,
  },
  {
    id: "SKK",
    name: "Slovak Koruna",
    code: "SKK",
    precision: 2,
  },
  {
    id: "SLL",
    name: "Leone",
    code: "SLL",
    precision: 2,
  },
  {
    id: "SOS",
    name: "Somali Shilling",
    code: "SOS",
    precision: 2,
  },
  {
    id: "SRD",
    name: "Suriname Dollar",
    code: "SRD",
    precision: 2,
  },
  {
    id: "SRG",
    name: "Suriname Guilder",
    code: "SRG",
    precision: 2,
  },
  {
    id: "STAT",
    name: "Statistical",
    code: "STAT",
    precision: 2,
  },
  {
    id: "STD",
    name: "Dobra",
    code: "STD",
    precision: 2,
  },
  {
    id: "SUR",
    name: "Rouble",
    code: "SUR",
    precision: 2,
  },
  {
    id: "SVC",
    name: "El Salvador Colon",
    code: "SVC",
    precision: 2,
  },
  {
    id: "SYP",
    name: "Syrian Pound",
    code: "SYP",
    precision: 2,
  },
  {
    id: "SZL",
    name: "Lilangeni",
    code: "SZL",
    precision: 2,
  },
  {
    id: "THB",
    name: "Baht",
    code: "THB",
    precision: 2,
  },
  {
    id: "TJR",
    name: "Tajik Ruble",
    code: "TJR",
    precision: 0,
  },
  {
    id: "TJS",
    name: "Somoni",
    code: "TJS",
    precision: 2,
  },
  {
    id: "TMM",
    name: "Manat (Obsolete)",
    code: "TMM",
    precision: 2,
  },
  {
    id: "TMT",
    name: "Manat",
    code: "TMT",
    precision: 2,
  },
  {
    id: "TND",
    name: "Tunisian Dinar",
    code: "TND",
    precision: 3,
  },
  {
    id: "TOP",
    name: "Pa'anga",
    code: "TOP",
    precision: 2,
  },
  {
    id: "TPE",
    name: "Timor Escudo",
    code: "TPE",
    precision: 0,
  },
  {
    id: "TRL",
    name: "Turkish Lira",
    code: "TRL",
    precision: 0,
  },
  {
    id: "TRY",
    name: "New Turkish Lira",
    code: "TRY",
    precision: 2,
  },
  {
    id: "TTD",
    name: "Trinidad and Tobago Dollar",
    code: "TTD",
    precision: 2,
  },
  {
    id: "TWD",
    name: "New Taiwan Dollar",
    code: "TWD",
    precision: 2,
  },
  {
    id: "TZS",
    name: "Tanzanian Shilling",
    code: "TZS",
    precision: 2,
  },
  {
    id: "UAH",
    name: "Hryvnia",
    code: "UAH",
    precision: 2,
  },
  {
    id: "UAK",
    name: "Karbovanet",
    code: "UAK",
    precision: 2,
  },
  {
    id: "UGS",
    name: "Uganda Shilling (Obsolete)",
    code: "UGS",
    precision: 2,
  },
  {
    id: "UGX",
    name: "Uganda Shilling",
    code: "UGX",
    precision: 2,
  },
  {
    id: "USD",
    name: "US Dollar",
    code: "USD",
    precision: 2,
  },
  {
    id: "USN",
    name: "US Dollar (next day)",
    code: "USN",
    precision: 2,
  },
  {
    id: "USS",
    name: "US Dollar (same day)",
    code: "USS",
    precision: 2,
  },
  {
    id: "UYI",
    name: "Uruguary Peso en Unidades Indexadas",
    code: "UYI",
    precision: 0,
  },
  {
    id: "UYP",
    name: "Uruguayan Peso",
    code: "UYP",
    precision: 2,
  },
  {
    id: "UYU",
    name: "Peso Uruguayo",
    code: "UYU",
    precision: 2,
  },
  {
    id: "UZS",
    name: "Uzbekistan Sum",
    code: "UZS",
    precision: 2,
  },
  {
    id: "VEB",
    name: "Bolivar",
    code: "VEB",
    precision: 2,
  },
  {
    id: "VEF",
    name: "Bolivar Fuerte",
    code: "VEF",
    precision: 2,
  },
  {
    id: "VND",
    name: "Dong",
    code: "VND",
    precision: 2,
  },
  {
    id: "VUV",
    name: "Vatu",
    code: "VUV",
    precision: 0,
  },
  {
    id: "WST",
    name: "Tala",
    code: "WST",
    precision: 2,
  },
  {
    id: "XAF",
    name: "CFA Franc BEAC",
    code: "XAF",
    precision: 0,
  },
  {
    id: "XAG",
    name: "Silver",
    code: "XAG",
    precision: 0,
  },
  {
    id: "XAU",
    name: "Gold",
    code: "XAU",
    precision: 0,
  },
  {
    id: "XB5",
    name: "Bond Markets Units - European Unit of Account 17 (Obsolete)",
    code: "XB5",
    precision: 0,
  },
  {
    id: "XBA",
    name: "Bond Markets Units - European Composite Unit (EURCO)",
    code: "XBA",
    precision: 0,
  },
  {
    id: "XBB",
    name: "Bond Markets Units - European Monetary Unit (E.M.U.-6)",
    code: "XBB",
    precision: 0,
  },
  {
    id: "XBC",
    name: "Bond Markets Units - European Unit of Account 9 (E.U.A.-9)",
    code: "XBC",
    precision: 0,
  },
  {
    id: "XBD",
    name: "Bond Markets Units - European Unit of Account 17 (E.U.A.-17)",
    code: "XBD",
    precision: 0,
  },
  {
    id: "XCD",
    name: "East Carribbean Dollar",
    code: "XCD",
    precision: 2,
  },
  {
    id: "XDR",
    name: "SDR",
    code: "XDR",
    precision: 0,
  },
  {
    id: "XEU",
    name: "European Currency Unit (E.C.U.)",
    code: "XEU",
    precision: 2,
  },
  {
    id: "XFO",
    name: "Special settlement currencies - Gold-Franc",
    code: "XFO",
    precision: 0,
  },
  {
    id: "XFU",
    name: "Special settlement currencies - UIC-Franc",
    code: "XFU",
    precision: 0,
  },
  {
    id: "XOF",
    name: "CFA Franc BCEAO",
    code: "XOF",
    precision: 0,
  },
  {
    id: "XPD",
    name: "Palladium",
    code: "XPD",
    precision: 0,
  },
  {
    id: "XPF",
    name: "CFP Franc",
    code: "XPF",
    precision: 0,
  },
  {
    id: "XPT",
    name: "Platinum",
    code: "XPT",
    precision: 0,
  },
  {
    id: "XTS",
    name: "Testing",
    code: "XTS",
    precision: 0,
  },
  {
    id: "XXX",
    name: "No currency",
    code: "XXX",
    precision: 0,
  },
  {
    id: "YDD",
    name: "Yemeni Dinar",
    code: "YDD",
    precision: 3,
  },
  {
    id: "YER",
    name: "Yemeni Rial",
    code: "YER",
    precision: 2,
  },
  {
    id: "YUD",
    name: "New Yugoslavian Dinar",
    code: "YUD",
    precision: 0,
  },
  {
    id: "YUM",
    name: "Yugoslvian Dinar",
    code: "YUM",
    precision: 2,
  },
  {
    id: "YUN",
    name: "Yugoslvian Dinar (Obsolete)",
    code: "YUN",
    precision: 2,
  },
  {
    id: "ZAL",
    name: "Financial Rand",
    code: "ZAL",
    precision: 2,
  },
  {
    id: "ZAR",
    name: "Rand",
    code: "ZAR",
    precision: 2,
  },
  {
    id: "ZMK",
    name: "Kwacha",
    code: "ZMK",
    precision: 2,
  },
  {
    id: "ZRN",
    name: "New Zaire",
    code: "ZRN",
    precision: 2,
  },
  {
    id: "ZRZ",
    name: "Zaire",
    code: "ZRZ",
    precision: 2,
  },
  {
    id: "ZWD",
    name: "Zimbabwe Dollar (Obsolete)",
    code: "ZWD",
    precision: 2,
  },
  {
    id: "ZWL",
    name: "Zimbabwe Dollar",
    code: "ZWL",
    precision: 2,
  },
];

const data: Array<{ value: string, name: mixed }> = currencies.map(
  (currency: { code: string, name: string }) => ({
    value: currency.code,
    name: currency.name,
  })
);

export default data;
