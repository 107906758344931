// @flow
import React, { type Node } from "react";
// import { ThemeProvider } from "emotion-theming";
import { ThemeProvider } from "styled-components";

// components
import Main from "./components/Main";

// utils
import { withProvider as withStoreProvider } from "./utils/storeProvider";

// store
import configureStore from "./state/store";

// themes
import themes from "./themes";

// types
import {
  type theme,
  type ObjectRelationPropType,
  type WorkflowPropType,
  type PencilType,
} from "./types";

// Note: objectAttrib is not in plural form, sounds like DOS ATTRIB
// Assume that objectAttrib receives array of ojbects

type Props = {
  theme: string,
  customTheme: theme,
  formId: number,
  schema: {},
  object: {},
  objectAttributes: Array<{}>,
  objectRelation: ObjectRelationPropType,
  pencilDefs?: PencilType,
  workflows: WorkflowPropType,
  save: (schema: Object, fieldsDiff: Object) => void,
  onCancel?: () => void,
  buttons: Array<{
    label: string,
    onClick: Function,
  }>,
  getStatus?: Function,
};

const FormDesigner = (props: Props): Node => {
  const {
    theme: themeName,
    customTheme,
    schema,
    object,
    objectAttributes,
    objectRelation,
    pencilDefs,
    workflows,
    onCancel,
    save,
    buttons,
    getStatus,
    formId,
  } = props;

  return (
    <ThemeProvider
      theme={{
        ...(themes || {})[themeName || "default"],
        ...(customTheme || {}),
      }}
    >
      <Main
        {...{
          schema,
          object,
          objectAttrib: objectAttributes,
          objectRelation,
          pencilDefs,
          workflows,
          save,
          buttons,
          onCancel,
          getStatus,
          formId,
        }}
      />
    </ThemeProvider>
  );
};

FormDesigner.defaultProps = {
  onCancel: undefined,
  getStatus: undefined,
  pencilDefs: undefined,
};

export default withStoreProvider(FormDesigner, {
  configureStore,
});
