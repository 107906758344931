import React from "react";
import { Input } from "reactstrap";
import FieldWrapper, { type FieldProps } from "./partials/FieldWrapper";

export type InputProps = FieldProps & {
  placeholder?: string,
  type?: string,
  getRef?: (ref: Object) => void,
  rows?: number,
};

const Field = ({
  type,
  label,
  name,
  placeholder,
  required,
  value,
  onChange,
  onBlur,
  className,
  error,
  getRef,
  id,
  rows,
  min,
  max,
}: InputProps) => (
  <Input
    type={type || "text"}
    rows={type === "textarea" ? rows || 1 : undefined}
    id={id || name}
    placeholder={placeholder || label || ""}
    required={required || false}
    value={typeof value === "number" ? value : value || ""}
    onChange={onChange}
    onBlur={onBlur}
    className={`${className || ""} ${error !== "" ? "is-invalid" : ""}`}
    {...(required ? { "data-required": true } : {})}
    innerRef={getRef}
    {...(type === "number" && typeof min !== "undefined" ? { min } : {})}
    {...(type === "number" && typeof max !== "undefined" ? { max } : {})}
  />
);

export default FieldWrapper(Field);
