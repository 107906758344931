import React, { useEffect } from "react";

const BREAD_CRUMB_BUTTONS_CONTAINER_NAME = "breadcrumb-buttons-container";

const listOfComponents = [];

/**
 * used to append components to breadcrumb buttons area components
 * @param Components
 */
export const AppendBreadCrumbButtons = ({
  Components,
}: {
  Components: Object,
}) => {
  useEffect(() => {
    listOfComponents.push(Components);
  }, []);
  return null;
};

export default () => (
  <div className="mida-container" id={BREAD_CRUMB_BUTTONS_CONTAINER_NAME}>
    {listOfComponents.map(Element => (
      <>{Element}</>
    ))}
  </div>
);
