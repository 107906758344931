// @flow
import React, { Component } from "react";
import styled from "styled-components";
import { Button, Modal } from "@swan/themer";
import { FaRegLifeRing as PreviewIcon } from "react-icons/fa";
import ConditionsEditor from "../ConditionsEditor";
import { conditionsToString } from "../../utils/processConditions";

type Props = {
  object?: string,
  resource?: Object,
  //   inputName?: string,
  //   inputType?: string,
  //   inputValue?: string | number,
  //   placeholder?: string,
  onChange?: Function,
};

type State = {
  isEditorModalOpen: boolean,
  isDetailModalOpen: boolean,
};

const Wrapper = styled("div")`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  position: relative;
`;

// eslint-disable-next-line react/prefer-stateless-function
export default class Input extends Component<Props, State> {
  static defaultProps = {
    object: undefined,
    resource: undefined,
    // inputType: "TEXT",
    // inputName: undefined,
    // inputValue: undefined,
    // placeholder: undefined,
    onChange: undefined,
  };

  constructor(props: Props) {
    super(props);
    this.state = {
      isEditorModalOpen: false,
      isDetailModalOpen: false,
    };
  }

  toggleEditorModal = () => {
    this.setState(({ isEditorModalOpen }) => ({
      isEditorModalOpen: !isEditorModalOpen,
    }));
  };

  toggleDetailModal = () => {
    this.setState(({ isDetailModalOpen }) => ({
      isDetailModalOpen: !isDetailModalOpen,
    }));
  };

  onChange = async (resource: Object) => {
    const { onChange } = this.props;
    const RhsResource = {
      type: "CONDITION",
      data: { ...resource },
    };
    if (onChange) {
      onChange(RhsResource);
    }

    return RhsResource;
  };

  onChangeConditions = (resource: Object) => {
    this.onChange(resource).then(() => {
      this.toggleEditorModal();
    });
  };

  render() {
    const { object, resource } = this.props;
    const { isEditorModalOpen, isDetailModalOpen } = this.state;

    return (
      <Wrapper>
        <Button onClick={this.toggleEditorModal} size="sm">
          Sub Conditions
        </Button>
        <Button onClick={this.toggleDetailModal} size="sm" color="warning">
          <PreviewIcon /> Condition Preview
        </Button>

        <Modal isOpen={isEditorModalOpen} toggle={this.toggleEditorModal}>
          <ConditionsEditor
            {...(resource && resource.data ? { ...resource.data } : {})}
            object={object}
            onCancel={this.toggleEditorModal}
            onSave={this.onChangeConditions}
          />
        </Modal>

        <Modal
          isOpen={isDetailModalOpen}
          toggle={this.toggleDetailModal}
          title="Condition String"
          enableBackdrop
        >
          {conditionsToString((resource || {}).data)}
        </Modal>
      </Wrapper>
    );
  }
}
