import React, { useState } from "react";
import {
  Dropdown as DropdownReactstrap,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import styled from "styled-components";

const Dropdown = styled(DropdownReactstrap)`
  display: inline;
`;

const Toggle = styled(DropdownToggle)`
  margin-right: 5px;
  padding: 5px 8px !important;
  border-radius: 2px !important;
  color: #f3f4f6 !important;
  background-color: transparent !important;
  border: none !important;
  font-size: inherit !important;
  outline: none;

  ${(props: any) => props.isOpen && "background-color: #1f4083 !important"}

  :hover {
    background-color: #395998 !important;
  }
  :focus {
    outline: none !important;
    box-shadow: none !important;
    border: none !important;
  }
`;

const Menu = styled(DropdownMenu)``;

const Item = styled(DropdownItem)`
  ${(props: any) => props.active && "background-color: #1f4083 !important"}

  :focus {
    outline: none !important;
  }

  :active {
    background-color: #1f4083 !important;
  }
`;

export default ({ label, menuItems }: any) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => setIsOpen(!isOpen);

  return (
    <Dropdown toggle={toggle} isOpen={isOpen}>
      <Toggle isOpen={isOpen}>{label}</Toggle>
      <Menu>
        {menuItems.map(item => (
          <Item onClick={item.handleClick} active={item.active}>
            {item.label}
          </Item>
        ))}
      </Menu>
    </Dropdown>
  );
};
