import React from "react";
import { uriProvider } from "./utils/uriHelpers";
import {
  MODULE_NAME,
  MODULE_DISPLAY_NAME,
  MODULE_SHORT_DISPLAY_NAME,
} from "./config";

// $FlowFixMe Should be React.lazy<DashboardProps> but causing issue in webpack
const Home = React.lazy(() => import("./Module"));

// https://github.com/ReactTraining/react-router/tree/master/packages/react-router-config
const entry_routes = [
  {
    path: uriProvider(),
    name: MODULE_NAME,
    component: Home,
    Permission: ["module:hcm"],
  },
];

export default {
  name: MODULE_NAME,
  displayName: MODULE_DISPLAY_NAME,
  mainNav: {
    name: MODULE_DISPLAY_NAME,
    url: uriProvider(), // default landing page
    icon: "fa-cogs",
    permissions: ["module:hcm"],
    color: "#57D9A3",
    shortName: MODULE_SHORT_DISPLAY_NAME,
    weight: 30,
  },
  preloadPermissions: {
    groups: ["hcm", "core"],
  },
  productNav: [
    {
      name: "Dashboard",
      url: uriProvider(),
      collapseOnClick: true,
      icon: "fa-cogs",
      permissions: ["module:hcm"],
    },
    {
      name: "My Approvals",
      url: uriProvider("my-approvals"),
      collapseOnClick: true,
      icon: "fa-cogs",
      permissionsMatchAll: false,
      permissions: [
        "hcmEmployee:edit",
        "hcmEmployeeDependent:edit",
        "hcmRequestAirTicketAllowance:view",
        "hcmRequestEducationAllowance:view",
        "hcmRequestHouseRentAllowance:view",
        "hcmRequestLetter:view",
        "hcmRequestSettlingAllowance:view",
        "hcmTalentManager:view",
        "hcmTalentRequest:view",
      ],
    },
    {
      name: "Employee Management",
      header: true,
      permissions: ["hcmEmployee:view"],
    },
    {
      name: "Employees Profile",
      url: uriProvider("employees"),
      icon: "fa-cogs",
      permissions: ["hcmEmployee:view"],
      collapseOnClick: true,
    },
    {
      name: "Request Management",
      url: uriProvider("requests"),
      icon: "fa-cogs",
      permissions: [
        "hcmRequestManager:view",
        "hcmRequestApprover:view",
        "hcmRequestEligibilityPeriodConfiguration:view",
        "hcmRequestType:view",
        "hcmRequestLetterType:view",
        "hcmRequestDocumentConfiguration:view",
      ],
      permissionsMatchAll: false,
      collapseOnClick: true,
    },
    {
      name: "Leaves Management",
      url: uriProvider("leaves"),
      icon: "fa-cogs",
      permissions: ["hcmLeaveManager:view"],
      collapseOnClick: true,
    },
    {
      name: "Total Payroll Cost",
      url: uriProvider("export-tpc"),
      icon: "fa-cogs",
      permissions: ["hcm:export-tpc"],
      collapseOnClick: true,
    },
    {
      name: "Employee Self Service",
      header: true,
      permissionsMatchAll: false,
      permissions: [
        "hcmRequestAirTicketAllowance:view",
        "hcmRequestHouseRentAllowance:view",
        "hcmRequestEducationAllowance:view",
        "hcmRequestSettlingAllowance:view",
        "hcmRequestLetter:view",
      ],
    },
    {
      name: "Requests",
      url: uriProvider("my-requests"),
      icon: "fa-cogs",
      permissionsMatchAll: false,
      permissions: [
        "hcmRequestAirTicketAllowance:view",
        "hcmRequestHouseRentAllowance:view",
        "hcmRequestEducationAllowance:view",
        "hcmRequestSettlingAllowance:view",
        "hcmRequestLetter:view",
        "hcmPayslip:view",
      ],
      collapseOnClick: true,
    },
    {
      name: "Leaves",
      url: uriProvider("my-leaves"),
      icon: "fa-cogs",
      permissionsMatchAll: false,
      permissions: ["hcmLeave:view"],
      collapseOnClick: true,
    },
    {
      name: "Appraisals",
      url: uriProvider("manage-appraisals"),
      collapseOnClick: true,
      icon: "fa-cogs",
      permissions: ["hcmAppraisal:view"],
    },
    {
      name: "Noujoum",
      url: uriProvider("noujoum"),
      collapseOnClick: true,
      icon: "fa-cogs",
      permissions: ["hcmNoujoumRecognition:view"],
    },

    {
      name: "HR & PRO Value Point",
      header: true,
      permissionsMatchAll: false,
      permissions: ["hcmValuePointRequest:view", "hcmValuePointPRRequest:view"],
    },
    {
      name: "Requests",
      url: uriProvider("value-point-requests"),
      icon: "fa-cogs",
      permissionsMatchAll: false,
      permissions: ["hcmValuePointRequest:view", "hcmValuePointPRRequest:view"],
      collapseOnClick: true,
    },
    {
      name: "Talents Management",
      header: true,
      permissionsMatchAll: false,
      permissions: [
        "hcmTalentRequest:view",
        "hcmTalentVacancy:view",
        "hcmTalentCandidate:view",
        "hcmTalentHiring:view",
      ],
      collapseOnClick: true,
    },
    {
      name: "Requests/TRF",
      url: uriProvider("talents/requests"),
      collapseOnClick: true,
      icon: "fa-cogs",
      permissionsMatchAll: false,
      permissions: ["hcmTalentRequest:view"],
    },
    {
      name: "Recruitment",
      url: uriProvider("talents"),
      collapseOnClick: true,
      icon: "fa-cogs",
      permissionsMatchAll: false,
      permissions: [
        "hcmTalentRequest:view",
        "hcmTalentVacancy:view",
        "hcmTalentCandidate:view",
        "hcmTalentHiring:view",
      ],
    },
    {
      name: "Configurations",
      header: true,
      permissions: ["hcmMasters:view"],
    },
    {
      name: "Masters",
      url: uriProvider("/masters"),
      icon: "fa-cogs",
      permissions: ["hcmMasters:view"],
      collapseOnClick: true,
    },
    {
      name: "Appraisal Cycles",
      url: uriProvider("planning-cycles"),
      icon: "fa-cogs",
      permissions: ["hcmAppraisalCycle:edit"],
      collapseOnClick: true,
    },
  ],
  routes: entry_routes,
};
