export default {
  // app
  APP_LOADING: "APP/LOADING",
  APP_LOADING_PENCILS: "APP/LOADING_PENCILS",
  APP_LOADING_ARTS: "APP/LOADING_ARTS",
  APP_LOAD_ATTRIBUTES: "APP/LOAD_ATTRIBUTES",
  APP_LOAD_OBJECT_FORM_ID: "APP/APP_LOAD_OBJECT_FORM_ID",
  APP_READY: "APP/READY",
  APP_UI: "APP/UI",
  // dragging
  START_DRAGGING: "APP/START_DRAGGING",
  STOP_DRAGGING: "APP/STOP_DRAGGING",
  GO_TO_TAB: "APP/GO_TO_TAB",
};
