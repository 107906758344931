// @flow

export const resourceFieldTypes = {
  DEFAULT: "singleLine",
  // Sys Object Attribute fieldTypes
  SINGLE_LINE: "singleLine",
  NUMBER: "numberField",
  DECIMAL: "decimalField",
  EMAIL: "email",
  // collection
  COLLECTION: "collection", // opens sub condition
};

export const defaultResourceTemplates = {
  DEFAULT: {
    type: "CONSTANTS",
    dataType: "TEXT",
    data: { input: "" },
  },
  NUMBER: {
    type: "CONSTANTS",
    dataType: "NUMBER",
    data: { input: 1, placeholder: "Number" },
  },
  EMAIL: {
    type: "CONSTANTS",
    dataType: "EMAIL",
    data: { input: "", placeholder: "user@example.com" },
  },
  ATTRIBUTE: {
    type: "ATTRIBUTE",
    data: {},
  },
  CONDITIONS: {
    type: "CONDITION",
    data: {
      conditions: [
        {
          id: 1,
          operandLeft: {
            type: "ATTRIBUTE",
            data: {},
          },
        },
      ],
      evalPattern: "ALL_AND",
    },
  },
};

export const dictionary: Array<Object> = [
  {
    fieldType: resourceFieldTypes.DEFAULT,
    resource: defaultResourceTemplates.DEFAULT,
  },
  {
    fieldType: resourceFieldTypes.NUMBER,
    resource: defaultResourceTemplates.NUMBER,
  },
  {
    fieldType: resourceFieldTypes.DECIMAL,
    resource: defaultResourceTemplates.NUMBER,
  },
  {
    fieldType: resourceFieldTypes.EMAIL,
    resource: defaultResourceTemplates.EMAIL,
  },
  {
    fieldType: resourceFieldTypes.COLLECTION,
    resource: defaultResourceTemplates.CONDITIONS,
  },
];

export default (filter?: { key: any, value: any } = {}) =>
  (
    dictionary.find(
      item =>
        item[filter.key || "fieldType"] ===
        (filter.value || resourceFieldTypes.DEFAULT)
    ) || {}
  ).resource || defaultResourceTemplates.DEFAULT;
