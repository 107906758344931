export default [
  {
    name: "Christianity",
    value: "CHRISTIANITY",
  },
  {
    name: "Islam",
    value: "ISLAM",
  },
  {
    name: "Shīʿah",
    value: "SHIA",
  },
  {
    name: "Secular/Nonreligious/Agnostic/Atheist",
    value: "NO_RELIGION",
  },
  {
    name: "Hinduism",
    value: "HINDUISM",
  },
  {
    name: "Buddhism",
    value: "BUDDHISM",
  },
  {
    name: "Sikhism",
    value: "SIKHISM",
  },
  {
    name: "Chinese Traditional",
    value: "CHINESE_TRADITIONAL",
  },
  {
    name: "Ethnic",
    value: "ETHNIC",
  },
  {
    name: "African Traditional",
    value: "AFRICAN_TRADITIONAL",
  },
];
