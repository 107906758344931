import { findIndex as _findIndex, remove as _remove } from "lodash";

import type { ReduxAction, ResourceState } from "../flowTypes/state";

const initialState = {
  isFetching: false,
  // isDuplicateLoading: false,
  // isDuplicateCompleted: null,
  isLoadingMore: false,
  isOrdering: false,
  error: "",
  data: [],
  details: {},
  loadingDetails: {},
  isSaving: false,
  currentPage: 1,
  loadMoreEnabled: true,
  saveResult: null,
  currentSorting: [],
  currentFilter: [],
  params: {},
  createdRecord: {},
  statusCode: null,
};

export default (resourceType: string, types: { [string]: string }) => {
  const pluralName = `${resourceType}s`;
  const nameInAction = resourceType.toUpperCase();
  const nameInActionPlural = pluralName.toUpperCase();
  return (state: ResourceState = initialState, action: ReduxAction) => {
    switch (action.type) {
      case "LOGOUT_COMPLETED":
        return initialState;
      case types[`CLEAR_${nameInActionPlural}`]:
        return {
          ...initialState,
        };
      case types[`LOAD_${nameInActionPlural}_BEGINS`]:
        return {
          ...state,
          isFetching: true,
          error: "",
        };
      case types[`LOAD_${nameInActionPlural}_COMPLETED`]: {
        const { filter, sorting, ...payload } = action.payload;
        return {
          ...state,
          ...payload,
          pages: action.payload.last_page,
          isFetching: false,
          error: "",
          currentFilter: [...(filter || [])],
          currentSorting: [...(sorting || [])],
        };
      }
      case types[`LOAD_${nameInActionPlural}_FAILED`]:
        return {
          ...state,
          isFetching: false,
          error: action.payload.error,
        };

      case types[`LOAD_MORE_${nameInActionPlural}_BEGINS`]:
        return {
          ...state,
          isFetching: true,
          isLoadingMore: true,
          error: "",
        };
      case types[`LOAD_MORE_${nameInActionPlural}_COMPLETED`]: {
        const { filter, data, sorting, ...payload } = action.payload;

        const currentPage = state.current_page ? state.current_page : 1;
        const lastPage = state.last_page ? state.last_page : 1;

        return {
          ...state,
          ...payload,
          data: [...state.data, ...data],
          isFetching: false,
          isLoadingMore: false,
          current_page: state.current_page + 1,
          error: "",
          loadMoreEnabled: !(
            (data && data.length === 0) ||
            currentPage >= lastPage
          ),
          currentFilter: [...(filter || [])],
          currentSorting: [...(sorting || [])],
        };
      }
      case types[`LOAD_MORE_${nameInActionPlural}_RESET`]:
        return {
          ...state,
          data: [],
          pages: undefined,
          current_page: 0,
          isLoadingMore: false,
          loadMoreEnabled: true,
        };
      case types[`LOAD_MORE_${nameInActionPlural}_FAILED`]:
        return {
          ...state,
          isFetching: false,
          isLoadingMore: false,
          error: action.payload.error,
        };

      case types[`UPDATE_${nameInAction}_BEGINS`]:
        return {
          ...state,
          isFetching: true,
          isSaving: true,
          saveResult: null,
          error: "",
        };
      case types[`LOAD_${nameInAction}_BEGINS`]: {
        const { id } = action.payload;
        return {
          ...state,
          details: {},
          loadingDetails: {
            ...state.loadingDetails,
            [id]: true,
          },
          statusCode: null,
        };
      }
      // used to avoid overriding the permission already exist.
      case types[`LOAD_${nameInAction}_SUBSCRIPTION_COMPLETED`]: {
        const { record } = action.payload;
        const id = (record || {}).id || null;

        if (!id) {
          return {
            ...state,
          };
        }
        return {
          ...state,
          details: {
            ...state.details,
            [id]: {
              ...record,
              permission:
                (record || {}).permission ||
                (state.details[id] || {}).permission,
            },
          },
          loadingDetails: {
            ...state.loadingDetails,
            [id]: false,
          },
          isFetching: false,
          error: "",
        };
      }

      case types[`LOAD_${nameInAction}_COMPLETED`]: {
        const { record } = action.payload;
        const id = (record || {}).id || null;

        if (!id) {
          return {
            ...state,
          };
        }

        return {
          ...state,
          details: {
            ...state.details,
            [id]: record,
          },
          loadingDetails: {
            ...state.loadingDetails,
            [id]: false,
          },
          isFetching: false,
          error: "",
        };
      }
      case types[`INIT_${nameInAction}`]: {
        return {
          ...state,
          createdRecord: {},
          params: {},
        };
      }
      case types[`LOAD_${nameInAction}_FAILED`]: {
        const { error, id, statusCode } = action.payload;
        return {
          ...state,
          loadingDetails: {
            ...state.loadingDetails,
            [id]: false,
          },
          statusCode,
          error,
        };
      }
      case types[`UPDATE_${nameInAction}_COMPLETED`]: {
        const { record } = action.payload;
        const { params } = action;
        const newState = { ...state };
        if (record && record.id) {
          const dataRecordIndex = _findIndex(newState.data, { id: record.id });
          newState.data = [...(newState.data || [])];
          if (dataRecordIndex !== -1) {
            newState.data[dataRecordIndex] = {
              ...newState.data[dataRecordIndex],
              ...record,
            };
          }
        }
        return {
          ...newState,
          isFetching: false,
          isSaving: false,
          saveResult: true,
          error: "",
          createdRecord: { ...record }, // used for save and continue feature
          params,
        };
      }
      case types[`UPDATE_${nameInAction}_FAILED`]: {
        const { record, recordId } = action.payload;
        const { params } = action;
        const newState = { ...state };
        if (recordId) {
          if (((newState || {}).details || {})[recordId]) {
            newState.details[recordId] = {
              ...newState.details[recordId],
              ...record,
            };
          }
        }
        return {
          ...newState,
          isFetching: false,
          isSaving: false,
          saveResult: false,
          error: action.payload.error,
          createdRecord: { ...record }, // used for save and continue feature
          params,
        };
      }
      case types[`UPDATE_${nameInAction}_RESET`]:
        return {
          ...state,
          isFetching: false,
          isSaving: false,
          saveResult: null,
          error: "",
        };
      case types[`CREATE_${nameInAction}_COMPLETED`]: {
        const { record } = action.payload;
        const { params } = action;
        const newState = { ...state, data: [...state.data] };
        const skipPushingToState = params && params.skipPushingToState;
        if (record && !skipPushingToState) {
          newState.data.push(record);
        }
        return {
          ...newState,
          isFetching: false,
          isSaving: false,
          saveResult: true,
          error: "",
          id: undefined,
          createdRecord: { ...record },
          params,
        };
      }
      case types[`DELETE_${nameInAction}_COMPLETED`]: {
        const { id } = action.payload;
        const newState = { ...state, data: [...state.data] };
        if (id) {
          _remove(newState.data, dataRecord => dataRecord.id === id);
        }
        return {
          ...newState,
          isFetching: false,
          isSaving: false,
          saveResult: true,
          error: "",
        };
      }
      case types[`REORDER_${nameInAction}_BEGINS`]: {
        return {
          ...state,
          isOrdering: true,
        };
      }
      case types[`REORDER_${nameInAction}_COMPLETED`]: {
        // TODO complete this.
        const newState = { ...state };
        return {
          ...newState,
          isOrdering: false,
          error: "",
        };
      }
      case types[`SUBSCRIBE_${nameInActionPlural}_NEW_RECORDS_COMPLETED`]: {
        const record = { ...action.payload };
        const id = (record || {}).id || null;
        if (!id) {
          return {
            ...state,
          };
        }
        let isExist = false;
        if ((state.data || []).some(item => item.id === id)) {
          isExist = true;
        }
        return {
          ...state,
          data: isExist ? [...state.data] : [record, ...state.data],
          total: (state.total || 0) + 1,
          isFetching: false,
          error: "",
          createdRecord: record,
        };
      }
      default:
        return state;
    }
  };
};
