import React from "react";
import { Spinner } from "reactstrap";
import styled from "styled-components";

const SpinContainer = styled.div({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: "100%",
  height: "100%",
});

export default (props: any) => (
  <SpinContainer>
    <Spinner {...props} />
  </SpinContainer>
);
