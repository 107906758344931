import { type ConditionsBlock, type Condition } from "../flowTypes";
import processResource from "./processResource";
import OperatorLabels from "../operators/labels";

/**
 * Function converts a condition to human readble string.
 * @param {Condition} condition A condition block
 */
export const conditionToString = (condition: Condition): string => {
  const left = condition.operandLeft
    ? processResource(condition.operandLeft).inputValue
    : "";
  const right = condition.operandRight
    ? processResource(condition.operandRight).inputValue
    : "";
  const op = condition.operator ? OperatorLabels[condition.operator] : "";
  return `${left} ${op} ${right}`;
};

export const conditionsToString = (block: ConditionsBlock): string => {
  if (!block.conditions) {
    return "";
  }
  const conditions = [];
  block.conditions.forEach((condition, index) => {
    conditions[index + 1] = conditionToString(condition);
  });

  conditions.splice(0, 1); // Remove the first empty

  const evalPattern = block.evalPattern || "ALL_AND";
  let str;
  switch (evalPattern) {
    case "ALL_OR":
      str = conditions.join(" OR ");
      break;
    case "ALL_AND":
      str = conditions.join(" AND ");
      break;
    default:
      str = evalPattern;
      conditions.forEach((cond, index) => {
        str = str.replace(`${index}`, cond);
      });
      break;
  }
  return str;
};

export default {
  conditionsToString,
};
