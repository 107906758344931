// import Global from "./globals";
import Typography from "./typography";
import { MainContainer, AppWrapper, MainClasses } from "./main";
import {
  PencilPanelWrapper,
  PencilBoxesWrapper,
  PencilBoxWrapper,
  PencilBoxTitle,
  PencilBoxCard,
  PencilWrapper,
  PencilIsDragging,
  PencilIsEven,
  PencilIsOdd,
  PencilIsInLastRow,
  PencilClasses,
} from "./panel";
import {
  ArtBoardWrapper,
  ArtsContainer,
  ArtsContainerLoading,
  ArtWrapper,
  ArtContainer,
  ArtDragHandle,
  ArtDroppableConditional,
  ArtDroppableOverWith,
  RowsWrapper,
  RowContainer,
  RowWrapper,
  DragFrameTitle,
  DragFrameTitleHover,
  ColDragFrameTitleHover,
  RowCloseHandler,
  RowDragHandler,
  RowDragHandlerHover,
  ColsWrapper,
  ColWrapper,
  ColCloseHandler,
  ColDragHandler,
  TabsWrapper,
  TabsNavWrapper,
  TabContentWrapper,
  TabWrapper,
  InkDropWrapper,
  InkDropCol,
  ContainerEmpty,
  ContainerWrapper,
  ContainerTitle,
} from "./artboard";

export default {
  // global
  // Global,

  // typography
  Typography,

  // from main
  MainContainer,
  AppWrapper,
  MainClasses,

  // from pencil panel
  PencilPanelWrapper,
  PencilBoxesWrapper,
  PencilBoxWrapper,
  PencilBoxTitle,
  PencilBoxCard,
  PencilWrapper,
  PencilIsDragging,
  PencilIsEven,
  PencilIsOdd,
  PencilIsInLastRow,
  PencilClasses,

  // from artboard
  ArtBoardWrapper,
  ArtsContainer,
  ArtsContainerLoading,
  ArtWrapper,
  ArtContainer,
  ArtDragHandle,
  ArtDroppableConditional,
  ArtDroppableOverWith,
  RowsWrapper,
  RowWrapper,
  RowContainer,
  RowDragHandler,
  DragFrameTitle,
  DragFrameTitleHover,
  ColDragFrameTitleHover,
  RowCloseHandler,
  RowDragHandlerHover,
  ColsWrapper,
  ColWrapper,
  ColCloseHandler,
  ColDragHandler,
  TabsWrapper,
  TabsNavWrapper,
  TabContentWrapper,
  TabWrapper,
  InkDropWrapper,
  InkDropCol,
  ContainerEmpty,
  ContainerWrapper,
  ContainerTitle,
};
