/* eslint-disable */
import * as React from "react";
import { compose } from "redux";
import { withRouter } from "react-router-dom";
import { Container, Row, Col, Button, Alert, Progress } from "reactstrap";
import styled from "styled-components";
import { COLOURS, StickyTopNavbar, NotFound } from "@swan/themer";
import { withRecordPermission } from "@swan/auth";
import { debounce } from "lodash";

import _ from "lodash";
import ButtonConfirm from "../../UI/Components/ButtonConfirm";
import ButtonMultichoice from "../../UI/Components/ButtonMultichoice";

type Buttons = Array<{
  id?: string,
  label: string,
  onClick?: Function,
  color?: string,
  disabled?: boolean,
  type?: string,
  confirmOptions?: Object,
}>;

type Props = {
  history: Object,
  match: Object,
  record?: Object,
  createdRecord?: Object,
  defaultValues?: Object,
  id?: number | string,
  loadRecord: (number | string) => void,
  validate: Object => boolean,
  resetUpdate: () => void,
  updateRecord: (number | string, record: Object, params: Object) => void,
  createRecord: (Object, params: Object) => void,
  deleteRecord: (number | string) => void,
  isFetching?: boolean,
  isSaving?: boolean,
  saveResult?: boolean,
  saveAndContinueEnabled?: boolean,
  params?: Object,
  children: Node,
  goBack: () => void,
  error: { [string]: string } | string,
  statusCode?: number,
  disableDelete?: boolean,
  saveLabel?: string,
  noSave?: boolean,
  saveAlwaysVisible?: boolean,
  onSave?: (originalSaveFunction: Function) => void,
  onAfterSave?: () => void,
  buttons?: Buttons,
  setButtons?: Buttons => void,
  addButtons?: Buttons => void,
  removeButtons?: (Array<string>) => void,
  beforeCreate?: Function => Object,
  registerSaveFunction?: Function => void,
  registerDeleteFunction?: Function => void,
  renderContext?: string,
  disableRecordPermission?: boolean,
  isEditAllowed: boolean, // comes from the withRecordPermission HOC
  readOnly?: boolean,
  disableButtonsBar?: boolean,
  isDeleteAllowed: boolean, // comes from the withRecordPermission HOC
  reloadRecord: boolean,
  disableBackButton: boolean,
  overrideDeletePermission: boolean,
  overrideEditPermission: boolean,
  skipAfterSave?: boolean,
};

type State = {
  record: Object,
  error: Object,
  dirty: boolean,
  wasValidated: boolean,
  saving: boolean,
  isLocked: boolean,
  dirtyAttributes: Array<string>,
  registeredButtons?: Buttons,
};

const ButtonsBar = styled(({ ...props }, ref) => (
  <StickyTopNavbar ref={() => ref} {...props} />
))`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding: 10px;
  margin-bottom: 10px;
`;
type validateFunction = (Object, Array<string>, boolean) => {};

const FormHOC = (
  hocOptions:
    | validateFunction
    | { validate?: validateFunction, useRouteParams?: boolean }
) => (FormComponent: any) => {
  let validate: validateFunction;
  let options;
  if (typeof hocOptions === "function") {
    validate = hocOptions;
    options = {};
  } else {
    validate = hocOptions.validate || ((): {} => ({}));
    options = hocOptions;
  }

  class Form extends React.Component<Props, State> {
    static defaultProps = {
      record: {},
      createdRecord: {},
      defaultValues: {},
      id: undefined,
      isFetching: false,
      isSaving: false,
      saveResult: undefined,
      disableDelete: options.disableDelete ? options.disableDelete : false,
      saveLabel: "Save",
      noSave: false,
      saveAlwaysVisible: false,
      onSave: undefined,
      buttons: [],
      setButtons: undefined,
      addButtons: undefined,
      removeButtons: undefined,
      renderContext: "",
      disableRecordPermission: false,
      readOnly: false,
      disableButtonsBar: false,
      reloadRecord: true,
      saveAndContinueEnabled: true,
      disableBackButton: false,
      overrideDeletePermission: false,
      overrideEditPermission: false,
      skipAfterSave: false,
    };

    beforeSaveCallbacks: Array<(values: Object) => boolean | string> = [];

    constructor(props: Props) {
      super(props);

      this.state = {
        dirty: this.isNew(),
        error: {},
        record: props.record,
        wasValidated: false,
        saving: false,
        isLocked: false,
        dirtyAttributes: [],
        registeredButtons: [],
      };
    }

    componentDidMount() {
      this.mounted = true;
      const {
        resetUpdate,
        record,
        loadRecord,
        defaultValues,
        reloadRecord,
      } = this.props;
      resetUpdate();
      const id = this.getId();
      if (!record || reloadRecord) {
        if (id !== "new" && id !== null && typeof id !== "undefined") {
          loadRecord(id);
        } else {
          this.setState({
            record: { ...(defaultValues || {}) },
          });
        }
      }
      this.refreshButtons();
      this.registerFunctions();
    }

    componentWillUnmount() {
      this.mounted = false;
    }

    afterSave() {
      if (this.mounted) {
        this.setState(
          {
            saving: false,
          },
          () => {
            if (this.props.onAfterSave) {
              this.props.onAfterSave();
            } else {
              this.goBack();
            }
          }
        );
      } else {
        if (this.props.onAfterSave) {
          this.props.onAfterSave();
        } else {
          this.goBack();
        }
      }
    }

    saveAndContinueHandler(nextProps: Props) {
      const { params, createdRecord, loadRecord } = nextProps;
      if (params && createdRecord && params.isSaveAndContinue) {
        const { history, match } = this.props;
        let pathToContinue = match.url.split("/");
        this.setState({
          saving: false,
        });
        // check if same path stay and dont redirect the user to the same page
        if (
          !isNaN(pathToContinue[pathToContinue.length - 1]) &&
          pathToContinue &&
          pathToContinue[pathToContinue.length - 1] == createdRecord.id
        ) {
          loadRecord(createdRecord.id);
          return true;
        }
        pathToContinue.splice(pathToContinue.length - 1, 1);
        pathToContinue.push(createdRecord.id);
        pathToContinue = pathToContinue.join("/");
        history.replace(pathToContinue);
        return true;
      }
      return false;
    }

    componentWillReceiveProps(nextProps: Props) {
      const { saving } = this.state;
      const { skipAfterSave } = this.props;

      if (this.saveAndContinueHandler(nextProps)) {
        return;
      }

      if (nextProps.record) {
        this.setState({ record: nextProps.record });
      }

      if (saving) {
        if (nextProps.saveResult === true) {
          if (!skipAfterSave) {
            this.afterSave();
          }
        } else if (nextProps.saveResult === false) {
          this.setState({
            saving: false,
          });
        }
      }
    }

    componentDidUpdate(prevProps, prevState) {
      const { dirty, registeredButtons, isLocked } = this.state;
      const { isFetching, isSaving, error } = this.props;
      const {
        isFetching: prevIsFetching,
        isSaving: prevIsSaving,
        error: prevError,
      } = prevProps;

      const isLoading = isFetching || isSaving;
      const prevIsLoading = prevIsFetching || prevIsSaving;
      const {
        dirty: prevDirty,
        registeredButtons: prevRegisteredButtons,
        isLocked: prevIsLocked,
      } = prevState;

      if (error && error !== prevError) {
        this.onFailedSaved();
      }

      const { isDeleteAllowed } = this.props;
      const { isDeleteAllowed: prevIsDeleteAllowed } = prevProps;
      if (
        prevDirty !== dirty ||
        registeredButtons !== prevRegisteredButtons ||
        isDeleteAllowed !== prevIsDeleteAllowed ||
        isLocked !== prevIsLocked ||
        isLoading !== prevIsLoading
      ) {
        this.refreshButtons();
      }
    }

    getId() {
      const {
        match: { params },
        record,
        id,
      } = this.props;
      if (record && record.id) {
        return record.id;
      }
      if (id) {
        return id;
      }
      if (options.useRouteParams === false) {
        return undefined;
      }
      return params.id;
    }

    setValue = (attr: string, value: any, callback?: Function) => {
      const { wasValidated, record } = this.state;
      if (record[attr] === value) {
        return;
      }
      this.setState(
        state => {
          const { record } = state;
          return {
            record: {
              ...record,
              [attr]: value,
            },
            dirty: true,
          };
        },
        () => {
          this.markAttributeDirty(attr);
          if (wasValidated) {
            this.validate();
          }
          if (callback) {
            callback();
          }
        }
      );
    };

    setValues = (values: { [attr: string]: any }, callback?: Function) => {
      const { wasValidated } = this.state;
      this.setState(
        state => {
          const { record } = state;
          return {
            record: {
              ...record,
              ...values,
            },
            dirty: true,
          };
        },
        () => {
          this.markAttributesDirty(Object.keys(values));
          if (wasValidated) {
            this.validate();
          }
          if (callback) {
            callback();
          }
        }
      );
    };

    /**
     * Set values without marking as dirty, avoids submitting on update
     */
    setCleanly = (values: { [attr: string]: any }, callback?: Function) => {
      const { wasValidated } = this.state;
      this.setState(
        state => {
          const { record } = state;
          return {
            record: {
              ...record,
              ...values,
            },
          };
        },
        () => {
          if (wasValidated) {
            this.validate();
          }
          if (callback) {
            callback();
          }
        }
      );
    };

    markAttributeDirty = (attr: string) => {
      this.setState(state => {
        const { dirtyAttributes } = state;
        return {
          dirtyAttributes: [...dirtyAttributes, attr],
        };
      });
    };

    markAttributesDirty = (attrs: Array<string>) => {
      this.setState(state => {
        const { dirtyAttributes } = state;
        return {
          dirtyAttributes: [...dirtyAttributes, ...attrs],
        };
      });
    };

    beforeSave(values): boolean {
      let i = 0;
      for (i = 0; i < this.beforeSaveCallbacks.length; i += 1) {
        const result = this.beforeSaveCallbacks[i](values);
        // Callback returned error message
        if (result === false) {
          return false;
        } else if (result === true) {
          continue;
        } else {
          if (result) {
            this.onFailedSaved();
          }
          this.setState({
            error: result || "",
          });
          return false;
        }
      }
      return this.validate();
    }

    onFailedSaved() {
      window.scroll(0, 0);
    }

    save = debounce((saveControls: Object = {}) => {
      const { readOnly } = this.props;
      const { isLocked, record } = this.state;
      if (this.isReadOnly() || readOnly || isLocked) {
        return;
      }
      const beforeSaveResult = this.beforeSave({ ...record });

      if (!beforeSaveResult) {
        return;
      }

      this.setState(
        {
          saving: true,
        },
        () => {
          const { record, dirtyAttributes } = this.state;
          const { skipAfterSave } = this.props;
          let result;
          if (this.isNew()) {
            const { createRecord, beforeCreate } = this.props;
            let values = { ...record };
            if (beforeCreate) values = beforeCreate({ ...record });
            if (values.id) delete values.id;
            result = createRecord(values, saveControls);
          } else {
            const { updateRecord } = this.props;
            const values = _.pick(record, dirtyAttributes);
            let id = this.getId();
            if (!id) {
              return false;
            }
            result = updateRecord(id, values, saveControls);
          }
          if (typeof result === "object" && result.then) {
            return result
              .then(() => {
                if (!skipAfterSave) {
                  this.afterSave();
                }
                return true;
              })
              .catch(() => {});
          }
          this.refreshButtons();
          return true;
        }
      );
    }, 300);

    isNew() {
      const id = this.getId();
      return id === "new" || typeof id === "undefined" || id === -1;
    }

    goBack = () => {
      const { history, goBack } = this.props;
      if (goBack) {
        goBack();
      } else {
        history.goBack();
      }
    };

    deleteRecord = debounce(() => {
      const { isLocked } = this.state;
      if (isLocked) {
        return null;
      }
      if (!this.isNew()) {
        this.setState(
          {
            saving: true,
          },
          () => {
            const { deleteRecord } = this.props;
            let id = this.getId();
            if (id) {
              deleteRecord(id);
            }
          }
        );
      }
    }, 300);

    validate() {
      const { record, dirtyAttributes } = this.state;
      const error = validate(record, dirtyAttributes, this.isNew());
      this.setState({
        error,
        wasValidated: true,
      });
      return Object.keys(error).length === 0;
    }

    canDelete = () => {
      const {
        disableDelete,
        isDeleteAllowed,
        overrideDeletePermission,
        readOnly,
      } = this.props;
      return (
        !disableDelete &&
        !this.isNew() &&
        (isDeleteAllowed || overrideDeletePermission) &&
        !readOnly
      );
    };

    canSaveAndContinue = () => {
      const {
        noSave,
        isEditAllowed,
        overrideEditPermission,
        readOnly,
        saveAndContinueEnabled,
        saveAlwaysVisible,
      } = this.props;
      const { dirty } = this.state;

      return (
        (dirty &&
          !noSave &&
          (isEditAllowed || overrideEditPermission) &&
          !readOnly &&
          saveAndContinueEnabled) ||
        saveAlwaysVisible
      );
    };

    canSave = () => {
      const {
        noSave,
        isEditAllowed,
        overrideEditPermission,
        readOnly,
        saveAndContinueEnabled,
        saveAlwaysVisible,
      } = this.props;
      const { dirty } = this.state;
      return (
        (dirty &&
          !noSave &&
          (isEditAllowed || overrideEditPermission) &&
          !readOnly &&
          !saveAndContinueEnabled) ||
        saveAlwaysVisible
      );
    };

    shouldDisplayBar() {
      const {
        noSave,
        buttons,
        disableDelete,
        saveAlwaysVisible,
        setButtons,
        disableButtonsBar,
        readOnly,
      } = this.props;

      const { dirty, registeredButtons } = this.state;
      if (disableButtonsBar) return false;
      if (setButtons) {
        return false;
      }
      if (saveAlwaysVisible) {
        return true;
      }
      if (dirty && !noSave && !readOnly) {
        return true;
      }
      if (!disableDelete && !this.isNew()) {
        return true;
      }
      if ((buttons || []).length) {
        return true;
      }
      if ((registeredButtons || []).length) {
        return true;
      }
      return false;
    }

    registerButtons = (buttons: Buttons) => {
      this.setState(state => ({
        registeredButtons: [...(state.registeredButtons || []), ...buttons],
      }));
    };

    getButtons = () => {
      const {
        isFetching,
        isSaving,
        disableDelete,
        saveLabel,
        noSave,
        saveAlwaysVisible,
        onSave,
        buttons,
        readOnly,
        saveAndContinueEnabled,
        disableBackButton,
        isEditAllowed,
        isDeleteAllowed,
        overrideDeletePermission,
        overrideEditPermission,
        ...restProps
      } = this.props;
      const { registeredButtons, isLocked, dirty } = this.state;
      const isLoading = isFetching || isSaving;
      const btns: Array<Object> = [
        ...(buttons || []),
        ...(registeredButtons || []),
      ];
      if (!disableBackButton) {
        btns.push({
          label: "Back",
          color: "info",
          onClick: this.goBack,
          disabled: false,
          actionType: "Back",
        });
      }

      if (this.canDelete()) {
        btns.push({
          label: "Delete",
          color: "danger",
          type: "ButtonConfirm",
          actionType: "Delete",
          onClick: this.deleteRecord,
          disabled: isLoading || isSaving || isLocked,
          loading: isLoading,
        });
      }

      if (this.canSaveAndContinue()) {
        btns.push({
          label: saveLabel || "Save",
          color: "primary",
          type: "ButtonMultichoice",
          // onClick: onSave ? () => onSave(this.save) : this.save,
          actionType: "Save",
          disabled: isLoading || isSaving || isLocked,
          loading: isLoading,
          choices: [
            {
              label: "Save & Continue",
              onClick: onSave
                ? () =>
                    onSave(() => {
                      this.save({ isSaveAndContinue: true });
                    })
                : () => this.save({ isSaveAndContinue: true }),
            },
            {
              label: "Save & Close",
              onClick: onSave
                ? () => onSave(() => this.save({ isSaveAndContinue: false }))
                : () => this.save({ isSaveAndContinue: false }),
            },
          ],
        });
      }

      if (this.canSave()) {
        btns.push({
          label: saveLabel || "Save",
          color: "primary",
          type: "normal",
          // onClick: onSave ? () => onSave(this.save) : this.save,
          actionType: "Save",
          disabled: isLoading || isSaving || isLocked,
          loading: isLoading,
          onClick: onSave
            ? () => onSave(() => this.save({ isSaveAndContinue: false }))
            : () => this.save({ isSaveAndContinue: false }),
        });
      }
      return btns;
    };

    refreshButtons() {
      const { setButtons } = this.props;
      if (setButtons) {
        setButtons(this.getButtons());
      }
    }

    isReadOnly() {
      const { record } = this.state;
      const { isEditAllowed, overrideEditPermission, readOnly } = this.props;
      if (readOnly) return true;
      if (!isEditAllowed && !overrideEditPermission) return true;
      if (record && record.is_locked) return true;
      return false;
    }

    registerFunctions() {
      const { registerSaveFunction, registerDeleteFunction } = this.props;
      if (registerSaveFunction) {
        registerSaveFunction(this.save);
      }
      if (registerDeleteFunction) {
        registerDeleteFunction(this.deleteRecord);
      }
    }

    registerBeforeSaveCallback = (callback: Function) => {
      this.beforeSaveCallbacks.push(callback);
    };

    toggleLock = (lockState: boolean = false) => {
      const isLocked = lockState === true || false;
      this.setState({
        isLocked,
      });
    };

    mounted: boolean;

    render() {
      const { record, error } = this.state;
      const {
        error: saveError,
        statusCode,
        isFetching,
        isSaving,
        renderContext,
        ...restProps
      } = this.props;

      if (statusCode === 404 && !this.isNew()) {
        return <NotFound customMessage="404 Not Found" />;
      }

      const FormOnlyComponent = (
        <FormComponent
          id={this.getId()}
          {...restProps} // to pass any other props
          setValue={this.setValue}
          setValues={this.setValues}
          setCleanly={this.setCleanly}
          onChange={this.setValue}
          onBatchChange={this.setValues}
          save={this.save}
          record={record}
          data={record}
          isFetching={isFetching}
          isSaving={isSaving}
          setLock={this.toggleLock}
          error={error}
          isNew={this.isNew()}
          registerButtons={this.registerButtons}
          registerBeforeSaveCallback={this.registerBeforeSaveCallback}
          readOnly={this.isReadOnly()}
        />
      );
      if (renderContext === "tableRow") {
        if (!record) return <Progress />;
        return <React.Fragment>{FormOnlyComponent}</React.Fragment>;
      }

      if (!record) {
        return (
          <Container fluid>
            <Progress />
          </Container>
        );
      }
      return (
        <div className="animated">
          {this.shouldDisplayBar() && (
            <ButtonsBar>
              {this.getButtons().map(button => {
                if (button.type === "ButtonConfirm") {
                  return (
                    <ButtonConfirm
                      key={`btn${button.label}`}
                      color={button.color || "info"}
                      onClick={button.onClick}
                      disabled={button.disabled}
                      loading={button.loading}
                      {...button.confirmOptions || {}}
                    >
                      {button.label}
                    </ButtonConfirm>
                  );
                } else if (button.type === "ButtonMultichoice") {
                  return (
                    <ButtonMultichoice
                      key={`btn${button.label}`}
                      color={button.color || "info"}
                      onClick={button.onClick}
                      disabled={button.disabled}
                      loading={button.loading}
                      // {...button.confirmOptions || {}}
                      choices={button.choices || []}
                    >
                      {button.label}
                    </ButtonMultichoice>
                  );
                } else {
                  return (
                    <Button
                      key={`btn${button.label}`}
                      color={button.color || "info"}
                      onClick={
                        button.onClick && !button.disabled
                          ? button.onClick.bind(this)
                          : null
                      }
                      disabled={button.disabled}
                      loading={button.loading}
                    >
                      {button.label}
                    </Button>
                  );
                }
              })}
            </ButtonsBar>
          )}
          {Object.keys(error).length > 0 ? (
            <Alert color="danger">
              {Object.values(error).map(e => (
                <p key={String(e)} style={{ marginBottom: 0 }}>
                  {String(e)}
                </p>
              ))}
            </Alert>
          ) : null}
          {typeof saveError === "string" && saveError !== "" ? (
            <Alert color="danger">
              <span dangerouslySetInnerHTML={{ __html: saveError }} />
            </Alert>
          ) : null}
          {typeof saveError === "object" ? (
            <Alert color="danger">
              {Object.values(saveError).map(e => (
                <p key={String(e)} style={{ marginBottom: 0 }}>
                  {String(e)}
                </p>
              ))}
            </Alert>
          ) : null}
          {FormOnlyComponent}
          <Row>
            <Col />
          </Row>
        </div>
      );
    }
  }

  return compose(
    withRouter,
    withRecordPermission
  )(Form);
};
export default FormHOC;
/* eslint-enable */
