export default {
  uid: "statusBreadcrumb",
  attributeId: "",
  formId: "",
  pencilDef: {
    title: "Status Breadcrumb",
    group: "FIELD",
    handleIcon: "FaRegClock",
    styles: {},
  },
  options: {
    title: "Statuses",
    type: "statusBreadcrumb",
    description: "",
    help: "",
    classNames: "",
    readonly: false,
    // enableHistoryTracking: false,
  },
};
