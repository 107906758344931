import * as React from "react";
import "./loading.css";
import { MdRefresh } from "react-icons/all";

export default () => (
  <div className="loading-shading">
    <div className="loading-icon">
      <MdRefresh
        size="3em"
        style={{ color: "rgb(220, 220, 220)", opacity: "0.4" }}
      />
    </div>
  </div>
);
