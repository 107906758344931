import React, { Component } from "react";
import Jodit from "jodit";
import JoditReact from "./editor";

type Props = {
  value: mixed,
  placeholder?: string,
  onChange?: (value?: Object) => void,
  extendConfig?: Object,
  getRefs?: Function,
  isJson: boolean,
};

class Editor extends Component<Props> {
  static defaultProps = {
    // value: "",
    placeholder: "Write here...",
    onChange: undefined,
    getRefs: undefined,
    extendConfig: [],
  };

  config = {
    readonly: false,
    showPlaceholder: true,
    saveModeInStorage: true,
    buttons: [...Jodit.defaultOptions.buttons],
  };

  constructor(props: Props) {
    super(props);
    this.Jodit = Jodit;
  }

  componentDidMount() {
    const { getRefs } = this.props;
    if (getRefs && typeof getRefs === "function") {
      getRefs({
        Jodit,
        editor: this.editor,
        textareaRef: this.textareaRef,
      });
    }
  }

  onChange = (value: any) => {
    const { onChange } = this.props;
    if (onChange) {
      onChange(value);
    }
  };

  setEditorRef = (jodit: any, ref: any) => {
    this.editor = jodit;
    this.textareaRef = ref;
  };

  editor: Object;

  textareaRef: Object;

  Jodit: Object;

  render() {
    const { placeholder, value, extendConfig, isJson } = this.props;
    return (
      <JoditReact
        getEditorRef={this.setEditorRef}
        value={value}
        config={{ ...this.config, ...extendConfig, placeholder }}
        onChange={this.onChange}
        isJson={isJson}
      />
    );
  }
}

export default Editor;
