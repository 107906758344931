import React from "react";
import { Button } from "@swan/themer"; // eslint-disable-line
import { VersionsResourceFactory } from "@swan/api"; // eslint-disable-line
import { withRouter } from "react-router-dom"; // eslint-disable-line
import { RendererSubscriber } from "../Main";

type Props = {
  schema: Object,
  history: Object,
};

type State = {};

class ButtonCreateQuote extends React.Component<Props, State> {
  getService() {
    const { schema } = this.props;
    if (this.service) {
      return this.service;
    }

    const { options: { objectName } = {} } = schema;
    const VersionService = VersionsResourceFactory(objectName);
    this.service = new VersionService();
    return this.service;
  }

  createVersion() {
    const { history } = this.props;
    this.getService()
      .post()
      .then(() => {
        history.goBack();
      });
  }

  service: Object;

  render() {
    const service = this.getService();

    return (
      <RendererSubscriber>
        {({ data }) => {
          service.setParent(data.version_root_id || data.id);
          if (!data.id || data.id === "new" || data.is_active_version === 0) {
            return null;
          }
          return (
            <Button
              onClick={() => {
                this.createVersion();
              }}
              color="primary"
            >
              Create Version
            </Button>
          );
        }}
      </RendererSubscriber>
    );
  }
}

export default withRouter(ButtonCreateQuote);
