import React, { Component, Fragment } from "react";
import { withRouter } from "react-router-dom";

import { Modal } from "@swan/themer";
import List from "../HOC/CRUD/List";
import ListDataProvider from "../HOC/CRUD/ListDataProvider";

import RecordPermissionFormFactory from "./RecordPermissionFormFactory";
import RecordPermissionService from "./services/RecordPermissionFactory";

type Props = {
  recordId: number,
  object: string,
  disableRoleSelector?: boolean,
};

type State = { enableEditForm: boolean, record: any, service: any };

function getColumns() {
  const columns = [
    {
      name: "to_role",
      title: "To Role",
      getCellValue: row => {
        let roleName = row.to_role ? row.to_role.name : "";
        if (row.to_role && row.to_role.organization) {
          roleName = `${roleName} [${row.to_role.organization.name}]`;
        }
        return roleName;
      },
    },
    {
      name: "to_user",
      title: "To User",
      getCellValue: row => {
        if (row && row.to_user && row.to_user.name) return row.to_user.name;
        return null;
      },
    },
    {
      name: "permission",
      title: "Permission",
      getCellValue: row => {
        let permission = "";
        switch (row.permission) {
          case 1:
            permission = "Read";
            break;
          case 2:
            permission = "Edit";
            break;
          case 4:
            permission = "Delete";
            break;
          case 7:
            permission = "Full";
            break;
          case 8:
            permission = "Deny";
            break;
          default:
            permission = "";
            break;
        }
        return permission;
      },
    },
  ];

  return columns;
}

class RecordPermissionList extends Component<Props, State> {
  static defaultProps = {
    disableRoleSelector: false,
  };

  state = {
    record: null,
    enableEditForm: false,
    service: null,
  };

  componentDidMount() {
    const { object, recordId } = this.props;
    this.setState({ service: new RecordPermissionService(object, recordId) });
  }

  getListComponent = () => {
    const { service } = this.state;
    return ListDataProvider(service)(
      List({
        buttons: [
          {
            label: "Add",
            color: "success",
            // $FlowFixMe
            onClick: () => {
              this.editRow(null);
            },
          },
        ],
        onRowClick: this.editRow,
        disableAdd: true,
      })(getColumns)(() => <span />)
    );
  };

  editRow = (record: any) => {
    this.setState({
      enableEditForm: true,
      record,
    });
  };

  /**
   * used to always disable form as Form needs it
   */
  closeForm = () => {
    this.setState({
      enableEditForm: false,
    });
  };

  render() {
    const { enableEditForm, record } = this.state;
    const { object, recordId, disableRoleSelector } = this.props;
    const ListComponent = this.getListComponent();
    const Form = RecordPermissionFormFactory(object, recordId);
    return (
      <Fragment>
        {enableEditForm && (
          <Modal
            title="Edit Permission"
            isOpen={enableEditForm}
            toggle={this.closeForm}
          >
            <Form
              id={record ? record.id : "new"}
              record={record}
              goBack={this.closeForm}
              disableBackButton
              disableRoleSelector={disableRoleSelector}
              overrideDeletePermission
              overrideEditPermission
              saveAndContinueEnabled={false}
            />
          </Modal>
        )}
        {/* eslint-disable */
        /* $FlowFixMe*/}
        <ListComponent listId={`report-${object}-${recordId}`} />
      </Fragment>
    );
  }
}

export default withRouter(RecordPermissionList);
