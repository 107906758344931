import * as React from "react";
import { withLayout, RemoteSelect } from "@swan/themer";
import { ObjectService, ResourceFactory } from "@swan/api";
import ConverterMappingConfigurationService from "./services/converterMappingConfigurationService";
import { Col, Row, Wrapper, TitleCol, BoldValue } from "./Style";

type Map = {
  source_object: string,
  destination_object: string,
  source_object_label: string,
  source_object_field: string,
  source_object_value: string,
  source_relationship_value: string,
  destination_object_label: string,
  source_relationship: any,
  destination_class: any,
  destination_relationship: any,
  destination_object_field: string,
  destination_object_value: string,
  source_relationship_label: string,
  configuration_group_title: string,
  destination_relationship_value: string,
  configuration_id: string,
};

type Props = {
  defaultMap?: Array<Map>,
  destinationObjectValue: string,
  sourceObjectValue: string,
  destinationObjectName: string,
  sourceObjectName: string,
  onMapUpdate: any => void,
};

type State = {
  map?: Array<Map>,
  services: Array<any>,
  sourceObjectService?: any,
  destinationObjectService?: any,
  sourceObject?: any,
  destinationObject?: any,
};

const converterMappingConfigurationService = new ConverterMappingConfigurationService();

class RelationshipsMapping extends React.Component<Props, State> {
  static defaultProps = {
    convertButtonText: "Convert Object",
    defaultMap: [],
  };

  state = {
    map: [],
    services: [],
  };

  constructor(props: Props) {
    const { defaultMap } = props;
    super(props);
    this.state = {
      map: defaultMap || [],
      services: [],
    };
  }

  componentWillReceiveProps(nextProps: Props): void {
    const {
      sourceObjectValue,
      sourceObjectName,
      destinationObjectName,
      destinationObjectValue,
    } = this.props;
    if (
      sourceObjectValue !== nextProps.sourceObjectValue ||
      sourceObjectName !== nextProps.sourceObjectName ||
      destinationObjectName !== nextProps.destinationObjectName ||
      destinationObjectValue !== nextProps.destinationObjectValue
    ) {
      this.setState({
        map: [],
      });
      converterMappingConfigurationService
        .get("configurations", {
          source_object_value: nextProps.sourceObjectValue,
          source_object: nextProps.sourceObjectName,
          destination_object: nextProps.destinationObjectName,
          destination_object_value: nextProps.destinationObjectValue,
        })
        .then(response => {
          const services = [];
          if (response && response.length > 0) {
            response.map(conf => {
              const SourceObjectService = ResourceFactory(
                conf.source_relationship.child_object
              );
              const DestinationObjectService = ResourceFactory(
                conf.destination_relationship.child_object
              );

              ObjectService.getObjectByName(
                conf.destination_relationship.child_object
              ).then(def => {
                services.push({
                  configurationId: conf.configuration_id,
                  configurationTitle: conf.configuration_title,
                  destinationChildObject: def,
                  sourceService: new SourceObjectService(),
                  destinationService: new DestinationObjectService(),
                });
                this.setState({
                  services,
                });
              });

              return true;
            });
          }
          this.setState({
            services,
            map: [...response],
          });
        })
        .catch(() => {});
    }
  }

  static getRelationshipFilters(mapingItem, destinationObjectValue) {
    if (mapingItem.destination_relationship.foreign_key) {
      return [
        {
          columnName: mapingItem.destination_relationship.foreign_key,
          operator: "=",
          value: destinationObjectValue,
        },
      ];
    }
    if (mapingItem.destination_relationship.polymorph_type) {
      return [
        {
          columnName: `${
            mapingItem.destination_relationship.polymorph_type
          }_id`,
          operator: "=",
          value: destinationObjectValue,
        },
        {
          columnName: `${
            mapingItem.destination_relationship.polymorph_type
          }_type`,
          operator: "=",
          value: mapingItem.destination_class,
        },
      ];
    }

    return [];
  }

  updateMap(value, key, configurationId, type) {
    const { map } = this.state;
    const { onMapUpdate } = this.props;
    const updatedMap = map ? [...map] : [];
    updatedMap[key][type] = value;
    updatedMap[key].configuration_id = configurationId;
    this.setState({ map: updatedMap });
    onMapUpdate(updatedMap);
  }

  render() {
    const { destinationObjectValue } = this.props;
    const { map, services } = this.state;
    return (
      <Wrapper>
        {destinationObjectValue &&
          map &&
          map.map((m, key) => (
            <React.Fragment
              key={`${m.source_relationship_value}-${m.configuration_id}`}
            >
              {key === 0 && (
                <Row>
                  <TitleCol align="center">
                    {m.configuration_group_title}
                  </TitleCol>
                </Row>
              )}
              {key !== 0 &&
                map[key - 1].configuration_group_title !==
                  m.configuration_group_title && (
                  <Row>
                    <TitleCol align="center">
                      {m.configuration_group_title}
                    </TitleCol>
                  </Row>
                )}
              <Row>
                <Col>
                  <BoldValue>
                    {m.source_relationship
                      ? m.source_relationship.child_object
                      : ""}{" "}
                  </BoldValue>
                  of{" "}
                  <BoldValue>
                    {m.source_relationship_label} ({m.source_relationship_value}
                    )
                  </BoldValue>{" "}
                  to
                </Col>
                {services && services[key] && services[key].destinationService && (
                  <Col align="center">
                    <RemoteSelect
                      filters={RelationshipsMapping.getRelationshipFilters(
                        m,
                        destinationObjectValue
                      )}
                      service={services[key].destinationService}
                      labelAttribute={
                        services[key].destinationChildObject.label_attribute
                      }
                      labelExpression={
                        services[key].destinationChildObject.label_expression
                      }
                      name={m.destination_object_label}
                      value={m.destination_relationship_value}
                      onChange={({ value }) => {
                        this.updateMap(
                          value,
                          key,
                          services[key].configurationId,
                          "destination_relationship_value"
                        );
                      }}
                    />
                  </Col>
                )}
              </Row>
            </React.Fragment>
          ))}
      </Wrapper>
    );
  }
}

export default withLayout(RelationshipsMapping);
