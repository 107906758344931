export const getJsonSchemaForChart = (): // id: string
{
  jsonSchema: {},
  jsonUiSchema: {},
} => {
  const jsonSchema = {
    type: "object",
    properties: {
      title: {
        type: "string",
        title: "Title",
      },
      customServicePath: {
        type: "string",
        title:
          'Custom Service Path (Optional) "it will use the default path if not specified"',
      },
    },
  };
  const jsonUiSchema = {};

  return {
    jsonSchema,
    jsonUiSchema,
  };
};

export default {
  getJsonSchemaForChart,
};
