import React, { useEffect, useState } from "react";
import Axios from "axios"; // eslint-disable-line
import { ResourceGenerator } from "@swan/api"; // eslint-disable-line
import { FiCamera } from "react-icons/all";
import { getLetterAvatar } from "@swan/helpers/utils";
import styled from "styled-components";
import { SwanContextManager } from "@swan/state"; // eslint-disable-line

const UserProfileImg = styled.span`
  ${(props: any) => `
    width: ${props.radius}rem;
    height: ${props.radius}rem;
  `}

  @media (max-width: 768px) {
    flex-direction: column;
  }
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  margin-top: 20px;
  margin-bottom: 20px;
  position: relative;
  ${(props: any) => `
    background-color: ${props.backgroundColor};
  `}
  z-index: 1;

  svg {
    margin: auto;
    color: #fff;
  }

  overflow: hidden;
  img {
    width: 100%;
  }

  font-size: 40px;

  ${(props: any) =>
    props.color &&
    `
    color: ${props.color};
  `}

  font-weight: bold;

  .avatar-uploader {
    display: none;
  }

  &:hover {
    .avatar-uploader {
      display: block;
      position: absolute;
      width: 100%;
      height: 100%;
      z-index: 200;
      opacity: 0.7;
      background: #cdced0;
      border-radius: 50%;
      cursor: pointer;

      .uploader-icon {
        display: flex;
        width: 100%;
        height: 100%;
        justify-content: center;
        align-items: center;
        font-size: 1rem;
        color: #395998;
      }
    }
  }
`;

export default (props: {
  user: Object,
  radius: number,
  uploadURL: string,
  canChangAvatar: boolean,
  backgroundColor: string,
  color: string,
}) => {
  const {
    user,
    radius,
    uploadURL,
    canChangAvatar,
    backgroundColor,
    color,
  } = props;
  const [userProfilePhoto, setUserProfilePhoto] = useState(undefined);
  useEffect(() => {
    const service = new (ResourceGenerator(Axios, "microsoft-graph"))();
    const cachedImage = SwanContextManager.getValue("user_profile_image");
    if (!cachedImage) {
      (service || {})
        .get("me/photo")
        .then(response => {
          SwanContextManager.setValue("user_profile_image", response);
          setUserProfilePhoto(response);
        })
        .catch(e => {
          // eslint-disable-next-line
          console.error(e);
        });
    } else {
      setUserProfilePhoto(cachedImage);
    }
  }, []);
  const changePhotoHandler = () => window.open(uploadURL, "_blank");
  return (
    <UserProfileImg
      radius={radius || 5}
      backgroundColor={backgroundColor || "#3b5a92"}
      color={color}
    >
      {userProfilePhoto && <img src={userProfilePhoto} alt="Your Profile" />}
      {!userProfilePhoto && <>{getLetterAvatar(user.name)}</>}
      {canChangAvatar && (
        <span
          className="avatar-uploader"
          onClick={changePhotoHandler}
          onKeyDown={changePhotoHandler}
          role="button"
        >
          <div className="uploader-icon">
            <FiCamera />
          </div>
        </span>
      )}
    </UserProfileImg>
  );
};
