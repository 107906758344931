import styled from "styled-components";
import { Input } from "reactstrap";

export const InkDrop = styled.div(
  ({ theme, dropDownOpen, pencilIsRequired, styles }) =>
    Object.assign(
      {},
      theme.InkDropWrapper ? { ...theme.InkDropWrapper } : {},
      dropDownOpen
        ? {
            background: "#f4f7fb",
            border: "1px solid #9cafc7",
            borderRadius: "2px",
            transition: "all .1s",
          }
        : {},
      pencilIsRequired ? { borderLeft: "2px solid #c85454" } : {},
      styles ? { ...styles } : {}
    )
);

export const InkDropCol = styled.div(
  ({ theme, size, colName, isTitle, styles }) =>
    Object.assign(
      {},
      theme.InkDropCol ? { ...theme.InkDropCol } : {},
      size ? { flex: size } : {},
      isTitle && {
        borderBottom: "2px solid #767676",
      },
      colName && colName === "inkLabel"
        ? {
            paddingLeft: ".5rem",
            color: "#767676",
            fontSize: ".6rem",
            // fontStyle: "italic",
            // textTransform: "uppercase",
          }
        : {},
      // colName && colName === "inkLabel" ? { textTransform: "uppercase" } : {}
      styles ? { ...styles } : {}
    )
);

export const InkDropInput = styled(Input)({
  borderRadius: 0,
  boxSizing: "border-box",
  fontSize: ".8rem",
  border: "1px solid transparent",
  cursor: "pointer",
  "&:hover": {
    background: "transparent",
    transition: "background fade-in .1",
  },
  "&:active, &:focus": {
    cursor: "text",
    boxShadow: "none",
    border: "1px solid #767676",
    transition: "border ease-in .2s",
    "&:hover": {
      background: "transparent",
    },
  },
});

export default {
  InkDrop,
  InkDropCol,
  InkDropInput,
};
