export default (
  container: Object,
  path: string | Array<string>,
  splitBy: string | RegExp
) =>
  String(path)
    .split(splitBy)
    .filter(Boolean)
    .reduce(
      (carry: any, currPath: any) =>
        carry !== null && currPath !== undefined ? carry[currPath] : carry,
      container
    );
