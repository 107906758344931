const initialState = {
  isLoading: false,
  error: null,
  errorMessage: null,
  isTimeout: false,
  workflowObjects: {},
  errors: [],
};

const buildWorkflowStateItem = (state, payload) => {
  const { object, id, ...rest } = payload;
  return {
    ...initialState,
    workflowObjects: {
      ...(state.workflowObjects || {}),
      [object]: {
        ...((state.workflowObjects && state.workflowObjects[object]) || {}),
        [id]: {
          ...((state.workflowObjects &&
            state.workflowObjects[object] &&
            state.workflowObjects[object][id]) ||
            {}),
          ...rest,
        },
      },
    },
  };
};

export default (state: any = initialState, action: any) => {
  // TODO index state with workflow instance id better than using objects
  switch (action.type) {
    case "LOAD_WORKFLOW_STATS_DONE": {
      const { payload } = action;
      if (payload.object) {
        return buildWorkflowStateItem(state, {
          isSubscribed: false,
          ...payload,
        });
      }
      return {
        ...state,
      };
    }
    case "LOAD_WORKFLOW_TIMELINE_DONE": {
      const { payload } = action;
      if (payload.object) {
        return buildWorkflowStateItem(state, payload);
      }
      return {
        ...state,
      };
    }
    case "WORKFLOW_SUBSCRIBE_STARTED": {
      const { payload } = action;
      return buildWorkflowStateItem(state, {
        isSubscribed: true,
        ...payload,
        errors: [],
      });
    }
    case "WORKFLOW_UNSUBSCRIBE_STARTED": {
      const { payload } = action;
      return buildWorkflowStateItem(state, { isSubscribed: false, ...payload });
    }
    case "UPDATE_WORKFLOW_MESSAGE": {
      const { payload } = action;
      return buildWorkflowStateItem(state, { ...payload });
    }
    case "LOAD_WORKFLOW_TIMELINE_ERROR": {
      const { payload } = action;
      return buildWorkflowStateItem(state, { ...payload });
    }
    default:
      return state;
  }
};
