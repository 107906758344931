export default {
  uid: "radioSelect",
  attributeId: "",
  formId: "",
  pencilDef: {
    title: "Radio Select",
    group: "FIELD",
    handleIcon: "FaDotCircle",
    styles: {},
  },
  options: {
    title: "Untitled",
    type: "radioSelect",
    required: false,
    limits: {
      min: 0,
      max: 1,
    },

    // RADIO BUTTON specific options
    enableHistoryTracking: false,
    items: [],
  },
};
