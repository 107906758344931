// @flow
import processSchema from "./processSchema";
import processRecord from "./processRecord";
import { ATTRIB, LAYOUT, RECORD } from "./templates";
import {
  SHOW_FIELD,
  HIDE_FIELD,
  HIDE_LAYOUT,
  REQUIRE_FIELD,
  SET_VALUE,
  SET_OPTIONS,
  READONLY_FIELDS,
  READONLY_FIELD,
} from "./verbs";

/**
 * actionFunction takes two parameters
 * i. processingDataObject: { rules: Rules, data: ActionData, updateData: setValueFunction, schema: cookedSchema }
 * ii. initialDataObject: { schema: initialSchema, record: formRecord }
 */

export default {
  [SHOW_FIELD]: processSchema(SHOW_FIELD, ATTRIB),
  [HIDE_FIELD]: processSchema(HIDE_FIELD, ATTRIB),
  [HIDE_LAYOUT]: processSchema(HIDE_LAYOUT, LAYOUT),
  [REQUIRE_FIELD]: processSchema(REQUIRE_FIELD, ATTRIB),
  [SET_VALUE]: processRecord(SET_VALUE, RECORD),
  [SET_OPTIONS]: processSchema(SET_OPTIONS, ATTRIB),
  [READONLY_FIELDS]: processSchema(READONLY_FIELDS, ATTRIB),
  [READONLY_FIELD]: processSchema(READONLY_FIELD, ATTRIB),
};
