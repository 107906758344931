import { getStore } from "../../state/store";

export default () => {
  const attributesEnum = [];
  const attributesEnumNames = [];

  const store = getStore();

  if (store && store.getState && store.getState instanceof Function) {
    const {
      app: { attributes },
      schema: { newAttributes },
    } = store.getState();

    const attributesMerged = [
      ...attributes,
      ...newAttributes.map(newAttribute => ({
        ...newAttribute,
        name: newAttribute.attributeId,
      })),
    ];

    attributesMerged.forEach(attr => {
      attributesEnum.push(attr.name);
      attributesEnumNames.push(attr.label);
    });
  }

  return {
    attributesEnum,
    attributesEnumNames,
  };
};
