import LaravelEcho from "laravel-echo";
import Socketio from "socket.io-client";

let echo = null;

export function createEcho(token: string, callback?: () => {}) {
  if (process.env.REACT_APP_DISABLE_SOCKET === true) return echo;
  echo = new LaravelEcho({
    broadcaster: "socket.io",
    host: process.env.REACT_APP_API_URL
      ? `${process.env.REACT_APP_API_URL}/`
      : "ws://localhost:6001/",
    client: Socketio,
    auth: {
      headers: {
        Authorization: `${token}`,
      },
    },
  });

  echo.connector.socket.on("connect", () => {
    if (callback) {
      callback();
    }
  });
  return echo;
}

export function deleteEcho(callback?: () => any) {
  if (echo) {
    echo.connector.socket.on("disconnect", () => {
      if (callback) {
        callback();
      }
    });
  }
  echo = null;
}

export function getEcho() {
  return echo;
}

export default { deleteEcho, createEcho, getEcho };
