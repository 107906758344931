import * as React from "react";
import { connect } from "react-redux";
import styled, { withTheme } from "styled-components";
import { Draggable } from "react-beautiful-dnd";
import { Button } from "reactstrap";
import { FaPlusCircle, FaTimes, FaBars, FaEllipsisV } from "react-icons/fa";
import { Modal, Input, TextArea, Checkbox } from "@swan/themer";

import Parts from "../../definitions/parts";
import Col from "./Col";
import ConditionalDroppable from "../DragDrop/ConditionalDroppable";

// constants
import COLOURS from "../../constants/colours";

// state
import { schemaActions } from "../../state/reducers/schema";

import type { Props } from "./types";

import type { Schema } from "../../constants/flowTypes";

type RowProps = Props & {
  updateElementSchema: (string, Schema) => void,
  removeSchemaNode: string => void,
  rowCount: number,
  gridId: string,
};

type RowState = {
  isModalOpen: boolean,
  modalId: string,
};

const RowContainer = styled.div(({ theme }) =>
  theme.RowContainer ? { ...theme.RowContainer } : {}
);

const RowDragHandler = styled(
  styled.div(({ theme }) =>
    theme.RowDragHandler ? { ...theme.RowDragHandler } : {}
  )
)`
  &:hover {
    cursor: move;
  }
`;

const RowCloseHandler = styled(
  styled.div(({ theme }) =>
    theme.RowCloseHandler ? { ...theme.RowCloseHandler } : {}
  )
)`
  &:hover {
    cursor: pointer;
  }
`;

// const DragFrameTitle = styled.div(({ theme, hovering }) =>
//   Object.assign(
//     {},
//     { zIndex: 2 },
//     theme.DragFrameTitle ? { ...theme.DragFrameTitle } : {},
//     hovering && theme.DragFrameTitleHover
//       ? { ...theme.DragFrameTitleHover }
//       : {}
//   )
// );

const RowWrapper = styled.div(({ theme, isDragging }) =>
  Object.assign(
    {},
    {
      position: "relative",
    },
    theme.RowWrapper ? { ...theme.RowWrapper } : {},
    isDragging
      ? {
          background: `${COLOURS.CURRENTLY_DRAGGING_BG}`,
          borderRadius: ".2rem",
        }
      : {}
  )
);

export const ColsWrapper = styled.div(({ theme, empty }) =>
  Object.assign(
    {},
    theme.ColsWrapper ? { ...theme.ColsWrapper } : {},
    empty && theme.ColWrapperEmpty ? theme.ColWrapperEmpty : {}
  )
);

const AddButton = styled(Button)({
  border: "none",
  fontSize: 20,
});

class Row extends React.Component<RowProps, RowState> {
  state = {
    isModalOpen: false,
    modalId: "",
  };

  getCols = properties => {
    const { id } = this.props;
    if (!properties) return [];
    return Object.keys(properties).map(
      (colName: string, index: number) =>
        properties[colName].type === Parts.Col.options.type && (
          <Col
            index={index}
            key={`${id}/${colName}`}
            rowId={id}
            id={`${id}/${colName}`}
            schema={properties[colName]}
            colCount={Object.keys(properties).length}
            childCount={Object.keys(properties).length}
            removeRow={rowId => this.removeRowSchema(rowId)}
          />
        )
    );
  };

  addCol = () => {
    const { schema, id, updateElementSchema } = this.props;
    if (!schema.properties) {
      schema.properties = {};
    }
    const currentCols = Object.keys(schema.properties).length;
    if (currentCols >= Parts.Row.settings.MaxCols) {
      return;
    }
    let idx = Object.keys(schema.properties).length;
    while (schema.properties[`col${idx}`]) {
      idx += 1;
    }
    schema.properties[`col${idx}`] = {
      type: Parts.Col.options.type,
      properties: { ...Parts.Col.options.defaultProperties },
    };
    updateElementSchema(id, schema);
    const evt = new Event("FormDesigner::Refresh");
    window.dispatchEvent(evt);
  };

  canAddCols = () => {
    const { schema } = this.props;
    if (!schema.properties) {
      return true;
    }
    const currentCols = Object.keys(schema.properties).length;
    return currentCols < Parts.Row.settings.MaxCols;
  };

  removeRowSchema = schemaPath => {
    const { removeSchemaNode, rowCount, gridId } = this.props;
    if (rowCount === 1) {
      // If single column remove row
      removeSchemaNode(gridId);
    } else {
      removeSchemaNode(schemaPath);
    }
  };

  openModal = modalId => {
    this.setState({
      modalId,
      isModalOpen: true,
    });
  };

  toggleModal = (setOn?: boolean, modalId?: string) => {
    this.setState(prevState => ({
      modalId,
      isModalOpen:
        setOn !== undefined && typeof setOn === "boolean"
          ? setOn
          : !prevState.isModalOpen,
    }));
  };

  updateRowOptions = (k, v) => {
    const { schema, updateElementSchema } = this.props;
    const { modalId } = this.state;

    if (schema.type && schema.type === "row") {
      const updatedSchema = Object.assign(
        { ...schema },
        { options: { ...(schema.options || {}), [k]: v } }
      );
      updateElementSchema(modalId, updatedSchema);
    }
  };

  render() {
    const { schema, id, index, rowCount } = this.props;
    const { isModalOpen } = this.state;
    const cols = this.getCols(schema.properties);
    return (
      <>
        <Draggable
          draggableId={id}
          index={index || 0}
          isDragDisabled={rowCount === 1}
        >
          {(draggableProvided, draggableSnapshot) => (
            <RowWrapper
              ref={draggableProvided.innerRef}
              {...draggableProvided.draggableProps}
              isDragging={draggableSnapshot.isDragging}
              draggingOver={draggableSnapshot.draggingOver}
            >
              {/* <DragFrameTitle hovering={hovering}>
              
            </DragFrameTitle> */}

              <RowContainer>
                <ConditionalDroppable
                  droppableId={id}
                  direction="horizontal"
                  allowTypes={Parts.Row.drop.allowTypes}
                  droppableWrapper="Row"
                >
                  <ColsWrapper empty={cols.length === 0} colCount={cols.length}>
                    {cols}
                  </ColsWrapper>
                </ConditionalDroppable>
                <div>
                  <RowDragHandler {...draggableProvided.dragHandleProps}>
                    <FaBars />
                    {/* {rowCount > 1 && <span>Drag Me</span>}
                {rowCount <= 1 && <span>Drag is not allowed</span>} */}
                  </RowDragHandler>
                  <RowCloseHandler
                    onClick={() => {
                      this.openModal(id);
                    }}
                  >
                    <FaEllipsisV size=".8em" />
                  </RowCloseHandler>
                  <RowCloseHandler
                    onClick={() => {
                      this.removeRowSchema(id);
                    }}
                  >
                    <FaTimes size=".8em" />
                  </RowCloseHandler>

                  <AddButton
                    color="secondary"
                    outline
                    onClick={this.addCol}
                    disabled={!this.canAddCols()}
                  >
                    <FaPlusCircle />
                  </AddButton>
                </div>
              </RowContainer>
            </RowWrapper>
          )}
        </Draggable>
        <Modal toggle={this.toggleModal} isOpen={isModalOpen} enableBackdrop>
          <Input
            name={`Class name for ${id}`}
            type="text"
            label="Class Names"
            placeholder="Space seperated class names"
            onChange={e => {
              this.updateRowOptions("classNames", e.target.value);
            }}
            value={((schema || {}).options || {}).classNames}
          />
          <TextArea
            name={`Custom styles for ${id}`}
            label="Custom Styles"
            placeholder="CSS Styles"
            onChange={e => {
              this.updateRowOptions("customStyles", e.target.value);
            }}
            value={((schema || {}).options || {}).customStyles}
          />

          <Checkbox
            name={`hideOnCreate for ${id}`}
            value={((schema || {}).options || {}).hideOnCreate}
            label="Hide on Create"
            onChange={() => {
              const checkValue =
                ((schema || {}).options || {}).hideOnCreate !== "1" ? "1" : "0";
              this.updateRowOptions("hideOnCreate", checkValue);
            }}
          />
          <Checkbox
            name={`hideOnDetail for ${id}`}
            value={((schema || {}).options || {}).hideOnDetail}
            label="Hide on Detail"
            onChange={() => {
              const checkValue =
                ((schema || {}).options || {}).hideOnDetail !== "1" ? "1" : "0";
              this.updateRowOptions("hideOnDetail", checkValue);
            }}
          />
          <Checkbox
            name={`isHideOnCondition for ${id}`}
            value={((schema || {}).options || {}).isHideOnCondition}
            label="Enable hide On Condition"
            onChange={() => {
              const checkValue =
                ((schema || {}).options || {}).isHideOnCondition !== "1"
                  ? "1"
                  : "0";
              this.updateRowOptions("isHideOnCondition", checkValue);
            }}
          />
          {((schema || {}).options || {}).isHideOnCondition === "1" && (
            <TextArea
              name={`hideOnCondition for ${id}`}
              label="Hide On Condition"
              value={((schema || {}).options || {}).hideOnCondition}
              onChange={e => {
                this.updateRowOptions("hideOnCondition", e.target.value);
              }}
            />
          )}
        </Modal>
      </>
    );
  }
}

export default connect(
  null,
  {
    updateElementSchema: schemaActions.updateElementSchema,
    removeSchemaNode: schemaActions.removeSchemaNode,
  }
)(withTheme(Row));
