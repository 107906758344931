import React, { Component, type ComponentType, type Node } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { Popover, Button as ButtonComponent, Spinner } from "@swan/themer";
import { Form as FormHOC, RecordProvider } from "@swan/helpers/CRUD";
// import memoize from "memoize-one";

import DynamicForm from "../DynamicForms/DynamicForm";
import {
  ObjectService,
  getObjectResourceService,
} from "../services/objectService";

const Footer = styled.div({
  display: "flex",
  justifyContent: "flex-end",
});

type PlacementType =
  | "auto"
  | "auto-start"
  | "auto-end"
  | "top"
  | "top-start"
  | "top-end"
  | "right"
  | "right-start"
  | "right-end"
  | "bottom"
  | "bottom-start"
  | "bottom-end"
  | "left"
  | "left-start"
  | "left-end";

type Props = {
  object: string,
  id: number,
  type?: string,
  placement?: PlacementType,
  popsBy?: "hover" | "click",
  Button?: ComponentType<{
    id: number,
    type: string,
    object: string,
    placement: string,
    isBodyLoaded: boolean,
  }>,
  moreLink?: string,
  moreText?: string,
  More?: ComponentType<{ link: string, text: string }>,
};

type State = {
  isBodyLoaded: boolean,
};

export default class ObjectPopover extends Component<Props, State> {
  static defaultProps = {
    type: "popover",
    placement: "auto",
    popsBy: "hover",
    Button: (props: {
      object: string,
      id: number,
      type?: string,
      placement?: string,
      isBodyLoaded: boolean,
      isOpen: boolean,
      children: Node,
    }) => (
      <ButtonComponent {...props}>
        {props.object} / {props.id}
      </ButtonComponent>
    ),
    More: ({ link, text }: { link: string, text: string }) => (
      <Link to={link}>{text}</Link>
    ),
    moreLink: undefined,
    moreText: "Link",
  };

  constructor(props: Props) {
    super(props);
    this.state = {
      isBodyLoaded: false,
    };
  }

  componentDidMount() {
    const { object } = this.props;
    if (object && this.Service === undefined) {
      ObjectService.getObjectByName(object)
        .then(result => {
          this.Service = getObjectResourceService(`${result.api_endpoint}`);
        })
        .catch(e => {
          throw e.message;
        });
    }
    // ObjectService.getObjectByName(object).get(result => console.log(result));
  }

  componentWillUnmount() {
    this.Service = undefined;
  }

  getBody = ({ object, id, type }: Object) => {
    const Form = props => (
      <DynamicForm {...props} object={object} type={type} id={id} readOnly />
    );
    const FormComponent = FormHOC({})(Form);
    return RecordProvider(this.Service)(FormComponent);
  };

  loadBody = () => {
    const { object, id, type } = this.props;
    const { isBodyLoaded } = this.state;
    if (!isBodyLoaded) {
      const BodyComponent = this.getBody({ object, id, type });
      this.Body = () => (
        <BodyComponent object={object} id={id} type={type} disableButtonsBar />
      );
      this.setState({
        isBodyLoaded: true,
      });
    }
  };

  Service: Object;

  Body: ComponentType<*>;

  render() {
    const {
      Button,
      object,
      id,
      type,
      placement,
      popsBy,
      moreLink,
      moreText,
      More,
    } = this.props;
    const { isBodyLoaded } = this.state;
    const FormComponent = this.Body;
    return (
      <Popover
        id={`${object}_${id}_${type || "popover"}`}
        popsBy={popsBy}
        bodyLoader={!isBodyLoaded ? this.loadBody : undefined}
        placement={placement}
        components={{
          Button: Button
            ? ({ isOpen, children }) => (
                <Button
                  {...{
                    object,
                    id,
                    type,
                    placement,
                    isOpen,
                    isBodyLoaded,
                    children,
                  }}
                />
              )
            : undefined,
        }}
      >
        {isBodyLoaded ? <FormComponent /> : <Spinner size="sm" />}

        {isBodyLoaded && moreLink && moreText && More !== undefined && (
          <Footer>
            <More link={moreLink} text={moreText} />
          </Footer>
        )}
      </Popover>
    );
  }
}
