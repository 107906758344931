export default {
  uid: "lastMeeting",
  attributeId: "",
  formId: "",
  pencilDef: {
    title: "Last Meeting",
    group: "FIELD",
    handleIcon: "FaSitemap",
    styles: {},
  },
  options: {
    title: "Last Meeting",
    type: "lastMeeting",
  },
};
