import coreDef from "./core_def";

const getCoreNavs = (navDef?: Array<Object> = []) => {
  const CoreNav = [];
  const modulesNav = {};
  let productNav = [];
  navDef.forEach(cn => {
    // core nav
    if ((cn || {}).mainNav) {
      CoreNav.push(cn.mainNav);
    }

    // product nav
    if ((cn || {}).productNav) {
      productNav = [...productNav, ...(cn || {}).productNav];
    }

    // module nav
    Object.assign(modulesNav, {
      [cn.name.toLowerCase()]: {
        nav: [...cn.productNav],
        info: {
          name: cn.name || ""
        }
      }
    });
  });

  return {
    CoreNav,
    modulesNav,
    productNav
  };
};

export const { CoreNav, modulesNav, productNav } = getCoreNavs(coreDef);

export default CoreNav;
