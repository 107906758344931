import * as React from "react";
import { withSwanContext, SwanContextManager } from "@swan/state"; // eslint-disable-line

type State = {
  isAllowed: ?boolean,
};

type Props = {
  Component?: any,
  SwanContext: any,
  ownerId: number,
  render?: Function,
  children?: React.Node,
  fallback?: any,
};

class OwnerComponent extends React.Component<Props, State> {
  static defaultProps = {
    Component: undefined,
    render: undefined,
    fallback: undefined,
    children: null,
  };

  state = {
    isAllowed: null,
  };

  componentDidMount() {
    this.isOwner();
  }

  isOwner() {
    const {
      ownerId,
      SwanContext: {
        user: { id },
      },
    } = this.props;
    const superAdminMode = SwanContextManager.getValue("superadminModeEnabled");
    if (id === ownerId || superAdminMode) {
      this.setState({
        isAllowed: true,
      });
    }
  }

  render() {
    const { isAllowed } = this.state;
    const { Component, render, fallback, ...restProps } = this.props;
    if (isAllowed === null) return null;
    if (isAllowed === false) {
      if (fallback) {
        return fallback;
      }
      return null;
    }
    if (Component) {
      return <Component {...restProps} render={render} />;
    }
    if (render) {
      return render(restProps);
    }
    const { children } = this.props;
    return children || null;
  }
}

export default withSwanContext(OwnerComponent);
