import { getStore } from "../../state/store";

export const getJsonSchemaForFilesUpload = (): {
  jsonSchema: {},
  jsonUiSchema: {},
} => {
  const store = getStore();
  const { RELOBJ } = store.getState().app.pencils;
  const formsEnum = [];
  const formsEnumNames = [];
  // get all relationships here
  (RELOBJ || []).map(relationship => {
    const {
      schema: {
        options: { relationMeta },
      },
    } = relationship;
    if (
      relationMeta.type === "OneToMany" ||
      relationMeta.type === "Polymorphic"
    ) {
      if (!formsEnumNames.includes(relationMeta.name)) {
        formsEnum.push(JSON.stringify(relationMeta));
        formsEnumNames.push(relationMeta.name);
      }
    }
    return true;
  });
  const jsonSchema = {
    type: "object",
    properties: {
      title: {
        type: "string",
        title: "Field Label",
      },
      relationship: {
        type: "string",
        title: "Relationship",
        enum: formsEnum,
        enumNames: formsEnumNames,
      },
      backgroundColor: {
        type: "string",
        title: "Title",
        default: "#20a8d8",
      },
      fileNameField: {
        type: "string",
        title: "Original Filename field name (default 'name')",
      },
      pathFieldName: {
        type: "string",
        title: "Path Field name (default 'path')",
      },
      sizeFieldName: {
        type: "string",
        title: "Size Field name (default 'size')",
      },
      // includeMimeType: {
      //   type: "boolean",
      //   title: "Include Mime Type",
      // },
      includeFileSize: {
        type: "boolean",
        title: "Include File Size",
      },
    },
  };
  const jsonUiSchema = {};
  return {
    jsonSchema,
    jsonUiSchema,
  };
};

export default {
  getJsonSchemaForFilesUpload,
};
