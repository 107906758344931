import Axios from "axios";
import { ServiceGenerator } from "@swan/api";

const BaseWorkflowTimelineService = ServiceGenerator(Axios, "workflow");
const WorkflowTimelineService = ServiceGenerator(Axios, "workflow");

class CachedWorkflowTimelineService extends BaseWorkflowTimelineService {
  workflowTimelineByObject = {};

  objectWorkflowTimelinePromises = {};

  /**
   * Load formsById
   */
  loadWorkflowTimelineByObject(
    object: string,
    id: number,
    workflowId: number
  ): Promise<Object> {
    const key = `objects/${object}/${id}/timeline/${workflowId}`;
    if ((this.workflowTimelineByObject || {})[key]) {
      return Promise.resolve(this.workflowTimelineByObject[key]);
    }
    if (this.objectWorkflowTimelinePromises[key]) {
      return this.objectWorkflowTimelinePromises[key];
    }

    this.objectWorkflowTimelinePromises[key] = this.get(key).then(response => {
      this.workflowTimelineByObject[key] = response;
      this.objectWorkflowTimelinePromises[key] = null;
      return response;
    });
    return this.objectWorkflowTimelinePromises[key];
  }

  getObjectWorkflowTimeline(
    object: string,
    id: number,
    workflowId: number,
    forceLoad: boolean = false
  ) {
    const key = `objects/${object}/${id}/timeline/${workflowId}`;
    if (forceLoad) {
      if ((this.workflowTimelineByObject || {})[key]) {
        delete (this.workflowTimelineByObject || {})[key];
      }
    }
    if (!(this.workflowTimelineByObject || {})[key]) {
      return this.loadWorkflowTimelineByObject(object, id, workflowId);
    }
    return Promise.resolve(this.workflowTimelineByObject[key]);
  }

  clearLocalObjectWorkflowTimeline(
    object: string,
    id: number,
    workflowId: number
  ) {
    const key = `objects/${object}/${id}/timeline/${workflowId}`;
    if ((this.workflowTimelineByObject || {})[key]) {
      delete (this.workflowTimelineByObject || {})[key];
    }
  }
}

export const CacheWorkflowTimelineServiceInstance = new CachedWorkflowTimelineService();

WorkflowTimelineService.prototype.getObjectWorkflowTimeline = function getObjectWorkflowTimeline(
  object: string,
  id: number,
  workflowId: number,
  forceLoad: boolean = false
) {
  return CacheWorkflowTimelineServiceInstance.getObjectWorkflowTimeline(
    object,
    id,
    workflowId,
    forceLoad
  );
};

WorkflowTimelineService.prototype.clearLocalObjectWorkflowTimeline = function clearLocalObjectWorkflowTimeline(
  object: string,
  id: number,
  workflowId: number
) {
  return CacheWorkflowTimelineServiceInstance.clearLocalObjectWorkflowTimeline(
    object,
    id,
    workflowId
  );
};

export default WorkflowTimelineService;
