import React from "react";

import styled from "styled-components";

const LoaderContainer = styled.div({
  minHeight: "100vh",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  backgroundColor: "black",
  color: "white",
});

export default () => (
  <LoaderContainer>
    <h3>Loading... Please wait</h3>
  </LoaderContainer>
);
