// styled
import Styled from "styled-components";

export const Wrapper = Styled.div``;

export const NavigationContainer = Styled.div``;

export const ProfileContainer = Styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-bottom: 1.7rem;

  .product-nav-title {
    margin: 0.5rem;
    font-size: 1.1rem
    color: #ffffff;
  }

  .letter-avatar {
    .avatar-uploader {
      display: none;
    }

    position: relative;
    height: 5rem;
    width: 5rem;
    justify-content: center;
    align-items: center;
    display: flex;
    border-radius: 50%;
    background: #cdced0;
    font-size: 2.6rem;
    font-weight: bold;
    margin: 0.5rem;

    &:hover {
      ${(props: any) => {
        if (props.canChangAvatar) {
          return `
            .avatar-uploader {
              display: block;
              position: absolute;
              width: 100%;
              height: 100%;
              z-index: 200;
              opacity: 0.7;
              background: #cdced0;
              border-radius: 50%;
              cursor: pointer;
  
              
              .uploader-icon {
                display: flex;
                width: 100%;
                height: 100%;
                justify-content: center;
                align-items: center;
                font-size: 1rem;
                color: #395998;
              }
            }
          `;
        }

        return ``;
      }}
    }

  }

  .profile-name {
      font-size: 1.1rem;
      font-weight: bold;
      color: #fff;
      padding: 0.4rem 0;
  }

  .profile-job-title {
    font-size: 0.8rem;
    color: #bababa;
    text-align: center;
    padding: 0 1rem
  }
`;
