import * as React from "react";
import Elements from ".";

import type { ElementProps } from "./types";
import type { Schema } from "../../types";

// import CustomComponentWrapper from "./partials/CustomComponentWrapper";
import { RendererContext } from "../Main";

type ElementsListProps = ElementProps & {
  renderType?: string,
};

class ElementsList extends React.Component<ElementsListProps> {
  static defaultProps = {
    renderType: undefined,
  };

  getElements = (elements: { [string]: Schema }): any => {
    const { id, renderType } = this.props;
    if (!elements) return [];
    return Object.keys(elements).map(
      (elemName: string, index: number) =>
        (elements[elemName].type === renderType || !renderType) && (
          <RendererContext.Consumer key={`${id}/${elemName}`}>
            {({ rendererComponents }) => {
              let rendererComponent;

              if (
                rendererComponents &&
                rendererComponents[
                  ((elements[elemName] || {}).options || {}).rendererComponent
                ]
              ) {
                rendererComponent =
                  rendererComponents[
                    ((elements[elemName] || {}).options || {}).rendererComponent
                  ];
              }
              return (
                <Elements.Element
                  index={index}
                  key={`${id}/${elemName}`}
                  id={`${id}/${elemName}`}
                  schema={elements[elemName]}
                  rendererComponent={rendererComponent}
                />
              );
            }}
          </RendererContext.Consumer>
        )
    );
  };

  render() {
    const { schema } = this.props;
    if (schema.properties) {
      return (
        <React.Fragment>{this.getElements(schema.properties)}</React.Fragment>
      );
    }
    return null;
  }
}

export default ElementsList;
