import React from "react";
import { Button, Prompt, ButtonConfirm } from "@swan/themer";
import { connect } from "react-redux";
import { withSwanContext, type SwanContextType } from "@swan/state";

import styled from "styled-components";

import WorkflowStatusService from "../services/workflowStats";
import WorkflowTimelineService from "../services/workflowTimeline";
import WorkflowService from "../services/workflow";
import workflowActions from "../state/actions";
import { withProvider as withStoreProvider } from "../utils/storeProvider";
import { persistor, store } from "../state/store";

type Props = {
  object: string,
  record: Object,
  workflowId: string,
  overrideWorkflowId: string, // this is temporary until enable forms for different organizations.
  stats: any,
  setButtons?: Function,
  removeButtons?: Function,
  SwanContext: SwanContextType,
  loadWorkflowStats: (
    object: string,
    id: number,
    workflowId: string,
    forceLoad: boolean
  ) => void,
};

type State = {
  // summary: Array<Object>,
  buttons: Array<Object>,
  currentButtons: Array<Object>,
};

const CustomButton = styled(Button)`
  border-radius: 17px;
  margin-right: 10px;
`;

const CustomButtonConfirm = styled(ButtonConfirm)`
  border-radius: 17px;
  margin-right: 10px;
`;

const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;

class WorkflowActionsButtons extends React.PureComponent<Props, State> {
  static defaultProps = {
    setButtons: undefined,
    removeButtons: undefined,
  };

  state = {
    // summary: [],
    currentButtons: [],
    buttons: [],
  };

  constructor(props: Props) {
    super(props);
    this.prompt = React.createRef();
  }

  componentDidMount() {
    // this.startPolling();
    this.load();
    this.mounted = true;
    const key = this.getWorkflowKey();
    if (key) {
      window.addEventListener(`${key}::refresh`, this.load);
    }
  }

  componentWillReceiveProps(nextProps: Props): void {
    const { setButtons, stats } = nextProps;
    const buttons = this.generateButtons(stats);
    this.setState(prevState => {
      if (
        JSON.stringify(prevState.currentButtons) !== JSON.stringify(buttons)
      ) {
        if (setButtons) {
          setButtons(buttons);
          return {
            currentButtons: buttons,
          };
        }
        return {
          buttons,
        };
      }
      return null;
    });
  }

  componentWillUnmount() {
    this.mounted = false;
    const key = this.getWorkflowKey();
    if (key) {
      window.removeEventListener(`${key}::refresh`, this.load);
    }
    const { removeButtons } = this.props;
    if (removeButtons) {
      const { currentButtons } = this.state;
      const ids = currentButtons.map(btn => btn.id);
      removeButtons(ids);
    }
  }

  getWorkflowKey() {
    const {
      record: { id },
      workflowId,
    } = this.props;
    if (id) {
      return `workflow:${workflowId}:${id}`;
    }
    return null;
  }

  load = () => {
    const {
      object,
      record: { id },
      workflowId,
      loadWorkflowStats,
      overrideWorkflowId,
    } = this.props;
    if (id) {
      loadWorkflowStats(object, id, overrideWorkflowId || workflowId, true);
    }
  };

  refreshNewButtons = () => {
    const {
      record: { id },
      stats,
      setButtons,
    } = this.props;
    let buttons = [];
    if (id) {
      buttons = this.generateButtons(stats);
      if (setButtons && this.mounted) {
        setButtons(buttons);
      }
    }
    return buttons;
  };

  refresh() {
    const key = this.getWorkflowKey();
    if (key) {
      // Clear the locally stored status
      const srv = new WorkflowStatusService();
      const timelineSrv = new WorkflowTimelineService();
      const {
        object,
        record: { id },
        workflowId,
      } = this.props;
      if (id) {
        srv.clearLocalObjectWorkflowStatus(object, id, workflowId);
        timelineSrv.clearLocalObjectWorkflowTimeline(object, id, workflowId);
      }
      const { removeButtons } = this.props;
      if (removeButtons) {
        const { currentButtons } = this.state;
        const ids = currentButtons.map(btn => btn.id);
        removeButtons(ids);
      }
      // Then trigger refresh event
      const event = new Event(`${key}::refresh`);
      window.dispatchEvent(event);
    }
  }

  /**
   * Generates the buttons from the status of the workflow.
   */
  generateButtons(status: {
    currentSteps?: Array<Object>,
    workflow: Object,
  }): Array<Object> {
    if (!status.currentSteps) {
      return [];
    }
    let buttons = [];
    (status.currentSteps || []).forEach(step => {
      if (step.type === "state" && step.operation === "MANUAL_TRIGGER") {
        const s = {};
        s.id = `workflow_${step.label}`;
        s.label = step.label;
        s.onClick = () => {
          const srv = new WorkflowService();
          srv.triggerAction(status.workflow.instance, step.name).then(() => {
            this.refresh();
          });
        };
        buttons.push(s);
      }
      if (step.type === "state" && step.operation === "DECISION") {
        Object.keys(step.options).forEach(optName => {
          const opt = step.options[optName];
          const s = {};
          s.id = `workflow_${opt.label}`;
          s.label = opt.label;
          s.color = opt.color;
          s.onClick = () => {
            if (opt.input && Object.keys(opt.input || {}).length) {
              this.prompt.current.open(opt.label, opt.input, inputs => {
                const srv = new WorkflowService();
                srv
                  .triggerDecision(
                    status.workflow.instance,
                    step.nodeId,
                    optName,
                    inputs
                  )
                  .then(() => {
                    this.refresh();
                  });
              });
            } else {
              const srv = new WorkflowService();
              srv
                .triggerDecision(status.workflow.instance, step.nodeId, optName)
                .then(() => {
                  this.refresh();
                });
            }
          };
          buttons.push(s);
        });
      }
      if (step.approval) {
        // For approval we check if the current user has a pending approval
        const {
          SwanContext: { user },
        } = this.props;
        if (step.approval.pending) {
          step.approval.pending.forEach(pendingApproval => {
            if (pendingApproval.user_id === user.id) {
              let approvalButtonsStyle = {
                approve: {
                  color: "#117a8b",
                  label: "Approve",
                },
                reject: {
                  color: "#dc3545",
                  label: "Reject",
                },
              };
              if (step.approvalButtonsStyle) {
                approvalButtonsStyle = { ...step.approvalButtonsStyle };
              }
              buttons.push({
                id: `workflow_${step.label}`,
                label:
                  status.workflow.type === "approval"
                    ? ((approvalButtonsStyle || {}).approve || {}).label ||
                      status.workflow.name ||
                      step.label // in the case of approval workflow, use workflow name instead of node label
                    : step.label,
                type: "ButtonConfirm",
                confirmOptions: {
                  message:
                    ((approvalButtonsStyle || {}).approve || {}).label ||
                    "Approve?",
                  onNoClick: () => {
                    this.prompt.current.open(
                      ((approvalButtonsStyle || {}).reject || {}).label ||
                        "Reject",
                      {
                        response: {
                          type: "paragraph",
                          label: "Comments",
                        },
                      },
                      inputs => {
                        const srv = new WorkflowService();
                        srv.reject(pendingApproval.id, inputs).then(() => {
                          this.refresh();
                        });
                      }
                    );
                  },
                },
                onClick: () => {
                  this.prompt.current.open(
                    ((approvalButtonsStyle || {}).approve || {}).label ||
                      "Approve",
                    {
                      response: {
                        type: "paragraph",
                        label: "Comments",
                      },
                    },
                    inputs => {
                      const srv = new WorkflowService();
                      srv.approve(pendingApproval.id, inputs).then(() => {
                        this.refresh();
                      });
                    }
                  );
                },
              });
            }
          });
        }
      }
      if (step.substatus) {
        const subbuttons = this.generateButtons(step.substatus);
        buttons = [...buttons, ...subbuttons];
      }
    });
    return buttons;
  }

  prompt: any;

  mounted: boolean;

  renderListOfSteps(steps: Array<Object>) {
    return (
      <ul>
        {steps.map(s => (
          <li>
            {s.text}
            {s.sub && this.renderListOfSteps(s.sub)}
          </li>
        ))}
      </ul>
    );
  }

  render() {
    const { buttons } = this.state;
    return (
      <ButtonsWrapper>
        <Prompt ref={this.prompt} />
        {buttons.map(button => (
          <>
            {button.confirmOptions && (
              <CustomButtonConfirm
                key={`btn${button.label}`}
                color={button.color || "info"}
                onClick={button.onClick}
                disabled={button.disabled}
                {...button.confirmOptions || {}}
              >
                {button.label}
              </CustomButtonConfirm>
            )}
            {!button.confirmOptions && (
              <CustomButton
                key={`btn${button.label}`}
                color={button.color || "info"}
                onClick={button.onClick}
                disabled={button.disabled}
              >
                {button.label}
              </CustomButton>
            )}
          </>
        ))}
      </ButtonsWrapper>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const {
    object,
    record: { id },
  } = ownProps;
  const {
    workflow: { workflowObjects },
  } = state;
  return {
    stats:
      (workflowObjects &&
        workflowObjects[object] &&
        workflowObjects[object][id] &&
        workflowObjects[object][id].stats) ||
      [],
  };
};

const mapDispatchToProps = dispatch => ({
  loadWorkflowStats: (...defs) =>
    dispatch(workflowActions.loadWorkflowStats(...defs)),
});

export default withStoreProvider(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withSwanContext(WorkflowActionsButtons)),
  {
    store,
    persistor,
  }
);
