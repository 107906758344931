import React from "react";
import { Link } from "react-router-dom";
import { Badge } from "reactstrap";
import { ObjectUtils } from "@swan/helpers/utils";
import Button from "../../Button";

import { Card } from "./Styled";

import { type CardType, type CardActionType, type CardTagType } from "./types";

type Props = CardType & {};

type State = {};

export default class Main extends React.Component<Props, State> {
  componentDidMount() {}

  getDataByAccessor = (dataPart: string, accessor: string) => {
    const { data } = this.props;
    return accessor ? ObjectUtils.get(data, accessor) : (data || {})[dataPart];
  };

  render() {
    const {
      data,
      titleAccessor,
      labelAccessor,
      bodyAccessor,
      footer,
      actions,
      tags,
    } = this.props;

    const title = titleAccessor
      ? ObjectUtils.get(data, titleAccessor)
      : (data || {}).title;
    const label = labelAccessor
      ? ObjectUtils.get(data, labelAccessor)
      : (data || {}).label;
    const body = bodyAccessor
      ? ObjectUtils.get(data, bodyAccessor)
      : (data || {}).body;

    return (
      <Card className="kb-card-container">
        {title || label ? (
          <div
            className={`kb-card-title-label ${
              !label ? "without-" : "with-"
            }label ${!title ? "without-" : "with-"}title`}
          >
            {title ? (
              <div
                className={`kb-card-title ${
                  !label ? "without-" : "with-"
                }label`}
              >
                {title}
              </div>
            ) : null}
            {label ? (
              <div
                className={`kb-card-label ${
                  !title ? "without-" : "with-"
                }title`}
              >
                {label}
              </div>
            ) : null}
          </div>
        ) : null}

        <div className="kb-card-body">{body}</div>

        {actions && actions.length ? (
          <div className="kb-card-actions">
            {(actions || []).map((action: CardActionType) => {
              if (action.to || action.href) {
                const Btn = (props: CardActionType) => (
                  <Button color={props.color} size="sm">
                    {props.label}
                  </Button>
                );
                return (
                  <div className="kb-card-action kb-card-action-item">
                    {action.to ? (
                      <Link to={action.to}>
                        <Btn action={action} />
                      </Link>
                    ) : null}

                    {action.href ? (
                      <a
                        href={action.href}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <Btn {...action} />
                      </a>
                    ) : null}
                  </div>
                );
              }
              return null;
            })}
          </div>
        ) : null}

        {tags ? (
          <div className="kb-card-tags">
            {(tags || []).map((tag: CardTagType) => (
              <Badge size={tag.size || "md"} color={tag.color || "primary"}>
                {tag.tag}
              </Badge>
            ))}
          </div>
        ) : null}

        {footer ? <div className="kb-card-footer">{footer}</div> : null}
      </Card>
    );
  }
}
