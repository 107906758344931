// setup a default options
const options = {
  type: "displayText",
  title: "Untitled", // no effect in display
  hideField: false,
  displayContent: "",
  allowHtml: true,
};

export default {
  uid: "displayText",
  attributeId: "",
  formId: "",
  pencilDef: {
    title: "Display Text",
    group: "FIELD",
    handleIcon: "FaParagraph",
    styles: {},
    skipNewField: true,
  },
  options: { ...options },
  // template provided for jsonSchemaProvider
  schema: {
    id: "displayText",
    label: "Untitled",
    formId: "",
    type: "displayText",
    options: { ...options },
  },
};
