export default {
  uid: "checkbox",
  attributeId: "",
  formId: "",
  pencilDef: {
    title: "Checkbox",
    group: "FIELD",
    handleIcon: "FaCheckSquare",
    styles: {},
  },
  options: {
    type: "checkbox",
    labelTitle: "",
    limits: {
      min: 2,
      max: 255,
    },
  },
};
