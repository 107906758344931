import {
  merge,
  // mergeWith,
  cloneDeep,
  has,
  hasIn,
  map,
  isEmpty,
  get,
  unset,
  isObject,
  isArray,
  assign,
  indexOf,
  startsWith,
  drop,
  keys as getObjectKeys,
} from "lodash";

import type { Schema } from "../../constants/flowTypes";
import schemaWalker from "./schemaWalker";
import objectCompare from "../../utils/objectCompare";

export const updateSchemaElement = (
  schema: Schema,
  elementId: string,
  elementSchema: Schema
) => {
  if (elementId === "@") {
    return { ...elementSchema };
  }
  const path = elementId.split("/");
  if (!path[1]) {
    return { ...schema };
  }

  const patch = {
    properties: {},
  };
  let current = patch.properties;
  let prev = schema.properties;
  for (let i = 1; i < path.length - 1; i += 1) {
    current[path[i]] = {
      properties: {},
    };
    current = current[path[i]].properties;
    if (prev) {
      prev = prev[path[i]].properties;
    }
  }
  current[path[path.length - 1]] = elementSchema;
  if (prev) {
    prev[path[path.length - 1]] = elementSchema;
  }

  return merge(schema, patch);
};

export const getTypeFromId = (elementId: string, schema: Schema) => {
  const path = elementId.split("/");
  if (path[0] !== "@") return elementId;
  if (!schema.properties) return null;
  let current = schema;
  for (let i = 1; i < path.length; i += 1) {
    current = (current.properties || {})[path[i]];
    if (!current) return null;
    if (!current.properties) return null;
  }
  return current.type;
};

// export const getSchemaPropertiesByDestination = (
//   destinationId: string,
//   schema: Schema
// ) => {
//   if (destinationId === "@") {
//     return schema.properties;
//   }

//   const propertiesLabel = "properties";
//   const pathArray = [];
//   map(destinationId.split("/"), destinationPart => {
//     if (!isEmpty(destinationPart)) {
//       if (destinationPart !== "@") pathArray.push(destinationPart);
//       pathArray.push(propertiesLabel);
//     }
//   });

//   if (!isEmpty(pathArray) && hasIn(schema, pathArray)) {
//     return get(schema, pathArray, {});
//   }

//   return schema.properties;
// };

/**
 * @param { Schema } schema Schema from state
 * @param { Schema } elementSchema Schema generated by JsonSchemaProvider utilility
 * @param { string } destinationId Droppable ID
 * @param { number } destinationIndex Droppable Index to be dropped off
 */
export const addElement = (
  schema: Schema,
  elementSchema: Schema,
  destinationId: string,
  destinationIndex: number
) => {
  const parts = destinationId.split("/");
  if (destinationId !== "@") {
    if (parts.length > 1) {
      const current = (schema.properties || {})[parts[1]]; // current schema
      parts.splice(0, 1);
      const newPath = parts.join("/");
      return {
        ...schema,
        properties: {
          ...schema.properties,
          [parts[0]]: addElement(
            current,
            elementSchema,
            newPath,
            destinationIndex
          ),
        },
      };
    }
  } else if (isEmpty(schema)) {
    return elementSchema;
  }

  const NewSchema = { ...schema, properties: {} };
  const keys = Object.keys(schema.properties || {});
  let i;
  for (i = 0; i < Math.min(keys.length, destinationIndex); i += 1) {
    NewSchema.properties[keys[i]] = { ...(schema.properties || {})[keys[i]] };
  }
  let newId = elementSchema.id || "";
  if ((schema.properties || {})[newId]) {
    // An element with the same id already exists
    let increment = 1;
    while ((schema.properties || {})[`${newId}_${increment}`]) {
      increment += 1;
    }
    newId = `${newId}_${increment}`;
  }
  NewSchema.properties[newId] = {
    ...elementSchema,
    ...{ id: newId },
  };
  for (; i < keys.length; i += 1) {
    // continue looping over properties
    NewSchema.properties[keys[i]] = { ...(schema.properties || {})[keys[i]] };
  }

  return NewSchema;
};

export const moveElement = (
  schema: Schema,
  elementId: string,
  destinationId: string,
  destinationIndex: number
) => {
  if (destinationId === null) {
    return schema;
  }

  // Start by cloning element and deleting from schema
  const path = elementId.split("/");
  if (!schema.properties) return schema;
  let current = schema;

  for (let i = 1; i < path.length - 1; i += 1) {
    current = (current.properties || {})[path[i]];
  }
  const elementSchema = cloneDeep(
    (current.properties || {})[path[path.length - 1]]
  ); // clone the element
  delete (current.properties || {})[path[path.length - 1]]; // delete from current location
  elementSchema.id = path[path.length - 1];
  return addElement(schema, elementSchema, destinationId, destinationIndex);
};

export const replaceSchemaElement = (
  schema: Schema,
  elementId: string,
  elementSchema: Schema
) => {
  // Start by cloning element and deleting from schema
  const path = elementId.split("/");
  if (!schema.properties) return schema;
  let current = schema;
  for (let i = 1; i < path.length - 1; i += 1) {
    current = (current.properties || {})[path[i]];
  }
  const parentProperties = current.properties || {};
  const oldId = path[path.length - 1];
  // const newId = elementSchema.id || "";
  const destinationIndex = Object.keys(parentProperties).indexOf(oldId);
  path.splice(-1, 1);
  const destinationId = path.join("/");
  delete (current.properties || {})[oldId]; // delete from current location

  return addElement(schema, elementSchema, destinationId, destinationIndex);
};

// Is valid schema
export const isValidSchema = (
  schema: Schema,
  checkContainsEmpty: boolean = false
) => {
  const result =
    has(schema, "type") &&
    has(schema, "properties") &&
    !isArray(schema.properties) &&
    isObject(schema.properties);

  if (checkContainsEmpty) return result && !isEmpty(schema.properties);

  return result;
};

// Schema in Glance
export const getSchemaGlance = (schema: Schema) => schemaWalker(schema);

// Compare schemas
export const schemaCompare = (
  upholdSchemaTree: {
    fields: { [string]: Schema },
    layouts: { [string]: Schema },
  },
  currentSchema: Schema
) => {
  const diff = {
    NEW: [],
    ADDED: [],
    MODIFIED: [],
    DELETED: [],
  };
  const { fields: updatedSchemaFieldsTree } = schemaWalker(currentSchema);

  // shortcut not to loop over inside mapped functions
  const existingFields = {};
  if (has(upholdSchemaTree, "fields")) {
    map(
      upholdSchemaTree.fields,
      (fieldSchema: Schema, fieldSchemaPath: string) => {
        if (has(fieldSchema, "fieldId"))
          if (!has(existingFields, fieldSchemaPath))
            assign(existingFields, { [fieldSchema.fieldId]: fieldSchemaPath });
      }
    );
  }

  const currentFields = {};
  map(
    updatedSchemaFieldsTree,
    (fieldSchema: Schema, fieldSchemaPath: string) => {
      if (has(fieldSchema, "fieldId"))
        if (!has(currentFields, fieldSchemaPath))
          assign(currentFields, { [fieldSchema.fieldId]: fieldSchemaPath });
    }
  );

  // walk through updated (new) field schema tree
  map(updatedSchemaFieldsTree, (fieldSchema /* , fieldSchemaPath */) => {
    // checkpoint: check attributeId, if starts with "new_", that's totally new field
    if (
      has(fieldSchema, "attributeId") &&
      startsWith(fieldSchema.attributeId, "new_")
    ) {
      diff.NEW.push(fieldSchema);
      return;
    }
    if (
      has(fieldSchema, "attributeId") &&
      !has(fieldSchema, "fieldId") &&
      !startsWith(fieldSchema.attributeId, "new_")
    ) {
      // Added an attribute that already exists
      diff.ADDED.push(fieldSchema);
      return;
    }

    // checkpoint: check fieldId, against upholdSchemaTree.fields
    // I'm not here checking feildSchema.attributeId whether it doesn't startsWith "new_" or not
    // Even in the case startsWith "new_", and if it doesn't have fieldId, consider it like newly added
    if (has(fieldSchema, "fieldId")) {
      if (
        indexOf(getObjectKeys(existingFields), `${fieldSchema.fieldId}`) === -1
      ) {
        diff.ADDED.push(fieldSchema);
      } else {
        // check for label props changes
        const difference = objectCompare(
          fieldSchema, // current Schema
          upholdSchemaTree.fields[existingFields[fieldSchema.fieldId]] // base Schema
        );

        if (!isEmpty(difference)) {
          // diff.MODIFIED.push({
          //   path: fieldSchemaPath,
          //   schema: fieldSchema,
          //   difference,
          // });
          diff.MODIFIED.push(fieldSchema);
        }
      }
    }
  });

  // map over upholdSchemaTree.fields
  map(existingFields, (existingPath, existingFieldId) => {
    if (indexOf(getObjectKeys(currentFields), existingFieldId) === -1) {
      diff.DELETED.push(
        upholdSchemaTree.fields[existingFields[existingFieldId]]
      );
    }
  });

  return diff;
};

export const removeSchema = (destinationId: string, schema: Schema) => {
  let isDeleted = false;
  let removedSchemaElement: Object = {};
  // let's say we've deleted whole section in artboard this creates newest empty "grid"
  if (destinationId === "@") {
    return {
      schema: {
        type: "grid",
        properties: {
          row1: {
            type: "row",
            properties: {
              col1: {
                type: "col",
                properties: {},
              },
            },
          },
        },
      },
      removedSchemaElement,
    };
  }
  // @TODO ??
  const newSchema = cloneDeep(schema);
  const propertiesLabel = "properties";
  let pathArray = [];

  map(destinationId.split("/"), destinationPart => {
    if (!isEmpty(destinationPart)) {
      pathArray.push(propertiesLabel);
      if (destinationPart !== "@") pathArray.push(destinationPart);
    }
  });

  pathArray = drop(pathArray);
  if (!isEmpty(pathArray) && hasIn(newSchema, pathArray)) {
    removedSchemaElement = get(newSchema, pathArray, {});
    isDeleted = unset(newSchema, pathArray);
  }

  if (isDeleted) {
    return { schema: newSchema, removedSchemaElement };
  }

  return { schema, removedSchemaElement };
};

export const extractAttributeIds = (schema: Schema) => {
  const tree = schemaWalker(schema);
  const attrIds = [];
  const relObjIds = [];
  const otherIds = [];
  Object.keys(tree.fields).forEach(path => {
    const field = tree.fields[path];

    // @deprecated the condition field.type.startsWith("relobj_"), is in the process of being replaced by newer condition field.type === "relatedObject".
    if (field.type === "relatedObject" || field.type.startsWith("relobj_")) {
      relObjIds.push(field.id);
    }

    if (field.attributeId) {
      if (typeof field.attributeId === "number") {
        attrIds.push(field.attributeId);
      } else if (field.attributeId.indexOf("new_") === -1) {
        attrIds.push(field.attributeId);
      }
      // if field has no attribute
    } else if (["lookup", "polymorphParent"].indexOf(field.type) !== -1) {
      attrIds.push(field.id);
    } else {
      otherIds.push(field.id);
    }
  });
  return { attrIds, relObjIds, otherIds };
};

export default {
  updateSchemaElement,
  getTypeFromId,
  moveElement,
  addElement,
  extractAttributeIds,
};
