import * as React from "react";

import styled from "styled-components";
import { keys } from "lodash";

import ConditionalDroppable from "../DragDrop/ConditionalDroppable";
import ElementsList from "./ElementsList";
import type { Props } from "./types";

import Parts from "../../definitions/parts";

const TabWrapper = styled.div(({ theme }) =>
  Object.assign(
    {},
    theme.TabWrapper ? { ...theme.TabWrapper } : {},
    // droppedCount === 0 ? { minHeight: "200px", height: "100%" } : {},
    // { height: "100px" }
    // childCount ? { minHeight: "200px" } : {}
    { minHeight: "200px" }
  )
);

// const Content = styled.div({
//   flexGrow: 1,
// });

const Tab = ({ schema, id }: Props) => (
  <ConditionalDroppable
    droppableId={id}
    excludeTypes={Parts.Tab.drop.excludeTypes}
    // allowTypes={Parts.Tab.drop.allowTypes}
    droppableWrapper="Tab"
  >
    <TabWrapper droppedCount={keys(schema.properties).length}>
      <ElementsList id={id} schema={schema} index={0} />
    </TabWrapper>
  </ConditionalDroppable>
);

// export default withTheme(Tab);
export default Tab;
