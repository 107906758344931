// @flow
import { map, has, hasIn } from "lodash";

// utils
import schemaWalker from "./schemaWalker";

// types
import { type Schema } from "../types";

export default (schema: Schema) => {
  const { fields } = schemaWalker(schema);
  const columns = [];
  map(fields, field => {
    if (
      has(field, "attributeName") &&
      has(field, "label") &&
      hasIn(field, ["options", "listed"])
    ) {
      columns.push({
        name: field.attributeName,
        title: field.label,
      });
    }
  });

  return columns;
};
