export default {
  settings: {
    MaxCols: 4,
  },
  drop: {
    allowTypes: ["col"],
  },
  options: {
    type: "row",
    defaultProperties: {
      col1: {
        type: "col",
        properties: {},
      },
    },
  },
};
