import React from "react";
import { type Location, withRouter } from "react-router-dom";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { AuthorizedRoute } from "@swan/auth";

type Props = {
  component: any,
  render?: Function,
  loggedIn: boolean,
  location: Location
};

function AuthenticatedRoute({
  component: Component,
  render,
  loggedIn,
  location,
  ...rest
}: Props) {
  return (
    <AuthorizedRoute
      {...rest}
      render={props =>
        loggedIn === true ? (
          render ? (
            render(props)
          ) : (
            <Component {...props} />
          )
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: {
                from: location.pathname === "/logout" ? null : location.pathname
              }
            }}
          />
        )
      }
    />
  );
}

function mapStateToProps(state) {
  return {
    loggedIn: state.user.loggedIn
  };
}

export default withRouter(connect(mapStateToProps)(AuthenticatedRoute));
