import config from "@swan/config"; // eslint-disable-line
import Axios from "axios";
import { SwanContextManager } from "@swan/state";

class Service {
  static prefix = "";

  prefix: string;

  api: any;

  timeout = 60000;

  constructor(prefix: ?string) {
    this.prefix = prefix || "";
  }

  getApi() {
    const headers = Object.assign(
      {},
      {
        "Content-Type": "application/json",
        Accept: "application/json",
      }
    );
    const superadminMode = SwanContextManager.getValue("superadminModeEnabled");
    if (superadminMode === true) {
      headers["x-swan-superadmin"] = 1;
    }
    if (this.api) {
      this.api.headers = headers;
      return Promise.resolve(this.api);
    }
    this.api = Axios.create({
      timeout: this.timeout,
      baseURL: config.apiBaseURL,
      headers,
      defaultInterceptors: true,
    });
    this.api.interceptors.response = Axios.interceptors.response;
    return Promise.resolve(this.api);
  }

  getUrl(route: string = "") {
    return `${this.prefix}${route ? (this.prefix ? "/" : "") + route : ""}`;
  }

  getAbsoluteUrl(route: string = "") {
    return `${config.apiBaseURL}${this.prefix}${
      route ? (this.prefix ? "/" : "") + route : ""
    }`;
  }

  post(route: string = "", data: {}) {
    return this.getApi()
      .then(api => api.post(this.getUrl(route), data))
      .then(response => response.data)
      .catch(error => this.constructor.handleError(error));
  }

  /**
   * used to make sure circular calls in json doesn't happen, now it filter property with name parent
   * TODO change parent to something else like e.g : __parent to avoid removing attribute with name parent
   * @param data
   * @return {string}
   */
  // static stringifyData(data: any) {
  //   const cache = [];
  //   return JSON.stringify(data, (name, value) => {
  //     if (typeof value === "object" && value !== null) {
  //       if (cache.indexOf(value) !== -1) {
  //         console.log(value);
  //         return undefined;
  //       }
  //       cache.push(value);
  //     }
  //     return value;
  //   });
  // }

  /**
   * Send a delete request
   */
  deleteRequest(route: string = "", id?: number) {
    return this.getApi()
      .then(api => api.delete(this.getUrl(route), { id }))
      .then(response => response.data)
      .catch(error => this.constructor.handleError(error));
  }

  /**
   * Send a put request
   */
  put(route: string, data: {} = {}) {
    return this.getApi()
      .then(api => api.put(this.getUrl(route), data))
      .then(response => response.data)
      .catch(error => this.constructor.handleError(error));
  }

  /**
   * Send a get request
   */
  get(route: string, params: {} = {}) {
    return this.getApi()
      .then(api =>
        api.get(this.getUrl(route), {
          params,
        })
      )
      .then(response => response.data)
      .catch(error => this.constructor.handleError(error));
  }

  /**
   * Upload files
   */
  upload(route: string, files: { [string]: any }, params: {} = {}) {
    const url = this.getUrl(route);
    const formData = new FormData();
    Object.keys(files).map(fileName =>
      formData.append(fileName, files[fileName])
    );
    Object.keys(params).map(fieldName =>
      formData.append(fieldName, params[fieldName])
    );

    const cfg = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };
    return this.getApi().then(api => api.post(url, formData, cfg));
  }

  /**
   * Send a get request and output to file
   */
  download(
    route: string,
    data: Object = {},
    afterDownload: Function = () => {},
    onErrorHandler: Function = () => {}
  ) {
    return this.getApi().then(api => {
      api
        .post(this.getUrl(route), data, {
          responseType: "arraybuffer",
        })
        .then(response => {
          Service.handleBlobResponse(response);
          afterDownload();
        })
        .catch(error => {
          onErrorHandler(error);
          return this.constructor.handleError(error);
        });
    });
  }

  /**
   * Send a get request and output to file
   */
  downloadFileWithGet(route: string) {
    return this.getApi().then(api => {
      api
        .get(this.getUrl(route), {
          responseType: "arraybuffer",
        })
        .then(response => Service.handleBlobResponse(response))
        .catch(error => this.constructor.handleError(error));
    });
  }

  static handleBlobResponse(response: any) {
    const blob = new Blob([response.data], {
      type: response.headers["x-filetype"],
    });
    if (typeof window.navigator.msSaveBlob !== "undefined") {
      // IE workaround for "HTML7007: One or more blob URLs were
      // revoked by closing the blob for which they were created.
      // These URLs will no longer resolve as the data backing
      // the URL has been freed."
      window.navigator.msSaveBlob(blob, response.headers["x-filename"]);
      return true;
    }
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", response.headers["x-filename"]);
    if (document.body) {
      document.body.appendChild(link);
    }
    link.click();
    setTimeout(() => document.body && document.body.removeChild(link), 500);
    return true;
  }

  /**
   * Handle request errors
   */
  static handleError(error: {
    response?: {
      data: {
        message: string | Object,
        errors?: string | Object,
      },
      status: number,
    },
  }) {
    let errorMessage = "";
    if (
      (error.response && error.response.data.message) ||
      (error.response && error.response.data.errors)
    ) {
      let messagesList;
      if (error.response.data.errors) {
        messagesList = error.response.data.errors;
      } else {
        messagesList = error.response.data.message;
      }
      if (typeof messagesList === "string") {
        errorMessage = messagesList;
      } else {
        const errorsAttribute = Object.keys(messagesList);
        for (let i = 0; i < errorsAttribute.length; i += 1) {
          errorMessage = `${errorMessage} ${errorsAttribute[i]}: ${
            messagesList[errorsAttribute[i]]
          } </br>`;
        }
      }
    } else {
      errorMessage = "Server error";
    }
    const result = new Error(errorMessage);
    // $FlowFixMe
    result.statusCode = error.response && error.response.status;
    return Promise.reject(result);
  }
}

export default Service;
