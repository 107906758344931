import React from "react";
import { FormGroup, Input, Label } from "reactstrap";
import FieldWrapper, { type FieldProps } from "./partials/FieldWrapper";

// utils
import getUniqueOptions from "../../utils/getUniqueOptions";

export type InputProps = FieldProps & {
  placeholder?: string,
  itemLabelAccessor?: Object => string,
  itemIdAccessor?: Object => string,
  multiple?: boolean,
  data?: Array<{
    id: string | number,
    label: string,
  }>,
};

const Field = ({
  name,
  value,
  // placeholder,
  // label,
  // multiple,
  required,
  onChange,
  className,
  data,
  error,
  itemLabelAccessor,
  itemIdAccessor,
}: // children,
// style,
InputProps) => (
  <div
    {...(required ? { "data-required": true } : {})}
    className={`form-control ${className || ""} ${
      error !== "" ? "is-invalid" : ""
    }`}
  >
    {getUniqueOptions(data, itemIdAccessor, itemLabelAccessor).map(item => (
      <FormGroup check className="form-radio" key={`${name}-${item.id}`}>
        <Input
          name={name}
          type="radio"
          id={`${name}_${item.id}`}
          required={required || false}
          value={item.id}
          checked={value === item.id}
          onChange={onChange}
        />
        <Label check for={`${name}_${item.id}`}>
          <span className="radio-appearance" />
          {item.label}
        </Label>
      </FormGroup>
    ))}
  </div>
);

export default FieldWrapper(Field);
