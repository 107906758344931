import React from "react";
import { schemaDataSource } from "@swan/form-designer";
import SearchableDropdown from "./SearchableDropdown";
import { type FieldProps } from "./partials/FieldWrapper";

export type InputProps = FieldProps & {
  placeholder?: string,
};

const Field = (props: InputProps) => {
  const data =
    (schemaDataSource.find(d => d.value === "CURRENCY") || {}).data || [];
  const itemLabelAccessor = item => item.name;
  const itemIdAccessor = item => item.value;
  const { name } = props;
  return (
    <SearchableDropdown
      name={name}
      data={data}
      itemLabelAccessor={itemLabelAccessor}
      itemIdAccessor={itemIdAccessor}
      {...props}
    />
  );
};

export default Field;
