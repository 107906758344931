// @flow

// 1. We recieve date value as ISO string or Date object

// 2. Moment tz guess the zone and convert to Date Object
// -> use is inside the component

// 3. On change convert it back to ISO string using moment .format()
// -> the final value sent to the server  or updated values get passed back to component as value

import React, { Component } from "react";
import DatePicker from "react-datepicker";
import moment from "moment-timezone";
import FieldWrapper, { type FieldProps } from "./partials/FieldWrapper";

export type InputProps = FieldProps & {
  placeholder?: string,
  type?: "date" | "datetime" | "time",
  dateFormat: string,
  showInModal: boolean,
  onClickBackDrop?: Function,
  isControlled?: boolean,
  hidePastDates?: boolean,
  preventDefaultValue?: boolean,
  startDate?: any, // @TODO need to correct the type
  endDate?: any, // @TODO need to correct the type
  minDate?: any, // @TODO need to correct the type
  maxDate?: any, // @TODO need to correct the type
};

type State = {
  selected: any,
};

class Field extends Component<InputProps, State> {
  static defaultProps = {
    type: "date",
    showInModal: false,
    onClickBackDrop: undefined,
    isControlled: false,
    hidePastDates: false,
    preventDefaultValue: false,
    startDate: undefined,
    endDate: undefined,
    minDate: undefined,
  };

  isoDateFormatString: string = "YYYY-MM-DDThh:mm:ssZ";

  // react-datepicker 1.8 onwards, date-fns is replaced in place of momentjs
  // starting with date-fns v2, format/parses uses unicode tokens
  // https://www.unicode.org/reports/tr35/tr35-dates.html#Date_Field_Symbol_Table
  tokenReplacer: { [string]: string } = {
    YYYY: "yyyy",
    YY: "yy",
    D: "d",
    DD: "dd",
  };

  constructor(props: any) {
    super(props);
    this.state = {
      selected: new Date(),
    };
  }

  componentDidMount() {
    const { value } = this.props;

    let date = value;
    if (typeof date === "number") {
      date = String(date);
    }
    this.setState({
      selected: this.getDate(date),
    });
  }

  getCurrentTimeZone = () => moment.tz.guess();

  getDate = (d?: number | string | Date) => {
    const { preventDefaultValue } = this.props;
    let date;
    // accept Date object
    if (d && typeof d === "object" && d.constructor === Date) {
      date = d;
    }
    // otherwise, we accept only iso string format as above
    if (typeof d === "number") date = String(d);
    if (typeof d === "string") {
      if (
        moment(d, this.isoDateFormatString).format(this.isoDateFormatString) ===
        date
      ) {
        // string is in iso format
        date = moment(d, this.isoDateFormatString);
      } else {
        date = moment.utc(d);
      }
      date = date.isValid() ? date.toDate() : new Date();
    }

    if (date) {
      return moment(date)
        .tz(this.getCurrentTimeZone())
        .toDate();
    }

    if (preventDefaultValue) return null;
    return new Date();
  };

  handleChange = (date: Date) => {
    const { onChange } = this.props;
    this.setState(
      {
        selected: date,
      },
      () => {
        const isoDate = moment(date).format();
        return onChange && onChange({ target: { value: isoDate } });
      }
    );
  };

  // handleOnBlur = e => {
  //   console.log(e);
  // };

  // util field:-
  // if otherwise, throwProtectedError is thrown
  formatUnicodeTokenParser = (format: string) => {
    if (!format) {
      return null;
    }
    return format.replace(/DDDo|DDD|Do|DDDD|D|DD|YYYY|YY/g, (match: string) => {
      if (["DDDo", "DDD", "Do", "DDDD"].indexOf(match) !== -1) return match;
      return this.tokenReplacer[match];
    });
  };

  getDateFormat = (dateFormat: string) =>
    this.formatUnicodeTokenParser(dateFormat);

  render() {
    const {
      type,
      name,
      value,
      dateFormat,
      onChange,
      className,
      error,
      showInModal,
      onClickBackDrop,
      isControlled,
      hidePastDates,
      preventDefaultValue,
      required,
      startDate,
      endDate,
      minDate,
      maxDate,
      placeholder,
      ...restProps
    } = this.props;
    const { selected } = this.state;

    return (
      <div
        className="form-control control-inside"
        {...(required ? { "data-required": true } : {})}
      >
        <DatePicker
          name={name}
          placeholderText={
            preventDefaultValue
              ? placeholder || `Select ${type || ""}`
              : undefined
          }
          selected={isControlled ? selected : this.getDate(value)}
          startDate={startDate ? this.getDate(startDate) : undefined}
          endDate={endDate ? this.getDate(endDate) : undefined}
          minDate={minDate ? this.getDate(minDate) : undefined}
          maxDate={maxDate ? this.getDate(maxDate) : undefined}
          showTimeSelect={type === "datetime"}
          showTimeSelectOnly={type === "time"}
          showYearDropdown
          showMonthDropdown
          dateFormat={
            this.getDateFormat(dateFormat) ||
            (type === "date" && "dd/MM/yyyy") ||
            (type === "datetime" && "dd/MM/yyyy hh:mm a") ||
            "dd/MM/yyyy"
          }
          onChange={this.handleChange}
          shouldCloseOnSelect
          // isClearable
          // onBlur={this.handleOnBlur}
          withPortal={showInModal}
          inline={showInModal}
          onClickOutside={onClickBackDrop}
          calendarClassName="swan-datepicker-calendar"
          popperClassName="swan-datepicker-popper"
          className={`swan-datepicker-input form-control ${
            error !== "" ? "is-invalid" : ""
          } ${type || "datetime"}--picker ${className || ""}`}
          {...(hidePastDates ? { minDate: moment().toDate() } : {})}
          {...restProps}
        />
      </div>
    );
  }
}

export default FieldWrapper(Field);
