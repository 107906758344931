import React from "react";
import styled from "styled-components";
import moment from "moment";

const getItemColor = (props: any) => (props.active ? "#4caf50" : "#CBD5E1");

const TimelineWrapper = styled.ul`
  list-style-type: none;
  position: relative;

  &:before {
    content: " ";
    background: #d4d9df;
    display: inline-block;
    position: absolute;
    left: 29px;
    width: 2px;
    height: 100%;
    z-index: 400;
  }
`;

const Item = styled.li`
  margin: 20px 0 30px 30px;
  padding-left: 20px;

  &:before {
    content: " ";
    background: white;
    display: inline-block;
    position: absolute;
    border-radius: 50%;
    border: ${(props: any) => (props.sub ? "2px" : "3px")} solid
      ${props => getItemColor(props)};
    left: ${(props: any) => (props.sub ? "25px" : "20px")};
    width: ${(props: any) => (props.sub ? "10px" : "20px")};
    height: ${(props: any) => (props.sub ? "10px" : "20px")};
    z-index: 400;
  }
`;

const ItemTitle = styled.span`
  padding: 0.5rem 0.75rem;
  border-radius: 0.25rem;
  background-color: ${(props: any) => getItemColor(props)};
  color: ${(props: any) => (props.active ? "#fff" : "#333")};
  font-size: ${(props: any) => (props.sub ? ".8rem" : "1.125rem")};
  font-style: normal;
`;

export const ItemDate = styled.span`
  padding: 0.25rem 0.5rem;
  border-radius: 0.25rem;
  float: right;
  background-color: #cbd5e1;
  font-size: 0.75rem;
  font-style: normal;
`;

const Timeline = ({ data, title }: any) => (
  <>
    <h2>{title}</h2>
    <div>
      <TimelineWrapper>
        {data.map(item => (
          <Item key={item.id} active={item.completed || item.is_current}>
            <ItemTitle active={item.completed || item.is_current}>
              {item.name}
            </ItemTitle>
            {item.completed && (
              <ItemDate>
                {moment(item.end_date || "").format("YYYY-MM-DD")}
              </ItemDate>
            )}
          </Item>
        ))}
      </TimelineWrapper>
    </div>
  </>
);

export default Timeline;
