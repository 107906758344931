export default {
  loadWorkflowTimeline: (
    object: string,
    id: number,
    workflowId: number,
    forceLoad: boolean = false
  ) => ({
    type: "LOAD_WORKFLOW_TIMELINE",
    object,
    id,
    workflowId,
    forceLoad,
  }),
  loadWorkflowStats: (
    object: string,
    id: number,
    workflowId: number,
    forceLoad: boolean = false
  ) => ({
    type: "LOAD_WORKFLOW_STATS",
    object,
    id,
    workflowId,
    forceLoad,
  }),
  subscribeWorkflowTimeline: (
    object: string,
    id: number,
    workflowId: number,
    forceLoad: boolean = false
  ) => ({
    type: "SUBSCRIBE_WORKFLOW_TIMELINE",
    object,
    id,
    workflowId,
    forceLoad,
  }),
  unsubscribeWorkflowTimeline: (
    object: string,
    id: number,
    workflowId: number,
    forceLoad: boolean = false
  ) => ({
    type: "UNSUBSCRIBE_WORKFLOW_TIMELINE",
    object,
    id,
    workflowId,
    forceLoad,
  }),
};
