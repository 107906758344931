import * as React from "react";
import Elements from "./index";

import type { Props } from "./types";
import type { Schema } from "../../constants/flowTypes";

type ElementsListProps = Props & {
  renderType?: string,
};

class ElementsList extends React.Component<ElementsListProps> {
  static defaultProps = {
    renderType: undefined,
  };

  static toJson() {
    return "";
  }

  getElements = (elements: { [string]: Schema }): any => {
    const { id, renderType } = this.props;
    if (!elements) return [];
    return Object.keys(elements).map(
      (elemName: string, index: number) =>
        (elements[elemName].type === renderType || !renderType) && (
          <Elements.Element
            index={index}
            key={`${id}/${elemName}`}
            id={`${id}/${elemName}`}
            schema={elements[elemName]}
          />
        )
    );
  };

  render() {
    const { schema } = this.props;
    if (schema.properties) {
      return (
        <React.Fragment>{this.getElements(schema.properties)}</React.Fragment>
      );
    }
    return null;
  }
}

export default ElementsList;
