/* eslint-disable no-bitwise */
import * as React from "react";
import withAuth from "./withAuth";
import { type AuthContextShape } from "../types";

type Props = {
  disableRecordPermission?: boolean,
  record: Object,
  object?: string, // In case the object type is provided
  auth: AuthContextShape,
};

type State = {
  loadedPermissions?: Object,
};

const withRecordPermission = (Form: any) => {
  // const VIEW_BIT = 1;
  const EDIT_BIT = 2;
  const DELETE_BIT = 4;

  class WrappedForm extends React.Component<Props, State> {
    static defaultProps = {
      disableRecordPermission: false,
      object: undefined,
    };

    state = {
      loadedPermissions: undefined,
    };

    componentDidMount() {
      this.preloadPermissions();
    }

    permissionEnabled = () => {
      const { record, disableRecordPermission } = this.props;
      if (disableRecordPermission) return false;
      if (record) {
        if (record.permission) return true;
        return false;
      }
      return true;
    };

    isEditAllowed = () => {
      if (!this.permissionEnabled()) {
        return true;
      }
      const { record, object } = this.props;
      if (record) {
        if ((record.permission & EDIT_BIT) !== EDIT_BIT) {
          return false;
        }
      }
      const { loadedPermissions } = this.state;
      if (!object) {
        return true;
      }
      return loadedPermissions
        ? loadedPermissions[`${object}:edit`] === true
        : false;
    };

    isDeleteAllowed = () => {
      if (!this.permissionEnabled()) {
        return true;
      }
      const { record, object } = this.props;
      if (record) {
        if ((record.permission & DELETE_BIT) !== DELETE_BIT) {
          return false;
        }
      }
      const { loadedPermissions } = this.state;
      if (!object) {
        return true;
      }
      return loadedPermissions
        ? loadedPermissions[`${object}:delete`] === true
        : false;
    };

    isAddAllowed = () => {
      if (!this.permissionEnabled()) {
        return true;
      }
      const { object } = this.props;
      if (!object) {
        // The object type was not specified
        return true;
      }
      const { loadedPermissions } = this.state;
      return loadedPermissions
        ? loadedPermissions[`${object}:create`] === true
        : false;
    };

    preloadPermissions() {
      const { object, auth } = this.props;
      if (!object) return;
      const permissions = [
        `${object}:create`,
        `${object}:edit`,
        `${object}:view`,
        `${object}:delete`,
      ];
      auth.getPermissions(permissions).then(perms => {
        this.setState({
          loadedPermissions: perms,
        });
      });
    }

    render() {
      const { loadedPermissions } = this.state;
      const { object } = this.props;
      if (object && !loadedPermissions) return null;
      return (
        <Form
          {...this.props}
          isEditAllowed={this.isEditAllowed()}
          isDeleteAllowed={this.isDeleteAllowed()}
          isAddAllowed={this.isAddAllowed()}
        />
      );
    }
  }

  return withAuth(WrappedForm);
};

export default withRecordPermission;
/* eslint-enable no-bitwise */
