// @flow
/* eslint-disable array-callback-return */
// import { has, hasIn, cloneDeep } from "lodash";
import {
  // ruleEvaluator,
  actionsDictionary,
  provideActions,
} from "@swan/object-conditions"; // eslint-disable-line
// import { SchemaWalker } from "@swan/form-designer/utils";

// types
// import { type Schema } from "@swan/form-designer/src/types";
import { type Rules } from "../types";

export default ({
  rules,
  data,
  updateData,
}: {
  rules: Rules,
  data: Object,
  updateData: Function,
}) => {
  const { layout: layoutRules } = rules;
  if (layoutRules) {
    const resultedActions = provideActions(layoutRules, {
      record: data,
    });
    if (resultedActions && resultedActions.length) {
      resultedActions.map((action: Object) => {
        const { action: actionFunction } = actionsDictionary.find(
          dictionaryItem => dictionaryItem.id === action.id
        );
        actionFunction(
          {
            updateData,
            data: action.data, // action data
          },
          { record: data }
        );
      });
    }
  }

  return true;
};
