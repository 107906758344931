import React from "react";

// components
import ResourceWidgets from "./ResourceWidgets";

// utils
import processResource from "../utils/processResource";
import provideResourceTemplate from "../utils/provideResourceTemplate";

import { type Resource } from "../flowTypes";

type Props = {
  object: ?string,
  resource?: Resource,
  onChange?: Function,
};

const Renderer = (props: Props) => {
  const { object, onChange, resource, ...restProps } = props;
  let prepareResource: Object = resource;
  // get default
  if (!prepareResource) {
    prepareResource = provideResourceTemplate();
  }
  // for attributes
  if (
    prepareResource.type &&
    prepareResource.type === "ATTRIBUTE" &&
    prepareResource.data &&
    !Object.keys(prepareResource.data).length
  ) {
    prepareResource = { ...prepareResource, ...{ data: {} } };
  }

  const resourceProcessed = processResource(prepareResource);
  const Widget = ResourceWidgets[resourceProcessed.widget || ""];
  if (Widget) {
    return (
      <Widget
        {...resourceProcessed}
        {...{ object, onChange, resource, ...restProps }}
      />
    );
  }

  return null;
};

Renderer.defaultProps = {
  // object: undefined,
  resource: provideResourceTemplate(),
  onChange: undefined,
};

export default Renderer;
