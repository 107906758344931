import React from "react";
import { Bar } from "react-chartjs-2";
import styled from "styled-components";

import { Card, CardBody, CardHeader } from "reactstrap";
import { type DatasetType } from "./types";

export type BarType = {
  datasets: Array<DatasetType>,
  labels: Array<string>,
};

// types
type Props = {
  data: BarType,
  title: string,
  exportButton?: any,
};

const Wrapper = styled("div")`
  width: 100%;
`;

const options = {
  scales: {
    xAxes: [
      {
        stacked: true,
      },
    ],
  },
};

const Widget = (props: Props) => {
  const { data, title, exportButton } = props;

  return (
    <Wrapper>
      <Card>
        <CardHeader
          tag="h3"
          className="text-white brand-01--bg chart-header d-flex justify-content-between align-items-center"
        >
          {title}
          {exportButton && exportButton}
        </CardHeader>
        <CardBody className="chart-body">
          <Bar minBarLength={200} data={data} options={options} />
        </CardBody>
      </Card>
    </Wrapper>
  );
};

Widget.defaultProps = {
  exportButton: undefined,
};

export default Widget;
