// @flow
/* eslint-disable no-new-func */
export default ({ body }: { body?: string } = {}, data?: Object = {}): any => {
  let result = "";
  try {
    const funcBody = body || "";
    const evaluator: Function = new Function("data", funcBody);
    result = evaluator(data);
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e.message);
    result = "Error in evaluating";
  }
  return result;
};
