import reducer from "./reducer";
import sagas from "./sagas";
import * as actions from "./actions";
import type { UserState } from "./reducer";

export default reducer;

export const userSagas = sagas;
export const userActions = actions;
export type State = UserState;
