import * as React from "react";

import styled from "styled-components";

import ElementsList from "./ElementsList";
import type { ElementProps } from "./types";

const TabWrapper = styled.div(({ theme }) =>
  Object.assign(
    {},
    theme.TabWrapper ? { ...theme.TabWrapper } : {}
    // droppedCount === 0 ? { minHeight: "200px", height: "100%" } : {},
    // { height: "100px" }
    // childCount ? { minHeight: "200px" } : {}
    // { minHeight: "300px" }
  )
);

// const Content = styled.div({
//   flexGrow: 1,
// });

const Tab = ({ schema, id }: ElementProps) => (
  <TabWrapper>
    <ElementsList id={id} schema={schema} index={0} />
  </TabWrapper>
);

// export default withTheme(Tab);
export default Tab;
