import * as TestingComponents from "./Testing";
import * as HocComponents from "./HOC";

export const Testing = TestingComponents;
export const HOC = HocComponents;

export {
  default as RecordPermissionList,
} from "./RecordPermission/RecordPermissionList";

export {
  default as ChangeOwnershipForm,
} from "./Ownership/ChangeOwnershipForm";

export { default as PolicyAdmin } from "./PolicyAdmin";

export {
  AppendBreadCrumbButtons,
} from "./UI/Components/Breadcrumbs/BreadcrumbButtons";

export { default as Paginator } from "./UI/Components/Paginator";

export default {
  Testing,
  HOC,
};
