// @flow
import { isObject, isArray, forOwn } from "lodash";

const objectIterator = (
  obj,
  path,
  nestedLevel,
  parent,
  parentKey,
  parentPath,
  callback
) => {
  if (!isObject(obj)) return;
  forOwn(obj, (value, key) => {
    const objectKey = key;
    if (isArray(obj)) {
      if (value === undefined && !(key in obj)) return;
    }

    const currentPath = path.concat([key]);
    const res = callback(
      value,
      objectKey,
      currentPath,
      nestedLevel,
      obj,
      parentKey,
      path
    );
    if (res !== false && isObject(value)) {
      objectIterator(
        value,
        currentPath,
        nestedLevel + 1,
        obj,
        objectKey,
        path,
        callback
      );
    }
  });
};

export default (
  obj: {},
  callback: (
    value: any,
    objectKey: string,
    currentPath: any, // @todo
    nestedLevel: number,
    obj: {},
    parentKey: string,
    path: any // @todo
  ) => any
) => {
  objectIterator(obj, [], 0, null, "", [], callback);
  return obj;
};
