import * as React from "react";
import { Input } from "@swan/themer";

import Field from "./partials/Field";
import type { ElementProps } from "./types";

const NumberField = ({
  schema,
  onChange,
  error,
  data,
  readOnly,
  hideLabel,
}: ElementProps) => {
  let min = Number.parseInt((schema.options || {}).minNumber, 10);
  let max = Number.parseInt((schema.options || {}).maxNumber, 10);
  if (Number.isNaN(min)) {
    min = (schema.options || {}).disableNegative === true ? 0 : undefined;
  }
  if (Number.isNaN(max)) max = undefined;

  return (
    <Input
      {...schema.options || {}}
      type="number"
      name={schema.attributeName || ""}
      value={(data || {})[schema.attributeName || ""]}
      label={schema.label}
      hideLabel={hideLabel || (schema.options || {}).hideLabel}
      placeholder={schema.label}
      onChange={e =>
        onChange && onChange(schema.attributeName || "", e.target.value)
      }
      error={(error || {})[schema.attributeName || ""]}
      static={readOnly || (schema.options || {}).readOnly}
      min={min}
      max={max}
    />
  );
};

export default Field(NumberField);
