import React from "react";
import FieldWrapper, { type FieldProps } from "./partials/FieldWrapper";

export type InputProps = FieldProps & {
  placeholder?: string,
  type?: string,
  getRef?: (ref: Object) => void,
  rows?: number,
};
/* eslint-disable */
const Field = ({ value }: InputProps) => (
  <div
    className="form-control"
    style={{ height: "100%" }}
    dangerouslySetInnerHTML={{ __html: value }}
  />
);

export default FieldWrapper(Field);
