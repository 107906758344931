import React, { type ComponentType } from "react";
import Styled from "styled-components";

import GlobalNavItem from "./components/GlobalNavItem";

import { LayoutSubscriber } from "./context/layout";

import { GLOBAL_NAV_WIDTH, GLOBAL_NAV_COLOR } from "./config";

type Props = {
  size?: number,
  primaryItems: Array<Object>,
  itemRenderer?: ComponentType<any>,
};

const Wrapper = Styled.div`
  height: 100vh;
  order: -2;
  position: fixed;
  bottom: 0;
  left: 0;
  top: 0;
  z-index: 2000;
  display: flex;
`;

const Spacer = Styled.div`
  background: linear-gradient(to left, rgba(0, 0, 0, 0.2) 0px, rgba(0, 0, 0, 0.2) 1px, rgba(0, 0, 0, 0.1) 1px, rgba(0, 0, 0, 0) 100%);
  bottom: 0px;
  left: -12px;
  opacity: 1;
  pointer-events: none;
  position: absolute;
  top: 0px;
  transition-duration: 0.22s;
  transition-property: left, opacity, width;
  transition-timing-function: cubic-bezier(0.2, 0, 0, 1);
  width: 12px;
`;

const Container = Styled.div`
    align-items: center;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    justify-content: space-between;
    padding-bottom: calc(20px);
    padding-top: 1rem;
    transition: background-color 0.3s cubic-bezier(0.2, 0, 0, 1) 0s, color 0.3s cubic-bezier(0.2, 0, 0, 1) 0s;
    width: ${(props: any) => props.size}px;
    height: 100%;
    background-color: ${(props: any) =>
      props.backgroundColor || GLOBAL_NAV_COLOR};
    color: #fff;
    fill:  ${(props: any) => props.backgroundColor || GLOBAL_NAV_COLOR};
`;

const PrimaryItems = Styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  box-pack: justify;
  box-align: center;
  justify-content: space-between;
  width: 100%;
  padding-bottom: 1rem;

  &>div:first-child {
    padding-bottom: 1.5rem;
  }
}
`;

const SecondaryItems = Styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  padding-top: 0.5rem;
  box-align: center;
  box-pack: justify;
}
`;

const GlobalNavigation = ({
  size,
  primaryItems,
  itemRenderer: ItemRenderer,
}: Props) => (
  <LayoutSubscriber>
    {({
      toggleProductNavigation,
      isProductNavigationOpen,
      openProductNavigation,
      activeMenu,
    }) => (
      <Wrapper
        className={`global-navigation ${
          !isProductNavigationOpen ? "global-navigation-disabled" : ""
        }`}
      >
        <Spacer />
        <Container size={size}>
          <PrimaryItems>
            {(primaryItems || []).map((item: any) => {
              const { id } = item;

              const clickHandler = () => {
                if (isProductNavigationOpen && id === activeMenu) {
                  toggleProductNavigation(id);
                  return;
                }

                openProductNavigation(id);
              };

              if (ItemRenderer) {
                return <ItemRenderer {...item} onClick={clickHandler} />;
              }
              return <GlobalNavItem {...item} onClick={clickHandler} />;
            })}
          </PrimaryItems>

          <SecondaryItems />
        </Container>
      </Wrapper>
    )}
  </LayoutSubscriber>
);

GlobalNavigation.defaultProps = {
  size: GLOBAL_NAV_WIDTH,
  itemRenderer: undefined,
};

export default GlobalNavigation;
