import React, { useEffect, useState } from "react";
import { Card, Loading } from "@swan/themer";
import styled from "styled-components";
import ChartService from "../services/charts";
import ChartFactory from "./ChartFactory";

const ReloadButton = styled.button`
  display: inline-block;
  border: none;
  background: transparent;
  outline: none;
  color: #1f4083;
  :hover {
    cursor: pointer;
  }
  :focus {
    outline: none;
  }
`;

type Props = {
  chartMeta: any,
};
const Chart = (props: Props) => {
  const [chart, setChart] = useState(undefined);
  const [isLoading, setIsLoading] = useState(false);
  const [isFailed, setIsFailed] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const { chartMeta } = props;

  const truncateString = string => {
    if (string.length > 20) {
      return `${string.substring(0, 20)}...`;
    }

    return string;
  };

  const loadChart = () => {
    setIsLoading(true);
    setIsFailed(false);

    const chartService = new ChartService();
    chartService
      .get(`generate-chart/${chartMeta.id}`)
      .then(response => {
        setIsLoading(false);
        if (Array.isArray(response) && response.length > 0) {
          setChart(response[0]);
        } else {
          setChart(response);
        }
      })
      .catch(e => {
        // eslint-disable-next-line no-console
        console.error(e);
        setIsLoading(false);
        setIsFailed(true);
        setErrorMessage(e.message);
      });
  };

  useEffect(() => {
    loadChart();
  }, []);
  return (
    <>
      {isLoading && <Loading />}
      {isFailed && (
        <Card className="h-100">
          <div className="h-100 d-flex justify-content-center align-items-center">
            <p className="text-03--color font-weight-bold">
              Ops! Something went wrong {truncateString(errorMessage)}
              <ReloadButton onClick={() => loadChart()}>Reload</ReloadButton>
            </p>
          </div>
        </Card>
      )}
      {chart && <ChartFactory chart={chartMeta} data={chart} />}
    </>
  );
};

export default Chart;
