import React, { Component } from "react";
import LoadingTopBar from "react-top-loading-bar";

type Props = {
  initAutoLoading: number,
  completed?: boolean,
  color?: string,
  height?: number,
};

type State = {
  progress: number,
};

export default class SwanTopLoadingBar extends Component<Props, State> {
  static defaultProps = {
    completed: false,
    color: "#4285F4",
    height: 5,
  };

  constructor(props: Props) {
    super(props);
    this.state = {
      progress: 0,
    };
  }

  componentDidMount() {
    const { initAutoLoading } = this.props;
    this.setState({ progress: 0 });
    if (initAutoLoading) {
      this.LoadingBar.continuousStart(initAutoLoading);
    }
  }

  componentDidUpdate(prevProps: Props) {
    const { completed } = this.props;
    if (completed === true && !prevProps.completed) {
      this.LoadingBar.complete();
    }
  }

  setRef = (ref: any) => {
    this.LoadingBar = ref;
  };

  getColours = (completedStatus: number) => {
    switch (true) {
      case completedStatus < 31:
        return "#4285F4";
      case completedStatus < 91:
        return "#FBBC05";
      case completedStatus <= 100:
        return "#34A853";
      default:
        return "#EA4335";
    }
  };

  onProgressChange = (progress: number) => {
    const { progress: progressState } = this.state;
    const { completed } = this.props;
    if (completed) {
      this.setState({
        progress: 100,
      });
      return;
    }
    if (progress && progressState < progress && progress < 100) {
      this.setState({
        progress,
      });
    }
  };

  LoadingBar: any;

  render() {
    const { color, height } = this.props;
    const { progress } = this.state;
    return (
      <LoadingTopBar
        onRef={this.setRef}
        color={this.getColours(progress) || color}
        {...{
          height,
        }}
        onProgressChange={this.onProgressChange}
        progress={progress > 100 ? 0 : progress}
      />
    );
  }
}
