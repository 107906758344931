import AJV from "ajv";

const validator = (data: Object, validationSchema: Object) => {
  const ajv = new AJV({
    coerceTypes: true,
    validateSchema: false,
  });
  const required = [];
  Object.keys(validationSchema.properties).forEach(attr => {
    if (validationSchema.properties[attr].required) {
      required.push(attr);
    }
  });
  const schema = {
    ...validationSchema,
    type: "object",
    required,
  };
  const validate = ajv.compile(schema);
  const valid = validate(data);
  if (valid) {
    return true;
  }
  const errors = validate.errors.map(e => {
    let attr = e.dataPath.substr(1); // Remove the first char which is "."
    if ((!attr || attr === "") && e.keyword === "required") {
      attr = (e.params || {}).missingProperty;
      const def = validationSchema.properties[attr];
      return `${def ? def.label : ""} is required.`;
    }
    const def = validationSchema.properties[attr];
    return `${def ? def.label : ""} ${e.message}`;
  });
  return errors;
};

export default validator;
