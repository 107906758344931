export default (
  savedOptions?: Object = {},
  defaultOptions?: Object = {}
): Object => {
  const sanitized = {};

  if (savedOptions) {
    // find null values and set it to empty string
    Object.keys(savedOptions || {}).forEach((k: any) => {
      Object.assign(sanitized, {
        [k]: savedOptions[k] === null ? "" : savedOptions[k],
      });
    });

    // defaultOptions counter-parts, if defaultOptions are of type boolean switches
    Object.keys(defaultOptions || {}).forEach((dk: any) => {
      if (
        (defaultOptions || {})[dk] &&
        typeof defaultOptions[dk] === "boolean" &&
        !savedOptions[dk]
      ) {
        Object.assign(sanitized, { [dk]: false });
      }
    });
  }

  return sanitized;
};
