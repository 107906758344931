import React, { type AbstractComponent, type ComponentType } from "react";
import { AuthConsumer } from "../context";
import { type AuthContextShape } from "../types";

type InjectedProps = {
  auth?: AuthContextShape,
};

function withAuth<T: {}>(
  Component: AbstractComponent<T>
): ComponentType<$Diff<T, InjectedProps>> {
  const WrappedComponent = (props: T) => (
    <AuthConsumer>
      {(auth: AuthContextShape) => <Component {...props} auth={auth} />}
    </AuthConsumer>
  );
  return WrappedComponent;
}

export default withAuth;
