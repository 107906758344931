import React from "react";
import { ResourceFactory, LookupResourceFactory } from "@swan/api"; // eslint-disable-line
import { isEqual } from "lodash";
import { withSwanContext, SwanContextType } from "@swan/state";
// utils
import { getFieldEventsByType, getFormEventsByType } from "../utils/modifiers";

// types
import { type Modifiers } from "../types";

// Note: objectAttrib is not in plural form, sounds like DOS ATTRIB
// Assume that objectAttrib receives array of ojbects

type Props = {
  children: Function,
  modifiers: Modifiers,
  onChange: Function,
  onBatchChange: Function,
  setLock: Function,
  data: Object,
  isNew: boolean,
  fieldsLocked?: Array<string>,
  fieldsLoading?: Array<string>,
  setFieldsUnlocked: Function,
  setFieldsLocked: Function,
  setFieldsUnloading: Function,
  setFieldsLoading: Function,
  SwanContext: SwanContextType,
};

type State = {
  // eventFired: boolean,
};

const ModifiersGate = class extends React.Component<Props, State> {
  static defaultProps = {
    fieldsLocked: [],
    fieldsLoading: [],
  };

  state = {
    // eventFired: false,
  };

  componentDidMount() {
    const { eventFired } = this;
    if (!eventFired) {
      this.eventFired = true;
      this.executeOnLoad();
    }
  }

  componentDidUpdate(prevProps: Props) {
    const { data, modifiers } = this.props;
    const { eventFired } = this;

    if (!eventFired) {
      this.eventFired = true;
      this.executeOnLoad();
    }

    const { data: previousRecord } = prevProps;
    if (!isEqual(data, previousRecord)) {
      Object.keys(data).forEach(attr => {
        if (
          data[attr] !== previousRecord[attr] &&
          !(data[attr] === null && previousRecord[attr] === undefined)
        ) {
          getFieldEventsByType(modifiers, attr, "onChange").then(events => {
            this.triggerOnChangeEvents(events, previousRecord);
          });
        }
      });
    }
  }

  onChange = (attribute: string, value: any) => {
    const { onChange } = this.props;
    onChange(attribute, value);
    // const events = getFieldEventsByType(modifiers, attribute, "onChange");
    // this.triggerOnChangeEvents(events);
  };

  onBatchChange = (attributes: Object) => {
    const { onBatchChange } = this.props;
    onBatchChange(attributes);
    // Object.keys(attributes).forEach(attribute => {
    //   const events = getFieldEventsByType(modifiers, attribute, "onChange");
    //   this.triggerOnChangeEvents(events);
    // });
  };

  getContext() {
    const {
      onBatchChange,
      isNew,
      setFieldsUnlocked,
      setFieldsLocked,
      setLock,
      setFieldsUnloading,
      setFieldsLoading,
      SwanContext,
    } = this.props;
    return {
      ResourceFactory,
      LookupResourceFactory,
      setValues: onBatchChange,
      isNew,
      setFieldsUnlocked,
      setFieldsLocked,
      setFieldsUnloading,
      setLock,
      whoami: SwanContext.user,
      setFieldsLoading,
    };
  }

  eventFired: boolean;

  async executeOnLoad() {
    const { data, modifiers } = this.props;
    if (data) {
      getFormEventsByType(modifiers, "onLoad").then(events => {
        events.forEach(event => {
          event({ record: data }, this.getContext());
        });
      });
    }
  }

  triggerOnChangeEvents(events: Array<Function>, previousRecord: any) {
    const { data } = this.props;
    events.forEach(event => {
      event(
        {
          record: data,
          previousRecord,
        },
        this.getContext()
      );
    });
  }

  render() {
    const {
      children,
      fieldsLocked,
      fieldsLoading,
      setFieldsUnlocked,
      setFieldsLocked,
      setFieldsUnloading,
      setFieldsLoading,
      SwanContext,
    } = this.props;
    return children({
      onChange: this.onChange,
      onBatchChange: this.onBatchChange,
      fieldsLocked,
      fieldsLoading,
      setFieldsUnlocked,
      setFieldsLocked,
      setFieldsUnloading,
      setFieldsLoading,
      whoami: SwanContext.user,
    });
  }
};

export default withSwanContext(ModifiersGate);
