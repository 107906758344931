import { getJsonSchema } from "./penOptionsSchema";
import { getJsonSchemaForRelatedObject } from "./relatedObject";
import { getJsonSchemaForVersions } from "./versions";
import { getJsonSchemaForFolio } from "./folio";
import { getJsonSchemaForPermissionEditor } from "./permissionEditor";
import { getJsonSchemaForWorkflow } from "./workflow";
import { getJsonSchemaForLookup } from "./lookup";
import { getJsonSchemaForChart } from "./chart";
import { getJsonSchemaForFilesUpload } from "./files-upload";
import { getJsonSchemaForBrandCard } from "./brandCard";
import { getJsonSchemaForTimeline } from "./timeline";
import { getJsonSchemaForStatusBreadcrumb } from "./statusBreadcrumb";
import { optionsFallbackDisabled } from "../../definitions/pencils/config";

export const getPenOptionsSchema = (
  pencilType: string,
  schemaId: string,
  pencilDefOptions: Object
) => {
  if (pencilType === "relatedObject") {
    return getJsonSchemaForRelatedObject(schemaId);
  }
  if (pencilType === "versions") {
    return getJsonSchemaForVersions();
  }
  if (pencilType === "lookup") {
    return getJsonSchemaForLookup(schemaId);
  }
  if (pencilType === "folio") {
    return getJsonSchemaForFolio();
  }
  if (pencilType === "timeline") {
    return getJsonSchemaForTimeline();
  }
  if (pencilType === "permissionEditor") {
    return getJsonSchemaForPermissionEditor();
  }
  if (pencilType === "multipleFileUpload") {
    return getJsonSchemaForFilesUpload();
  }
  if (pencilType === "brandCard") {
    return getJsonSchemaForBrandCard();
  }
  if (pencilType === "chartBar") {
    return getJsonSchemaForChart();
  }
  if (pencilType === "statusBreadcrumb") {
    return getJsonSchemaForStatusBreadcrumb();
  }
  if (
    pencilType === "workflowActionsButtons" ||
    pencilType === "workflowStatusSummary" ||
    pencilType === "workflowTimeline"
  ) {
    return getJsonSchemaForWorkflow();
  }
  return getJsonSchema(
    pencilDefOptions,
    optionsFallbackDisabled.indexOf(pencilType) !== -1
  );
};

export default {
  getPenOptionsSchema,
};
