import React, { PureComponent } from "react";
import { compose } from "redux";
import { withRouter } from "react-router-dom";
import { withRecordPermission } from "@swan/auth"; // eslint-disable-line
import {
  Grid,
  type GridProps,
  type GridOptions,
  type GridColumns,
} from "../../UI/Components/Grid";

export type Options = GridOptions & {
  disableAdd?: boolean,
  addRoute?: string,
  editRoute?:
    | string
    | {
        pathname: string,
        search?: string,
        state?: Object,
      }
    | (Object => string),
};

type State = {
  columns: GridColumns,
  gridOptions?: GridOptions,
};

export type Props = GridProps & {
  history: any,
  disableRecordPermission?: boolean,
  isAddAllowed: boolean, // comes from the withRecordPermission HOC
};

const ListHOC = (opts: ?Options) => (getColumns: () => GridColumns) => (
  Component: any // eslint-disable-line
) => {
  const options = Object.assign({}, { ...opts });

  class List extends PureComponent<Props, State> {
    static defaultProps = {
      disableRecordPermission: false,
    };

    state = {
      columns: getColumns(),
      gridOptions: undefined,
    };

    componentDidMount() {
      this.prepareOptions();
    }

    componentDidUpdate(prevProps: Props) {
      const { isAddAllowed } = this.props;
      if (isAddAllowed && isAddAllowed !== prevProps.isAddAllowed) {
        if (
          options.disablePermissions ||
          (!options.disableAdd && options.addRoute && isAddAllowed)
        ) {
          this.enableAddButton();
        }
      }
    }

    gotoEdit = row => {
      if (options.editRoute) {
        const { history } = this.props;
        let pathname;
        if (
          typeof options.editRoute === "object" &&
          Object.keys(options.editRoute).indexOf("pathname") !== -1
        ) {
          let editRouteObject = { ...options.editRoute };
          pathname = editRouteObject.pathname.replace(":id", row.id);
          editRouteObject = { ...editRouteObject, pathname };
          history.push(editRouteObject);
        }

        if (typeof options.editRoute === "string") {
          pathname = options.editRoute.replace(":id", row.id);
          history.push(pathname);
        }

        if (typeof options.editRoute === "function") {
          pathname = options.editRoute(row);
          history.push(pathname);
        }
      }
    };

    prepareOptions() {
      const gridOptions = Object.assign({}, { ...options });
      if (!gridOptions.onRowClick && gridOptions.editRoute) {
        gridOptions.onRowClick = row => this.gotoEdit(row);
      }
      this.setState({ gridOptions }, () => {
        // Now check for permission on Add before we add the button
        const { isAddAllowed } = this.props;
        if (
          options.disablePermissions ||
          (!options.disableAdd && options.addRoute && isAddAllowed)
        ) {
          this.enableAddButton();
        }
      });
    }

    enableAddButton() {
      const { gridOptions } = this.state;
      const { history } = this.props;
      let { buttons } = gridOptions || {};
      buttons = [...(buttons || [])];
      buttons = [
        {
          label: "Add",
          actionType: "Add",
          color: "success",
          onClick: () => history.push(options.addRoute),
        },
        ...buttons,
      ];

      this.setState(state => ({
        ...state,
        gridOptions: {
          ...state.gridOptions,
          buttons,
        },
      }));
    }

    render() {
      const { columns, gridOptions } = this.state;
      const { columns: propsColumns } = this.props;

      return (
        <Grid
          {...this.props}
          columns={propsColumns || columns}
          options={gridOptions}
        />
      );
    }
  }

  return compose(
    withRouter,
    withRecordPermission
  )(List);
};

export const ListDefaultOptions = ListHOC({});

export default ListHOC;
