export default {
  uid: "mapField",
  attributeId: "",
  formId: "",
  pencilDef: {
    title: "Map",
    group: "FIELD",
    handleIcon: "FaMapMarkedAlt",
    styles: {},
  },
  options: {
    title: "Untitled",
    type: "mapField",
    required: false,
    limits: {
      min: 0,
      max: 1,
    },
    description: "",
    help: "",
    hideLabel: true,
    classNames: "",
    disabled: false,
    readonly: false,
    // allowDuplicate: true,
    // isEncrypted: false,
    defaultLng: undefined,
    defaultLat: undefined,
    isMapPointer: true,
    isInputFields: false,
    isSearchEnabled: true,
    enableHistoryTracking: false,
    isHideByDefault: false,
    addressComponentsMap: {},
  },
};
