// @flow
import isNumeric from "./is_numberic";

type valueType = string | number;
export default (conditionValue: valueType) => (fieldValue: valueType) => {
  if (isNumeric(conditionValue) && isNumeric(fieldValue)) {
    return parseFloat(conditionValue) === parseFloat(fieldValue);
  }
  return false;
};
