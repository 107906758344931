// import React from "react";
// import { SelectWidget } from "./widgets";

export default {
  schema: {
    title: "Select Option",
    type: "array",
    items: {
      type: "object",
      required: ["value", "name"],
      properties: {
        value: {
          title: "Value",
          type: "string",
        },
        name: {
          title: "Display Name",
          type: "string",
        },
      },
    },
  },
  uiSchema: {
    "ui:options": {
      ordarable: false,
      addable: true,
    },
    items: {
      "ui:order": ["name", "value", "*"],
      name: {
        "ui:placeholder": "Human display value",
      },
      value: {
        "ui:placeholder": "Machine name",
      },
    },
  },
};
