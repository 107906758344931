import * as React from "react";
import { connect } from "react-redux";
import styled, { withTheme } from "styled-components";
import { Draggable } from "react-beautiful-dnd";
import { keys } from "lodash";
import { FaArrowsAlt, FaTimes, FaEllipsisH } from "react-icons/fa";
import { Modal, Input, TextArea, Checkbox } from "@swan/themer";

import ConditionalDroppable from "../DragDrop/ConditionalDroppable";
import ElementsList from "./ElementsList";
// import type { Props } from "./types";

import Parts from "../../definitions/parts";

// state
import { schemaActions } from "../../state/reducers/schema";

// types
import type { Schema } from "../../constants/flowTypes";

type ColPropsType = {
  id: string,
  rowId: string,
  index: number,
  schema: Schema,
  colCount: number,
  removeSchemaNode: string => void,
  updateElementSchema: (shcemaId: string, schema: Schema) => void,
  removeRow: string => void,
};

type ColState = {
  hovering: boolean,
  isModalOpen: boolean,
  modalId: string,
};

const ColContainer = styled.div(({ theme }) =>
  theme.ColContainer ? { ...theme.ColContainer } : {}
);

const ColDragHandler = styled(
  styled.div(({ theme }) =>
    theme.ColDragHandler ? { ...theme.ColDragHandler } : {}
  )
)`
  &:hover {
    cursor: move;
    color: #fff;
  }
`;

const ColCloseHandler = styled(
  styled.div(({ theme }) =>
    theme.ColCloseHandler ? { ...theme.ColCloseHandler } : {}
  )
)`
  &:hover {
    cursor: pointer;
    color: #fff;
  }
`;

const DragFrameTitle = styled.div(({ theme, hovering }) =>
  Object.assign(
    {},
    { zIndex: 3 },
    theme.DragFrameTitle ? { ...theme.DragFrameTitle } : {},
    hovering && theme.ColDragFrameTitleHover
      ? { ...theme.ColDragFrameTitleHover }
      : {}
  )
);

export const ColWrapper = styled.div(({ theme, colCount }) =>
  Object.assign(
    {},
    { position: "relative" },
    theme.ColWrapper ? { ...theme.ColWrapper } : {},
    colCount ? { flexBasis: `${100 / colCount}%` } : {}
    // { minHeight: "300px" }
    // droppedCount === 0 ? { minHeight: "300px" } : {}
  )
);

class Col extends React.Component<ColPropsType, ColState> {
  state = {
    hovering: false,
    isModalOpen: false,
    modalId: "",
  };

  componentDidMount() {
    this.mounted = true;
  }

  componentWillUnmount() {
    this.mounted = false;
    if (this.timeout) {
      clearTimeout(this.timeout);
    }
  }

  removeColSchema = schemaPath => {
    const { removeSchemaNode, colCount, rowId, removeRow } = this.props;
    if (colCount === 1) {
      // If single column remove row
      removeRow(rowId);
    } else {
      removeSchemaNode(schemaPath);
    }
  };

  hovering = () => {
    if (this.timeout) {
      clearTimeout(this.timeout);
    }
    this.setState({
      hovering: true,
    });
  };

  notHovering = () => {
    if (this.mounted) {
      this.setState({
        hovering: false,
      });
    }
  };

  openModal = modalId => {
    this.setState({
      modalId,
      isModalOpen: true,
    });
  };

  toggleModal = (setOn?: boolean, modalId?: string) => {
    this.setState(prevState => ({
      modalId,
      isModalOpen:
        setOn !== undefined && typeof setOn === "boolean"
          ? setOn
          : !prevState.isModalOpen,
    }));
  };

  updateColOptions = (k, v) => {
    const { schema, updateElementSchema } = this.props;
    const { modalId } = this.state;

    if (schema.type && schema.type === "col") {
      const updatedSchema = Object.assign(
        { ...schema },
        { options: { ...(schema.options || {}), [k]: v } }
      );
      updateElementSchema(modalId, updatedSchema);
    }
  };

  timeout: any;

  mounted: boolean;

  render() {
    const { schema, id, index, colCount } = this.props;
    const { hovering, isModalOpen } = this.state;
    return (
      <>
        <Draggable
          draggableId={id}
          index={index || 0}
          isDragDisabled={colCount === 1}
        >
          {draggableProvided => (
            <ColWrapper
              ref={draggableProvided.innerRef}
              {...draggableProvided.draggableProps}
              childCount={colCount}
              droppedCount={keys(schema.properties).length}
              onMouseOver={this.hovering}
              onFocus={this.hovering}
              onMouseOut={this.notHovering}
              onBlur={this.notHovering}
            >
              <DragFrameTitle hovering={hovering}>
                <ColDragHandler {...draggableProvided.dragHandleProps}>
                  <FaArrowsAlt size=".8em" />
                  {/* {rowCount > 1 && <span>Drag Me</span>}
                {rowCount <= 1 && <span>Drag is not allowed</span>} */}
                </ColDragHandler>
                <ColCloseHandler
                  onClick={() => {
                    this.openModal(id);
                  }}
                >
                  <FaEllipsisH size=".8em" />
                </ColCloseHandler>
                <ColCloseHandler
                  onClick={() => {
                    this.removeColSchema(id);
                  }}
                >
                  <FaTimes size=".8em" />
                </ColCloseHandler>
              </DragFrameTitle>
              <ColContainer>
                <ConditionalDroppable
                  droppableId={id}
                  excludeTypes={Parts.Col.drop.excludeTypes}
                  droppedCount={keys(schema.properties).length}
                  droppableWrapper="Col"
                >
                  <ElementsList id={id} schema={schema} index={0} />
                </ConditionalDroppable>
              </ColContainer>
            </ColWrapper>
          )}
        </Draggable>
        <Modal toggle={this.toggleModal} isOpen={isModalOpen} enableBackdrop>
          <Input
            name={`Column Size of ${id}`}
            type="number"
            label="Column Size"
            placeholder="Value should be between 1 and 12"
            onChange={e => {
              this.updateColOptions("columnSize", e.target.value);
            }}
            value={((schema || {}).options || {}).columnSize}
          />
          <Input
            name={`Class name for ${id}`}
            type="text"
            label="Class Names"
            placeholder="Space seperated class names"
            onChange={e => {
              this.updateColOptions("classNames", e.target.value);
            }}
            value={((schema || {}).options || {}).classNames}
          />
          <TextArea
            name={`Custom styles for ${id}`}
            label="Custom Styles"
            placeholder="CSS Styles"
            onChange={e => {
              this.updateColOptions("customStyles", e.target.value);
            }}
            value={((schema || {}).options || {}).customStyles}
          />
          <Checkbox
            name={`hideOnCreate for ${id}`}
            value={((schema || {}).options || {}).hideOnCreate}
            label="Hide on Create"
            onChange={() => {
              const checkValue =
                ((schema || {}).options || {}).hideOnCreate !== "1" ? "1" : "0";
              this.updateColOptions("hideOnCreate", checkValue);
            }}
          />
          <Checkbox
            name={`hideOnDetail for ${id}`}
            value={((schema || {}).options || {}).hideOnDetail}
            label="Hide on Detail"
            onChange={() => {
              const checkValue =
                ((schema || {}).options || {}).hideOnDetail !== "1" ? "1" : "0";
              this.updateColOptions("hideOnDetail", checkValue);
            }}
          />
          <Checkbox
            name={`isHideOnCondition for ${id}`}
            value={((schema || {}).options || {}).isHideOnCondition}
            label="Enable hide On Condition"
            onChange={() => {
              const checkValue =
                ((schema || {}).options || {}).isHideOnCondition !== "1"
                  ? "1"
                  : "0";
              this.updateColOptions("isHideOnCondition", checkValue);
            }}
          />
          {((schema || {}).options || {}).isHideOnCondition === "1" && (
            <TextArea
              name={`hideOnCondition for ${id}`}
              label="Hide On Condition Formula"
              value={((schema || {}).options || {}).hideOnCondition}
              onChange={e => {
                this.updateColOptions("hideOnCondition", e.target.value);
              }}
            />
          )}
        </Modal>
      </>
    );
  }
}

// export default withTheme(Col);

export default connect(
  null,
  {
    removeSchemaNode: schemaActions.removeSchemaNode,
    updateElementSchema: schemaActions.updateElementSchema,
  }
)(withTheme(Col));
