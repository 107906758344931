export default {
  pencilDef: {
    title: "Container",
    group: "LAYOUT",
    handleIcon: "FaRegSquare",
    styles: {},
  },
  drop: {
    excludeTypes: ["row", "tab", "col"],
  },
  options: {
    title: "Untitle Section",
    type: "container",
    defaultProperties: {},
  },
};
