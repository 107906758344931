import React, { useEffect, useState } from "react";
import { Resource } from "@swan/api";
import { FaFileExport } from "react-icons/fa";
import {
  ButtonDropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";
import { toast } from "@swan/themer";

const TemplatesResource = new Resource("exporter");

const ExportButton = (props: any) => {
  const [templates, setTemplates] = useState([]);
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const exportTemplate = (template: any) => {
    TemplatesResource.post(`templates/${template.id}`, {
      filter: props.filter,
    })
      .then(response => {
        toast(response.message);
      })
      .catch(() => {
        toast("Ops! something went wrong, please try again later");
      });
  };

  useEffect(() => {
    const { object } = props;

    TemplatesResource.get(`${object}/templates`)
      .then(response => {
        setTemplates(response);
      })
      .catch(error => {
        // eslint-disable-next-line no-console
        console.error(error);
      });
  }, []);

  if (templates.length === 0) return null;

  return (
    <div>
      <ButtonDropdown
        className="mx-1"
        isOpen={dropdownOpen}
        toggle={toggleDropdown}
      >
        <DropdownToggle
          style={{ borderRadius: "6px" }}
          className="export-button"
          color="success"
        >
          <div className="d-flex align-items-center">
            <FaFileExport />
            <span className="ml-1">Export</span>
          </div>
        </DropdownToggle>
        <DropdownMenu right>
          {templates.map(template => (
            <DropdownItem onClick={() => exportTemplate(template)}>
              {template.name}
            </DropdownItem>
          ))}
        </DropdownMenu>
      </ButtonDropdown>
    </div>
  );
};

export default ExportButton;
