import { forEach, isPlainObject, isArray, camelCase } from "lodash";

const objectCamelCased = (snakeCaseObject: Object): Object => {
  const camelCasedObject = isArray(snakeCaseObject) ? [] : {};

  forEach(snakeCaseObject, (v, k: string | number) => {
    let value = v;
    if (isPlainObject(v) || isArray(v)) {
      value = objectCamelCased(v);
    }
    if (isArray(camelCasedObject)) {
      camelCasedObject[k] = value;
    } else {
      camelCasedObject[camelCase(k.toString())] = value;
    }
  });

  return camelCasedObject;
};

export default objectCamelCased;
