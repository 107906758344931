import type {
  ApiResponseRecord,
  ApiResponseList,
  ApiResponseError,
  Filter,
  Sorting,
} from "../flowTypes/state";

export default (resourceType: string, types: { [string]: string }) => {
  const pluralName = `${resourceType}s`;
  const nameInAction = resourceType.toUpperCase();
  const nameInActionPlural = pluralName.toUpperCase();
  return {
    // Load records
    loadRecordsBegins: () => ({
      type: types[`LOAD_${nameInActionPlural}_BEGINS`],
      payload: {
        statusCode: null,
      },
      meta: {
        loading: true,
      },
    }),

    loadRecordsCompleted: (
      response: ApiResponseList,
      filter?: Filter,
      sorting?: Sorting
    ) => ({
      type: types[`LOAD_${nameInActionPlural}_COMPLETED`],
      payload: { ...response, filter, sorting },
      meta: { loading: false },
    }),

    loadRecordsFailed: (error: ApiResponseError) => ({
      type: types[`LOAD_${nameInActionPlural}_FAILED`],
      payload: { error },
      meta: { loading: false },
    }),
    // End load records

    // Load record
    loadRecordBegins: (id: number) => ({
      type: types[`LOAD_${nameInAction}_BEGINS`],
      payload: { id, statusCode: null },
      meta: {
        loading: true,
      },
    }),

    loadRecordCompleted: (record: ApiResponseRecord) => ({
      type: types[`LOAD_${nameInAction}_COMPLETED`],
      payload: { record, statusCode: null },
      meta: { loading: false },
    }),

    // used for websocket subscription to avoid overriding the permission field if exist
    loadRecordSubscriptionCompleted: (record: ApiResponseRecord) => ({
      type: types[`LOAD_${nameInAction}_SUBSCRIPTION_COMPLETED`],
      payload: { record, statusCode: null },
      meta: { loading: false },
    }),

    loadRecordFailed: (
      error: ApiResponseError,
      id: number,
      statusCode?: number
    ) => ({
      type: types[`LOAD_${nameInAction}_FAILED`],
      payload: { error, id, statusCode },
      meta: { loading: false },
    }),
    // End load records

    // Update Record
    updateRecordBegins: () => ({
      type: types[`UPDATE_${nameInAction}_BEGINS`],
      payload: {},
      meta: {
        loading: true,
      },
    }),
    duplicateRecordBegins: () => ({
      type: types[`DUPLICATE_${nameInAction}_BEGINS`],
      payload: {},
      meta: {
        isDuplicateLoading: true,
      },
    }),
    duplicateRecordCompleted: (response: Object) => ({
      type: types[`DUPLICATE_${nameInAction}_COMPLETED`],
      payload: response,
      meta: {
        isDuplicateLoading: false,
        isDuplicateCompleted: true,
      },
    }),
    duplicateRecordFailed: (errors: string) => ({
      type: types[`DUPLICATE_${nameInAction}_FAILED`],
      payload: {},
      meta: {
        errors,
      },
    }),
    updateRecordCompleted: (response: ApiResponseRecord, params: Object) => ({
      type: types[`UPDATE_${nameInAction}_COMPLETED`],
      payload: { record: response },
      meta: { loading: false },
      params,
    }),
    initForm: () => ({
      type: types[`INIT_${nameInAction}`],
      payload: {},
      meta: {},
      params: {},
    }),
    createRecordCompleted: (response: ApiResponseRecord, params: Object) => ({
      type: types[`CREATE_${nameInAction}_COMPLETED`],
      payload: { record: response },
      meta: { loading: false },
      params,
    }),
    deleteRecordCompleted: (response: ApiResponseRecord) => ({
      type: types[`DELETE_${nameInAction}_COMPLETED`],
      payload: { ...response },
      meta: { loading: false },
    }),

    updateRecordFailed: (
      error: ApiResponseError,
      record: any,
      recordId?: number
    ) => ({
      type: types[`UPDATE_${nameInAction}_FAILED`],
      payload: { error, record, recordId },
      meta: { loading: false },
    }),

    updateRecordReset: () => ({
      type: types[`UPDATE_${nameInAction}_RESET`],
      payload: {},
    }),
    // End Update Record

    // Load More Records
    loadMoreRecordsBegins: () => ({
      type: types[`LOAD_MORE_${nameInActionPlural}_BEGINS`],
      payload: {},
      meta: {
        loading: true,
      },
    }),
    loadMoreRecordsCompleted: (
      response: ApiResponseList,
      filter?: Filter,
      sorting?: Sorting
    ) => ({
      type: types[`LOAD_MORE_${nameInActionPlural}_COMPLETED`],
      payload: { ...response, filter, sorting },
      meta: { loading: false },
    }),
    loadMoreRecordsFailed: (error: ApiResponseError) => ({
      type: types[`LOAD_MORE_${nameInActionPlural}_FAILED`],
      payload: { error },
      meta: { loading: false },
    }),
    loadMoreRecordsReset: () => ({
      type: types[`LOAD_MORE_${nameInActionPlural}_RESET`],
      payload: {},
    }),
    subscribeStarted: (id: number, object: string) => ({
      type: types[`SUBSCRIBE_${nameInAction}_STARTED`],
      payload: { id, object },
    }),
    unsubscribeStarted: (id: number, object: string) => ({
      type: types[`UNSUBSCRIBE_${nameInAction}_STARTED`],
      payload: { id, object },
    }),

    unsubscribeNewRecordsStarted: (object: string) => ({
      type: types[`UNSUBSCRIBE_${nameInActionPlural}_NEW_RECORDS_STARTED`],
      payload: { object },
    }),

    subscribeNewRecordsStarted: (object: string) => ({
      type: types[`SUBSCRIBE_${nameInActionPlural}_NEW_RECORDS_STARTED`],
      payload: { object },
    }),

    subscribeNewRecordsCompleted: (response: Object) => ({
      type: types[`SUBSCRIBE_${nameInActionPlural}_NEW_RECORDS_COMPLETED`],
      payload: { ...response },
    }),

    subscribeNewRecordsFailed: (errors: Object) => ({
      type: types[`SUBSCRIBE_${nameInActionPlural}_NEW_RECORDS_FAILED`],
      payload: {},
      meta: {
        errors,
      },
    }),

    reorderRecordBegins: () => ({
      type: types[`REORDER_${nameInAction}_BEGINS`],
      payload: {},
      meta: {
        isReordering: true,
      },
    }),
    reorderRecordCompleted: (response: Object) => ({
      type: types[`REORDER_${nameInAction}_COMPLETED`],
      payload: response,
      meta: {
        isReordering: false,
      },
    }),
    reorderRecordFailed: (errors: string) => ({
      type: types[`REORDER_${nameInAction}_FAILED`],
      payload: {},
      meta: {
        isReordering: false,
        errors,
      },
    }),
    // End Load More Records

    exportRecordsBegins: () => ({
      type: types[`EXPORT_${nameInActionPlural}_BEGINS`],
      payload: {
        statusCode: null,
      },
      meta: {
        loading: true,
      },
    }),

    exportRecordsCompleted: (
      response: ApiResponseList,
      filter?: Filter,
      sorting?: Sorting
    ) => ({
      type: types[`EXPORT_${nameInActionPlural}_COMPLETED`],
      payload: { ...response, filter, sorting },
      meta: { loading: false },
    }),

    exportRecordsFailed: (error: ApiResponseError) => ({
      type: types[`EXPORT_${nameInActionPlural}_FAILED`],
      payload: { error },
      meta: { loading: false },
    }),
  };
};
