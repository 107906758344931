export default {
  "converted from opportunity": { color: "#FFF", background: "#ffc107" },
  "in progress": { color: "#FFF", background: "#b3b3b3" },

  submitted: { color: "#FFF", background: "#b3b3b3" },
  "attempt to contact": { color: "#FFF", background: "#b3b3b3" },
  "not contracted": { color: "#FFF", background: "#b3b3b3" },
  "junk prospect": { color: "#FFF", background: "#dc3545" },
  "contact in future": { color: "#FFF", background: "#FFC400" },
  contacted: { color: "#FFF", background: "#57D9A3" },
  "pre-qualified": { color: "#FFF", background: "#519bff" },

  "waiting for approval": { color: "#FFF", background: "#FFC400" },
  awaiting_approval: { color: "#FFF", background: "#FFC400" },
  "awaiting approval": { color: "#FFF", background: "#FFC400" },
  approved: { color: "#FFF", background: "#519bff" },
  rejected: { color: "#FFF", background: "#dc3545" },
  "waiting for signature": { color: "#FFF", background: "#FFC400" },
  ordered: { color: "#FFF", background: "#57D9A3" },
  signed: { color: "#FFF", background: "#57D9A3" },
  "order cancelled": { color: "#FFF", background: "#dc3545" },
  "quote rejected": { color: "#FFF", background: "#dc3545" },
  cancelled: { color: "#FFF", background: "#dc3545" },
  "awaiting customer approval": { color: "#FFF", background: "#FFC400" },
  "*** not doa related ***": { color: "#FFF", background: "#b3b3b3" },

  "on court": { color: "#FFF", background: "#dc3545" },
  "under construction": { color: "#FFF", background: "#FFC400" },
  available: { color: "#FFF", background: "#57D9A3" },

  prospect: { color: "#FFF", background: "#FFC400" },
  confirmed: { color: "#FFF", background: "#57D9A3" },

  Initialized: { color: "#FFF", background: "#b3b3b3" },
  "internal approval": { color: "#FFF", background: "#FFC400" },
  "tenant approval": { color: "#FFF", background: "#FFC400" },
  "under revision": { color: "#FFF", background: "#FFC400" },
  "ready to least": { color: "#FFF", background: "#57D9A3" },
  "ready to lease": { color: "#FFF", background: "#57D9A3" },
  "awaiting signature": { color: "#FFF", background: "#FFC400" },
  registered: { color: "#FFF", background: "#57D9A3" },

  "available for lease": { color: "#FFF", background: "#57D9A3" },
  "capital work on progress": { color: "#FFF", background: "#FFC400" },
  "attempt to contact (first call)": { color: "#FFF", background: "#FFC400" },

  villa: { color: "#FFF", background: "#57D9A3" },
  building: { color: "#FFF", background: "#FFC400" },
  land: { color: "#FFF", background: "#dc3545" },
  grouped: { color: "#FFF", background: "#b3b3b3" },

  qualification: { color: "#fff", background: "#57d9a3" },
  "needs analysis": { color: "#fff", background: "#ffc400" },
  "value proposition": { color: "#fff", background: "#ffc400" },
  "identify decision makers": { color: "#fff", background: "#57d9a3" },
  "proposal/price quote": { color: "#fff", background: "#57d9a3" },
  "negotiation/review": { color: "#fff", background: "#ffc400" },
  "closed won": { color: "#fff", background: "#57d9a3" },
  "closed-lost to competition": { color: "#fff", background: "#dc3545" },
  "budgetary stage": { color: "#fff", background: "#ffc400" },
  "commercial negotiation": { color: "#fff", background: "#ffc400" },
  "technical evaluation": { color: "#fff", background: "#ffc400" },
  "project on hold": { color: "#000", background: "#b3b3b3" },
  "quote submitted": { color: "#fff", background: "#57d9a3" },
  "closed lost": { color: "#fff", background: "#dc3545" },
  "quote in progress": { color: "#fff", background: "#ffc400" },
  dropped: { color: "#fff", background: "#dc3545" },
  expired: { color: "#fff", background: "#dc3545" },
  "not contacted": { color: "#fff", background: "#dc3545" },
};
