import React, { Component } from "react";
import styled from "styled-components";
import { FaRegCopy as CopyIcon } from "react-icons/fa";

type Props = {
  children?: any,
  //   controlIcon?: Node | React.Element<any>,
  onCopied?: (copiedValue: any) => void,
  extractionMode?: "innerText" | "textContent",
  showSelection?: boolean,
  styles?: Object,
};

const Wrapper = styled(styled.div(({ styles }) => Object.assign({}, styles)))`
  position: relative;
`;

const ControlButton = styled("span")`
  position: absolute;
  width: 2rem;
  height: 100%;
  right: 0;
  top: 0;
  background: #f4f7fb;
  color: #3d70b2;
  font-size: 1em;
  display: none;
  justify-content: center;
  align-items: center;

  &:hover {
    display: flex;
  }
`;

const IconHolder = styled("span")``;

const Content = styled("div")`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  &:hover ~ .copy-text-control {
    visibility: visible;
    display: flex;
  }
`;

export default class CopyText extends Component<Props> {
  static defaultProps = {
    // controlIcon: CopyIcon,
    onCopied: undefined,
    children: null,
    extractionMode: "innerText",
    showSelection: false,
    styles: {},
  };

  copyToClipBoard = () => {
    const { onCopied } = this.props;
    this.selectElement(this.contentSelection);
    if (onCopied) {
      const text = this.textExtractor(this.contentSelection);
      onCopied(text);
    }
  };

  selectElement = (el: any) => {
    const { showSelection } = this.props;
    const range = document.createRange();
    range.selectNodeContents(el);
    const selection = window.getSelection();
    selection.removeAllRanges();
    selection.addRange(range);
    document.execCommand("copy");
    if (!showSelection) selection.removeAllRanges();
  };

  textExtractor = (el: any) => {
    let text = "";
    const { extractionMode } = this.props;
    if (extractionMode === "innerText") text = el.innerText || "";
    if (extractionMode === "textContent") text = el.textContent || "";
    return text;
  };

  initRef = (el: any) => {
    this.contentSelection = el;
  };

  contentSelection: any;

  render() {
    const { children, styles } = this.props;
    return (
      <Wrapper
        className="copy-text-wrapper"
        styles={(styles || {}).wrapper || {}}
      >
        <Content className="copy-text-content" ref={this.initRef}>
          {children}
        </Content>
        <ControlButton
          className="copy-text-control"
          onClick={this.copyToClipBoard}
        >
          <IconHolder className="copy-text-control-icon">
            <CopyIcon />
          </IconHolder>
        </ControlButton>
      </Wrapper>
    );
  }
}
