export default [
  {
    name: "Single",
    value: "SINGLE",
  },
  {
    name: "Married",
    value: "MARRIED",
  },
  {
    name: "remarried",
    value: "REMARRIED",
  },
  {
    name: "Separated",
    value: "SEPARATED",
  },
  {
    name: "Divorced",
    value: "DIVORCED",
  },
  {
    name: "Widowed",
    value: "WIDOWED",
  },
  {
    name: "Other",
    value: "OTHER_MARTIAL_STATUS",
  },
];
