// @flow
import React, { type Node } from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import Button from "./Button";

type Props = {
  isOpen?: boolean,
  toggle?: Function,
  title?: string,
  children: Node,
  buttons?: Array<{
    label: string,
    icon?: Node,
    type?: string,
    color?: string,
    onClick?: Function,
  }>,
  disableToggleOnHeader?: boolean,
  size?: string,
  enableBackdrop?: boolean,
  zIndex?: number,
};

// eslint-disable-next-line react/prefer-stateless-function
class SwanModal extends React.Component<Props> {
  static defaultProps = {
    title: "",
    isOpen: false,
    toggle: () => {},
    buttons: [],
    size: "lg",
    enableBackdrop: undefined,
    zIndex: 9999,
    disableToggleOnHeader: false,
  };

  render() {
    const {
      isOpen,
      toggle,
      title,
      children,
      buttons,
      size,
      enableBackdrop,
      zIndex,
      disableToggleOnHeader,
      ...restProps
    } = this.props;
    return (
      <Modal
        zIndex={zIndex}
        isOpen={isOpen}
        toggle={toggle}
        size={size}
        backdrop={enableBackdrop || false}
        {...restProps}
      >
        {title && (
          <ModalHeader toggle={!disableToggleOnHeader ? toggle : undefined}>
            {title}
          </ModalHeader>
        )}
        <ModalBody>{children}</ModalBody>

        {Array.isArray(buttons) && buttons.length > 0 && (
          <ModalFooter>
            {buttons.map(button => {
              const { label, color, onClick } = button;
              return (
                <Button
                  key={label}
                  color={color || "secondary"}
                  onClick={onClick}
                >
                  {label}
                </Button>
              );
            })}
          </ModalFooter>
        )}
      </Modal>
    );
  }
}

export default SwanModal;
