import * as React from "react";

import styled, { withTheme } from "styled-components";

import ElementsList from "./ElementsList";
import { type ElementProps } from "./types";

export const ContainerWrapper = styled.div(({ theme }) =>
  Object.assign(
    {},
    {
      display: "flex",
      flexDirection: "row",
      width: "100%",
      "& > *": {
        flex: 1,
      },
    },
    theme.ContainerWrapper ? { ...theme.ContainerWrapper } : {}
  )
);

const RowContainer = ({ schema, id }: ElementProps) => (
  <ContainerWrapper>
    <ElementsList id={id} schema={schema} index={0} />
  </ContainerWrapper>
);

export default withTheme(RowContainer);
