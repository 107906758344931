// import { measure as spacingMeasure, space, spaceMedium } from "./spacing";

const PencilPanelWrapper = {
  boxSizing: "border-box",
  flexBasis: "25%",
  Width: "25%",
  minWidth: 360,
  flexShrink: 0,
  padding: "0.5rem 2rem",
  minHeight: "100vh",
  background: "#656565",
};

const PencilBoxesWrapper = {
  display: "flex",
  flexDirection: "column",
};

const PencilBoxWrapper = {
  flex: 1,
  display: "flex",
  // flexDirection: "column",
  flexWrap: "wrap",
  justifyContent: "flex-start",
  // background: "#fff",
  // padding: "0.5rem 0.5rem 0",
  // padding: "1rem",
  // border: "1px solid #ddd",
  // borderRadius: "0.2rem",
  // marginBottom: "0.8rem",
  // boxShadow: ".1rem .1rem  .2rem .2rem #555",
};

const PencilBoxTitle = {
  lineHeight: "1.1rem",
  fontSize: ".8rem",
  textTransform: "uppercase",
  color: "#fff",
  padding: "1.3rem 0 .5rem 0.1rem",
  fontWeight: 900,
  margin: 0,
  display: "flex",
  justifyContent: "flex-start",
  alignItems: "center",
};

const PencilBoxCard = {
  background: "transparent",
  border: "none",
};

const PencilWrapper = {
  // flex: 1,
  flexBasis: "calc(50% - .25rem)",
  display: "flex",
  userSelect: "none",
  padding: "0.5rem",
  margin: "0 0.5rem 0.5rem 0",
  // justifyContent: "flex-start",
  // alignItem: "flex-start",
  // alignContent: "center",
  lineHeight: 1.5,
  borderRadius: "0.2rem",
  background: "#fff",
  border: "1px solid #656565",
  fontSize: ".8rem",
  // letterSpacing: 0.5,
};

const PencilIsDragging = {
  border: "1px dashed #656565",
  boxShadow: ".1rem .1rem  .2rem .2rem #656565",
};

const PencilIsEven = {
  marginRight: 0,
  // background: "red",
};
const PencilIsOdd = {};

const PencilIsInLastRow = {
  marginBottom: 0,
};

const PencilClasses = {
  PencilPanelWrapper: "pencil-panel-wrapper",
  PencilBoxesWrapper: "pencil-boxes-wrapper",
  PencilBoxWrapper: "pencil-box-wrapper",
  PencilWrapper: "pencil-wrapper",
  PencilIsDragging: "pencil-is-dragging",
};

export {
  PencilPanelWrapper,
  PencilBoxesWrapper,
  PencilBoxWrapper,
  PencilBoxTitle,
  PencilBoxCard,
  PencilWrapper,
  PencilIsDragging,
  PencilIsEven,
  PencilIsOdd,
  PencilIsInLastRow,
  PencilClasses,
};
