import Resource from "./resource";
import ObjectService from "./services/object";
import { type SwanObject } from "./types";

class GenericRelationshipResource extends Resource {
  objectName: string;

  isComposedEndpoint: boolean;

  genericPrefix: string = "r";

  relationshipName: string;

  objectDef: SwanObject;

  parentId: string;

  setParent(p: string) {
    this.parentId = p;
  }

  setObjectName(name: string) {
    this.objectName = name;
  }

  /**
   * Override the get api function to dynamically load object definition
   */
  getApi() {
    if (!this.objectDef) {
      if (!this.objectName) {
        throw new Error("Object name not defined");
      }

      return ObjectService.getObjectByName(this.objectName).then(def => {
        const relName = this.relationshipName.toLowerCase();
        if (!def.relationships) throw new Error("No relationships");
        if (!def.relationships[relName])
          throw new Error(
            `Relationship not defined: ${this.objectName} ${
              this.relationshipName
            }`
          );
        this.objectDef = def;

        if (this.isComposedEndpoint) {
          const prefixParts = [
            this.genericPrefix,
            // $FlowFixMe
            def.module.name,
            this.objectName,
          ];
          if (this.parentId) {
            prefixParts.push(this.parentId);
          }
          prefixParts.push(this.relationshipName);
          this.prefix = prefixParts.join("/");
        } else {
          this.prefix = def.relationships[relName].api_endpoint;
          if (this.parentId) {
            this.prefix = this.prefix.replace("{parentId}", this.parentId);
          }
        }

        return super.getApi();
      });
    }
    return super.getApi();
  }
}

export default GenericRelationshipResource;
