export const getJsonSchemaForStatusBreadcrumb = (): // id: string
{
  jsonSchema: {},
  jsonUiSchema: {},
} => {
  const jsonSchema = {
    type: "object",
    properties: {
      key: {
        type: "string",
        default: "status",
      },
      statuses: {
        type: "array",
        title: "Statuses",
        items: {
          type: "object",
          properties: {
            id: { type: "string" },
            label: { type: "string" },
          },
        },
      },
    },
  };
  const jsonUiSchema = {};

  return {
    jsonSchema,
    jsonUiSchema,
  };
};

export default {
  getJsonSchemaForStatusBreadcrumb,
};
