// @flow
import { type AddressType } from "./types";

const addressComponentExtractor = (
  addressComponents: Array<{
    types: string | Array<string>,
    long_name: string,
    short_name: string,
  }>
) => {
  const addressParts = {};

  if (Array.isArray(addressComponents)) {
    addressComponents.forEach(addressComponent => {
      if (addressComponent.long_name && addressComponent.types) {
        let { types } = addressComponent;
        if (!Array.isArray(types)) types = [types];
        types.forEach(type => {
          if (Object.keys(addressParts).includes(type)) {
            if (!Array.isArray(addressParts[type])) {
              addressParts[type] = [addressParts[type]];
            }
            // $FlowFixMe
            addressParts[type].push(addressComponent.long_name);
          } else {
            Object.assign(addressParts, {
              [type]:
                type === "country"
                  ? addressComponent.short_name
                  : addressComponent.long_name,
            });
          }
        });
      }
    });
  }

  return addressParts;
};

const getLocationByLatlng = (
  latLng: AddressType,
  onSubmit: AddressType => void
) => {
  const geocoder = new window.google.maps.Geocoder();
  geocoder.geocode(
    {
      location: latLng,
    },
    (results: Object[], status) => {
      if (status === "OK" && results[0]) {
        onSubmit({
          ...latLng,
          address: results[0].formatted_address,
        });
      } else {
        // TODO handle errors.
      }
    }
  );
};

const getCurrentLocation = (
  map: Object,
  onCurrentLocationFound: AddressType => void
) => {
  if (navigator.geolocation) {
    navigator.geolocation.getCurrentPosition(
      position => {
        const pos: AddressType = {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        };
        getLocationByLatlng(pos, onCurrentLocationFound);
        map.setCenter(pos);
      },
      () => {
        // console.log(e);
      }
    );
  } else {
    // console.log("navigator geolocation not found");
  }
};

export { getCurrentLocation, getLocationByLatlng, addressComponentExtractor };
export default getLocationByLatlng;
