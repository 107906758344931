import React from "react";
// import Home from "./pages/Home";
// import type { DashboardProps } from "./views/Dashboard";
import { SwanContextManager } from "@swan/state";
import { GoSettings } from "react-icons/all";

// $FlowFixMe Should be React.lazy<DashboardProps> but causing issue in webpack
const Home = React.lazy(() => import("./Module"));

// https://github.com/ReactTraining/react-router/tree/master/packages/react-router-config
const entry_routes = [
  {
    path: "/administration",
    name: "Administration",
    component: Home,
  },
];

export default {
  name: "Administration",
  displayName: "Administration",
  mainNav: {
    name: "Administration",
    url: "/administration",
    icon: <GoSettings />,
    weight: 990,
  },
  productNav(permissions: Array<string>) {
    const navItems = [];

    if (permissions && Array.isArray(permissions)) {
      if (
        permissions.includes("admin:composer") === true &&
        SwanContextManager.getValue("userIsAdmin") === true
      ) {
        navItems.push({
          name: "Composer",
          url: "/administration/composer",
          icon: "fa-cogs",
        });
      }

      if (
        permissions.includes("admin:users") === true &&
        SwanContextManager.getValue("userIsAdmin") === true
      ) {
        navItems.push({
          name: "My Users",
          url: "/administration/users",
          icon: "fa-users",
        });
      }

      if (
        permissions.includes("admin:roles") === true &&
        SwanContextManager.getValue("userIsAdmin") === true
      ) {
        navItems.push({
          name: "My Roles",
          url: "/administration/roles",
          icon: "fa-users",
        });
      }

      if (
        permissions.includes("admin:organizations") === true &&
        SwanContextManager.getValue("userIsAdmin") === true
      ) {
        navItems.push({
          name: "Organizations",
          url: "/administration/organizations",
          icon: "fa-bank",
        });
      }

      if (
        permissions.includes("admin:templates") === true &&
        SwanContextManager.getValue("userIsAdmin") === true
      ) {
        navItems.push({
          name: "Templates",
          url: "/administration/templates",
          icon: "fa-cogs",
        });
      }

      if (
        permissions.includes("admin:record_permissions") === true &&
        SwanContextManager.getValue("userIsAdmin") === true
      ) {
        navItems.push({
          name: "Record Permissions",
          url: "/administration/record_permissions",
          icon: "fa-cogs",
        });
      }
    }

    return navItems;
  },
  routes: entry_routes,
};
