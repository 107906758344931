import * as React from "react";
import { SwanModuleContext, SwanContextManager } from "@swan/state";
// import { withAuth } from "@swan/auth";
import ModuleDefinition from "./module_def";

// $FlowFixMe
export const CurrentModuleContext = React.createContext({});

// enslint-disable-next-line
type Props = {
  registerNav: ({}, Array<{}>, string, string) => void,
  registerRoutes: (Array<{}>, string) => void,
  i18n: Object,
  history: Object,
  onModuleReady: () => void,
};

class Module extends React.Component<Props> {
  watchPermissionRefreshed = () => {
    window.addEventListener("AdminPermissionsRefreshed", this.refreshNav);
  };

  stopWatchPermissionRefreshed = () => {
    window.removeEventListener("AdminPermissionsRefreshed", this.refreshNav);
  };

  refreshNav = () => {
    const { registerNav } = this.props;
    const adminPermissions = SwanContextManager.getValue("adminPermissions");
    registerNav(
      ModuleDefinition.mainNav,
      ModuleDefinition.productNav(adminPermissions),
      ModuleDefinition.name,
      ModuleDefinition.displayName
    );
  };

  componentDidMount() {
    const {
      registerNav,
      registerRoutes,
      history,
      i18n,
      onModuleReady,
    } = this.props;

    this.watchPermissionRefreshed();

    const adminPermissions = SwanContextManager.getValue("adminPermissions");
    registerNav(
      ModuleDefinition.mainNav,
      ModuleDefinition.productNav(adminPermissions),
      ModuleDefinition.name,
      ModuleDefinition.displayName
    );

    const routes = ModuleDefinition.routes;
    const enhancedRoutes = routes.map(route => ({
      ...route,
      component: undefined,
      render: props => (
        <CurrentModuleContext.Provider value={{ history, i18n }}>
          <route.component {...props} />
        </CurrentModuleContext.Provider>
      ),
    }));
    registerRoutes(enhancedRoutes, ModuleDefinition.name);

    onModuleReady();
  }

  componentWillUnmount() {
    this.stopWatchPermissionRefreshed();
  }

  render() {
    return null;
  }
}

export const CurrentModule = ({
  onModuleReady,
  ...restProps
}: {
  onModuleReady: () => void,
}) => (
  <SwanModuleContext.Consumer>
    {({ registerRoutes, registerNav, history, i18n, user }) => (
      <Module
        registerRoutes={registerRoutes}
        registerNav={registerNav}
        i18n={i18n}
        history={history}
        onModuleReady={onModuleReady}
        {...restProps}
      />
    )}
  </SwanModuleContext.Consumer>
);
export const Definition = ModuleDefinition;
export default CurrentModule;
