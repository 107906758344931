import React from "react";
import countryList from "country-list";
import SearchableDropdown from "./SearchableDropdown";
import { type FieldProps } from "./partials/FieldWrapper";

export type InputProps = FieldProps & {
  placeholder?: string,
};

const Field = (props: InputProps) => {
  const countries = countryList.getCodeList();
  const data = [];
  Object.keys(countries).forEach(code => {
    data.push({
      value: code,
      label: countries[code],
    });
  });

  const itemIdAccessor = (item: Object | string) => {
    if (item === Object(item) && typeof item !== "string") {
      return item.value;
    }

    if (typeof item === "string" && countries[item] === item) {
      return countries[item];
    }
    return item;
  };

  const itemLabelAccessor = (item: Object | string) => {
    if (item === Object(item) && typeof item !== "string") {
      return item.label;
    }

    if (typeof item === "string" && countries[item]) {
      return countries[item];
    }

    return item;
  };

  const getValue = (
    SearchableOptions: Array<{ id: string | number, label: string }>,
    value: string | Object
  ) => {
    if (value === Object(value)) {
      return value;
    }

    if (SearchableOptions && value) {
      const selectedItem = SearchableOptions.find(
        v =>
          value === itemIdAccessor(v) ||
          value === itemLabelAccessor(v) ||
          value === v.id
      );
      if (selectedItem) {
        return selectedItem;
      }
    }
    return value;
  };

  const { name } = props;

  return (
    <SearchableDropdown
      name={name}
      data={data}
      itemLabelAccessor={itemLabelAccessor}
      itemIdAccessor={itemIdAccessor}
      getValue={getValue}
      {...props}
    />
  );
};

export default Field;
