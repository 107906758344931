import IMG_BRAND_LOGO_PNG from "./img/brand/logo.png";
import IMG_BRAND_LOGO_JS from "./img/brand/logo.js";
import IMG_BRAND_LOGO_SVG from "./img/brand/logo.svg";

// LOGO VARIENTS
import IMG_BRAND_LOGO_1_PNG from "./img/brand/logo_1.png";

export const IMAGES = {
  BRAND: {
    LOGO: {
      png: IMG_BRAND_LOGO_PNG,
      js: IMG_BRAND_LOGO_JS,
      svg: IMG_BRAND_LOGO_SVG,
    },
    LOGO_WHITE: {
      png: IMG_BRAND_LOGO_PNG,
    },
    LOGO_BLUE: {
      png: IMG_BRAND_LOGO_1_PNG,
    },
  },
};

export default {
  IMAGES,
};
