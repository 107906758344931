import React, { type Node } from "react";
import { RecordProvider /* , Form */ } from "@swan/helpers/CRUD";
import { DynamicForm, ConnectedForm } from "@swan/dynamic-ui"; // eslint-disable-line
import { schemaListedColumns } from "@swan/form-designer/utils";
import TableRowRenderer from "../../../../TableRowRenderer";

type TableRowFormProps = {
  object: string,
  type: string,
  service: Object,
  ServiceFactory: (object: string, data: {}) => Object,
  columns: Node,
  data: Object,
  closeHandler: Function,
  form: Object,
};

class TableRowForm extends React.Component<TableRowFormProps> {
  constructor(props: any) {
    super(props);
    const { object, type, service, ServiceFactory } = this.props;
    const Form = ConnectedForm({ object, type, ServiceFactory })(() => (
      <span />
    ));
    this.Component = RecordProvider(service)(Form);
  }

  Component: any;

  render() {
    const {
      object,
      type,
      ServiceFactory,
      closeHandler,
      form,
      ...restProps
    } = this.props;
    const { Component } = this;
    return (
      <Component
        noSave
        disableDelete
        silentLoading
        form={form}
        {...restProps}
        FormComponent={({ schema, onChange, ...formProps }) => (
          <TableRowRenderer
            schema={schema}
            pickbyFields={schemaListedColumns(schema)}
            hideLabel
            onChange={onChange}
            closeHandler={closeHandler}
            {...restProps}
            {...formProps}
          />
        )}
        renderContext="tableRow"
        goBack={closeHandler}
      />
    );
  }
}
export default TableRowForm;
