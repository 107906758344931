import { transform, isEqual, isObject } from "lodash";

// Return a new object who represent the diff
export const difference = (object: any, base: any) =>
  transform(object, (result, value, key) => {
    if (!isEqual(value, base[key])) {
      // eslint-disable-next-line
      result[key] =
        isObject(value) && isObject(base[key])
          ? difference(value, base[key])
          : value;
    }
  });

export default difference;
