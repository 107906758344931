export default (resourceType: string, actionsPrefix: string = "") => {
  const pluralName = `${resourceType}s`;
  const nameInAction = resourceType.toUpperCase();
  const nameInActionPlural = pluralName.toUpperCase();

  return {
    [`CLEAR_${nameInActionPlural}`]: `${actionsPrefix}/CLEAR_${nameInActionPlural}`,

    [`LOAD_${nameInActionPlural}`]: `${actionsPrefix}/LOAD_${nameInActionPlural}`,
    [`LOAD_MORE_${nameInActionPlural}`]: `${actionsPrefix}/LOAD_MORE_${nameInActionPlural}`,
    [`LOAD_${nameInAction}`]: `${actionsPrefix}/LOAD_${nameInAction}`,
    [`UPDATE_${nameInAction}`]: `${actionsPrefix}/UPDATE_${nameInAction}`,

    [`INIT_${nameInAction}`]: `${actionsPrefix}/INIT_${nameInAction}`,
    [`INIT_${nameInAction}_COMPLETED`]: `${actionsPrefix}/INIT_${nameInAction}_COMPLETED`,

    [`CREATE_${nameInAction}`]: `${actionsPrefix}/CREATE_${nameInAction}`,
    [`DELETE_${nameInAction}`]: `${actionsPrefix}/DELETE_${nameInAction}`,
    [`UPDATE_${nameInAction}_RESET`]: `${actionsPrefix}/UPDATE_${nameInAction}_RESET`,

    [`LOAD_${nameInActionPlural}_BEGINS`]: `${actionsPrefix}/LOAD_${nameInActionPlural}_BEGINS`,
    [`LOAD_${nameInActionPlural}_COMPLETED`]: `${actionsPrefix}/LOAD_${nameInActionPlural}_COMPLETED`,
    [`LOAD_${nameInActionPlural}_FAILED`]: `${actionsPrefix}/LOAD_${nameInActionPlural}_FAILED`,
    [`LOAD_MORE_${nameInActionPlural}_BEGINS`]: `${actionsPrefix}/LOAD_MORE_${nameInActionPlural}_BEGINS`,
    [`LOAD_MORE_${nameInActionPlural}_COMPLETED`]: `${actionsPrefix}/LOAD_MORE_${nameInActionPlural}_COMPLETED`,
    [`LOAD_MORE_${nameInActionPlural}_FAILED`]: `${actionsPrefix}/LOAD_MORE_${nameInActionPlural}_FAILED`,
    [`LOAD_MORE_${nameInActionPlural}_RESET`]: `${actionsPrefix}/LOAD_MORE_${nameInActionPlural}_RESET`,
    [`LOAD_${nameInAction}_BEGINS`]: `${actionsPrefix}/LOAD_${nameInAction}_BEGINS`,
    [`LOAD_${nameInAction}_COMPLETED`]: `${actionsPrefix}/LOAD_${nameInAction}_COMPLETED`,
    [`LOAD_${nameInAction}_FAILED`]: `${actionsPrefix}/LOAD_${nameInAction}_FAILED`,
    [`UPDATE_${nameInAction}_BEGINS`]: `${actionsPrefix}/UPDATE_${nameInAction}_BEGINS`,
    [`UPDATE_${nameInAction}_COMPLETED`]: `${actionsPrefix}/UPDATE_${nameInAction}_COMPLETED`,
    [`UPDATE_${nameInAction}_FAILED`]: `${actionsPrefix}/UPDATE_${nameInAction}_FAILED`,
    [`UPDATE_${nameInAction}_RESET`]: `${actionsPrefix}/UPDATE_${nameInAction}_RESET`,
    // TODO do we need to create separate FAILED and BEGINS for create and delete or they all act the same ?
    [`CREATE_${nameInAction}_COMPLETED`]: `${actionsPrefix}/CREATE_${nameInAction}_COMPLETED`,
    [`DELETE_${nameInAction}_COMPLETED`]: `${actionsPrefix}/DELETE_${nameInAction}_COMPLETED`,
    [`REFRESH_${nameInActionPlural}`]: `${actionsPrefix}/REFRESH_${nameInActionPlural}`,
    // following for websocket
    [`SUBSCRIBE_${nameInAction}`]: `${actionsPrefix}/SUBSCRIBE_${nameInAction}`,
    [`UNSUBSCRIBE_${nameInAction}`]: `${actionsPrefix}/UNSUBSCRIBE_${nameInAction}`,

    [`SUBSCRIBE_${nameInAction}_STARTED`]: `${actionsPrefix}/SUBSCRIBE_${nameInAction}_STARTED`,
    [`UNSUBSCRIBE_${nameInAction}_STARTED`]: `${actionsPrefix}/UNSUBSCRIBE_${nameInAction}_STARTED`,

    [`SUBSCRIBE_${nameInActionPlural}_NEW_RECORDS`]: `${actionsPrefix}/SUBSCRIBE_${nameInActionPlural}_NEW_RECORDS`,
    [`SUBSCRIBE_${nameInActionPlural}_NEW_RECORDS_STARTED`]: `${actionsPrefix}/SUBSCRIBE_${nameInActionPlural}_NEW_RECORDS_STARTED`,
    [`SUBSCRIBE_${nameInActionPlural}_NEW_RECORDS_COMPLETED`]: `${actionsPrefix}/SUBSCRIBE_${nameInActionPlural}_NEW_RECORDS_COMPLETED`,
    [`SUBSCRIBE_${nameInActionPlural}_NEW_RECORDS_FAILED`]: `${actionsPrefix}/SUBSCRIBE_${nameInActionPlural}_NEW_RECORDS_FAILED`,
    [`UNSUBSCRIBE_${nameInActionPlural}_NEW_RECORDS`]: `${actionsPrefix}/UNSUBSCRIBE_${nameInActionPlural}_NEW_RECORDS`,
    [`UNSUBSCRIBE_${nameInActionPlural}_NEW_RECORDS_STARTED`]: `${actionsPrefix}/UNSUBSCRIBE_${nameInActionPlural}_NEW_RECORDS_STARTED`,
    [`LOAD_${nameInAction}_SUBSCRIPTION_COMPLETED`]: `${actionsPrefix}/LOAD_${nameInAction}_SUBSCRIPTION_COMPLETED`,

    // duplicate record
    [`DUPLICATE_${nameInAction}_BEGINS`]: `${actionsPrefix}/DUPLICATE_${nameInAction}_BEGINS`,
    [`DUPLICATE_${nameInAction}`]: `${actionsPrefix}/DUPLICATE_${nameInAction}`,
    [`DUPLICATE_${nameInAction}_COMPLETED`]: `${actionsPrefix}/DUPLICATE_${nameInAction}_COMPLETED`,
    [`DUPLICATE_${nameInAction}_FAILED`]: `${actionsPrefix}/DUPLICATE_${nameInAction}_FAILED`,

    // reorder record
    [`REORDER_${nameInAction}_BEGINS`]: `${actionsPrefix}/REORDER_${nameInAction}_BEGINS`,
    [`REORDER_${nameInAction}`]: `${actionsPrefix}/REORDER_${nameInAction}`,
    [`REORDER_${nameInAction}_COMPLETED`]: `${actionsPrefix}/REORDER_${nameInAction}_COMPLETED`,
    [`REORDER_${nameInAction}_FAILED`]: `${actionsPrefix}/REORDER_${nameInAction}_FAILED`,

    // export records
    [`EXPORT_${nameInActionPlural}`]: `${actionsPrefix}/EXPORT_${nameInActionPlural}`,
    [`EXPORT_${nameInActionPlural}_BEGINS`]: `${actionsPrefix}/EXPORT_${nameInActionPlural}_BEGINS`,
    [`EXPORT_${nameInActionPlural}_COMPLETED`]: `${actionsPrefix}/EXPORT_${nameInActionPlural}_COMPLETED`,
    [`EXPORT_${nameInActionPlural}_FAILED`]: `${actionsPrefix}/EXPORT_${nameInActionPlural}_FAILED`,
  };
};
