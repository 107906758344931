import { toast as toastify } from "react-toastify";

type Options = {
  type?: string,
};
/**
 * types [error,info,warning,dark,default]
 * @param message
 * @param options
 * @returns {*}
 */
export const toast = (message: any, options?: Options = { type: "info" }) => {
  toastify.configure({ ...options });
  return toastify[options.type](message);
};

export const dismissToast = (toastId: string) => {
  toastify.dismiss(toastId);
};
