class Service {
  parent: number;

  data: Array<Object>;

  onChange: Function;

  createdCount = 0;

  constructor(data: Array<Object>, onChange?: Function) {
    this.data = data || [];
    this.onChange = onChange;
  }

  setParent(id: number) {
    this.parent = id;
  }

  setData(data: any) {
    this.data = data;
  }

  loadRecord(id: number) {
    for (let i = 0; i < this.data.length; i += 1) {
      if (this.data[i].id === id) {
        return Promise.resolve(this.data[i]);
      }
    }
    return Promise.resolve({});
  }

  get(id: number) {
    return this.loadRecord(id);
  }

  create(values: Object) {
    this.createdCount += 1;
    this.data.push({
      ...values,
      id: `n${this.createdCount}`,
    });
    if (this.onChange) {
      this.onChange(this.data);
    }
    return Promise.resolve();
  }

  update(id: Number, values: Object) {
    for (let i = 0; i < this.data.length; i += 1) {
      if (this.data[i].id === id) {
        this.data[i] = { ...this.data[i], ...values };
      }
    }
    if (this.onChange) {
      this.onChange(this.data);
    }
    return Promise.resolve();
  }

  delete(id: Number) {
    for (let i = 0; i < this.data.length; i += 1) {
      if (this.data[i].id === id) {
        this.data.splice(i, 1);
      }
    }
    if (this.onChange) {
      this.onChange(this.data);
    }
    return Promise.resolve();
  }
}

export default Service;
