export default {
  uid: "lastCall",
  attributeId: "",
  formId: "",
  pencilDef: {
    title: "Last Call",
    group: "FIELD",
    handleIcon: "FaPhone",
    styles: {},
  },
  options: {
    title: "Last Call",
    type: "lastCall",
  },
};
