import * as React from "react";
import { ListDataProvider, LocalListDataProvider } from "@swan/helpers/CRUD";
import CardList from "./Card/CardList"; // eslint-disable-line
import CardListOrderingWrapper from "./Card/CardListOrderingWrapper";

type GridListProps = {
  listId: string,
  object: string,
  service: Object,
  useLocalData: boolean,
};

class GridList extends React.Component<GridListProps> {
  constructor(props: any) {
    super(props);
    const { service, useLocalData } = props;
    if (useLocalData) {
      this.Component = LocalListDataProvider(CardListOrderingWrapper(CardList));
    } else {
      this.Component = ListDataProvider(service)(
        CardListOrderingWrapper(CardList)
      );
    }
  }

  Component: any;

  render() {
    const { listId, object, ...rest } = this.props;
    const { Component } = this;
    return <Component isLoadMore listId={listId} object={object} {...rest} />;
  }
}

export default GridList;
