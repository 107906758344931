import Axios from "axios";
import { ServiceGenerator } from "@swan/api"; // eslint-disable-line
import PermissionsStorage from "./storage";
import { CachePermissionsServiceInstance } from "./services/PermissionsBatchChecker";

const Base = ServiceGenerator(Axios, "auth/user");

export class AuthServiceBase extends Base {
  getAllPermissions() {
    return this.get("permissions");
  }

  getDirectPermissions() {
    return this.get("directpermissions");
  }

  /**
   * Loads all permissions of a given group
   */
  getPermissionsByGroup(group: string): Promise<{ [string]: boolean }> {
    return this.get("permissionsbygroup", { group }).then(
      (permissions: { [string]: boolean }) => permissions
    );
  }

  /**
   * Check multiple permission items and return an object mapping each permission to a bool
   * @param {string} permission Permission name
   */
  getPermissions(permission: Array<string>): Promise<{ [string]: boolean }> {
    return this.get("isallowed", { permission });
  }

  /**
   * Check a single permission item
   * @param {string} permission Permission name
   */
  /* eslint-disable class-methods-use-this */
  // Disabled for backward compatibility, cannot convert to static
  isAllowed(permission: string): Promise<boolean> {
    return CachePermissionsServiceInstance.isAllowed(permission);
  }
  /* eslint-enable class-methods-use-this */

  /**
   * Check if user is allowed any of the given permissions
   */
  isAllowedAny(permissions: Array<string>): Promise<boolean> {
    if (permissions.length === 1) {
      // Take advantage of batching since only 1 permission
      return this.isAllowed(permissions[0]);
    }
    return new Promise(resolve => {
      this.getPermissions(permissions).then(perms => {
        let found = false;
        Object.keys(perms).forEach(p => {
          if (found) return;
          if (perms[p] === true) {
            resolve(true);
            found = true;
          }
        });
        if (!found) {
          resolve(false);
        }
      });
    });
  }

  /**
   * Check if user is allowed all permissions given
   */
  isAllowedAll(permissions: Array<string>): Promise<boolean> {
    if (permissions.length === 1) {
      // Take advantage of batching since only 1 permission
      return this.isAllowed(permissions[0]);
    }
    return new Promise(resolve => {
      this.getPermissions(permissions).then(perms => {
        let found = false;
        Object.keys(perms).forEach(p => {
          if (found) return;
          if (perms[p] === false) {
            resolve(false);
            found = true;
          }
        });
        if (!found) {
          resolve(true);
        }
      });
    });
  }
}

class AuthService extends AuthServiceBase {
  permissions = {};

  loadedGroups = {};

  /**
   * Restore state from local storage
   */
  restoreState() {
    const storage = new PermissionsStorage();
    const perms = storage.loadPermissions();
    if (perms) {
      this.permissions = perms.permissions;
      this.loadedGroups = perms.loadedGroups;
    }
  }

  /**
   * Persist loaded permissions to local storage
   */
  persist() {
    const storage = new PermissionsStorage();
    storage.storePermissions({
      permissions: this.permissions,
      loadedGroups: this.loadedGroups,
    });
  }

  /**
   * Checks if a given permission item has been loaded already
   * @param {permission} permission Permission name
   */
  isPermissionLoaded(permission: string): boolean {
    return typeof this.permissions[permission] !== "undefined";
  }

  /**
   * Loads all permissions of a given group, will update the permission state to mark the group as loaded
   * and add the permission items to the list
   */
  getPermissionsByGroup(group: string): Promise<{ [string]: boolean }> {
    if (this.loadedGroups[group]) {
      return Promise.resolve(this.loadedGroups[group].permissions);
    }
    return super
      .getPermissionsByGroup(group)
      .then((permissions: { [string]: boolean }) => {
        this.loadedGroups[group] = {
          date: new Date(),
          permissions,
        };
        this.permissions = { ...this.permissions, ...permissions };
        return permissions;
      });
  }

  /**
   * Check a single permission item, will check if user allowed this permission and add to state if not already there
   */
  isAllowed(permission: string): Promise<boolean> {
    if (typeof this.permissions[permission] !== "undefined") {
      return Promise.resolve(this.permissions[permission]);
    }
    return super.isAllowed(permission).then((allowed: boolean) => {
      this.permissions[permission] = allowed;
      return allowed;
    });
  }

  /**
   * Get the permission of multiple items in a single shot. Will return an object mapping each item to a bool
   */
  getPermissions(permissions: Array<string>): Promise<{ [string]: boolean }> {
    return new Promise(resolve => {
      const toLoad = [];
      const ret = {};
      permissions.forEach(perm => {
        if (!this.isPermissionLoaded(perm)) {
          toLoad.push(perm);
        } else {
          ret[perm] = this.permissions[perm];
        }
      });

      if (toLoad.length === 0) {
        return resolve(ret);
      }
      return super.getPermissions(permissions).then(newPermissions => {
        this.permissions = { ...this.permissions, ...newPermissions };
        return resolve({
          ...ret,
          ...newPermissions,
        });
      });
    });
  }
}

export default AuthService;
