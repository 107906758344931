// @flow
import { ATTRIB, RECORD, FORM } from "./templates";
import {
  SHOW_FIELD,
  HIDE_FIELD,
  HIDE_LAYOUT,
  REQUIRE_FIELD,
  SET_VALUE,
  SET_OPTIONS,
  READONLY_FIELDS,
  READONLY_FIELD,
} from "./verbs";
import Actions from "./actionFunctions";

import { type Action } from "./flowTypes";

const dictionary: Array<Action> = [
  {
    label: "Show Field",
    id: SHOW_FIELD,
    template: ATTRIB,
    action: Actions[SHOW_FIELD],
    type: "LAYOUT",
  },
  {
    label: "Hide Field",
    id: HIDE_FIELD,
    template: ATTRIB,
    action: Actions[HIDE_FIELD],
    type: "LAYOUT",
  },
  {
    label: "Hide Layout",
    id: HIDE_LAYOUT,
    template: FORM,
    action: Actions[HIDE_LAYOUT],
    type: "LAYOUT",
  },
  {
    label: "Make Field Required",
    id: REQUIRE_FIELD,
    template: ATTRIB,
    action: Actions[REQUIRE_FIELD],
    type: "LAYOUT",
  },
  {
    label: "Set Value",
    id: SET_VALUE,
    template: RECORD,
    action: Actions[SET_VALUE],
    type: "LAYOUT",
  },
  {
    label: "Set Options",
    id: SET_OPTIONS,
    template: ATTRIB,
    action: Actions[SET_OPTIONS],
    type: "INTERNAL",
  },
  {
    label: "Show warning message",
    id: "SHOW_WARNING",
    template: FORM,
    action: undefined,
    type: "VALIDATION",
  },
  {
    label: "Readonly Fields",
    id: READONLY_FIELDS,
    template: ATTRIB,
    action: Actions[READONLY_FIELDS],
    type: "LAYOUT",
  },
  {
    label: "Readonly Field",
    id: READONLY_FIELD,
    template: ATTRIB,
    action: Actions[READONLY_FIELD],
    type: "LAYOUT",
  },
];

export default dictionary;
