import { useState, useEffect } from "react";

const Paginator = ({ list, perPage = 10, children }: any) => {
  const [paginatedList, setPaginatedList] = useState([]);
  const [loadedChunks, setLoadedChunks] = useState(0);
  const [page, setPage] = useState(1);

  const chunks = Math.ceil(list.length / perPage);

  const paginate = () => {
    const end = page === 1 ? perPage : page * perPage;

    setPaginatedList(list.slice(0, end));

    setLoadedChunks(loadedChunks + 1);
  };

  const loadMore = () => {
    if (loadedChunks < chunks) {
      setPage(page + 1);
    }
  };

  useEffect(
    () => {
      paginate();
    },
    [page]
  );

  return children({
    list: paginatedList,
    canLoadMore: loadedChunks < chunks,
    loadMore,
  });
};

export default Paginator;
