// @flow
import { toString, trimStart, trimEnd } from "lodash";

export default (v: any, trim: boolean = true) => {
  let output = toString(v);
  if (trim) {
    output = trimEnd(trimStart(output));
  }
  return output;
};
