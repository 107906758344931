import React, { type Node } from "react";
import { Jumbotron, Button } from "reactstrap";

type Action = {
  id?: string,
  label: string,
  onClick?: Function,
  color?: string,
  disabled?: boolean,
  type?: string,
  confirmOptions?: Object,
};

type Props = {
  titleSize?: number,
  titleType?: string,
  title?: string,
  children?: Node,
  actions?: Array<Action>,
  hide?: boolean,
  color?: string,
  classNames?: string,
  jumbotronClassName?: string,
  variant?: string,
};

const styles = {
  default: {},
  outline: {
    background: "transparent",
    border: "1px solid #dfe3e6",
  },
};

const CTA = (props: Props) => {
  const {
    titleSize,
    titleType,
    title,
    children,
    actions,
    hide,
    color,
    classNames,
    jumbotronClassName,
    variant,
    ...rest
  } = props;
  if (hide) return null;
  return (
    <Jumbotron
      {...rest}
      className={jumbotronClassName || "swan_cta"}
      style={(styles || {})[variant || "default"] || undefined}
    >
      {title ? (
        <h1
          className={`display-${String(titleSize) ||
            "1"} swan--type-${titleType || "alpha"} text-${color ||
            "normal"} ${classNames || ""}`}
        >
          {title}
        </h1>
      ) : null}
      {children}
      {(actions || []).map(action => (
        <Button
          key={`btn${action.label}`}
          color={action.color || "info"}
          onClick={action.onClick && !action.disabled ? action.onClick : null}
          disabled={action.disabled}
        >
          {action.label}
        </Button>
      ))}
    </Jumbotron>
  );
};

CTA.defaultProps = {
  titleSize: 1,
  titleType: "alpha",
  title: undefined,
  children: undefined,
  actions: [],
  hide: false,
  color: undefined,
  classNames: undefined,
  jumbotronClassName: undefined,
  variant: undefined,
};

export default CTA;
