import * as React from "react";

import styled, { withTheme } from "styled-components";
import { Draggable } from "react-beautiful-dnd";
import { FaCog } from "react-icons/fa";
import {
  // Button,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  // Input,
} from "reactstrap";
import { keys, debounce } from "lodash";
import { connect } from "react-redux";
import ConditionalDroppable from "../DragDrop/ConditionalDroppable";
import ElementsList from "./ElementsList";
import InkDropModal from "./InkDropModal";
import type { Props } from "./types";

// import Parts from "../../definitions/parts";
import Pencils from "../../definitions/index";
import { allDefinitions } from "../../definitions/pencils";
import { InkDropCol, InkDropInput } from "./InkDrop";
import { schemaActions } from "../../state/reducers/schema";
import type { Schema } from "../../constants/flowTypes";
import getObjectFullness from "../../utils/getObjectFullness";
import sanitizeOptions from "../../utils/penOptions/sanitizeOptions";

export const ContainerWrapper = styled.div(({ theme }) =>
  Object.assign(
    {},
    theme.ContainerWrapper ? { ...theme.ContainerWrapper } : {},
    {
      padding: "0.5rem",
    }
  )
);

// styled
const DropDownMenuStyled = styled(DropdownMenu)`
  border-radius: 0;
  border: 1px solid $ui-03;
  &[data-placement="top-end"] {
    box-shadow: 0 1px 1px 0 $ui-03;
  }
  &[data-placement="bottom-end"] {
    box-shadow: 0 0 1px 1px $ui-03;
  }
`;

type State = {
  containerTitle?: string,
  isEditing: boolean,
  dropDownOpen: boolean,
  optionModalOpen: boolean,
};

type ContainerWithTitleProps = Props & {
  updateElementSchema: (string, Schema) => void,
  removeSchemaNode: (id: any) => void,
};

class Container extends React.Component<ContainerWithTitleProps, State> {
  constructor(props: ContainerWithTitleProps) {
    super(props);
    this.state = {
      containerTitle: props.schema ? props.schema.title : "",
      isEditing: false,
      dropDownOpen: false,
      optionModalOpen: false,
    };
    this.debouncedUpdateElementSchema = debounce(
      this.debouncedUpdateElementSchema,
      1000
    );
  }

  toggleInlineEditing = (): void => {
    this.setState(prevState => ({
      isEditing: !prevState.isEditing,
    }));
  };

  updateTitle = (containerTitle: string): void => {
    this.setState({ containerTitle });
    this.updateContainerTitleSchema(containerTitle);
  };

  updateContainerTitleSchema = (containerTitle: string) => {
    const { schema } = this.props;
    const updatedSchema = Object.assign(
      { ...schema },
      { title: containerTitle }
    );
    this.debouncedUpdateElementSchema(updatedSchema);
  };

  debouncedUpdateElementSchema = (updatedSchema: Object) => {
    const { id, updateElementSchema } = this.props;
    updateElementSchema(id, updatedSchema);
  };

  toggleDropDown = (): void => {
    this.setState(prevState => ({ dropDownOpen: !prevState.dropDownOpen }));
  };

  toggleOptionModal = (): void => {
    this.setState(prevState => ({
      optionModalOpen: !prevState.optionModalOpen,
    }));
  };

  markAs = (
    optionProperty: string = "required",
    makeItTrue: boolean = true
  ) => {
    const { id, schema, updateElementSchema } = this.props;
    let updatedSchema = { ...schema };
    if (!(updatedSchema || {}).options) {
      updatedSchema = Object.assign({ ...updatedSchema }, { options: {} });
    }
    updatedSchema = Object.assign(
      { ...updatedSchema },
      { options: { ...updatedSchema.options, [optionProperty]: makeItTrue } }
    );
    updateElementSchema(id, updatedSchema);
  };

  // handler for removing ink-drop from schema
  removeInkDrop = () => {
    const { id, removeSchemaNode } = this.props;
    removeSchemaNode(id);
  };

  // handler for saving inkdrop options
  onSubmitHandler = ({ formData }: { formData: any }) => {
    const { id, schema, updateElementSchema } = this.props;

    const formDataSanitized = getObjectFullness(formData);
    const updatedSchema = Object.assign(
      { ...schema },
      { options: formDataSanitized },
      { title: formData.title }
    );

    this.setState(
      {
        containerTitle: formData.title,
      },
      () => {
        updateElementSchema(id, updatedSchema);
        this.toggleOptionModal();
      }
    );
  };

  render() {
    const { containerTitle, dropDownOpen, optionModalOpen } = this.state;
    const { schema, id, index } = this.props;
    const { id: schemaId, type: pencilType, options, title } = schema;

    const pencilDefOptions: Object =
      pencilType && ((allDefinitions || {})[pencilType] || {}).options
        ? allDefinitions[pencilType].options
        : undefined;

    const pencilIsToggled =
      options &&
      (options || {}).toggleSection &&
      options.toggleSection === true;

    return (
      <Draggable
        draggableId={id}
        index={index || 0}
        isDragDisabled={id === "@"}
      >
        {draggableProvided => (
          <ContainerWrapper
            ref={draggableProvided.innerRef}
            {...draggableProvided.draggableProps}
            {...draggableProvided.dragHandleProps}
            droppedCount={keys(schema.properties).length}
          >
            <InkDropCol isTitle size={9} colName="inkTitle">
              <InkDropInput
                // isEditing={isEditing}
                bsSize="sm"
                type="text"
                // isTitle
                value={containerTitle}
                onBlur={() => this.toggleInlineEditing()}
                onChange={e => this.updateTitle(e.target.value)}
                onKeyPress={e =>
                  e.key === "Enter" ? this.toggleInlineEditing() : null
                }
              />
              <InkDropCol size={1} styles={{ padding: "0 .5rem" }}>
                <Dropdown
                  isOpen={dropDownOpen}
                  toggle={this.toggleDropDown}
                  direction="down"
                >
                  <DropdownToggle
                    size="sm"
                    outline
                    className="outline outline-clear"
                  >
                    <FaCog />
                  </DropdownToggle>

                  <DropDownMenuStyled right>
                    <DropdownItem
                      onClick={() => {
                        this.markAs("toggleSection", !pencilIsToggled);
                      }}
                    >
                      {!pencilIsToggled ? (
                        <span>Enable Toggle</span>
                      ) : (
                        <span>Disable Toggle</span>
                      )}
                    </DropdownItem>
                    <DropdownItem divider />
                    <DropdownItem
                      onClick={() => {
                        this.toggleOptionModal();
                      }}
                    >
                      Edit Properties
                    </DropdownItem>
                    <DropdownItem divider />
                    <DropdownItem onClick={this.removeInkDrop}>
                      Delete this
                    </DropdownItem>
                  </DropDownMenuStyled>
                </Dropdown>
              </InkDropCol>
            </InkDropCol>

            {/* note: keep here until, we'd find there'd be no requirement for modal to popup in this case */}
            <InkDropModal
              title="Options"
              pencilType={pencilType || ""}
              schemaId={schemaId || ""}
              pencilDefOptions={pencilDefOptions}
              isOpen={optionModalOpen}
              toggleHandler={this.toggleOptionModal}
              onCloseHandler={this.toggleOptionModal}
              onSubmitHandler={this.onSubmitHandler}
              initialFormData={Object.assign(
                {},
                { ...sanitizeOptions(options, pencilDefOptions) },
                { title: containerTitle || title }
              )}
            />

            <ConditionalDroppable
              droppableId={id}
              excludeTypes={
                Pencils.pencils.layoutDefinitions.container.drop.excludeTypes
              }
              droppedCount={keys(schema.properties).length}
              droppableWrapper="Container"
            >
              <ElementsList id={id} schema={schema} index={0} />
            </ConditionalDroppable>
          </ContainerWrapper>
        )}
      </Draggable>
    );
  }
}

export default connect(
  null,
  {
    updateElementSchema: schemaActions.updateElementSchema,
    removeSchemaNode: schemaActions.removeSchemaNode,
  }
)(withTheme(Container));
