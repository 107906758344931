import React from "react";
import styled from "styled-components";
import { Button } from "reactstrap";

const FabButton = styled(Button)`
  position: fixed;
  bottom: 25px;
  right: 25px;
  width: 42px;
  height: 42px;
  border-radius: 21px;
  box-shadow: 0px 3px 5px -1px #00000033, 0px 6px 10px 0px #00000040,
    0px 1px 18px 0px #00000036;
  background-color: #4caf50;
  border-color: #4caf50;
`;

const FAB = (props: any) => <FabButton {...props} />;

export default FAB;
