import { schemaDataSource } from "@swan/form-designer";

const findSelected = (
  selected: string | number | boolean,
  items: Array<Object> = []
) => {
  if (items && Array.isArray(items) && items.length) {
    return (items.find(
      item =>
        (item || {})["value" || "id"] &&
        item["value" || "id"] === selected &&
        (item || {})["name" || "label"]
    ) || {})["name" || "label"];
  }
  return undefined;
};

export default (selected: string | number | boolean, options: Object) => {
  // options can be either schema-options or schema itself
  let displayValue;
  const items = (options || {}).items || ((options || {}).options || {}).items;

  if (items) displayValue = findSelected(selected, items);
  if (displayValue) return displayValue;

  if (!displayValue) {
    const standardItems =
      (options || {}).standardItems ||
      ((options || {}).options || {}).standardItems;

    if (
      standardItems &&
      Array.isArray(schemaDataSource) &&
      schemaDataSource.length
    ) {
      const { data: dataSource } =
        schemaDataSource.find(
          source => (source || {}).value === standardItems
        ) || {};
      if (dataSource) return findSelected(selected, dataSource);
    }
  }

  return undefined;
};
