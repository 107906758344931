import * as React from "react";
import { Input } from "@swan/themer";

import Field from "./partials/Field";
import type { ElementProps } from "./types";

const Sequence = ({ schema, data, hideLabel }: ElementProps) => (
  <Input
    {...schema.options || {}}
    name={schema.attributeName || ""}
    value={(data || {})[schema.attributeName || ""]}
    label={schema.label}
    hideLabel={hideLabel || (schema.options || {}).hideLabel}
    placeholder={schema.label}
    static
  />
);

export default Field(Sequence);
