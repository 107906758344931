import React from "react";
import { DuplicationsResourceFactory, WebsocketService } from "@swan/api"; // eslint-disable-line
import { dismissToast, toast } from "@swan/themer"; // eslint-disable-line
import { withRouter } from "react-router-dom"; // eslint-disable-line
import { RendererSubscriber } from "../Main";
import type { ElementProps } from "./types";
import Field from "./partials/Field";

type Props = {
  schema: Object,
  object: string,
  history: Object,
  addButtons: Function, // Function to add buttons to the top bar
  duplicateRecord: Function,
  data: Object,
  setLock: Function,
  match: Object,
};

type State = {
  isLocked: boolean,
};

class DuplicateButton extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      isLocked: false,
    };
  }

  componentDidMount(): void {
    const { addButtons, data } = this.props;
    const service = this.getService();

    service.setParent(data.id);

    if (!data.id || data.id === "new") {
      return;
    }
    const duplicateButton = {
      id: `duplicate_${data.id}`,
      label: "Duplicate",
      onClick: () => {
        this.duplicate();
      },
    };
    const buttons = [];
    buttons.push(duplicateButton);
    if (typeof addButtons === "function") {
      addButtons(buttons);
    }
  }

  getService() {
    const { schema } = this.props;
    if (this.service) {
      return this.service;
    }

    const { options: { objectName } = {} } = schema;
    const VersionService = DuplicationsResourceFactory(objectName);
    this.service = new VersionService();
    return this.service;
  }

  lock(isLock: boolean) {
    const { setLock } = this.props;
    if (isLock === true) {
      this.setState({ isLocked: true });
      setLock(true);
    } else {
      this.setState({ isLocked: false });
      setLock(true);
    }
    return true;
  }

  duplicate() {
    const { duplicateRecord, object, data, history } = this.props;
    const { isLocked } = this.state;
    if (isLocked) {
      return true;
    }
    if (!data.id || data.id === "new") {
      return true;
    }
    history.goBack();
    this.lock(true);
    duplicateRecord(data.id, object, response => {
      this.lock(false);
      if (!response.error) {
        this.subscribe(data.id);
      }
    });
    return true;
  }

  subscribe(id: number) {
    const { object, history, match } = this.props;

    const oldPath = match.path;
    const toastId = toast("Duplication started please wait...", {
      autoClose: false,
      type: "info",
    });
    history.goBack();
    WebsocketService.join(
      `SWAN.DUPLICATION.${object}.${id}`,
      "\\Mazrui\\SwanNotification\\Events\\Websocket\\GenericObjectEvent",
      e => {
        if (e.record && e.record.id) {
          dismissToast(toastId);
          toast(e.message, { autoClose: 8000, type: "info" });
          history.push(oldPath.replace(":id", e.record.id));
        }
      }
    );
  }

  service: Object;

  render() {
    // TODO implement inline duplication later
    return null;
  }
}

/**
 * used to pass addButtons to DuplicateButton
 * @param props
 * @return {*}
 * @constructor
 */
const DuplicationButtonWrapper = (props: ElementProps) => (
  <RendererSubscriber>
    {({ object, addButtons, data, duplicateRecord, setLock }) => (
      <DuplicateButton
        {...props}
        object={object}
        data={data}
        addButtons={addButtons}
        setLock={setLock}
        duplicateRecord={duplicateRecord}
      />
    )}
  </RendererSubscriber>
);

export default withRouter(Field(DuplicationButtonWrapper));
