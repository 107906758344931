import { getStore } from "../state/store";
import { schemaCompare } from "../state/utils/schema";

export const getSchemaDiff = () => {
  const store = getStore();
  const {
    schema: {
      schema,
      _uphold: { schemaTree: upholdData },
    },
  } = store.getState();
  return schemaCompare(upholdData, schema);
};

export const getSchema = () => {
  const store = getStore();
  const {
    schema: { schema },
  } = store.getState();
  return schema;
};

export default getSchemaDiff;
