import { IMAGES } from "../../assets";

export const NAV_GROUPS = {
  DEFAULT: "DEFAULT",
  USER_ORGANIZATIONS: "USER_ORGANIZATIONS",
};

export const PAGE_HEADER_BRAND = IMAGES.BRAND.LOGO_BLUE.png;

export const AVATAR_UPLOADER_URL =
  "https://outlook.office365.com/ecp/PersonalSettings/EditAccount.aspx?chgPhoto=1&exsvurl=1&realm=mazrui.ae";
