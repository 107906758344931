import React from "react";
import { Route, Redirect } from "react-router-dom";
import AuthorizedComponent from "./AuthorizedComponent";

type AuthorizedRouteProps = {
  Permission: string | Array<string>,
  MatchAll?: boolean,
  RedirectOnNotAllowed?: boolean,
};

const AuthorizedRoute = ({
  MatchAll,
  RedirectOnNotAllowed,
  ...restProps
}: AuthorizedRouteProps) => (
  <AuthorizedComponent
    MatchAll={MatchAll}
    fallback={RedirectOnNotAllowed ? <Redirect to="/403" /> : null}
    {...restProps}
    Component={Route}
  />
);

AuthorizedRoute.defaultProps = {
  MatchAll: true,
  RedirectOnNotAllowed: true,
};

export default AuthorizedRoute;
