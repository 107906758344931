import Axios from "axios";
import { ResourceGenerator } from "@swan/api"; // eslint-disable-line

const ObjectService = ResourceGenerator(Axios, "administration/objects");

/* eslint-disable-next-line func-names */
ObjectService.prototype.setObject = function(object: string) {
  this.object = object;
  this.prefix = `administration/objects/${this.object}`;
};

export { ObjectService };

export default {};
