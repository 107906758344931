import * as React from "react";
import { Nav, NavItem, Button } from "reactstrap";

import { type GridProps, type GridOptions } from "./types";

const GridNavBar = ({
  gridProps,
  options,
}: {
  gridProps: GridProps,
  options: GridOptions,
}) =>
  options.buttons ? (
    <div className="my-3">
      <Nav pills>
        {(options.buttons || []).map(btn => (
          <NavItem key={btn.label}>
            <Button
              onClick={() => {
                if (btn.onClick) {
                  btn.onClick(gridProps);
                }
              }}
              className={`mr-3 ${btn.color ? `btn-${btn.color}` : ""}`}
            >
              {btn.label}
            </Button>
          </NavItem>
        ))}
      </Nav>
    </div>
  ) : null;

export default GridNavBar;
