// setup a default options
const options = {
  type: "resourceDisplay",
  title: "Untitled Display",
  resourceDataAccessor: "data.",
  hideIfEmpty: false,
  inputType: "text",
};

export default {
  uid: "resourceDisplay",
  attributeId: "",
  formId: "",
  pencilDef: {
    title: "Resource Display",
    group: "FIELD",
    handleIcon: "FaGlasses",
    styles: {},
    skipNewField: true,
  },
  options: { ...options },
  // template provided for jsonSchemaProvider
  schema: {
    id: "resourceDisplay",
    label: "Untitled Display",
    formId: "",
    type: "resourceDisplay",
    options: { ...options },
  },
};
