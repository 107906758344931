// import { getStore } from "../../state/store";

export const getJsonSchemaForFolio = (): // id: string
{
  jsonSchema: {},
  jsonUiSchema: {},
} => {
  const jsonSchema = {
    type: "object",
    properties: {
      title: {
        type: "string",
        title: "Field Label",
      },
      activeLeaves: {
        type: "array",
        title: "Active Leaves",
        items: {
          type: "string",
          enum: ["note", "activity", "call", "email", "task", "appointment"],
        },
        uniqueItems: true,
      },
      isFloating: {
        type: "boolean",
        title:
          'Float Option "if float option enable place this pencil outside all the tabs"',
        default: false,
      },
      leafableIdAttributeName: {
        type: "string",
        title:
          'Override the default leafable id attribute name "default is id"',
      },
      leafableTypeAttributeName: {
        type: "string",
        title:
          'Override the default leafable type attribute name "default is the value of the current object"',
      },
      activities: {
        type: "array",
        title: "Activities",
        items: {
          type: "object",
          properties: {
            label: {
              type: "string",
            },
            icon: {
              type: "string",
            },
            color: {
              type: "string",
            },
            value: {
              type: "string",
              default: "activity",
            },
            model: {
              type: "string",
            },
            disableAdd: {
              title: "Disable Create",
              type: "boolean",
            },
            alignNextLeafsToRight: {
              title: "Align next leafs to right ?",
              type: "boolean",
            },
            object: {
              type: "string",
            },
            formId: {
              type: "number",
            },
          },
        },
      },
    },
  };
  const jsonUiSchema = {
    "ui:widget": "checkboxes",
    "ui:options": {
      orderable: false,
    },
  };
  return {
    jsonSchema,
    jsonUiSchema,
  };
};

export default {
  getJsonSchemaForFolio,
};
