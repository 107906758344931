import * as React from "react";
import { ListDataProvider, LocalListDataProvider } from "@swan/helpers/CRUD";
import { DynamicList } from "@swan/dynamic-ui"; // eslint-disable-line

type GridListProps = {
  listId: string,
  object: string,
  type: string,
  service: Object,
  useLocalData: boolean,
};

class GridList extends React.Component<GridListProps> {
  constructor(props: any) {
    super(props);
    const { service, useLocalData } = props;
    if (useLocalData) {
      this.Component = LocalListDataProvider(DynamicList);
    } else {
      this.Component = ListDataProvider(service)(DynamicList);
    }
  }

  Component: any;

  render() {
    const { listId, object, type, ...rest } = this.props;
    const { Component } = this;
    return (
      <Component
        listId={listId}
        object={object}
        type={type}
        dynamicOptions={{ recordParentKnown: true }} // To hide polymorph parent from list if any
        {...rest}
      />
    );
  }
}

export default GridList;
