import * as React from "react";
import {
  ButtonDropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
} from "reactstrap";
import { Spinner } from "@swan/themer";

type Props = {
  yesLabel?: string,
  noLabel?: string,
  choices?: Array<Object>,
  message?: string,
  children: React.Node,
  color?: string,
  disabled?: boolean,
  loading?: boolean,
  onClickParams?: Object,
  className?: string,
};
type State = {
  isOpen: boolean,
};

class ButtonConfirm extends React.Component<Props, State> {
  static defaultProps = {
    yesLabel: "Yes",
    noLabel: "No",
    message: "Are you sure?",
    color: "danger",
    choices: [],
    onClickParams: [],
    disabled: false,
    loading: false,
    className: undefined,
  };

  constructor(props: Props) {
    super(props);
    this.state = {
      isOpen: false,
    };
    this.id = `btn-${Math.ceil(Math.random() * 10000)}`;
  }

  toggle = () => {
    const { isOpen } = this.state;
    this.setState({
      isOpen: !isOpen,
    });
  };

  id: string;

  render() {
    const {
      message,
      choices,
      children,
      disabled,
      loading,
      className,
      yesLabel,
      noLabel,
      onClickParams,
      ...buttonProps
    } = this.props;
    const { isOpen } = this.state;
    return (
      <React.Fragment>
        <ButtonDropdown
          isOpen={isOpen}
          {...buttonProps}
          id={this.id}
          toggle={this.toggle}
          disabled={disabled}
        >
          <DropdownToggle
            caret
            color={buttonProps.color}
            className={[className, loading ? "loading" : ""].join(" ")}
            disabled={disabled}
          >
            <div className="control-label-container">
              {loading ? <Spinner size="sm" /> : null}
              <span className="control-label">{children}</span>
            </div>
          </DropdownToggle>
          {!disabled ? (
            <DropdownMenu right>
              <DropdownItem header>{message}</DropdownItem>
              {choices &&
                choices.map(button => (
                  <DropdownItem
                    key={button.label}
                    className={`text-${button.color || ""}`}
                    color={button.color}
                    onClick={() => {
                      if (button.onClick) {
                        button.onClick(onClickParams);
                      }
                      return true;
                    }}
                  >
                    {button.label}
                  </DropdownItem>
                ))}
            </DropdownMenu>
          ) : null}
        </ButtonDropdown>
      </React.Fragment>
    );
  }
}

export default ButtonConfirm;
