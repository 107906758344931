import {
  WebsocketService as ApiWebsocketService,
  DuplicationsResourceFactory as DuplicationsService,
  ReorderResourceFactory as ReorderService,
} from "@swan/api";
import type { Channel } from "redux-saga";
import { eventChannel } from "redux-saga";
import { call, put, takeEvery, all, select, take } from "redux-saga/effects";
import sagasActionsGenerator from "./sagasActions";
import toast from "./notify";
import type {
  ApiService,
  Filter,
  Sorting,
  WebsocketService as WebsocketServiceType,
} from "../flowTypes/state";

/**
 * This generator will return sagas to allow for all CRUD operations of a resource
 */

export default (
  Service: ApiService,
  resourceType: string,
  types: { [string]: string },
  WebsocketService: WebsocketServiceType = ApiWebsocketService
) => {
  const pluralName = `${resourceType}s`;
  const nameInAction = resourceType.toUpperCase();
  const nameInActionPlural = pluralName.toUpperCase();

  const actions = sagasActionsGenerator(resourceType, types);

  let serviceInstance;

  function getService() {
    if (!serviceInstance) {
      serviceInstance = new Service();
    }
    return serviceInstance;
  }

  /**
   *
   * @param object object name
   * @param id instance id e.g : quote id
   * @return {Channel<any>}
   */
  function websocketSubscribe({
    channel,
    className,
  }: {
    channel: string,
    className: string,
  }): Channel {
    return eventChannel(emit => {
      WebsocketService.join(channel, className, emit);
      return () => {};
    });
  }

  /**
   *
   * @param callback
   * @param object
   * @param id
   */
  const websocketUnsubscribe = ({ channel }: { channel: string }) =>
    WebsocketService.getEcho().leave(channel);

  function setService(srv: ApiService) {
    serviceInstance = srv;
  }

  function* loadRecords({
    page,
    filter,
    pageSize,
    sorting,
    tempFilters,
  }: {
    page: number,
    filter?: Filter,
    pageSize?: number,
    sorting?: Sorting,
    tempFilters?: Filter,
  }) {
    yield put(actions.loadRecordsBegins());
    const service = getService();
    try {
      const response = yield call(
        [service, service.find],
        page,
        [...(filter || []), ...(tempFilters || [])],
        pageSize,
        sorting
      );
      yield put(actions.loadRecordsCompleted(response, filter, sorting));
    } catch (e) {
      yield put(actions.loadRecordsFailed(e.message));
    }
  }

  function* exportRecords({
    filter,
    tempFilters,
  }: {
    filter?: Filter,
    tempFilters?: Filter,
  }) {
    yield put(actions.exportRecordsBegins());
    const service = getService();
    try {
      const response = yield call(
        [service, service.export],
        [...(filter || []), ...(tempFilters || [])]
      );
      yield put(actions.exportRecordsCompleted(response, filter));
    } catch (e) {
      yield put(actions.exportRecordsFailed(e.message));
    }
  }

  function* loadMoreRecords({
    page,
    filter,
    pageSize,
    sorting,
  }: {
    page: number,
    filter?: Filter,
    pageSize?: number,
    sorting?: Sorting,
  }) {
    yield put(actions.loadMoreRecordsBegins());
    const service = getService();
    try {
      const response = yield call(
        [service, service.find],
        page,
        filter,
        pageSize,
        sorting
      );
      yield put(actions.loadMoreRecordsCompleted(response, filter, sorting));
    } catch (e) {
      yield put(actions.loadMoreRecordsFailed(e.message));
    }
  }
  // eslint-disable-next-line
  function* refreshPage(action: {}) {
    const state = yield select();
    let payload;
    if (state[resourceType]) {
      payload = {
        page: 1,
        filter: state[resourceType].currentFilter,
        sorting: state[resourceType].currentSorting,
      };
    } else {
      payload = {
        page: 1,
      };
    }
    yield call(loadRecords, payload);
  }

  function* loadRecord({ id }: { id: number }) {
    yield put(actions.loadRecordBegins(id));
    const service = getService();

    yield put(actions.initForm());
    try {
      const response = yield call([service, service.get], id);
      yield put(actions.loadRecordCompleted(response));
    } catch (e) {
      if (e.statusCode === 404) {
        // record not found event used to help find the record if not exist in the current user state
        const event = new CustomEvent("SWAN_RECORD_NOT_FOUND", {
          detail: {
            resourceDetails: {
              resource: resourceType,
              id,
            },
          },
        });
        window.dispatchEvent(event);
      }
      yield put(actions.loadRecordFailed(e.message, id, e.statusCode));
    }
  }

  function* updateRecord({
    id,
    record,
    params,
  }: {
    id: number,
    record: {},
    params: {},
  }) {
    yield put(actions.updateRecordBegins());
    const service = getService();
    try {
      const response = yield call([service, service.update], id, record);
      yield put(actions.updateRecordCompleted(response, params));
    } catch (e) {
      yield put(actions.updateRecordFailed(e.message, record, id));
    }
  }

  function* createRecord({ record, params }: { record: {}, params: {} }) {
    yield put(actions.updateRecordBegins());
    const service = getService();

    try {
      const response = yield call([service, service.create], record);
      yield put(actions.createRecordCompleted(response, params));
    } catch (e) {
      yield put(actions.updateRecordFailed(e.message, record));
    }
  }

  function* duplicateRecord({
    id,
    object,
    callback,
  }: {
    id: number,
    object: string,
    callback: ?Function,
  }) {
    yield put(actions.duplicateRecordBegins());
    let response = null;
    try {
      const duplicationService = new (DuplicationsService(object))();
      duplicationService.setParent(id);
      response = yield call([duplicationService, duplicationService.post]);
      yield put(actions.duplicateRecordCompleted({ response }));
    } catch (e) {
      response = { error: e.message };
      yield put(actions.duplicateRecordFailed(e.message));
    }
    if (callback) {
      callback(response);
    }
  }

  function* deleteRecord({ id }: { id: number }) {
    yield put(actions.updateRecordBegins());
    const service = getService();

    try {
      const response = yield call([service, service.delete], id);
      yield put(actions.deleteRecordCompleted({ response, id }));
    } catch (e) {
      yield put(actions.updateRecordFailed(e.message));
    }
  }

  function* reorderRecord({
    id,
    object,
    newOrder,
  }: {
    id: number,
    object: string,
    newOrder: number,
  }) {
    yield put(actions.reorderRecordBegins());
    let response = null;
    try {
      const reorderService = new (ReorderService(object))();
      response = yield call(
        [reorderService, reorderService.reorder],
        id,
        object,
        newOrder
      );
      yield put(actions.reorderRecordCompleted({ response }));
    } catch (e) {
      yield put(actions.reorderRecordFailed(e.message));
    }
  }

  /**
   *
   * @param object
   * @param id
   * @return {IterableIterator<*>}
   */
  function* subscribe({
    object,
    id,
  }: {
    object: string,
    id: number,
  }): Generator<any, void, any> {
    const echoChannel: Channel = yield call(websocketSubscribe, {
      className:
        "\\Mazrui\\SwanNotification\\Events\\Websocket\\GenericObjectEvent",
      channel: `SWAN.RECORD.${object}.${id}`,
    });
    yield put(actions.subscribeStarted(id, object));
    while (true) {
      try {
        const result = yield take(echoChannel);
        if (result && result.message) {
          toast(result ? result.message : "");
        }
        yield put(actions.loadRecordSubscriptionCompleted(result.record));
      } catch (errors) {
        yield put(actions.loadRecordFailed(errors, id, undefined));
      }
    }
  }

  /**
   *
   * @param object
   * @param id
   * @return {IterableIterator<*>}
   */
  function* unsubscribe({
    object,
    id,
  }: {
    object: string,
    id: number,
  }): Generator<any, void, any> {
    yield put({
      type: actions.unsubscribeStarted(id, object),
    });
    yield call(websocketUnsubscribe, {
      channel: `SWAN.RECORD.${object}.${id}`,
    });
  }

  function* subscribeNewRecords({
    object,
  }: {
    object: string,
  }): Generator<any, void, any> {
    const echoChannel: Channel = yield call(websocketSubscribe, {
      className:
        "\\Mazrui\\SwanNotification\\Events\\Websocket\\GenericObjectEvent",
      channel: `SWAN.RECORDS.${object}`,
    });
    yield put(actions.subscribeNewRecordsStarted(object));
    while (true) {
      try {
        const result = yield take(echoChannel);
        yield put(actions.subscribeNewRecordsCompleted(result.record));
      } catch (errors) {
        yield put(actions.subscribeNewRecordsFailed(errors));
      }
    }
  }

  /**
   *
   * @param object
   * @param id
   * @return {IterableIterator<*>}
   */
  function* unsubscribeNewRecords({
    object,
  }: {
    object: string,
  }): Generator<any, void, any> {
    yield put({
      type: actions.unsubscribeNewRecordsStarted(object),
    });
    yield call(websocketUnsubscribe, {
      channel: `SWAN.RECORDS.${object}`,
    });
  }

  const sagas = function* sagas(): Iterable<any> {
    yield all([
      takeEvery(types[`LOAD_${nameInActionPlural}`], loadRecords),
      takeEvery(types[`EXPORT_${nameInActionPlural}`], exportRecords),
      takeEvery(types[`DUPLICATE_${nameInAction}`], duplicateRecord),
      takeEvery(types[`REFRESH_${nameInActionPlural}`], refreshPage),
      takeEvery(types[`LOAD_MORE_${nameInActionPlural}`], loadMoreRecords),
      takeEvery(types[`LOAD_${nameInAction}`], loadRecord),
      takeEvery(
        types[`SUBSCRIBE_${nameInActionPlural}_NEW_RECORDS`],
        subscribeNewRecords
      ),
      takeEvery(
        types[`UNSUBSCRIBE_${nameInActionPlural}_NEW_RECORDS`],
        unsubscribeNewRecords
      ),
      takeEvery(types[`UPDATE_${nameInAction}`], updateRecord),
      takeEvery(types[`CREATE_${nameInAction}`], createRecord),
      takeEvery(types[`DELETE_${nameInAction}`], deleteRecord),
      takeEvery(types[`SUBSCRIBE_${nameInAction}`], subscribe),
      takeEvery(types[`UNSUBSCRIBE_${nameInAction}`], unsubscribe),
      takeEvery(types[`REORDER_${nameInAction}`], reorderRecord),
    ]);
  };

  return { sagas, setService, getService };
};
