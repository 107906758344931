import { connect } from "react-redux";
import { compose } from "redux";
import { SwanContextManager } from "@swan/state";

// state
import { userActions } from "../../state/reducers/user";

export default (Component: any) => {
  // mapStateToProps
  const mapStateToProps = ({ user }) => {
    const userName = ((user || {}).profile || {}).name;
    const userOrg = (
      ((user || {}).organizations || []).find(
        org => org.id === user.activeOrganization
      ) || {}
    ).name;
    // Note: for now, we show organization avatar instead of user avatar
    const activeOrganization =
      ((user || {}).organizations || []).find(
        org => org.id === user.activeOrganization
      ) || {};
    const userAvatar = activeOrganization.avatar;
    const userOrgCode = activeOrganization.code;
    return {
      userProfile: SwanContextManager.getValue("userProfile"),
      // to be cleaned
      userOrganizations: user.organizations,
      userName,
      userOrg,
      userAvatar,
      userOrgCode,
    };
  };

  return compose(
    connect(
      mapStateToProps,
      {
        switchOrganization: userActions.switchOrganization,
        subscribeUserLogin: userActions.subscribeUserLogin,
        unsubscribeUserLogin: userActions.unsubscribeUserLogin,
      }
    )
  )(Component);
};
