import React from "react";
import { withRouter } from "react-router-dom";
import { CustomBanner } from "..";

type Props = {
  appRoutes?: Array<any>,
  disable?: boolean,
  match: Object,
  bannerClassName?: string,
  crumbClassName?: string,
  disableCrumbBar?: boolean,
  style?: Object,
};

type State = {
  crumbs?: any,
  formTitle?: string,
};

class Breadcrumbs extends React.Component<Props, State> {
  static defaultProps = {
    appRoutes: [],
    bannerClassName: undefined,
    crumbClassName: undefined,
    disable: false,
    customClass: undefined,
    disableCrumbBar: false,
    style: {},
  };

  constructor(props: Props) {
    super(props);

    this.state = {
      crumbs: [],
      formTitle: "SWAN",
    };
  }

  componentDidMount() {
    this.setCrumbs();
  }

  getSelectedRoutes = () => {
    const { appRoutes, match } = this.props;
    const currentPathURLArray = (match.path || "").split("/");
    const selectedRoutes = [];
    let iteratorURL = "";
    currentPathURLArray.shift();
    let prevSegment = "";
    currentPathURLArray.forEach((segment, key) => {
      iteratorURL = `${iteratorURL}/${segment}`;
      selectedRoutes.push(
        ...(appRoutes || []).filter(route => iteratorURL === route.path)
      );
      if (currentPathURLArray.length - 1 === key) {
        prevSegment = selectedRoutes[selectedRoutes.length - 1] || {};
        if (segment === ":id") {
          selectedRoutes.push({
            path: match.url,
            name:
              (prevSegment || {}).customEditTitle ||
              `Edit ${(prevSegment || {}).name || ""}`,
            active: false,
          });
        } else if (segment === "new") {
          selectedRoutes.push({
            path: match.url,
            name:
              (prevSegment || {}).customCreateTitle ||
              `Create ${(prevSegment || {}).name}`,
            active: false,
          });
        }
      }
    });
    return selectedRoutes;
  };

  setCrumbs = () => {
    const selectedRoutes = this.getSelectedRoutes();
    const crumbs = [];
    let title = "SWAN";
    selectedRoutes.map((item, key) => {
      crumbs.push({
        to: item.path,
        label: item.name,
        active: item.active || true,
      });
      if (selectedRoutes.length - 1 === key) {
        title = item.customTitle || item.name;
      }
      return true;
    });
    this.setState({
      crumbs,
      formTitle: title,
    });
  };

  render() {
    const {
      bannerClassName,
      crumbClassName,
      disable,
      disableCrumbBar,
      style,
    } = this.props;
    const { crumbs, formTitle } = this.state;
    if (disable) return null;
    const { margin, width } = style || {};
    return (
      <CustomBanner
        classNames={{
          banner: bannerClassName,
          crumb: crumbClassName,
        }}
        disableCrumbBar={disableCrumbBar}
        margin={{
          left: (margin || {}).left || "-2",
          right: (margin || {}).right || "-2",
          top: (margin || {}).top || "-2",
        }}
        width={width || "calc(100% + 4rem)"}
        title={formTitle}
        crumbs={crumbs || []}
      />
    );
  }
}

export default withRouter(Breadcrumbs);
