export default (resourceType: string, types: { [string]: string }) => {
  const pluralName = `${resourceType}s`;
  const nameInAction = resourceType.toUpperCase();
  const nameInActionPlural = pluralName.toUpperCase();
  const actions = {
    resetList: () => ({
      type: types[`CLEAR_${nameInActionPlural}`],
    }),
    loadRecords: (
      page: number,
      filter: Array<{ columnName: string, value: string }>,
      pageSize?: number,
      sorting: {},
      tempFilters?: Array<{ columnName: string, value: string }>
    ) => ({
      type: types[`LOAD_${nameInActionPlural}`],
      page,
      filter,
      pageSize,
      sorting,
      tempFilters,
    }),
    exportRecords: (
      filter: Array<{ columnName: string, value: string }>,
      tempFilters?: Array<{ columnName: string, value: string }>
    ) => ({
      type: types[`EXPORT_${nameInActionPlural}`],
      filter,
      tempFilters,
    }),
    loadMoreRecords: (
      page: number,
      filter: Array<{ columnName: string, value: string }>,
      pageSize?: number,
      sorting: {}
    ) => ({
      type: types[`LOAD_MORE_${nameInActionPlural}`],
      page,
      filter,
      pageSize,
      sorting,
    }),
    loadMoreRecordsReset: () => ({
      type: types[`LOAD_MORE_${nameInActionPlural}_RESET`],
    }),
    loadRecord: (id: number) => ({
      type: types[`LOAD_${nameInAction}`],
      id,
    }),
    updateRecord: (id: number, record: {}, params: {}) => ({
      type: types[`UPDATE_${nameInAction}`],
      id,
      record,
      params,
    }),
    duplicateRecord: (id: number, object: string, callback: Function) => ({
      type: types[`DUPLICATE_${nameInAction}`],
      id,
      object,
      callback,
    }),
    reorderRecord: (id: number, object: string, newOrder: number) => ({
      type: types[`REORDER_${nameInAction}`],
      id,
      object,
      newOrder,
    }),
    createRecord: (record: {}, params: {}) => ({
      type: types[`CREATE_${nameInAction}`],
      record,
      params,
    }),
    initForm: () => ({
      type: types[`INIT_${nameInAction}`],
      createdRecord: {},
      params: {},
    }),
    deleteRecord: (id: number) => ({
      type: types[`DELETE_${nameInAction}`],
      id,
    }),
    updateRecordReset: () => ({
      type: types[`UPDATE_${nameInAction}_RESET`],
    }),
    getCrudListActions: () => {},
    getWebsocketFormActions: () => {},
    getCrudFormActions: () => {},
    refreshPage: () => ({
      type: types[`REFRESH_${nameInActionPlural}`],
    }),

    websocketSubscribe: (id: number, object: string) => ({
      type: types[`SUBSCRIBE_${nameInAction}`],
      id,
      object,
    }),
    websocketUnsubscribe: (id: number, object: string) => ({
      type: types[`UNSUBSCRIBE_${nameInAction}`],
      id,
      object,
    }),

    websocketSubscribeNewRecords: (object: string) => ({
      type: types[`SUBSCRIBE_${nameInActionPlural}_NEW_RECORDS`],
      object,
    }),
    websocketUnsubscribeNewRecords: (object: string) => ({
      type: types[`UNSUBSCRIBE_${nameInActionPlural}_NEW_RECORDS`],
      object,
    }),
  };
  actions.getCrudListActions = () => ({
    loadRecords: actions.loadRecords,
    exportRecords: actions.exportRecords,
  });
  actions.getCrudFormActions = () => ({
    loadRecord: actions.loadRecord,
    duplicateRecord: actions.duplicateRecord,
    updateRecord: actions.updateRecord,
    createRecord: actions.createRecord,
    deleteRecord: actions.deleteRecord,
    exportRecords: actions.exportRecords,
    resetUpdate: actions.updateRecordReset,
    initForm: actions.initForm,
  });
  actions.getWebsocketFormActions = () => ({
    websocketSubscribe: actions.websocketSubscribe,
    websocketUnsubscribe: actions.websocketUnsubscribe,
    websocketSubscribeNewRecords: actions.websocketSubscribeNewRecords,
    websocketUnsubscribeNewRecords: actions.websocketUnsubscribeNewRecords,
  });
  return actions;
};
