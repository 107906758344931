import { Image } from "@tiptap/extension-image";
import { Plugin, PluginKey } from "prosemirror-state";

const toBase64 = (file, callback) => {
  const reader = new FileReader();
  reader.readAsDataURL(file);

  reader.onloadend = () => {
    callback(reader.result);
  };
};

const DragAndDropImage = Image.extend({
  name: "dragAndDropImage",
  addProseMirrorPlugins() {
    return [
      new Plugin({
        key: new PluginKey("dragAndDropImage"),
        props: {
          handleDrop(view, event) {
            const hasFiles = event.dataTransfer.files.length > 0;

            if (!hasFiles) return;
            event.preventDefault();

            const { schema } = view.state;
            const coordinates = view.posAtCoords({
              left: event.clientX,
              top: event.clientY,
            });

            const image = event.dataTransfer.files[0];

            toBase64(image, result => {
              const node = schema.nodes.dragAndDropImage.create({
                src: result,
              });

              const transaction = view.state.tr.insert(coordinates.pos, node);

              view.dispatch(transaction);
            });
          },
        },
      }),
    ];
  },
});

export default DragAndDropImage;
