// @flow
import prepareString from "./prepare_string";
import isNumeric from "../number/is_numberic";

export default (conditionValue: string | number) => (fieldValue: any) => {
  if (isNumeric(conditionValue)) {
    return (
      new TextEncoder().encode(prepareString(fieldValue)).length <=
      parseFloat(conditionValue)
    );
  }
  return false;
};
