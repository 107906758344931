// utils
import {
  layoutProvider,
  navigationProvider,
  LayoutSubscriber,
  withLayout,
  layoutIcons,
  toast,
  dismissToast,
  type LayoutContext,
} from "./utils";

import type {
  CardTagType,
  CardActionType,
  CardDataType,
  CardOptionsType,
  ServiceParamsType,
  CardType,
  ColumnConfigType,
  BoardViewType,
} from "./components/Dashboard/KanbanView/types";

export {
  layoutProvider,
  navigationProvider,
  LayoutSubscriber,
  withLayout,
  layoutIcons,
  dismissToast,
  toast,
};

export type Layout = LayoutContext;
export type KanbanViewCardTagType = CardTagType;
export type KanbanViewCardActionType = CardActionType;
export type KanbanViewCardDataType = CardDataType;
export type KanbanViewCardOptionsType = CardOptionsType;
export type KanbanViewServiceParamsType = ServiceParamsType;
export type KanbanViewCardType = CardType;
export type KanbanViewColumnConfigType = ColumnConfigType;
export type KanbanViewBoardViewType = BoardViewType;

export * from "./components";
export * from "./constants";

export * from "./components/Deferred";

export default {
  layoutProvider,
  navigationProvider,
  LayoutSubscriber,
  withLayout,
  layoutIcons,
  dismissToast,
  toast,
};
