import * as React from "react";
import { connect } from "react-redux";
import { Droppable } from "react-beautiful-dnd";

import styled from "styled-components";

import type { Schema } from "../../constants/flowTypes";
import { getTypeFromId } from "../../state/utils/schema";

import COLOURS from "../../constants/colours";

// import { ColWrapper } from "../Elements/Col";
// import { ColsWrapper } from "../Elements/Row";

const ArtDroppableConditional = styled.div(
  ({
    theme,
    direction,
    isDraggingOverWith,
    isDraggingOver,
    droppedCount,
    droppableWrapper,
    // isDropDisabled,
    isEmptyContainer,
  }) =>
    Object.assign(
      {},
      theme.ArtDroppableConditional ? { ...theme.ArtDroppableConditional } : {},
      direction === "horizontal"
        ? { flexDirection: "row" }
        : { flexDirection: "column" },
      isDraggingOver && isDraggingOverWith !== null
        ? { ...theme.ArtDroppableOverWith }
        : {},
      droppableWrapper === "Tabs" && direction === "vertical"
        ? {
            flexGrow: 0,
            flexShrink: 1,
          }
        : {},
      droppableWrapper === "Grid"
        ? { height: "auto", selfAlign: "stretch" }
        : {},
      droppableWrapper === "Col" && droppedCount === 0
        ? { minHeight: "200px" }
        : {},
      droppableWrapper === "Row" ? { marginRight: ".5rem" } : {},
      // droppableWrapper === "Col" ? { border: "1px dashed darkslategray" } : {},
      // droppableWrapper === "Tab" ? { minHeight: "200px" } : {},
      // droppableWrapper === "Container" ? { minHeight: "200px" } : {},
      isEmptyContainer || droppedCount === 0
        ? {
            minHeight: "200px",
            border: `1px dashed ${COLOURS.CONDITIONAL_DROPPABLE_BORDER}`,
            borderRadius: ".2rem",
            // background: `${COLOURS.CONDITIONAL_DROPPABLE_BG}`,
          }
        : {}
    )
);

type ConditionalDroppableProps = {
  droppableId: string,
  direction: string,
  allowTypes?: Array<string>,
  excludeTypes?: Array<string>,
  children: React.Node | Function,
  schema: Schema,
  draggingOverWith: string,
  droppableWrapper: string,
  droppedCount: number,
  isEmptyContainer: boolean,
};

class ConditionalDroppable extends React.Component<ConditionalDroppableProps> {
  static defaultProps = {
    allowTypes: null,
    excludeTypes: null,
  };

  shouldDisable(draggingOverWith: string) {
    if (!draggingOverWith) return false;
    const { allowTypes, excludeTypes, schema } = this.props;
    const type = getTypeFromId(draggingOverWith, schema);
    if (allowTypes === null) {
      if (excludeTypes) {
        if (excludeTypes.includes(type)) {
          return true;
        }
      }
      return false;
    }
    if (allowTypes) {
      if (allowTypes.includes(type)) {
        return false;
      }
    }
    return true;
  }

  render() {
    const {
      droppableId,
      direction,
      children,
      draggingOverWith,
      droppableWrapper,
      droppedCount,
      isEmptyContainer,
    } = this.props;

    const isDropDisabled = this.shouldDisable(draggingOverWith);
    return (
      <Droppable
        droppableId={droppableId}
        direction={direction}
        isDropDisabled={isDropDisabled}
      >
        {(droppableProvided, droppableSnapshot) => (
          <ArtDroppableConditional
            ref={droppableProvided.innerRef}
            {...droppableProvided.droppableProps}
            isDraggingOver={droppableSnapshot.isDraggingOver}
            direction={direction}
            // isDraggingOverWith={droppableSnapshot.isDraggingOverWith}
            isDraggingOverWith={droppableSnapshot.draggingOverWith}
            droppableWrapper={droppableWrapper}
            droppedCount={droppedCount}
            isEmptyContainer={isEmptyContainer}
          >
            {/* {console.log({ droppableWrapper, isDropDisabled })} */}
            {React.Children.map(children, child =>
              React.cloneElement(child, {
                isDropDisabled,
                isDraggingOver: droppableSnapshot.isDraggingOver,
                childCount: React.Children.count(children),
                childCloned: child,
              })
            )}

            {droppableProvided.placeholder}
          </ArtDroppableConditional>
        )}
      </Droppable>
    );
  }
}

function mapStateToProps(state) {
  return {
    schema: state.schema.schema,
    draggingOverWith: state.app.draggingOverWith,
  };
}

export default connect(mapStateToProps)(ConditionalDroppable);
