import React, { Fragment } from "react";
import { connect } from "react-redux";
import { ServiceGenerator } from "@swan/api";
// $FlowFixMe
import { withRouter } from "react-router";

import Axios from "axios";
import { userActions } from "../state/reducers/user";
import EventsDictionary from "./EventsDictionary";
import { toast } from "@swan/themer";

const NotFoundEventListener = ({
  switchOrganization,
  location,
}: {
  switchOrganization: Function,
  location: any,
}) => {
  const PageNotFoundListener = event => {
    const Service = ServiceGenerator(Axios, "object/record/meta");
    const service = new Service();
    if (event.detail && event.detail.resourceDetails) {
      const resourceDetails = event.detail.resourceDetails;
      service
        .get(`${resourceDetails.resource}/${resourceDetails.id}`)
        .then(
          (response: {
            owner_id: number,
            orgid: number,
            organization_name: string,
          }) => {
            const { orgid, organization_name } = response;
            if (orgid) {
              toast(
                `Record exists in ${organization_name}, Please wait while we redirect you there.`
              );
              switchOrganization(orgid, organization_name, location.pathname);
            }
          }
        )
        .catch(() => {
          toast(
            `You don't have a permission to access this record or organization.`,
            {
              type: "error",
            }
          );
        });
    }
  };
  window.addEventListener(
    EventsDictionary.SWAN_RECORD_NOT_FOUND,
    PageNotFoundListener
  );

  return <Fragment />;
};

function mapStateToProps() {
  return {};
}

export default connect(
  mapStateToProps,
  {
    switchOrganization: userActions.switchOrganization,
  }
)(withRouter(NotFoundEventListener));
