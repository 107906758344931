/* eslint-disable camelcase */
import { Component } from "react";

type Props = {
  // listId: string,
  // extraParameters?: () => {},
  data: ?Array<Object>,
  error?: string | Object,
  per_page?: number,
  children: Function,
};
type State = {};

class ListArrayDataProvider extends Component<Props, State> {
  static defaultProps = {
    extraParameters: null,
    error: undefined,
    per_page: undefined,
  };

  static loadRecords = () => {};

  mounted = false;

  getData() {
    const { data } = this.props;
    // $FlowFixMe
    return (data || []).map((row, idx) => ({
      ...row,
      id: idx,
    }));
  }

  render() {
    const { per_page, children, error } = this.props;
    const data = this.getData();
    return children({
      ...this.props,
      data,
      totalCount: (data || []).length,
      pageSize: per_page,
      currentPage: -1,
      sorting: [],
      filter: [],
      isFetching: false,
      error,
      loadRecords: ListArrayDataProvider.loadRecords,
    });
  }
}

export default ListArrayDataProvider;
/* eslint-enable camelcase */
