import * as React from "react";

import styled, { withTheme } from "styled-components";
import { Collapse } from "reactstrap";
import { FaCaretDown, FaCaretUp } from "react-icons/fa";

import Button from "../Button";

type Props = {
  title?: string,
  togglable?: boolean,
  defaultOpen?: boolean,
  children: React.Node,
};

export const ContainerWithTitleWrapper = styled.div(({ theme }) =>
  Object.assign(
    {},
    theme.ContainerWrapper ? { ...theme.ContainerWrapper } : {},
    { padding: "0.5rem" }
    // colCount ? { flexBasis: `${100 / colCount}%` } : {}
    // { minHeight: "300px" }
  )
);

// export const ContainerTitle = styled.div(({ theme }) =>
//   Object.assign({}, theme.ContainerTitle ? { ...theme.ContainerTitle } : {})
// );

export const ContainerTitle = styled.div`
  margin: 0px;
  padding: 0px;
  font-size: 20px;
  font-weight: bold;
  border-bottom: 2px solid #767676;
  padding-bottom: 10px;
  padding-top: 11px;
`;

type State = {
  isOpen: boolean,
};

class ContainerWithTitle extends React.Component<Props, State> {
  static defaultProps = {
    title: "",
    togglable: false,
    defaultOpen: true,
  };

  constructor(props: Props) {
    super(props);

    this.state = {
      isOpen: props.defaultOpen || false,
    };
  }

  isTogglable = () => {
    const { togglable } = this.props;
    return togglable;
  };

  toggle = () => {
    if (!this.isTogglable()) return;
    this.setState(prevState => ({
      isOpen: !prevState.isOpen,
    }));
  };

  render() {
    const { isOpen } = this.state;
    const { title, children } = this.props;
    const isToggleEnabled = this.isTogglable();
    return (
      <ContainerWithTitleWrapper>
        {title && (
          <ContainerTitle>
            {title}
            <>
              {isToggleEnabled && (
                <span className="float-right">
                  {` `}
                  <Button outline size="sm" onClick={this.toggle}>
                    {isOpen && <FaCaretUp />}
                    {!isOpen && <FaCaretDown />}
                  </Button>
                </span>
              )}
            </>
          </ContainerTitle>
        )}
        {isToggleEnabled && <Collapse isOpen={isOpen}>{children}</Collapse>}

        {!isToggleEnabled && children}
      </ContainerWithTitleWrapper>
    );
  }
}

export default withTheme(ContainerWithTitle);
