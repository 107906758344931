// @flow
import { has, map, isEmpty, indexOf, isNumber, orderBy, assign } from "lodash";

import { optionsFallback } from "../../definitions/pencils/config";
import jsonSchemaSnips from "./jsonSchemaSnips";

export const composeJsonSchema = ({
  optionProperty,
  optionValue,
}: {
  optionProperty: string,
  optionValue: any,
}) => {
  if ((jsonSchemaSnips || {})[optionProperty]) {
    return jsonSchemaSnips[optionProperty]({ optionProperty, optionValue });
  }
  return {};
};

export const getJsonSchema = (
  pencilDefOptionsProvided: {},
  silentOptionsFallback: boolean = false
): {
  jsonSchema: {},
  jsonUiSchema: {},
} => {
  let pencilDefOptions = { ...pencilDefOptionsProvided };
  if (!silentOptionsFallback) {
    pencilDefOptions = { ...optionsFallback, ...pencilDefOptionsProvided }; // Object.assign(optionsFallback, pencilDefOptionsProvided);
  }

  const jsonSchema = {
    type: "object",
    properties: {},
    required: ["title"],
  };
  const jsonUiSchema = {};
  const uiOrder = [];
  const uiGroup = {};
  if (pencilDefOptions) {
    /* eslint-disable no-restricted-syntax */
    for (const [optionProperty, optionValue] of Object.entries(
      pencilDefOptions
    )) {
      const { schema, uiSchema, optionOrder, optionGroup } = composeJsonSchema({
        optionProperty,
        optionValue,
      });

      if (isNumber(optionOrder) && optionOrder > 0) {
        uiOrder.push({ optionOrder, optionProperty });
      }

      if (optionGroup && !has(uiGroup, optionGroup))
        assign(uiGroup, { [optionGroup]: [] });

      if (
        has(uiGroup, optionGroup) &&
        indexOf(uiGroup[optionGroup], optionProperty) === -1
      )
        uiGroup[optionGroup].push(optionProperty);

      if (schema && !isEmpty(schema))
        Object.assign(jsonSchema.properties, {
          [optionProperty]: { ...schema, group: optionGroup },
        });
      if (uiSchema && !isEmpty(uiSchema))
        Object.assign(jsonUiSchema, { [optionProperty]: uiSchema });
    }

    if (!isEmpty(uiOrder))
      Object.assign(jsonUiSchema, {
        "ui:order": [
          ...map(
            orderBy(uiOrder, "optionOrder", "desc"), // eslint-disable-line
            ({ optionProperty }) => optionProperty
          ),
          "*",
        ],
      });
  }

  return {
    jsonSchema,
    jsonUiSchema,
    uiGroup,
  };
};

export default {
  composeJsonSchema,
  getJsonSchema,
};
