import React, { useEffect, useState } from "react";
import { Service } from "@swan/api";
import { Loading, Timeline as TimelineUi } from "@swan/themer";
import Field from "./partials/Field";

const service = new Service();

const Timeline = ({ data, schema }: any) => {
  const [timeline, setTimeline] = useState([]);
  const [loading, setLoading] = useState(false);

  const { options } = schema || {};
  const { title, endpoint } = options || {};

  const getTimeline = () => {
    const url = endpoint.replace("{id}", data.id);

    setLoading(true);
    service
      .get(url)
      .then(response => {
        setTimeline(response);
        setLoading(false);
      })
      .catch(error => {
        setLoading(false);
        // eslint-disable-next-line no-console
        console.error(error);
      });
  };

  useEffect(() => {
    getTimeline();
  }, []);

  return (
    <>
      {loading && <Loading />}
      <TimelineUi title={title} data={timeline} />
    </>
  );
};

export default Field(Timeline);
