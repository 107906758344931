import Axios from "axios";
import { ServiceGenerator } from "@swan/api";

const BasePermissionsService = ServiceGenerator(Axios, "auth/user");
const PermissionsService = ServiceGenerator(Axios, "auth/user");

class CachedPermissionsService extends BasePermissionsService {
  permissionsByName = {};

  batchLoadingPromise = null;

  permissionsToLoad = [];

  executeLoadByNames = () => {
    if (this.batchLoadingPromise) {
      return this.batchLoadingPromise;
    }
    this.batchLoadingPromise = new Promise((resolve, reject) => {
      setTimeout(() => {
        if (!this.permissionsToLoad.length) {
          return resolve({});
        }
        const permissions = [...this.permissionsToLoad];
        this.permissionsToLoad = [];
        const tld = [];
        permissions.forEach(i => {
          // Make sure we don't have them locally
          if (!this.permissionsByName[i]) {
            tld.push(i);
          }
        });

        if (tld.length === 0) {
          return resolve({});
        }

        return super
          .get("isallowed", { permission: tld })
          .then(response => {
            this.permissionsByName = {
              ...this.permissionsByName,
              ...response,
            };
            resolve(response);
            this.batchLoadingPromise = null;
          })
          .catch(e => reject(e));
      }, 200);
    });
    return this.batchLoadingPromise;
  };

  /**
   * Load permissionsByName
   */
  loadPermissionByName(permission: string): Promise<boolean> {
    if ((this.permissionsByName || {})[permission]) {
      return Promise.resolve(this.permissionsByName[permission]);
    }
    if (this.permissionsToLoad.indexOf(permission) === -1) {
      this.permissionsToLoad.push(permission);
    }
    return this.executeLoadByNames().then(() => {
      if (typeof (this.permissionsByName || {})[permission] !== "undefined") {
        return Promise.resolve(this.permissionsByName[permission]);
      }
      // We got an old batch, wait again
      return this.executeLoadByNames().then(() => {
        if (typeof (this.permissionsByName || {})[permission] !== "undefined") {
          return Promise.resolve(this.permissionsByName[permission]);
        }
        return Promise.reject();
      });
    });
  }

  isAllowed(permission: string): Promise<boolean> {
    if (!(this.permissionsByName || {})[permission]) {
      return this.loadPermissionByName(permission);
    }
    return Promise.resolve(this.permissionsByName[permission]);
  }
}

export const CachePermissionsServiceInstance = new CachedPermissionsService();

PermissionsService.prototype.isAllowed = function isAllowed(
  permission: string
) {
  return CachePermissionsServiceInstance.isAllowed(permission);
};

export default PermissionsService;
