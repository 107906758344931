import React, { Component } from "react";
import { FaTrashAlt, FaEye } from "react-icons/fa";
import styled from "styled-components";

import Dropzone from "./components/Dropzone";
import Button from "../Button";
import { Controls, Thumbnail, Preview } from "./components/Styled";
import { getFileTypes, getFileSource, getFileExtension } from "./utils";
// import Progress from "./components/Progress";

type Props = {
  service?: Object,
  autoUpload?: boolean,
  className?: string,
  onBeforeSave?: Function,
  onSave?: Function,
  onAfterSave?: Function,
  onDelete?: Function,
  onPreview?: Function,
  multiple?: boolean,
  valueAccessor?: string,
  files?: Array<Object>,
  actions?: Array<Object>,
  hideClear?: boolean,
  showPreview?: boolean,
  showFileNameOnly?: boolean,
  disabled?: boolean,
  isControlled?: boolean,
  disableItemDeletion?: boolean,
  fileSourceAccessor?: Function,
};

type State = {
  files: Array<*>,
  uploading: boolean,
  uploadSuccess: boolean,
  filesUploaded: Array<*>,
};

const FileNameWrapper = styled.span({
  color: "#39659e",
  textDecoration: "underline",
  display: "list-item",
  listStyleType: "disc",
  listStylePosition: "inside",
  cursor: "pointer",
});

class Upload extends Component<Props, State> {
  static defaultProps = {
    service: undefined,
    autoUpload: false,
    className: undefined,
    onBeforeSave: undefined,
    onSave: undefined,
    onAfterSave: undefined,
    onDelete: undefined,
    onPreview: undefined,
    multiple: false,
    valueAccessor: "url",
    files: [],
    actions: [],
    hideClear: false,
    showPreview: false,
    disabled: undefined,
    isControlled: false,
    showFileNameOnly: false,
    disableItemDeletion: undefined,
    fileSourceAccessor: undefined,
  };

  constructor(props: Props) {
    super(props);
    this.Service = props.service;
    this.state = {
      files: [],
      uploading: false,
      uploadSuccess: false,
      filesUploaded: [],
    };
  }

  componentDidMount() {
    const { isControlled, files } = this.props;
    if (!isControlled && files && files.length) {
      this.setState({
        filesUploaded: files,
      });
    }
  }

  onFilesAdded = (files: Array<File>) => {
    const { autoUpload, multiple, isControlled } = this.props;
    const filesAdded = multiple ? files : [files[0]];
    if (autoUpload) {
      this.onAutoSave(filesAdded);
    } else if (isControlled) {
      this.controlledForSave(filesAdded);
    } else {
      this.prepareForSave(filesAdded);
    }
  };

  controlledForSave = (files: Array<*>) => {
    const { onBeforeSave, onSave, files: filesProp } = this.props;
    const f = [...(filesProp || []), ...files];
    const filesData =
      onBeforeSave && onBeforeSave instanceof Function
        ? onBeforeSave(f)
        : this.onBeforeSave(f);

    if (onSave && onSave instanceof Function) {
      onSave(filesData, this.getUtils());
    }
  };

  prepareForSave = (files: Array<*>) => {
    this.setState(
      prevState => ({
        files: [...prevState.files, ...files],
      }),
      () => {
        const { onBeforeSave, onSave } = this.props;
        const { files: filesState } = this.state;
        const filesData =
          onBeforeSave && onBeforeSave instanceof Function
            ? onBeforeSave(filesState)
            : this.onBeforeSave(filesState);

        if (onSave && onSave instanceof Function) {
          onSave(filesData, this.getUtils());
        }
      }
    );
  };

  // considering only autoUpload case
  onAutoSave = (files: Array<*>) => {
    const { onBeforeSave, onSave } = this.props;
    let uploaded = [];
    let promises = [];
    const filesData =
      onBeforeSave && onBeforeSave instanceof Function
        ? onBeforeSave(files)
        : this.onBeforeSave(files);
    if (onSave && onSave instanceof Function) {
      promises = onSave(filesData).then(({ data: uploadedFiles }) => {
        uploaded = uploaded.concat(uploadedFiles);
      });
    } else if (this.Service) {
      let service = this.Service;
      let path = [];
      if (typeof service === "object" && service.constructor === Object) {
        ({ service, path } = service);
      }
      if (!Array.isArray(path)) path = [path];
      promises = [
        service
          .upload(path.join("/"), filesData)
          .then(({ data: uploadedFiles }) => {
            uploaded = uploaded.concat(uploadedFiles);
          }),
      ];
    }

    Promise.all(promises).then(() => {
      this.setState(
        {
          files: uploaded,
          uploading: true,
        },
        this.onAfterSave
      );
    });
  };

  // default behaviour is sending file objects keyed as filename
  onBeforeSave = (files: Array<*>) => {
    let uploadedFiles = {};
    files.forEach(file => {
      uploadedFiles = { ...uploadedFiles, ...{ [file.name]: file } };
    });
    return uploadedFiles;
  };

  onAfterSave = () => {
    const { onAfterSave } = this.props;
    const { files } = this.state;
    const filesUploaded = [...files];
    this.setState(
      {
        files: [],
        uploadSuccess: true,
        uploading: false,
      },
      () => {
        if (onAfterSave && onAfterSave instanceof Function) {
          onAfterSave(filesUploaded);
        }
      }
    );
  };

  getDisabledStatus = () => {
    const { uploading, files, filesUploaded, uploadSuccess } = this.state;
    const { multiple, autoUpload, disabled } = this.props;
    return (
      disabled ||
      uploading ||
      (!multiple && uploadSuccess) ||
      (!multiple && filesUploaded.length > 0) ||
      (!multiple &&
        !autoUpload &&
        (files.length > 0 || filesUploaded.length > 0))
    );
  };

  clearUploads = () => {
    const { isControlled, files: filesProp } = this.props;
    const { files } = this.state;
    if (isControlled && filesProp) {
      this.onDelete(filesProp);
    } else {
      const oldFiles = [...files];
      this.setState(
        {
          files: [],
          uploadSuccess: false,
          filesUploaded: [],
        },
        () => {
          this.onDelete(oldFiles);
        }
      );
    }
  };

  onDelete = (files: Array<Object>) => {
    const { onDelete, multiple } = this.props;
    if (onDelete && onDelete instanceof Function) {
      onDelete(files);
      if (!multiple) {
        this.setState({
          uploadSuccess: false,
          uploading: false,
        });
      }
    }
    // @todo for other cases
  };

  onView = (files: Array<Object>) => {
    const { onPreview } = this.props;
    if (onPreview && onPreview instanceof Function) {
      const utils = this.getUtils();
      onPreview(files, utils);
    }
  };

  getUtils = () => ({
    getFileSource,
    getFileExtension,
    getFileTypes,
  });

  getEffectiveFiles = () => {
    const { autoUpload, isControlled, files: filesProp } = this.props;
    if (isControlled) return [...(filesProp || [])];

    const { files, filesUploaded } = this.state;
    return autoUpload ? filesProp || [] : [...filesUploaded, ...files];
  };

  getFileName = (filename: string) => {
    if (filename) {
      const filenameArray = filename.split("/");
      return filenameArray[filenameArray.length - 1];
    }
    return "File";
  };

  // getThumbnail = () => { };

  Service: Object;

  UploadPath: Array<string>;

  render() {
    const {
      //   service,
      // autoUpload,
      className,
      multiple,
      valueAccessor,
      actions,
      hideClear,
      showPreview,
      showFileNameOnly,
      disableItemDeletion,
      fileSourceAccessor,
    } = this.props;
    const { /* files, filesUploaded, */ uploading } = this.state;
    const previewFiles = this.getEffectiveFiles();

    return (
      <div className={["dropzone-upload", className].join(" ")}>
        <div
          className={`dropzone-content ${
            showFileNameOnly ? "align-items-start" : ""
          }`}
        >
          <Dropzone
            onFilesAdded={this.onFilesAdded}
            disabled={this.getDisabledStatus()}
            uploading={uploading}
            multiple={multiple}
            files={previewFiles}
            valueAccessor={valueAccessor}
          />
          {
            <Preview showFileNameOnly={showFileNameOnly}>
              {previewFiles.map((file: any) => {
                // if (!file) return null;
                let src;
                if (
                  fileSourceAccessor &&
                  fileSourceAccessor instanceof Function
                ) {
                  src = fileSourceAccessor(file);
                } else {
                  const accessors = [];
                  if (valueAccessor) {
                    accessors.push(valueAccessor);
                  } else {
                    accessors.push("name");
                  }

                  src = getFileSource(file, accessors);
                }

                return (
                  <div
                    key={file[valueAccessor] || file.name || file}
                    className={!showFileNameOnly ? "Cell" : ""}
                  >
                    {showPreview ? (
                      <>
                        {showFileNameOnly ? (
                          <FileNameWrapper
                            className="previewButton controlButton"
                            onClick={() => {
                              this.onView([file]);
                            }}
                            onKeyDown={this.onView}
                            role="button"
                          >
                            {file.original_file_name ||
                              this.getFileName(file[valueAccessor])}
                          </FileNameWrapper>
                        ) : (
                          <Thumbnail width={64} height={64}>
                            <img src={src} alt="" />
                            <div className="controlActions">
                              <span
                                className="previewButton controlButton"
                                onClick={() => {
                                  this.onView([file]);
                                }}
                                onKeyDown={this.onView}
                                role="button"
                              >
                                <FaEye />
                              </span>
                              {!disableItemDeletion ||
                              disableItemDeletion === false ? (
                                <span
                                  className="clearButton controlButton"
                                  onClick={() => {
                                    this.onDelete([file]);
                                  }}
                                  onKeyDown={this.onDelete}
                                  role="button"
                                >
                                  <FaTrashAlt />
                                </span>
                              ) : null}
                            </div>
                          </Thumbnail>
                        )}
                      </>
                    ) : (
                      <div className="Filename">
                        {!showPreview
                          ? file[valueAccessor] || file.name || file
                          : null}
                      </div>
                    )}
                  </div>
                );
              })}
            </Preview>
          }
        </div>
        {!hideClear || actions ? (
          <Controls className="dropzone-actions">
            {!hideClear && previewFiles.length ? (
              <Button size="sm" onClick={this.clearUploads}>
                Clear
              </Button>
            ) : null}
            {!multiple &&
              previewFiles.length > 0 &&
              actions &&
              actions.map(({ onClick, content, size, color }) => (
                <Button
                  size={size || "sm"}
                  onClick={onClick}
                  color={color || "secondary"}
                >
                  {content}
                </Button>
              ))}
          </Controls>
        ) : null}
      </div>
    );
  }
}

export default Upload;
