import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

const BannerCrumbWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 1rem;
  margin-bottom: 2rem;
`;

const BannerCrumbItem = styled(Link)`
  font-size: 1rem;
  font-weight: bold;
  color: #6e7075;
  // &.active-crumb {
  //   font-weight: normal;
  // }
`;

const BannerCrumbSeperator = styled.span`
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  font-weight: bold;
`;

export type CrumbType = {
  to: string,
  label: string,
  seperator?: string,
  active?: boolean,
};

type Props = {
  crumbs: Array<CrumbType>,
};

export default (props: Props) => {
  const { crumbs } = props;
  return (
    <BannerCrumbWrapper className="crumb-item-wrapper">
      {crumbs.map((crumb, index) => (
        <div key={`${crumb.label}`}>
          <BannerCrumbItem
            to={crumb.to}
            className={`crumb-item ${crumb.active ? "active-crumb" : ""}`}
          >
            {crumb.label}
          </BannerCrumbItem>
          {crumbs.length - 1 !== index ? (
            <BannerCrumbSeperator>
              {crumb.seperator || "/"}
            </BannerCrumbSeperator>
          ) : null}
        </div>
      ))}
    </BannerCrumbWrapper>
  );
};
