import React from "react";
import { Link } from "react-router-dom";
import Style from "styled-components";

import { IMAGES } from "../../assets";

type Props = {};

const Wrapper = Style.div`
  width: 100%;

  .brand-link {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .brand-logo-img {
    width: 45%;
  }
`;

export default (props: Props) => {
  return (
    <Wrapper>
      <Link to="/" className="brand-link">
        <img
          src={IMAGES.BRAND.LOGO_WHITE.png}
          alt="SWAN Logo"
          className="brand-logo brand-logo-img brand-logo-img--png"
        />
      </Link>
    </Wrapper>
  );
};
