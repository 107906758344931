import React, { Fragment, useState } from "react";
import { Card, CardBody, CardHeader, Table } from "reactstrap";
import styled from "styled-components";
import Axios from "axios";
import { ResourceGenerator } from "@swan/api"; // eslint-disable-line
import { Spinner } from "../../index";
import InfiniteScroll from "../../InfiniteScroll";

const Container = styled(CardBody)`
  height: 450px;
  overflow: auto;

  ::-webkit-scrollbar {
    width: 0.35rem !important;
    height: 0.35rem !important;
  }
`;

type Props = {
  data: Object,
  tableTitle: string,
  id: string,
  exportButton?: any,
};

const service = new (ResourceGenerator(
  Axios,
  "analytics/charts/generate-chart"
))();

const ChartTable = (props: Props) => {
  const { data, tableTitle, id, exportButton } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [rows, setRows] = useState(data.rows);
  const [page, setPage] = useState(1);
  const [isLoaderHidden, setIsLoaderHidden] = useState(false);

  const appendRows = newRows => {
    if ((newRows || []).length > 0) {
      setRows([...rows, ...newRows]);
      setPage(page + 1);
    } else {
      setIsLoaderHidden(true);
    }
  };

  const loadData = () => {
    setIsLoading(true);

    service
      .get(`${id}?page=${page + 1}`)
      .then(response => {
        appendRows(response.rows);
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  return (
    <Fragment>
      <Card>
        <CardHeader
          style={{ textAlign: "center" }}
          className="d-flex justify-content-between align-items-center text-white brand-01--bg chart-header"
        >
          <h3>{tableTitle}</h3>
          <div>{exportButton && exportButton}</div>
        </CardHeader>
        {rows ? (
          <Container className="chart-body" id="container-of-the-table">
            <InfiniteScroll
              callback={loadData}
              canLoadMore={!isLoading && !isLoaderHidden && data.isPaginated}
              loaderComponent={<Spinner className="mt-3" />}
            >
              <Table borderless striped hover>
                <thead>
                  <tr>
                    {data.labels.map(label => (
                      <th className="text-center font-weight-bold" key={label}>
                        {label}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {rows.map(row => (
                    <tr key={row.id}>
                      {row.data.map(col => (
                        <td
                          className="text-center"
                          key={`${col.label}-${col.value}`}
                        >
                          {col.value}
                        </td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </Table>
            </InfiniteScroll>
          </Container>
        ) : (
          <Container className="chart-body overflow-hidden d-flex justify-content-center align-items-center">
            <p className="text-03--color font-weight-bold">
              No Data Available Right Now
            </p>
          </Container>
        )}
      </Card>
    </Fragment>
  );
};

ChartTable.defaultProps = {
  exportButton: undefined,
};
export default ChartTable;
