import * as React from "react";
import { RemoteSelect } from "@swan/themer";
import { ObjectService, Resource } from "@swan/api"; // eslint-disable-line

import Field from "./partials/Field";
import type { ElementProps } from "./types";
import { RendererContext } from "../Main";

function onSelectChange(onBatchChange, polymorphType, schema, value) {
  if (onBatchChange) {
    const v = {};
    Object.keys(value).forEach(attr => {
      if (attr === "value") {
        v[`${polymorphType}_id`] = value[attr];
      } else {
        v[attr] = value[attr];
      }
    });
    onBatchChange(v);
  }
}

class PolymorphChildConfigured extends React.Component<
  ElementProps & {
    object: string,
    polymorphChildEndpoint?: string,
  },
  {
    service?: Object,
    objectDefinition?: Object,
    labelAttribute?: ?string,
    labelExpression?: ?string,
  }
> {
  static defaultProps = {
    polymorphChildEndpoint: undefined,
  };

  state = {
    service: null,
    objectDefinition: null,
    labelAttribute: null,
    labelExpression: null,
  };

  componentDidMount() {
    const {
      schema: { options },
      object,
    } = this.props;
    // if (options) {
    //   if (options.polymorphChildEndpoint) {
    //     const { polymorphChildEndpoint } = options;
    //     const service = new Resource(polymorphChildEndpoint);
    //     this.setState({
    //       service,
    //       labelAttribute: "name",
    //     });
    //   }
    // }

    ObjectService.getObjectByName(object).then(def => {
      if (def) {
        const { polymorphChildEndpoint } = this.props;
        const relation =
          def.relationships[(options || {}).relationName] || null;
        if (relation) {
          const service = new Resource(
            polymorphChildEndpoint || relation.polymorph_child_endpoint
          );
          this.setState({
            service,
            objectDefinition: def,
            labelAttribute: "name",
          });
        }
      }
    });
  }

  getValueMap() {
    const { objectDefinition } = this.state;
    if (!objectDefinition) {
      return {};
    }
    return {
      type: `${this.getPolymorphType()}_type`,
    };
  }

  getFilters() {
    const { objectDefinition } = this.state;
    const { data } = this.props;
    if (!objectDefinition) {
      return {};
    }
    return {
      type: (data || {})[`${this.getPolymorphType()}_type`] || "",
    };
  }

  getPolymorphType() {
    const { schema } = this.props;
    if (schema) {
      if (schema.options) {
        if (schema.options.polymorphType) {
          return schema.options.polymorphType;
        }
      }
    }
    return "";
  }

  render() {
    const {
      schema,
      // onChange,
      onBatchChange,
      error,
      data,
      readOnly,
      hideLabel,
    } = this.props;
    const { service, labelAttribute, labelExpression } = this.state;
    if (!service) {
      return null;
    }
    const attributeName = `${this.getPolymorphType()}_id`;
    return (
      <>
        <RemoteSelect
          {...schema.options || {}}
          name={attributeName || ""}
          value={(data || {})[attributeName || ""]}
          initialDisplay={
            (data || {})[`${schema.attributeName || ""}_display` || ""]
          }
          label={schema.label}
          hideLabel={hideLabel}
          onChange={e => {
            onSelectChange(onBatchChange, this.getPolymorphType(), schema, e);
          }}
          error={(error || {})[attributeName || ""]}
          labelAttribute={labelAttribute}
          labelExpression={labelExpression}
          service={service}
          // filters={this.getFilters()}
          valueRetreiveFilter={this.getFilters()}
          valueMap={this.getValueMap()}
          // creatable
          static={readOnly || (schema.options || {}).readOnly}
        />
      </>
    );
  }
}

const PolymorphChild = (props: ElementProps) => (
  <RendererContext.Consumer>
    {({ object, options }) => (
      <PolymorphChildConfigured
        {...props}
        object={object}
        recordChildKnown={options.recordChildKnown || false}
      />
    )}
  </RendererContext.Consumer>
);

export default Field(PolymorphChild);
