import * as React from "react";
import { connect } from "react-redux";
import { Draggable } from "react-beautiful-dnd";
import { Button } from "reactstrap";
import { FaCheck } from "react-icons/fa";

// utils
import { has, hasIn } from "lodash";
// defintions
import { allDefinitions } from "../../definitions/pencils";

import { schemaActions } from "../../state/reducers/schema";
import type { Props } from "./types";
import type { Schema } from "../../constants/flowTypes";
import type { pencilDef as pencilDefType } from "../../types";

import jsonSchemaProvider from "../../utils/jsonSchemaProvider";
import getObjectFullness from "../../utils/getObjectFullness";
import AttributesService from "../../services/attributes";
import { InkDrop, InkDropCol, InkDropInput } from "./InkDrop";

type NewFieldState = {
  label: string,
};

type NewFieldProps = Props & {
  replaceElement: (id: string, schema: Schema) => void,
};

class NewField extends React.Component<NewFieldProps, NewFieldState> {
  constructor(props) {
    super(props);
    this.state = {
      label: props.schema.label,
    };
  }

  changeLabel(label) {
    this.setState({ label });
  }

  done() {
    const { schema, id, replaceElement } = this.props;
    const { label } = this.state;
    // Create temporary attribute to get attributeId
    const attributeId = AttributesService.createAttribute(
      label,
      schema.pencilType || ""
    );
    const newSchema = jsonSchemaProvider({
      type: schema.pencilType || "",
      label,
      attributeId,
      options: getObjectFullness(schema.options || {}),
      // options: schema.options,
    });

    replaceElement(id, newSchema);
  }

  render() {
    const { id, index, schema } = this.props;
    const { label } = this.state;
    const { pencilType } = schema;
    const pencilDef: pencilDefType | boolean =
      pencilType && hasIn(allDefinitions, [pencilType, "pencilDef"])
        ? allDefinitions[pencilType].pencilDef
        : false;

    const pencilDefTitle: any =
      pencilDef && has(pencilDef, "title") ? pencilDef.title : label;

    return (
      <Draggable draggableId={id} index={index || 0}>
        {draggableProvided => (
          <InkDrop
            ref={draggableProvided.innerRef}
            {...draggableProvided.draggableProps}
            {...draggableProvided.dragHandleProps}
          >
            <InkDropCol size={6}>
              <InkDropInput
                bsSize="sm"
                type="text"
                value={label}
                onChange={e => this.changeLabel(e.target.value)}
                onBlur={() => this.done()}
                onKeyPress={e => (e.key === "Enter" ? this.done() : null)}
                autoFocus
              />
            </InkDropCol>
            <InkDropCol size={3} colName="inkLabel">
              {pencilDefTitle}
            </InkDropCol>
            <InkDropCol size={1}>
              <Button size="sm" onClick={() => this.done()}>
                <FaCheck />
              </Button>
            </InkDropCol>
          </InkDrop>
        )}
      </Draggable>
    );
  }
}

export default connect(
  null,
  {
    replaceElement: schemaActions.replaceElement,
  }
)(NewField);
