import React from "react";

// export const RoutesContext = React.createContext<RouteContext>({
//   registerRoutes: (routes: Array<Route>, module: string) => {},
//   routes: []
// });
// $FlowFixMe
export default React.createContext({
  routes: []
});
