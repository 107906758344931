export default {
  uid: "singleOn",
  attributeId: "",
  formId: "",
  pencilDef: {
    title: "Record Single On",
    group: "FIELD",
    handleIcon: "FaStarOfLife",
    styles: {},
  },
  options: {
    title: "Primary Record",
    type: "singleOn",
    // required: false,
    singleOnLabel: "Yes",
    singleOffLabel: "No",
    singleOnRequired: false,
    singleOnDisplayWidget: "RADIO", // default
  },
};
