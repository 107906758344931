import Axios from "axios";
import { ObjectService, ResourceGenerator } from "@swan/api";

const getObjectResourceService = (apiEndpoint?: string) => {
  if (apiEndpoint) {
    const Service = ResourceGenerator(Axios, `${apiEndpoint}`);
    return new Service();
  }
  return undefined;
};

export { ObjectService, getObjectResourceService };

export default {};
