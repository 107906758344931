// @flow
import React, { Component } from "react";

type Props = {
  resource?: Object,
  inputName?: string,
  inputType?: string,
  inputValue?: string | number,
  placeholder?: string,
  onChange?: Function,
};

export default class Input extends Component<Props> {
  static defaultProps = {
    resource: undefined,
    inputType: "TEXT",
    inputName: undefined,
    inputValue: undefined,
    placeholder: undefined,
    onChange: undefined,
  };

  onChangeInput = (e: SyntheticInputEvent<HTMLInputElement>) => {
    const { onChange, resource } = this.props;
    const currentResource = resource || {};
    if (onChange) {
      onChange({
        ...currentResource,
        data: {
          input: e.target.value,
        },
      });
    }
  };

  render() {
    const {
      inputType: type,
      inputName: name,
      inputValue: value,
      placeholder,
    } = this.props;

    return (
      <div className="form-group">
        <input
          type={(type || "").toLowerCase()}
          name={name}
          placeholder={placeholder || name}
          className="form-control"
          onChange={this.onChangeInput}
          value={value}
        />
      </div>
    );
  }
}
