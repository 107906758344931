import * as React from "react";
import styled from "styled-components";
import { /* Skeleton */ Spinner } from "@swan/themer";

import type { ElementProps } from "../types";
import { RendererContext } from "../../Main";

const FieldWrapper = styled.div(({ theme }) =>
  Object.assign({}, theme.FieldWrapper ? { ...theme.FieldWrapper } : {}, {})
);

const ComponentElement = styled.div({
  position: "relative",
});

const LoadingOverlay = styled.div({
  position: "absolute",
  right: 0,
  top: 0,
  width: "auto",
  height: "100%",
});

// const { Field: FieldSkeleton } = Skeleton;

const CustomComponentWrapper = (Component: any) => (props: ElementProps) => (
  <RendererContext.Consumer>
    {({
      data,
      onChange,
      onBatchChange,
      error,
      loading,
      addButtons,
      removeButtons,
      readOnly,
      isNew,
      fieldsLoading,
      fieldsLocked,
    }) => (
      <FieldWrapper>
        <ComponentElement>
          <Component
            {...props}
            data={props.data || data}
            onChange={props.onChange || onChange}
            onBatchChange={props.onBatchChange || onBatchChange}
            error={error}
            // loading={
            //   loading ||
            //   fieldsLoading.indexOf((props.schema || {}).attributeName) !== -1
            // }
            loading={loading}
            addButtons={addButtons}
            removeButtons={removeButtons}
            readOnly={
              readOnly ||
              fieldsLocked.indexOf((props.schema || {}).attributeName) !== -1
            }
            isNew={isNew}
          />
          {fieldsLoading.indexOf((props.schema || {}).attributeName) !== -1 ||
          fieldsLoading.indexOf(
            ((props.schema || {}).id || "").replace("attrib_", "") // Some pencils don't have attribute name, id can be used (like polymorph parent)
          ) !== -1 ? (
            <LoadingOverlay>
              <Spinner size="sm" color="primary" />
            </LoadingOverlay>
          ) : null}
        </ComponentElement>
      </FieldWrapper>
    )}
  </RendererContext.Consumer>
);

export default CustomComponentWrapper;
