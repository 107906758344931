import React from "react";
import { compose } from "redux";
import { type Schema } from "@swan/form-renderer"; //eslint-disable-line
import { pluralize } from "@swan/helpers/utils";
import { ModuleBreadcrumbFactory } from "@swan/helpers/UI";
import { withRouter } from "react-router-dom";
import FormService from "../services/formService";

const RecordBreadcrumbsFactory = (
  appRoutes: any,
  connect: Function,
  actions: { [string]: Function },
  selectors: { [string]: Function }
) => {
  const RecordBreadcrumbsGenerator = (
    object: string,
    formType: string /* options?: {} */
  ) => {
    const pluralObject = pluralize(object);

    type Props = {
      object: string,
      type: string,
      record: Object,
      headerTheme?: "default",
      leafExpression?: string,
    };

    type State = {
      form: ?{
        options: Object,
        body: Schema,
      },
    };

    class DynamicBreadcrumbs extends React.Component<Props, State> {
      static defaultProps = {
        leafExpression: undefined,
        headerTheme: "default",
      };

      state = {
        form: null,
      };

      componentDidMount() {
        const { leafExpression } = this.props;
        if (!leafExpression) {
          this.loadForm();
        }
      }

      getLeaf() {
        const { record, leafExpression } = this.props;
        const { form } = this.state;
        let leaf;
        let expr = leafExpression;
        if (!expr) {
          if (record) {
            if (form) {
              if (form.options) {
                if (form.options.breadcrumbs) {
                  expr = form.options.breadcrumbs;
                }
              }
            }
          }
        }
        if (expr && record) {
          leaf = expr;
          Object.keys(record).forEach(key => {
            leaf = leaf.replace(`\${${key}}`, record[key]);
          });
        }
        return leaf;
      }

      loadForm() {
        const service = new FormService();
        service.getObjectForm(object, formType).then(response => {
          this.setState({ form: response.form });
        });
      }

      render() {
        const { leafExpression /* headerTheme */ } = this.props;
        const { form } = this.state;
        if (!form && !leafExpression) {
          return null;
        }

        const Breadcrumb = ModuleBreadcrumbFactory({ routes: appRoutes });

        return <Breadcrumb leaf={this.getLeaf()} {...this.props} />;
      }
    }

    function mapStateToProps(state: Object, ownProps: Object) {
      return {
        ...selectors[`${pluralObject}Selectors`].getCrudFormState(
          state[pluralObject],
          ownProps
        ),
      };
    }

    return compose(
      withRouter,
      connect(mapStateToProps)
    )(DynamicBreadcrumbs);
  };

  return RecordBreadcrumbsGenerator;
};

export default RecordBreadcrumbsFactory;
