import React, { Component } from "react";
import ReactSelect, { components as ReactSelectComponents } from "react-select";
import classNames from "classnames";
import { FaArrowDown } from "react-icons/fa";

import FieldWrapper, { type FieldProps } from "./partials/FieldWrapper";
// partials
import { onMenuList } from "./partials/styles/select2";

export type InputProps = FieldProps & {
  itemLabelAccessor?: Object => string,
  itemIdAccessor?: Object => string,
  multiple?: boolean,
  data?: Array<{
    id: string | number,
    label: string,
  }>,
  isClearable: boolean,
  getValue?: (
    data: Array<{ id: string | number, label: string }>,
    selectedItem: string | Object
  ) => string | Object,
};

type InputState = {
  isOpen: boolean,
};

const DropdownIndicator = props => (
  <ReactSelectComponents.DropdownIndicator {...props}>
    <FaArrowDown />
  </ReactSelectComponents.DropdownIndicator>
);

export class Field extends Component<InputProps, InputState> {
  constructor(props: any) {
    super(props);
    this.state = {
      isOpen: false,
    };
  }

  menuSelected = () => {
    this.setState(prevState => ({
      isOpen: !prevState.isOpen,
    }));
  };

  onMenuOpen = () => {
    this.setState({
      isOpen: true,
    });
  };

  onBlur = () => {
    this.setState({ isOpen: false });
  };

  getOptions = (data: Array<Object>): Array<Object> => {
    if (!Array.isArray(data)) return [];
    const { itemLabelAccessor, itemIdAccessor } = this.props;
    return data
      .map(item => {
        const val = itemIdAccessor ? itemIdAccessor(item) : item.id;
        if (!val) return false;
        return {
          value: val,
          label: itemLabelAccessor ? itemLabelAccessor(item) : item.label,
        };
      })
      .filter(item => item !== false);
  };

  getValue = (value: string | Object) => {
    const { data, getValue, itemIdAccessor, itemLabelAccessor } = this.props;

    if (getValue && getValue instanceof Function) {
      return getValue(data, value);
    }

    if (value === Object(value)) {
      return value;
    }
    if (data) {
      const selectedItem = data.find(
        v => value === (itemIdAccessor ? itemIdAccessor(v) : v.id)
      );

      if (selectedItem) {
        return {
          value: itemIdAccessor
            ? itemIdAccessor(selectedItem)
            : selectedItem.id,
          label: itemLabelAccessor
            ? itemLabelAccessor(selectedItem)
            : selectedItem.id,
        };
      }
    }

    return value;
  };

  render() {
    const {
      id,
      name,
      value,
      required,
      onChange,
      data,
      multiple,
      isClearable,
    } = this.props;

    const { isOpen } = this.state;

    return (
      <ReactSelect
        isMulti={multiple}
        id={id || name}
        className={classNames("select2-control", {
          "select2-control-selected": isOpen,
          "select2-control-required": required,
        })}
        defaultValue={this.getValue(value)}
        value={this.getValue(value)}
        options={this.getOptions(data || [])}
        onChange={newVal =>
          onChange ? onChange({ target: { value: newVal } }) : null
        }
        blurInputOnSelect={false}
        onClick={this.menuSelected}
        onMenuOpen={this.onMenuOpen}
        onBlur={this.onBlur}
        isClearable={isClearable !== false}
        components={{ DropdownIndicator }}
        styles={{
          menuList: () => onMenuList || {},
        }}
      />
    );
  }
}

export default FieldWrapper(Field);
