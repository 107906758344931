// import { REHYDRATE } from "redux-persist/lib/constants";
import { appTypes } from "./index";

// initial states
const initialState = {
  loading: false,
  pencils: {},
  arts: {},
  formId: [],
  ui: {
    pencilGroupsOpen: ["FIELD", "LAYOUT"],
  },
  draggingOverWith: null,
  tabsState: {},
  attributes: [],
};

// reducer
// $FlowFixMe
export default (state = initialState, action) => {
  switch (action.type) {
    case appTypes.APP_LOADING:
      return {
        initialState,
        loading: true,
      };
    case appTypes.APP_LOADING_PENCILS:
      return {
        ...state,
        pencils: action.payload.bygroup, // Object.assign(state.pencils, action.payload),
        allDefinitions: action.payload.alldefs,
      };
    case appTypes.APP_LOADING_ARTS:
      return {
        ...state,
        arts: action.payload,
      };
    case appTypes.APP_LOAD_ATTRIBUTES:
      return {
        ...state,
        attributes: [...action.payload.attributes],
      };
    case appTypes.APP_LOAD_OBJECT_FORM_ID:
      return {
        ...state,
        formId: action.payload.formId,
      };
    case appTypes.APP_READY:
      return {
        ...state,
        loading: false,
      };
    case appTypes.APP_UI:
      return {
        ...state,
        ui: Object.assign(state.ui, { pencilGroupsOpen: action.payload }),
      };
    case appTypes.START_DRAGGING:
      return {
        ...state,
        draggingOverWith: action.payload,
      };
    case appTypes.STOP_DRAGGING:
      return {
        ...state,
        draggingOverWith: null,
      };
    case appTypes.GO_TO_TAB:
      return {
        ...state,
        tabsState: {
          ...state.tabsState,
          [action.tabsId]: action.tabIndex,
        },
      };
    default:
      return state;
  }
};
