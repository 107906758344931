import React from "react";
import { uriProvider } from "./utils/uriHelpers";
import {
  MODULE_NAME,
  MODULE_DISPLAY_NAME,
  MODULE_SHORT_DISPLAY_NAME,
} from "./config";

// $FlowFixMe Should be React.lazy<DashboardProps> but causing issue in webpack
const Home = React.lazy(() => import("./Module"));

// https://github.com/ReactTraining/react-router/tree/master/packages/react-router-config
const entry_routes = [
  {
    path: uriProvider(),
    name: MODULE_NAME,
    component: Home,
    Permission: ["module:crm"],
  },
];

export default {
  name: MODULE_NAME,
  displayName: MODULE_DISPLAY_NAME,
  mainNav: {
    name: MODULE_DISPLAY_NAME,
    url: uriProvider(),
    icon: "fa-cogs",
    permissions: ["module:crm"],
    color: "#00C7E5",
    shortName: MODULE_SHORT_DISPLAY_NAME,
    weight: 50,
  },
  preloadPermissions: {
    groups: ["crm", "core"],
  },
  productNav: [
    {
      name: "CRM",
      url: uriProvider(),
      icon: "fa-cogs",
    },
    {
      name: "Sales",
      header: true,
      permissionsMatchAll: false,
      permissions: ["contact:view", "account:view", "appointment:view"],
    },
    {
      name: "Accounts",
      url: uriProvider("accounts"),
      icon: "fa-cogs",
      permissions: ["account:view"],
    },
    {
      name: "Contacts",
      url: uriProvider("contacts"),
      icon: "fa-cogs",
      permissions: ["contact:view"],
    },
    {
      name: "Appointments",
      url: uriProvider("appointments"),
      icon: "fa-cogs",
      permissions: ["appointment:view"],
    },
    {
      name: "Prospecting",
      permissionsMatchAll: false,
      header: true,
      permissions: [
        "campaign:view",
        "lead:view",
        "prospect:view",
        "enquiry:view",
        "opportunity:view",
        "project:view",
      ],
    },
    {
      name: "Campaigns",
      url: uriProvider("campaigns"),
      icon: "fa-cogs",
      permissions: ["campaign:view"],
    },
    {
      name: "Leads",
      url: uriProvider("leads"),
      icon: "fa-cogs",
      permissions: ["lead:view"],
    },
    {
      name: "Prospects",
      url: uriProvider("prospects"),
      icon: "fa-cogs",
      permissions: ["prospect:view"],
    },
    {
      name: "Enquiries",
      url: uriProvider("enquiries"),
      icon: "fa-cogs",
      permissions: ["enquiry:view"],
    },
    {
      name: "Opportunities",
      url: uriProvider("opportunities"),
      icon: "fa-cogs",
      permissions: ["opportunity:view"],
    },
    {
      name: "Projects",
      url: uriProvider("projects"),
      icon: "fa-cogs",
      permissions: ["project:view"],
    },
    {
      name: "Order Management",
      header: true,
      permissionsMatchAll: false,
      permissions: ["quote:view", "salesOrder:view"],
    },
    {
      name: "Quotes",
      url: uriProvider("quotes"),
      icon: "fa-cogs",
      permissions: ["quote:view"],
    },
    {
      name: "Sales Orders",
      url: uriProvider("sales_orders"),
      icon: "fa-cogs",
      permissions: ["salesOrder:view"],
    },
    {
      name: "Tools",
      header: true,
      permissions: ["crm:configuration"],
    },
    {
      name: "Configuration",
      url: uriProvider("configuration"),
      icon: "fa-cogs",
      permissions: ["crm:configuration"],
    },
  ],
  routes: entry_routes,
};
