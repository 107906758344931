import React from "react";

import Column from "./Column";
import { Title, Board } from "./Styled";

import type { ColumnConfigType, BoardViewType } from "./types";

type Props = BoardViewType;

type State = {
  activeFilters: Array<Object>,
};

class Main extends React.Component<Props, State> {
  static defaultProps = {
    title: undefined,
    titleVariant: "h1",
    columns: [],
    spacing: { padding: 1 },
    filters: [],
  };

  constructor(props: Props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    this.setHtmlWrapper();
  }

  componentWillUnmount() {
    this.setHtmlWrapper(false);
  }

  setHtmlWrapper = (inject?: boolean = true) => {
    const { id } = this.props;

    const element = document.querySelector("html");
    if (!element) return;

    if (inject) {
      element.setAttribute("data-swan-kanban", id);
    } else {
      element.removeAttribute("data-swan-kanban");
    }
  };

  render() {
    const {
      id,
      title,
      titleVariant,
      columns,
      spacing,
      service,
      serviceProvider,
      serviceResponseHandle,
      servicePaginatorParams,
      serviceFilters,
      data,
      components,
      cardOptions,
      customStyle,
    } = this.props;

    return (
      <Board
        data-kanban-board={id}
        spacing={spacing}
        customStyle={
          customStyle && typeof customStyle === "string"
            ? customStyle
            : undefined
        }
        style={
          customStyle && customStyle.constructor === Object
            ? customStyle
            : undefined
        }
      >
        {title ? (
          <Title variant={titleVariant} className="board-title">
            {title}
          </Title>
        ) : (
          undefined
        )}
        <div className="board-container">
          {(columns || []).map((column: ColumnConfigType) => {
            const {
              id: colId,
              title: colTitle,
              data: colData,
              service: colService,
              serviceProvider: colServiceProvider,
              serviceResponseHandle: colServiceResponseHandle,
              servicePaginatorParams: colServicePaginatorParams,
              serviceFilters: colServiceFilters,
              cardOptions: colCardOptions,
              ...restProps
            } = column;

            const ColumnComponent: any =
              (components || {}).column || Column || null;

            if (!ColumnComponent) return null;

            return (
              <ColumnComponent
                id={colId}
                title={colTitle}
                data={colData || (data || {})[colId] || []}
                service={colService || service}
                serviceProvider={colServiceProvider || serviceProvider}
                serviceResponseHandle={
                  colServiceResponseHandle || serviceResponseHandle
                }
                servicePaginatorParams={
                  colServicePaginatorParams || servicePaginatorParams
                }
                serviceFilters={colServiceFilters || serviceFilters}
                components={
                  (components || {}).card
                    ? { card: (components || {}).card }
                    : undefined
                }
                cardOptions={colCardOptions || cardOptions}
                {...restProps}
              />
            );
          })}
        </div>
      </Board>
    );
  }
}

export default Main;
