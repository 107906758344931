import React, { Component, Fragment } from "react";
import { Progress } from "reactstrap";

type Props = {
  isLoadingMore?: boolean,
  isLoading?: boolean,
  element?: any,
  loadMore: () => void,
  canLoadMore?: boolean,
};

type State = {
  element: any,
};

/**
 * @deprecated
 * Please use InfiniteScroll.js instead
 * Used to detect if user reached end of the screen to load more items.
 */
class LoadMore extends Component<Props, State> {
  static isBottom(el: { current: null | HTMLDivElement }) {
    if (!el.current) {
      return false;
    }
    // $FlowFixMe
    return el.current.getBoundingClientRect().bottom <= window.innerHeight;
  }

  static defaultProps = {
    isLoading: false,
    isLoadingMore: false,
    element: document,
    canLoadMore: true,
  };

  constructor(props: Props) {
    super(props);
    this.loadMoreRef = React.createRef();
  }

  state = {
    element: document,
  };

  componentDidMount(): void {
    const { element } = this.props;
    // $FlowFixMe
    (element.current || document).addEventListener(
      "scroll",
      this.trackScrolling
    );
  }

  componentWillReceiveProps(nextProps: Props) {
    const { element } = nextProps;
    if (element !== null) {
      this.setState({
        element,
      });
      // $FlowFixMe
      (element.current || document).addEventListener(
        "scroll",
        this.trackScrolling
      );
    }
  }

  componentWillUnmount() {
    const { element: propElement } = this.state;
    const element = propElement || document;
    // $FlowFixMe
    (element.current || document).removeEventListener(
      "scroll",
      this.trackScrolling
    );
  }

  trackScrolling = () => {
    const { loadMore, isLoading, canLoadMore } = this.props;
    const { element } = this.state;
    if (!element) return null;
    if (LoadMore.isBottom(this.loadMoreRef)) {
      if (!isLoading && canLoadMore) {
        loadMore();
      }
    }
    return null;
  };

  loadMoreRef: { current: null | HTMLDivElement };

  render() {
    const { isLoadingMore } = this.props;
    return (
      <Fragment>
        <div ref={this.loadMoreRef} />
        {isLoadingMore && (
          <Progress
            animated
            // color="warning"
            className="mt-3"
            value="100"
          />
        )}
      </Fragment>
    );
  }
}

export default LoadMore;
