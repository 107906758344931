import React, { type ComponentType } from "react";
import Styled from "styled-components";

import { GLOBAL_NAV_COLOR, GLOBAL_NAV_ICON_SIZE } from "../config";

type Props = {
  name: string,
  icon: ComponentType<any>,
  onClick?: Function,
  size?: number,
  color?: string,
  backgroundColor?: string,
};

const Wrapper = Styled.div`
  display: inline-block;
  padding-bottom: 0.5rem;

  button { 
    box-align: center;
    box-pack: center;
    align-items: center;
    border: 0px;
    border-radius: 50%;
    color: ${(props: any) => props.color || "#fff"};
    cursor: pointer;
    display: flex;
    justify-content: center;
    line-height: 1;
    outline: none;
    padding: 0px;
    position: relative;
    height: 40px;
    width: 40px;
    background-color: ${(props: any) => props.backgroundColor || "transparent"};

    &:hover {
      border-radius: 50%;
      background-color: ${(props: any) =>
        props.color ? `${props.color}4d` : "#ffffff4d"}
    }
  }
`;

const GlobalItemRenderer = ({
  name,
  icon: Icon,
  size,
  onClick,
  backgroundColor,
  color,
}: Props) => (
  <Wrapper {...{ color, backgroundColor }} title={name}>
    <button
      type="button"
      className={`global-nav-item global-nav-item--${(name || "")
        .replace(/\s+/g, "-")
        .toLowerCase()}`}
      onClick={onClick}
      title={name}
    >
      <Icon size={size} />
    </button>
  </Wrapper>
);

GlobalItemRenderer.defaultProps = {
  onClick: undefined,
  size: GLOBAL_NAV_ICON_SIZE,
  color: "#ffffff",
  backgroundColor: GLOBAL_NAV_COLOR,
};

export default GlobalItemRenderer;
