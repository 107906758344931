import React from "react";
import Upload from "../Upload";
import FieldWrapper, { type FieldProps } from "./partials/FieldWrapper";

export type InputProps = FieldProps & {
  placeholder?: string,
  type?: string,
};

const Field = props => (
  <Upload {...props} className="form-control form-control-upload" />
);

export default FieldWrapper(Field);
