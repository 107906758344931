// @flow
/* eslint-disable no-underscore-dangle */
import React, { Component } from "react";
import { DropDown } from "@swan/themer";
import styled from "styled-components";

// components
import ResourceRenderer from "./ResourceRenderer";

// operators
import { operatorFieldMap, operatorLabels } from "../operators";

// utils
import { resourceDataToCascade } from "../utils/provideObjectCascade";
import provideResourceTemplate from "../utils/provideResourceTemplate";

// types
import { type Condition, type Resource } from "../flowTypes";

type Props = {
  condition: Condition,
  object?: string,
  objectData?: Object,
  conditionsObserver: Function,
  conditionCount?: number,
  filteredAttributes?: Array<{
    key: string,
    value: string,
  }>,
};

type State = {
  operandFieldType: string,
  operandCollection: ?string,
};

// styled components -- starts
const Wrapper = styled.div(({ theme }) =>
  Object.assign(
    {
      display: "flex",
      flexDirection: "row",
      justifyContent: "flex-start",
      alignItems: "center",
      flex: 1,
      width: "100%",
    },
    theme.Wrapper ? { ...theme.Wrapper } : {}
  )
);

const Row = styled.div({
  display: "flex",
  flexDirection: "row",
  justifyContent: "flex-start",
  alignItems: "center",
  width: "100%",
});

const Col = styled.div(({ size, align }) =>
  Object.assign(
    {},
    {
      flex: 1,
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "center",
      width: "100%",
    },
    size ? { flexBasis: `${size}px`, flexGrow: 0 } : {},
    align
      ? { justifyContent: `${align !== "center" ? `flex-${align}` : align}` }
      : {}
  )
);

// --- ends

class ConditionRow extends Component<Props, State> {
  static defaultProps = {
    theme: {},
    object: undefined,
    objectData: undefined,
    filteredAttributes: undefined,
    conditionCount: undefined,
  };

  constructor(props: Props) {
    super(props);

    this.state = {
      operandFieldType: "",
      operandCollection: undefined,
    };
  }

  componentDidMount() {
    const {
      condition: { operandLeft },
    } = this.props;
    const { fieldTypes, collection } = resourceDataToCascade(operandLeft);

    const fieldType = fieldTypes.length
      ? fieldTypes[fieldTypes.length - 1]
      : undefined;

    if (fieldType || collection) {
      this.setState(
        Object.assign(
          {},
          fieldType ? { operandFieldType: fieldType } : {},
          collection ? { operandCollection: collection } : {}
        )
      );
    }
  }

  // 1. When LHS is changed
  // Assume that left operand is resource
  onChangeOperandLeft = (resource: Resource) => {
    const { condition, conditionsObserver } = this.props;
    const { data: resourceData } = resource;
    const { fieldTypes, collection } = resourceDataToCascade(resourceData);

    const fieldType = fieldTypes.length
      ? fieldTypes[fieldTypes.length - 1]
      : undefined;

    if (fieldType || collection) {
      this.setState(
        Object.assign(
          {},
          fieldType ? { operandFieldType: fieldType } : {},
          collection ? { operandCollection: collection } : {}
        )
      );
    }

    const newerCondition = {
      ...condition,
      operandLeft: resource,
    };

    Object.assign(newerCondition, {
      operandRight: provideResourceTemplate({
        key: "fieldType",
        value: fieldType,
      }),
    });

    conditionsObserver(newerCondition);
  };

  // 2. When RHS is changed
  onChangeOperandRight = (resource: Resource) => {
    const { condition, conditionsObserver } = this.props;

    const newerCondition = {
      ...condition,
      operandRight: resource,
    };

    conditionsObserver(newerCondition);
  };

  // 3. When Operator is changed
  onOperatorChange = (e: SyntheticInputEvent<HTMLSelectElement>) => {
    const { condition, conditionsObserver } = this.props;

    const newerCondition = {
      ...condition,
      operator: e.target.value,
    };

    conditionsObserver(newerCondition);
  };

  render() {
    const { operandFieldType, operandCollection } = this.state;
    const {
      object,
      objectData,
      filteredAttributes,
      condition: { id, operandLeft, operandRight, operator },
      conditionCount,
    } = this.props;

    return (
      <Wrapper>
        <Row>
          {(!conditionCount || conditionCount > 1) && (
            <Col size={50} align="center">
              {id}
            </Col>
          )}

          {/* 1. LHS */}
          <Col>
            <ResourceRenderer
              object={object}
              objectData={objectData}
              filteredAttributes={filteredAttributes}
              resource={operandLeft}
              onChange={this.onChangeOperandLeft}
            />
          </Col>
          {/* 2. Operators based on the attribute selected at first column */}
          <Col>
            <DropDown
              name="operator"
              data={
                operandFieldType && operatorFieldMap[operandFieldType]
                  ? [
                      ...operatorFieldMap[operandFieldType].map(o => ({
                        id: o,
                        label: operatorLabels[o] || o,
                      })),
                    ]
                  : [
                      ...operatorFieldMap.default.map(o => ({
                        id: o,
                        label: operatorLabels[o] || o,
                      })),
                    ]
              }
              placeholder="Select Operator"
              value={operator}
              onChange={this.onOperatorChange}
            />
          </Col>
          {/*
           * 3. RHS
           * @was: we assumed that only user input of type string or number
           * @now: able to handle all kind of resources
           * @todo: require a test case to confirm the same
           */}
          <Col>
            <ResourceRenderer
              object={operandCollection}
              resource={operandRight}
              onChange={this.onChangeOperandRight}
            />
          </Col>
        </Row>
      </Wrapper>
    );
  }
}

export default ConditionRow;
