import React from "react";
import styled from "styled-components";
import { FaExclamationCircle } from "react-icons/fa";

const NotFoundContainer = styled.div`
  font-size: 26px;
  text-align: center;
  width: 100%;
  float: left;
  margin-top: 20px;
  font-weight: bold;
  color: #dedede;
`;

const NotFoundIcon = styled.span`
  width: 100%;
  float: left;
  font-size: 146px;
  margin-bottom: 28px;
`;

export const NotFound = (
  { customMessage }: { customMessage?: string } = { customMessage: "" }
) => (
  <NotFoundContainer>
    <NotFoundIcon>
      <FaExclamationCircle />
    </NotFoundIcon>
    {customMessage || "Not Found"}
  </NotFoundContainer>
);
export default NotFound;
