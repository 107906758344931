import Axios from "axios";
import ResourceGenerator from "../resourceGenerator";
import ObjectsStorage from "../storage/ObjectStorage";
import { type SwanObject } from "../types";
// import type { SwanForm } from "../types";

const ObjectService = ResourceGenerator(Axios, "def/objects");

class CachedObjectService extends ObjectService {
  objects = null;

  objectsByName = {}; // Forms using route

  restored = false;

  namesToLoad = [];

  loadingPromise = null;

  batchLoadingPromise = null;

  /**
   * Restore state from local storage
   */
  restoreState() {
    const storage = new ObjectsStorage();
    const store = storage.loadItems();
    if (store) {
      this.objects = store.objects;
    }
    this.restored = true;
  }

  /**
   * Persist loaded permissions to local storage
   */
  persist() {
    const storage = new ObjectsStorage();
    storage.storeItems({
      objects: this.objects,
    });
  }

  executeLoadByIds = () => {
    if (this.batchLoadingPromise) {
      return this.batchLoadingPromise;
    }
    // $FlowFixMe
    this.batchLoadingPromise = new Promise((resolve, reject) => {
      setTimeout(() => {
        if (!this.namesToLoad.length) {
          return resolve({});
        }
        const ids = [...this.namesToLoad];
        this.namesToLoad = [];
        const tld = [];
        ids.forEach(i => {
          // Make sure we don't have them locally
          if (!this.objectsByName[i]) {
            tld.push(i);
          }
        });

        if (tld.length === 0) {
          return resolve({});
        }

        return super
          .get("bynames", { names: tld })
          .then(response => {
            this.objectsByName = {
              ...this.objectsByName,
              ...response,
            };
            resolve(response);
            this.batchLoadingPromise = null;
          })
          .catch(e => reject(e));
      }, 200);
    });
    return this.batchLoadingPromise;
  };

  /**
   * Load objectsByName
   */
  getObjectByName(name: string): Promise<SwanObject> {
    if (!this.restored) {
      this.restoreState();
    }
    if ((this.objectsByName || {})[name]) {
      return Promise.resolve(this.objectsByName[name]);
    }
    if (this.namesToLoad.indexOf(name) === -1) {
      this.namesToLoad.push(name);
    }
    return this.executeLoadByIds().then(() => {
      if ((this.objectsByName || {})[name]) {
        return Promise.resolve(this.objectsByName[name]);
      }
      // We got an old batch, wait again
      return this.executeLoadByIds().then(() => {
        if ((this.objectsByName || {})[name]) {
          return Promise.resolve(this.objectsByName[name]);
        }
        return Promise.reject();
      });
    });
  }

  /**
   * Load getObjectByName
   */

  getObjectAttributeLabel(object: string, attributeName: string) {
    return this.getObjectByName(object).then(def => {
      let i;
      for (i = 0; i < def.object_attributes.length; i += 1) {
        if (def.object_attributes[i].name === attributeName) {
          return def.object_attributes[i].label;
        }
      }
      return attributeName;
    });
  }
}

const srv = new CachedObjectService();

export default srv;
