import * as React from "react";
import { Route, Switch } from "react-router-dom";
import { find } from "lodash";

import {
  ListTopBar,
  type ListTopBarButtons,
  ModuleBreadcrumbFactory,
  ModuleContent,
} from "@swan/helpers/UI";

import { AuthorizedRoute, makeAuthorizedComponent } from "@swan/auth"; // eslint-disable-line

import ConnectedListFactory from "../DynamicLists/ConnectedListFactory";
import ConnectedFormFactory from "../DynamicForms/ConnectedFormFactory";
import RecordBreadcrumbsFactory from "../BreadCrumbs/RecordBreadcrumbsFactory";

import FormPropsFromRoute from "../DynamicForms/FormPropsFromRoute";

const DynamicCrudFactory = (
  connect: Function,
  actions: { [string]: Function },
  selectors: { [string]: Function },
  appRoutes: any,
  ServiceFactory: (object: string, data?: Object) => Object,
  factoryOverrides?: Object = {}, // connected factory overrides
  themeConfiguration?: Object = {
    headerTheme: "default",
    listTopBarLocation: "top",
    listTopBarCustomClasses: [],
  }
) => {
  const ListGenerator = ConnectedListFactory(
    connect,
    actions,
    selectors,
    ServiceFactory
  );
  const FormGenerator = ConnectedFormFactory(
    connect,
    actions,
    selectors,
    ServiceFactory
  );
  const Breadcrumb = ModuleBreadcrumbFactory({ routes: appRoutes });
  const RecordBreadcrumbsGenerator = RecordBreadcrumbsFactory(
    appRoutes,
    connect,
    actions,
    selectors
  );

  const DynamicCrudGenerator = ({
    object,
    editFormType = "details",
    createFormType = "create",
    listRoute,
    createRoute = `${listRoute}/new`,
    editRoute = `${listRoute}/:id`,
    searchRoute = listRoute,
    ListComponent,
    CreateComponent,
    EditComponent,
    breadcrumbOptions = {},
    disablePermissions = false,
    formsProps,
  }: {
    object: string,
    editFormType?: string,
    createFormType?: string,
    listRoute: string,
    createRoute?: string,
    editRoute?: string,
    searchRoute?: string,
    ListComponent?: React.ComponentType<any>,
    CreateComponent?: React.ComponentType<any>,
    EditComponent?: React.ComponentType<any>,
    breadcrumbOptions?: Object,
    disablePermissions?: boolean,
    formsProps?: Object,
  }) => {
    const DetailsForm =
      EditComponent ||
      FormGenerator(object, {
        ...{ formType: editFormType },
        ...factoryOverrides,
      });
    const CreateForm =
      CreateComponent ||
      FormGenerator(object, {
        ...{ formType: createFormType },
        ...factoryOverrides,
      });
    // const List = ListComponent || ListGenerator(object);
    const List =
      ListComponent ||
      ListGenerator(object, {
        ...{
          gridOptions: { addRoute: createRoute, editRoute },
        },
        ...factoryOverrides,
      });
    const RecordBreadcrumbs = RecordBreadcrumbsGenerator(object, editFormType);

    type State = {
      buttons: ListTopBarButtons,
    };

    class DynamicCrud extends React.Component<{}, State> {
      state = {
        buttons: [],
      };

      constructor(props: {}) {
        super(props);
        this.listTopBar = React.createRef();
      }

      setButtons = (buttons: ListTopBarButtons) => {
        this.setState({
          buttons,
        });
      };

      addButtons = (newButtons: ListTopBarButtons) => {
        this.setState(state => {
          const buttons = [...state.buttons];
          newButtons.forEach(btn => {
            if (!find(buttons, { label: btn.label })) {
              // only add button if no other button with same label exists
              buttons.push(btn);
            }
          });
          return {
            buttons,
          };
        });
      };

      removeButtons = (buttonIds: Array<string>) => {
        this.setState(state => {
          const buttons = [...state.buttons];
          const newButtons = [];
          buttons.forEach(btn => {
            if (buttonIds.indexOf(btn.id) === -1) {
              newButtons.push(btn);
            }
          });
          return {
            ...state,
            buttons: newButtons,
          };
        });
      };

      listTopBar: any;

      render() {
        const { buttons } = this.state;
        return (
          <React.Fragment>
            <Route
              path={listRoute}
              render={props => (
                <div
                  className={`crud-elms crud-elms--list crud-elms--${object}--list`}
                >
                  <Breadcrumb isInternal {...breadcrumbOptions} />
                  <ListTopBar
                    showSearch={false} // @todo: this change is temporary and needs a revisit
                    ref={this.listTopBar}
                    buttons={buttons}
                    customClasses={undefined}
                    searchRoute={searchRoute}
                    isMainTopBar
                    showOn="bottom"
                  />
                  <ModuleContent>
                    <List
                      {...props}
                      setButtons={this.setButtons}
                      addButtons={this.addButtons}
                      removeButtons={this.removeButtons}
                      useGlobalSearch
                      disableRecordPermission={disablePermissions}
                    />
                  </ModuleContent>
                </div>
              )}
              exact
            />
            <Switch>
              <AuthorizedRoute
                Permission={disablePermissions ? null : `${object}:create`}
                path={createRoute}
                render={props => (
                  <div
                    className={`crud-elms crud-elms--create crud-elms--${object}--create`}
                  >
                    <Breadcrumb isInternal {...breadcrumbOptions} />
                    <ListTopBar
                      showSearch={false} // @todo: this change is temporary and needs a revisit
                      ref={this.listTopBar}
                      buttons={buttons}
                      customClasses={undefined}
                      searchRoute={searchRoute}
                      isMainTopBar
                    />
                    <ModuleContent>
                      <FormPropsFromRoute route={createRoute}>
                        {formPropsFromRoute => (
                          <CreateForm
                            {...props}
                            {...formsProps}
                            {...formPropsFromRoute}
                            setButtons={this.setButtons}
                            addButtons={this.addButtons}
                            removeButtons={this.removeButtons}
                            disableRecordPermission={disablePermissions}
                          />
                        )}
                      </FormPropsFromRoute>
                    </ModuleContent>
                  </div>
                )}
              />
              <AuthorizedRoute
                Permission={
                  disablePermissions
                    ? null
                    : [`${object}:edit`, `${object}:view`]
                }
                MatchAll={false}
                path={editRoute}
                render={props => (
                  <div
                    className={`crud-elms crud-elms--edit crud-elms--${object}--edit crud-elms--${object}-view`}
                  >
                    {breadcrumbOptions.disableRecordBreadcrumbs ? (
                      <Breadcrumb isInternal {...breadcrumbOptions} />
                    ) : (
                      <RecordBreadcrumbs
                        headerTheme={themeConfiguration.headerTheme}
                        isInternal
                        {...breadcrumbOptions}
                      />
                    )}
                    <ListTopBar
                      showSearch={false} // @todo: this change is temporary and needs a revisit
                      ref={this.listTopBar}
                      buttons={buttons}
                      customClasses={undefined}
                      searchRoute={searchRoute}
                      isMainTopBar
                    />
                    <ModuleContent>
                      <FormPropsFromRoute route={editRoute}>
                        {formPropsFromRoute => (
                          <DetailsForm
                            {...props}
                            {...formsProps}
                            {...formPropsFromRoute}
                            setButtons={this.setButtons}
                            addButtons={this.addButtons}
                            removeButtons={this.removeButtons}
                            disableRecordPermission={disablePermissions}
                          />
                        )}
                      </FormPropsFromRoute>
                    </ModuleContent>
                  </div>
                )}
              />
            </Switch>
          </React.Fragment>
        );
      }
    }
    if (!disablePermissions) {
      // Wrap in withPermissions to preload permission strings
      return makeAuthorizedComponent([`${object}:view`])(DynamicCrud);
    }
    return DynamicCrud;
  };
  return DynamicCrudGenerator;
};

export default DynamicCrudFactory;
