import { cloneDeep } from "lodash";
import { schemaTypes } from "./index";
// @TODO: need to keep reducers plain by moving util operations to reducers
import {
  updateSchemaElement,
  moveElement,
  addElement,
  replaceSchemaElement,
  getSchemaGlance,
  schemaCompare,
  removeSchema,
  extractAttributeIds,
} from "../../utils/schema";

// initial states
const initialState = {
  loading: false,
  schema: {},
  _uphold: {
    originalSchema: {},
    schemaTree: {},
  },
  _diff: {},
  initialSchema: {},
  newAttributes: [],
  newAttributesCount: 0,
  attributesInUse: [], // ids of attributes curently in use in schema
  relatedObjectsInUse: [],
  otherObjectsInUse: [],
  isDirty: false, // @todo: when changes happened and reverted back to original status, this doesn't state it as false
};

// reducer
// $FlowFixMe
export default (state = initialState, action) => {
  let schema;
  switch (action.type) {
    case schemaTypes.SCHEMA_LOADING:
      return {
        initialState,
        loading: true,
      };
    case schemaTypes.SCHEMA_READY: {
      const { attrIds, relObjIds, otherIds } = extractAttributeIds(
        action.payload
      );
      return {
        ...state,
        schema: action.payload,
        _uphold: {
          originalSchema: cloneDeep(action.payload),
          schemaTree: getSchemaGlance(cloneDeep(action.payload)),
        },
        attributesInUse: attrIds,
        relatedObjectsInUse: relObjIds,
        otherObjectsInUse: otherIds,
      };
    }
    case schemaTypes.SCHEMA_WALK:
      return {
        ...state,
        _diff: schemaCompare(
          action.payload.upholdData,
          action.payload.currentSchema
        ),
      };
    case schemaTypes.UPDATE_ELEMENT:
      return {
        ...state,
        schema: updateSchemaElement(
          { ...state.schema },
          action.payload.id,
          action.payload.schema
        ),
        isDirty: true,
      };
    case schemaTypes.REPLACE_ELEMENT: {
      schema = replaceSchemaElement(
        { ...state.schema },
        action.payload.id,
        action.payload.schema
      );
      const { attrIds, relObjIds, otherIds } = extractAttributeIds(schema);
      return {
        ...state,
        schema,
        attributesInUse: attrIds,
        relatedObjectsInUse: relObjIds,
        otherObjectsInUse: otherIds,
        isDirty: true,
      };
    }
    case schemaTypes.MOVE_ELEMENT: {
      return {
        ...state,
        schema: moveElement(
          { ...state.schema },
          action.payload.id,
          action.payload.destinationId,
          action.payload.destinationIndex
        ),
        isDirty: true,
      };
    }
    case schemaTypes.ADD_ELEMENT: {
      schema = addElement(
        { ...state.schema },
        action.payload.schema,
        action.payload.destinationId,
        action.payload.destinationIndex
      );
      const { attrIds, relObjIds, otherIds } = extractAttributeIds(schema);
      return {
        ...state,
        schema,
        attributesInUse: attrIds,
        relatedObjectsInUse: relObjIds,
        otherObjectsInUse: otherIds,
        isDirty: true,
      };
    }
    case schemaTypes.ADD_NEW_ATTRIBUTE:
      return {
        ...state,
        newAttributesCount: state.newAttributesCount + 1,
        newAttributes: [...state.newAttributes, action.payload],
        isDirty: true,
      };
    case schemaTypes.SCHEMA_DESTROY: {
      let removedSchemaElement = {};
      const newAttributes = [...state.newAttributes];
      let { newAttributesCount } = state;
      ({ schema, removedSchemaElement } = removeSchema(
        action.payload,
        state.schema
      ));
      if (
        (removedSchemaElement || {}).attributeId &&
        String(removedSchemaElement.attributeId).startsWith("new_")
      ) {
        const newAttributeStateIndex = newAttributes.findIndex(
          newAttribute =>
            newAttribute.attributeId === removedSchemaElement.attributeId
        );
        if (newAttributeStateIndex !== -1) {
          newAttributes.splice(newAttributeStateIndex, 1);
          newAttributesCount -= 1;
        }
      }
      const { attrIds, relObjIds, otherIds } = extractAttributeIds(schema);
      return {
        ...state,
        schema,
        attributesInUse: attrIds,
        relatedObjectsInUse: relObjIds,
        otherObjectsInUse: otherIds,
        newAttributes,
        newAttributesCount,
        isDirty: true,
      };
    }
    default:
      return state;
  }
};
