export default {
  CONDITIONAL_DROPPABLE_BORDER: "aliceblue",
  CONDITIONAL_DROPPABLE_BG: "#e7e7e8",
  CONDITIONAL_DROPPABLE_HOVERWITH: "rgba(85,150,230,.1)",
  DRAGHANDLER_COLOR: "#e7e7e8",
  DRAGHANDLER_COLOR_HOVER: "#6c757d",
  CURRENTLY_DRAGGING_BG: "rgb(239, 239, 239)",
  DRAGGABLE_FRAME: "#888",
  DRAGGABLE_FRAME_LIGHT: "#999",
  DRAGGABLE_FRAME_COLOR: "#E3E3E3",
};
