import { map, has, indexOf, assign, cloneDeep } from "lodash";
import { ArtTypes } from "../../../constants";
import { appTypes } from "./index";

// types
import type { pencilDefs as pencilDefsTypes } from "../../../types";

export const initPencils = (pencilDefs: pencilDefsTypes) => {
  const payload = {};
  map(pencilDefs, (_pencilDef, _pencilId) => {
    const {
      pencilDef: { group: pencilDefGroup },
    } = _pencilDef;
    Object.assign(_pencilDef, { id: _pencilId });
    if (
      indexOf(
        [
          "FIELD",
          "LAYOUT",
          "ATTRIB",
          "RELOBJ",
          "WORKFLOW",
          "VERSIONS",
          "DUPLICATIONS",
          "CHARTS",
        ],
        pencilDefGroup
      ) !== -1
    ) {
      if (!has(payload, pencilDefGroup))
        assign(payload, { [pencilDefGroup]: [] });

      payload[pencilDefGroup].push(_pencilDef);
    }
  });

  return {
    type: appTypes.APP_LOADING_PENCILS,
    payload: {
      bygroup: cloneDeep(payload),
      alldefs: cloneDeep(pencilDefs),
    },
  };
};

export const initArts = (artDefs: {}) => {
  const payload = {};
  map(artDefs, (artDef, artUuid) => {
    const { status } = artDef;
    if (
      indexOf(
        [ArtTypes.ART_STATUS_ACTIVE, ArtTypes.ART_STATUS_DRAFT],
        status
      ) !== -1
    ) {
      if (!has(payload, status)) assign(payload, { [status]: [] });
      assign(artDef, { uuid: artUuid });
      payload[status].push(artDef);
    }
  });

  return {
    type: appTypes.APP_LOADING_ARTS,
    payload,
  };
};

export const loadObjectFormId = (formId: number) => ({
  type: appTypes.APP_LOAD_OBJECT_FORM_ID,
  payload: {
    formId,
  },
});

export const loadAttributes = (attributes: Array<Object>) => ({
  type: appTypes.APP_LOAD_ATTRIBUTES,
  payload: {
    attributes,
  },
});

export const pencilGroupsToggle = (groupTags: Array<string>) => ({
  type: appTypes.APP_UI,
  payload: groupTags,
});

export const startDragging = (draggingOverWith: string) => ({
  type: appTypes.START_DRAGGING,
  payload: draggingOverWith,
});

export const stopDragging = () => ({
  type: appTypes.STOP_DRAGGING,
});

export const goToTab = (tabsId: string, tabIndex: number) => ({
  type: appTypes.GO_TO_TAB,
  tabsId,
  tabIndex,
});
