import React, { type AbstractComponent } from "react";
import AuthorizedComponent from "./AuthorizedComponent";

function makeAuthorizedComponent<T: {}>(
  Permission: string | Array<string>,
  MatchAll: boolean = true
) {
  return (Component: AbstractComponent<T | any>): AbstractComponent<any> => (
    props: any
  ) => (
    <AuthorizedComponent
      Permission={Permission}
      MatchAll={MatchAll}
      Component={Component}
      {...props}
    />
  );
}

export default makeAuthorizedComponent;
