import React from "react";
import styled from "styled-components";
import {
  StickyTopNavbar,
  FAB,
  Button,
  withLayout,
  type Layout,
} from "@swan/themer";
import { FaPlus, FaChevronLeft, FaTrash, FaSave } from "react-icons/fa";

// components
// import { Button } from "reactstrap";

import ButtonConfirm from "../ButtonConfirm";
import ButtonMultichoice from "../ButtonMultichoice";

import SearchBox, { type SearchAttributes } from "./SearchBox";

const StickyBar = styled(StickyTopNavbar)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background: rgb(248 250 253);
  padding: 10px;
  margin-bottom: 10px;
  flex-wrap: nowrap;

  & > .right {
    align-self: flex-end;
    white-space: nowrap;
  }
`;

const ButtonsContainer = styled.div`
  padding-right: 0.7rem;
`;

export type Buttons = Array<{
  id?: string,
  label: string,
  onClick?: Function,
  color?: string,
  disabled?: boolean,
  loading?: boolean,
  icon?: any,
  type?: "ButtonConfirm" | "AddButton",
  actionType?: "Back" | "Delete" | "Add" | "Save",
  confirmOptions?: Object,
}>;

type Props = {
  onSearchChange?: (search: string) => void,
  searchRoute?: string,
  searchAttributes?: SearchAttributes,
  showSearch?: boolean,
  customClasses?: Array<string>,
  buttons?: Buttons,
  isMainTopBar?: boolean,
  layout: Layout,
  showOn?: "bottom" | "top",
};
type State = {
  buttons: Buttons,
};

class ListTopBar extends React.Component<Props, State> {
  static defaultProps = {
    onSearchChange: undefined,
    showSearch: true,
    searchRoute: undefined,
    searchAttributes: undefined,
    buttons: undefined,
    customClasses: [],
    isMainTopBar: false,
    showOn: "top",
  };

  static getButtonIcon(button) {
    let Icon;
    switch (button.actionType) {
      case "Add":
        Icon = FaPlus;
        break;
      case "Back":
        Icon = FaChevronLeft;
        break;
      case "Delete":
        Icon = FaTrash;
        break;
      case "Save":
        Icon = FaSave;
        break;
      default:
        Icon = null;
        break;
    }
    return Icon;
  }

  static renderFAB(button) {
    // const isMobile = this.isMobile();
    const Icon = ListTopBar.getButtonIcon(button);
    return (
      <FAB
        key={`btn${button.label}`}
        color={button.color || "info"}
        onClick={button.onClick}
        disabled={button.disabled}
      >
        {Icon && <Icon />}
      </FAB>
    );
  }

  state = {
    buttons: [],
  };

  setButtons = (buttons: Buttons) => {
    this.setState({
      buttons,
    });
  };

  isMobile() {
    const { layout } = this.props;
    return layout.isMobile;
  }

  renderButton(button) {
    const isMobile = this.isMobile();

    if (isMobile && button.actionType === "Add") {
      return ListTopBar.renderFAB(button);
    }
    const Icon = ListTopBar.getButtonIcon(button);
    return (
      <Button
        key={`btn${button.label}`}
        color={button.color || "info"}
        onClick={button.onClick}
        disabled={button.disabled}
        loading={button.loading}
      >
        {isMobile && Icon ? <Icon /> : button.label}
      </Button>
    );
  }

  renderButtonMultichoice(button) {
    const isMobile = this.isMobile();
    const Icon = ListTopBar.getButtonIcon(button);
    return (
      <ButtonMultichoice
        key={`btn${button.label}`}
        color={button.color || "info"}
        disabled={button.disabled}
        loading={button.loading}
        choices={button.choices}
      >
        {isMobile && Icon ? <Icon /> : button.label}
      </ButtonMultichoice>
    );
  }

  renderButtonConfirm(button) {
    const isMobile = this.isMobile();
    const Icon = ListTopBar.getButtonIcon(button);
    return (
      <ButtonConfirm
        key={`btn${button.label}`}
        color={button.color || "info"}
        onClick={button.onClick}
        disabled={button.disabled}
        loading={button.loading}
        {...button.confirmOptions || {}}
      >
        {isMobile && Icon ? <Icon /> : button.label}
      </ButtonConfirm>
    );
  }

  render() {
    const { buttons } = this.state;
    const { buttons: propsButtons, customClasses, showOn } = this.props;
    const {
      showSearch,
      onSearchChange,
      searchRoute,
      searchAttributes,
      isMainTopBar,
    } = this.props;

    return (
      <StickyBar
        className={
          showOn === "top"
            ? customClasses
            : [...(customClasses || []), "bottom-action-container"]
        }
        isMainTopBar={isMainTopBar}
      >
        <div>
          {showSearch && (
            <SearchBox
              onSearchChange={onSearchChange}
              searchRoute={searchRoute}
              searchAttributes={searchAttributes}
            />
          )}
        </div>

        <ButtonsContainer className="right">
          {[...(propsButtons || []), ...buttons].map(button => {
            switch (button.type) {
              case "ButtonConfirm":
                return this.renderButtonConfirm(button);
              case "ButtonMultichoice":
                return this.renderButtonMultichoice(button);
              default:
                return this.renderButton(button);
            }
          })}
        </ButtonsContainer>
      </StickyBar>
    );
  }
}

export default withLayout(ListTopBar);
