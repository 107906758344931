import React, { Component } from "react";
import { FaRegWindowClose } from "react-icons/fa";

import { VersionsResourceFactory } from "@swan/api"; // eslint-disable-line
import { Modal } from "@swan/themer"; // eslint-disable-line

import type { Schema } from "../../../types";

import { RendererSubscriber } from "../../Main";

import { RelatedObjectWrapper, TitleRow } from "../partials/CRUD/theme";
import GridList from "../partials/CRUD/GridList";
// import TableRowForm from "../partials/CRUD/TableRowForm";
import Form from "../partials/CRUD/Form";
// import CardList from "../RelatedObject";

type Props = {
  schema: Schema,
  data: Object,
  customService: Object,
  onChange: (attribute: string, value: string | number | Object) => void,
  isNew: boolean,
  // id: string,
  // index: string | number,
};

type State = {
  listMode: string,
  editMode: string,
  isEditing: boolean,
  selectedRecord: any,
  modalOpen: boolean,
};

class Versions extends Component<Props, State> {
  mounted = false;

  constructor(props: Props) {
    super(props);
    this.state = {
      selectedRecord: undefined,
      isEditing: false,
      editMode: "inline",
      listMode: "grid",
      modalOpen: false,
    };
  }

  componentDidMount() {
    const {
      schema: { options: { listMode, editMode } = {} },
    } = this.props;
    this.mounted = true;
    if (this.mounted) {
      this.setState({
        editMode: editMode || "inline",
        listMode: listMode || "grid",
      });
    }
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  getService = () => {
    const { schema } = this.props;
    if (this.service) return this.service;
    const { options: { objectName } = {} } = schema;
    const VersionService = VersionsResourceFactory(objectName);
    this.service = new VersionService();
    return this.service;
  };

  rowClickHandler = (rowData: Object) => {
    const { editMode } = this.state;
    this.setState({
      isEditing: true,
      selectedRecord: rowData,
      modalOpen: editMode === "modal",
    });
  };

  modalToggler = () => {
    this.setState({
      isEditing: false,
      selectedRecord: undefined,
    });
  };

  closeHandler = () => {
    this.setState({
      isEditing: false,
      selectedRecord: undefined,
    });
  };

  handleSaveFunction = (saveFunction: Function) => {
    this.saveRecord = saveFunction;
  };

  handleDeleteFunction = (deleteFunction: Function) => {
    this.deleteRecord = deleteFunction;
  };

  shouldUseLocalData = () => {
    const { isNew: parentIsNew } = this.props;
    return parentIsNew;
  };

  saveRecord: Function;

  deleteRecord: Function;

  service: Object;

  render() {
    const { schema } = this.props;
    const { options: { defaultForm, objectName } = {} } = schema;
    const {
      isEditing,
      editMode,
      listMode,
      selectedRecord,
      modalOpen,
    } = this.state;
    const service = this.getService();

    // buttons
    const buttons: Array<Object> = [
      {
        label: "Close",
        icon: <FaRegWindowClose />,
        onClick: () => this.closeHandler(),
        color: "secondary",
      },
    ];

    return (
      <RelatedObjectWrapper>
        <RendererSubscriber>
          {({ ServiceFactory, data }) => {
            // Disable viewing old version on non active versions
            if (data.is_active_version === 0) {
              return null;
            }
            service.setParent(data.version_root_id || data.id);
            const FormComponent = (props: any) => (
              <Form
                listId={`${objectName}_list`}
                formMode="details"
                service={service}
                object={objectName}
                formId={defaultForm}
                type="details"
                ServiceFactory={ServiceFactory}
                id={
                  modalOpen
                    ? selectedRecord.id
                    : "" /* should be id of selected row */
                }
                noSave
                disableDelete
                registerSaveFunction={this.handleSaveFunction}
                registerDeleteFunction={this.handleDeleteFunction}
                goBack={this.modalToggler}
                {...props}
              />
            );
            return (
              <React.Fragment>
                <TitleRow>
                  {/* 0. Title */}
                  <h6 className="title">Versions List</h6>
                </TitleRow>
                {isEditing && (
                  <Modal
                    title="View Version"
                    isOpen={modalOpen}
                    toggle={this.modalToggler}
                    buttons={buttons}
                    size="xl"
                  >
                    <FormComponent />
                  </Modal>
                )}

                {/* 4. Grid Listing */}
                {listMode === "grid" && (
                  <GridList
                    service={service}
                    object={objectName}
                    type="details"
                    reloadRecord={false}
                    listId={`${objectName}_list`}
                    gridOptions={{
                      onRowClick: this.rowClickHandler,
                      disableAdd: true, // reset the buttons grid options
                    }}
                    selectedId={selectedRecord ? selectedRecord.id : undefined}
                    editMode={editMode}
                    useLocalData={this.shouldUseLocalData()}
                  />
                )}
              </React.Fragment>
            );
          }}
        </RendererSubscriber>
      </RelatedObjectWrapper>
    );
  }
}

export default Versions;
