import React from "react";

// export const NavContext = React.createContext<NavContext>({
//   registerNav: (navs: Array<Nav>, module: string) => {},
//   nav: {items: []}
// });
// $FlowFixMe
export default React.createContext({
  nav: { items: [] }
});
