import React from "react";
import { Table } from "@devexpress/dx-react-grid-bootstrap4";

import { type GridProps } from "./types";

const TableRow = ({
  row,
  gridProps,
  ...restProps
}: {
  row: Object,
  gridProps: GridProps,
}) => {
  const { options, TableRowComponent, onRowClick } = gridProps;
  let onclick = false;
  const opts = Object.assign({}, { ...(options || {}) });
  if (opts.onRowClick) {
    onclick = clickedRow => {
      if (opts.onRowClick) opts.onRowClick(clickedRow);
    };
  } else if (onRowClick) {
    onclick = clickedRow => {
      if (onRowClick) onRowClick(clickedRow);
    };
  }

  const DefaultRow = (
    <Table.Row
      {...restProps}
      onClick={() => (onclick ? onclick(row) : false)}
      style={
        onclick
          ? {
              cursor: "pointer",
            }
          : {}
      }
    />
  );

  if (TableRowComponent) {
    return (
      <TableRowComponent
        row={row}
        options={options}
        gridProps={gridProps}
        DefaultRow={DefaultRow}
        {...restProps}
      />
    );
  }

  return DefaultRow;
};

export default TableRow;
