// @flow
import React, { PureComponent } from "react";
import { Draggable } from "react-beautiful-dnd";

import styled, { withTheme } from "styled-components";

import IconHandler, { IconHandle as IconHandleStyled } from "./IconHandler";

// types
import type { PencilType, theme as themeType } from "../types";

const PencilWrapper = styled.div(({ theme, isDragging, isEven, isLastRow }) =>
  Object.assign(
    {},
    theme.PencilWrapper ? { ...theme.PencilWrapper } : {},
    isDragging && theme.PencilIsDragging ? { ...theme.PencilIsDragging } : {},
    isEven ? { ...theme.PencilIsEven } : { ...theme.PencilIsOdd },
    isLastRow ? { ...theme.PencilIsInLastRow } : {}
  )
);

const PencilWrapperCloned = styled(PencilWrapper)`
  + div {
    display: none !important;
  }
`;

const PencilTitlte = styled("div")`
  flex-grow: 1;

  &:hover {
    ~ ${IconHandleStyled} {
      transform: scale(1.3) translateZ(0);
    }
  }
`;

type Props = {
  pencil: PencilType,
  index: number,
  theme: themeType,
  isEven: boolean,
  isLastRow: boolean,
};

const Pencil = class Pencil extends PureComponent<Props> {
  render() {
    const {
      pencil: { id: penId, pencilDef },
      index,
      theme: { PencilClasses },
      isEven,
      isLastRow,
    } = this.props;
    const {
      title: pencilDefTitle,
      handleIcon: pencilIcon,
      group: pencilGroup,
    } = pencilDef;

    return (
      <Draggable draggableId={penId} {...{ index }}>
        {(draggableProvided, draggableSnapshot) => (
          <React.Fragment>
            <PencilWrapper
              ref={draggableProvided.innerRef}
              {...draggableProvided.draggableProps}
              {...draggableProvided.dragHandleProps}
              isDragging={draggableSnapshot.isDragging}
              className={PencilClasses.PencilWrapper || "pencil-wrapper"}
              isEven={isEven}
              isLastRow={isLastRow}
            >
              <PencilTitlte>{pencilDefTitle}</PencilTitlte>
              <IconHandler name={pencilIcon} />
            </PencilWrapper>
            {pencilGroup !== "ATTRIB" &&
              pencilGroup !== "RELOBJ" &&
              draggableSnapshot.isDragging && (
                <PencilWrapperCloned isEven={isEven} isLastRow={isLastRow}>
                  <PencilTitlte>{pencilDefTitle}</PencilTitlte>
                  <IconHandler name={pencilIcon} />
                </PencilWrapperCloned>
              )}
          </React.Fragment>
        )}
      </Draggable>
    );
  }
};

export default withTheme(Pencil);
