import IntlWrappedRender, {
  withIntlProvider as withIntlProviderHOC,
} from "./IntlWrapper";
import { wrapModule } from "./ModuleWrapper";

export const IntlWrapper = IntlWrappedRender;
export const withIntlProvider = withIntlProviderHOC;
export const connectModule = wrapModule;

export default {
  IntlWrapper,
  withIntlProvider,
  connectModule,
};
