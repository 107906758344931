// @flow
/* eslint-disable no-nested-ternary */
import React, { Component, type Node, type ElementRef } from "react";
import styled, { css } from "styled-components";
import { FaEllipsisH } from "react-icons/fa";

// prop types

// flowDirection possible options to implement
// "verticalRightIn", "verticalRightOut", "verticalLeftOut", "verticalLeftIn",
// "horizontalRightIn", "horizontalRightOut", "horizontalLeftOut", "horizontalLeftIn",

type Directions =
  | "vertical"
  | "horizontal"
  | "horizontalRight"
  | "horizontalLeft"
  | "verticalTop"
  | "verticalBottom"
  | string;

type Props = {
  key?: string,
  symbol: "PROCESS" | "CONNECTOR",
  flowDirection?: string,
  flowDirections?: Array<Directions>,
  children: Node,
  size?: number,
  spacing?:
    | number
    | {
        ["TOP" | "RIGHT" | "BOTTOM" | "LEFT"]: number,
      },
  lineSize?: number,
  lineColor?: string,
  connectorSize?: number,
  styles?: Object,
  theme?: "contrast" | "gray",
  justify?: "top" | "center" | "bottom",
  positionSubId?: string,
  positionSubscriber?: (
    key: string,
    { heightCalc: number, widthCalc: number }
  ) => void,
  clickHandler?: Function,
  actions?: any,
};

type State = {
  heightCalc: number,
  widthCalc: number,
};

const getNodeSymbolLineWidth = (direction, { lineSize /* justify */ }) => {
  switch (direction) {
    case "vertical":
    case "verticalTop":
    case "verticalBottom": {
      return `${lineSize}px`;
    }
    case "horizontal": {
      return `${100}%`;
    }
    case "horizontalLeft":
    case "horizontalRight": {
      return `${50}%`;
    }
    default: {
      return 0;
    }
  }
};

const getNodeSymbolLineHeight = (direction, { lineSize, justify, state }) => {
  switch (direction) {
    case "vertical": {
      return `${100}%`;
    }
    case "verticalTop":
    case "verticalBottom": {
      switch (justify) {
        case "top": {
          return `calc(100% - ${state.heightCalc}px)`;
        }
        default:
          return `${50}%`;
      }
    }
    case "horizontal":
    case "horizontalLeft":
    case "horizontalRight": {
      return `${lineSize}px`;
    }
    default: {
      return 0;
    }
  }
};

const getNodeSymbolBeforeAfterStyles = (props: Object) => {
  let lineStyles = "";
  const before = props.lines[Object.keys(props.lines)[0]];
  const after = props.lines[Object.keys(props.lines)[1]];

  if (before) {
    const beforeDirection = Object.keys(props.lines)[0];

    lineStyles += `
      ::before {
        content: "";
        position: absolute;
        width: ${getNodeSymbolLineWidth(beforeDirection, props)};
        height: ${getNodeSymbolLineHeight(beforeDirection, props)};
        z-index: -1;
        background: ${
          props.lineColor
            ? props.lineColor
            : props.theme === "contrast"
            ? "black"
            : "gray"
        }; 
      }
    `;

    Object.keys(before).forEach(lrtb => {
      lineStyles += `
        ::before {
          ${lrtb}: ${before[lrtb]}px;
        }
      `;
    });
  }

  // if (after && props.symbol !== "CONNECTOR") {
  if (after) {
    const afterDirection = Object.keys(props.lines)[1];

    lineStyles += `
      ::after {
        content: "";
        position: absolute;
        width: ${getNodeSymbolLineWidth(afterDirection, props)};
        height: ${getNodeSymbolLineHeight(afterDirection, props)};
        z-index: -1;
        background: ${
          props.lineColor
            ? props.lineColor
            : props.theme === "contrast"
            ? "black"
            : "gray"
        }; 
      }
    `;

    Object.keys(after).forEach(lrtb => {
      lineStyles += `
        ::after {
          ${lrtb}: ${after[lrtb]}px;
        }
      `;
    });
  }

  return css`
    ${lineStyles}
  `;
};

// styled components
const NodeSymbol = styled(
  styled.div(({ /* lines,  lineColor, lineSize, justify, */
    symbol /* connectorSize, flowDirection, theme, state */ }) =>
    Object.assign(
      {},
      {
        // flex: 1,
        // flexShrink: 0,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        width: "100%",
        // height: "100%"
      },
      symbol === "PROCESS"
        ? {
            width: "100%",
            // height: "100%"
          }
        : {}
    )
  )
)`
  ${(props: Object) => getNodeSymbolBeforeAfterStyles(props)}
`;

const Wrap = styled(
  styled.div(({ /* styles, */ size, spacing, justify }) =>
    Object.assign(
      {},
      {
        boxSizing: "border-box",
        display: "flex",
        justifyContent: `${
          justify === "center"
            ? "center"
            : justify === "bottom"
            ? "flex-end"
            : "flex-start"
        }`, // @todo adding other directional grow
        flexDirection: "column",
        alignItems: "center",
        position: "relative",
      },
      size ? { flexBasis: size } : {},
      spacing
        ? typeof spacing === "number"
          ? { padding: `${spacing}px` }
          : {
              paddingLeft: `${spacing.LEFT || 0}px`,
              paddingRight: `${spacing.RIGHT || 0}px`,
              paddingTop: `${spacing.TOP || 0}px`,
              paddingBottom: `${spacing.BOTTOM || 0}px`,
            }
        : {}
    )
  )
)``;

const NodeContent = styled(
  styled.div(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    background: "white",
    border: `1px solid ${theme === "contrast" ? "black" : "gray"}`,
    position: "relative",
    cursor: "pointer",
  }))
)`
  :hover {
    box-shadow: 0 1px 1px 1px gray;
    outline: 1px solid transparent;
  }
`;

const Icon = styled.div({
  display: "absolute",
  justifyContent: "center",
  alignItems: "center",
  position: "absolute",
  right: 0,
  top: 0,
  padding: "5px",
});

const Circle = styled(NodeContent)(({ connectorSize, styles, theme }) =>
  Object.assign(
    {},
    {
      width: `${connectorSize || 100}px`,
      height: `${connectorSize || 100}px`,
      borderRadius: `${connectorSize / 2 || 100 / 2}px`,
      background: `${theme === "contrast" ? "white" : "gray"}`,
      borderColor: `${theme === "gray" ? "gray" : "black"}`,
    },
    styles ? { ...styles } : {}
  )
);

const RectangleOverlay = styled.div({});

const Rectangle = styled(
  styled(NodeContent)(({ theme, styles }) =>
    Object.assign(
      {},
      {
        position: "relative",
        boxShadow: `0 1px 1px 0 ${theme === "contrast" ? "black" : "gray"}`,
        padding: "10px",
        borderRadius: "2px",
        width: "100%",
      },
      styles ? { ...styles } : {}
    )
  )
)`
  ${RectangleOverlay} {
    position: absolute;
    background: transparent;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
  }
`;

export default class DiagNode extends Component<Props, State> {
  mounted = false;

  static defaultProps = {
    key: undefined,
    flowDirection: undefined,
    flowDirections: undefined,
    size: 250,
    spacing: 0,
    lineSize: 2,
    lineColor: undefined,
    connectorSize: 100,
    styles: {},
    theme: "gray",
    justify: "top",
    positionSubscriber: undefined,
    positionSubId: undefined,
    clickHandler: undefined,
    actions: undefined,
  };

  constructor(props: Props) {
    super(props);
    this.nodeSymbol = React.createRef();
    this.state = {
      heightCalc: 0,
      widthCalc: 0,
    };
  }

  componentDidMount() {
    this.mounted = true;
    const { positionSubscriber, positionSubId } = this.props;

    // calculated height and width
    const heightCalc = this.nodeSymbol.current.offsetHeight;
    const widthCalc = this.nodeSymbol.current.offsetWidth;
    // update the satate
    this.setState({
      heightCalc,
      widthCalc,
    });
    // listen to positionSubscriber
    if (positionSubscriber && positionSubId)
      positionSubscriber(positionSubId, {
        heightCalc,
        widthCalc,
      });
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  getAbsolutePositions = () => {
    const { heightCalc, widthCalc } = this.state;
    const {
      lineSize,
      flowDirection,
      flowDirections,
      spacing,
      /* justify, */
    } = this.props;

    const spaceAll = typeof spacing === "number" ? spacing : 0;
    const spaceLeft = Number(spacing && spacing.LEFT ? spacing.LEFT : 0);
    // const spaceRight = Number(spacing && spacing.RIGHT ? spacing.RIGHT : 0);
    const spaceTop = Number(spacing && spacing.TOP ? spacing.TOP : 0);
    // const spaceBottom = Number(spacing && spacing.BOTTOM ? spacing.BOTTOM : 0);

    const positions = {};

    let directions = flowDirections || flowDirection;
    if (typeof directions === "string") directions = directions.split();

    if (Array.isArray(directions)) {
      if (directions.length > 2) directions = directions.splice(0, 2);

      directions.forEach(flowDir => {
        let top;
        let left;
        let right;
        let bottom;

        // vertical, verticalTop, verticalBottom
        if (
          ["vertical", "verticalTop", "verticalBottom"].indexOf(flowDir) !== -1
        ) {
          left = widthCalc / 2 - (lineSize || 2) / 2 + (spaceLeft || spaceAll);

          if (["vertical", "verticalTop"].indexOf(flowDir) !== -1) {
            top = 0;
          }

          if (flowDir === "verticalBottom") {
            bottom = 0;
          }
        }

        // horizontal, horizontalLeft, horizontalRight
        if (
          ["horizontal", "horizontalLeft", "horizontalRight"].indexOf(
            flowDir
          ) !== -1
        ) {
          top = heightCalc / 2 - (lineSize || 2) / 2 + (spaceTop || spaceAll);

          if (["horizontal", "horizontalLeft"].indexOf(flowDir) !== -1) {
            left = 0;
          }

          if (flowDir === "horizontalRight") {
            right = 0;
          }
        }

        positions[flowDir] = {};
        const linePositions = { left, right, top, bottom };
        Object.keys(linePositions).forEach(position => {
          // value is exempted for zero
          if (linePositions[position] || linePositions[position] === 0) {
            Object.assign(positions[flowDir], {
              [position]: linePositions[position],
            });
          }
        });
      });
    }

    return positions;
  };

  nodeSymbol: ElementRef<typeof NodeSymbol>;

  render() {
    const {
      children,
      size,
      lineSize,
      connectorSize,
      spacing,
      lineColor,
      symbol,
      theme,
      styles,
      justify,
      actions,
      clickHandler,
    } = this.props;
    // const { heightCalc, widthCalc } = this.state;

    const lines = this.getAbsolutePositions();

    return (
      <Wrap {...{ size, spacing, justify }} className="diagnode-wrap">
        <NodeSymbol
          ref={this.nodeSymbol}
          {...{
            lineColor,
            lineSize,
            symbol,
            theme,
            justify,
            lines,
          }}
          state={this.state}
          className="diagnode-symbol"
          role={clickHandler ? "button" : undefined}
          onClick={clickHandler}
        >
          {symbol === "PROCESS" && (
            <Rectangle
              theme={theme}
              styles={styles}
              className="diagnode-rectangle"
            >
              {actions && actions.length && (
                <Icon>
                  <FaEllipsisH />
                </Icon>
              )}
              {children}
              <RectangleOverlay />
            </Rectangle>
          )}
          {symbol === "CONNECTOR" && (
            <Circle
              theme={theme}
              connectorSize={
                !connectorSize
                  ? size || 100
                  : connectorSize < (size || 0)
                  ? connectorSize
                  : size
              }
              styles={styles}
              className="diagnode-circle"
            >
              {children}
            </Circle>
          )}
        </NodeSymbol>
      </Wrap>
    );
  }
}
