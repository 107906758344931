import * as React from "react";
import { connect } from "react-redux";

import { IntlProvider, addLocaleData } from "react-intl";
import en from "react-intl/locale-data/en";

import DirectionProvider, {
  DIRECTIONS
} from "react-with-direction/dist/DirectionProvider";

import enTranslation from "./lang/en";

import { userActions } from "./state/reducers/user";

addLocaleData([...en]);
const translations = {
  en: enTranslation
};

const RTL = ["ar"];

// $FlowFixMe
const { Provider, Consumer } = React.createContext();

type Props = {
  children: React.Node,
  setLocale: (locale: string) => void,
  locale: "en"
};

class IntlProviderWrapper extends React.Component<Props> {
  static defaultProps = {
    locale: "en"
  };
  providerValue: {
    switchLocale: (locale: string) => void
  };

  constructor(...args) {
    super(...args);

    this.providerValue = {
      switchLocale: this.switchLocale.bind(this)
    };
  }

  componentDidMount() {
    this.setHtmlDir();
  }

  componentDidUpdate(prevProps) {
    const { locale: prevLocale } = prevProps;
    const { locale: currentLocale } = this.props;
    if (prevLocale !== currentLocale) {
      this.setHtmlDir();
    }
  }

  setHtmlDir() {
    const { locale } = this.props;
    const dir = RTL.indexOf(locale) >= 0 ? DIRECTIONS.RTL : DIRECTIONS.LTR;
    const html = document.querySelector("html");
    if (html) {
      html.dir = dir;
    }
  }

  switchLocale(locale) {
    const { setLocale } = this.props;
    setLocale(locale);
  }

  render() {
    const { children, locale } = this.props;
    return (
      <Provider value={this.providerValue}>
        <IntlProvider
          key={locale}
          locale={locale || "en"}
          messages={translations[locale]}
          defaultLocale="en"
        >
          <DirectionProvider
            direction={
              RTL.indexOf(locale) >= 0 ? DIRECTIONS.RTL : DIRECTIONS.LTR
            }
          >
            {children}
          </DirectionProvider>
        </IntlProvider>
      </Provider>
    );
  }
}

const IntlProviderWrapperConnected = connect(
  state => ({
    locale: state.user.locale
  }),
  {
    setLocale: userActions.setLocale
  }
)(IntlProviderWrapper);

export {
  IntlProviderWrapperConnected as IntlProvider,
  Consumer as IntlConsumer
};
