import React from "react";
import { compose } from "redux";
import { pluralize } from "@swan/helpers/utils";
import DynamicList from "./DynamicList";

const ConnectedListFactory = (
  connect: Function,
  actions: { [string]: Function },
  selectors: { [string]: Function },
  ServiceFactory: (object: string, data: {}) => Object
) => {
  const ListGenerator = (object: string, options?: Object) => {
    let pluralObject = pluralize(object);
    // overrides
    if (options && options.objectPlural) {
      pluralObject = options.objectPlural;
    }

    const List = props => (
      <DynamicList
        object={object}
        type="details"
        gridOptions={{
          editRoute: `${pluralObject}/:id`,
          addRoute: `${pluralObject}/new`,
        }}
        ServiceFactory={ServiceFactory}
        {...props}
        {...options}
      />
    );

    function mapStateToProps(state) {
      return {
        ...selectors[`${pluralObject}Selectors`].getCrudListState(
          state[pluralObject]
        ),
      };
    }

    return compose(
      connect(
        mapStateToProps,
        {
          ...actions[`${pluralObject}Actions`].getCrudListActions(),
        }
      )
    )(List);
  };

  return ListGenerator;
};

export default ConnectedListFactory;
