import React from "react";
import { connect } from "react-redux";
// import WorkflowTimelineService from "../services/workflowTimeline";
import { store, persistor } from "../state/store";
import workflowActions from "../state/actions";
import { withProvider as withStoreProvider } from "../utils/storeProvider";

type Props = {
  object: string,
  record: Object,
  workflowId: number,
  overrideWorkflowId: string, // this is temporary until enable forms for different organizations.
  title?: string,
  loadTimeline: (object: string, id: number, workflowId: string) => void,
  subscribeWorkflowTimeline: (
    object: string,
    id: number,
    workflowId: string
  ) => void,
  isSubscribed: boolean,
  message: string,
  timeline: Array<Object>,
  errors: any,
};

type State = {};

class WorkflowTimeline extends React.PureComponent<Props, State> {
  static defaultProps = {
    title: undefined,
  };

  /**
   * Generates the timeline from the status of the workflow.
   * The timeline is an array of strings
   */
  componentDidMount() {
    this.load();
  }

  load = () => {
    const {
      object,
      record: { id },
      workflowId,
      loadTimeline,
      overrideWorkflowId,
      subscribeWorkflowTimeline,
      isSubscribed,
    } = this.props;
    if (id) {
      loadTimeline(object, id, overrideWorkflowId || workflowId.toString());
      if (!isSubscribed) {
        subscribeWorkflowTimeline(
          object,
          id,
          overrideWorkflowId || workflowId.toString()
        );
      }
    }
  };

  render() {
    const { title, timeline, message, errors } = this.props;
    return (
      <>
        {!!(title && timeline.length) && <p>{title}</p>}
        <ul>
          {timeline.map(s => {
            let userInputs = {};
            if (s.result && s.result.context && s.result.context.userInput) {
              userInputs = s.result.context.userInput;
            }
            if (s.response) {
              userInputs.response = s.response;
            }
            return (
              <li style={{ marginTop: "10px" }} key={s.label}>
                &bull; {s.label}
                {Object.keys(userInputs).map(userInputKey => (
                  <span key={userInputs[userInputKey]}>
                    <br />
                    {userInputKey}: {userInputs[userInputKey]}
                  </span>
                ))}
              </li>
            );
          })}
        </ul>
        <br />
        <br />
        <span style={{ color: "green", marginTop: "10px" }}>{message}</span>
        {errors && (
          <div style={{ color: "red" }}>
            {!Array.isArray(errors) ? (
              <span>{errors}</span>
            ) : (
              <ul>
                {errors.map(error => (
                  <li key={error}>{error}</li>
                ))}
              </ul>
            )}
          </div>
        )}
      </>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const {
    object,
    record: { id },
  } = ownProps;
  const {
    workflow: { workflowObjects },
  } = state;
  return {
    timeline:
      (workflowObjects &&
        workflowObjects[object] &&
        workflowObjects[object][id] &&
        workflowObjects[object][id].timeline) ||
      [],
    isSubscribed:
      (workflowObjects &&
        workflowObjects[object] &&
        workflowObjects[object][id] &&
        workflowObjects[object][id].isSubscribed) ||
      false,
    message:
      (workflowObjects &&
        workflowObjects[object] &&
        workflowObjects[object][id] &&
        workflowObjects[object][id].message) ||
      false,
  };
};

const mapDispatchToProps = dispatch => ({
  loadTimeline: (...defs) =>
    dispatch(workflowActions.loadWorkflowTimeline(...defs)),
  subscribeWorkflowTimeline: (...defs) =>
    dispatch(workflowActions.subscribeWorkflowTimeline(...defs)),
  unsubscribeWorkflowTimeline: (...defs) =>
    dispatch(workflowActions.unsubscribeWorkflowTimeline(...defs)),
});

export default withStoreProvider(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(WorkflowTimeline),
  {
    store,
    persistor,
  }
);
