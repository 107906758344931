class PersistedStorage<T> {
  STORAGE_KEY: string;

  constructor(key?: string) {
    if (key) {
      this.STORAGE_KEY = key;
    }
  }

  /**
   * Load items from local storage
   */
  loadItems(): ?T {
    // TODO Check how to handle invalidation and re-enable caching
    if (this.STORAGE_KEY) {
      return null;
    }
    return null;
    // const perms = localStorage.getItem(this.STORAGE_KEY);
    // if (perms) {
    //   return JSON.parse(perms);
    // }
    // return null;
  }

  /**
   * Update local storage
   */
  storeItems(items: T): void {
    localStorage.setItem(this.STORAGE_KEY, JSON.stringify(items));
  }

  /**
   * Destroy
   */
  destroy() {
    localStorage.removeItem(this.STORAGE_KEY);
  }
}

export default PersistedStorage;
