import React, { Fragment } from "react";
import SingleSelectDictionary from "./SingleSelectDictionary";

type Props = {
  value: string,
};

export const SequenceFormatter = ({ value }: Props) =>
  value ? (
    <Fragment>
      <span
        style={{
          color: "#FFF",
          background: "rgb(99, 153, 224)",
          padding: "3px",
          fontWeight: "bold",
          borderRadius: "2px",
        }}
      >
        {(value || "").match(/[a-zA-z]+/g)}
      </span>
      <span
        style={{
          color: "#3d70b2",
          background: "#eaeaea",

          padding: "3px",
          fontWeight: "bold",
          borderRadius: "2px",
        }}
      >
        {(value || "").match(/\d+/g)}
      </span>
    </Fragment>
  ) : (
    ""
  );

export const NumberFormatter = ({ value }: Props) =>
  value ? (
    <Fragment>
      <span style={{ display: "flex", justifyContent: "center" }}>
        <span
          style={{
            // background: "#CCC",
            // padding: "8px",
            // borderRadius: "50%",
            background: "rgb(217 217 217)",
            padding: "2px",
            borderRadius: "4px",
          }}
        >
          {value}
        </span>
      </span>
    </Fragment>
  ) : (
    ""
  );

export const DateTimeFormatter = ({ value }: Props) =>
  value ? (
    <span
      style={{
        background: "rgb(218, 218, 218)",
        color: "#000",
        fontSize: "12px",
        padding: "5px",
        borderRadius: "2px",
      }}
    >
      {value}
    </span>
  ) : (
    ""
  );

export const SingleSelectFormatter = ({ value }: Props) => {
  let result = value;
  if (value && typeof value !== "string") {
    result = String(value);
  }

  return result ? (
    <span
      style={{
        padding: "5px",
        borderRadius: "2px",
        ...(SingleSelectDictionary[(result || "").toLowerCase()] || {}),
      }}
    >
      {result}
    </span>
  ) : (
    ""
  );
};

const formatDecimal = (num: string) =>
  parseFloat(num)
    .toFixed(2)
    .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");

export const DecimalFieldFormatter = ({ value }: Props) => {
  const formattedDecimal = formatDecimal(value);
  return value ? (
    <span style={{ float: "right", height: "10px" }}>
      {Number.isNaN(formattedDecimal) || formattedDecimal === "NaN"
        ? ""
        : formattedDecimal.replace(/\.00/g, "")}
    </span>
  ) : (
    ""
  );
};
export const getMappedColumnsByType = (columns: any) => {
  const columnsTypeMapped = {
    datetime: [],
    sequence: [],
    singleSelect: [],
    decimalField: [],
    datetimeField: [],
    dateField: [],
    dateTime: [],
    numberField: [],
    lookup: [],
  };

  (columns || []).map(column => {
    if (!columnsTypeMapped[column.type]) {
      columnsTypeMapped[column.type] = [];
    }
    columnsTypeMapped[column.type].push(column.name);
    return columnsTypeMapped;
  });
  return columnsTypeMapped;
};

export default getMappedColumnsByType;
