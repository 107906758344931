// @flow
import memoizeOne from "memoize-one";
import { indexOf, join, concat, remove, map, clone, assign, has } from "lodash";

// Utils
import objectWalker from "./objectWalker";

// types
import { type Schema } from "../types";

// momoize object walker
const schemaWalker = memoizeOne(objectWalker);

// Get schema in Glance
export default (schema: Schema) => {
  const tree = {
    layouts: {},
    fields: {},
  };

  // TODO: This needs to be aggregated from definitions
  const layoutKeys = [
    "row",
    "col",
    "grid",
    "tabs",
    "tab",
    "CONTAINER",
    "container",
    "containerWithTitle",
    "rowContainer",
  ];

  // var t0 = performance.now();
  schemaWalker(
    schema,
    (value, key, path, depth, parent, parentKey, parentPath) => {
      if (key === "type") {
        // TODO: need no-inclue layouts
        const treeToggled =
          indexOf(layoutKeys, value) !== -1 ? "layouts" : "fields";
        const id = join(
          concat(
            ["@"],
            remove(map(parentPath, clone), ppath => ppath !== "properties")
          ),
          "/"
        );
        if (!has(tree[treeToggled], id))
          assign(tree[treeToggled], { [id]: parent });
      }
    }
  );
  // var t1 = performance.now();
  // console.log("schemaWalker took me" + (t1 - t0) + " milliseconds.");

  return tree;
};
