import React, { useState } from "react";
import { TextArea, Wysiwyg } from "@swan/themer";

import Field from "./partials/Field";
import type { ElementProps } from "./types";

const LongText = ({
  schema,
  onChange,
  error,
  data,
  readOnly,
  hideLabel,
}: ElementProps) => {
  const { isJson, disableWysiwyg } = schema.options || {};
  const [stateError, setStateError] = useState(undefined);

  const stringify = text => {
    if (typeof text === "object") {
      return JSON.stringify(text);
    }
    return text;
  };

  const handleChange = e => {
    const text = e.target.value;
    setStateError(undefined);
    if (typeof onChange === "function") {
      if (isJson) {
        try {
          onChange(schema.attributeName || "", JSON.parse(text));
        } catch (exception) {
          setStateError("Not valid JSON");
          onChange(schema.attributeName || "", text);
        }
      } else {
        onChange(schema.attributeName || "", text);
      }
    }
  };

  if (isJson) {
    return (
      <TextArea
        {...schema.options || {}}
        name={schema.attributeName || ""}
        value={stringify((data || {})[schema.attributeName || ""])}
        label={schema.label}
        hideLabel={hideLabel || (schema.options || {}).hideLabel}
        onChange={handleChange}
        error={stateError || (error || {})[schema.attributeName || ""]}
        static={readOnly || (schema.options || {}).readOnly}
      />
    );
  }

  if (disableWysiwyg) {
    return (
      <TextArea
        {...schema.options || {}}
        name={schema.attributeName || ""}
        value={(data || {})[schema.attributeName || ""]}
        label={schema.label}
        hideLabel={hideLabel || (schema.options || {}).hideLabel}
        onChange={e =>
          onChange && onChange(schema.attributeName || "", e.target.value)
        }
        error={(error || {})[schema.attributeName || ""]}
        static={readOnly || (schema.options || {}).readOnly}
      />
    );
  }

  return (
    <Wysiwyg
      {...schema.options || {}}
      type="longText"
      name={schema.attributeName || ""}
      value={(data || {})[schema.attributeName || ""]}
      label={schema.label}
      hideLabel={hideLabel || (schema.options || {}).hideLabel}
      onChange={e =>
        onChange && onChange(schema.attributeName || "", e.target.value)
      }
      error={(error || {})[schema.attributeName || ""]}
      static={readOnly || (schema.options || {}).readOnly}
      allowCopyText
    />
  );
};

export default Field(LongText);
