import React, { type ComponentType } from "react";
import {
  CSidebarBrand,
  CSidebar,
  CSidebarNav,
  // CSidebarHeader,
  // CSidebarFooter,
  CSidebarToggler,
} from "@coreui/react";
import Styled from "styled-components";

// components
import NavItem from "./components/NavItem";

// context
import { LayoutSubscriber } from "./context/layout";

// config
import { GLOBAL_NAV_WIDTH } from "./config";

// types
type Props = {
  Components: { [string]: ComponentType<any> },
};

const Sidebar = Styled(CSidebar)`
left: ${GLOBAL_NAV_WIDTH}px !important;
`;

const SidebarNav = Styled(CSidebarNav)`
  .sidebar-nav-item--name {
    padding-left: .5rem;
    text-overflow: ellipsis;
    overflow: hidden;
    &.without-icon {
      // margin-left: 20px;
    }
  }

  .sidebar-nav-item--title {
    color: #cdced0;

    &.has-parent {
      margin: 0 1rem;
    }
  }

  .sidebar-nav-item--link {
    font-size: 0.8rem;
    color: #fff !important;
    margin: 0 1rem;
    border-radius: 0.4rem;
    cursor: pointer;

    .sidebar-nav-item--icon {
      font-size: 0.9rem;
      svg {
        color: #fff !important;
      }
    }
  }

  .sidebar-nav-item {
    &.has-parent {
      .sidebar-nav-item--link {
        margin-left: 2rem;
        padding: 0.4rem 0 0.4rem 1rem;
      }
      .sidebar-nav-item--icon {
        display: none;
      }
    }
  }

  .nav-group-toggle {
    color: #ffffff !important;
    padding: 0.75rem 1rem;
    margin-top: 1rem;
    font-size: 80%;
    font-weight: 700;
    text-transform: uppercase;
    transition: height 0.15s, margin 0.15s;
  }
`;

const SidebarToggler = Styled(CSidebarToggler)`
  &.stuck {
    &::before {
      transform: rotate(-180deg);
    }
  }
`;

const BrandSeperator = Styled.div`
  border-radius: 1px;
  flex-shrink: 0;
  height: 2px;
  margin: 0.5rem 0;
  background-color: #b4c7e785;
`;

export default (props: Props) => {
  const {
    Components: { SidebarHeader, Navigation },
  } = props;

  return (
    <LayoutSubscriber>
      {({
        isProductNavigationOpen,
        isProductNavigationStick,
        stickProductNavigation,
      }) => (
        <Sidebar
          colorScheme="light"
          unfoldable={false}
          visible={isProductNavigationOpen || isProductNavigationStick}
          overlaid
          narrow={!isProductNavigationOpen}
          position="fixed"
          placement="end"
        >
          <CSidebarBrand className="swan-logo">
            <SidebarHeader
              {...{
                isProductNavigationOpen,
                isProductNavigationStick,
              }}
            />
          </CSidebarBrand>
          <BrandSeperator />

          <SidebarNav isProductNavigationOpen={isProductNavigationOpen}>
            <Navigation itemRenderer={NavItem} />
          </SidebarNav>

          <SidebarToggler
            className={`swan-sidebar-openToggler swan-sidebar-stick-btn ${
              isProductNavigationStick ? "stuck" : "not-stuck"
            }`}
            onClick={stickProductNavigation}
          />
        </Sidebar>
      )}
    </LayoutSubscriber>
  );
};
