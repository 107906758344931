export const MODULE_NAME = "CRM"; // Module display name
export const MODULE_SHORT_DISPLAY_NAME =
  "Customer Relationship Management (CRM)";
export const MODULE_DISPLAY_NAME = "Customer Relationship Management";
export const MODULE_URN = "crm"; // module url prefix
export const MODULE_UNIQUE = "crm"; // module unique identifier within swan system and this should be unique throught the system

export default {
  MODULE_NAME,
  MODULE_DISPLAY_NAME,
  MODULE_SHORT_DISPLAY_NAME,
  MODULE_URN,
  MODULE_UNIQUE,
};
