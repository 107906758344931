import * as React from "react";
import {
  Input,
  // Button,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import styled from "styled-components";
import { FaEllipsisV } from "react-icons/fa";
import { connect } from "react-redux";

import { Modal, Input as ThemerInput, Checkbox, TextArea } from "@swan/themer";

// state
import { schemaActions } from "../../../state/reducers/schema";

type Props = {
  title: string,
  id: string,
  onNameChange: (tabId: string, title: string) => void,
  onTabSettingsChange: (tabId: string, options: Object) => void,
  schemaPathID: string,
  removeSchemaNode: string => void,
  showEditButton: boolean,
  direction: string,
  tabSettings: Object,
};

type State = {
  editing: boolean,
  editedTitle: string,
  dropDownOpen: boolean,
  isModalEditOpen: boolean,
};

export const TabTitle = styled.div((props: { direction: string }) => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: props.direction === "vertical" ? "flex-start" : "center",

  ".tab-title, .dropdown, .addplus": {
    height: "1.6rem",
    display: "flex",
    alignSelf: "center",
    lineHeight: "1.6rem",
  },

  ".dropdown": {
    width: "1rem",
    marginLeft: "0.4rem",
  },
}));

export const InputStyled = styled(Input)`
  height: 1.6rem;
  border-radius: 0;
  box-sizing: border-box;
  border: none;
  font-size: 0.8rem;
  border: 1px solid #f4f7fb;
  color: #767676;
  &:hover,
  &:focus,
  &:active {
    box-shadow: none;
    border: none;
  }
  &:active,
  &:focus {
    border: 1px dashed #767676;
  }
`;

const DropDownToggleStyled = styled(DropdownToggle)`
  &:hover {
    color: #6b87ab;
  }
`;

class TabName extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      editing: false,
      editedTitle: "",
      dropDownOpen: false,
      isModalEditOpen: false,
    };
  }

  onChange = (editedTitle: string) => this.setState({ editedTitle });

  onDone() {
    const { onNameChange, id } = this.props;
    const { editedTitle } = this.state;
    onNameChange(id, editedTitle);
    this.setState({
      editing: false,
    });
  }

  toggleDropDown = (): void => {
    this.setState(prevState => ({ dropDownOpen: !prevState.dropDownOpen }));
  };

  toggleEdit = () => {
    const { title } = this.props;
    this.setState({
      editing: true,
      editedTitle: title,
    });
  };

  toggleModal = (setOn?: boolean /* tabId?: string */) => {
    this.setState(prevState => ({
      // tabId,
      isModalEditOpen:
        setOn !== undefined && typeof setOn === "boolean"
          ? setOn
          : !prevState.isModalEditOpen,
    }));
  };

  removeTabSchema = () => {
    const { schemaPathID, removeSchemaNode } = this.props;
    removeSchemaNode(schemaPathID);
  };

  render() {
    const {
      id,
      title,
      showEditButton,
      direction,
      // schemaPathID,
      tabSettings,
      onTabSettingsChange,
    } = this.props;
    const { editing, editedTitle, dropDownOpen, isModalEditOpen } = this.state;
    return (
      <>
        <TabTitle direction={direction}>
          {editing && (
            <InputStyled
              type="text"
              value={editedTitle}
              onChange={e => this.onChange(e.target.value)}
              onBlur={() => this.onDone()}
              autoFocus
            />
          )}
          {!editing && <div className="tab-title">{title}</div>}

          {(showEditButton || editing || dropDownOpen) && (
            <Dropdown isOpen={dropDownOpen} toggle={this.toggleDropDown}>
              <DropDownToggleStyled tag="span">
                <FaEllipsisV size="1em" />
              </DropDownToggleStyled>
              <DropdownMenu right>
                <DropdownItem onClick={() => this.toggleEdit()}>
                  Edit tab title
                </DropdownItem>
                <DropdownItem divider />
                <DropdownItem onClick={() => this.toggleModal()}>
                  Tab Settings
                </DropdownItem>
                <DropdownItem onClick={this.removeTabSchema}>
                  Delete this
                </DropdownItem>
              </DropdownMenu>
            </Dropdown>
          )}
        </TabTitle>

        <Modal
          toggle={this.toggleModal}
          isOpen={isModalEditOpen}
          enableBackdrop
        >
          <ThemerInput
            name={`{title} display icon`}
            type="text"
            label="Icon"
            placeholder="Supported icons are: font-awesome, "
            onChange={e => {
              if (onTabSettingsChange) {
                onTabSettingsChange(id, {
                  ...tabSettings,
                  ...{
                    iconName: e.target.value,
                  },
                });
              }
            }}
            value={(tabSettings || {}).iconName}
          />
          <Checkbox
            name="hideOnCreate"
            value={(tabSettings || {}).hideOnCreate}
            label="Hide on Create"
            onChange={() => {
              if (onTabSettingsChange) {
                onTabSettingsChange(id, {
                  ...tabSettings,
                  ...{
                    hideOnCreate:
                      (tabSettings || {}).hideOnCreate !== "1" ? "1" : "0",
                  },
                });
              }
            }}
          />
          <Checkbox
            name="hideOnDetail"
            value={(tabSettings || {}).hideOnDetail}
            label="Hide on Detail"
            onChange={() => {
              if (onTabSettingsChange) {
                onTabSettingsChange(id, {
                  ...tabSettings,
                  ...{
                    hideOnDetail:
                      (tabSettings || {}).hideOnDetail !== "1" ? "1" : "0",
                  },
                });
              }
            }}
          />
          <TextArea
            name="hideOnCondition"
            label="Hide On Condition"
            value={(tabSettings || {}).hideOnCondition}
            onChange={e => {
              if (onTabSettingsChange) {
                onTabSettingsChange(id, {
                  ...tabSettings,
                  ...{
                    hideOnCondition: e.target.value,
                  },
                });
              }
            }}
          />
        </Modal>
      </>
    );
  }
}

// export default TabName;

export default connect(
  null,
  {
    removeSchemaNode: schemaActions.removeSchemaNode,
  }
)(TabName);
