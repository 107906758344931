import Service from "./service";

export type Filter = Array<{
  columnName: string,
  value: string,
}>;

export type Sorting = Array<{
  columnName: string,
  direction: string,
}>;

class Resource extends Service {
  defaultFilter = [];

  setDefaultFilters(filter: Filter) {
    this.defaultFilter = filter;
  }

  clearDefaultFilters() {
    this.defaultFilter = [];
  }

  /**
   * CRUD
   */
  find(
    page: number,
    filter: Filter,
    pageSize: number,
    sorting: Sorting,
    extra: Object
  ) {
    return this.get("", {
      page,
      pageSize,
      sorting,
      filter: [...(filter || []), ...this.defaultFilter],
      ...extra,
    });
  }

  /**
   * Create
   */
  create(data: {}) {
    return this.post("", { ...data });
  }

  /**
   * Update
   */
  update(id: number, data: {}) {
    return this.put(`${id}`, { ...data });
  }

  /**
   * Delete
   */
  delete(id: number) {
    return this.deleteRequest(`${id}`);
  }
}

export default Resource;
