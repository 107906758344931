import React, { Component } from "react";
import styled from "styled-components";
import type { FieldProps } from "../Form/partials/FieldWrapper";

export type InputProps = FieldProps & {
  colors?: Array<string>,
};

type State = {
  colors: Array<string>,
  selectedColor: string | number,
};

const Color = styled.span`
  display: block;
  background-color: ${(props: any) => props.color};
  width: 15px;
  height: 15px;
  border: ${(props: any) => (props.selected ? "3px solid #a0a0a0" : "0px")};
  margin-left: 5px;
  margin-right: 5px;
  margin-bottom: 10px;
`;

class ColorPicker extends Component<InputProps, State> {
  static defaultProps = {
    type: "color",
    colors: [],
  };

  DEFAULT_COLORS = [
    "#000000",
    "#3d70b2",
    "#dc3545",
    "#28a745",
    "#17a2b8",
    "#ffffff",
    "#8d8d8d",
    "#dbadff",
    "#fbb878",
    "#fbd75a",
    "#AFCA0E",
    "#7ae7bf",
    "#46d6db",
    "#5484ed",
  ];

  constructor(props: any) {
    super(props);
    this.state = { selectedColor: "#FFFFFF", colors: this.DEFAULT_COLORS };
  }

  componentDidMount() {
    const { value, colors } = this.props;
    this.setState({
      colors: [...(colors || []), ...this.DEFAULT_COLORS],
      selectedColor: value,
    });
  }

  handleClick(color: any) {
    const { onChange, static: staticField } = this.props;
    if (!staticField) {
      this.setState({ selectedColor: color });
      if (onChange) {
        onChange({ target: { value: color } });
      }
    }
  }

  render() {
    const { colors, selectedColor } = this.state;

    return (
      <div className="form-control control-inside d-flex flex-wrap align-items-center h-auto pt-2">
        {(colors || []).map(color => (
          <Color
            color={color}
            selected={selectedColor === color}
            key={color}
            role="button"
            onKeyDown={() => {
              this.handleClick(color);
            }}
            onClick={() => {
              this.handleClick(color);
            }}
          />
        ))}
      </div>
    );
  }
}

export default ColorPicker;
