import * as React from "react";
import {
  FormGroup,
  Label,
  FormText,
  // Input,
  InputGroup,
  InputGroupText,
} from "reactstrap";
import moment from "moment";
import { FaRegCheckCircle, FaRegTimesCircle } from "react-icons/fa";
import CopyText from "../../CopyText";
import Tooltip from "../../Tooltip";

export type FieldProps = {
  label?: string,
  name: string,
  id?: string,
  static?: boolean,
  displayValue?: string,
  value?: string | number | Object,
  type?: string,
  error?: string,
  onChange?: (event: Object) => void,
  onBlur?: (event: Object) => void,
  required?: boolean,
  hideLabel?: boolean,
  className?: string,
  isJson?: boolean,
  children?: Node,
  style?: Object,
  prefix?: string | Node | React.Element<any>,
  suffix?: string | Node | React.Element<any>,
  help?: string,
  allowCopyText?: boolean,
  description?: string,
  min?: number,
  max?: number,
};

const FieldWrapper = (Component: any) => {
  class WrappedField extends React.Component<
    FieldProps & { dateFormat?: string }
  > {
    static defaultProps = {
      static: false,
      label: undefined,
      required: undefined,
      hideLabel: false,
      error: "",
      type: "text",
      className: undefined,
      style: undefined,
      displayValue: undefined,
      value: undefined,
      onChange: () => {},
      onBlur: () => {},
      children: undefined,
      id: undefined,
      prefix: undefined,
      suffix: undefined,
      help: undefined,
      description: undefined,
      allowCopyText: false,
      isJson: false,
    };

    readOnly() {
      const {
        label,
        name,
        displayValue,
        value,
        type,
        required,
        hideLabel,
        className,
        allowCopyText,
        isJson,
      } = this.props;

      /* have no idea whether to remove it or keept it */
      // let inputType = "text";
      // if (type && type !== "remoteSelect") {
      //   inputType = type;
      // }
      let readOnlyValue;

      readOnlyValue = displayValue || value || "";
      if (isJson) {
        readOnlyValue = JSON.stringify(readOnlyValue, null, 2);
      }
      if (type === "checkbox") {
        readOnlyValue = (
          <>
            {String(readOnlyValue) === "1" ? (
              <FaRegCheckCircle color="#5aa700" />
            ) : (
              <FaRegTimesCircle color="#e0182d" />
            )}

            <span className="checkbox-label-readonly">{label}</span>
          </>
        );
      }

      if (type === "datetime" || type === "date") {
        const { dateFormat } = this.props;
        if (!readOnlyValue) {
          readOnlyValue = new Date();
        }
        if (readOnlyValue) {
          const format =
            dateFormat ||
            (type === "datetime" && "DD/MM/YYYY HH:mm:ss") ||
            (type === "date" && "DD/MM/YYYY") ||
            undefined;
          readOnlyValue = moment(readOnlyValue).format(format);
        }
      }

      return (
        <FormGroup
          className={[
            "form-group-alike",
            ...(className ? [className] : []),
          ].join(" ")}
        >
          <div
            className={[
              "form-control",
              "form-control-readonly",
              ...(type === "textarea" ? ["wysiwyg wysiwyg-readonly"] : []),
            ].join(" ")}
            title={readOnlyValue}
            {...(required ? { "data-required": true } : {})}
          >
            {/* {readOnlyValue} */}

            {allowCopyText ? (
              <CopyText>{readOnlyValue}</CopyText>
            ) : (
              readOnlyValue
            )}
          </div>
          {!hideLabel && label && (
            <Label htmlFor={name} className="label-readonly">
              {label}
            </Label>
          )}
        </FormGroup>
      );
    }

    render() {
      const {
        label,
        name,
        static: staticField,
        error,
        prefix,
        suffix,
        type,
        hideLabel,
        help,
        className,
      } = this.props;

      if (staticField) {
        return this.readOnly();
      }

      return (
        <FormGroup
          className={`${className || ""} form-group-of--${type || ""}`}
        >
          {!!prefix || !!suffix ? (
            <InputGroup className="form-control control-inside">
              {!!prefix && (
                <div className="input-group-prepend">
                  <InputGroupText>{prefix}</InputGroupText>
                </div>
              )}
              <Component {...this.props} />
              {!!suffix && (
                <div className="input-group-append">
                  <InputGroupText>{suffix}</InputGroupText>
                </div>
              )}
            </InputGroup>
          ) : (
            <Component {...this.props} />
          )}
          {!hideLabel && label && (
            <Label htmlFor={name}>
              {label}{" "}
              {help ? <Tooltip id={`tooltip_${name}`}>{help}</Tooltip> : null}
            </Label>
          )}{" "}
          {error && <FormText>{error}</FormText>}
        </FormGroup>
      );
    }
  }

  return WrappedField;
};

export default FieldWrapper;
