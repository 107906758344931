// @flow
import processResource from "./processResource";
import { type ResourceAction } from "../actions/flowTypes";

export default (
  action: ResourceAction
): { resourceString: ?string, resourceData: ?string } => ({
  resourceString:
    (processResource((action.data || {}).resource || {}) || {}).inputValue ||
    undefined,
  resourceData:
    (action.data || {}).layoutPath || (action.data || {}).setValue || undefined,
});
