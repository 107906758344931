import FormRenderer from "./FormRenderer";

/**
 * FormRenderer API
 *
 * props.theme
 * props.schema
 * props.data
 */

export default FormRenderer;

export {
  default as CustomComponentWrapper,
} from "./components/Elements/partials/CustomComponentWrapper";
export {
  default as taggbleSelectProvider,
} from "./components/Elements/partials/Field/taggableM2MSelectProvider";

export type { Schema } from "./types";
