import * as React from "react";
import { SwanModuleContext } from "@swan/state";
import {
  default as ModuleDefinition,
  getProductNavDefinition,
} from "./module_def";

// $FlowFixMe
export const CurrentModuleContext = React.createContext({});

// enslint-disable-next-line
type Props = {
  registerNav: ({}, Array<{}>, string, string) => void,
  registerProductNav: Function,
  registerRoutes: (Array<{}>, string) => void,
  i18n: Object,
  history: Object,
  user: Object,
  onModuleReady: () => void,
};

class Module extends React.Component<Props> {
  componentDidMount() {
    const {
      registerNav,
      registerProductNav,
      registerRoutes,
      history,
      i18n,
      onModuleReady,
      user,
    } = this.props;
    // TODO find a better way to achieve this
    registerNav(
      ModuleDefinition.mainNav,
      ModuleDefinition.productNav,
      ModuleDefinition.name,
      ModuleDefinition.displayName
    );
    const routes = ModuleDefinition.routes;
    const enhancedRoutes = routes.map(route => ({
      ...route,
      component: undefined,
      render: props => (
        <CurrentModuleContext.Provider value={{ history, i18n }}>
          <route.component {...props} />
        </CurrentModuleContext.Provider>
      ),
    }));
    registerRoutes(enhancedRoutes, ModuleDefinition.name);
    onModuleReady();
    if (user !== null) {
      getProductNavDefinition().then(AsyncModuleDefinition => {
        registerProductNav(ModuleDefinition.name, AsyncModuleDefinition);
      });
    }
  }

  render() {
    return null;
  }
}

export const CurrentModule = ({
  onModuleReady,
}: {
  onModuleReady: () => void,
}) => (
  <SwanModuleContext.Consumer>
    {({
      registerRoutes,
      registerNav,
      registerProductNav,
      history,
      i18n,
      user,
    }) => (
      <Module
        registerRoutes={registerRoutes}
        registerNav={registerNav}
        i18n={i18n}
        registerProductNav={registerProductNav}
        history={history}
        onModuleReady={onModuleReady}
        user={user}
      />
    )}
  </SwanModuleContext.Consumer>
);
export const Definition = ModuleDefinition;
export default CurrentModule;
