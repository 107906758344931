import getFileTypes from "./getFileTypes";

export default (file: string | File, withPrefixDot: boolean = true) => {
  let ext;

  if (file instanceof File) {
    const fileTypes = getFileTypes();
    const mime = file.type;
    ext = Object.keys(fileTypes).find(e => fileTypes[e] === mime);
    if (!withPrefixDot && !!ext) {
      [, ext] = ext.split(".");
    }
  }

  if (typeof file === "string" && window.URL) {
    const base64Matched = file.match(/[^:/]\w+(?=;|,)/);
    if (base64Matched && Array.isArray(base64Matched) && base64Matched.length) {
      [ext] = base64Matched;
      if (withPrefixDot) ext = `.${ext}`;
    } else {
      const url = new URL(file);
      [, ext] = url.pathname
        .split("/")
        .reverse()[0]
        .split(".");

      if (withPrefixDot) ext = `.${ext}`;
    }
  }

  return ext || undefined;
};
