// @flow
export const IS = "IS"; // = "is",
export const IS_NOT = "IS_NOT"; // = "is not",
export const IS_EMPTY = "IS_EMPTY"; // = "is empty",
export const IS_NOT_EMPTY = "IS_NOT_EMPTY"; // = "is not empty",
export const STARTS_WITH = "STARTS_WITH"; // = "starts with",
export const STARTS_WITH_CI = "STARTS_WITH_CI"; // = "starts with (CI)",
export const STARTS_WITHOUT = "STARTS_WITHOUT"; // = "not starts with",
export const ENDS_WITH = "ENDS_WITH"; // = "ends with",
export const ENDS_WITHOUT = "ENDS_WITHOUT"; // = "not ends with",
export const CONTAINS = "CONTAINS"; // = "contains",
export const CONTAINS_NOT = "CONTAINS_NOT"; // = "not contains",
export const CHARACTER_BYTES_MIN = "CHARACTER_BYTES_MIN"; // = "min of characters",
export const CHARACTER_BYTES_MAX = "CHARACTER_BYTES_MAX"; // = "max of characters",

export const E = "E"; // "==",
export const NE = "NE"; // "!=",
export const LT = "LT"; // "<",
export const LTE = "LTE"; // "<=",
export const GT = "GT"; // ">",
export const GTE = "GTE"; // ">=",
export const ROUND = "ROUND"; // "round ==",
export const TRUNC = "TRUNC"; // "trunc ==",
export const FLOOR = "FLOOR"; // "floor ==",
export const CEIL = "CEIL"; // "ceil ==",

export const DATE_IS = "DATE_IS"; // "is",
export const DATE_IS_NOT = "DATE_IS_NOT"; // "is not",
export const DATE_BEFORE = "DATE_BEFORE"; // "before",
export const DATE_SAME_OR_BEFORE = "DATE_SAME_OR_BEFORE";
export const DATE_AFTER = "DATE_AFTER"; // "after",
export const DATE_SAME_OR_AFTER = "DATE_SAME_OR_AFTER";
export const DATE_YEAR_IS = "DATE_YEAR_IS"; // "year is",
export const DATE_YEAR_IS_BEFORE = "DATE_YEAR_IS_BEFORE"; // "year before",
export const DATE_YEAR_IS_AFTER = "DATE_YEAR_IS_AFTER"; // "year after",
export const DATE_YEAR_IS_NOT = "DATE_YEAR_IS_NOT"; // "year is not",
export const DATE_MONTH_IS = "DATE_MONTH_IS"; // "month is",
export const DATE_MONTH_IS_NOT = "DATE_MONTH_IS_NOT"; // "month is not",
export const DATE_DATE_IS = "DATE_DATE_IS"; // "date is",
export const DATE_DATE_IS_BEFORE = "DATE_DATE_IS_BEFORE"; // "date before",
export const DATE_DATE_IS_AFTER = "DATE_DATE_IS_AFTER"; // "date after",
export const DATE_DATE_IS_NOT = "DATE_DATE_IS_NOT"; // "date is not",
export const DATE_WEEK_IS = "DATE_WEEK_IS"; // "week is",
export const DATE_WEEK_IS_NOT = "DATE_WEEK_IS_NOT"; // "week is not",
export const DATE_DAY_IS = "DATE_DAY_IS"; // "day is",
export const DATE_DAY_IS_NOT = "DATE_DAY_IS_NOT"; // "day is not",

export const TIME_HOUR_IS = "TIME_HOUR_IS"; // "hour is",
export const TIME_HOUR_IS_NOT = "TIME_HOUR_IS_NOT"; // "hour is not",
export const TIME_HOUR_IS_BEFORE = "TIME_HOUR_IS_BEFORE"; // "hour before",
export const TIME_HOUR_IS_AFTER = "TIME_HOUR_IS_AFTER"; // "hour after",

export const EMAIL_USERNAME_IS = "EMAIL_USERNAME_IS"; // "username is",
export const EMAIL_USERNAME_IS_NOT = "EMAIL_USERNAME_IS_NOT"; // "username is not",
export const EMAIL_DOMAIN_IS = "EMAIL_DOMAIN_IS"; // "domain is",
export const EMAIL_DOMAIN_IS_NOT = "EMAIL_DOMAIN_IS_NOT"; // "domain is not",
export const EMAIL_TLD_IS = "EMAIL_TLD_IS"; // "TLD is",
export const EMAIL_TLD_IS_NOT = "EMAIL_TLD_IS_NOT"; // "TLD is not",

export const FILE_NAME_CONTAINS = "FILE_NAME_CONTAINS"; // "filename contains",
export const FILE_NAME_NOT_CONTAINS = "FILE_NAME_NOT_CONTAINS"; // "filename not contains",
export const FILE_SIZE_GT = "FILE_SIZE_GT"; // "size >",
export const FILE_SIZE_LS = "FILE_SIZE_LS"; // "size <",
export const FILE_MIME_TYPE_IS = "FILE_MIME_TYPE_IS"; // "mime type is",
export const FILE_MIME_TYPE_IS_NOT = "FILE_MIME_TYPE_IS_NOT"; // "mime type is not",

export const ARRAY_INCLUDES = "ARRAY_INCLUDES"; // "one of",
export const ARRAY_INCLUDES_NOT = "ARRAY_INCLUDES_NOT"; // "not any of",

export const PHONE_COUNTRY_IS = "PHONE_COUNTRY_IS"; // "country is",
export const PHONE_COUNTRY_IS_NOT = "PHONE_COUNTRY_IS_NOT"; // "country is not",

export const HAS_ONE = "HAS_ONE";

export default {
  // String
  [IS]: "is",
  [IS_NOT]: "is not",
  [IS_EMPTY]: "is empty",
  [IS_NOT_EMPTY]: "is not empty",
  [STARTS_WITH]: "starts with",
  [STARTS_WITH_CI]: "starts with",
  [STARTS_WITHOUT]: "not starts with",
  [ENDS_WITH]: "ends with",
  [ENDS_WITHOUT]: "not ends with",
  [CONTAINS]: "contains",
  [CONTAINS_NOT]: "not contains",
  [CHARACTER_BYTES_MIN]: "min of characters",
  [CHARACTER_BYTES_MAX]: "max of characters",

  // Number
  [E]: "==",
  [NE]: "!=",
  [LT]: "<",
  [LTE]: "<=",
  [GT]: ">",
  [GTE]: ">=",
  [ROUND]: "round ==",
  [TRUNC]: "trunc ==",
  [FLOOR]: "floor ==",
  [CEIL]: "ceil ==",

  // Date
  [DATE_IS]: "is",
  [DATE_IS_NOT]: "is not",
  [DATE_BEFORE]: "before",
  [DATE_AFTER]: "after",
  [DATE_YEAR_IS]: "year is",
  [DATE_YEAR_IS_BEFORE]: "year before",
  [DATE_YEAR_IS_AFTER]: "year after",
  [DATE_YEAR_IS_NOT]: "year is not",
  [DATE_MONTH_IS]: "month is",
  [DATE_MONTH_IS_NOT]: "month is not",
  [DATE_DATE_IS]: "date is",
  [DATE_DATE_IS_BEFORE]: "date before",
  [DATE_DATE_IS_AFTER]: "date after",
  [DATE_DATE_IS_NOT]: "date is not",
  [DATE_WEEK_IS]: "week is",
  [DATE_WEEK_IS_NOT]: "week is not",
  [DATE_DAY_IS]: "day is",
  [DATE_DAY_IS_NOT]: "day is not",

  // Time
  [TIME_HOUR_IS]: "hour is",
  [TIME_HOUR_IS_NOT]: "hour is not",
  [TIME_HOUR_IS_BEFORE]: "hour before",
  [TIME_HOUR_IS_AFTER]: "hour after",

  // Email
  [EMAIL_USERNAME_IS]: "username is",
  [EMAIL_USERNAME_IS_NOT]: "username is not",
  [EMAIL_DOMAIN_IS]: "domain is",
  [EMAIL_DOMAIN_IS_NOT]: "domain is not",
  [EMAIL_TLD_IS]: "TLD is",
  [EMAIL_TLD_IS_NOT]: "TLD is not",

  // File
  [FILE_NAME_CONTAINS]: "filename contains",
  [FILE_NAME_NOT_CONTAINS]: "filename not contains",
  [FILE_SIZE_GT]: "size >",
  [FILE_SIZE_LS]: "size <",
  [FILE_MIME_TYPE_IS]: "mime type is",
  [FILE_MIME_TYPE_IS_NOT]: "mime type is not",

  // Other
  [ARRAY_INCLUDES]: "one of",
  [ARRAY_INCLUDES_NOT]: "not any of",
  [PHONE_COUNTRY_IS]: "country is",
  [PHONE_COUNTRY_IS_NOT]: "country is not",

  // Collection
  [HAS_ONE]: "has one (sub conditions)",
};
