import { find, pick } from "lodash";
import Parts from "../definitions/parts";
import { getStore } from "../state/store";

export type DraggablePropsProvided = {
  type: string,
  pencilType?: string,
  label?: string,
};

export default ({
  type,
  pencilType,
  label,
  ...other
}: DraggablePropsProvided) => {
  const store = getStore();
  // if User dropped an existing attribute
  if ((pencilType || "").indexOf("attrib_") === 0) {
    const { ATTRIB } = store.getState().app.pencils;
    const attribute = find(ATTRIB, { id: pencilType });
    if (attribute) {
      return Object.assign(
        {},
        pick(attribute, [
          "type",
          "id",
          "label",
          "options",
          "attributeId",
          "attributeName",
        ]),
        {
          attributeName: attribute.name,
        }
      );
    }
  }

  const { allDefinitions } = store.getState().app;
  const def = allDefinitions[pencilType || ""];

  if (def && def.pencilDef.skipNewField) {
    return Object.assign({}, def.schema);
  }

  // const allDefinitions = { ...allDefs };

  if (type === Parts.NewField.options.type) {
    // Check pencil type if layout element
    if (allDefinitions[pencilType || type].pencilDef.group === "LAYOUT") {
      return {
        type: allDefinitions[pencilType || type].options.type,
        id: allDefinitions[pencilType || type].options.type,
        properties: {
          ...(allDefinitions[pencilType || type].options.defaultProperties ||
            {}),
        },
      };
    }

    return {
      type: Parts.NewField.options.type,
      pencilType,
      id: allDefinitions[pencilType || type].options.title,
      label: allDefinitions[pencilType || type].options.title,
      options: allDefinitions[pencilType || type].options,
    };
  }

  return {
    type,
    id: label,
    label,
    ...other,
    properties: {
      ...(allDefinitions[type].options.defaultProperties || {}),
    },
  };
};
