/**
 * A generic service that uses an object name and relationship name to fetch configuration like the api endpoint
 * and returns a service with the route correctly set to access this relationship
 */
import GenericLookupResource from "./GenericLookupResource";

const LookupResourceFactory = (object: string, relationship: string) => {
  class Res extends GenericLookupResource {
    objectName = object;

    relationshipName = relationship;
  }
  return Res;
};
export default LookupResourceFactory;
