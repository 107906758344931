import React from "react";
import FieldWrapper, { type FieldProps } from "../partials/FieldWrapper";
import TiptapEditor from "./tiptap";
import JoditEditor from "./Jodit";

export type InputProps = FieldProps & {
  placeholder?: string,
  type?: string,
  isTiptap?: boolean,
};

const Field = ({
  value,
  onChange,
  isTiptap,
  placeholder,
  ...restProps
}: InputProps) => (
  <div className="form-control wysiwyg">
    {isTiptap ? (
      <TiptapEditor
        value={value || ""}
        onChange={newVal => onChange && onChange({ target: { value: newVal } })}
        {...restProps}
      />
    ) : (
      <JoditEditor
        value={value || ""}
        placeholder={placeholder}
        onChange={newVal => onChange && onChange({ target: { value: newVal } })}
        {...restProps}
      />
    )}
  </div>
);

export default FieldWrapper(Field);
