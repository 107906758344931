// @flow

// utils
import { ruleEvaluator } from "./rulesEvaluator";

export default (rules: Array<Object>, data: Object) => {
  // 1. get all the ACTIONs
  let resultedActions = [];
  rules.forEach(rule => {
    const actions = ruleEvaluator(rule, data);
    if (actions && Array.isArray(actions))
      resultedActions = [...resultedActions, ...actions];
  });

  // 1.i filter, if there're unique ACTIONs
  const actionsResultedUnique = {};
  resultedActions.forEach(resultedAction => {
    if (resultedAction.id && resultedAction.data) {
      let suffix = "";
      // if action is of type ATTRIBUTE
      if ((((resultedAction.data || {}).resource || {}).data || {}).attributeId)
        suffix = (((resultedAction.data || {}).resource || {}).data || {})
          .attributeId;
      // if action is of type FORM
      if ((resultedAction.data || {}).layoutPath)
        suffix = (resultedAction.data || {}).layoutPath;

      // object having unique ACTIONs
      Object.assign(actionsResultedUnique, {
        [`${resultedAction.id}_${suffix}`]: resultedAction,
      });
    }
  });

  resultedActions = Object.values(actionsResultedUnique);

  return resultedActions;
};
