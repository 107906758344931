import * as React from "react";
import { ObjectPrintButton } from "@swan/helpers/UI";
import CustomComponentWrapper from "./partials/CustomComponentWrapper";
import Field from "./partials/Field";

import type { ElementProps } from "./types";

const ObjectPrint = (props: ElementProps & { object: string }) => {
  const { id, index, schema, data, object } = props;
  const baseURL = (schema.options || {}).objectPrintBaseURL || "objectPrints";
  const filters = (schema.options || {}).filterByTemplateNames || undefined;
  const endPoint = (schema.options || {}).objectPrintEndPoint || undefined;
  const label = (schema.options || {}).objectPrintLabel || undefined;

  if (!data || !(data || {}).id) return null;

  const Component = CustomComponentWrapper(() => (
    <ObjectPrintButton
      color="primary"
      printUrl={endPoint || `${baseURL}/${object}/${data.id}/print`}
      object={object}
      label={label}
      filterByNames={filters || undefined} // name of the template to filter
      uniqueId={data.id}
    />
  ));

  return <Component {...{ id, index, schema }} />;
};

export default Field(ObjectPrint);
