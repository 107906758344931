// @flow
export default (obj: Object) => {
  const objFullness = {};
  if (!!obj && obj.constructor === Object) {
    const objectKeys = Object.keys(obj);
    objectKeys.forEach(key => {
      if (
        !(
          (typeof obj[key] === "boolean" && obj[key] === false) ||
          (typeof obj[key] === "string" && obj[key] === "") ||
          obj[key] === null
        )
      ) {
        Object.assign(objFullness, { [key]: obj[key] });
      }
    });
  }
  return objFullness;
};
