import * as React from "react";
import { RemoteSelect, withLayout } from "@swan/themer";
import { ObjectService } from "@swan/api";
import { Col, Row, Wrapper } from "./Style";

type Props = {
  sourceObjectService: any,
  destinationObjectService: any,
  defaultSourceObjectValue?: string,
  onSourceObjectValueChange?: (string, any) => void,
  onDestinationObjectValueChange?: (string, any) => void,
  sourceObjectName: string,
  destinationObjectName: string,
};

type State = {
  destinationObject?: string,
  sourceObjectValue?: string,
  destinationObjectValue?: string,
  sourceObject?: any,
  destinationObject?: any,
};

class ObjectsMapping extends React.Component<Props, State> {
  static defaultProps = {
    onSourceObjectValueChange: () => {},
    onDestinationObjectValueChange: () => {},
    defaultSourceObjectValue: undefined,
  };

  constructor(props: Props) {
    const { defaultSourceObjectValue } = props;
    super(props);
    this.state = {
      sourceObjectValue: defaultSourceObjectValue || undefined,
      destinationObjectValue: undefined,
      sourceObject: undefined,
      destinationObject: undefined,
    };
  }

  componentDidMount(): void {
    const { sourceObjectName, destinationObjectName } = this.props;
    this.reloadObjects(sourceObjectName, destinationObjectName);
  }

  componentWillReceiveProps(nextProps: Props): void {
    const { sourceObjectName, destinationObjectName } = this.props;
    if (
      nextProps.sourceObjectName !== sourceObjectName ||
      nextProps.destinationObjectName !== destinationObjectName
    ) {
      this.reloadObjects(
        nextProps.sourceObjectName,
        nextProps.destinationObjectName
      );
    }
  }

  sourceObjectSelectHandler = ({ value }) => {
    const { onSourceObjectValueChange } = this.props;
    this.setState({
      sourceObjectValue: value,
    });
    if (onSourceObjectValueChange) {
      onSourceObjectValueChange(value);
    }
  };

  destinationObjectSelectHandler = ({ value }) => {
    const { onDestinationObjectValueChange } = this.props;
    this.setState({
      destinationObjectValue: value,
    });
    if (onDestinationObjectValueChange) {
      onDestinationObjectValueChange(value);
    }
  };

  reloadObjects(sourceObjectName, destinationObjectName) {
    ObjectService.getObjectByName(sourceObjectName).then(def => {
      this.setState({
        sourceObject: def,
      });
    });
    ObjectService.getObjectByName(destinationObjectName).then(def => {
      this.setState({
        destinationObject: def,
      });
    });
  }

  render() {
    const {
      sourceObjectService,
      destinationObjectService,
      destinationObjectName,
      sourceObjectName,
    } = this.props;

    const {
      sourceObjectValue,
      destinationObjectValue,
      sourceObject,
      destinationObject,
    } = this.state;

    return (
      <Wrapper>
        <Row>
          {sourceObject && sourceObjectService && (
            <Col>
              <RemoteSelect
                label={`${sourceObjectName}`}
                labelAttribute={sourceObject.label_attribute}
                labelExpression={sourceObject.label_expression}
                service={sourceObjectService}
                name="name"
                value={sourceObjectValue || undefined}
                onChange={this.sourceObjectSelectHandler}
              />
            </Col>
          )}
          {destinationObject && destinationObjectService && (
            <Col>
              <RemoteSelect
                label={`${destinationObjectName}`}
                service={destinationObjectService}
                labelAttribute={destinationObject.label_attribute}
                labelExpression={destinationObject.label_expression}
                name="name"
                value={destinationObjectValue || undefined}
                onChange={this.destinationObjectSelectHandler}
              />
            </Col>
          )}
        </Row>
      </Wrapper>
    );
  }
}

export default withLayout(ObjectsMapping);
