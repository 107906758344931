import React from "react";
import { uriProvider } from "./utils/uriHelpers";
import { MODULE_NAME, MODULE_DISPLAY_NAME } from "./config";

// $FlowFixMe Should be React.lazy<DashboardProps> but causing issue in webpack
const Home = React.lazy(() => import("./Module"));

// https://github.com/ReactTraining/react-router/tree/master/packages/react-router-config
const entry_routes = [
  {
    path: uriProvider(),
    name: MODULE_NAME,
    component: Home,
  },
];

export default {
  name: MODULE_NAME,
  displayName: MODULE_DISPLAY_NAME,
  mainNav: {
    name: MODULE_DISPLAY_NAME,
    url: uriProvider(),
    icon: "fa-cogs",
    weight: 5,
  },
  productNav: [
    {
      name: "My Modules",
      url: uriProvider(""), // dashboard for widgets and reporting
      collapseOnClick: true,
      icon: "fa-cogs",
    },
    {
      name: "My Activities",
      url: uriProvider("/activities"), // dashboard for widgets and reporting
      collapseOnClick: true,
      icon: "fa-cogs",
    },
    {
      name: "SWAN Platform",
      header: true,
    },
    {
      name: "About SWAN",
      url: uriProvider("/pages/about-swan"),
      icon: "fa-cogs",
    },
  ],
  preloadPermissions: {},
  routes: entry_routes,
};
