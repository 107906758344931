// $FlowFixMe
import { debounce, takeEvery, take, call, put } from "redux-saga/effects";
import { eventChannel } from "redux-saga";
import type { Channel } from "redux-saga";
import { WebsocketService } from "@swan/api";
import WorkflowTimelineService from "../services/workflowTimeline";
import WorkflowStats from "../services/workflowStats";

/**
 *
 * @param object object name
 * @param id instance id e.g : quote id
 * @param workflowId workflow id
 * @return {Channel<any>}
 */
function subscribeTimelineUpdate({
  object,
  id,
}: {
  object: string,
  id: number,
}): Channel {
  return eventChannel(emit => {
    WebsocketService.join(
      `SWAN.WORKFLOW.${object}.${id}`,
      "\\Mazrui\\SwanWorkflow\\Events\\Websocket\\WorkflowEvent",
      emit
    );
    return () => {};
  });
}
/**
 *
 * @param callback
 * @param workflowId
 * @param object
 * @param id
 */
const unsubscribeTimelineUpdate = ({
  object,
  id,
}: {
  object: string,
  id: number,
}) => WebsocketService.getEcho().leaveChannel(`SWAN.WORKFLOW.${object}.${id}`);

/**
 *
 * @param object
 * @param id
 * @param workflowId
 * @param forceLoad
 * @return {IterableIterator<*>}
 */
export function* subscribeWorkflowTimeline({
  object,
  id,
  workflowId,
  forceLoad,
}: {
  object: string,
  id: number,
  workflowId: number,
  forceLoad: boolean,
}): Generator<any, void, any> {
  const echoChannel: Channel = yield call(subscribeTimelineUpdate, {
    object,
    id,
  });
  yield put({
    type: "WORKFLOW_SUBSCRIBE_STARTED",
    payload: { object, id, workflowId, forceLoad },
  });
  while (true) {
    try {
      const message = yield take(echoChannel);
      yield put({
        type: "UPDATE_WORKFLOW_MESSAGE",
        payload: { object, id, workflowId, forceLoad, ...message },
      });

      yield put({
        type: "LOAD_WORKFLOW_TIMELINE",
        object,
        id,
        workflowId,
        forceLoad: true,
      });
      yield put({
        type: "LOAD_WORKFLOW_STATS",
        object,
        id,
        workflowId,
        forceLoad: true,
      });
    } catch (errors) {
      yield put({
        type: "LOAD_WORKFLOW_TIMELINE_ERROR",
        payload: { errors },
      });
    }
  }
}

/**
 *
 * @param object
 * @param id
 * @param workflowId
 * @param forceLoad
 * @return {IterableIterator<*>}
 */
export function* unsubscribeWorkflowTimeline({
  object,
  id,
}: {
  object: string,
  id: number,
}): Generator<any, void, any> {
  yield put({
    type: "WORKFLOW_UNSUBSCRIBE_STARTED",
    payload: { object, id },
  });
  yield call(unsubscribeTimelineUpdate, { object, id });
}

/**
 *
 * @param object
 * @param id
 * @param workflowId
 * @param forceLoad
 * @return {IterableIterator<*>}
 */
export function* loadWorkflowTimeline({
  object,
  id,
  workflowId,
  forceLoad,
}: {
  object: string,
  id: number,
  workflowId: number,
  forceLoad: boolean,
}): Generator<any, void, any> {
  const workflowTimelineService = new WorkflowTimelineService();
  const timeline = yield call(
    workflowTimelineService.getObjectWorkflowTimeline,
    object,
    id,
    workflowId,
    forceLoad
  );
  yield put({
    type: `LOAD_WORKFLOW_TIMELINE_DONE`,
    payload: { timeline, id, workflowId, forceLoad, object },
  });
}

/**
 *
 * @param object
 * @param id
 * @param workflowId
 * @param forceLoad
 * @return {IterableIterator<*>}
 */
export function* loadWorkflowStats({
  object,
  id,
  workflowId,
  forceLoad,
}: {
  object: string,
  id: number,
  workflowId: number,
  forceLoad: boolean,
}): Generator<any, void, any> {
  const workflowStatsService = new WorkflowStats();
  const stats = yield call(
    workflowStatsService.getObjectWorkflowStatus,
    object,
    id,
    workflowId,
    forceLoad
  );
  yield put({
    type: `LOAD_WORKFLOW_STATS_DONE`,
    payload: { stats, id, workflowId, forceLoad, object },
  });
}

// srv.getObjectWorkflowStatus
export default function* rootSagas(): Generator<any, void, any> {
  yield debounce(1000, "LOAD_WORKFLOW_TIMELINE", loadWorkflowTimeline);
  yield debounce(1000, "LOAD_WORKFLOW_STATS", loadWorkflowStats);
  yield takeEvery("SUBSCRIBE_WORKFLOW_TIMELINE", subscribeWorkflowTimeline);
  yield takeEvery("UNSUBSCRIBE_WORKFLOW_TIMELINE", unsubscribeWorkflowTimeline);
}
