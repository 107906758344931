// @flow
import React, { Component } from "react";
import styled from "styled-components";
import { Input } from "@swan/themer";
// utils
import processResource from "../utils/processResource";

// service
import ObjectAttributeService from "../services/objectAttribute";

// types
import { type Condition, type Resource } from "../flowTypes";

type Props = {
  conditions: Array<Condition>, // initial conditions
  onChange?: Function,
};

type State = {
  conditionResource: Resource,
  actionResource: Resource,
  conditionData: Array<Object>,
  actionData: Array<Object>,
  actionsSelected: Object,
};

const Wrapper = styled.div({
  display: "flex",
  flexDirection: "column",
});

const MapFields = styled.div({
  display: "flex",
  position: "relative",
  flexDirection: "row",
});

const FieldPanel = styled.div({
  flex: 1,
  display: "flex",
  flexDirection: "column",
});
const FieldValuesSelector = styled.div({
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-start",
  padding: "1rem",
});

const CascadeInput = styled.div({
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "center",
  padding: "1rem",
  fontWeight: "bold",
  borderBottom: "2px solid #ccc",
});

class FieldMapConditionsEditor extends Component<Props, State> {
  static defaultProps = {
    onChange: () => {},
  };

  constructor(props: Props) {
    super(props);
    this.state = {
      conditionResource: {},
      actionResource: {},
      conditionData: [],
      actionData: [],
      actionsSelected: {},
    };
  }

  componentDidMount() {
    const { conditions } = this.props;
    this.loadInitialResourceAndData(conditions);
  }

  loadInitialResourceAndData = (conditions: Array<Object>) => {
    let updateState = {};
    const actionsSelected = {};
    // set conditionResource and actionResource
    if (conditions && Array.isArray(conditions) && conditions.length) {
      // consider first condition operand left and condition resource
      // remaining condition rules also follow the same
      if (Object.keys(conditions[0]).indexOf("operandLeft") !== -1) {
        updateState = Object.assign({}, updateState, {
          conditionResource: conditions[0].operandLeft,
        });
      }

      // condition will always contains one master action
      if (
        Object.keys(conditions[0]).indexOf("actions") !== -1 &&
        Array.isArray(conditions[0].actions) && // next condition will throw flow error, otherwise
        conditions[0].actions.length
      ) {
        const actionResource = conditions[0].actions[0];
        if (Object.keys(actionResource).indexOf("data") !== -1) {
          updateState = Object.assign({}, updateState, {
            actionResource: {
              ...actionResource.data.resource,
            },
          });
        }
      }

      conditions.forEach(condition => {
        if (
          (((condition || {}).operandRight || {}).data || {}).input &&
          (condition || {}).actions &&
          condition.actions.length &&
          ((condition.actions[0] || {}).data || {}).setOptions
        ) {
          Object.assign(actionsSelected, {
            [condition.operandRight.data.input]:
              condition.actions[0].data.setOptions,
          });
        }
      });

      this.setState({
        actionsSelected,
      });
    }

    this.loadAttributeData(updateState.conditionResource.data).then(
      ({ fields }) => {
        this.setState({
          ...updateState,
          conditionData: this.getResourceOptions(fields),
        });
      }
    );

    this.loadAttributeData(updateState.actionResource.data).then(
      ({ fields }) => {
        this.setState({
          ...updateState,
          actionData: this.getResourceOptions(fields),
        });
      }
    );
  };

  loadAttributeData = async (resourceData: Object) => {
    const { object, attributeId } = resourceData;
    this.service = new ObjectAttributeService();
    this.service.setObject(object);
    const attributeData = await this.service.get(attributeId);
    return attributeData;
  };

  getResourceOptions = (attributeFields: Array<Object>) => {
    let options: any = [];
    attributeFields.forEach(field => {
      if (
        Object.keys(field).indexOf("options") !== -1 &&
        Object.keys(field.options).indexOf("items") !== -1
      ) {
        options = [...options, ...field.options.items];
      }
    });

    options = options.map(option => {
      const { value, name } = option;
      return {
        ...option,
        id: value,
        label: name,
      };
    });

    return options;
  };

  onActionDataChangeForCondition = (e: any, conditionSelected: any) => {
    const { conditions } = this.props;
    const { options } = e.target;
    const selected = [];
    for (let i = 0, l = options.length; i < l; i += 1) {
      if (options[i].selected) {
        selected.push(options[i].value);
      }
    }

    let newerConditions: Array<Object> = [...conditions];

    this.setState(
      prevState => {
        const currentConditionIndex = newerConditions.findIndex(
          condition =>
            (((condition || {}).operandRight || {}).data || {}).input ===
            conditionSelected
        );

        if (currentConditionIndex !== -1) {
          newerConditions[
            currentConditionIndex
          ].actions[0].data.setOptions = selected;
        }

        if (currentConditionIndex === -1) {
          const id =
            Math.max(...newerConditions.map(condition => condition.id || 0)) +
            1;
          let push = true;

          if (
            newerConditions.length === 1 &&
            (((newerConditions[0] || {}).operandRight || {}).data || {})
              .input === null
          )
            push = false;

          const newerCondition = {
            id,
            operandLeft: prevState.conditionResource,
            operandRight: {
              type: "CONSTANTS",
              dataType: "TEXT",
              data: {
                input: conditionSelected,
              },
            },
            operator: "IS",
            actions: [
              {
                id: "SET_OPTIONS",
                name: "Set Options",
                data: {
                  resource: { ...prevState.actionResource },
                  setOptions: selected,
                },
              },
            ],
          };

          if (push) {
            newerConditions.push(newerCondition);
          } else {
            newerConditions = [newerCondition];
          }
        }

        return {
          actionsSelected: {
            ...prevState.actionsSelected,
            [conditionSelected]: selected,
          },
        };
      },
      () => {
        const { onChange } = this.props;
        if (onChange) {
          onChange(newerConditions);
        }
      }
    );
  };

  service: Object;

  render() {
    const {
      conditionResource,
      conditionData,
      actionResource,
      actionData,
      actionsSelected,
    } = this.state;

    const conditionResourceProcessed = processResource(conditionResource);
    const actionResourceProcessed = processResource(actionResource);

    return (
      <Wrapper>
        <MapFields>
          <FieldPanel>
            {conditionResourceProcessed &&
              conditionResourceProcessed.widget === "CASCADER" && (
                <CascadeInput>
                  {conditionResourceProcessed.inputValue}
                </CascadeInput>
              )}
          </FieldPanel>
          <FieldPanel>
            {actionResourceProcessed &&
              actionResourceProcessed.widget === "CASCADER" && (
                <CascadeInput>
                  {actionResourceProcessed.inputValue}
                </CascadeInput>
              )}
          </FieldPanel>
        </MapFields>
        {conditionData.map(cData => (
          <MapFields key={cData.value}>
            <FieldPanel>
              <Input
                name={`condition_data_${cData.value}`}
                value={cData.name}
                readOnly
              />
            </FieldPanel>
            <FieldPanel>
              <FieldValuesSelector>
                {actionData && (
                  <select
                    name="action_data"
                    onChange={e => {
                      this.onActionDataChangeForCondition(e, cData.value);
                    }}
                    multiple
                    value={actionsSelected[cData.value]}
                  >
                    {actionData.map(item => (
                      <React.Fragment key={`${item.value}`}>
                        <option value={item.value}>{item.name}</option>
                      </React.Fragment>
                    ))}
                  </select>
                )}
              </FieldValuesSelector>
            </FieldPanel>
          </MapFields>
        ))}
      </Wrapper>
    );
  }
}

export default FieldMapConditionsEditor;
