import React, { useEffect, useState } from "react";
import { Service } from "@swan/api";
import { FaSitemap } from "react-icons/fa/index";
import styled from "styled-components";
import Field from "./partials/Field";

export const Container = styled.div`
  padding: 0 0.5rem;
  h5 {
    font-size: 1rem;
  }
`;

const LastMeeting = (props: any) => {
  const [meeting, setMeeting] = useState(undefined);

  useEffect(() => {
    const { object, data } = props;
    const leafService = new Service(
      `/folio/statistics/latest-activity/${object}/appointment/${data.id}`
    );

    leafService
      .get()
      .then(response => {
        if (response) setMeeting(response);
      })
      .catch(error => {
        // eslint-disable-next-line no-console
        console.error(error);
      });
  }, []);

  return (
    <Container>
      <div className="d-flex flex-column mb-2">
        <h5>Last Meeting</h5>
        <div className="d-flex align-items-baseline mt-2">
          <span className="brand-01--color">
            <FaSitemap />
          </span>
          <div className="mx-2 ui-01--d70--color">
            {meeting ? (
              <>
                <p className="stats-text-sm font-italic">
                  {meeting.start_date}
                </p>
                <p className="stats-text-sm">
                  Held by
                  <span className="stats-text-black ml-1">
                    {meeting.owner_name}
                  </span>
                </p>
              </>
            ) : (
              <p className="stats-text-sm">
                There are no meetings scheduled to date
              </p>
            )}
          </div>
        </div>
      </div>
    </Container>
  );
};
export default Field(LastMeeting);
