import * as React from "react";
import type { Nav as NavType } from "./flowTypes";
import CoreNav, {
  modulesNav as coreModulesNav,
  productNav as coreProductNav,
} from "./core";

import NavContext from "./nav-context";

import history from "../utils/history";

export type Nav = NavType;

type State = {
  coreNav: Array<Nav>,
  modulesNav: { [string]: { nav: Array<Nav>, info: Object } },
  productNav: Array<Nav>,
};

export class NavProvider extends React.Component<any, State> {
  unlistenToHistory: Function;
  constructor(props: any) {
    super(props);
    this.state = {
      coreNav: [...CoreNav],
      modulesNav: { ...coreModulesNav },
      productNav: [...coreProductNav],
    };

    this.registerNav = this.registerNav.bind(this);
  }

  componentDidMount() {
    this.unlistenToHistory = history.listen(location => {
      this.setState({
        productNav: this.getSubNav(),
      });
    });
    // initial value
    this.setState({
      productNav: this.getSubNav(),
    });
  }

  componentWillUnmount() {
    if (this.unlistenToHistory) {
      this.unlistenToHistory();
    }
  }

  registerNav = (
    mainNav: Nav,
    subNav: Array<Nav>,
    module: string,
    moduleDisplayName?: string
  ) => {
    this.setState(
      state => ({
        ...state,
        coreNav: [...state.coreNav, mainNav],
        modulesNav: {
          ...state.modulesNav,
          [module.toLowerCase()]: {
            nav: [...subNav],
            info: {
              name: module,
              displayName: moduleDisplayName,
            },
          },
        },
      }),
      () => {
        // Refresh sub nav
        this.setState({
          productNav: this.getSubNav(),
        });
      }
    );
  };

  registerProductNav = (module: string, newProductNav: any) => {
    this.setState(
      state => ({
        ...state,
        modulesNav: {
          ...state.modulesNav,
          [module.toLowerCase()]: {
            ...state.modulesNav[module.toLowerCase()],
            nav: [
              ...state.modulesNav[module.toLowerCase()].nav,
              ...newProductNav,
            ],
          },
        },
      }),
      () => {
        // Refresh sub nav
        this.setState({
          productNav: this.getSubNav(),
        });
      }
    );
  };

  getMainNav = (): Array<Nav> => {
    const { coreNav } = this.state;
    return [...coreNav];
  };

  getSubNav = (): Array<Nav> => {
    const mod = this.getCurrentModule();
    const { modulesNav } = this.state;
    return [...(modulesNav[mod] || { nav: [] }).nav];
  };

  getCurrentModule = () => {
    const location = history.location.pathname.split("/");
    return location[1];
  };

  getCurrentModuleName = () => {
    const mod = this.getCurrentModule();
    const { modulesNav } = this.state;
    return (modulesNav[mod] || { info: {} }).info.name;
  };

  getCurrentModuleNiceName = () => {
    const mod = this.getCurrentModule();
    const { modulesNav } = this.state;
    return (modulesNav[mod] || { info: {} }).info.displayName;
  };

  render() {
    const { productNav } = this.state;
    const contextValue = {
      mainNav: { items: this.getMainNav() },
      productNav: {
        items: productNav || this.getSubNav(),
        module: this.getCurrentModuleName(),
        moduleDisplayName: this.getCurrentModuleNiceName(),
      },
      registerProductNav: this.registerProductNav,
      registerNav: this.registerNav,
    };
    const { children } = this.props;
    return (
      <NavContext.Provider value={contextValue}>{children}</NavContext.Provider>
    );
  }
}

export const NavConsumer = NavContext.Consumer;

export default {
  Provider: NavProvider,
  Consumer: NavConsumer,
};
