import * as types from "./types";
import type { State } from "@swan/state";

export type UserState = {
  loggedIn: boolean,
  token?: ?string,
  profile?: ?{},
  error?: ?boolean,
  errorMessage?: ?string,
  locale: string,
  activeOrganization?: number,
  organizations?: Array<{
    id: number,
    name: string,
    code?: string
  }>
};

const initialState: UserState = {
  loggedIn: false,
  token: null,
  profile: null,
  activeOrganization: undefined,
  organizations: undefined,
  error: null,
  errorMessage: null,
  locale: (navigator.language || "en").substr(0, 2)
};

const user = (state: UserState = initialState, action: State.Action) => {
  switch (action.type) {
    case types.LOGIN_WITH_ID_TOKEN_BEGIN:
      return {
        ...state,
        error: null
      };
    case types.LOGIN_WITH_ID_TOKEN_SUCCESS:
      return {
        ...state,
        loggedIn: true,
        token: action.token,
        profile: action.profile,
        locale: action.profile.locale || state.locale || "en",
        activeOrganization: action.profile.active_organization
      };
    case types.LOGIN_WITH_ID_TOKEN_FAILED:
      return {
        ...state,
        loggedIn: false,
        token: null,
        error: true,
        errorMessage: action.message
      };
    case types.LOGOUT_COMPLETED:
      if (action.switchingOrg) {
        return state;
      }
      return initialState;
    case types.SET_LOCALE:
      return {
        ...state,
        locale: action.locale
      };
    case types.ORGANIZATIONS_LOADED:
      return {
        ...state,
        organizations: [...action.payload]
      };
    case types.SWITCH_ORGANIZATION_SUCCESS:
      return {
        ...state,
        profile: action.profile,
        activeOrganization: action.profile.active_organization
      };
    default:
      return state;
  }
};

export default user;
