import * as React from "react";
import withAuth from "./withAuth";
import type { AuthContextShape } from "../types";

/**
 * Wraps a component and waits until all specified permissions are loaded, and passes these
 * permissions to the wrapped components as a prop
 *
 * @param {Array<string>} permissions Array of permission names
 */

type State = {
  loadedPermissions: Object,
};

type Props = {
  permissions: Array<string>,
  children: React.Node | Function,
  auth: AuthContextShape,
};

class PermissionsProvider extends React.Component<Props, State> {
  state = {};

  componentDidMount() {
    const { auth, permissions } = this.props;
    auth.getPermissions(permissions || []).then(perms => {
      this.setState({
        loadedPermissions: perms,
      });
    });
  }

  render() {
    const { loadedPermissions } = this.state;
    const { children } = this.props;
    if (!loadedPermissions) {
      return null;
    }
    return React.Children.map(children, child =>
      React.cloneElement(child, {
        permissions: loadedPermissions,
      })
    );
  }
}

export default withAuth(PermissionsProvider);
