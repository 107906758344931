export default {
  uid: "datetimeField",
  attributeId: "",
  formId: "",
  pencilDef: {
    title: "Datetime",
    group: "FIELD",
    handleIcon: "FaRegClock",
    styles: {},
  },
  options: {
    type: "datetimeField",
    hidePastDates: false,
    preventDefaultValue: false,
    dateFormat: "DD/MM/YYYY hh:mm a",
  },
};
