import React from "react";
import { Row, Col, CardTitle, Card, CardBody } from "reactstrap";
import { Line } from "react-chartjs-2";
import styled from "styled-components";

type Props = {
  title: string,
  subTitle: string,
  yMax: number,
  data: any,
};

const GraphWrapper = styled.div`
  .graph-subtitle {
    margin-top: 10px;
  }
  .graph-title {
    font-size: 22px;
  }
`;

const Graph = ({ title, subTitle, yMax, data }: Props) => {
  const mainChart = {
    labels: (data || {}).labels || [],
    datasets: (data || {}).datasets,
  };

  const mainChartOpts = {
    tooltips: {
      enabled: true,
      // custom: CustomTooltips,
      intersect: true,
      mode: "index",
      position: "nearest",
      callbacks: {
        labelColor: (tooltipItem, chart) => ({
          backgroundColor:
            chart.data.datasets[tooltipItem.datasetIndex].borderColor,
        }),
      },
    },
    maintainAspectRatio: false,
    legend: {
      display: false,
    },
    scales: {
      xAxes: [
        {
          gridLines: {
            drawOnChartArea: false,
          },
        },
      ],
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
            maxTicksLimit: 5,
            stepSize: Math.ceil((yMax || 250) / 5),
            max: yMax || 250,
          },
        },
      ],
    },
    elements: {
      point: {
        radius: 0,
        hitRadius: 10,
        hoverRadius: 4,
        hoverBorderWidth: 3,
      },
    },
  };

  return (
    <GraphWrapper>
      <Card>
        <CardBody>
          <Row>
            <Col sm="5">
              <CardTitle className="mb-0 graph-title">{title}</CardTitle>
              <div className="small text-muted graph-subtitle">{subTitle}</div>
            </Col>
          </Row>
          <div
            className="chart-wrapper"
            style={{ height: "300px", marginTop: "40px" }}
          >
            <Line data={mainChart} options={mainChartOpts} height={300} />
          </div>
        </CardBody>
      </Card>
    </GraphWrapper>
  );
};

export default Graph;
