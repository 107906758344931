// @flow
import {
  // Number
  E,
  NE,
  GT,
  GTE,
  LT,
  LTE,
  // String
  IS,
  IS_NOT,
  IS_EMPTY,
  IS_NOT_EMPTY,
  // STARTS_WITH,
  STARTS_WITH_CI,
  STARTS_WITHOUT,
  ENDS_WITH,
  ENDS_WITHOUT,
  CONTAINS,
  CONTAINS_NOT,
  CHARACTER_BYTES_MIN,
  CHARACTER_BYTES_MAX,
  // Date
  DATE_IS,
  DATE_IS_NOT,
  DATE_BEFORE,
  DATE_SAME_OR_BEFORE,
  DATE_SAME_OR_AFTER,
  // array
  ARRAY_INCLUDES,
  ARRAY_INCLUDES_NOT,
} from "./labels";
import CommonOperators from "./common";
import NumberOperators from "./number";
import StringOperators from "./string";
import DateOperators from "./date";
import ArrayOperators from "./array";

// operator field map
export { default as operatorFieldMap } from "./fieldMap";
export { default as operatorLabels } from "./labels";

// default as operator functions
export default {
  // Number
  [E]: NumberOperators.E,
  [NE]: NumberOperators.NE,
  [GT]: NumberOperators.GT,
  [GTE]: NumberOperators.GTE,
  [LT]: NumberOperators.LT,
  [LTE]: NumberOperators.LTE,
  // String
  [IS]: CommonOperators.IS,
  [IS_NOT]: CommonOperators.IS_NOT,
  [IS_EMPTY]: CommonOperators.IS_EMPTY,
  [IS_NOT_EMPTY]: CommonOperators.IS_NOT_EMPTY,
  // [STARTS_WITH]: StringOperators.STARTS_WITH,
  [STARTS_WITH_CI]: StringOperators.STARTS_WITH_CI,
  [STARTS_WITHOUT]: StringOperators.STARTS_WITHOUT,
  [ENDS_WITH]: StringOperators.ENDS_WITH,
  [ENDS_WITHOUT]: StringOperators.ENDS_WITHOUT,
  [CONTAINS]: StringOperators.CONTAINS,
  [CONTAINS_NOT]: StringOperators.CONTAINS_NOT,
  [CHARACTER_BYTES_MIN]: StringOperators.CHARACTER_BYTES_MIN,
  [CHARACTER_BYTES_MAX]: StringOperators.CHARACTER_BYTES_MAX,
  // Date
  [DATE_IS]: DateOperators.DATE_IS,
  [DATE_IS_NOT]: DateOperators.DATE_IS_NOT,
  [DATE_BEFORE]: DateOperators.DATE_BEFORE,
  [DATE_SAME_OR_BEFORE]: DateOperators.DATE_SAME_OR_BEFORE,
  [DATE_SAME_OR_AFTER]: DateOperators.DATE_SAME_OR_AFTER,
  // ARRAY
  [ARRAY_INCLUDES]: ArrayOperators.ARRAY_INCLUDES,
  [ARRAY_INCLUDES_NOT]: ArrayOperators.ARRAY_INCLUDES_NOT,
};
