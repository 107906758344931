import * as React from "react";
import { DatePicker } from "@swan/themer";

import Field from "./partials/Field";
import type { ElementProps } from "./types";

const DateField = ({
  schema,
  onChange,
  error,
  data,
  readOnly,
  hideLabel,
}: ElementProps) => (
  <DatePicker
    {...schema.options || {}}
    type="date"
    name={schema.attributeName || ""}
    value={(data || {})[schema.attributeName || ""]}
    label={schema.label}
    hideLabel={hideLabel || (schema.options || {}).hideLabel}
    onChange={e =>
      onChange && onChange(schema.attributeName || "", e.target.value)
    }
    error={(error || {})[schema.attributeName || ""]}
    static={readOnly || (schema.options || {}).readOnly}
    dateFormat={(schema.options || {}).dateFormat || "DD/MM/YYYY"}
  />
);

export default Field(DateField);
