import React from "react";
import Styled from "styled-components";

import GlobalNavigation from "./GlobalNavigation";
import ProductNavigation from "./ProductNavigation";

import { LayoutSubscriber } from "./context/layout";

import { GLOBAL_NAV_WIDTH } from "./config";

import { type LayoutProps } from "./types";

type Props = LayoutProps;

const Wrapper = Styled.div``;

const Empty = () => <div />;

export default (props: Props) => {
  const {
    sidebarHeader,
    pageHeader,
    productNavigations,
    globalNavigation,
    ...restProps
  } = props;

  return (
    <LayoutSubscriber>
      {({ toggleProductNavigation, autoSlideOnHover, activeMenu }) => {
        const Component = {
          SidebarHeader: (sidebarHeader || {}).component || Empty,
          Navigation: productNavigations[activeMenu] || Empty,
        };
        return (
          <Wrapper
            onMouseEnter={
              autoSlideOnHover ? toggleProductNavigation : undefined
            }
            onMouseLeave={
              autoSlideOnHover ? toggleProductNavigation : undefined
            }
          >
            <>
              <GlobalNavigation size={GLOBAL_NAV_WIDTH} {...globalNavigation} />
              <ProductNavigation Components={Component} {...restProps} />
            </>
          </Wrapper>
        );
      }}
    </LayoutSubscriber>
  );
};
