import { layoutProvider } from "@swan/themer";
import logo from "../../assets/img/brand/logo.png";

export default layoutProvider({
  name: "coreui",
  props: {
    brand: {
      logo,
    },
  },
});
