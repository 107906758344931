import React, { Fragment } from "react";
import styled from "styled-components";

export type BrandCardType = {
  title: string,
  data: { [string]: number },
};

const BrandCardContainer = styled.div`
  flex-direction: column;
  min-width: 0;
  margin-bottom: 1.5rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid #c8ced3;
  border-radius: 0.25rem;
  position: relative;
  display: flex;
`;

const BrandCardHeader = styled.div`
  position: relative;
  display: -ms-flexbox;
  display: flex;
  color: #fff;
  align-items: center;
  -ms-flex-pack: center;
  justify-content: center;
  height: 6rem;
  border-radius: 0.25rem 0.25rem 0 0;
  background-color: rgb(32, 168, 216);
`;

const BrandCardTitle = styled.div`
  font-size: 16px;
  text-align: center;
  font-weight: 400;
  text-transform: uppercase;
  letter-spacing: 3px;
`;

const BrandCardBody = styled.div`
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
  flex-direction: row;
  padding: 0.75rem 0;
  text-align: center;

  & > :not(:last-child) {
    border-right: 1px solid #c8ced3;
  }

  & > * {
    flex: 1 1;
    padding: 0.1875rem 0;
  }
`;

const BrandCardValue = styled.div`
  font-size: 1.3125rem;
  font-weight: 600;
`;

const NoDataAvailable = styled.div`
  font-size: 18px;
  color: #b9b9b9;
  margin-top: 6px;
  margin-bottom: 6px;
`;

const BrandCardValueTitle = styled.div`
  color: #73818f !important;
  text-transform: uppercase !important;
  font-size: 80%;
  font-weight: 400;
`;

const BrandCard = ({ title, data }: BrandCardType) => (
  <Fragment>
    {data && (
      <BrandCardContainer>
        <BrandCardHeader className="chart-header">
          <BrandCardTitle>{title}</BrandCardTitle>
        </BrandCardHeader>
        <BrandCardBody className="chart-body">
          {Object.keys(data).length === 0 && (
            <NoDataAvailable>No Data Available</NoDataAvailable>
          )}
          {Object.keys(data).map(key => (
            <div key={key}>
              <BrandCardValue>{data[key]}</BrandCardValue>
              <BrandCardValueTitle>{key}</BrandCardValueTitle>
            </div>
          ))}
        </BrandCardBody>
      </BrandCardContainer>
    )}
  </Fragment>
);

export default BrandCard;
