import React from "react";
import { Doughnut } from "react-chartjs-2";
import styled from "styled-components";

import { Card, CardBody, CardHeader } from "reactstrap";
import { type DatasetType } from "./types";

export type DoughnutDataType = {
  datasets: Array<DatasetType>,
  labels: Array<string>,
};
export type DoughnutType = {
  data: DoughnutDataType,
  title?: string,
  width?: number,
  height?: number,
  exportButton?: any,
};

// types
type Props = DoughnutType;

const Wrapper = styled("div")`
  flex: 1;
`;

// eslint-disable-next-line react/prefer-stateless-function
class Widget extends React.Component<Props> {
  static defaultProps = {
    title: undefined,
    width: undefined,
    height: undefined,
    exportButton: undefined,
  };

  render() {
    const { data, title, width, height, exportButton } = this.props;
    return (
      <Wrapper>
        <Card>
          {title && (
            <CardHeader
              tag="h3"
              className="ui-03--bg chart-header d-flex justify-content-between align-items-center"
            >
              {title}
              {exportButton && exportButton}
            </CardHeader>
          )}
          <CardBody className="chart-body">
            <Doughnut
              data={data}
              // @todo
              legend={{
                display: false,
                labels: { fontColor: "rgb(0, 0, .1)" },
                position: "bottom",
              }}
              width={width}
              height={height}
            />
          </CardBody>
        </Card>
      </Wrapper>
    );
  }
}

export default Widget;
