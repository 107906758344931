import React from "react";
import { uriProvider } from "./utils/uriHelpers";
import {
  MODULE_NAME,
  MODULE_DISPLAY_NAME,
  MODULE_SHORT_DISPLAY_NAME,
} from "./config";

// $FlowFixMe Should be React.lazy<DashboardProps> but causing issue in webpack
const Home = React.lazy(() => import("./Module"));

// https://github.com/ReactTraining/react-router/tree/master/packages/react-router-config
const entry_routes = [
  {
    path: uriProvider(),
    name: MODULE_NAME,
    component: Home,
    Permission: ["module:servicedesk"],
  },
];

export default {
  name: MODULE_NAME,
  displayName: MODULE_DISPLAY_NAME,
  mainNav: {
    name: MODULE_DISPLAY_NAME,
    url: uriProvider(), // default landing page
    icon: "fa-cogs",
    permissions: ["module:servicedesk"],
    color: "#FF7452",
    shortName: MODULE_SHORT_DISPLAY_NAME,
    weight: 10,
    showOnLayouts: ["web", "outlook"],
  },
  preloadPermissions: {
    groups: ["servicedesk", "core"],
  },
  productNav: [
    {
      name: "Dashboard",
      url: uriProvider("/dashboard"), // dashboard for widgets and reporting
      icon: "fa-cogs",
    },
    {
      name: "Service Desk",
      header: true,
      permissions: ["serviceTicket:view"],
    },
    {
      name: "Ticketing System",
      url: uriProvider(),
      icon: "fa-cogs",
      permissions: ["serviceTicket:view"],
    },
    {
      name: "Raised Tickets",
      header: true,
    },
    {
      name: "My Tickets",
      url: uriProvider("my-tickets"),
      icon: "fa-cogs",
      permissions: ["serviceTicket:view"],
    },
    {
      name: "ITSM Management",
      header: true,
      permissions: ["serviceTicketManage:view"],
    },
    {
      name: "My Tasks",
      url: uriProvider("my-task-assignments"),
      icon: "fa-cogs",
      permissions: ["serviceTicketManage:view"],
    },
    {
      name: "My Assignment",
      url: uriProvider("my-assignments"),
      icon: "fa-cogs",
      permissions: ["serviceTicketManage:view"],
    },
    {
      name: "My Team Assignment",
      url: uriProvider("my-team-assignments"),
      icon: "fa-cogs",
      permissions: ["serviceTicketManage:view"],
    },
    {
      name: "All Tickets",
      url: uriProvider("all-tickets"),
      icon: "fa-cogs",
      permissions: ["serviceTicketManage:view"],
    },
    {
      name: "Configuration",
      header: true,
      permissions: ["serviceTicketMasters:view"],
    },
    {
      name: "Masters",
      url: uriProvider("masters"),
      icon: "fa-cogs",
      permissions: ["serviceTicketMasters:view"],
    },
    {
      name: "Workflow",
      url: uriProvider("workflows"),
      icon: "fa-cogs",
      permissions: ["serviceTicketWorkflow:view"],
    },
  ],
  routes: entry_routes,
};
