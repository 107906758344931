import React from "react";
import { Input, Label, FormGroup } from "reactstrap";
import FieldWrapper, { type FieldProps } from "./partials/FieldWrapper";

export type InputProps = FieldProps & {
  title?: string,
  type?: string,
};

const Field = ({
  // type,
  title,
  name,
  required,
  value,
  onChange,
  className,
  error,
}: InputProps) => (
  <div className="form-control form-control-checkbox">
    <FormGroup check>
      <Input
        type="checkbox"
        id={name}
        required={required || false}
        value={value}
        onChange={onChange}
        className={`${className || ""} ${error !== "" ? "is-invalid" : ""}`}
        checked={value === "1" || (value && value !== "0")}
      />
      <Label check for={name}>
        {title}
      </Label>
    </FormGroup>
  </div>
);

export default FieldWrapper(Field);
