import React, { Component } from "react";
import { InputGroupText } from "reactstrap";
import { MdMyLocation } from "react-icons/all";
// import { getCurrentLocation } from "./Utils";
// import { type AddressType } from "./types";

type Props = {
  // map: Object,
  onSubmit: () => void,
};

class CurrentLocationButton extends Component<Props> {
  getLocation = () => {
    const { onSubmit } = this.props;
    if (onSubmit) onSubmit();
  };

  render() {
    return (
      <div className="input-group-prepend filter-map-icon">
        <InputGroupText
          className="input-group-append"
          onClick={this.getLocation}
        >
          <MdMyLocation />
        </InputGroupText>
      </div>
    );
  }
}

export default CurrentLocationButton;
