import FormDesigner from "./FormDesigner";
import { schemaDataSource } from "./definitions";

/**
 * FormDesigner API
 *
 * props.theme
 * props.loader: React.Component
 * props.normalize: boolean
 * props.pencilDefs: Object {| attributeId: string, formId: string, options: {}|}
 * props.artDefs: (JSON UI SCHEMA)
 */

export { getSchemaDiff, getSchema } from "./utils/exposeSchema";
export { schemaDataSource };

export default FormDesigner;
