export const onMenuList = {
  // borderLeft: "1px solid #3d70b2",
  top: "-6px",
  backgroundColor: "hsl(0,0%,100%)",
  borderRadius: "0px",
  boxShadow: "0px 3px 4px -2px rgb(0 0 0 / 41%)",
  marginBottom: "0px !important",
  marginTop: "0px !important",
  position: "absolute",
  width: "100%",
  zIndex: 1,
  boxSizing: "border-box",
  fontSize: ".875rem",
  maxHeight: "250px",
  overflow: "hidden",
  overflowY: "scroll",
  whiteSpace: "pre-wrap",
  lineHeight: "18px",
};

export default {
  onMenuList,
};
