import React, { Component } from "react";
import classNames from "classnames";
import { FaCloudUploadAlt } from "react-icons/fa";
import { DropzoneContainer, DropzoneUploadMessage } from "./Styled";

// import "../styles/dropzone.css";

type Props = {
  disabled?: boolean,
  onFilesAdded?: Function,
  multiple?: boolean,
  uploadMessage?: string,
  uploading?: boolean,
  // files?: Array<Files>,
};

type State = {
  dragOver: boolean,
};

class Dropzone extends Component<Props, State> {
  static defaultProps = {
    multiple: false,
    disabled: false,
    onFilesAdded: undefined,
    uploadMessage: undefined,
    uploading: false,
    // files: [],
  };

  constructor(props: Props) {
    super(props);
    this.state = { dragOver: false };
    this.fileInputRef = React.createRef();
  }

  onFilesAdded = (e: any) => {
    const { disabled, onFilesAdded } = this.props;
    if (disabled) return;
    const { files } = e.target;
    if (onFilesAdded) {
      const fileListAsArray = this.fileListAsArray(files);
      onFilesAdded(fileListAsArray);
    }
  };

  openFileDialog = () => {
    const { disabled } = this.props;
    if (disabled) return;
    (this.fileInputRef.current || {}).click();
  };

  onDragOver = (e: SyntheticDragEvent<HTMLDivElement>) => {
    e.preventDefault();
    const { disabled } = this.props;
    if (disabled) return;
    this.setState({ dragOver: true });
  };

  onDragLeave = (/* e: SyntheticDragEvent<HTMLDivElement> */) => {
    this.setState({ dragOver: false });
  };

  onDrop = (e: SyntheticDragEvent<HTMLDivElement>) => {
    e.preventDefault();
    const { disabled, onFilesAdded } = this.props;
    if (disabled) return;
    const { files } = e.dataTransfer || {};
    if (onFilesAdded) {
      const fileListAsArray = this.fileListAsArray(files);
      onFilesAdded(fileListAsArray);
    }
    this.setState({ dragOver: false });
  };

  fileListAsArray = (fileList: FileList) => {
    const fileListToArray = [];
    for (let i = 0; i < fileList.length; i += 1) {
      fileListToArray.push(fileList.item(i));
    }
    return fileListToArray;
  };

  fileInputRef: { current: ?HTMLInputElement };

  render() {
    const {
      disabled,
      multiple,
      uploadMessage,
      uploading,
      // disabledMessage,
      // files,
      // valueAccessor,
    } = this.props;
    const { dragOver } = this.state;
    return (
      <>
        {!disabled && (
          <DropzoneContainer
            className={classNames("dropzone", {
              highlight: dragOver,
              disabled,
            })}
            onDragOver={this.onDragOver}
            onDragLeave={this.onDragLeave}
            onDrop={this.onDrop}
            onClick={this.openFileDialog}
          >
            <>
              <input
                ref={this.fileInputRef}
                className="dropzone-file-input"
                type="file"
                multiple={multiple}
                onChange={this.onFilesAdded}
              />
              <DropzoneUploadMessage disabled={disabled}>
                <FaCloudUploadAlt size="2em" />
                <span>
                  {uploading
                    ? "Uploading..."
                    : uploadMessage || "Drag and Drop or Click"}
                </span>
              </DropzoneUploadMessage>
            </>
          </DropzoneContainer>
        )}
      </>
    );
  }
}

export default Dropzone;
