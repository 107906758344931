import * as React from "react";
import { withLayout, Modal } from "@swan/themer";
import { ResourceFactory } from "@swan/api";
import ObjectsSelection from "./ObjectsSelection";
import RelationshipsMapping from "./RelationshipsMapping";
import ObjectsMapping from "./ObjectsMapping";
import ConverterMappingConfigurationService from "./services/converterMappingConfigurationService";

type Props = {
  defaultSourceObject?: string, // the main object which contain the mapped object
  defaultDestinationObject?: string, // the object which the source is going to convert to
  defaultDestinationObjectService?: Object, // the object which the source is going to convert to
  map?: Array<any>, // it maps relationships of the object
  onModalToggle: boolean => void,
  disableObjectsSelection?: boolean,
  defaultSourceObjectValue?: string,
  defaultDestinationObjectValue?: string,
  isModalEnabled?: false,
  onSuccess?: Function,
};

type State = {
  isModalEnabled: boolean,
  sourceObjectName?: string,
  destinationObjectName?: string,
  sourceObjectService?: any,
  destinationObjectService?: any,
  destinationObjectValue?: string,
  sourceObjectValue?: string,
  map?: Array<any>,
};

const converterMappingConfigurationService = new ConverterMappingConfigurationService();

class ObjectConverter extends React.Component<Props, State> {
  static defaultProps = {
    map: [],
    defaultSourceObject: undefined,
    disableObjectsSelection: false,
    isModalEnabled: false,
    defaultDestinationObject: undefined,
    defaultDestinationObjectService: undefined,
    defaultSourceObjectValue: undefined,
    defaultDestinationObjectValue: undefined,
    onSuccess: undefined,
  };

  constructor(props: Props) {
    const {
      defaultDestinationObject,
      defaultDestinationObjectService,
      defaultSourceObject,
      defaultSourceObjectValue,
      defaultDestinationObjectValue,
      isModalEnabled,
    } = props;
    super(props);
    this.state = {
      isModalEnabled: isModalEnabled || false,
      map: [],
      destinationObjectName: defaultDestinationObject || undefined,
      sourceObjectName: defaultSourceObject || undefined,
      sourceObjectValue: defaultSourceObjectValue || undefined,
      sourceObjectService: defaultSourceObject
        ? this.getObjectService(defaultSourceObject)
        : undefined,
      destinationObjectService:
        defaultDestinationObjectService ||
        (defaultDestinationObject
          ? this.getObjectService(defaultDestinationObject)
          : undefined),
      destinationObjectValue: defaultDestinationObjectValue,
    };
  }

  componentDidMount(): void {
    const { isModalEnabled } = this.props;
    this.setState({ isModalEnabled });
  }

  onDestinationObjectChange = ({ value }) => {
    this.setState({
      destinationObjectName: value,
      destinationObjectService: this.getObjectService(value),
    });
  };

  onDestinationObjectValueChange = value => {
    this.setState({
      destinationObjectValue: value,
    });
  };

  onSourceObjectChange = ({ value }) => {
    this.setState({
      sourceObjectName: value,
      sourceObjectService: this.getObjectService(value),
    });
  };

  onSourceObjectValueChange = value => {
    this.setState({
      sourceObjectValue: value,
    });
  };

  onMapUpdate = map => {
    this.setState({ map });
  };

  getObjectService = objectName => {
    const Service = ResourceFactory(objectName);
    return new Service();
  };

  modalToggler = () => {
    const { isModalEnabled } = this.state;
    const { onModalToggle } = this.props;
    onModalToggle(!isModalEnabled);
    this.setState({ isModalEnabled: !isModalEnabled });
  };

  mapObjects() {
    const {
      sourceObjectName,
      destinationObjectName,
      destinationObjectValue,
      sourceObjectValue,
      map,
    } = this.state;

    converterMappingConfigurationService
      .post("update-mapping", {
        sourceObjectName,
        destinationObjectName,
        destinationObjectValue,
        sourceObjectValue,
        map,
      })
      .then(() => {
        this.modalToggler();
        const { onSuccess } = this.props;
        if (onSuccess) {
          onSuccess();
        }
      })
      .catch(() => {});
  }

  render() {
    const {
      defaultSourceObject,
      defaultDestinationObject,
      disableObjectsSelection,
      defaultSourceObjectValue,
      map,
    } = this.props;

    const {
      isModalEnabled,
      sourceObjectService,
      destinationObjectService,
      destinationObjectValue,
      sourceObjectValue,
      destinationObjectName,
      sourceObjectName,
    } = this.state;

    const buttons = [
      {
        label: "Map",
        color: "primary",
        onClick: () => {
          this.mapObjects();
        },
      },
      {
        label: "Close",
        onClick: () => this.modalToggler(),
        color: "secondary",
      },
    ];

    return (
      <>
        {isModalEnabled && (
          <Modal
            title={
              defaultSourceObject && defaultDestinationObject
                ? `Convert ${defaultSourceObject} to ${defaultDestinationObject}`
                : `Choose Objects to convert`
            }
            isOpen={isModalEnabled}
            toggle={this.modalToggler}
            buttons={buttons.reverse()}
            size="xl"
          >
            {!disableObjectsSelection && (
              <ObjectsSelection
                defaultDestinationObject={defaultDestinationObject}
                defaultSourceObject={defaultSourceObject}
                onDestinationObjectChange={this.onDestinationObjectChange}
                onSourceObjectChange={this.onSourceObjectChange}
              />
            )}
            {sourceObjectName && destinationObjectName && (
              <>
                <ObjectsMapping
                  sourceObjectService={sourceObjectService}
                  destinationObjectService={destinationObjectService}
                  sourceObjectName={sourceObjectName}
                  defaultSourceObjectValue={defaultSourceObjectValue}
                  destinationObjectName={destinationObjectName}
                  onDestinationObjectValueChange={
                    this.onDestinationObjectValueChange
                  }
                  onSourceObjectValueChange={this.onSourceObjectValueChange}
                />
                <RelationshipsMapping
                  defaultMap={map || []}
                  sourceObjectService={sourceObjectService}
                  destinationObjectService={destinationObjectService}
                  destinationObjectValue={destinationObjectValue}
                  sourceObjectValue={sourceObjectValue}
                  destinationObjectName={destinationObjectName}
                  sourceObjectName={sourceObjectName}
                  onMapUpdate={this.onMapUpdate}
                />
              </>
            )}
          </Modal>
        )}
      </>
    );
  }
}

export default withLayout(ObjectConverter);
