import React, { Component } from "react";
import { Modal, ModalHeader, ModalBody } from "reactstrap";

type Props = {};
type State = {
  show: boolean
};

class ReloadModal extends Component<Props, State> {
  static toggle() {
    // Reload when modal click.
    window.location.reload(window.location.href);
  }

  state = {
    show: false
  };

  componentDidMount() {
    // Handle global event.
    window.addEventListener("newContentAvailable", () => {
      this.setState({
        show: true
      });
    });
  }

  render() {
    const { show } = this.state;
    if (!show) {
      return null;
    }
    return (
      <Modal
        isOpen={show}
        toggle={ReloadModal.toggle}
        onClosed={ReloadModal.toggle}
      >
        <ModalHeader toggle={ReloadModal.toggle}>Update Available</ModalHeader>
        <ModalBody>
          <span> New Content Available! please reload </span>
        </ModalBody>
      </Modal>
    );
  }
}

export default ReloadModal;
