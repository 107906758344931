import {
  GROUP_DEFAULT,
  // groupsOrderFallback,
} from "../../../definitions/pencils/config";

import jsonSchemaParts from "../../../definitions/parts/vendor/jsonSchema";

const { singleOnWidget } = jsonSchemaParts;

export default () => ({
  schema: {
    ...singleOnWidget.schema,
  },
  uiSchema: {
    ...singleOnWidget.uiSchema,
  },
  optionGroup: GROUP_DEFAULT,
  optionOrder: 0,
});
