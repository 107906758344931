import React, { type Node } from "react";
import { CNavTitle, CNavItem, CNavLink, CNavGroup } from "@coreui/react";
import { Link } from "react-router-dom";
import { AuthorizedComponent } from "@swan/auth";

import { LayoutSubscriber } from "../context/layout";

type NavItem = {
  header: boolean,
  name: string,
  url?: string,
  icon?: Node,
  onClick?: Function,
  hasParent?: boolean,
  permissions: any,
};

type Props = NavItem & {
  items?: Array<NavItem>,
};

const ItemRenderer = (props: Props) => {
  const {
    name,
    url,
    icon,
    header,
    items,
    onClick,
    hasParent,
    permissions,
  } = props;

  const LinkRenderer = url ? Link : CNavLink;

  if (header) {
    return (
      <CNavTitle
        className={`sidebar-nav-item sidebar-nav-item--title ${
          hasParent ? "has-parent" : "no-parent"
        }`}
      >
        {name}
      </CNavTitle>
    );
  }

  if (items && Array.isArray(items) && items.length) {
    return (
      <CNavGroup toggler={name} visible className="sidebar-nav-item--group">
        {items.map(item => (
          <ItemRenderer {...item} key={item.name} hasParent />
        ))}
      </CNavGroup>
    );
  }

  return (
    <CNavItem
      className={`sidebar-nav-item ${hasParent ? "has-parent" : "no-parent"}`}
    >
      <LayoutSubscriber>
        {({ toggleProductNavigation }) => {
          const clickHandle = () => {
            if (onClick && onClick instanceof Function) {
              onClick();
            }

            toggleProductNavigation();
          };
          return (
            <AuthorizedComponent Permission={permissions} MatchAll>
              <LinkRenderer
                {...(url ? { to: url } : {})}
                onClick={clickHandle}
                className="nav-link sidebar-nav-item--link"
              >
                {icon ? (
                  <span className="sidebar-nav-item--icon">{icon}</span>
                ) : null}
                <span
                  className={`sidebar-nav-item--name ${
                    !icon ? "without-icon" : "with-icon"
                  }`}
                >
                  {name}
                </span>
              </LinkRenderer>
            </AuthorizedComponent>
          );
        }}
      </LayoutSubscriber>
    </CNavItem>
  );
};

export default ItemRenderer;
