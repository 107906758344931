import React from "react";
import { FaTags } from "react-icons/fa";
// eslint-disable-next-line import/no-unresolved
import { ObjectAttributesCascader } from "@swan/object-conditions";
import { /* FieldWrapper, */ type FieldProps } from "./partials/FieldWrapper";
import ThemerInput from "./Input";

export type InputProps = FieldProps & {
  placeholder?: string,
  type?: string,
  object?: string,
  onChange?: Function,
};

const Tag = () => <FaTags />;

const InsertTemplateComponent = ({
  onChange,
  object,
}: {
  onChange?: Function,
  object?: string,
}) => (
  <ObjectAttributesCascader
    object={object}
    components={{
      input: Tag,
    }}
    isResourceToString
    onChange={onChange}
  />
);

InsertTemplateComponent.defaultProps = {
  onChange: undefined,
  object: "",
};

const TemplateInput = (props: InputProps) => {
  const { name, object, onChange } = props;
  return (
    <ThemerInput
      type="text"
      id={name}
      name={name}
      {...props}
      suffix={
        // eslint-disable-next-line jsx-a11y/anchor-is-valid
        <InsertTemplateComponent
          object={object || ""}
          onChange={tplTag => {
            if (onChange) {
              onChange({ target: { value: tplTag } });
            }
          }}
        />
      }
    />
  );
};

export default TemplateInput;
