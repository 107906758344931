import * as React from "react";
import {
  // Popover,
  // PopoverHeader,
  // PopoverBody,
  // Button,
  ButtonDropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
} from "reactstrap";
import { Spinner } from "@swan/themer";

type Props = {
  onNoClick?: () => void,
  yesLabel?: string,
  noLabel?: string,
  message?: string,
  onClick: ?() => void,
  children: React.Node,
  color?: string,
  disabled?: boolean,
  className?: string,
  yesDisabled?: boolean,
  noDisabled?: boolean,
  loading?: boolean,
  classNames?: {
    button?: string,
    toggle?: string,
    menu?: string,
    itemYes?: string,
    itemNo?: string,
  },
};
type State = {
  isOpen: boolean,
};

class ButtonConfirm extends React.Component<Props, State> {
  static defaultProps = {
    yesLabel: "Yes",
    noLabel: "No",
    message: "Are you sure?",
    color: "danger",
    onNoClick: () => {},
    disabled: false,
    className: undefined,
    yesDisabled: undefined,
    noDisabled: undefined,
    classNames: undefined,
    loading: false,
  };

  constructor(props: Props) {
    super(props);

    this.state = {
      isOpen: false,
    };
    this.id = `btn-${Math.ceil(Math.random() * 10000)}`;
  }

  onNoClick = () => {
    const { onNoClick } = this.props;
    if (onNoClick) {
      onNoClick();
    }
    this.toggle();
  };

  toggle = () => {
    const { isOpen } = this.state;
    this.setState({
      isOpen: !isOpen,
    });
  };

  id: string;

  render() {
    const {
      yesLabel,
      noLabel,
      message,
      onClick,
      onNoClick,
      children,
      disabled,
      loading,
      className,
      yesDisabled,
      noDisabled,
      classNames,
      ...buttonProps
    } = this.props;
    const { isOpen } = this.state;

    return (
      <React.Fragment>
        <ButtonDropdown
          isOpen={isOpen}
          {...buttonProps}
          id={this.id}
          toggle={this.toggle}
          disabled={disabled}
          className={(classNames || {}).button || undefined}
        >
          <DropdownToggle
            caret
            color={buttonProps.color}
            className={[className, loading ? "loading" : ""].join(" ")}
            disabled={disabled}
          >
            <div className="control-label-container">
              {loading ? <Spinner size="sm" /> : null}{" "}
              <span className="control-label">{children}</span>
            </div>
          </DropdownToggle>
          {!disabled ? (
            <DropdownMenu right>
              <DropdownItem header>{message}</DropdownItem>
              {!yesDisabled ? (
                <DropdownItem
                  className={`text-${buttonProps.color || ""}`}
                  color={buttonProps.color}
                  onClick={onClick}
                >
                  {yesLabel}
                </DropdownItem>
              ) : null}
              {!noDisabled ? (
                <DropdownItem className="text-primary" onClick={this.onNoClick}>
                  {noLabel}
                </DropdownItem>
              ) : null}
            </DropdownMenu>
          ) : null}
        </ButtonDropdown>
      </React.Fragment>
    );
  }
}

export default ButtonConfirm;
