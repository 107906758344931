import React, { createContext } from "react";

export type Layout = {
  toggleProductNavigation: Function,
  isProductNavigationOpen: boolean,
  isMobile: boolean,
  isMobileMenuOpen: boolean,
  formTypePrefix: string,
  layoutType: string,
};

// helps the consumer without provider, but useless here and kept for convention
const contextDefault = {
  isProductNavigationOpen: false,
};

// $FlowFixMe
const { Provider: LayoutContext, Consumer: LayoutSubscriber } = createContext(
  contextDefault
);

const withLayout = (Component: any) => {
  /* eslint-disable react/prefer-stateless-function */
  class WrappedComponent extends React.Component<any> {
    render() {
      return (
        <LayoutSubscriber>
          {layout => <Component {...this.props} layout={layout} />}
        </LayoutSubscriber>
      );
    }
  }
  /* eslint-enable react/prefer-stateless-function */
  return WrappedComponent;
};

export { LayoutContext, LayoutSubscriber, withLayout };
export default { LayoutContext, LayoutSubscriber, withLayout };
