import React from "react";
import styled from "styled-components";

const Wrapper = styled("div")`
  display: flex;
  flex-direction: row;
  padding: 5px;
`;

const CRUMB_COLORS = {
  COMPLETED: "#69abfd",
  DEFAULT: "#cdd1d4",
  ACTIVE: "#30588c",
};
const Crumb = styled("div")`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  font-size: 0.8rem;
  padding: 0.7rem;
  padding-right: 0;
  position: relative;
  margin-right: 5px;

  &::before,
  &::after {
    content: "";
    position: absolute;
    left: 0;
    height: 50%;
    width: 100%;
    background: ${CRUMB_COLORS.DEFAULT};
    // border-right: 2px solid #f4f7fb;
  }

  &::before {
    top: 0px;
    transform: skew(30deg);
    border-bottom: 2px solid ${CRUMB_COLORS.DEFAULT};
  }

  &::after {
    bottom: 0px;
    transform: skew(-30deg);
    border-bottom: 2px solid ${CRUMB_COLORS.DEFAULT};
  }

  &:first-child {
    left: -5px;
    background-color: ${CRUMB_COLORS.DEFAULT};

    &:hover {
    }

    &::before,
    &::after {
      left: 5px;
    }
  }

  &:last-child {
    &::before,
    &::after {
      margin-right: 0;
    }
  }

  &.active-status {
    color: white;

    &::before,
    &::after {
      background: ${CRUMB_COLORS.ACTIVE};
    }

    &::before {
      border-bottom: 2px solid ${CRUMB_COLORS.ACTIVE};
    }

    &::after {
      border-bottom: 2px solid ${CRUMB_COLORS.ACTIVE};
    }

    &:first-child {
      background-color: ${CRUMB_COLORS.ACTIVE};
    }
  }

  &.completed-status {
    &::before,
    &::after {
      background: ${CRUMB_COLORS.COMPLETED};
    }

    &::before {
      border-bottom: 2px solid ${CRUMB_COLORS.COMPLETED};
    }

    &::after {
      border-bottom: 2px solid ${CRUMB_COLORS.COMPLETED};
    }

    &:first-child {
      background-color: ${CRUMB_COLORS.COMPLETED};
    }
  }

  div.crumb-text {
    z-index: 1;
  }
`;

const StatusBreadcrumb = ({ statuses, isActive }: any) => (
  <Wrapper>
    {statuses.map(status => (
      <Crumb
        key={status.id}
        className={isActive(status) ? "active-status" : "completed-status"}
      >
        <div className="crumb-text">{status.label}</div>
      </Crumb>
    ))}
  </Wrapper>
);

export default StatusBreadcrumb;
