import React, { Fragment } from "react";
import { Resource, Service, WebsocketService } from "@swan/api";

// components
import {
  Button,
  ButtonDropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
} from "reactstrap";

import { Spinner, dismissToast, toast } from "@swan/themer";

type Props = {
  object: string,
  printUrl?: string,
  color?: string,
  async?: boolean,
  uniqueId?: string,
  templateName?: string,
  filterByNames?: string | Array<string>,
  label?: string,
  templatesRequestParams?: Object,
  options?: Array<Object>,
};

type State = {
  templates: {
    [id: number | string]: string,
  },
  isDownloading: boolean,
  dropdownOpen: boolean,
  downloadableLink?: string,
};

class ObjectPrintButton extends React.Component<Props, State> {
  static defaultProps = {
    printUrl: undefined,
    color: "primary",
    templateName: undefined,
    uniqueId: undefined,
    async: false,
    filterByNames: undefined,
    label: undefined,
    options: undefined,
    templatesRequestParams: undefined,
  };

  state = {
    templates: {},
    isDownloading: false,
    dropdownOpen: false,
    downloadableLink: undefined,
  };

  componentDidMount() {
    this.loadTemplates();
  }

  toggleDropdown = () => {
    const { dropdownOpen } = this.state;
    this.setState({
      dropdownOpen: !dropdownOpen,
    });
  };

  print = (template: number | string, printOption: Object) => {
    const { printUrl, async, object, uniqueId } = this.props;
    const url = (printOption || {}).printUrl || printUrl;
    if (!url) return;
    const printService = new Service(url);
    this.setState({ isDownloading: true, downloadableLink: undefined });
    if (!async) {
      printService.download("", { template }).then(() => {
        this.setState({ isDownloading: false });
      });
    } else {
      printService.post("", { template }).then(() => {
        const toastId = toast("Please wait your file is getting generated...", {
          autoClose: false,
          type: "info",
        });
        WebsocketService.join(
          `SWAN.DOWNLOAD.${object}.${uniqueId || ""}`,
          "\\Mazrui\\SwanNotification\\Events\\Websocket\\GenericObjectEvent",
          e => {
            if (e.message && e.message.url) {
              window.open(e.message.url, "_blank");
            } else {
              toast(e.message);
            }
            WebsocketService.getEcho().leave(
              `SWAN.DOWNLOAD.${object}.${uniqueId || ""}`
            );

            this.setState({ downloadableLink: e.message.url });
            this.setState({ isDownloading: false });
            dismissToast(toastId);
          }
        );
      });
    }
  };

  loadTemplates() {
    const { object, templateName, templatesRequestParams } = this.props;
    const TemplatesResource = new Resource("templates");
    let routeName = `objects/${object}/print-templates`;
    if (templateName) {
      routeName = `objects/${object}/${templateName}/print-templates`;
    }
    TemplatesResource.get(routeName, templatesRequestParams).then(response => {
      this.setState({
        templates: response,
      });
    });
  }

  render() {
    const {
      templates,
      dropdownOpen,
      isDownloading,
      downloadableLink,
    } = this.state;
    const { color, filterByNames, label, options } = this.props;

    const hasOptions = options && Array.isArray(options) && options.length;

    let templatesFiltered = {};
    if (filterByNames) {
      const filters = !Array.isArray(filterByNames)
        ? [filterByNames]
        : filterByNames;
      Object.keys(templates).forEach(template => {
        if (filters.indexOf(templates[template]) !== -1) {
          templatesFiltered = {
            ...templatesFiltered,
            ...{ [template]: templates[template] },
          };
        }
      });
    } else {
      templatesFiltered = templates;
    }

    if (!Object.keys(templatesFiltered).length) return null;
    if (Object.keys(templatesFiltered).length === 1 && !hasOptions) {
      return (
        <Button
          color={color}
          onClick={() => this.print(Object.keys(templatesFiltered)[0])}
          disabled={isDownloading}
        >
          <span style={{ float: "left" }}>{label || "Print"}</span>
          {isDownloading && (
            <span
              style={{ float: "left", marginLeft: "10px", marginTop: "2px" }}
            >
              <Spinner color="light" size="sm" />
            </span>
          )}
        </Button>
      );
    }

    return (
      <Fragment>
        <ButtonDropdown
          isOpen={dropdownOpen}
          toggle={this.toggleDropdown}
          disabled={isDownloading}
        >
          <DropdownToggle caret color={color} style={{ borderRadius: "17px" }}>
            <span style={{ float: "left" }}> {label || "Print"}</span>
            {isDownloading && (
              <span
                style={{ float: "left", marginLeft: "10px", marginTop: "2px" }}
              >
                <Spinner color="light" size="sm" />
              </span>
            )}
          </DropdownToggle>
          <DropdownMenu>
            {Object.keys(templatesFiltered).map((template, idx) => {
              if (hasOptions) {
                return (
                  <>
                    {(options || []).map((option: any) => (
                      <DropdownItem
                        color={color}
                        onClick={() => this.print(template, option)}
                        disabled={isDownloading}
                      >
                        {option.label || templates[template]}
                      </DropdownItem>
                    ))}

                    {idx ===
                    Object.keys(templatesFiltered).length - 1 ? null : (
                      <DropdownItem divider />
                    )}
                  </>
                );
              }
              return (
                <DropdownItem
                  color={color}
                  onClick={() => this.print(template)}
                  disabled={isDownloading}
                >
                  {templates[template]}
                </DropdownItem>
              );
            })}
          </DropdownMenu>
        </ButtonDropdown>
        <br />
        {downloadableLink && (
          <a
            href={downloadableLink}
            style={{ float: "left", marginTop: "10px", marginBottom: "10px" }}
            target="_blank"
            rel="noopener noreferrer"
          >
            <span>
              If you didn&apos;t get the file yet click here to download.
            </span>
          </a>
        )}
      </Fragment>
    );
  }
}

export default ObjectPrintButton;
