/**
 * A generic service that uses an object name and relationship name to fetch configuration like the api endpoint
 * and returns a service with the route correctly set to access this relationship
 */
import GenericRelationshipResource from "./GenericRelationshipResource";

const RelationshipResourceFactory = (
  object: string,
  relationship: string,
  isComposedEndpoint?: boolean
) => {
  class Res extends GenericRelationshipResource {
    objectName = object;

    isComposedEndpoint = isComposedEndpoint || false;

    relationshipName = relationship;
  }
  return Res;
};
export default RelationshipResourceFactory;
