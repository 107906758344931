import React, { Component } from "react";
import { Input } from "@swan/themer";

import Field from "./partials/Field";
import type { ElementProps } from "./types";
import getUniqueId from "../../utils/getUniqueId";

type State = {
  value: string,
};

type Props = ElementProps & {
  precision: number,
  thousandDelimiter: string,
  decimalDelimiter: string,
};

class Decimal extends Component<Props, State> {
  static defaultProps = {
    precision: 2,
    thousandDelimiter: ",",
    decimalDelimiter: ".",
  };

  constructor(props: Props) {
    super(props);
    const { data, schema } = props;
    this.state = {
      value: this.format((data || {})[schema.attributeName || ""]),
    };
    this.cursor = 0;
  }

  componentDidMount() {
    const { precision } = this.props;
    const { value } = this.state;
    let finalValue = value;
    if (value) {
      finalValue = parseFloat(this.rawValue(value));
      finalValue = parseFloat(Math.round(finalValue * 100) / 100).toFixed(
        precision
      );
    }
    this.setState({
      value: this.format(finalValue),
    });
  }

  componentDidUpdate(prevProps) {
    const { data, schema, onChange } = this.props;
    // const { precision } = this.props;
    const currentPropsValue = (data || {})[schema.attributeName || ""];
    const prevPropsValue = (prevProps.data || {})[schema.attributeName || ""];
    if (currentPropsValue !== prevPropsValue) {
      // used to bypass AJV library null values decimal validation issue
      if (currentPropsValue === null && onChange && schema.attributeName) {
        onChange(schema.attributeName, undefined);
      }
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        value: this.format(currentPropsValue),
      });
    }
  }

  format = (value: any) => {
    const { decimalDelimiter, thousandDelimiter } = this.props;
    if (!value || Number.isNaN(value) || value === "NaN") {
      return "";
    }
    let valueString = value.toString();

    // following line used to keep only one - in the decimal
    const numPartsMinusSeparated = valueString.split("-");
    if (
      Array.isArray(numPartsMinusSeparated) &&
      numPartsMinusSeparated.length > 1
    ) {
      // eslint-disable-next-line
      valueString = `-${numPartsMinusSeparated[1]}`;
    } else {
      // eslint-disable-next-line
      valueString = numPartsMinusSeparated[0];
    }
    valueString += "";
    // following used to separate with decimal delimiter
    const numParts = valueString.split(decimalDelimiter);
    const beforeDecimal = numParts[0]
      .replace(/,/g, "")
      .replace(/(\d)(?=(\d{3})+\b)/g, `$1${thousandDelimiter}`);
    const afterDecimal = numParts.length > 1 ? `.${numParts[1]}` : "";

    return `${beforeDecimal}${afterDecimal}`;
  };

  rawValue = (value: string) => value.replace(/[^0-9.-]/g, "");

  onChange = event => {
    const {
      target: { value },
    } = event;
    const { onChange, schema } = this.props;
    let finalValue;
    let rawValue;
    if (value && !Number.isNaN(value) && value !== "NaN") {
      finalValue = value;
      rawValue = this.rawValue(finalValue);
    }
    this.setState({ value: this.format(finalValue) });
    if (onChange) {
      onChange(schema.attributeName || "", rawValue);
    }
  };

  onBlur = () => {
    const { onChange, schema, precision } = this.props;
    const { value } = this.state;

    let finalValue;
    if (value && !Number.isNaN(value) && value !== "NaN") {
      finalValue = parseFloat(this.rawValue(value));
      finalValue = parseFloat(Math.round(finalValue * 100) / 100).toFixed(
        precision
      );
    }
    this.setState(
      {
        value: this.format(finalValue),
      },
      () => {
        if (onChange) {
          onChange(schema.attributeName || "", finalValue);
        }
      }
    );
  };

  assignRef = ref => {
    this.input = ref;
  };

  cursor: number;

  input: any;

  render() {
    const {
      schema,
      /* onChange, */ error,
      /* data, */ readOnly,
      id,
      hideLabel,
    } = this.props;
    const { value } = this.state;
    return (
      <Input
        {...schema.options || {}}
        type="text"
        id={getUniqueId(id, this.props) || id}
        name={schema.attributeName || ""}
        value={value}
        label={schema.label}
        hideLabel={hideLabel || (schema.options || {}).hideLabel}
        placeholder={schema.label}
        onChange={this.onChange}
        onBlur={this.onBlur}
        error={(error || {})[schema.attributeName || ""]}
        static={readOnly || (schema.options || {}).readOnly}
        getRef={this.assignRef}
      />
    );
  }
}

export default Field(Decimal);
