export const MODULE_NAME = "LMS"; // Module display name
export const MODULE_DISPLAY_NAME = "Learning Management System";
export const MODULE_SHORT_DISPLAY_NAME = "Learning Management System (LMS)";
export const MODULE_URN = "lms"; // module url prefix
export const MODULE_UNIQUE = "lms"; // module unique identifier within swan system and this should be unique throught the system
const apiBaseURL = process.env.REACT_APP_API_URL || "http://localhost:8000";

export default {
  MODULE_DISPLAY_NAME,
  MODULE_SHORT_DISPLAY_NAME,
  MODULE_NAME,
  MODULE_URN,
  MODULE_UNIQUE,
  languages: [
    { id: "en", label: "English" },
    { id: "ar", label: "Arabic" },
    { id: "hi", label: "Hindi" },
    { id: "ur", label: "Urdu" },
  ],
  publicURL: `${apiBaseURL}`,
};
