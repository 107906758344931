import React, { useState } from "react";
import { RiFileExcel2Line } from "react-icons/all";
import { Button } from "reactstrap";
import { toast, Modal } from "@swan/themer";
import Service from "../services/analytics";

const ExportButton = ({ id, label, type }: any) => {
  const [showModal, setShowModal] = useState(false);

  const startExport = () => {
    const url = `export-${type}/${id}`;

    const exportService = new Service();

    exportService
      .get(url)
      .then(() => {
        toast("We will email you the exported file shortly");
        setShowModal(false);
      })
      .catch(error => {
        toast(error.message);
        setShowModal(false);
      });
  };

  const handleClick = () => {
    setShowModal(true);
  };

  return (
    <>
      <Button size="sm" color="success" onClick={handleClick}>
        <RiFileExcel2Line /> {label && <span>Export {label}</span>}
      </Button>
      <Modal
        title="Confirmation"
        isOpen={showModal}
        size="sm"
        disableToggleOnHeader
        buttons={[
          {
            label: "Cancel",
            size: "sm",
            onClick: () => {
              setShowModal(false);
            },
          },
          { label: "Sure", size: "sm", color: "success", onClick: startExport },
        ]}
      >
        <p>Are you sure you want to export this report?</p>
      </Modal>
    </>
  );
};

export default ExportButton;
