export const getJsonSchemaForTimeline = (): // id: string
{
  jsonSchema: {},
  jsonUiSchema: {},
} => {
  const jsonSchema = {
    type: "object",
    properties: {
      title: {
        type: "string",
        title: "Title",
      },
      endpoint: {
        type: "string",
        title: "Timeline Endpoint ('/users/{id}/statuses')",
      },
    },
  };
  const jsonUiSchema = {};

  return {
    jsonSchema,
    jsonUiSchema,
  };
};

export default {
  getJsonSchemaForTimeline,
};
