import React, { useEffect, useState } from "react";
import { Service } from "@swan/api";
import { ChartFactory } from "@swan/statistics";
import Field from "./partials/Field";

const service = new Service();

const ChartLoader = ({ object, data, schema }: any) => {
  const [chartData, setChartData] = useState();
  const [chart, setChart] = useState();

  const { options } = schema || {};
  const { contexts, chart: chartId } = options || {};

  const generateChartData = () => {
    service
      .get(`analytics/charts/generate-chart/${chartId}/${object}/${data.id}`, {
        options: { contexts },
      })
      .then(response => {
        setChartData(response);
      })
      .catch(error => {
        // eslint-disable-next-line no-console
        console.error(error);
      });
  };

  const getChart = () => {
    service
      .get(`analytics/charts/${chartId}`)
      .then(response => {
        setChart(response);
      })
      .catch(error => {
        // eslint-disable-next-line no-console
        console.error(error);
      });
  };

  useEffect(() => {
    generateChartData();
    getChart();
  }, []);

  return (
    <>{chart && chartData && <ChartFactory chart={chart} data={chartData} />}</>
  );
};

export default Field(ChartLoader);
