// setup a default options
const options = {
  type: "formula",
  title: "Untitled",
  hideLabel: false,
  hideField: false,
  enableBackgroundEval: false,
  formulaOutputName: "",
  formulaBody: "return 0",
};

export default {
  uid: "formula",
  attributeId: "",
  formId: "",
  pencilDef: {
    title: "Formula",
    group: "FIELD",
    handleIcon: "FaSuperscript",
    styles: {},
    skipNewField: true,
  },
  options: { ...options },
  // template provided for jsonSchemaProvider
  schema: {
    id: "formula",
    label: "Untitled",
    formId: "",
    type: "formula",
    options: { ...options },
  },
};
