// @flow
import { has, pick } from "lodash";

// type
import {
  type ObjectRelationPropType,
  type PencilType,
  type pencilDef as pencilDefType,
  type ObjectType,
  type WorkflowPropType,
} from "../types";

type objectAttribType = Array<{
  attributeId?: string,
  id: string,
  name?: string,
  label: string,
  type: string,
  pencilDef: pencilDefType,
}>;

function getOneToManyDefinition(id, relation) {
  const { id: objectRelationId } = relation;

  const defaultForm =
    has(relation.child, "forms") && relation.child.forms.length > 0
      ? relation.child.forms[0].id
      : 0;

  return Object.assign(
    {},
    { __relobj: relation },
    {
      // id,
      uid: id,
      label: relation.name,
      relationId: objectRelationId,
      formId: "",
      // definition required for pencil
      pencilDef: {
        title: relation.child.displayName,
        group: "RELOBJ",
        handleIcon: "",
        styles: {},
        skipNewField: true,
      },
      schema: {
        id,
        label: relation.name,
        relationId: objectRelationId,
        formId: "",
        type: "relatedObject",
        // as template we passed it to schema
        options: {
          type: "relatedObject",
          title: relation.child.displayName,
          listMode: "grid",
          editMode: "inline",
          relationType: relation.type,
          relationName: relation.name,
          defaultForm,
          cardViewForm: defaultForm,
          relationMeta: pick(relation, [
            "id",
            "parentObject",
            "childObject",
            "type",
            "name",
            "reverseName",
            "foreignKey",
            "localKey",
          ]),
          validation: {
            type: "array",
            label: relation.name,
          },
          // formId: 0,
        },
      },
    }
  );
}

function getLookupDefinition(id, relation) {
  const { id: objectRelationId } = relation;
  return Object.assign(
    {},
    { __relobj: relation },
    {
      // id,
      uid: id,
      label: relation.name,
      relationId: objectRelationId,
      formId: "",
      // definition required for pencil
      pencilDef: {
        title: relation.name,
        group: "ATTRIB",
        handleIcon: "",
        styles: {},
        skipNewField: true,
      },
      schema: {
        id,
        label: relation.name,
        attributeName: relation.foreignKey,
        relationId: objectRelationId,
        formId: "",
        type: "lookup",
        // as template we passed it to schema
        options: {
          type: "lookup",
          title: relation.name,
          relationType: relation.type,
          relationName: relation.name,
          relationMeta: pick(relation, [
            "id",
            "parentObject",
            "childObject",
            "type",
            "name",
            "reverseName",
            "foreignKey",
            "localKey",
            "options",
          ]),
          enableLookupInPopover: false,
          lookupIcon: "",
          // formId: 0,
        },
      },
    }
  );
}

/**
 * Generate lookup for the reverse of a one to many relationship
 * @param {string} id id
 * @param {Object} relation relationship definition
 */
function getOneToManyLookupDefinition(id, relation) {
  const { id: objectRelationId } = relation;
  return Object.assign(
    {},
    { __relobj: relation },
    {
      // id,
      uid: id,
      label: relation.reverseName,
      relationId: objectRelationId,
      formId: "",
      // definition required for pencil
      pencilDef: {
        title: relation.reverseName,
        group: "ATTRIB",
        handleIcon: "",
        styles: {},
        skipNewField: true,
      },
      schema: {
        id,
        label: relation.reverseName,
        attributeName: relation.foreignKey,
        relationId: objectRelationId,
        formId: "",
        type: "lookup",
        // as template we passed it to schema
        options: {
          type: "lookup",
          title: relation.reverseName,
          relationType: relation.type,
          relationName: relation.reverseName,
          relationMeta: pick(relation, [
            "id",
            "parentObject",
            "childObject",
            "type",
            "name",
            "reverseName",
            "foreignKey",
            "localKey",
            "options",
          ]),
          enableLookupInPopover: false,
          lookupIcon: "",
          // formId: 0,
        },
      },
    }
  );
}

export const getObjectRelationDefinitions = (
  object: ObjectType,
  objectRelation: ObjectRelationPropType
): { [string]: PencilType } => {
  const objectRelationDefinitions = {};
  const childInPolymorphicAdded = []; // Maintain a list of child in polymorphics added to avoid duplication
  if (objectRelation) {
    objectRelation.forEach(relObject => {
      if (relObject.parentObject === object.name) {
        // Relations where the object is parent
        if (
          relObject.child &&
          (relObject.type === "OneToMany" || relObject.type === "Polymorphic")
        ) {
          const { id: objectRelationId } = relObject;
          const objectUnique = `relobj_${objectRelationId}`;
          objectRelationDefinitions[objectUnique] = getOneToManyDefinition(
            objectUnique,
            relObject
          );
        }
      }
      if (
        relObject.type === "LookupValue" &&
        relObject.parentObject === object.name
      ) {
        const { id: objectRelationId } = relObject;
        const objectUnique = `lookup_${objectRelationId}`;
        objectRelationDefinitions[objectUnique] = getLookupDefinition(
          objectUnique,
          relObject
        );
      }
      if (
        relObject.type === "OneToMany" &&
        relObject.childObject === object.name
      ) {
        const { id: objectRelationId } = relObject;
        const objectUnique = `lookup_${objectRelationId}`;
        objectRelationDefinitions[objectUnique] = getOneToManyLookupDefinition(
          objectUnique,
          relObject
        );
      }
      if (
        relObject.type === "Polymorphic" &&
        relObject.childObject === object.name &&
        relObject.polymorphType !== object.polymorph_type &&
        childInPolymorphicAdded.indexOf(relObject.polymorphType) === -1
      ) {
        childInPolymorphicAdded.push(relObject.polymorphType);
        // The object is a child in polymorphic relation
        const { id: objectRelationId } = relObject;
        const objectUnique = `polychild_${objectRelationId}`;
        objectRelationDefinitions[objectUnique] = {
          id: objectUnique,
          type: "polymorphChild",
          label: `${relObject.polymorphType || ""} Select`,
          attributeName: `${relObject.polymorphType || ""}_id`,
          name: `${relObject.polymorphType || ""}_id`,
          pencilDef: {
            title: `${relObject.polymorphType || ""} Select`,
            group: "ATTRIB",
            skipNewField: true,
          },
          options: {
            required: false,
            type: "polymorphChild",
            label: `${relObject.polymorphType || ""} Select`,
          },
          schema: {
            id: objectUnique,
            label: `${relObject.polymorphType || ""} Select`,
            type: "polymorphChild",
            // as template we passed it to schema
            options: {
              type: "polymorphChild",
              required: false,
              title: `${relObject.polymorphType || ""} Select`,
              polymorphType: relObject.polymorphType,
              relationId: objectRelationId,
              relationName: relObject.reverseName.toLowerCase(),
              validation: {
                type: "array",
                label: relObject.name,
              },
            },
          },
        };
      }
    });
  }

  return objectRelationDefinitions;
};

export const getAttributeDefintions = (
  object: ObjectType,
  objectAttrib: objectAttribType
): { [string]: PencilType } => {
  const attributeDefintions = {};
  if (object.polymorph_type) {
    // This is a polymorphic object, add a pencil for parent selection
    const schemaId = `attrib_${object.polymorph_type}_id`;
    attributeDefintions[schemaId] = {
      id: schemaId,
      type: "polymorphParent",
      label: "Parent",
      attributeName: `${object.polymorph_type}_id`,
      name: `${object.polymorph_type}_id`,
      pencilDef: {
        title: `Parent`,
        group: "ATTRIB",
      },
      options: {
        type: "polymorphParent",
        label: "Parent",
        disableCache: false,
      },
      defaultProperties: {
        type: "polymorphParent",
      },
    };
  }
  objectAttrib.forEach(attrib => {
    const { id, label, type } = attrib;

    if (type === "lookup" || type === "parent" || type === "hidden") return;
    // @todo remove this condition when unlinked object-relation and attributes
    // @deprecated the condition !type.startsWith("relobj_"), is in the process of being replaced by newer condition !type === "relatedObject".
    if (!type === "relatedObject" || !type.startsWith("relobj_")) {
      const schemaId =
        typeof id === "string" && id.startsWith("attrib_")
          ? id
          : `attrib_${id}`;
      Object.assign(attrib, {
        id: schemaId,
        attributeId: id,
        pencilDef: {
          title: label,
          group: "ATTRIB",
          handleIcon: type,
        },
      });
      Object.assign(attributeDefintions, { [schemaId]: attrib });
    }
  });

  return attributeDefintions;
};

export const getWorkflowsDefinitions = (
  object: ObjectType,
  workflows: WorkflowPropType
): { [string]: PencilType } => {
  const attributeDefintions = {};

  workflows.forEach(workflow => {
    const { id, name, master_id: masterId } = workflow;

    const types = [
      {
        type: "workflowStatusSummary",
        labelSuffix: "Status Summary",
      },
      {
        type: "workflowActionsButtons",
        labelSuffix: "Actions Buttons",
      },
      {
        type: "workflowTimeline",
        labelSuffix: "Timeline",
      },
    ];
    types.forEach(type => {
      const schemaId = `${type.type}_${id}`;

      const options = {
        ...{
          type: type.type,
          title: `${name} - ${type.labelSuffix}`,
          workflowId: masterId,
        },
        ...(type.type === "workflowActionsButtons"
          ? {
              inlineWorkflowButtons: false,
            }
          : {}),
        // formId: 0,
      };
      const pencil = Object.assign(
        {},
        {
          id: schemaId,
          type: type.type,
          pencilDef: {
            title: `${name} - ${type.labelSuffix}`,
            group: "WORKFLOW",
            skipNewField: true,
          },
          options,
          schema: {
            id: schemaId,
            label: `${name} - ${type.labelSuffix}`,
            workflowId: masterId,
            type: type.type,
            // as template we passed it to schema
            options,
          },
        }
      );
      Object.assign(attributeDefintions, { [schemaId]: pencil });
    });
  });

  return attributeDefintions;
};

export const getDuplicationsDefinitions = (
  object: ObjectType
): { [string]: PencilType } => {
  const button = Object.assign(
    {},
    {
      id: "duplicationButtons",
      pencilDef: {
        title: `Duplicate`,
        group: "DUPLICATIONS",
      },
      options: {
        title: `Duplicate`,
        objectName: object.name,
      },
      schema: {
        id: "duplicationButtons",
        label: "Duplication",
        // as template we passed it to schema
        type: "duplicationButtons",
        // as template we passed it to schema
        options: {
          type: "duplicationButtons",
          objectName: object.name,
        },
      },
    }
  );
  return { duplicationButtons: button };
};

export const getChartsDefinitions = (
  object: ObjectType
): { [string]: PencilType } => {
  const chartBar = {
    id: "chartBar",
    pencilDef: {
      title: `Bar Chart`,
      group: "CHARTS",
    },
    options: {
      title: `Bar Chart`,
      objectName: object.name,
    },
    schema: {
      id: "chartBar",
      label: "Bar Chart ",
      // as template we passed it to schema
      type: "chartBar",
      // as template we passed it to schema
      options: {
        type: "chartBar",
        objectName: object.name,
        customServicePath: undefined,
      },
    },
  };
  const brandCard = {
    id: "brandCard",
    pencilDef: {
      title: `Brand Card`,
      group: "CHARTS",
    },
    options: {
      title: `Brand Card`,
      objectName: object.name,
    },
    schema: {
      id: "brandCard",
      label: "Brand Card",
      // as template we passed it to schema
      type: "brandCard",
      // as template we passed it to schema
      options: {
        type: "brandCard",
        objectName: object.name,
        title: "",
        customServicePath: undefined,
      },
    },
  };
  return { chartBar, brandCard };
};

export const getVersioningDefinitions = (
  object: ObjectType
): { [string]: PencilType } => {
  if (object && object.has_versioning === 1) {
    const pencil = Object.assign(
      {},
      {
        id: "versions",
        editMode: "modal",
        pencilDef: {
          title: "Versions",
          group: "VERSIONS",
        },
        options: {
          title: "Versions",
          objectName: object.name,
        },
        schema: {
          id: "versions",
          label: "Versions",
          // as template we passed it to schema
          type: "versions",
          // as template we passed it to schema
          options: {
            type: "versions",
            objectName: object.name,
          },
        },
      }
    );
    const buttons = Object.assign(
      {},
      {
        id: "versionsButtons",
        pencilDef: {
          title: "Versions Buttons",
          group: "VERSIONS",
        },
        options: {
          title: "Versions Buttons",
          objectName: object.name,
          inlineCreateVersionButton: true,
        },
        schema: {
          id: "versions",
          label: "Versions",
          // as template we passed it to schema
          type: "versionsButtons",
          // as template we passed it to schema
          options: {
            type: "versionsButtons",
            objectName: object.name,
          },
        },
      }
    );
    return { versions: pencil, versionsButtons: buttons };
  }
  return {};
};

export default {};
