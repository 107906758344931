import * as React from "react";
import { Input } from "@swan/themer";

import Field from "./partials/Field";
import type { ElementProps } from "./types";

// utils
import getFormulaResult from "../../utils/getFormulaResult";

class FormulaResult extends React.Component<
  ElementProps,
  {
    value: string,
  }
> {
  state = {
    value: "",
  };

  componentDidUpdate() {
    const { schema, data } = this.props;
    const { value: currentValue } = this.state;
    const value = getFormulaResult(
      {
        body: ((schema || {}).options || {}).formulaBody,
      },
      { record: data }
    );
    if (value && value !== currentValue) {
      this.updateValue(value);
    }
  }

  updateValue(value) {
    const {
      onChange,
      schema: { options },
    } = this.props;
    this.setState({
      value,
    });
    if (onChange && (options || {}).formulaOutputName) {
      onChange((options || {}).formulaOutputName || "", value);
    }
  }

  render() {
    const {
      schema,
      // onChange,
      error,
      // readOnly,
      hideLabel,
    } = this.props;
    const { value } = this.state;
    return (
      <Input
        {...schema.options || {}}
        name=""
        value={value}
        label={schema.label}
        hideLabel={hideLabel || (schema.options || {}).hideLabel}
        placeholder={schema.label}
        error={(error || {})[schema.attributeName || ""]}
        className={(schema.options || {}).enableBackgroundEval ? "hide-it" : ""}
        static
      />
    );
  }
}

export default Field(FormulaResult);
