const options = {
  type: "objectPrint",
  title: "Untitled", // no effect in display
  filterByTemplateNames: "",
  objectPrintBaseURL: "",
  objectPrintEndPoint: "",
  objectPrintLabel: "",
};

export default {
  uid: "objectPrint",
  attributeId: "",
  formId: "",
  pencilDef: {
    title: "Object Print Button",
    group: "FIELD",
    handleIcon: "FaPrint",
    styles: {},
    skipNewField: true,
  },
  options: { ...options },
  // template provided for jsonSchemaProvider
  schema: {
    id: "objectPrint",
    label: "Untitled",
    formId: "",
    type: "objectPrint",
    options: { ...options },
  },
};
