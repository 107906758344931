export default [
  {
    name: "Pages",
    productNav: [
      {
        name: "Modules",
        url: "/dashboard",
      },
    ],
  },
  {
    name: "Dashboard",
    productNav: [
      {
        name: "Modules",
        url: "/dashboard",
      },
    ],
  },
];
