import React, { Component, type Node } from "react";
import { Tooltip } from "reactstrap";
import {
  FaQuestionCircle as HelpIcon,
  FaExclamationCircle as WarnIcon,
  FaMinusCircle as ErrorIcon,
  FaInfoCircle as InforIcon,
} from "react-icons/fa";
import styled from "styled-components";

type Props = {
  id: string,
  children: Node,
  message?: string,
  placement?:
    | "auto"
    | "auto-start"
    | "auto-end"
    | "top"
    | "top-start"
    | "top-end"
    | "right"
    | "right-start"
    | "right-end"
    | "bottom"
    | "bottom-start"
    | "bottom-end"
    | "left"
    | "left-start"
    | "left-end",
  type?: "help" | "warning" | "info" | "danger",
};

type State = {
  isOpen: boolean,
};

const TipWrap = styled.span({
  paddingLeft: ".5rem",
});

const IconWrap = styled.span({});

const Icons = {
  help: HelpIcon,
  warning: WarnIcon,
  info: InforIcon,
  danger: ErrorIcon,
};

class Tip extends Component<Props, State> {
  static defaultProps = {
    placement: "auto",
    type: "help",
    message: undefined,
  };

  state = {
    isOpen: false,
  };

  toggle = () => {
    this.setState(prevState => ({
      isOpen: !prevState.isOpen,
    }));
  };

  render() {
    const { id, placement, type, children, message } = this.props;
    const { isOpen } = this.state;
    const Icon = Icons[type || "help"];
    return (
      <TipWrap>
        <IconWrap id={id}>
          <Icon />
        </IconWrap>
        <Tooltip
          placement={placement}
          isOpen={isOpen}
          toggle={this.toggle}
          target={id}
        >
          {children || message}
        </Tooltip>
      </TipWrap>
    );
  }
}

export default Tip;
