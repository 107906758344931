import { dictionary as types } from "../fileTypes";

export default (group?: string) => {
  const extensions = {};

  let groups = [];
  if (!group) {
    groups = Object.keys(types);
  }
  if (types[group || ""]) {
    groups.push(group);
  }

  groups.forEach(g => {
    const tg = types[g || ""];
    Object.keys(tg).forEach(t => {
      const { ext } = tg[t] || {};
      const { mime } = tg[t] || {};
      if (Array.isArray(ext)) {
        ext.forEach(e => {
          Object.assign(extensions, {
            [e]: mime,
          });
        });
      }

      if (typeof ext === "string") {
        Object.assign(extensions, {
          [ext]: mime,
        });
      }
    });
  });

  return extensions;
};
