import { Component } from "react";

type Props = {
  id?: number,
  defaultValues?: Object,
  listId?: string,
  record?: Object,
  data: ?Array<Object>,
  onChange: (Array<Object>) => Promise<boolean>,
  children: Function,
};

type State = {
  record: Object,
  isSaving: boolean,
  error: string,
  saveResult: ?boolean,
  defaultValues: ?{},
};

class ArrayRecordProvider extends Component<Props, State> {
  static defaultProps = {
    id: undefined,
    defaultValues: [],
    listId: undefined,
    record: null,
  };

  static resetUpdate() {}

  constructor(props: Props) {
    super(props);

    this.state = {
      record: props.record || (props.data || [])[props.id || 0] || {},
      isSaving: false,
      error: "",
      saveResult: null,
      defaultValues: props.defaultValues || {},
    };
    this.updateRecord = this.updateRecord.bind(this);
    this.createRecord = this.createRecord.bind(this);
    this.deleteRecord = this.deleteRecord.bind(this);
  }

  getId(receivedId: number) {
    const { id } = this.props;
    return id || receivedId;
  }

  loadRecord = (recId: number) => {
    const id = this.getId(recId);
    if (id === -1 || !id) {
      const { defaultValues } = this.state;
      this.setState({
        record: {
          id: -1,
          ...defaultValues,
        },
      });
      return;
    }
    const { data } = this.props;
    this.setState({
      record: {
        ...((data || [])[id || 0] || {}),
      },
    });
  };

  updateRecord = (recId: number, values: Object) => {
    const id = this.getId(recId);
    const { data, onChange } = this.props;
    // $FlowFixMe
    return new Promise((resolve, reject) => {
      this.setState(
        {
          isSaving: true,
        },
        () => {
          const updatedData = [...(data || [])];
          updatedData[id] = {
            ...updatedData[id],
            ...values,
          };
          return onChange(updatedData)
            .then(() => {
              this.setState({
                isSaving: false,
              });
              this.refreshList();
              resolve();
            })
            .catch(() => reject());
        }
      );
    });
  };

  createRecord = (values: Object) => {
    const { defaultValues } = this.state;
    const { data, onChange } = this.props;
    // $FlowFixMe
    return new Promise((resolve, reject) => {
      this.setState(
        {
          isSaving: true,
        },
        () => {
          const updatedData = [
            ...(data || []),
            {
              ...defaultValues,
              ...values,
            },
          ];
          return onChange(updatedData)
            .then(() => {
              this.setState({
                isSaving: false,
              });
              this.refreshList();
              resolve();
            })
            .catch(() => reject());
        }
      );
    });
  };

  deleteRecord = (recId: number) => {
    const id = this.getId(recId);
    const { data, onChange } = this.props;
    // $FlowFixMe
    return new Promise((resolve, reject) => {
      this.setState(
        {
          isSaving: true,
        },
        () => {
          const updatedData = [...(data || [])];
          updatedData.splice(id, 1);
          return onChange(updatedData)
            .then(() => {
              this.setState({
                isSaving: false,
              });
              this.refreshList();
              resolve();
            })
            .catch(() => reject());
        }
      );
    });
  };

  refreshList() {
    // Check if a listId is provided, trigger a refresh event
    const { listId } = this.props;
    if (listId) {
      const event = new Event(`${listId}::refresh`);
      window.dispatchEvent(event);
    }
  }

  service: Object;

  render() {
    const { record, isSaving, error, saveResult } = this.state;
    const { children } = this.props;
    return children({
      ...this.props,
      record,
      isSaving,
      error,
      saveResult,
      resetUpdate: this.constructor.resetUpdate,
      loadRecord: this.loadRecord,
      updateRecord: this.updateRecord,
      createRecord: this.createRecord,
      deleteRecord: this.deleteRecord,
    });
  }
}

export default ArrayRecordProvider;
