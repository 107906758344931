/**
 * A generic service that uses an object name and relationship name to fetch configuration like the api endpoint
 * and returns a service with the route correctly set to access this relationship
 */
import Resource from "./resource";
import type { SwanObject } from "./types";

const ReorderResourceFactory = (object: string) => {
  class Res extends Resource {
    objectName = object;

    objectDef: SwanObject;

    parentId: string;

    defaultPrefix: string;

    setDefaultPrefix(p: string) {
      this.defaultPrefix = p;
    }

    setParent(p: string) {
      this.parentId = p;
    }

    /**
     * Override the get api function to dynamically load object definition
     */
    getApi() {
      this.setDefaultPrefix("");
      this.prefix = `${this.defaultPrefix}`;
      return super.getApi();
    }

    /**
     * Send a get request
     */
    reorder(id: number, objectName: string, newOrder: number, params: {} = {}) {
      return this.getApi()
        .then(api =>
          api.post(this.getUrl(`reorder/${id}/${objectName}/${newOrder}`), {
            params,
          })
        )
        .then(response => response.data)
        .catch(error => this.constructor.handleError(error));
    }
    // reorderById

    reorderById(
      id: number,
      objectName: string,
      newOrderItemId: number,
      params: {} = {}
    ) {
      return this.getApi()
        .then(api =>
          api.post(
            this.getUrl(`reorderById/${id}/${objectName}/${newOrderItemId}`),
            {
              params,
            }
          )
        )
        .then(response => response.data)
        .catch(error => this.constructor.handleError(error));
    }
  }
  return Res;
};
export default ReorderResourceFactory;
