// @flow
import React, { Component, type ElementRef } from "react";
import styled from "styled-components";
import { DropDown, Button } from "@swan/themer";

import ObjectAttributesCascader from "./ResourceWidgets/Cascaders/ObjectAttributes";

import { actionsDictionary } from "../actions";
import { ATTRIB, RECORD, FORM } from "../actions/templates";

import { resourceDataToCascade } from "../utils/provideObjectCascade";

type Props = {
  id?: string,
  data?: Object,
  object?: string,
  form?: number,
  filter?: { [string]: string },
  onSave?: Object => any,
  onCancel?: Object => any,
  onDelete?: (Object, boolean) => any,
  canDelete?: boolean,
};

type State = {
  data: Object,
  action: Object,
  actions: Array<Object>,
  // defaultValue: string,
};

const EditorWrap = styled.div({
  display: "flex",
  flexDirection: "column",
  padding: "1rem",
});

const Editor = styled.div({
  flexDirection: "column",
});

const ControlsWrapper = styled.div(({ theme }) =>
  Object.assign(
    {
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      alignItems: "center",
    },
    theme.ControlsWrapper ? { ...theme.ControlsWrapper } : {}
  )
);

class ActionsEditor extends Component<Props, State> {
  static defaultProps = {
    id: undefined,
    data: undefined,
    form: undefined,
    object: undefined,
    filter: undefined,
    canDelete: undefined,
    onSave: () => {},
    onCancel: () => {},
    onDelete: () => {},
  };

  constructor(props: Props) {
    super(props);
    this.state = {
      data: {},
      action: {},
      actions: [],
      // defaultValue: "",
    };
  }

  componentDidMount() {
    const { id, data, filter } = this.props;

    if (id) {
      const action = actionsDictionary.find(actionDic => actionDic.id === id);
      this.setState({
        data,
        action,
      });
    }

    const actions = actionsDictionary.filter(actionDic =>
      filter &&
      typeof filter === "object" &&
      Object.keys(filter).indexOf("type") !== -1
        ? actionDic.type.toLowerCase() === filter.type.toLowerCase()
        : true
    );

    this.setState({
      actions,
      // defaultValue:
      //   actions.length && Object.keys(actions[0]).indexOf("id") !== -1
      //     ? actions[0].id
      //     : "",
    });
  }

  setValueInput: ElementRef<any>;

  setLayoutPathSelection: ElementRef<any>;

  onActionVerbChange = (e: SyntheticEvent<>) => {
    const action =
      actionsDictionary.find(
        // $FlowFixMe
        actionDic => actionDic.id === e.target.value
      ) || {};

    this.setState({
      action,
    });
  };

  onActionDataChange = (resource: Object) => {
    this.setState({
      data: {
        resource,
      },
    });
  };

  // @todo: handling action changes against the verbs, needs to go inside onActionDataChange
  onChangeSetValue = () => {
    this.setState(prevState => ({
      data: {
        ...prevState.data,
        setValue: this.setValueInput.value,
      },
    }));
  };

  // @todo: handling action changes against the verbs, needs to go inside onActionDataChange
  onChangeSetLayoutPath = () => {
    const { object, form } = this.props;
    this.setState(prevState => ({
      data: {
        ...prevState.data,
        resource: {
          ...(prevState.data || {}).resource,
          type: "FORM",
          data: {
            ...((prevState.data || {}).resource || {}).data,
            object,
            form,
          },
        },
        layoutPath: this.setLayoutPathSelection.value,
      },
    }));
  };

  cancelHandler = () => {
    const { action, data } = this.state;
    const { onCancel } = this.props;

    if (onCancel) {
      onCancel({ action, data });
    }
  };

  deleteHandler = () => {
    const { action, data } = this.state;
    const { onDelete } = this.props;

    if (onDelete) {
      onDelete({ action, data }, true);
    }
  };

  saveHandler = () => {
    const { action, data } = this.state;
    const { onSave } = this.props;

    if (onSave) {
      onSave({ action, data });
    }
  };

  render() {
    const { id, object, canDelete } = this.props;
    const { actions, action, data } = this.state;
    const { cascaded } = resourceDataToCascade(data.resource || {});
    if (!cascaded.length || cascaded.length < 1) cascaded.push(String(object));

    return (
      <EditorWrap className="editor-wrapper">
        <h2>Actions</h2>
        <Editor>
          {actions && (
            <DropDown
              label="Action"
              name="actionVerb"
              data={[...actions]}
              onChange={this.onActionVerbChange}
              value={action.id || id || ""}
            />
          )}
          {action && (
            <React.Fragment>
              {(action.template === ATTRIB || action.template === RECORD) && (
                <span>
                  <ObjectAttributesCascader
                    object={object}
                    inputValue={`{!${cascaded.join(".")}}`}
                    cascaded={cascaded}
                    onChange={this.onActionDataChange}
                  />
                </span>
              )}
              {action.template === RECORD && (
                <React.Fragment>
                  <div className="form-group">
                    <input
                      ref={input => {
                        this.setValueInput = input;
                      }}
                      type="text"
                      name="setValue"
                      className="form-control"
                      onChange={this.onChangeSetValue}
                      value={data.setValue ? data.setValue : ""}
                    />
                  </div>
                </React.Fragment>
              )}

              {/* don't want to mess with setValue input, this might end-up with selectable dropdown with fixed options */
              action.template === FORM && (
                <React.Fragment>
                  <div className="form-group">
                    <input
                      ref={input => {
                        this.setLayoutPathSelection = input;
                      }}
                      type="text"
                      name="setLayoutPath"
                      className="form-control"
                      onChange={this.onChangeSetLayoutPath}
                      value={data.layoutPath ? data.layoutPath : ""}
                    />
                  </div>
                </React.Fragment>
              )}
            </React.Fragment>
          )}
        </Editor>
        <ControlsWrapper>
          {canDelete && (
            <Button color="danger" type="button" onClick={this.deleteHandler}>
              Delete
            </Button>
          )}
          <Button color="primary" type="button" onClick={this.saveHandler}>
            Save
          </Button>
          <Button color="secondary" type="button" onClick={this.cancelHandler}>
            Cancel
          </Button>
        </ControlsWrapper>
      </EditorWrap>
    );
  }
}

export default ActionsEditor;
