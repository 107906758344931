import React, { type ComponentType } from "react";
import { isEmpty } from "lodash";
import { type Schema } from "@swan/form-renderer"; //eslint-disable-line
import { Loading, withLayout, type Layout } from "@swan/themer";
import { ObjectService } from "@swan/api"; // eslint-disable-line
import FormService from "../services/formService";
import ConnectedWebsocket from "./ConnectedWebsocket";

type Props = {
  object: string,
  type: string,
  formId?: number,
  form?: Object,
  ServiceFactory: (object: string, data: Object) => Object,
  layout: Layout,
  FormComponent: ComponentType<*>,
  silentLoading?: boolean,
};

type State = {
  form: ?{
    id: number,
    body: Schema,
  },
  validation: Object,
  rules: ?{
    validation: Array<Object>,
    layout: Array<Object>,
  },
};

class DynamicForm extends React.Component<Props, State> {
  static defaultProps = {
    formId: 0,
    form: {},
    silentLoading: false,
  };

  state = {
    form: null,
    rules: undefined,
    validation: undefined,
  };

  componentDidMount() {
    this.refreshForm();
  }

  componentDidUpdate(prevProps: Props) {
    const {
      layout: { layout },
    } = this.props;
    if (layout !== prevProps.layout.layout) {
      this.refreshForm();
    }
  }

  static getValidation(form, validation) {
    // const formLevelValidation = {};
    // const validationResult = validation;

    // if (form && form.options && form.options.validation) {
    //   // get related objects validations
    //   if (form.options.validation.relatedObjects) {
    //     Object.keys(form.options.validation.relatedObjects).forEach(key => {
    //       formLevelValidation[key] =
    //         form.options.validation.relatedObjects[key];
    //     });
    //     validationResult.properties = {
    //       ...validation.properties,
    //       ...formLevelValidation,
    //     };
    //   }
    //   // get other validations...
    // }
    return validation;
  }

  loadRules = () => {
    const { object } = this.props;
    const { form: currentForm } = this.state;
    if (currentForm) {
      ObjectService.getObjectByName(object).then(def => {
        const data = [...(def.rules || [])];
        const rules = {
          validation: data.filter(rule => {
            let filterCondition = rule.type === "validation";
            if (rule.form_id) {
              filterCondition =
                filterCondition && rule.form_id === currentForm.id;
            }

            return filterCondition;
          }),
          layout: data.filter(rule => {
            let filterCondition = rule.type === "layout";
            if (rule.form_id) {
              filterCondition =
                filterCondition && rule.form_id === currentForm.id;
            }

            return filterCondition;
          }),
        };

        this.setState({
          rules,
        });
      });
    }
  };

  refreshForm() {
    const { object, type, formId, form } = this.props;
    const {
      layout: { formTypePrefix },
    } = this.props;

    if (!isEmpty(form)) {
      this.setState(
        {
          form: form.form,
          validation: DynamicForm.getValidation(form.form, form.validation),
        },
        () => {
          this.loadRules();
        }
      );
    } else {
      const service = new FormService();
      const prom = formId
        ? service.getById(formId)
        : service.getObjectForm(object, `${formTypePrefix}${type}`);
      prom
        .then(response => {
          const currentForm = response.form;
          this.setState(
            {
              form: currentForm,
              validation: DynamicForm.getValidation(
                currentForm,
                response.validation
              ),
            },
            () => {
              this.loadRules();
            }
          );
        })
        .catch(() => {});
    }
  }

  render() {
    const { form, rules, validation } = this.state;
    const { silentLoading } = this.props;

    if (!form) {
      if (silentLoading) {
        return null;
      }
      return <Loading />;
    }
    return (
      <ConnectedWebsocket
        schema={form.body}
        rules={rules}
        {...this.props}
        formId={form.id}
        formValidationSchema={validation}
      />
    );
  }
}

export default withLayout(DynamicForm);
