import * as React from "react";
import { withLayout, RemoteSelect } from "@swan/themer";
import ObjectService from "./services/object";
import { Col, Row, Wrapper } from "./Style";

type Props = {
  defaultSourceObject?: string, // the main object which contain the mapped object
  defaultDestinationObject?: string, // the object which the source is going to convert to
  onSourceObjectChange?: (string, any) => void,
  onDestinationObjectChange?: (string, any) => void,
};

type State = {
  sourceObject?: string,
  destinationObject?: string,
};

const objService = new ObjectService();

class ObjectsSelection extends React.Component<Props, State> {
  static defaultProps = {
    defaultSourceObject: undefined,
    defaultDestinationObject: undefined,
    onSourceObjectChange: () => {},
    onDestinationObjectChange: () => {},
    defaultMap: [],
  };

  constructor(props: Props) {
    const { defaultDestinationObject, defaultSourceObject } = props;
    super(props);
    this.state = {
      sourceObject: defaultSourceObject || undefined,
      destinationObject: defaultDestinationObject || undefined,
    };
  }

  onSourceObjectChange = value => {
    const { onSourceObjectChange } = this.props;
    this.setState({
      sourceObject: value,
    });
    if (onSourceObjectChange) {
      onSourceObjectChange(value);
    }
  };

  onDestinationObjectChange = value => {
    const { onDestinationObjectChange } = this.props;
    this.setState({
      destinationObject: value,
    });
    if (onDestinationObjectChange) {
      onDestinationObjectChange(value);
    }
  };

  render() {
    const { sourceObject, destinationObject } = this.state;
    return (
      <Wrapper>
        <Row>
          <Col>
            <RemoteSelect
              label="From Object"
              service={objService}
              labelAttribute="display_name"
              idAttribute="name"
              name="source_object"
              value={sourceObject}
              onChange={this.onSourceObjectChange}
            />
          </Col>
          <Col>
            <RemoteSelect
              label="To Object"
              service={objService}
              labelAttribute="display_name"
              idAttribute="name"
              name="parent_object"
              value={destinationObject}
              onChange={this.onDestinationObjectChange}
            />
          </Col>
        </Row>
      </Wrapper>
    );
  }
}

export default withLayout(ObjectsSelection);
