import React from "react";
import {
  GoTasklist,
  GoQuote,
  IoIosApps,
  // IoMdNotificationsOutline,
  RiGitRepositoryPrivateLine,
  FaFileSignature,
  BiLogOut,
} from "react-icons/all";
import { Wrapper, NavigationContainer, ProfileContainer } from "./Styled";

// context
import { NavConsumer } from "../../../nav";
import { AVATAR_UPLOADER_URL } from "../config";
import withUserData from "../withUserData";
import { UserProfile } from "@swan/themer";

// config
const ADMIN_NAV_IDENTIFIERS = ["Super Admin", "Administration"];

const DefaultMenu = (props: any) => {
  const { itemRenderer: ItemRenderer, userProfile } = props;
  return (
    <NavConsumer>
      {({ mainNav, productNav }) => {
        let items = [];

        items = items.concat([
          {
            name: "All Modules",
            url: "/userzone",
            icon: <IoIosApps />,
          },
          {
            name: "My Activities",
            url: "/userzone/activities",
            type: "item",
            icon: <GoTasklist />,
          },
          {
            name: "Logout",
            url: "/logout",
            type: "item",
            icon: <BiLogOut />,
          },
        ]);

        // 2. platform pages, only on userzone module
        if (((productNav || {}).module || "").toLowerCase() === "userzone") {
          items = items.concat([
            {
              name: "THE PLATFORM",
              header: true,
            },
            {
              name: "About SWAN",
              url: "/userzone/pages/about-swan",
              icon: <GoQuote />,
            },
            {
              name: "Privacy Policy",
              url: "/userzone/pages/privacy-policy",
              icon: <RiGitRepositoryPrivateLine />,
            },
            {
              name: "Terms of Services",
              url: "/userzone/pages/terms-of-service",
              icon: <FaFileSignature />,
            },
          ]);
        }

        // 3. modules producNav
        if (
          ((productNav || {}).module || "").toLowerCase() !== "userzone" &&
          productNav &&
          Array.isArray(productNav.items) &&
          productNav.items.length
        ) {
          items.push({
            name: productNav.moduleDisplayName || productNav.module,
            icon: productNav.icon || <FaFileSignature />,
            items: productNav.items,
          });
        }

        // 4. administration
        // check for permission, tbd
        if (((productNav || {}).module || "").toLowerCase() === "userzone") {
          const adminMenus = [...mainNav.items]
            .filter(item => ADMIN_NAV_IDENTIFIERS.indexOf(item.name) !== -1)
            .sort((a, b) => {
              // whatever without weight, comes to top as floating weight is considered
              return (a.weight || 0) - (b.weight || 0);
            });

          if (adminMenus.length) {
            items.push({
              name: "ADMINISTRATION",
              header: true,
            });
            items = items.concat(adminMenus);
          }
        }

        return (
          <Wrapper>
            <ProfileContainer canChangAvatar={true}>
              <UserProfile
                user={userProfile}
                radius={5}
                backgroundColor="rgb(205, 206, 208)"
                canChangAvatar
                uploadURL={AVATAR_UPLOADER_URL}
              />
              <div className="profile-name">{userProfile.name}</div>
              <div className="profile-job-title">{userProfile.job_title}</div>
            </ProfileContainer>

            <NavigationContainer>
              {items.map((item: Object) => (
                <ItemRenderer {...item} />
              ))}
            </NavigationContainer>
          </Wrapper>
        );
      }}
    </NavConsumer>
  );
};

export default withUserData(DefaultMenu);
