import Element from "./Element";
import EmptyContainer from "./EmptyContainer";
import Grid from "./Grid";
import Row from "./Row";
import Col from "./Col";
import Container from "./Container";
import RowContainer from "./RowContainer";
import ContainerWithTitle from "./ContainerWithTitle";
import Tabs from "./Tabs";
import Tab from "./Tab";

// Fields
import NewField from "./NewField";
import EditField from "./EditField";

export { NewField, EditField };

export default {
  Element,
  EmptyContainer,
  grid: Grid,
  row: Row,
  col: Col,

  tabs: Tabs,
  tab: Tab,
  container: Container,
  rowContainer: RowContainer,
  containerWithTitle: ContainerWithTitle,

  new_field: NewField,
  edit_field: EditField,

  singleLine: EditField,
  multiLine: EditField,
  longText: EditField,
  email: EditField,
  phone: EditField,
  singleSelect: EditField,
  multiSelect: EditField,
  singleSelectCountries: EditField,
  numberField: EditField,
  decimalField: EditField,
  currency: EditField,
  dateField: EditField,
  datetimeField: EditField,
  percent: EditField,
  checkbox: EditField,
  radioSelect: EditField,
  urlField: EditField,
  // fileUpload: EditField,
  pickList: EditField,
  mapField: EditField,
  timeField: EditField,
  tagSelect: EditField,
  dragDropSelect: EditField,

  sequence: EditField,
  relatedObject: EditField,
  lookup: EditField,
  folio: EditField,
  multipleFileUploads: EditField,
  permissionEditor: EditField,
  timeline: EditField,
  resourceDisplay: EditField,
  formula: EditField,
  singleOn: EditField,
  polymorphParent: EditField,
  lastCall: EditField,
  lastMeeting: EditField,
  // Workflow
  workflowStatusSummary: EditField,
  workflowActionsButtons: EditField,
  workflowTimeline: EditField,

  // Versions
  versions: EditField,
  versionsButtons: EditField,
};
