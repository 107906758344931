import { WebsocketService as ApiWebsocketService } from "@swan/api";
import generateActions from "./actions";
import generateSagas from "./sagas";
import generateReducers from "./reducer";
import generateSelectors from "./selectors";
import generateTypes from "./types";

import type {
  ApiService,
  ResourceState,
  ReduxAction,
  WebsocketService as WebsocketServiceType,
} from "../flowTypes/state";

export {
  generateActions,
  generateSagas,
  generateReducers,
  generateSelectors,
  generateTypes,
};

type GeneratedState = {
  reducer: (ResourceState, ReduxAction) => ResourceState,
  actions: { [string]: Function },
  sagas: () => Iterable<any>,
  selectors: { [string]: Function },
  types: { [string]: string },
};
type StateGenerator = (
  Service: ApiService,
  resourceType: string,
  actionsPrefix: string,
  defaultTypes: ?{ [string]: string }
) => GeneratedState;

const generateState: StateGenerator = (
  Service: ApiService,
  resourceType: string,
  actionsPrefix: string,
  defaultTypes: ?{ [string]: string } = null,
  websocketService?: WebsocketServiceType
): GeneratedState => {
  let types;
  if (!defaultTypes) {
    types = generateTypes(resourceType, actionsPrefix);
  } else {
    types = { ...defaultTypes };
  }
  const reducer = generateReducers(resourceType, types);
  const actions = generateActions(resourceType, types);
  const { sagas, setService, getService } = generateSagas(
    Service,
    resourceType,
    types,
    websocketService || ApiWebsocketService
  );
  const selectors = generateSelectors();
  // $FlowFixMe
  return {
    reducer,
    actions,
    sagas,
    selectors,
    types,
    utils: {
      setService,
      getService,
    },
  };
};

export default generateState;
