import styled from "styled-components";

// styled components -- starts
export const Wrapper = styled.div(({ theme }) =>
  Object.assign(
    {
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-start",
      alignItems: "center",
      flex: 1,
      width: "100%",
    },
    theme.Wrapper ? { ...theme.Wrapper } : {}
  )
);

export const Row = styled.div({
  display: "flex",
  flexDirection: "row",
  justifyContent: "flex-start",
  alignItems: "center",
  width: "100%",
});

export const Col = styled.div(({ size, align }) =>
  Object.assign(
    {},
    {
      flex: 1,
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "center",
      width: "100%",
    },
    size ? { flexBasis: `${size}px`, flexGrow: 0 } : {},
    align
      ? { justifyContent: `${align !== "center" ? `flex-${align}` : align}` }
      : {}
  )
);

/**
 *     font-size: 20px;
 font-weight: bold;
 border-bottom: solid 2px;
 padding-bottom: 14px;
 text-transform: uppercase;
 padding-top: 7px;

 */
export const TitleCol = styled.div(({ size, align }) =>
  Object.assign(
    {},
    {
      flex: 1,
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "center",
      width: "100%",
      fontSize: "20px",
      fontWeight: "bold",
      borderBottom: "solid 2px",
      paddingBottom: "14px",
      textTransform: "uppercase",
      paddingTop: "7px",
    },
    size ? { flexBasis: `${size}px`, flexGrow: 0 } : {},
    align
      ? { justifyContent: `${align !== "center" ? `flex-${align}` : align}` }
      : {}
  )
);

export const BoldValue = styled.span({
  fontWeight: "bold",
  paddingLeft: "2px",
  paddingRight: "2px",
});

export default {
  Wrapper,
  Col,
  Row,
  TitleCol,
  BoldValue,
};
