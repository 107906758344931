import Axios from "axios";
import ServiceGenerator from "../serviceGenerator";
import FormStorage from "../storage/FormStorage";
import { type SwanForm } from "../types";

const BaseFormService = ServiceGenerator(Axios, "forms");
const FormService = ServiceGenerator(Axios, "forms");

class CachedFormService extends BaseFormService {
  formsById = {};

  formsByObject = {};

  formsByRoute = {}; // Forms using route

  restored = false;

  loadingPromise = {};

  batchLoadingPromise = null;

  batchObjectLoadingPromise = null;

  idsToLoad = [];

  objectsToLoad = {};

  /**
   * Restore state from local storage
   */
  restoreState() {
    const storage = new FormStorage();
    const store = storage.loadItems();
    if (store) {
      if (store.formsById) {
        this.formsById = store.formsById;
      }
      if (store.formsByRoute) {
        this.formsByRoute = store.formsByRoute;
      }
      if (store.formsByObject) {
        this.formsByObject = store.formsByObject;
      }
    }
    this.restored = true;
  }

  /**
   * Persist loaded permissions to local storage
   */
  persist() {
    const storage = new FormStorage();
    storage.storeItems({
      formsById: this.formsById,
      formsByRoute: this.formsByRoute,
      formsByObject: this.formsByObject,
    });
  }

  executeLoadByIds = () => {
    if (this.batchLoadingPromise) {
      return this.batchLoadingPromise;
    }
    // $FlowFixMe
    this.batchLoadingPromise = new Promise((resolve, reject) => {
      setTimeout(() => {
        if (!this.idsToLoad.length) {
          return resolve({});
        }
        const ids = [...this.idsToLoad];
        this.idsToLoad = [];
        const tld = [];
        ids.forEach(i => {
          // Make sure we don't have them locally
          if (!this.formsById[i]) {
            tld.push(i);
          }
        });

        if (tld.length === 0) {
          return resolve({});
        }

        return super
          .get("byids", { ids: tld })
          .then(response => {
            this.formsById = {
              ...this.formsById,
              ...response,
            };
            resolve(response);
            this.batchLoadingPromise = null;
          })
          .catch(e => {
            reject(e);
            this.batchLoadingPromise = null;
          });
      }, 200);
    });
    return this.batchLoadingPromise;
  };

  /**
   * Load formsById
   */
  loadFormById(id: string): Promise<SwanForm> {
    if (!this.restored) {
      this.restoreState();
    }
    if ((this.formsById || {})[id]) {
      return Promise.resolve(this.formsById[id]);
    }
    if (this.idsToLoad.indexOf(id) === -1) {
      this.idsToLoad.push(id);
    }
    return this.executeLoadByIds().then(() => {
      if ((this.formsById || {})[id]) {
        return Promise.resolve(this.formsById[id]);
      }
      // We got an old batch, wait again
      return this.executeLoadByIds().then(() => {
        if ((this.formsById || {})[id]) {
          return Promise.resolve(this.formsById[id]);
        }
        return Promise.reject();
      });
    });
  }

  executeLoadByObjects = () => {
    if (this.batchObjectLoadingPromise) {
      return this.batchObjectLoadingPromise;
    }
    // $FlowFixMe
    this.batchObjectLoadingPromise = new Promise((resolve, reject) => {
      setTimeout(() => {
        if (!Object.keys(this.objectsToLoad).length) {
          return resolve({});
        }
        const objects = { ...this.objectsToLoad };
        this.objectsToLoad = {};
        const tld = [];
        Object.keys(objects).forEach(key => {
          // Make sure we don't have them locally
          if (!this.formsByObject[key]) {
            tld.push(objects[key]);
          }
        });

        if (tld.length === 0) {
          return resolve({});
        }

        return super
          .get("byobjects", { objects: tld })
          .then(response => {
            this.formsByObject = {
              ...this.formsByObject,
              ...response,
            };
            resolve(response);
            this.batchObjectLoadingPromise = null;
          })
          .catch(e => reject(e));
      }, 200);
    });
    return this.batchObjectLoadingPromise;
  };

  /**
   * Load formsById
   */
  loadFormByObject(object: string, type: string): Promise<SwanForm> {
    const key = `${object}/${type}`;
    if (!this.restored) {
      this.restoreState();
    }
    if ((this.formsByObject || {})[key]) {
      return Promise.resolve(this.formsByObject[key]);
    }
    if (!this.objectsToLoad[key]) {
      this.objectsToLoad[key] = {
        object,
        type,
      };
    }
    return this.executeLoadByObjects().then(() => {
      if ((this.formsByObject || {})[key]) {
        return Promise.resolve(this.formsByObject[key]);
      }
      // We got an old batch, wait again
      return this.executeLoadByObjects().then(() => {
        if ((this.formsByObject || {})[key]) {
          return Promise.resolve(this.formsByObject[key]);
        }
        return Promise.reject(new Error("Form not found"));
      });
    });
  }

  /**
   * Load formsByRoute
   */
  loadFormByRoute(route: string): Promise<SwanForm> {
    if (!this.restored) {
      this.restoreState();
      if ((this.formsByRoute || {})[route])
        return Promise.resolve(this.formsByRoute[route]);
    }
    if (this.loadingPromise[route]) {
      return this.loadingPromise[route];
    }
    this.loadingPromise[route] = super.get(route).then((response: any) => {
      this.formsByRoute = this.formsByRoute || {};
      this.formsByRoute[route] = response;
      this.persist();
      this.loadingPromise[route] = null;
      return this.formsByRoute[route];
    });
    return this.loadingPromise[route];
  }

  getById(id: string): Promise<SwanForm> {
    if (!(this.formsById || {})[id]) {
      return this.loadFormById(id);
    }
    return Promise.resolve(this.formsById[id]);
  }

  get(route: string): Promise<SwanForm> {
    if (!(this.formsByRoute || {})[route]) {
      return this.loadFormByRoute(route);
    }
    return Promise.resolve(this.formsByRoute[route]);
  }

  getObjectForm(object: string, type: string) {
    const key = `${object}/${type}`;
    if (!(this.formsByObject || {})[key]) {
      return this.loadFormByObject(object, type);
    }
    return Promise.resolve(this.formsByObject[key]);
  }
}

export const CacheFormServiceInstance = new CachedFormService();

// $FlowFixMe
FormService.prototype.getById = function getById(id: string) {
  return CacheFormServiceInstance.getById(id);
};

// $FlowFixMe
FormService.prototype.getObjectForm = function getObjectForm(
  object: string,
  type: string
) {
  return CacheFormServiceInstance.getObjectForm(object, type);
};

// $FlowFixMe
FormService.prototype.get = function get(route: string) {
  return CacheFormServiceInstance.get(route);
};

export default FormService;
