import React, { type AbstractComponent, type ComponentType } from "react";
import SwanContext from ".";
import { type SwanContextType } from "../flowTypes/context";

type InjectedProps = {
  SwanContext?: SwanContextType,
};

function withSwanContext<T: {}>(
  Component: AbstractComponent<T>
): ComponentType<$Diff<T, InjectedProps>> {
  const WrappedComponent = (props: T) => (
    <SwanContext.Consumer>
      {(Context: SwanContextType) => (
        <Component {...props} SwanContext={Context} />
      )}
    </SwanContext.Consumer>
  );
  return WrappedComponent;
}

export default withSwanContext;
