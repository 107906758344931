import React, { useState } from "react";

import { RecordPermissionList } from "@swan/helpers";
import { AuthorizedComponent, OwnerComponent } from "@swan/auth";
import { Button, Modal } from "@swan/themer";
import Field from "./partials/Field";

export type Props = {
  data: any,
  object: string,
  permissionName: string,
  schema: {
    options: {
      permissionName: string,
    },
  },
};

const PermissionButton = ({ isModalOpen, toggleModal, object, data }) => (
  <>
    <Button color="primary" disabled={isModalOpen} onClick={toggleModal}>
      <span style={{ float: "left" }}>Edit Permission</span>
    </Button>
    <Modal
      title="Edit Permissions"
      isOpen={isModalOpen}
      toggle={toggleModal}
      size="l"
    >
      <RecordPermissionList object={object} recordId={data.id} />
    </Modal>
  </>
);

const PermissionEditor = ({ data, object, schema }: Props) => {
  const {
    options: { permissionName },
  } = schema;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };
  return (
    <>
      {!permissionName ||
        (permissionName === "" && (
          <OwnerComponent ownerId={data.owner_id}>
            <PermissionButton
              isModalOpen={isModalOpen}
              toggleModal={toggleModal}
              object={object}
              data={data}
            />
          </OwnerComponent>
        ))}
      {permissionName &&
        (permissionName !== "" && (
          <AuthorizedComponent Permission={permissionName}>
            <PermissionButton
              isModalOpen={isModalOpen}
              toggleModal={toggleModal}
              object={object}
              data={data}
            />
          </AuthorizedComponent>
        ))}
    </>
  );
};

export default Field(PermissionEditor);
