export default {
  uid: "mapField",
  attributeId: "",
  formId: "",
  pencilDef: {
    title: "Folio",
    group: "FIELD",
    handleIcon: "FaHashtag",
    styles: {},
  },
  options: {
    title: "Untitled",
    type: "mapField",
    description: "",
    help: "",
    classNames: "",
    readonly: false,
    // enableHistoryTracking: false,
  },
};
