// @flow
/**
 * API
 *
 * layoutProvider: (config: LayoutProviderConfigType) => React.ComponentType
 */
import React, { type Node, type ComponentType } from "react";

// layouts
import layoutsLibrary from "../layouts";

// types

export type LayoutProviderConfigType = {
  name: string,
  props?: any,
};
export type LayoutComponentType = {
  sidebarHeader: { component: ComponentType<any>, secondary?: any },
  pageHeader: { component: ComponentType<any>, secondary?: any },
  productNavigation: { component: ComponentType<any>, items?: Array<any> },
  globalNavigation: {
    primaryItems?: Array<Object>,
    secondaryItems?: any,
    itemRenderer: ComponentType<any>,
    activeItem?: string,
  },
  children: Node,
};

// default values
const defaultConfig = {
  name: "default",
  props: {},
};

export default (layoutConfig: LayoutProviderConfigType = defaultConfig) => (
  layoutProps: LayoutComponentType
) => {
  const Component =
    layoutsLibrary[layoutConfig.name].LayoutProvider ||
    layoutsLibrary.defaultLayout ||
    null;

  if (!Component) return null;

  return (
    <Component
      name={layoutConfig.name}
      {...layoutProps}
      layoutConfig={layoutConfig.props}
    />
  );
};
