import { schemaTypes } from "./index";
import type { Schema } from "../../../constants/flowTypes";
import type { Attribute } from "./flowTypes";

// load schema to state to maintain through a redux state
export const loadSchema = (schema: {}) => ({
  type: schemaTypes.SCHEMA_READY,
  payload: schema,
});

// update the schema of a given element
export const updateElementSchema = (id: string, schema: Schema) => ({
  type: schemaTypes.UPDATE_ELEMENT,
  payload: {
    id,
    schema,
  },
});

// replace element
export const replaceElement = (id: string, schema: Schema) => ({
  type: schemaTypes.REPLACE_ELEMENT,
  payload: {
    id,
    schema,
  },
});

// update the schema following a drop
export type MoveElement = (
  id: string,
  destinationId: string,
  destinationIndex: number
) => {
  type: string,
  payload: {},
};

export const moveElement = (
  id: string,
  destinationId: string,
  destinationIndex: number
) => ({
  type: schemaTypes.MOVE_ELEMENT,
  payload: {
    id,
    destinationId,
    destinationIndex,
  },
});

export type AddElement = (
  schema: Schema,
  destinationId: string,
  destinationIndex: number
) => { type: string, payload: {} };

// add element to schema
export const addElement = (
  schema: Schema,
  destinationId: string,
  destinationIndex: number
) => ({
  type: schemaTypes.ADD_ELEMENT,
  payload: {
    schema,
    destinationId,
    destinationIndex,
  },
});

// Add new attribute
export const addNewAttribute = (attr: Attribute) => ({
  type: schemaTypes.ADD_NEW_ATTRIBUTE,
  payload: attr,
});

// walk through schema that snap the diff
export const schemaWalk = (
  upholdData: { fields: {}, layouts: {} },
  currentSchema: Schema
) => ({
  type: schemaTypes.SCHEMA_WALK,
  payload: {
    upholdData,
    currentSchema,
  },
});

// save schema updates local states
export const saveSchema = () => {
  // TODO
};

export const removeSchemaNode = (destinationId: string) => ({
  type: schemaTypes.SCHEMA_DESTROY,
  payload: destinationId,
});

export default { loadSchema };
