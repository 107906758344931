import React, { useState } from "react";
import ColorPicker from "../../../../ColorPicker";
import { Button } from "../Styled";

export default ({ handleChange, label }: any) => {
  const [isOpen, setIsOpen] = useState(false);
  const [color, setColor] = useState("#000000");

  const onColorChange = event => {
    setColor(event.target.value);
    handleChange(event.target.value);
    setIsOpen(false);
  };

  return (
    <div className="d-inline position-relative">
      <Button onClick={() => setIsOpen(!isOpen)}>{label}</Button>
      {isOpen && (
        <div
          className="position-absolute d-flex"
          style={{ zIndex: 99, left: 0, width: "200px" }}
        >
          <ColorPicker name="color" value={color} onChange={onColorChange} />
        </div>
      )}
    </div>
  );
};
