import * as React from "react";
import Elements, { EditField as FallbackComponent } from "./index";

import type { Props } from "./types";

class Element extends React.Component<Props> {
  static toJson() {
    return "";
  }

  render() {
    const { schema, id, index } = this.props;
    if (schema.type) {
      const Component = Elements[schema.type] || FallbackComponent;
      if (Component) {
        return <Component schema={schema} id={id} index={index} />;
      }
    }
    return null;
  }
}

export default Element;
