import React from "react";
import {
  HiOutlineCode,
  FiBold,
  FiUnderline,
  FiAlignLeft,
  BiColorFill,
  AiOutlineFontColors,
  FiItalic,
  FaListUl,
  FaListOl,
  RiHeading,
  HiOutlineMinus,
  ImTable2,
  HiOutlineArrowNarrowLeft,
  HiOutlineArrowNarrowRight,
  AiOutlineLineHeight,
} from "react-icons/all";
import { Button, Menu } from "../Styled";
import ColorPicker from "./ColorPicker";
import Dropdown from "./Dropdown";

const MenuBar = ({ editor }: any) => {
  if (!editor) {
    return null;
  }

  return (
    <Menu>
      <Button
        onClick={() =>
          editor
            .chain()
            .focus()
            .toggleBold()
            .run()
        }
        active={editor.isActive("bold")}
      >
        <FiBold size="1.3em" />
      </Button>
      <Button
        onClick={() =>
          editor
            .chain()
            .focus()
            .toggleItalic()
            .run()
        }
        active={editor.isActive("italic")}
      >
        <FiItalic size="1.3em" />
      </Button>

      <Button
        onClick={() =>
          editor
            .chain()
            .focus()
            .toggleUnderline()
            .run()
        }
        active={editor.isActive("underline")}
      >
        <FiUnderline size="1.3em" />
      </Button>
      <ColorPicker
        label={<BiColorFill size="1.3rem" />}
        handleChange={color => editor.commands.setHighlight({ color })}
      />
      <ColorPicker
        label={<AiOutlineFontColors size="1.3rem" />}
        handleChange={color =>
          editor
            .chain()
            .focus()
            .setColor(color)
            .run()
        }
      />
      <Dropdown
        label={<AiOutlineLineHeight size="1.3em" />}
        menuItems={[
          {
            label: "1",
            handleClick: () =>
              editor
                .chain()
                .focus()
                .setLineHeight(1)
                .run(),
            active: editor.isActive({ lineHeight: 1 }),
          },
          {
            label: "1.15",
            handleClick: () =>
              editor
                .chain()
                .focus()
                .setLineHeight(1.15)
                .run(),
            active: editor.isActive({ lineHeight: 1.15 }),
          },
          {
            label: "1.5",
            handleClick: () =>
              editor
                .chain()
                .focus()
                .setLineHeight(1.5)
                .run(),
            active: editor.isActive({ lineHeight: 1.5 }),
          },
          {
            label: "2",
            handleClick: () =>
              editor
                .chain()
                .focus()
                .setLineHeight(2)
                .run(),
            active: editor.isActive({ lineHeight: 2 }),
          },
          {
            label: "2.5",
            handleClick: () =>
              editor
                .chain()
                .focus()
                .setLineHeight(2.5)
                .run(),
            active: editor.isActive({ lineHeight: 2.5 }),
          },
          {
            label: "3",
            handleClick: () =>
              editor
                .chain()
                .focus()
                .setLineHeight(3)
                .run(),
            active: editor.isActive({ lineHeight: 3 }),
          },
        ]}
      />
      <Dropdown
        label={<RiHeading size="1.3em" />}
        menuItems={[
          {
            label: "H1",
            handleClick: () =>
              editor
                .chain()
                .focus()
                .toggleHeading({ level: 1 })
                .run(),
            active: editor.isActive("heading", { level: 1 }),
          },
          {
            label: "H2",
            handleClick: () =>
              editor
                .chain()
                .focus()
                .toggleHeading({ level: 2 })
                .run(),
            active: editor.isActive("heading", { level: 2 }),
          },
          {
            label: "H3",
            handleClick: () =>
              editor
                .chain()
                .focus()
                .toggleHeading({ level: 3 })
                .run(),
            active: editor.isActive("heading", { level: 3 }),
          },
          {
            label: "H4",
            handleClick: () =>
              editor
                .chain()
                .focus()
                .toggleHeading({ level: 4 })
                .run(),
            active: editor.isActive("heading", { level: 4 }),
          },
        ]}
      />

      <Dropdown
        label={<FiAlignLeft size="1.3em" />}
        menuItems={[
          {
            label: "Left",
            handleClick: () =>
              editor
                .chain()
                .focus()
                .setTextAlign("left")
                .run(),
            active: editor.isActive({ textAlign: "left" }),
          },
          {
            label: "Center",
            handleClick: () =>
              editor
                .chain()
                .focus()
                .setTextAlign("center")
                .run(),
            active: editor.isActive({ textAlign: "center" }),
          },
          {
            label: "Right",
            handleClick: () =>
              editor
                .chain()
                .focus()
                .setTextAlign("right")
                .run(),
            active: editor.isActive({ textAlign: "right" }),
          },
        ]}
      />
      <Button
        onClick={() =>
          editor
            .chain()
            .focus()
            .toggleBulletList()
            .run()
        }
        active={editor.isActive("bulletList")}
      >
        <FaListUl size="1.3em" />
      </Button>
      <Button
        onClick={() =>
          editor
            .chain()
            .focus()
            .toggleOrderedList()
            .run()
        }
        active={editor.isActive("orderedList")}
      >
        <FaListOl size="1.3em" />
      </Button>
      <Dropdown
        label={<ImTable2 size="1.3em" />}
        menuItems={[
          {
            label: "New Table",
            handleClick: () =>
              editor
                .chain()
                .focus()
                .insertTable({ rows: 3, cols: 3 })
                .run(),
          },
          {
            label: "New Row",
            handleClick: () =>
              editor
                .chain()
                .focus()
                .addRowAfter()
                .run(),
          },
          {
            label: "Delete Row",
            handleClick: () =>
              editor
                .chain()
                .focus()
                .deleteRow()
                .run(),
          },
          {
            label: "New Column",
            handleClick: () =>
              editor
                .chain()
                .focus()
                .addColumnAfter()
                .run(),
          },
          {
            label: "Delete Column",
            handleClick: () =>
              editor
                .chain()
                .focus()
                .deleteColumn()
                .run(),
          },
        ]}
      />
      <Button
        onClick={() =>
          editor
            .chain()
            .focus()
            .toggleCodeBlock()
            .run()
        }
        active={editor.isActive("codeBlock")}
      >
        <HiOutlineCode size="1.3em" />
      </Button>
      <Button
        onClick={() =>
          editor
            .chain()
            .focus()
            .setHorizontalRule()
            .run()
        }
      >
        <HiOutlineMinus size="1.3em" />
      </Button>
      <Button
        onClick={() =>
          editor
            .chain()
            .focus()
            .undo()
            .run()
        }
      >
        <HiOutlineArrowNarrowLeft size="1.3em" />
      </Button>
      <Button
        onClick={() =>
          editor
            .chain()
            .focus()
            .redo()
            .run()
        }
      >
        <HiOutlineArrowNarrowRight size="1.2em" />
      </Button>
    </Menu>
  );
};

export default MenuBar;
