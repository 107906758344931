import t from "../../../../i18n/t";

export default {
  latitude: t("latitude"),
  longitude: t("longitude"),
  search_string: t("search_string"),
  street_address: t("street_address"),
  route: t("route"),
  intersection: t("intersection"),
  political: t("political"),
  country: t("country"),
  administrative_area_level_1: t("administrative_area_level_1"),
  administrative_area_level_2: t("administrative_area_level_2"),
  administrative_area_level_3: t("administrative_area_level_3"),
  administrative_area_level_4: t("administrative_area_level_4"),
  administrative_area_level_5: t("administrative_area_level_5"),
  colloquial_area: t("colloquial_area"),
  locality: t("locality"),
  sublocality: t("sublocality"),
  sublocality_level_1: t("sublocality_level_1"),
  sublocality_level_2: t("sublocality_level_2"),
  sublocality_level_3: t("sublocality_level_3"),
  sublocality_level_4: t("sublocality_level_4"),
  sublocality_level_5: t("sublocality_level_5"),
  neighborhood: t("neighborhood"),
  premise: t("premise"),
  subpremise: t("subpremise"),
  postal_code: t("postal_code"),
  natural_feature: t("natural_feature"),
  airport: t("airport"),
  park: t("park"),
  point_of_interest: t("point_of_interest"),
};
