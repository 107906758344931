import { withRouter, type Location } from "react-router-dom";

type Props = {
  route: string,
  location: Location,
  children: Function,
};

const FormPropsFromRoute = (props: Props) => {
  const {
    location: { state },
    route,
    children,
  } = props;
  let subProps = {};
  if (state) {
    if (state.formParameters) {
      if (state.formParameters[route]) {
        subProps = { ...state.formParameters[route] };
      }
    }
  }
  return children(subProps);
};

export default withRouter(FormPropsFromRoute);
