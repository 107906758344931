import React, { PureComponent } from "react";
import CRMModule from "@swan/crm";
import LMSModule from "@swan/lms";
import HyperionModule from "@swan/hyperion";
import INSIGHTSModule from "@swan/insights";
import ReportingModule from "@swan/reporting";
import ServiceDeskModule from "@swan/servicedesk";
import HCMModule from "@swan/hcm";
import USERZONEModule from "@swan/userzone";
import AdministrationModule from "@swan/administration";
import SuperadminModule from "@swan/superadmin";
import DigiSignModule from "@swan/digisign";
import AnalyticsModule from "@swan/analytics";
import PPCTieringToolModule from "@swan/ppctieringtool";

const SwanModulesMap = {
  servicedesk: ServiceDeskModule,
  hcm: HCMModule,
  crm: CRMModule,
  lms: LMSModule,
  hyperion: HyperionModule,
  businessIntelligence: ReportingModule,
  insights: INSIGHTSModule,
  digisign: DigiSignModule,
  analytics: AnalyticsModule,
  superadmin: SuperadminModule,
  admin: AdministrationModule,
  ppctieringtool: PPCTieringToolModule,
};

type State = {
  loaded: Array<string>,
};
type Props = {
  onModulesLoaded: () => void,
};

export class Modules extends PureComponent<Props, State> {
  loadedModules = {};

  onModuleReady(mod: string) {
    const { onModulesLoaded } = this.props;
    this.loadedModules[mod] = true;
    if (
      onModulesLoaded &&
      Object.keys(this.loadedModules).length ===
        Object.keys(SwanModulesMap).length
    ) {
      onModulesLoaded();
    }
  }

  render() {
    return (
      <React.Fragment>
        <USERZONEModule
          key="userzone"
          onModuleReady={() => this.onModuleReady("userzone")}
        />
        <ServiceDeskModule
          key="servicedesk"
          onModuleReady={() => this.onModuleReady("servicedesk")}
        />
        <HCMModule key="hcm" onModuleReady={() => this.onModuleReady("hcm")} />

        <LMSModule key="lms" onModuleReady={() => this.onModuleReady("lms")} />

        <CRMModule key="crm" onModuleReady={() => this.onModuleReady("crm")} />

        <DigiSignModule
          key="digisign"
          onModuleReady={() => this.onModuleReady("digisign")}
        />

        <HyperionModule
          key="hyperion"
          onModuleReady={() => this.onModuleReady("hyperion")}
        />

        <ReportingModule
          key="businessIntelligence"
          onModuleReady={() => this.onModuleReady("businessIntelligence")}
        />

        <AnalyticsModule
          key="analytics"
          onModuleReady={() => this.onModuleReady("analytics")}
        />

        <INSIGHTSModule
          key="insights"
          onModuleReady={() => this.onModuleReady("insights")}
        />

        <AdministrationModule
          key="admin"
          onModuleReady={() => this.onModuleReady("admin")}
        />

        <SuperadminModule
          key="superadmin"
          onModuleReady={() => this.onModuleReady("superadmin")}
        />

        <PPCTieringToolModule
          key="ppctieringtool"
          onModuleReady={() => this.onModuleReady("ppctieringtool")}
        />
      </React.Fragment>
    );
  }
}

export default Modules;
