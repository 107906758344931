import * as React from "react";
import AuthContext from "./context";
import PermissionsStorage from "./storage";
import AuthService from "./AuthService";

type Props = {
  children: React.Node,
};
type State = {};

class AuthProvider extends React.Component<Props, State> {
  static onLogout = () => {
    const storage = new PermissionsStorage();
    storage.destroy();
  };

  static watchLogout() {
    window.addEventListener("logout", AuthProvider.onLogout);
  }

  static stopWatchingLogout() {
    window.removeEventListener("logout", AuthProvider.onLogout);
  }

  constructor(props: Props) {
    super(props);

    this.authService = new AuthService();
  }

  componentDidMount() {
    AuthProvider.watchLogout();
    this.authService.restoreState();
  }

  componentWillUnmount() {
    AuthProvider.stopWatchingLogout();
  }

  /**
   * Given array of permission names, will return a map including each permission item to wether it is allowed or not
   */
  getPermissions = (permissions: Array<string>) =>
    this.authService.getPermissions(permissions);

  authService: AuthService;

  render() {
    const { children } = this.props;
    return (
      <AuthContext.Provider
        value={{
          getPermissionsByGroup: this.authService.getPermissionsByGroup.bind(
            this.authService
          ),
          getPermissions: this.getPermissions.bind(this.authService),
          isAllowed: this.authService.isAllowed.bind(this.authService),
          isAllowedAll: this.authService.isAllowedAll.bind(this.authService),
          isAllowedAny: this.authService.isAllowedAny.bind(this.authService),
        }}
      >
        {children}
      </AuthContext.Provider>
    );
  }
}

export default AuthProvider;
