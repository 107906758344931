// @flow
import * as React from "react";
import { Modal } from "@swan/themer";

import type { PencilDefOptions as PencilDefOptionsType } from "../types";

export const withModalProvider = (ModalContent: any) => ({
  isOpen,
  onCloseHandler,
  onSubmitHandler,
  pencilDefOptions,
  initialFormData,
  title,
  pencilType,
  schemaId,
  toggleHandler,
  ...props
}: {
  isOpen: boolean,
  onCloseHandler: () => any,
  onSubmitHandler: ({ formData: PencilDefOptionsType }) => any,
  initialFormData: any,
  title: string,
  pencilDefOptions: any,
  pencilType: string,
  schemaId: string,
  toggleHandler: Function,
}) => (
  <Modal isOpen={isOpen} toggle={toggleHandler} title={title || `Modal Form`}>
    <ModalContent
      pencilType={pencilType}
      pencilDefOptions={pencilDefOptions}
      onSubmitHandler={onSubmitHandler}
      onCloseHandler={onCloseHandler}
      initialFormData={initialFormData}
      schemaId={schemaId}
      {...props}
    />
  </Modal>
);

export default {
  withModalProvider,
};
