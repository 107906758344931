// import { getStore } from "../../state/store";

export const getJsonSchemaForWorkflow = (): {
  jsonSchema: {},
  jsonUiSchema: {},
} => {
  const jsonSchema = {
    type: "object",
    properties: {
      title: {
        type: "string",
        title: "Field Label",
      },
      workflowId: {
        type: "integer",
        title: "Workflow ID",
      },
      overrideWorkflowId: {
        type: "string",
        title:
          "Override workflow id with multiples id separated (temporary until org forms enabled)",
      },
    },
  };

  return {
    jsonSchema,
    jsonUiSchema: {},
  };
};

export default {
  getJsonSchemaForWorkflow,
};
