export default {
  FontFamily: {
    Regular: "Helvetica, Arial, sans-serif",
    Monospace: "",
    Serif: "",
    Sansserif: "",
  },
  FontSizeRegular: 12,
  FontWeightRegular: 400,
  FontWeightBold: 900,
};
