// @flow
import * as React from "react";
import { Droppable } from "react-beautiful-dnd";
import { connect } from "react-redux";
import styled, { withTheme } from "styled-components";
import { Button } from "reactstrap";

// state
// import { appActions } from "../state/reducers/app/index";
import { schemaActions } from "../state/reducers/schema";
import { schemaCompare } from "../state/utils/schema";

// types
import type { Schema } from "../constants/flowTypes";

// components
import Elements from "./Elements";
import { ArtWrapper } from "./Art";
import ConditionalDroppable from "./DragDrop/ConditionalDroppable";

// styled
const ArtBoardWrapper = styled.div(({ theme, loading }) =>
  Object.assign(
    {},
    theme.ArtBoardWrapper ? { ...theme.ArtBoardWrapper } : {},
    loading
      ? {
          /** some styles */
        }
      : {}
  )
);

const ArtsContainer = styled.div(({ theme }) =>
  theme.ArtsContainer ? { ...theme.ArtsContainer } : {}
);

const ArtsContainerLoading = styled.div(({ theme }) =>
  theme.ArtsContainerLoading ? { ...theme.ArtsContainerLoading } : {}
);

const ArtBoardButtons = styled.div({
  display: "flex",
  justifyContent: "flex-end",
  alignItems: "center",
  padding: "1rem",
});

const EmptyMessage = styled.div(({ isDraggingOver }) => ({
  display: `${isDraggingOver ? "none" : "flex"}`,
  justifyContent: "center",
  alignItems: "center",
  fontSize: "1rem",
  color: "#5a6872",
  height: "100%",
  flex: 1,
}));

// component types
type Props = {
  artDefs: {},
  app: {
    arts: {
      ACTIVE: {},
    },
    pencils: Object,
  },
  schema: Schema,
  loading: boolean,
  schemaWalk: Function,
  upholdData: { fields: { [string]: Schema }, layouts: { [string]: Schema } },
  save: (schema: Object, fieldsChanges: Object) => void,
  onCancel: ?() => void,
  buttons: Array<{
    label: string,
    onClick: Function,
  }>,
};

class ArtBoard extends React.PureComponent<Props> {
  schemaWalker = e => {
    e.preventDefault();
    const { schema, upholdData, save, schemaWalk } = this.props;
    if (save) {
      const diff = schemaCompare(upholdData, schema);
      save(schema, diff);
    } else {
      schemaWalk(upholdData, schema);
    }
  };

  render() {
    const {
      schema,
      loading,
      buttons,
      onCancel,
      app: { pencils },
      save,
    } = this.props;
    const { type } = schema || {};
    const layoutElementTypes = pencils.LAYOUT
      ? pencils.LAYOUT.map(({ options }) => options.type)
      : [];
    return (
      <ArtBoardWrapper>
        {loading && <ArtsContainerLoading>Loading...</ArtsContainerLoading>}
        {!loading && (
          <ArtsContainer>
            {!type && (
              <ConditionalDroppable
                droppableId="@"
                allowTypes={layoutElementTypes}
                isEmptyContainer
              >
                <EmptyMessage className="empty-container-message">
                  <h3>Drop any layout elements to start...</h3>
                </EmptyMessage>
              </ConditionalDroppable>
            )}
            {type && (
              <Droppable droppableId="root" isDropDisabled>
                {droppableProvided => (
                  <ArtWrapper
                    ref={droppableProvided.innerRef}
                    {...droppableProvided.droppableProps}
                    {...droppableProvided.placeholder}
                    className="artwrapper"
                  >
                    <Elements.Element id="@" schema={schema} index={0} />
                  </ArtWrapper>
                )}
              </Droppable>
            )}
          </ArtsContainer>
        )}
        <ArtBoardButtons>
          {onCancel && (
            <Button color="link" onClick={onCancel}>
              Cancel
            </Button>
          )}{" "}
          {(buttons || []).map((btn, index) => (
            <Button
              key={btn.label || index}
              color="secondary"
              onClick={() => btn.onClick(schema)}
            >
              {btn.label}
            </Button>
          ))}
          {save && (
            <Button color="primary" onClick={this.schemaWalker}>
              Save
            </Button>
          )}
        </ArtBoardButtons>
      </ArtBoardWrapper>
    );
  }
}

const mapDispatchToProps = {
  schemaWalk: schemaActions.schemaWalk,
};

const mapStateToProps = ({ app, schema }) => ({
  app,
  schema: schema.schema,
  upholdData: schema._uphold.schemaTree // eslint-disable-line
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTheme(ArtBoard));
