import { MODULE_URN } from "../config";

export const uriProvider = (
  uri: string = "",
  withSlash: boolean = true,
  withTrailingSlash: boolean = false
): string => {
  return `/${MODULE_URN}${
    uri && !uri.startsWith("/") && withSlash ? "/" : ""
  }${uri}${withTrailingSlash ? "/" : ""}`;
};
