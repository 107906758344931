import React from "react";

import Sidebar from "./Sidebar";
import PageContent from "./PageContent";
import MobileWrap from "../../components/MobileWrap";

import { LayoutContext } from "./context/layout";

import { type LayoutProps } from "./types";
import LayoutTypeProvider from "./LayoutTypeProvider";

type Props = LayoutProps;

type State = {
  isProductNavigationOpen: boolean,
  isProductNavigationStick: boolean,
  isMobile: boolean,
  activeMenu: string | null,
};

const DEFAULT_LAYOUT = "";

class Layout extends React.Component<Props, State> {
  state = {
    isProductNavigationOpen: false,
    isProductNavigationStick: false,
    isMobile: false,
    activeMenu: "DEFAULT",
  };

  toggleProductNavigation = (activeMenu: string) => {
    const { isProductNavigationStick } = this.state;
    if (isProductNavigationStick) return;
    this.setState(prevState => ({
      isProductNavigationOpen: !prevState.isProductNavigationOpen,
      activeMenu,
    }));
  };

  openProductNavigation = (activeMenu: string) => {
    this.setState({ isProductNavigationOpen: true, activeMenu });
  };

  closeProductNavigation = () => {
    const { isProductNavigationOpen } = this.state;
    if (isProductNavigationOpen) {
      this.setState({
        isProductNavigationOpen: false,
      });
    }
  };

  stickProductNavigation = () => {
    this.setState(prevState => ({
      isProductNavigationStick: !prevState.isProductNavigationStick,
    }));
  };

  onSetMobile = () => {
    this.setState({ isMobile: true });
  };

  onUnsetMobile = () => {
    this.setState({ isMobile: false });
  };

  getFormPrefix = () => {
    const { layoutType } = this.props;
    const { isMobile } = this.state;

    if (layoutType && !isMobile) {
      return layoutType === "web" ? DEFAULT_LAYOUT : `${layoutType}_`;
    }

    if (isMobile) {
      return "mobile_";
    }

    return DEFAULT_LAYOUT;
  };

  render() {
    const { children, pageHeader, layoutType } = this.props;
    const {
      isProductNavigationOpen,
      isProductNavigationStick,
      isMobile,
      activeMenu,
    } = this.state;

    const Components = {
      PageHeader: (pageHeader || {}).component,
    };

    return (
      <>
        <MobileWrap
          id="mobile"
          selector="html"
          dataProperty="data-layout"
          onSetMobile={this.onSetMobile}
          onUnsetMobile={this.onUnsetMobile}
        />
        <LayoutContext
          value={{
            toggleProductNavigation: this.toggleProductNavigation,
            stickProductNavigation: this.stickProductNavigation,
            openProductNavigation: this.openProductNavigation,
            closeProductNavigation: this.closeProductNavigation,
            isProductNavigationOpen,
            isProductNavigationStick,
            autoSlideOnHover: false,
            isMobile,
            activeMenu,
            layoutType,
            formTypePrefix: this.getFormPrefix(),
          }}
        >
          <Sidebar {...this.props} />
          <PageContent Components={Components}>{children}</PageContent>
        </LayoutContext>
      </>
    );
  }
}

export default LayoutTypeProvider(Layout);
