import ResourceStateGenerator from "./generator";
import StoreCreator from "./store";
import Context from "./context";

import type {
  ReduxAction,
  ResourceStateGeneratorType,
} from "./flowTypes/state";

import { type SwanContextType as _SwanContextType } from "./flowTypes/context";

export type SwanContextType = _SwanContextType;

export type State = {
  ReduxAction: ReduxAction,
};

export default {
  generator: ResourceStateGenerator,
};

export { default as withSwanContext } from "./context/withSwanContext";

export const createStore = StoreCreator;
export const ResourceGenerator: ResourceStateGeneratorType = ResourceStateGenerator;
export const SwanModuleContext = Context;
export { swanContextManager as SwanContextManager } from "./context/index";
