export const getJsonSchemaForPermissionEditor = (): // id: string
{
  jsonSchema: {},
  jsonUiSchema: {},
} => {
  const jsonSchema = {
    type: "object",
    properties: {
      permissionName: {
        type: "string",
        title:
          "By filling this field user with this permission will be able to edit record permission",
      },
    },
  };
  const jsonUiSchema = {
    "ui:widget": "checkboxes",
    "ui:options": {
      orderable: false,
    },
  };
  return {
    jsonSchema,
    jsonUiSchema,
  };
};

export default {
  getJsonSchemaForPermissionEditor,
};
