import React, { Component, Fragment } from "react";
import { RemoteSelect, Button, Spinner } from "@swan/themer";

import { withRouter } from "react-router-dom";
import { Alert, Col, Row } from "reactstrap";
import OwnershipFactory from "./services/OwnershipFactory";
import UserServiceFactory from "./services/UserFactory";

type Props = {
  recordId: number,
  object: string,
  currentOwnerName: string,
  onChange: Function,
};

type State = {
  ownershipService: any,
  userService: any,
  newOwnerId: number,
  error: any,
  isChanging: boolean,
};

class ChangeOwnershipForm extends Component<Props, State> {
  state = {
    ownershipService: null,
    userService: null,
    newOwnerId: NaN,
    error: null,
    isChanging: false,
  };

  componentDidMount(): void {
    const { object, recordId } = this.props;

    const ownershipService = new OwnershipFactory();
    const userService = new UserServiceFactory(object, recordId);

    this.setState({
      ownershipService,
      userService,
    });
  }

  changeOwnership = () => {
    const { object, recordId, onChange } = this.props;
    const { ownershipService, newOwnerId } = this.state;
    this.setState({ isChanging: true });
    if (ownershipService) {
      ownershipService
        .post(`${object}/${recordId}`, { newOwnerId })
        .then(() => {
          this.setState({ isChanging: false }, () => {
            if (onChange) {
              onChange();
            }
          });
        })
        .catch(error => {
          this.setState({ error });
          this.setState({ isChanging: false });
        });
    }
  };

  render() {
    const { currentOwnerName } = this.props;
    const { userService, error, isChanging } = this.state;

    if (!userService) {
      return null;
    }

    /**
     * either fromType or toType values changed
     */

    return (
      <Fragment>
        {error && (
          <Row>
            <Alert color="danger">
              <p style={{ marginBottom: 0 }}>{String(error)}</p>
            </Alert>
          </Row>
        )}
        <Row
          style={{ fontWeight: "bold", marginTop: "40px", fontSize: "19px" }}
        >
          Current Owner is {currentOwnerName}
        </Row>
        <Row>
          <Col xs="10">
            <RemoteSelect
              label="Change ownership to user"
              service={userService}
              labelAttribute="name"
              name="newOwnerId"
              isDisabled={isChanging}
              onChange={({ value }) => {
                this.setState({ newOwnerId: value });
              }}
            />
          </Col>
          <Col xs="2">
            <Button
              color="primary"
              disabled={isChanging}
              onClick={this.changeOwnership}
            >
              <span style={{ float: "left" }}>Change</span>
              {isChanging && (
                <span
                  style={{
                    float: "left",
                    marginLeft: "10px",
                    marginTop: "2px",
                  }}
                >
                  <Spinner color="light" size="sm" />
                </span>
              )}
            </Button>
          </Col>
        </Row>
      </Fragment>
    );
  }
}

export default withRouter(ChangeOwnershipForm);
