import React from "react";
// import Home from "./pages/Home";
// import type { DashboardProps } from "./views/Dashboard";
import { GoGear } from "react-icons/all";
import { MODULE_SHORT_DISPLAY_NAME } from "./config";

// $FlowFixMe Should be React.lazy<DashboardProps> but causing issue in webpack
const Home = React.lazy(() => import("./Module"));

// https://github.com/ReactTraining/react-router/tree/master/packages/react-router-config
const entry_routes = [
  {
    path: "/superadmin",
    name: "Super Admin",
    component: Home,
    permissions: ["module:superadmin"],
  },
];

export default {
  name: "SuperAdmin",
  displayName: "Super Admin",
  mainNav: {
    name: "Super Admin",
    url: "/superadmin",
    icon: <GoGear />,
    permissions: ["module:superadmin"],
    color: "#6B778C",
    shortName: MODULE_SHORT_DISPLAY_NAME,
    weight: 1000,
  },
  productNav: [
    {
      name: "Creator",
      url: "/superadmin/creator",
      icon: "fa-cogs",
      permissions: [
        "superadmin:create-attributes",
        "superadmin:create-relations",
        "superadmin:manage-cloudintegrations",
      ],
      permissionsMatchAll: false,
    },
    {
      name: "Composer",
      url: "/superadmin/composer",
      icon: "fa-cogs",
      permissions: ["superadmin:edit-system-forms"],
    },
    {
      name: "Users",
      url: "/superadmin/users",
      icon: "fa-users",
      permissions: ["superadmin:manage-users"],
    },
    {
      name: "Organizations",
      url: "/superadmin/organizations",
      icon: "fa-bank",
      permissions: ["superadmin:manage-organizations"],
    },
    {
      name: "Static Pages",
      url: "/superadmin/pages",
      icon: "fa-cogs",
      permissions: ["superadmin:edit-static-pages"],
    },
    {
      name: "Policies",
      url: "/superadmin/policies",
      icon: "fa-cogs",
      permissions: ["superadmin:manage-policies"],
    },
    {
      name: "Templates",
      url: "/superadmin/templates",
      icon: "fa-cogs",
      permissions: ["superadmin:manage-templates"],
    },
    {
      name: "Login as different user",
      url: "/superadmin/login-as-user",
      icon: "fa-cogs",
      permissions: ["superadmin:login-as-user"],
    },
  ],
  routes: entry_routes,
};
