import React, { Component } from "react";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import { ObjectService } from "@swan/api";
import CardOrderingWrapper from "./CardOrderingWrapper";
import CardFormRow from "./CardFormRow";

type Props = {
  object: string,
  data: ?Array<Object>,
  reorderRecord: Function,
};
type State = {};

/**
 * Used to either use normal cardFormRow or to wrap it with orderable to enable
 * ordering
 * @param CardListComponent
 * @return {WrappedComponent}
 * @constructor
 */
const CardListOrderingWrapper = (CardListComponent: any) => {
  let CardRow;
  class WrappedComponent extends Component<Props, State> {
    constructor(props: Props) {
      super(props);
      this.state = {};
    }

    componentDidMount(): void {
      const { object } = this.props;
      CardRow = CardFormRow;
      ObjectService.getObjectByName(object).then(def => {
        if (def.has_ordering === 1) {
          CardRow = CardOrderingWrapper(CardFormRow);
        }
      });
    }

    static getDerivedStateFromProps(props: Props) {
      const { data } = props;
      return { data };
    }

    onDragEnd = (params: any) => {
      const { reorderRecord, object, data: previousData } = this.props;

      const { destination, source } = params;
      if (!destination) {
        return;
      }
      if (
        destination.droppableId === source.droppableId &&
        destination.index === source.index
      ) {
        return;
      }
      reorderRecord(source.index, object, destination.index, previousData);
    };

    render() {
      return (
        <React.Fragment>
          <DragDropContext onDragEnd={this.onDragEnd}>
            <Droppable droppableId="THIS_IS_UNIQUE">
              {droppableProvided => (
                <div
                  ref={droppableProvided.innerRef}
                  {...droppableProvided.droppableProps}
                >
                  <CardListComponent {...this.props} CardRow={CardRow} />
                  {droppableProvided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </React.Fragment>
      );
    }
  }
  return WrappedComponent;
};

export default CardListOrderingWrapper;
