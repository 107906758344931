/**
 * A generic service that uses an object name and relationship name to fetch configuration like the api endpoint
 * and returns a service with the route correctly set to access this relationship
 */
import Resource from "./resource";
import ObjectService from "./services/object";
import type { SwanObject } from "./types";

const VersionsResourceFactory = (object: string) => {
  class Res extends Resource {
    objectName = object;

    objectDef: SwanObject;

    parentId: string;

    defaultPrefix: string;

    setDefaultPrefix(p: string) {
      this.defaultPrefix = p;
    }

    setParent(p: string) {
      this.parentId = p;
    }

    /**
     * Override the get api function to dynamically load object definition
     */
    getApi() {
      if (!this.objectDef) {
        if (!this.objectName) {
          throw new Error("Object name not defined");
        }
        return ObjectService.getObjectByName(this.objectName).then(def => {
          this.objectDef = def;
          this.prefix = `${this.defaultPrefix || def.api_endpoint}/${
            this.parentId
          }/versions`;
          return super.getApi();
        });
      }
      return super.getApi();
    }

    /**
     * Send a get request
     */
    get(route: string, params: {} = {}) {
      return this.getApi()
        .then(api =>
          api.get(this.getUrl(route), {
            params,
          })
        )
        .then(response => response.data)
        .catch(error => this.constructor.handleError(error));
    }
  }
  return Res;
};
export default VersionsResourceFactory;
