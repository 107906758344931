import { combineReducers } from "redux";

// co-located reducers
import app from "./app/index";
import schema from "./schema/index";

const getReducers = () =>
  // $FlowFixMe
  combineReducers({
    app,
    schema,
  });

export default getReducers;
