import React from "react";

// components
import Cascaders from "./Cascaders";

// type
import { type ResourceType } from "../../flowTypes";

type Props = {
  resource: {
    type: ResourceType,
  },
};

export default (props: Props) => {
  const {
    resource: { type },
  } = props;
  if (
    type &&
    Object.keys(Cascaders).indexOf(type) !== -1 &&
    type === "ATTRIBUTE"
  ) {
    const Cascader = Cascaders[type];
    return <Cascader {...props} />;
  }
  return null;
};
