import React from "react";
import { MemoryRouter } from "react-router-dom";

export const wrapModule = (Component: any) => {
  const WrappedComponent = (props: any) => (
    <MemoryRouter>
      <React.Suspense fallback={<div>Loading...</div>}>
        <Component {...props} />
      </React.Suspense>
    </MemoryRouter>
  );
  return WrappedComponent;
};

export default wrapModule;
