/* eslint-disable */
/**
 * Default template editor
 */
import React, { Component } from "react";
import Jodit from "jodit";
import { ObjectAttributesCascader } from "@swan/object-conditions";
import { FaTags } from "react-icons/fa";
import memoize from "memoize-one";
import styled from "styled-components";
import Wysiwyg from "../Wysiwyg";
import Modal from "../../Modal";

// custom promise for objectRelationForms
import { objectRelationForms } from "./objectData";

import FieldWrapper, { type FieldProps } from "../partials/FieldWrapper";

type Props = FieldProps & {
  id?: string,
  object?: string | Array<string> | Array<Object>,
  placeholder?: string,
  type?: string
};

type State = {
  tagEditorOpen: boolean
};

const TagModalWrapper = styled.div({
  position: "relative"
});

/* Template Editor Component */
class TemplateEditor extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      tagEditorOpen: false
    };
  }

  getObjectData = memoize(() => {
    const { object } = this.props;
    return objectRelationForms(object);
  });

  config = {
    buttons: [
      ...Jodit.defaultOptions.buttons,
      ...[
        {
          name: "insertTemplates",
          icon: "attachment",
          tooltip: "Insert Template",
          exec: (/* e, control, $btn */) => {
            if (this.editor.selection.isFocused()) {
              this.currentRange = this.editor.selection.range;
              this.currentSelection = this.editor.selection.sel;
              this.toggleEditor();
            } else {
              throw Error("Editor is out of focus");
            }
          }
        }
      ]
    ]
  };

  toggleEditor = () => {
    this.setState(prevState => ({ tagEditorOpen: !prevState.tagEditorOpen }));
  };

  setRefs = ({ editor, Jodit }: { editor: Object, Jodit: Object }) => {
    this.Jodit = Jodit;
    this.editor = editor;
  };

  insertTemplateTag = (tag: any) => {
    const templateTag = document.createTextNode(String(tag));

    const expandedSelRange = this.currentRange.cloneRange();
    this.currentRange.collapse(false);
    this.currentRange.insertNode(templateTag);
    expandedSelRange.setEndAfter(templateTag);
    this.currentSelection.removeAllRanges();
    this.currentSelection.addRange(expandedSelRange);
    this.toggleEditor();
  };

  customOnChangePreprocessor = (
    value: Array<any>
    // selectedOptions: Array<Object>
  ) => {
    const selectedValues = [...value];
    const isValueOfChildSelection = selectedValues
      .splice(0, 1)
      .find(v => v === "__children");

    if (isValueOfChildSelection) {
      return `{#${selectedValues.join(".")}}`;
    }

    return undefined;
  };

  Jodit: Object;

  editor: Object;

  currentRange: Range;

  currentSelection: Selection;

  render() {
    const { onChange, value, object, id, ...restProps } = this.props;
    const { tagEditorOpen } = this.state;
    return (
      <>
        <Modal
          isOpen={tagEditorOpen}
          toggle={this.toggleEditor}
          title="Select a tag to insert"
        >
          <TagModalWrapper>
            <ObjectAttributesCascader
              object={object}
              isResourceToString
              objectData={this.getObjectData()}
              onChangePreprocessor={this.customOnChangePreprocessor}
              onChange={this.insertTemplateTag}
            />
          </TagModalWrapper>
        </Modal>
        <Wysiwyg
          {...restProps}
          id={id}
          onChange={onChange}
          value={value}
          extendConfig={this.config}
          getRefs={this.setRefs}
        />
      </>
    );
  }
}

export default TemplateEditor;

// export default FieldWrapper(TemplateEditor);
