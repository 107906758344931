import * as React from "react";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";

import { RoutesConsumer } from "../routes";
import { NavConsumer } from "../nav";
import config from "@swan/config";
import { SwanModuleContext, SwanContextManager } from "@swan/state";

import history from "../utils/history";

// $FlowFixMe AbstractComponent not found
function SwanModule<T>(Component: React.AbstractComponent<T>): T {
  const WrapperComponent = props => (
    <RoutesConsumer>
      {({ registerRoutes }) => (
        <NavConsumer>
          {({ registerNav, registerProductNav }) => (
            <SwanModuleContext.Provider
              value={{
                registerRoutes,
                registerNav,
                history,
                registerProductNav,
                i18n: {
                  defaultLocale: props.intl.defaultLocale,
                  locale: props.intl.locale,
                  messages: props.intl.messages,
                },
                user: props.user,
                config,
                ...SwanContextManager.getValues(),
              }}
            >
              <Component {...props} />
            </SwanModuleContext.Provider>
          )}
        </NavConsumer>
      )}
    </RoutesConsumer>
  );

  // function mapStateToProps({ user }) {
  //   return {
  //     user
  //   };
  // }

  // return connect(
  //   mapStateToProps,
  //   injectIntl(WrapperComponent)
  // )(WrapperComponent);
  return injectIntl(WrapperComponent);
}

// $Flow FixMe AbstractComponent not found
const _SwanContextProvider = props => (
  <RoutesConsumer>
    {({ registerRoutes }) => (
      <NavConsumer>
        {({ registerNav, registerProductNav }) => (
          <SwanModuleContext.Provider
            value={{
              registerRoutes,
              registerNav,
              registerProductNav,
              history,
              i18n: {
                defaultLocale: props.intl.defaultLocale,
                locale: props.intl.locale,
                messages: props.intl.messages,
              },
              user: props.user,
              config,
            }}
          >
            {props.children}
          </SwanModuleContext.Provider>
        )}
      </NavConsumer>
    )}
  </RoutesConsumer>
);

function mapStateToProps({ user }) {
  return {
    user: user.profile,
  };
}

export const SwanContextProvider = connect(mapStateToProps)(
  injectIntl(_SwanContextProvider)
);

export default SwanModule;
