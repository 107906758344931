export default {
  uid: "dateField",
  attributeId: "",
  formId: "",
  pencilDef: {
    title: "Date",
    group: "FIELD",
    handleIcon: "FaRegCalendarAlt",
    styles: {},
  },
  options: {
    type: "dateField",
    preventDefaultValue: false,
    dateFormat: "DD/MM/YYYY",
  },
};
