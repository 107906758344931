// @flow
import React, { Component } from "react";
import { connect } from "react-redux";

import styled, { withTheme, createGlobalStyle } from "styled-components";
import { DragDropContext } from "react-beautiful-dnd";
import { has } from "lodash";

// components
import PencilPanel from "./PencilPanel";
import ArtBoard from "./ArtBoard";

// types
import type {
  pencilDefs,
  theme as themeType,
  ObjectRelationPropType,
  WorkflowPropType,
} from "../types";

// state
import { schemaActions } from "../state/reducers/schema";
import type { AddElement, MoveElement } from "../state/reducers/schema/actions";
import { appActions } from "../state/reducers/app";

// utils
import JsonSchemaProvider from "../utils/jsonSchemaProvider";
import parts from "../definitions/parts";
import { ObjectDefContext } from "../state/context/ObjectDefContext";

const MainContainer = styled.div(({ theme }) =>
  theme.MainContainer ? { ...theme.MainContainer } : {}
);

const AppWrapper = styled.div(({ theme }) =>
  theme.AppWrapper ? { ...theme.AppWrapper } : {}
);

type Props = {
  pencilDefs: pencilDefs,
  pencils: pencilDefs,
  schema: {},
  loadSchema: Function,
  loadAttributes: Function,
  object: {},
  objectAttrib: Array<{}>,
  objectRelation: ObjectRelationPropType,
  workflows: WorkflowPropType,
  theme: themeType,
  startDragging: string => void,
  stopDragging: () => void,
  addElement: AddElement,
  moveElement: MoveElement,
  save: (schema: Object, fieldsDiff: Object) => void,
  onCancel: ?() => void,
  buttons: Array<{
    label: string,
    onClick: Function,
  }>,
  isDirty?: boolean,
  getStatus?: Function,
  loadObjectFormId: Function,
  formId: Function,
};

type State = {
  show: boolean,
  key: number,
};

const GlobalStyle = createGlobalStyle`
  i.glyphicon { display: none; }
  .btn-add::after { content: 'Add'; }
  .array-item-move-up::after { content: 'Move Up'; }
  .array-item-move-down::after { content: 'Move Down'; }
  .array-item-remove::after { content: 'Remove'; }
`;

const Main = class Main extends Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      show: true,
      key: 0,
    };
  }

  componentDidMount() {
    const {
      schema,
      loadSchema,
      loadAttributes,
      objectAttrib,
      loadObjectFormId,
      formId,
    } = this.props;
    loadSchema(schema);
    loadAttributes(objectAttrib);
    loadObjectFormId(formId);
    window.addEventListener("FormDesigner::Refresh", this.refresh);
  }

  componentDidUpdate(prevProps) {
    const { getStatus, isDirty } = this.props;
    const { show } = this.state;
    if (!show) {
      setTimeout(() => {
        this.setState({ show: true });
      }, 100);
    }
    if (getStatus && prevProps.isDirty !== isDirty) {
      getStatus({ isDirty });
    }
  }

  componentWillUnmount() {
    window.removeEventListener("FormDesigner::Refresh", this.refresh);
  }

  refresh = () => {
    this.setState(state => ({ key: state.key + 1 }));
  };

  onDragEndResponder = result => {
    const {
      stopDragging,
      addElement,
      moveElement,
      pencils: pencilsDef,
    } = this.props;
    const layoutElementTypes = has(pencilsDef, "LAYOUT")
      ? pencilsDef.LAYOUT.map(({ options }) => options.type)
      : [];
    stopDragging();
    if (result.destination === null) return;
    if (result.destination.droppableId === result.draggableId) return;
    if (result.destination.droppableId.indexOf(result.draggableId) === 0) {
      // Dropping an element into a child of it
      return;
    }
    if (layoutElementTypes.includes(result.draggableId)) {
      // TODO: needs to fix re-rendering whole artboard when schema changes
      this.refresh();
    }
    if (result.draggableId.substr(0, 1) !== "@") {
      // element is from pencil panel
      addElement(
        JsonSchemaProvider({
          pencilType: result.draggableId, // pencil id
          type: parts.NewField.options.type, // parts: new field option.type, i.e. new_field
        }),
        result.destination.droppableId,
        result.destination.index
      );
    } else {
      // element dragged from artboard
      moveElement(
        result.draggableId,
        result.destination.droppableId,
        result.destination.index
      );
    }
  };

  onDragStartResponder = result => {
    const { startDragging } = this.props;
    startDragging(result.draggableId);
  };

  // onDragUpdateResponder = result => {
  // console.log("Update: ", /result); // eslint-disable-line
  // };

  render() {
    const {
      pencilDefs: pencilDefinitions,
      object,
      objectAttrib,
      objectRelation,
      pencils,
      workflows,
      theme: { MainClasses },
      save,
      onCancel,
      buttons,
    } = this.props;
    const { show, key } = this.state;

    return (
      <ObjectDefContext.Provider
        value={{ object, objectAttrib, objectRelation }}
      >
        <MainContainer
          className={MainClasses.MainContainer || "main-container"}
        >
          <GlobalStyle />
          <AppWrapper className={MainClasses.AppWrapper || "app-wrapper"}>
            <DragDropContext
              onDragStart={this.onDragStartResponder}
              onDragEnd={this.onDragEndResponder}
              // onDragUpdate={this.onDragUpdateResponder}
            >
              <PencilPanel
                pencilDefs={pencilDefinitions}
                object={object}
                objectAttrib={objectAttrib}
                objectRelation={objectRelation}
                workflows={workflows}
                pencils={pencils}
              />
              <ArtBoard
                key={`v${key}`}
                loading={!show}
                save={save}
                buttons={buttons}
                onCancel={onCancel}
              />
            </DragDropContext>
          </AppWrapper>
        </MainContainer>
      </ObjectDefContext.Provider>
    );
  }
};

const mapStateToProps = ({ app: { pencils }, schema: { isDirty } }) => ({
  pencils,
  isDirty,
});

// theme prop is available in consumer components
export default connect(
  mapStateToProps,
  {
    loadSchema: schemaActions.loadSchema,
    startDragging: appActions.startDragging,
    stopDragging: appActions.stopDragging,
    loadAttributes: appActions.loadAttributes,
    loadObjectFormId: appActions.loadObjectFormId,
    moveElement: schemaActions.moveElement,
    addElement: schemaActions.addElement,
  }
)(withTheme(Main));
