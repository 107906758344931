import { createStore } from "@swan/state"; // eslint-disable-line
import reducers from "./reducer";
import sagas from "./sagas";

// module configs

export const { store, persistor } = createStore("WORKFLOW", sagas, {
  workflow: reducers,
});
export default { store, persistor };
