import sectionContainer from "./sectionContainer";

export default {
  ...sectionContainer,
  pencilDef: {
    ...sectionContainer.pencilDef,
    title: "Section",
    handleIcon: "FaRegWindowMaximize",
  },
  options: {
    ...sectionContainer.options,
    type: "containerWithTitle",
    toggleSection: false,
    defaultToggleOpen: true,
    hideSectionTitle: false,
  },
};
