import * as React from "react"; // eslint-disable-line
import { IconContext } from "react-icons";
// @todo: loading different vendor font libraries via IconHandler props
import * as FaIcons from "react-icons/fa";
import { has } from "lodash";

import styled from "styled-components";

// type
export type Props = {
  name?: string,
  color?: string,
  size?: string | number,
  className?: string,
  style?: {},
  attr?: string,
  noContainer?: boolean,
};

// styled
export const IconHandle = styled("div")`
  display: flex;
  align-items: center;
  align-content: center;
  user-select: none;
  margin: -0.5rem -0.5rem -0.5rem 0;
  padding: 0.5rem;
  line-height: 1.5;
  transform: translateZ(0);
  transition: transform 0.2s ease-out;

  &:hover {
    transform: scale(1.3) translateZ(0);
  }
`;

// component
const Icon = ({
  name,
  color,
  size,
  className,
  style,
  attr,
  noContainer,
}: Props) => {
  const Handler = !has(FaIcons, name)
    ? FaIcons[Icon.defaultProps.name]
    : FaIcons[name];

  if (noContainer)
    return (
      <IconContext.Provider value={{ color, size, className, style, attr }}>
        <Handler />
      </IconContext.Provider>
    );

  return (
    <IconHandle>
      <IconContext.Provider value={{ color, size, className, style, attr }}>
        <Handler />
      </IconContext.Provider>
    </IconHandle>
  );
};

// default props
Icon.defaultProps = {
  name: "FaPencilAlt",
  color: "",
  size: "1em",
  style: {},
  attr: "",
  className: "",
  noContainer: false,
};

export default Icon;
