import { type PerimssionsStorageShape } from "./types";

class PermissionsStorage {
  STORAGE_KEY = "swanAuth";

  /**
   * Load permissions from local storage
   */
  loadPermissions(): ?PerimssionsStorageShape {
    const perms = localStorage.getItem(this.STORAGE_KEY);
    if (perms) {
      return JSON.parse(perms);
    }
    return null;
  }

  /**
   * Update local storage
   */
  storePermissions(permissions: PerimssionsStorageShape): void {
    localStorage.setItem(this.STORAGE_KEY, JSON.stringify(permissions));
  }

  /**
   * Destroy
   */
  destroy() {
    localStorage.removeItem(this.STORAGE_KEY);
  }
}

export default PermissionsStorage;
