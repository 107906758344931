import React from "react";
import { useEditor } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import Table from "@tiptap/extension-table";
import TableRow from "@tiptap/extension-table-row";
import TableCell from "@tiptap/extension-table-cell";
import TableHeader from "@tiptap/extension-table-header";
import Underline from "@tiptap/extension-underline";
import TextAlign from "@tiptap/extension-text-align";
import Highlight from "@tiptap/extension-highlight";
import TextStyle from "@tiptap/extension-text-style";
import { Color } from "@tiptap/extension-color";
import { EditorContent } from "./Styled";
import MenuBar from "./Components/MenuBar";
import DragAndDropImage from "./Plugins/DragAndDropImage";
import LineHeight from "./Plugins/LineHeight";

const Editor = ({ onChange, value }: any) => {
  const tiptap = useEditor({
    extensions: [
      StarterKit,
      DragAndDropImage,
      Table.configure({
        resizable: true,
      }),
      LineHeight,
      TableRow,
      TableHeader,
      TableCell,
      Underline,
      TextAlign.configure({
        types: ["heading", "paragraph"],
      }),
      Highlight.configure({
        multicolor: true,
      }),
      TextStyle,
      Color,
    ],
    content: value,
    onUpdate: ({ editor }) => {
      onChange(editor.getHTML());
    },
  });

  return (
    <div style={{ border: "1px solid #e0e0e0" }}>
      <MenuBar editor={tiptap} />
      <EditorContent
        style={{ backgroundColor: "#fff", padding: "10px" }}
        editor={tiptap}
      />
    </div>
  );
};

export default Editor;
