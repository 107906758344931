// @flow
import React from "react";
import { Provider } from "react-redux";

// Default loader
import type { Node } from "react";

type Props = {
  loader?: Node,
};

export const withProvider = (
  FormDesigner: any, // @todo
  config: { configureStore: any }
) => {
  class WrappedComponent extends React.Component<Props> {
    static defaultProps = { loader: undefined };

    constructor(props: Props) {
      super(props);

      const { configureStore } = config;
      this.store = configureStore();
    }

    store: any;

    render() {
      return (
        <Provider store={this.store}>
          <FormDesigner {...this.props} />
        </Provider>
      );
    }
  }
  return WrappedComponent;
};

export default { withProvider };
