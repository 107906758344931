import React, { Component, type ElementRef } from "react";
import styled, { ThemeProvider } from "styled-components";
import { Button, DropDown } from "@swan/themer";
import { FaPlusCircle, FaTrashAlt } from "react-icons/fa";

// components
import ConditionRow from "./ConditionRow";

// utils
import { objectDataCascade } from "../utils/provideObjectCascade";
import { defaultResourceTemplates } from "../utils/provideResourceTemplate";

// $FlowFixMe
import "rc-cascader/assets/index.css";
import "../styles/custom.css";

// types
import { type Condition, type Theme } from "../flowTypes";

type Props = {
  title?: string,
  noTitle?: boolean,
  readOnlyTitle?: boolean,
  limit?: number,
  evalPattern?: string,
  object?: string,
  filteredAttributes?: Array<{ key: string, value: string }>,
  conditions?: Array<Condition>,
  theme?: Theme,
  objectAttributePreload?: boolean,
  onSave?: Object => any,
  onCancel?: Object => any,
  onDelete?: (Object, boolean) => any,
  name?: string,
  useMode?: "field" | "editor",
  useModeHandle?: Object => any,
  canDelete?: boolean,
};

type State = {
  canAdd: boolean,
  conditions: Array<Condition>,
  evalPattern: string,
  evalOption: "ALL_AND" | "ALL_OR" | "CUSTOM" | string,
  title: string,
  objectData: Object,
};

// styled components -- starts
const EditorWrapper = styled.div(({ theme }) =>
  Object.assign(
    {
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-start",
      alignItems: "flex-start",
      padding: "1rem",
      border: "1px solid #ddd",
      borderRadius: "3px",
      boxShadow: "0 1px 1px 0 gray",
      width: "100%",
    },
    theme.EditorWrapper ? { ...theme.EditorWrapper } : {}
  )
);

const Title = styled.input({
  fontSize: "1.2rem",
  fontWeight: 600,
});

const TitleWrapper = styled(
  styled.div(({ theme }) =>
    Object.assign(
      {
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "center",
        paddingBottom: ".5rem",
      },
      theme.TitleWrapper ? { ...theme.TitleWrapper } : {}
    )
  )
)`
  ${Title} {
    flex: 1;
  }
`;

const ConditionWrapper = styled.div({
  display: "flex",
  flexDirection: "row",
  width: "100%",
});

const EvalPatternWrapper = styled.div({
  display: "flex",
  flexDirection: "row",
  width: "100%",
});

const EvalPattern = styled.div({
  flex: 1,
});

const EvalOptions = styled.div({
  display: "flex",
  flexBasis: "300px",
});

const ConditionControls = styled.div({
  flexBasis: "100px",
  flexGrow: 0,
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "center",
});

const ControlsWrapper = styled.div(({ theme }) =>
  Object.assign(
    {
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      alignItems: "center",
      padding: ".5rem 0",
    },
    theme.ControlsWrapper ? { ...theme.ControlsWrapper } : {}
  )
);
// -- ends

class ConditionsEditor extends Component<Props, State> {
  static defaultProps = {
    limit: -1,
    conditions: [
      {
        id: 1,
        operandLeft: defaultResourceTemplates.ATTRIBUTE,
      },
    ],
    evalPattern: "ALL_AND",
    theme: {},
    title: "Untitled Condition",
    name: "conditions_editor",
    noTitle: false,
    readOnlyTitle: false,
    object: undefined,
    filteredAttributes: undefined,
    objectAttributePreload: false,
    onSave: () => {},
    onCancel: () => {},
    onDelete: () => {},
    useMode: "editor",
    useModeHandle: () => {},
    canDelete: false,
  };

  constructor(props: Props) {
    super(props);

    this.state = {
      canAdd: false,
      conditions: [],
      evalPattern: "",
      title: "",
      objectData: {},
      evalOption: "",
    };

    this.titleInput = React.createRef();
  }

  componentDidMount() {
    const {
      conditions,
      evalPattern,
      title,
      object,
      objectAttributePreload,
    } = this.props;
    const canAdd = this.canAdd();
    this.setState({
      conditions,
      canAdd,
      evalPattern,
      title,
    });

    // if objectAttributePreload is true, set objectData in state
    if (objectAttributePreload) {
      objectDataCascade(object).then(objectData => {
        this.setState({
          objectData,
        });
      });
    }

    // set evalOption fallback to CUSTOM,
    if (["ALL_AND", "ALL_OR"].indexOf(evalPattern) === -1) {
      this.setState({
        evalOption: "CUSTOM",
      });
    } else {
      this.setState({
        evalOption: evalPattern,
      });
    }
    // If this is allowed to run, it will clear the value on every component mount
    // this.useModeHandler();
  }

  canAdd = (newerConditions?: Array<Condition>) => {
    const { limit, conditions } = this.props;
    const cx = newerConditions || conditions;
    return !limit || limit === -1 || !cx ? true : cx.length < limit;
  };

  // Manging condition state
  // 1. triggering every changes on conditions object
  updateCondition = (condition: Condition) => {
    this.setState(
      prevState => {
        const foundIndex = prevState.conditions.findIndex(
          c => c.id === condition.id
        );

        let newerConditions = [...prevState.conditions];
        if (foundIndex !== -1) {
          newerConditions.splice(foundIndex, 1, condition);
        } else {
          newerConditions = [...newerConditions, condition];
        }

        return {
          conditions: newerConditions,
          canAdd: this.canAdd(newerConditions),
        };
      },
      () => {
        this.useModeHandler();
      }
    );

    // useModeHandle is hooked
    // this.useModeHandler();
  };

  // 2. removing conditions from state
  removeCondition = (id: number) => {
    this.setState(
      prevState => {
        const foundIndex = prevState.conditions.findIndex(c => c.id === id);

        const newerConditions = [...prevState.conditions];
        if (foundIndex !== -1) {
          newerConditions.splice(foundIndex, 1);
          return {
            conditions: newerConditions,
            canAdd: this.canAdd(newerConditions),
          };
        }
        return {};
      },
      () => {
        this.useModeHandler();
      }
    );

    // useModeHandle is hooked
    this.useModeHandler();
  };

  // 3. Add new empty condition to the state
  addNewConditionRow = () => {
    this.updateCondition({
      id: this.findNextIncrement(),
      // $FlowFixMe
      operandLeft: defaultResourceTemplates.ATTRIBUTE,
    });
    // useModeHandle is hooked
    this.useModeHandler();
  };

  // Utility class field to find next available numeric id for condition
  findNextIncrement = () => {
    const { conditions } = this.state;
    if (conditions && conditions.length === 0) {
      return 0;
    }
    return Math.max(...conditions.map(condition => condition.id || 0)) + 1;
  };

  // Handling changes on condition's title
  titleHandler = (e: SyntheticInputEvent<HTMLInputElement>) => {
    this.setState({
      title: e.target.value,
    });
    // useModeHandle is hooked
    this.useModeHandler();
  };

  // Save handler for editor as whole
  saveHandler = () => {
    const { onSave } = this.props;
    const { conditions, title, evalPattern } = this.state;

    if (title === "") {
      this.titleInput.focus();
      return;
    }

    if (onSave) {
      onSave({ conditions, title, evalPattern });
    }
  };

  deleteHandler = () => {
    const { onDelete } = this.props;
    const { conditions, title, evalPattern } = this.state;

    if (onDelete) {
      onDelete({ conditions, title, evalPattern }, true);
    }
  };

  // Cancel handler for editor as whole
  cancelHandler = (e: SyntheticMouseEvent<HTMLButtonElement>) => {
    const { onCancel } = this.props;
    if (onCancel) {
      onCancel(e);
    }
  };

  // Managing evalutation pattern options changes, records in state
  // 1.
  evalPatternOptionChange = (e: SyntheticInputEvent<HTMLSelectElement>) => {
    const evalOption = e.target.value;
    if (["ALL_AND", "ALL_OR", "CUSTOM"].indexOf(evalOption) !== -1) {
      this.setState({
        evalOption: evalOption || "CUSTOM",
        evalPattern: evalOption === "CUSTOM" ? "" : evalOption,
      });
    }
  };

  // 2.
  evalPatternChange = (e: SyntheticInputEvent<HTMLInputElement>) => {
    this.setState({
      evalPattern: e.target.value,
    });
  };

  // useMode??
  // A way of defining, its application in different use cases
  // i.e. using condition editor as single filed inside an existing form
  // useMode comes with its own "handle" function - useModeHandle
  useModeHandler = () => {
    const { conditions, title, evalPattern } = this.state;
    const { useModeHandle, name, useMode } = this.props;
    if (["field"].indexOf(useMode) !== -1 && useModeHandle) {
      useModeHandle({
        [name || title]: {
          conditions,
          title,
          evalPattern,
        },
      });
    }
  };

  titleInput: ElementRef<any>;

  render() {
    const {
      theme,
      noTitle,
      readOnlyTitle,
      object,
      objectAttributePreload,
      filteredAttributes,
      useMode,
      canDelete,
      // limit,
    } = this.props;
    const {
      canAdd,
      conditions,
      evalPattern,
      evalOption,
      title,
      objectData,
    } = this.state;

    const titleRequired =
      !noTitle && title === "" ? { "data-invalid": true } : {};

    return (
      <ThemeProvider theme={theme || {}}>
        <EditorWrapper className="editor-wrapper">
          {/* 0. conditions block title */}
          {!noTitle && (
            <TitleWrapper className="form-group">
              {!readOnlyTitle && (
                <Title
                  value={title}
                  name="title"
                  ref={input => {
                    this.titleInput = input;
                  }}
                  onChange={this.titleHandler}
                  className="editor-title form-control"
                  {...titleRequired}
                />
              )}

              {readOnlyTitle && <h2>{title}</h2>}
            </TitleWrapper>
          )}
          {/* 1. conditions block */}
          {!!conditions.length &&
            conditions.map(condition => (
              <ConditionWrapper key={`condition-wrapper-${condition.id}`}>
                <ConditionRow
                  conditionCount={conditions.length}
                  key={`condition-row-${condition.id}`}
                  condition={condition}
                  conditionsObserver={this.updateCondition}
                  object={object}
                  filteredAttributes={filteredAttributes}
                  objectData={objectAttributePreload ? objectData : undefined}
                  className="condition-row"
                />
                {conditions.length > 1 && (
                  <ConditionControls>
                    <Button
                      size="1em"
                      color="danger"
                      onClick={() => {
                        this.removeCondition(condition.id);
                      }}
                    >
                      <FaTrashAlt />
                    </Button>
                  </ConditionControls>
                )}
              </ConditionWrapper>
            ))}
          {canAdd && (
            <div className="clearfix">
              <Button
                onClick={this.addNewConditionRow}
                className="add-more-button"
                color="success"
              >
                Add more{` `}
                <FaPlusCircle />
              </Button>
            </div>
          )}

          {!!conditions.length && conditions.length > 1 && (
            <EvalPatternWrapper>
              {
                <EvalOptions className="eval-options">
                  <DropDown
                    name="evalPatternOption"
                    data={[
                      {
                        id: "ALL_AND",
                        label: "All Conditions are met",
                      },
                      {
                        id: "ALL_OR",
                        label: "Any of the Conditions are met",
                      },
                      {
                        id: "CUSTOM",
                        label: "Custom Evaluation Pattern",
                      },
                    ]}
                    value={evalOption}
                    placeholder="Evaluation Pattern"
                    onChange={this.evalPatternOptionChange}
                  />
                </EvalOptions>
              }

              {evalOption === "CUSTOM" && (
                <EvalPattern className="custom-eval-pattern form-group">
                  <input
                    className="form-control"
                    type="text"
                    name="evalPattern"
                    value={evalPattern}
                    onChange={this.evalPatternChange}
                  />
                </EvalPattern>
              )}
            </EvalPatternWrapper>
          )}

          {["editor"].indexOf(useMode) !== -1 && (
            <ControlsWrapper>
              {canDelete && (
                <Button
                  color="danger"
                  type="button"
                  onClick={this.deleteHandler}
                >
                  Delete
                </Button>
              )}
              <Button color="primary" type="button" onClick={this.saveHandler}>
                Save
              </Button>
              <Button
                color="secondary"
                type="button"
                onClick={this.cancelHandler}
              >
                Cancel
              </Button>
            </ControlsWrapper>
          )}
        </EditorWrapper>
      </ThemeProvider>
    );
  }
}

export default ConditionsEditor;
