import React from "react";
import {
  Bar,
  BrandCard,
  Doughnut,
  SimpleTable,
  StackedBar,
  Table,
  // $FlowFixMe
} from "@swan/themer/src/components/Dashboard/Widgets";
import ExportButton from "./ExportButton";

const ChartFactory = ({ chart, data }: any) => (
  <>
    {chart.type === "BRAND_CARD" && (
      <BrandCard
        data={data}
        title={chart.name}
        exportButton={<ExportButton id={chart.id} type="chart" />}
      />
    )}
    {chart.type === "BAR" && (
      <Bar
        data={data}
        title={chart.name}
        exportButton={<ExportButton id={chart.id} type="chart" />}
      />
    )}
    {chart.type === "STACKED_BAR" && (
      <StackedBar
        data={data}
        title={chart.name}
        exportButton={<ExportButton id={chart.id} type="chart" />}
      />
    )}
    {chart.type === "DOUGHNUT_CHART" && (
      <Doughnut
        title={chart.name}
        data={data}
        exportButton={<ExportButton id={chart.id} type="chart" />}
      />
    )}
    {chart.type === "TABLE" && (
      <Table
        tableTitle={chart.name}
        data={data}
        exportButton={<ExportButton id={chart.id} type="chart" />}
      />
    )}
    {chart.type === "SIMPLE_TABLE" && (
      <SimpleTable
        tableTitle={chart.name}
        data={data}
        id={chart.id}
        exportButton={<ExportButton id={chart.id} type="chart" />}
      />
    )}
  </>
);

export default ChartFactory;
