export default {
  uid: "singleSelect",
  attributeId: "",
  formId: "",
  pencilDef: {
    title: "Single Select",
    group: "FIELD",
    handleIcon: "FaCheck",
    styles: {},
  },
  options: {
    title: "Untitled",
    type: "singleSelect",
    required: false,
    limits: {
      min: 0,
      max: 1,
    },
    description: "",
    help: "",
    hideLabel: true,
    classNames: "",
    disabled: false,
    readonly: false,
    allowDuplicate: true,
    // isEncrypted: false,
    searchable: true,
    listed: true,

    // SINGLE_SELECT specific options
    enableHistoryTracking: false,
    standardItems: "", // true, // make standardItems possible
    items: [], // overriden custom items
  },
};
