// @flow
/* eslint-disable no-unused-vars */
import { isEmpty } from "lodash";

export default (conditionValue: any) => (fieldValue: any) => {
  // considered empty values are not
  // 1. null
  // 2. undefined
  // 3. "" empty string
  // 4. false
  // 5. NaN
  // 6. Empty collection
  let v = fieldValue;

  // we might have 0 as valid value, so skip the case
  if (typeof v === "number" && v === 0) {
    v = String(v);
  }

  // checks the emptiness of collections
  if (["undefined", "boolean", "number"].indexOf(typeof v) === -1) {
    return isEmpty(v);
  }

  // use of undefined checking is arguable
  return typeof v === "undefined" || !v;
  // return isEmpty(fieldValue);
};
