import React, { Component, Fragment } from "react";
import { compose } from "redux";
import { DropDown, RemoteSelect } from "@swan/themer";
import { withRouter } from "react-router-dom";
import { Col, Row } from "reactstrap";
import RoleServiceFactory from "./services/RoleFactory";
import UserServiceFactory from "./services/UserFactory";
import RecordPermissionService from "./services/RecordPermissionFactory";
import Form from "../HOC/CRUD/Form";
import RecordProvider from "../HOC/CRUD/RecordProvider";

type Props = {
  record: Object,
  setValues: ({ [string]: any }) => void,
  isNew: boolean,
  error: Object,
  disableRoleSelector?: boolean,
};

type State = {
  toType: string,
  roleService: any,
  userService: any,
};

export default (object: string, recordId: number) => {
  class RecordPermissionForm extends Component<Props, State> {
    static defaultProps = {
      disableRoleSelector: false,
    };

    state = {
      toType: "user",
      userService: null,
      roleService: null,
    };

    static getDerivedStateFromProps(props: Props, state: State) {
      const { record } = props;
      const { toType } = state;

      return {
        toType: record.to_role || toType === "role" ? "role" : "user",
      };
    }

    componentDidMount(): void {
      const roleService = new RoleServiceFactory(object, recordId);
      const userService = new UserServiceFactory(object, recordId);

      this.setState({
        userService,
        roleService,
      });
    }

    onTypeChanged = (typeName, value) => {
      const { setValues } = this.props;
      if (typeName === "toType") {
        this.setState({
          toType: value,
        });
        setValues({ to_user: null, to_role: null });
      }
    };

    static getRoleName(role) {
      let roleName = role.name;
      if (role.organization) {
        roleName = `${roleName} [${role.organization.name}]`;
      }
      return roleName;
    }

    render() {
      const {
        isNew,
        record,
        setValues,
        error,
        disableRoleSelector,
      } = this.props;

      const { toType, userService, roleService } = this.state;

      if (!userService || !roleService) {
        return null;
      }

      if (!record.permission) {
        setValues({ permission: "1" });
      }
      /**
       * either fromType or toType values changed
       */
      const types = [{ id: "user", label: "User" }];
      if (!disableRoleSelector) {
        types.push({ id: "role", label: "Role" });
      }
      return (
        <Fragment>
          <Row>
            <Col xs="12" sm="12">
              <DropDown
                type="select"
                name="toType"
                value={toType || "user"}
                onChange={event => {
                  this.onTypeChanged("toType", event.target.value);
                }}
                label="To Type"
                error={error.toType}
                data={types}
              />
              {toType === "user" && (isNew || record.to_user) && (
                <RemoteSelect
                  label="To User"
                  service={userService}
                  labelAttribute="name"
                  name="to_user"
                  selectedItem={
                    record.to_user
                      ? {
                          id: isNew ? record.to_user : record.to_user.id,
                          name:
                            isNew || record.to_user_name
                              ? record.to_user_name
                              : record.to_user.name,
                        }
                      : null
                  }
                  onChange={({ value, label }) => {
                    setValues({ to_user: value, to_user_name: label });
                  }}
                />
              )}
              {!disableRoleSelector &&
                toType === "role" &&
                (isNew || record.to_role) && (
                  <RemoteSelect
                    label="To Role"
                    service={roleService}
                    labelAttribute="name"
                    name="to_role"
                    getLabel={role => RecordPermissionForm.getRoleName(role)}
                    selectedItem={
                      record.to_role
                        ? {
                            id: isNew ? record.to_role : record.to_role.id,
                            name:
                              isNew || record.to_role_name
                                ? record.to_role_name
                                : RecordPermissionForm.getRoleName(
                                    record.to_role
                                  ),
                          }
                        : null
                    }
                    onChange={({ value, label }) => {
                      setValues({ to_role: value, to_role_name: label });
                    }}
                  />
                )}
              <DropDown
                type="select"
                name="permission"
                value={record.permission || "1"}
                onChange={event => {
                  setValues({ permission: event.target.value });
                }}
                label="Permission"
                error={error.permission}
                data={[
                  { id: "1", label: "Read" },
                  { id: "2", label: "Edit" },
                  { id: "4", label: "Delete" },
                  { id: "7", label: "Full" },
                  { id: "8", label: "Deny" },
                ]}
              />
            </Col>
          </Row>
        </Fragment>
      );
    }
  }

  function validate(): {} {
    const error = {};
    // if (isNew) {
    //   if (
    //     (record["to_role"] === "" ||
    //       typeof record["to_role"] === "undefined") &&
    //     (record["to_user"] === "" || typeof record["to_user"] === "undefined")
    //   ) {
    //     error[
    //       "to_role"
    //     ] = `either a role or a user should be selected is required`;
    //     error[
    //       "to_user"
    //     ] = `either a role or a user should be selected is required`;
    //   }
    // }
    return error;
  }

  return compose(
    RecordProvider(RecordPermissionService(object, recordId)),
    Form(validate),
    withRouter
  )(RecordPermissionForm);
};
