import React, { useEffect, useState } from "react";
import { withAuth } from "@swan/auth";
import Icons from "./Icons";

import {
  PalletItem as CustomPalletItem,
  PalletItemButton as CustomPalletItemButton,
  PalletItemDesc as CustomPalletItemDesc,
  PalletItemIcon as CustomPalletItemIcon,
  PalletItemTitle as CustomPalletItemTitle,
  PalletRow as CustomPalletRow,
  Panel as CustomPanel,
  PalletButtonsContainer as CustomPalletButtonsContainer,
  PanelRow as CustomPanelRow,
  PanelItemButtonLink as CustomPanelItemButtonLink,
  PalletItemIconContainer as CustomPalletItemIconContainer,
} from "./Styled";

import Grid from "../Grid";

type ButtonItem = {
  label: string,
  url?: string,
  blank?: boolean,
  externalUrl?: string,
  permission?: string,
  disable?: boolean,
  permissionsMatchAny?: boolean,
  permissions?: Array<string>,
};

type MenuItem = {
  label: string,
  iconName?: string,
  icon?: any,
  description?: string,
  buttonLabel?: string,
  permissions?: Array<string>,
  buttons: Array<ButtonItem>,
};

type Props = {
  children: any,
  auth: any,
  menuItems: Array<MenuItem>,
};

const Dashboard = (props: Props) => {
  const {
    children,
    menuItems,
    auth: { getPermissions },
  } = props;
  const [itemsPermissions, setItemPermissions] = useState({});
  useEffect(
    () => {
      let permissionsList = [];
      menuItems.map(item => {
        permissionsList = [...permissionsList, ...(item.permissions || [])];
        return true;
      });
      getPermissions(permissionsList)
        .then(response => {
          setItemPermissions(response);
        })
        .catch(() => {});
    },
    [menuItems, getPermissions]
  );

  const isAllowed = (permissions: Array<string>) => {
    let allowed = false;
    if (permissions.length === 0) {
      return true;
    }
    (permissions || []).map(permission => {
      if (itemsPermissions[permission]) {
        allowed = true;
      }
      return true;
    });
    return allowed;
  };

  return (
    <CustomPanelRow className="panel-row panel-row-hcm-appraisals">
      <CustomPanel className="left panel-left" md={4}>
        {children}
      </CustomPanel>
      <CustomPanel className="right panel-right" md={8}>
        {menuItems.map(menuItem => (
          <>
            {isAllowed(menuItem.permissions || []) && (
              <CustomPalletRow key={`${menuItem.label}`}>
                <Grid.Row>
                  <CustomPalletItemIconContainer md={2}>
                    <CustomPalletItemIcon
                      className={menuItem.icon ? "custom-item-icon" : ""}
                    >
                      {menuItem.icon ? (
                        <menuItem.icon />
                      ) : (
                        menuItem.iconName && (
                          <img src={Icons[menuItem.iconName]} alt="" />
                        )
                      )}
                    </CustomPalletItemIcon>
                  </CustomPalletItemIconContainer>
                  <Grid.Col
                    md={8}
                    style={{
                      display: "flex",
                      alignItems: "left",
                      justifyContent: "left",
                    }}
                  >
                    <CustomPalletItem className="pallet-item-col">
                      <CustomPalletItemTitle className="pallent-item-col--element pallet-item-title">
                        {menuItem.label || ""}
                      </CustomPalletItemTitle>

                      <CustomPalletItemDesc className="pallent-item-col--element pallet-item-desc">
                        {menuItem.description || ""}
                      </CustomPalletItemDesc>
                    </CustomPalletItem>
                  </Grid.Col>
                  <Grid.Col
                    md={2}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <CustomPalletItem>
                      <CustomPalletButtonsContainer>
                        {(menuItem.buttons || []).map(button => {
                          let isButtonEnabled = false;
                          if (
                            button.permissions &&
                            (button.permissions || []).length > 0
                          ) {
                            if (button.permissionsMatchAny) {
                              isButtonEnabled = isAllowed(button.permissions);
                            } else {
                              isButtonEnabled = true;
                              (button.permissions || []).map(p => {
                                if (!isAllowed([p || ""])) {
                                  isButtonEnabled = false;
                                }
                                return true;
                              });
                            }
                          } else if (button.permission) {
                            isButtonEnabled = isAllowed([
                              button.permission || "",
                            ]);
                          } else {
                            isButtonEnabled = true;
                          }
                          return (
                            <>
                              {isButtonEnabled && (
                                <>
                                  {button.url && (
                                    <CustomPanelItemButtonLink
                                      to={button.url}
                                      key={button.url}
                                    >
                                      <CustomPalletItemButton
                                        color={
                                          !button.disable
                                            ? "primary"
                                            : "secondary"
                                        }
                                        block
                                        className="blue-bg"
                                      >
                                        {button.label || "view"}
                                      </CustomPalletItemButton>
                                    </CustomPanelItemButtonLink>
                                  )}
                                  {button.externalUrl && (
                                    <a
                                      href={button.externalUrl}
                                      key={button.url}
                                      target={button.blank ? "_blank" : ""}
                                      rel={
                                        button.blank
                                          ? "noopener noreferrer"
                                          : ""
                                      }
                                    >
                                      <CustomPalletItemButton
                                        color="primary"
                                        block
                                        className="blue-bg"
                                      >
                                        {button.label || "view"}
                                      </CustomPalletItemButton>
                                    </a>
                                  )}
                                </>
                              )}
                            </>
                          );
                        })}
                      </CustomPalletButtonsContainer>
                    </CustomPalletItem>
                  </Grid.Col>
                </Grid.Row>
              </CustomPalletRow>
            )}
          </>
        ))}
      </CustomPanel>
    </CustomPanelRow>
  );
};

export default withAuth(Dashboard);
