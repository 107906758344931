export default {
  uid: "singleSelectCountries",
  attributeId: "",
  formId: "",
  pencilDef: {
    title: "Country Select",
    group: "FIELD",
    handleIcon: "",
    styles: {},
  },
  options: {
    title: "Countries",
    type: "singleSelectCountries",
    limits: {
      min: 0,
      max: 1,
    },
    default: "",

    // SINGLE_SELECT specific options
    enableHistoryTracking: false,
  },
};
