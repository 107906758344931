// @flow
import React, { PureComponent } from "react";
import Form from "react-jsonschema-form";
import { Button } from "reactstrap";
import styled from "styled-components";

// utils
import { getPenOptionsSchema } from "../../utils/penOptions";
import { withModalProvider } from "../../utils/modalProvider";
import { withObjectDef } from "../../state/context/ObjectDefContext";

// jsonSchema
// import widgets from "../../definitions/parts/vendor/jsonSchema/widgets";
// import { FieldTemplate } from "../../definitions/parts/vendor/jsonSchema";

// type
export type Props = {
  pencilDefOptions: any,
  onSubmitHandler: any,
  onCloseHandler: any,
  initialFormData: any,
  pencilType: string,
  schemaId: string,
  objectDef: any,
};

const ModalStyled = styled("div")`
  form.inkdrop-modal-form {
    div.form-group {
      > div {
        width: 100%;
      }
    }
  }
  fieldset {
    width: 100%;
    div.form-group {
      > div {
        width: 100%;
      }

      div.checkbox {
        label {
          border-bottom: none;
          box-shadow: none;
        }
      }
    }
  }
`;

class InkDropModal extends PureComponent<Props> {
  render() {
    const {
      pencilDefOptions,
      pencilType,
      onSubmitHandler,
      onCloseHandler,
      initialFormData,
      schemaId,
      objectDef,
    } = this.props;
    const { jsonSchema, jsonUiSchema } = getPenOptionsSchema(
      pencilType,
      schemaId,
      pencilDefOptions
    );

    const Component = pencilDefOptions.component || Form;
    return (
      <ModalStyled>
        <Component
          schema={jsonSchema}
          uiSchema={jsonUiSchema}
          onSubmit={onSubmitHandler}
          onClose={onCloseHandler}
          formData={initialFormData}
          objectDef={objectDef}
          className="inkdrop-modal-form"
        >
          <Button className="float-right">Save</Button>
          <Button color="link" onClick={onCloseHandler} className="float-right">
            Cancel
          </Button>
        </Component>
      </ModalStyled>
    );
  }
}

export default withObjectDef(withModalProvider(InkDropModal));
