import allowDuplicate from "./allowDuplicate";
import allowHtml from "./allowHtml";
import classNames from "./classNames";
import composer from "./composer";
import defaultLat from "./defaultLat";
import defaultLng from "./defaultLng";
import defaultToggleOpen from "./defaultToggleOpen";
import description from "./description";
import displayContent from "./displayContent";
import formulaBody from "./formulaBody";
import formulaOutputName from "./formulaOutputName";
import help from "./help";
import hideField from "./hideField";
import hideIfEmpty from "./hideIfEmpty";
import hideLabel from "./hideLabel";
import inlineWorkflowButtons from "./inlineWorkflowButtons";
import isEncrypted from "./isEncrypted";
import isHideByDefault from "./isHideByDefault";
import isMapPointer from "./isMapPointer";
import addressComponentsMap from "./addressComponentsMap";
import items from "./items";
import listed from "./listed";
import listedOrder from "./listedOrder";
import columnOrderInCrudList from "./columnOrderInCrudList";
import placeholder from "./placeholder";
import readOnly from "./readOnly";
import required from "./required";
import resourceDataAccessor from "./resourceDataAccessor";
import inputType from "./inputType";
import searchable from "./searchable";
import singleOffLabel from "./singleOffLabel";
import singleOnLabel from "./singleOnLabel";
import singleOnRequired from "./singleOnRequired";
import singleOnDisplayWidget from "./singleOnDisplayWidget";
import standardItems from "./standardItems";
import title from "./title";
import labelTitle from "./labelTitle";
import toggleSection from "./toggleSection";
import hideSectionTitle from "./hideSectionTitle";
import enableBackgroundEval from "./enableBackgroundEval";
import hidePastDates from "./hidePastDates";
import preventDefaultValue from "./preventDefaultValue";
import rows from "./textareaRows";
import filterByTemplateNames from "./filterByTemplateNames";

import objectPrintBaseURL from "./objectPrintBaseURL";
import objectPrintEndPoint from "./objectPrintEndPoint";
import objectPrintLabel from "./objectPrintLabel";

import dateFormat from "./dateFormat";
import disableNegative from "./disableNegative";
import minNumber from "./minNumber";
import maxNumber from "./maxNumber";

import disableCache from "./disableCache";

import customServicePath from "./customServicePath";

import isJson from "./isJson";
import isTiptap from "./isTiptap";

import disableWysiwyg from "./disableWysiwyg";

export default {
  allowDuplicate,
  allowHtml,
  classNames,
  composer,
  defaultLat,
  defaultLng,
  defaultToggleOpen,
  description,
  displayContent,
  formulaBody,
  formulaOutputName,
  help,
  hideField,
  hideIfEmpty,
  hideLabel,
  inlineWorkflowButtons,
  isEncrypted,
  isHideByDefault,
  isMapPointer,
  addressComponentsMap,
  items,
  listed,
  listedOrder,
  columnOrderInCrudList,
  placeholder,
  readOnly,
  required,
  resourceDataAccessor,
  inputType,
  searchable,
  singleOffLabel,
  singleOnLabel,
  singleOnRequired,
  singleOnDisplayWidget,
  standardItems,
  title,
  labelTitle,
  toggleSection,
  hideSectionTitle,
  enableBackgroundEval,
  disableCache,
  hidePastDates,
  preventDefaultValue,
  rows,
  filterByTemplateNames,
  objectPrintBaseURL,
  objectPrintEndPoint,
  objectPrintLabel,
  customServicePath,
  isJson,
  disableWysiwyg,
  isTiptap,
  dateFormat,
  disableNegative,
  minNumber,
  maxNumber,
};
