import styled from "styled-components";

export const ArtWrapper = styled.div(({ theme }) =>
  theme.ArtWrapper ? { ...theme.ArtWrapper } : {}
);

export const ArtContainer = styled.div(({ theme }) =>
  theme.ArtContainer ? { ...theme.ArtContainer } : {}
);

export default {
  ArtWrapper,
  ArtContainer,
};
