import React, { Component } from "react";
import { Skeleton } from "@swan/themer";

type Props = {
  children?: any,
  fieldsLocked?: Array<string>,
  fieldsLoading?: Array<string>,
  isFetching?: boolean,
};

type State = {
  fieldsLocked: Array<string>,
  fieldsLoading: Array<string>,
};

const { Form: FormSkeleton } = Skeleton;

export default class RendererUIController extends Component<Props, State> {
  static defaultProps = {
    children: null,
    isFetching: false,
    fieldsLocked: [],
    fieldsLoading: [],
  };

  constructor(props: Props) {
    super(props);

    this.state = {
      fieldsLocked: [],
      fieldsLoading: [],
    };
  }

  componentDidMount() {
    const { fieldsLocked, fieldsLoading } = this.props;

    this.setState({
      fieldsLocked,
      fieldsLoading,
    });
  }

  toggleFieldAtList = (
    fieldOrFields?: string | Array<string>,
    list?: Array<string> = [],
    action?: "ADD" | "REMOVE" = "ADD"
  ) => {
    let result = [...list];
    let fields = fieldOrFields;

    if (typeof fields === "string") {
      fields = [fields];
    }

    if (Array.isArray(fields)) {
      fields.forEach(field => {
        if (field) {
          const fieldFoundInAtIndex = result.indexOf(field);

          if (action === "ADD") {
            if (fieldFoundInAtIndex === -1) {
              result = [...result, field];
            }
          }

          if (action === "REMOVE") {
            if (fieldFoundInAtIndex !== -1) {
              result.splice(fieldFoundInAtIndex, 1);
            }
          }
        }
      });
    }

    return result;
  };

  setFieldsUnlocked = (fieldOrFields?: string | Array<string>) => {
    const { fieldsLocked } = this.state;
    this.setState({
      fieldsLocked: this.toggleFieldAtList(
        fieldOrFields,
        [...fieldsLocked],
        "REMOVE"
      ),
    });
  };

  setFieldsLocked = (fieldOrFields?: string | Array<string>) => {
    const { fieldsLocked } = this.state;
    this.setState({
      fieldsLocked: this.toggleFieldAtList(
        fieldOrFields,
        [...fieldsLocked],
        "ADD"
      ),
    });
  };

  setFieldsUnloading = (fieldOrFields?: string | Array<string>) => {
    const { fieldsLoading } = this.state;
    this.setState({
      fieldsLoading: this.toggleFieldAtList(
        fieldOrFields,
        [...fieldsLoading],
        "REMOVE"
      ),
    });
  };

  setFieldsLoading = (fieldOrFields?: string | Array<string>) => {
    const { fieldsLoading } = this.state;
    this.setState({
      fieldsLoading: this.toggleFieldAtList(
        fieldOrFields,
        [...fieldsLoading],
        "ADD"
      ),
    });
  };

  render() {
    const { children, isFetching } = this.props;
    const { fieldsLocked, fieldsLoading } = this.state;

    // if isFetching...
    if (isFetching) {
      return <FormSkeleton />;
    }

    // if, otherwise
    const childrenWithUIControls = React.Children.map(children, child =>
      React.cloneElement(child, {
        fieldsLocked,
        fieldsLoading,
        setFieldsUnlocked: this.setFieldsUnlocked,
        setFieldsLocked: this.setFieldsLocked,
        setFieldsUnloading: this.setFieldsUnloading,
        setFieldsLoading: this.setFieldsLoading,
      })
    );
    return <>{childrenWithUIControls}</>;
  }
}
