export const MODULE_NAME = "BUSINESS-INTELLIGENCE"; // Module display name
export const MODULE_DISPLAY_NAME = "Business Intelligence";
export const MODULE_SHORT_DISPLAY_NAME = "Business Intelligence (BI)";
export const MODULE_URN = "business-intelligence"; // module url prefix
export const MODULE_UNIQUE = "businessIntelligence"; // module unique identifier within swan system and this should be unique throught the system

export default {
  MODULE_NAME,
  MODULE_DISPLAY_NAME,
  MODULE_URN,
  MODULE_UNIQUE,
  MODULE_SHORT_DISPLAY_NAME,
};
