import Axios from "axios";
import {
  getEcho as getEchoApi,
  createEcho,
  deleteEcho as deleteEchoApi,
} from "../echo";

export function getEcho() {
  if (getEchoApi() === null) {
    return createEcho(Axios.defaults.headers.Authorization);
  }
  return getEchoApi();
}

export function deleteEcho() {
  return deleteEchoApi();
}

export function join(channel: string, event: string, callback: any) {
  const Echo = getEcho();
  if (Echo) {
    Echo.private(channel).listen(event, e => {
      callback(e);
    });
  } else {
    throw new Error("Echo not initiated");
  }
}

export default { getEcho, deleteEcho, join };
