import React, { Component } from "react";
import { connect } from "react-redux";
import { type Location, type Match } from "react-router-dom";
import {
  Card,
  CardBody,
  CardGroup,
  Col,
  Container,
  Row,
  Progress,
} from "reactstrap";

export type SwitchOrganizationProps = {
  activeOrganization: number,
  location: Location,
  match: Match,
};

class SwitchOrganization extends Component<SwitchOrganizationProps> {
  componentDidMount() {
    this.checkOrganization();
  }

  componentDidUpdate() {
    this.checkOrganization();
  }

  checkOrganization() {
    if (this.timeout) {
      return;
    }
    const {
      activeOrganization,
      match: {
        params: { targetId },
      },
      location: { state },
    } = this.props;

    const redirectTo = state && state.redirectTo ? state.redirectTo : "/";

    if (activeOrganization * 1 === parseInt(targetId || "")) {
      this.timeout = setTimeout(() => {
        window.location.href = redirectTo;
      }, 5000);
    }
  }

  timeout: any;

  render() {
    const {
      location: { state },
    } = this.props;
    const name = state ? state.name : "";
    return (
      <div className="animated fadeIn">
        <div className="app flex-row align-items-center">
          <Container>
            <Row className="justify-content-center align-content-center min-vh-100">
              <Col md="8">
                <CardGroup>
                  <Card
                    className="text-white py-5 d-md-down-none"
                    style={{ backgroundColor: "#1f4083" }}
                  >
                    <CardBody className="text-center">
                      <div>
                        <h2>Switching Organization</h2>
                        <p>{`Please wait while we redirect you to ${name}`}</p>
                        <br />
                        <Progress animated value={100} color="info" />
                      </div>
                    </CardBody>
                  </Card>
                </CardGroup>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    );
  }
}

function mapStateToProps({ user }: Object) {
  return {
    activeOrganization: user.activeOrganization,
  };
}
export default connect(mapStateToProps)(SwitchOrganization);
