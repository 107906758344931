export default {
  pencilDef: {
    title: "Tabs",
    group: "LAYOUT",
    handleIcon: "FaRegWindowRestore",
    styles: {},
  },
  drop: {
    allowTypes: ["tab"],
  },
  options: {
    title: "Untitled",
    type: "tabs",
    direction: "horizontal",
    defaultProperties: {
      tab1: {
        type: "tab",
        title: "Tab 1",
        properties: {},
      },
    },
  },
};
