import * as React from "react";
import Styled from "styled-components";

type ConfigType = {
  classNames?: string,
};

const ModuleWrapper = Styled.div``;

export const ModuleContent = ({ children }: any) => (
  <ModuleWrapper className="module-content">{children}</ModuleWrapper>
);

export default (
  Component: React.ComponentType<any>,
  config?: ConfigType = {}
) => (props: any) => {
  const { classNames } = config;
  return (
    <ModuleWrapper
      className={`module-content ${
        (props || {}).moduleContainerCustomClass
      } ${classNames || ""}`}
    >
      <Component {...props} />
    </ModuleWrapper>
  );
};
