import Axios from "axios";
import { ServiceGenerator } from "@swan/api";

const BaseWorkflowService = ServiceGenerator(Axios, "workflow");
const WorkflowService = ServiceGenerator(Axios, "workflow");

class CachedWorkflowService extends BaseWorkflowService {
  workflowStatusByObject = {};

  objectWorkflowStatusPromises = {};

  /**
   * Load formsById
   */
  loadWorkflowStatusByObject(
    object: string,
    id: number,
    workflowId: number
  ): Promise<Object> {
    const key = `objects/${object}/${id}/status/${workflowId}`;
    if ((this.workflowStatusByObject || {})[key]) {
      return Promise.resolve(this.workflowStatusByObject[key]);
    }
    if (this.objectWorkflowStatusPromises[key]) {
      return this.objectWorkflowStatusPromises[key];
    }

    this.objectWorkflowStatusPromises[key] = this.get(key).then(response => {
      this.workflowStatusByObject[key] = response;
      this.objectWorkflowStatusPromises[key] = null;
      return response;
    });
    return this.objectWorkflowStatusPromises[key];
  }

  getObjectWorkflowStatus(
    object: string,
    id: number,
    workflowId: number,
    forceLoad: boolean = false
  ) {
    const key = `objects/${object}/${id}/status/${workflowId}`;
    if (forceLoad) {
      if ((this.workflowStatusByObject || {})[key]) {
        delete (this.workflowStatusByObject || {})[key];
      }
    }
    if (!(this.workflowStatusByObject || {})[key]) {
      return this.loadWorkflowStatusByObject(object, id, workflowId);
    }
    return Promise.resolve(this.workflowStatusByObject[key]);
  }

  clearLocalObjectWorkflowStatus(
    object: string,
    id: number,
    workflowId: number
  ) {
    const key = `objects/${object}/${id}/status/${workflowId}`;
    if ((this.workflowStatusByObject || {})[key]) {
      delete (this.workflowStatusByObject || {})[key];
    }
  }
}

export const CacheWorkflowServiceInstance = new CachedWorkflowService();

WorkflowService.prototype.getObjectWorkflowStatus = function getObjectWorkflowStatus(
  object: string,
  id: number,
  workflowId: number,
  forceLoad: boolean = false
) {
  return CacheWorkflowServiceInstance.getObjectWorkflowStatus(
    object,
    id,
    workflowId,
    forceLoad
  );
};

WorkflowService.prototype.clearLocalObjectWorkflowStatus = function clearLocalObjectWorkflowStatus(
  object: string,
  id: number,
  workflowId: number
) {
  return CacheWorkflowServiceInstance.clearLocalObjectWorkflowStatus(
    object,
    id,
    workflowId
  );
};

export default WorkflowService;
