const ApplicationId = "034caecb-6654-480e-8de2-79a7b929f3e5";
const TenantId = "b9a19126-e60c-474a-b28b-aea5cb8ce800";
const baseURL = encodeURIComponent(window.location.origin);
const apiBaseURL = process.env.REACT_APP_API_URL || "http://localhost";

export { default as services } from "./services";
export { google, getAwsLexBotConfig, getAwsIdentityPoolId } from "./services";

export default {
  apiBaseURL: `${apiBaseURL}/api/v1/`,
  ssoURL: `https://login.microsoftonline.com/${TenantId}/oauth2/v2.0/authorize?client_id=${ApplicationId}&response_type=id_token+token&redirect_uri=${baseURL}%2Fchecklogin%2F&scope=openid%20email%20profile&response_mode=fragment&state=${Math.random() *
    100000}&nonce=${Math.random() * 100000}`,
  publicURL: `${apiBaseURL}`,
  languages: [
    { id: "en", label: "English" },
    { id: "ar", label: "Arabic" },
    { id: "hi", label: "Hindi" },
    { id: "ur", label: "Urdu" },
  ],
};
