export const GLOBAL_NAV_WIDTH = 64;
export const GLOBAL_NAV_ICON_SIZE = 24;
export const GLOBAL_NAV_COLOR = "#1f4083";
export const SIDEBAR_NAV_WIDTH__REM = 16;
export const GLOBAL_NAV_WIDTH__REM = 3;
export default {
  GLOBAL_NAV_WIDTH,
  GLOBAL_NAV_ICON_SIZE,
  GLOBAL_NAV_COLOR,
  SIDEBAR_NAV_WIDTH__REM,
  GLOBAL_NAV_WIDTH__REM,
};
