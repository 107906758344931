/* eslint-disable no-underscore-dangle */
import { ObjectService } from "@swan/api"; // eslint-disable-line
import { ObjectService as ObjectSrv } from "./services"; // needs to replace by FormService

const prepareObjectRelationFormCascade = (
  data: Object,
  relationshipsToCascade?: string | Array<string> = ["Polymorphic", "OneToMany"]
) => {
  // const CacheFormService = new FormService();
  const relationshipTypes = !Array.isArray(relationshipsToCascade)
    ? [relationshipsToCascade]
    : relationshipsToCascade;
  if (
    (data || {}).relationships &&
    typeof data.relationships === "object" &&
    Object.keys(data.relationships).length
  ) {
    const hasRelationshipsOf = Object.keys(data.relationships).filter(
      relatedObjectName =>
        relationshipTypes.indexOf(
          data.relationships[relatedObjectName].type
        ) !== -1
    );

    if (hasRelationshipsOf.length) {
      const currentObjectData = {
        __type: "object",
        label: data.display_name,
        value: data.name,
        key: data.key,
        children: [],
      };
      hasRelationshipsOf.forEach(async realtionshipOfKey => {
        const currentRelationData = {
          __type: "relationship",
          label: data.relationships[realtionshipOfKey].name,
          value: realtionshipOfKey,
          __parent_object: data.relationships[realtionshipOfKey].parent_object,
          __child_object: data.relationships[realtionshipOfKey].child_object,
          children: [],
        };
        const objectSrv = new ObjectSrv();
        const ro =
          data.name === currentRelationData.__parent_object
            ? currentRelationData.__child_object
            : currentRelationData.__parent_object;

        objectSrv.setObject(ro);
        const currentRelatedObjectForm = await objectSrv.get("forms");
        const currentRelatedForms =
          (currentRelatedObjectForm || {}).data || currentRelatedObjectForm;

        currentRelationData.children = currentRelatedForms.map(form => ({
          __type: "form",
          label: form.name,
          value: form.id,
        })); // currentRelatedObjectForm;

        currentObjectData.children.push(currentRelationData);
      });

      return currentObjectData;
    }
  }

  return {};
};

export default async (
  object: string | Array<string> | Array<Object> = "",
  relationshipsToCascade?: string | Array<string> = ["Polymorphic", "OneToMany"]
): Promise<Object> => {
  let objectIsEmpty = false;
  if (
    (typeof object === "string" && object === "") ||
    (Array.isArray(object) && !object.length) ||
    object === null
  ) {
    objectIsEmpty = true;
  }
  // if (typeof object === "string" && object === "") objectIsEmpty = true;
  // if (Array.isArray(object) && !object.length) objectIsEmpty = true;
  // if (object === null) objectIsEmpty = true;

  if (!objectIsEmpty) {
    let objectData;
    const objectsDataCascaded = {
      __type: "list",
      label: "Object Lists",
      value: "__children",
      children: [],
    };
    if (typeof object === "string") {
      if (object === "") {
        const objectsFromService = await ObjectService.get();
        const allObjects = (objectsFromService || {}).data
          ? objectsFromService.data
          : objectsFromService;
        allObjects.forEach(data => {
          objectsDataCascaded.children.push(
            prepareObjectRelationFormCascade(data, relationshipsToCascade)
          );
          // objectsDataCascaded.push(prepareCascader(data));
        });
      } else {
        objectData = await ObjectService.getObjectByName(object);
        // return [prepareObjectRelationFormCascade(objectData)];
        objectsDataCascaded.children.push(
          prepareObjectRelationFormCascade(objectData, relationshipsToCascade)
        );
      }
    } else {
      // we received array of objects
      objectData = [];
      const promises = (
        object.map(o => {
          // if items of array are type of string
          if (typeof o === "string") {
            return ObjectService.getObjectByName(o).then(dt => {
              objectsDataCascaded.children.push(
                prepareObjectRelationFormCascade(dt, relationshipsToCascade)
              );
            });
          }
          // if items of array are object and it has property name in it
          if (typeof o === "object" && (o || {}).name) {
            return ObjectService.getObjectByName(o.name).then((dt: Object) => {
              const d = {
                ...dt,
                key: o.key || dt.name, // If no key is provided, default to object name
              };
              objectsDataCascaded.children.push(
                prepareObjectRelationFormCascade(d, relationshipsToCascade)
              );
            });
          }
          // if otherwise
          return false;
        }) || []
      )
        // filter false values
        .filter(oo => oo !== false);
      await Promise.all(promises);
    }
    return [objectsDataCascaded];
  }

  return [];
};
