export const MODULE_NAME = "HYPERION"; // Module display name
export const MODULE_DISPLAY_NAME = "Hyperion Performance Management";
export const MODULE_SHORT_DISPLAY_NAME = "Hyperion Financial Management";
export const MODULE_URN = "hyperion"; // module url prefix
export const MODULE_UNIQUE = "hyperion"; // module unique identifier within swan system and this should be unique throught the system

export default {
  MODULE_DISPLAY_NAME,
  MODULE_SHORT_DISPLAY_NAME,
  MODULE_NAME,
  MODULE_URN,
  MODULE_UNIQUE,
};
