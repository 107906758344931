// import t from "../../../../i18n/t";
import getEnum from "../../attributeEnumerators";
import addressComponentsDict from "./dictionary";
// import { type optionPairType } from "../composer";

export default (/* options: optionPairType */) => {
  const { attributesEnum, attributesEnumNames } = getEnum();

  const schema = {
    type: "object",
    title: "Address Component Map",
    properties: {},
  };
  Object.keys(addressComponentsDict).forEach(addressComponent => {
    Object.assign(schema.properties, {
      [addressComponent]: {
        title: addressComponentsDict[addressComponent],
        type: "string",
        enum: attributesEnum,
        enumNames: attributesEnumNames,
      },
    });
  });

  return {
    schema: {
      ...schema,
    },
    uiSchema: {},
  };
};
