// @flow
import { has, hasIn } from "lodash";
import React, { Component } from "react";
import { Draggable } from "react-beautiful-dnd";
import { FaEllipsisH, FaRegCheckSquare } from "react-icons/fa";
import { connect } from "react-redux";
import styled from "styled-components";

import {
  // Button,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  // Input,
} from "reactstrap";

// defintions
// import { allDefinitions } from "../../definitions/pencils";
import { quickActionsDisabled } from "../../definitions/pencils/config";

// state
import { schemaActions } from "../../state/reducers/schema";

// component
import { InkDrop, InkDropCol, InkDropInput } from "./InkDrop";
import InkDropModal from "./InkDropModal";

// type
import type { Schema } from "../../constants/flowTypes";
import type {
  PencilDefOptions as pencilDefOptionsType,
  pencilDef as pencilDefType,
} from "../../types";

// utils
// import { getJsonSchema } from "../../utils/penOptionsSchema";
import getObjectFullness from "../../utils/getObjectFullness";
import sanitizeOptions from "../../utils/penOptions/sanitizeOptions";

// styled
const DropDownMenuStyled = styled(DropdownMenu)`
  border-radius: 0;
  border: 1px solid $ui-03;
  &[data-placement="top-end"] {
    box-shadow: 0 1px 1px 0 $ui-03;
  }
  &[data-placement="bottom-end"] {
    box-shadow: 0 0 1px 1px $ui-03;
  }
`;

type Props = {
  id: string,
  index: number,
  schema: Schema,
  updateElementSchema: (id: string, shcema: Schema) => void,
  removeSchemaNode: (id: string) => void,
  pencils: any,
  allDefinitions: Object,
};

type State = {
  dropDownOpen: boolean,
  optionModalOpen: boolean,
  isEditing: boolean,
  inkDropLabel: any,
};

class EditField extends Component<Props, State> {
  inkDropInputRef = React.createRef();

  constructor(props: Props) {
    super(props);
    this.state = {
      dropDownOpen: false,
      optionModalOpen: false,
      isEditing: false,
      inkDropLabel: "",
    };
  }

  componentDidMount() {
    const {
      schema: { label },
    } = this.props;
    this.setState({
      inkDropLabel: label,
    });
  }

  toggleDropDown = (): void => {
    this.setState(prevState => ({ dropDownOpen: !prevState.dropDownOpen }));
  };

  toggleOptionModal = (): void => {
    this.setState(prevState => ({
      optionModalOpen: !prevState.optionModalOpen,
    }));
  };

  toggleInlineEditing = (): void => {
    this.setState(prevState => ({
      isEditing: !prevState.isEditing,
    }));
  };

  blurInkDropLabel = (): void => {
    if (this.inkDropInputRef.current) {
      this.inkDropInputRef.current.blur();
    }
  };

  focusInkDropLabel = (): void => {
    const { isEditing } = this.state;
    if (!isEditing) {
      this.setState(() => ({ isEditing: true }));
    }
    if (this.inkDropInputRef.current) this.inkDropInputRef.current.focus();
  };

  updateInkDropLabel = (labelNow: string): void => {
    this.setState({ inkDropLabel: labelNow });
    this.updateInkDropLabelSchema(labelNow);
  };

  updateInkDropLabelSchema = (labelNow: string) => {
    const { id, schema, updateElementSchema } = this.props;
    const updatedSchema = Object.assign(
      { ...schema },
      { label: labelNow },
      { options: { ...schema.options, title: labelNow } }
    );
    updateElementSchema(id, updatedSchema);
  };

  // handler for saving inkdrop options
  onSubmitHandler = ({ formData }: { formData: pencilDefOptionsType }) => {
    const { id, schema, updateElementSchema } = this.props;
    this.setState({ inkDropLabel: formData.title });

    const formDataSanitized = getObjectFullness(formData);

    const updatedSchema = Object.assign(
      { ...schema },
      { label: formData.title },
      { options: formDataSanitized }
    );

    updateElementSchema(id, updatedSchema);
    this.toggleOptionModal();
  };

  // handler for removing ink-drop from schema
  removeInkDrop = () => {
    const { id, removeSchemaNode } = this.props;
    removeSchemaNode(id);
  };

  markAs = (
    optionProperty: string = "required",
    makeItTrue: boolean = true
  ) => {
    const { id, schema, updateElementSchema } = this.props;
    let updatedSchema = { ...schema };
    if (!(updatedSchema || {}).options) {
      updatedSchema = Object.assign({ ...updatedSchema }, { options: {} });
    }
    updatedSchema = Object.assign(
      { ...updatedSchema },
      { options: { ...updatedSchema.options, [optionProperty]: makeItTrue } }
    );
    updateElementSchema(id, updatedSchema);
  };

  render() {
    const {
      schema: { id: schemaId, label, type: pencilType, options },
      id,
      index,
      allDefinitions,
    } = this.props;
    const {
      dropDownOpen,
      optionModalOpen,
      isEditing,
      inkDropLabel,
    } = this.state;

    let pencilDef: pencilDefType | boolean =
      pencilType && hasIn(allDefinitions, [pencilType, "pencilDef"])
        ? allDefinitions[pencilType].pencilDef
        : false;
    if (!pencilDef) {
      pencilDef = allDefinitions[schemaId]
        ? allDefinitions[schemaId].pencilDef || false
        : false;
    }

    const pencilDefTitle: any =
      pencilDef && has(pencilDef, "title") ? pencilDef.title : label;

    let pencilDefOptions: Object =
      pencilType && hasIn(allDefinitions, [pencilType, "options"])
        ? allDefinitions[pencilType].options
        : undefined;
    if (!pencilDefOptions) {
      pencilDefOptions = allDefinitions[schemaId]
        ? allDefinitions[schemaId].options || false
        : false;
    }

    const pencilIsRequired =
      has(options, "required") && options.required === true;

    const pencilIsListed = has(options, "listed") && options.listed === true;

    const isQuickActionsDisabled =
      quickActionsDisabled.indexOf(pencilType) !== -1;

    let initialData = { ...sanitizeOptions(options, pencilDefOptions) };

    if (pencilType === "lookup") {
      if (
        (initialData || {}).filters &&
        Array.isArray(initialData.filters) &&
        !initialData.filters.length
      ) {
        initialData = { ...initialData, filters: {} };
      }

      if (
        (initialData || {}).filters &&
        Array.isArray(initialData.map) &&
        !initialData.map.length
      ) {
        initialData = { ...initialData, map: {} };
      }
    }

    return (
      <Draggable draggableId={id} index={index || 0}>
        {draggableProvided => (
          <InkDrop
            ref={draggableProvided.innerRef}
            {...draggableProvided.draggableProps}
            {...draggableProvided.dragHandleProps}
            className={`ink-drop-wrapper ${
              dropDownOpen ? "ink-dropdown-open" : ""
            }`}
            dropDownOpen={dropDownOpen}
            pencilIsRequired={pencilIsRequired}
          >
            <InkDropCol size={5} colName="inkTitle">
              <InkDropInput
                bsSize="sm"
                type="text"
                value={isEditing ? inkDropLabel : label}
                onChange={e => this.updateInkDropLabel(e.target.value)}
                onKeyPress={e =>
                  e.key === "Enter" ? this.blurInkDropLabel() : null
                }
                innerRef={this.inkDropInputRef}
              />
            </InkDropCol>
            <InkDropCol
              size={4}
              colName="inkLabel"
              onClick={this.focusInkDropLabel}
            >
              {pencilDefTitle}
            </InkDropCol>
            <InkDropCol size={1}>
              <Dropdown
                isOpen={dropDownOpen}
                toggle={this.toggleDropDown}
                direction="down"
              >
                <DropdownToggle
                  size="sm"
                  outline
                  className="outline outline-clear"
                >
                  <FaEllipsisH />
                </DropdownToggle>
                <DropDownMenuStyled right>
                  {!isQuickActionsDisabled && (
                    <React.Fragment>
                      <DropdownItem
                        onClick={() => {
                          this.markAs("required", !pencilIsRequired);
                        }}
                      >
                        {!pencilIsRequired ? (
                          <span>Mark as required</span>
                        ) : (
                          <span>Mark as not required</span>
                        )}
                      </DropdownItem>
                      <DropdownItem
                        onClick={() => {
                          this.markAs("listed", !pencilIsListed);
                        }}
                      >
                        {!pencilIsListed ? (
                          <span>Mark as listed</span>
                        ) : (
                          <span>Mark as not listed</span>
                        )}
                      </DropdownItem>
                      <DropdownItem divider />
                    </React.Fragment>
                  )}
                  <DropdownItem
                    onClick={() => {
                      this.toggleOptionModal();
                    }}
                  >
                    Edit Properties
                  </DropdownItem>
                  <DropdownItem divider />
                  <DropdownItem onClick={this.removeInkDrop}>
                    Delete this
                  </DropdownItem>
                </DropDownMenuStyled>
              </Dropdown>
              {pencilIsListed && <FaRegCheckSquare />}
            </InkDropCol>
            <InkDropModal
              title="Options"
              pencilType={pencilType || ""}
              schemaId={schemaId || ""}
              pencilDefOptions={pencilDefOptions}
              isOpen={optionModalOpen}
              toggleHandler={this.toggleOptionModal}
              onCloseHandler={this.toggleOptionModal}
              onSubmitHandler={this.onSubmitHandler}
              initialFormData={Object.assign(
                {},
                { ...initialData },
                { title: inkDropLabel }
              )}
            />
          </InkDrop>
        )}
      </Draggable>
    );
  }
}

function mapStateToProps({ app }) {
  return {
    allDefinitions: app.allDefinitions,
  };
}

export default connect(
  mapStateToProps,
  {
    updateElementSchema: schemaActions.updateElementSchema,
    removeSchemaNode: schemaActions.removeSchemaNode,
  }
)(EditField);
