import * as React from "react";
import { connect } from "react-redux";

import styled, { withTheme } from "styled-components";
import { Draggable } from "react-beautiful-dnd";
import { Button } from "reactstrap";

import { FaPlusCircle } from "react-icons/fa";

import Parts from "../../definitions/parts";
import Pencils from "../../definitions/index";
import Row from "./Row";
import ConditionalDroppable from "../DragDrop/ConditionalDroppable";

import { schemaActions } from "../../state/reducers/schema";

import type { Props } from "./types";
import type { Schema } from "../../constants/flowTypes";

import { ArtContainer } from "../Art";

type GridProps = Props & {
  updateElementSchema: (string, Schema) => void,
};

const AddButton = styled(Button)({
  border: "none",
  fontSize: 20,
});

const RowsWrapper = styled.div(({ theme, isEmpty }) =>
  Object.assign(
    {},
    theme.RowsWrapper ? { ...theme.RowsWrapper } : {},
    // Note: Grid should have at least one Row and a Row should contain
    // at least one Col, to retain the actual purpose of Grid
    // this condition doesn't make any sense with the idea of Grid
    // Empty should be checked for Col as it'd be the least-droppable in Grid's case
    isEmpty ? { ...theme.RowWrapperEmpty } : {}
  )
);

class Grid extends React.Component<GridProps> {
  getRows = properties => {
    const { id } = this.props;
    if (!properties) return [];
    return Object.keys(properties).map(
      (rowName: string, index: number) =>
        properties[rowName].type === "row" && (
          <Row
            index={index}
            key={`${id}/${rowName}`}
            gridId={id}
            id={`${id}/${rowName}`}
            schema={properties[rowName]}
            rowCount={Object.keys(properties).length}
          />
        )
    );
  };

  addRow = () => {
    const { schema, id, updateElementSchema } = this.props;
    if (!schema.properties) {
      schema.properties = {};
    }
    let idx = Object.keys(schema.properties).length;
    while (schema.properties[`row${idx}`]) {
      idx += 1;
    }
    schema.properties[`row${idx}`] = {
      type: Parts.Row.options.type,
      properties: { ...Parts.Row.options.defaultProperties },
    };
    updateElementSchema(id, schema);

    const evt = new Event("FormDesigner::Refresh");
    window.dispatchEvent(evt);
  };

  render() {
    const {
      schema: { properties },
      id,
      index,
    } = this.props;
    const rows = this.getRows(properties);
    return (
      <Draggable
        draggableId={id}
        index={index || 0}
        isDragDisabled={id === "@"}
      >
        {draggableProvided => (
          <ArtContainer
            ref={draggableProvided.innerRef}
            {...draggableProvided.draggableProps}
            {...draggableProvided.dragHandleProps}
          >
            <ConditionalDroppable
              droppableId={id}
              allowTypes={
                Pencils.pencils.layoutDefinitions.grid.drop.allowTypes
              }
              droppableWrapper="Grid"
            >
              <RowsWrapper isEmpty={rows.length === 0}>
                {rows}
                <AddButton
                  outline
                  block
                  onClick={this.addRow}
                  color="secondary"
                >
                  <FaPlusCircle />
                </AddButton>
              </RowsWrapper>
            </ConditionalDroppable>
          </ArtContainer>
        )}
      </Draggable>
    );
  }
}

export default connect(
  null,
  { updateElementSchema: schemaActions.updateElementSchema }
)(withTheme(Grid));
