import React, { Component, type Node } from "react";
import { FaAngleRight, FaAngleLeft } from "react-icons/fa";
import styled from "styled-components";
import classNames from "classnames";

type PanelProps = {
  isOpen: boolean,
  children: Node,
  direction?: string,
  width?: number | string,
};

type PanelState = {
  isPanelOpen: boolean,
};

const PanelWrapper = styled.div(({ width, isPanelOpen }) => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "flex-start",
  alignItems: "center",
  alignSelf: "stretch",
  width: isPanelOpen ? width : "1rem",
  transition: "all .2s",
}));

const PanelContent = styled.div(({ isPanelOpen }) =>
  Object.assign(
    {},
    {
      alignSelf: "stretch",
      width: isPanelOpen ? "100%" : 0,
      overflowX: "hidden",
      // transition: "all .2s",
    },
    isPanelOpen ? { display: "flex", flex: 1 } : {}
  )
);

const PanelSwitcher = styled.div({});

class Panel extends Component<PanelProps, PanelState> {
  static defaultProps = {
    direction: "vertical",
    width: 250,
  };

  constructor(props: any) {
    super(props);
    const { isOpen } = this.props;
    this.state = {
      isPanelOpen: isOpen || true,
    };
  }

  togglePanel = () => {
    this.setState(prevState => ({
      isPanelOpen: !prevState.isPanelOpen,
    }));
  };

  render() {
    const { isPanelOpen } = this.state;
    const { children, direction, width } = this.props;
    return (
      <PanelWrapper
        className={`${classNames("panel", {
          "is-open": isPanelOpen,
          "is-closed": !isPanelOpen,
        })}`}
        width={width}
        isPanelOpen={isPanelOpen}
      >
        <PanelContent
          className="panel-content first-child-fullwidth ui-02--bg brand-01--border"
          isPanelOpen={isPanelOpen}
        >
          {children}
        </PanelContent>
        <PanelSwitcher
          type="button"
          className={`${classNames("panel-switcher", {
            "switcher-is-open": isPanelOpen,
          })}`}
          onClick={this.togglePanel}
          data-direction={direction}
        >
          <span className="switcher-button">
            {isPanelOpen && <FaAngleLeft />}
            {!isPanelOpen && <FaAngleRight />}
          </span>
        </PanelSwitcher>
      </PanelWrapper>
    );
  }
}

export default Panel;
