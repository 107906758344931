import React from "react";
import styled from "styled-components";
import { Card } from "reactstrap";

const Wrapper = styled(Card)`
  margin: 0 0.5rem;
  padding: 1rem;
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const Label = styled("div")`
  font-size: 0.8rem;
  font-weight: bold;
  text-transform: uppercase;
`;
const Counter = styled("div")`
  font-size: 2rem;
`;

type Props = {
  key?: string,
  count?: number,
  label?: string,
  // styles?: Object
};

const Widget = (props: Props) => {
  const { key, count, label } = props;
  return (
    <Wrapper key={key}>
      <Label>{label || "Untitled"}</Label>
      <Counter className="static-counter counter-value">{count}</Counter>
    </Wrapper>
  );
};

Widget.defaultProps = {
  key: "SWAN_COUNTER",
  label: "Undefined",
  count: 0,
};

export default Widget;
