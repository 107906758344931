import React from "react";
import Styled from "styled-components";
import { Link } from "react-router-dom";
import { withSwanContext } from "@swan/state";
import { GiHamburgerMenu } from "react-icons/all";
import { withLayout } from "@swan/themer";

import { PAGE_HEADER_BRAND } from "./config";
import { DEFAULT_LANDING_PAGE } from "../../nav/config";

const Wrapper = Styled.div`
display: flex;
  height: 4.5rem;
  width: 100%;
  padding: 0.25rem 1rem;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;

  .header-logo, .active-org-logo {
    flex: 1;
    display: flex;
    align-items: center;
    height: 100%;
  }

  .header-logo  {
    justify-content: flex-start;
    .header-logo--link {
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;


      img {
        height: 45%;
      }
    }
  }
  .active-org-logo {
    justify-content: flex-end;
    img {
      height: 50px;
    }
  }
`;

const getActiveOrgLogo = orgid =>
  `https://s3.ap-southeast-1.amazonaws.com/assets.swan.mazrui.ae/Administration/CorporateIdentity/${orgid}/SWAN_ORG_LOGO.png`;
const PageHeader = ({ layout, SwanContext: { user } }: any) => {
  const { toggleProductNavigation } = layout;

  return (
    <Wrapper className="page-header">
      <div className="header-logo d-none d-md-flex">
        <Link to={DEFAULT_LANDING_PAGE} className="header-logo--link">
          <img alt="header-logo--img" src={PAGE_HEADER_BRAND} />
        </Link>
      </div>
      <div className="d-flex d-md-none">
        <button
          type="button"
          className="border-0 outline-clear bg-transparent"
          onClick={() => toggleProductNavigation("DEFAULT")}
        >
          <GiHamburgerMenu style={{ color: "#1f4083" }} size={24} />
        </button>
      </div>
      <div className="active-org-logo">
        <img
          src={getActiveOrgLogo((user || {}).active_organization || 1)}
          alt="ORG LOG"
        />
      </div>
    </Wrapper>
  );
};

export default withSwanContext(withLayout(PageHeader));
