import * as React from "react";
import { connect } from "react-redux";
import styled, { withTheme } from "styled-components";
import { Draggable } from "react-beautiful-dnd";
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";

import { FaPlusCircle, FaSync } from "react-icons/fa";

import Parts from "../../definitions/parts";
import Pencils from "../../definitions/index";
import Tab from "./Tab";
import TabName, { TabTitle } from "./Partials/TabName";
import ConditionalDroppable from "../DragDrop/ConditionalDroppable";
import { schemaActions } from "../../state/reducers/schema";
import { appActions } from "../../state/reducers/app";

import type { Props } from "./types";
import type { Schema } from "../../constants/flowTypes";

type TabsProps = Props & {
  updateElementSchema: (string, Schema) => void,
  goToTab: (tabsId: string, tabIndex: number) => void,
  activeTab: number,
};

type TabsState = {
  hovering: ?number,
  vertical: boolean,
};

export const TabsWrapper = styled.div(({ theme, direction }) =>
  Object.assign(
    {},
    theme.TabsWrapper ? { ...theme.TabsWrapper } : {},
    direction === "vertical"
      ? {
          display: "flex",
          flexDirection: "row",
        }
      : {}
  )
);

const TabsNavWrapper = styled.div(({ theme }) =>
  theme.TabsNavWrapper ? { ...theme.TabsNavWrapper } : {}
);

const TabNavItemWrapper = styled.div(({ theme }) =>
  theme.TabNavItemWrapper ? { ...theme.TabNavItemWrapper } : {}
);

const TabContentWrapper = styled.div(({ theme, direction }) =>
  Object.assign(
    {},
    theme.TabContentWrapper ? { ...theme.TabContentWrapper } : {},
    direction === "vertical"
      ? {
          flexGrow: 1,
        }
      : {}
  )
);

const NavStyled = styled(Nav)`
  margin: 0;

  .nav-link {
    padding: 0.5rem;
  }
`;

const NavItemStyled = styled(NavItem)`
  display: flex;
`;

type GeneratedTab = {
  id: string,
  tabName: string,
  component: React.Node,
  options: Object,
};

class Tabs extends React.Component<TabsProps, TabsState> {
  state = {
    hovering: null,
    vertical: false,
  };

  componentDidMount() {
    this.mounted = true;
    const {
      schema: { options },
    } = this.props;
    const { vertical } = options || {};
    this.setState({
      vertical: vertical === true,
    });
  }

  componentWillUnmount() {
    this.mounted = false;
    if (this.timeout) {
      clearTimeout(this.timeout);
    }
  }

  getTabs = (properties: ?{ [string]: Schema }): { [string]: GeneratedTab } => {
    const { id } = this.props;
    const tabs = {};
    if (properties) {
      Object.keys(properties).forEach((tabName: string, index: number) => {
        if (properties) {
          if (properties[tabName].type === Parts.Tab.options.type) {
            tabs[properties[tabName].title || ""] = {
              id: `${id}/${tabName}`,
              tabName,
              options: properties[tabName].options,
              component: (
                <Tab
                  index={index}
                  key={`${id}/${tabName}`}
                  tabsId={id}
                  id={`${id}/${tabName}`}
                  schema={properties[tabName]}
                />
              ),
            };
          }
        }
      });
    }
    return tabs;
  };

  addTab = () => {
    const { schema, id, updateElementSchema } = this.props;
    if (!schema.properties) {
      schema.properties = {};
    }
    const currentTabs: Array<string> = Object.keys(schema.properties);
    let tabLastIndex: number = currentTabs.length;
    // const currentTabs = Object.keys(schema.properties).length;
    while (currentTabs.indexOf(`tab${tabLastIndex + 1}`) !== -1) {
      tabLastIndex += 1;
    }
    Object.assign(schema.properties, {
      [`tab${tabLastIndex + 1}`]: {
        type: Parts.Tab.options.type,
        title: `Tab ${tabLastIndex + 1}`,
        properties: { ...Parts.Tab.options.defaultProperties },
      },
    });
    updateElementSchema(id, schema);
  };

  onTabNameChange = (tabName: string, title: string) => {
    const { schema, id, updateElementSchema } = this.props;
    if (!schema.properties) {
      schema.properties = {};
    }

    schema.properties[tabName] = {
      ...schema.properties[tabName],
      title,
    };
    updateElementSchema(id, schema);
  };

  goToTab = (activeTab: number) => {
    const { goToTab, id } = this.props;
    goToTab(id, activeTab);
  };

  hovering = (index: number) => {
    this.setState({
      hovering: index,
    });
  };

  notHovering = (index: number) => {
    this.timeout = setTimeout(() => {
      if (this.mounted) {
        this.setState(state => ({
          hovering: state.hovering === index ? null : state.hovering,
        }));
      }
    }, 1000);
  };

  toggleTabDirection = () => {
    const { vertical } = this.state;
    const { schema, id, updateElementSchema } = this.props;
    const newSchema = Object.assign({}, schema, {
      options: { vertical: !vertical },
    });
    updateElementSchema(id, newSchema);

    this.setState({
      vertical: !vertical,
    });
  };

  updateTabSettings = (tabName: string, tabSettings: Object) => {
    const { schema, id, updateElementSchema } = this.props;
    if (!schema.properties) {
      schema.properties = {};
    }
    schema.properties[tabName] = {
      ...schema.properties[tabName],
      ...{
        options: tabSettings,
      },
    };
    updateElementSchema(id, schema);
  };

  timeout: any;

  mounted: boolean;

  render() {
    const {
      schema: { properties, options },
      id,
      index,
      activeTab,
    } = this.props;
    const { vertical } = options || {};
    const { hovering } = this.state;
    const tabs = this.getTabs(properties);
    return (
      <Draggable
        draggableId={id}
        index={index || 0}
        isDragDisabled={id === "@"}
      >
        {draggableProvided => (
          <TabsWrapper
            ref={draggableProvided.innerRef}
            {...draggableProvided.draggableProps}
            {...draggableProvided.dragHandleProps}
            direction={vertical ? "vertical" : "horizontal"}
          >
            <ConditionalDroppable
              droppableId={id}
              allowTypes={
                Pencils.pencils.layoutDefinitions.tabs.drop.allowTypes
              }
              direction={vertical ? "vertical" : "horizontal"}
              droppableWrapper="Tabs"
            >
              <TabsNavWrapper>
                <NavStyled tabs vertical={vertical}>
                  {Object.keys(tabs).map((tabName, tabIndex) => (
                    <Draggable
                      key={`draggableTab${tabIndex}`} // eslint-disable-line
                      draggableId={tabs[tabName].id}
                      index={tabIndex || 0}
                    >
                      {tabDraggableProvided => (
                        <TabNavItemWrapper
                          ref={tabDraggableProvided.innerRef}
                          {...tabDraggableProvided.draggableProps}
                          {...tabDraggableProvided.dragHandleProps}
                        >
                          <NavItem
                            onMouseMove={() => this.hovering(tabIndex)}
                            onFocus={() => this.hovering(tabIndex)}
                            onMouseOut={() => this.notHovering(tabIndex)}
                            onBlur={() => this.notHovering(tabIndex)}
                          >
                            <NavLink
                              className={activeTab === tabIndex ? "active" : ""}
                              onClick={() => this.goToTab(tabIndex)}
                            >
                              <TabName
                                title={tabName}
                                id={tabs[tabName].tabName}
                                onNameChange={this.onTabNameChange}
                                onTabSettingsChange={this.updateTabSettings}
                                schemaPathID={tabs[tabName].id}
                                tabSettings={
                                  tabs[tabName].options
                                    ? tabs[tabName].options
                                    : {}
                                }
                                showEditButton={hovering === tabIndex}
                                direction={vertical ? "vertical" : "horizontal"}
                              />
                            </NavLink>
                          </NavItem>
                        </TabNavItemWrapper>
                      )}
                    </Draggable>
                  ))}
                  <NavItemStyled>
                    <NavLink onClick={this.addTab}>
                      <TabTitle>
                        <FaPlusCircle size="1.2em" color="#727272" />
                      </TabTitle>
                    </NavLink>
                  </NavItemStyled>
                  <NavItemStyled>
                    <NavLink onClick={this.toggleTabDirection}>
                      <TabTitle>
                        <FaSync size="1.2em" color="#727272" />
                        {/* {vertical && (
                          <FaGripVertical size="1.6em" color="#727272" />
                        )}
                        {!vertical && (
                          <FaGripHorizontal size="1.6em" color="#727272" />
                        )} */}
                      </TabTitle>
                    </NavLink>
                  </NavItemStyled>
                </NavStyled>
              </TabsNavWrapper>
            </ConditionalDroppable>

            <TabContentWrapper
              empty={tabs.length === 0}
              direction={vertical ? "vertical" : "horizontal"}
            >
              <TabContent activeTab={activeTab}>
                {Object.values(tabs).map((tab, tabIndex) => (
                  // eslint-disable-next-line
                  <TabPane tabId={tabIndex} key={`tabpane-${tabIndex}`}>
                    {/* $FlowFixMe tab.component causes error since Object.values return mixed type */}
                    {tab.component}
                  </TabPane>
                ))}
              </TabContent>
            </TabContentWrapper>
          </TabsWrapper>
        )}
      </Draggable>
    );
  }
}

function mapStateToProps(state: Object, ownProps: { id: string }) {
  return {
    activeTab: state.app.tabsState[ownProps.id || ""] || 0,
  };
}

export default connect(
  mapStateToProps,
  {
    updateElementSchema: schemaActions.updateElementSchema,
    goToTab: appActions.goToTab,
  }
)(withTheme(Tabs));
