import * as React from "react";
import { Input, Checkbox } from "@swan/themer";

import Field from "./partials/Field";
import type { ElementProps } from "./types";

// context
// import { RendererSubscriber } from "../Main";

// utils
import getResourceDisplayValue from "../../utils/getResourceDisplayValue";

const ResourceDisplay = ({
  schema,
  // onChange,
  error,
  // readOnly,
  hideLabel,
  data,
}: ElementProps) => {
  const value = getResourceDisplayValue(
    {
      resourceDataAccessor: ((schema || {}).options || {}).resourceDataAccessor,
    },
    { data }
  );
  if (
    (schema.options || {}).hideIfEmpty === true &&
    (typeof value === "undefined" || value === null || value === "")
  ) {
    return null;
  }

  const isCheckbox = ((schema || {}).options || {}).inputType === "checkbox";
  const Component = isCheckbox ? Checkbox : Input;

  return (
    <Component
      {...schema.options || {}}
      name=""
      type={isCheckbox ? "checkbox" : "text"}
      value={value}
      label={schema.label}
      hideLabel={isCheckbox || hideLabel || (schema.options || {}).hideLabel}
      title={schema.label}
      // hideLabel
      placeholder={schema.label}
      error={(error || {})[schema.attributeName || ""]}
      // static={readOnly || (schema.options || {}).readOnly}
      static
    />
  );
};

export default Field(ResourceDisplay);
