// @flow
import React, { PureComponent } from "react";

import styled, { withTheme } from "styled-components";
import { connect } from "react-redux";
import { map, indexOf, has } from "lodash";
import { Collapse, Card } from "reactstrap";
import { FaAngleDown, FaAngleRight } from "react-icons/fa";

// state
import { appActions } from "../state/reducers/app/index";

// definitions
import { standardDefinitions, layoutDefinitions } from "../definitions/pencils";

// components
import PencilBox from "./PencilBox";

// i18n
import { t } from "../i18n";

// constants
import { PENCIL_BOX_ORDER } from "../constants/pencilTypes";

// utils
import {
  getObjectRelationDefinitions,
  getAttributeDefintions,
  getWorkflowsDefinitions,
  getVersioningDefinitions,
  getDuplicationsDefinitions,
  getChartsDefinitions,
} from "../utils/prepareDefs";

// type
import type {
  pencilDef as pencilDefType,
  theme as themeType,
  ObjectRelationPropType,
  ObjectType,
  WorkflowPropType,
} from "../types";

const PencilPanelWrapper = styled.div(({ theme }) =>
  theme.PencilPanelWrapper ? { ...theme.PencilPanelWrapper } : {}
);

const PencilBoxesWrapper = styled.div(({ theme }) =>
  theme.PencilBoxesWrapper ? { ...theme.PencilBoxesWrapper } : {}
);

const PencilBoxTitle = styled.h4(({ theme }) =>
  theme.PencilBoxTitle ? { ...theme.PencilBoxTitle } : {}
);

type Props = {
  pencilDefs: {},
  initPencils: (pencilDefs: {}) => any,
  pencilGroupsToggle: (groupTag: Array<string>) => void,
  app: {
    pencils: {},
    ui: {
      pencilGroupsOpen: Array<string>,
    },
  },
  object: ObjectType,
  objectAttrib: Array<{
    attributeId?: string,
    id: string,
    name?: string,
    label: string,
    type: string,
    pencilDef: pencilDefType,
  }>,
  objectRelation: ObjectRelationPropType,
  // pencils?: ExtendedPencilsType,
  workflows: WorkflowPropType,
  theme: themeType,
  attributesInUse: Array<string | number>,
  relatedObjectsInUse: Array<string>,
  otherObjectsInUse: Array<string>,
};

const PencilPanel = class PencilPanel extends PureComponent<Props> {
  componentDidMount() {
    const {
      pencilDefs,
      initPencils,
      object,
      objectAttrib,
      objectRelation,
      workflows,
    } = this.props;

    // processing relatedObject to match pencil definitions
    const objectRelationDefinitions = getObjectRelationDefinitions(
      object,
      objectRelation
    );

    // processing objectAttrib to match pencil definitions
    const attributeDefintions = getAttributeDefintions(object, objectAttrib);

    const workflowsDefinitions = getWorkflowsDefinitions(object, workflows);

    const versioningDefinitions = getVersioningDefinitions(object);

    const chartsDefinitions = getChartsDefinitions(object);

    const duplicationsDefinitions = getDuplicationsDefinitions(object);

    const pencilDefsMerged = Object.assign(
      {},
      standardDefinitions,
      layoutDefinitions,
      attributeDefintions,
      objectRelationDefinitions,
      workflowsDefinitions,
      versioningDefinitions,
      chartsDefinitions,
      duplicationsDefinitions,
      pencilDefs || {} // overides
    );
    initPencils(pencilDefsMerged);
  }

  toggleHandler = pencilsGroup => {
    const {
      app: {
        ui: { pencilGroupsOpen },
      },
      pencilGroupsToggle,
    } = this.props;

    const isOpen = indexOf(pencilGroupsOpen, pencilsGroup);
    if (isOpen !== -1) pencilGroupsOpen.splice(isOpen, 1);
    if (isOpen === -1) pencilGroupsOpen.push(pencilsGroup);

    pencilGroupsToggle(pencilGroupsOpen);
  };

  handleKeyPress = () => {};

  checkPencils(pencils, pencilsGroup) {
    if (pencilsGroup === "FIELD" || pencilsGroup === "LAYOUT") {
      return pencils;
    }
    const {
      attributesInUse,
      relatedObjectsInUse,
      otherObjectsInUse,
    } = this.props;

    return pencils.filter(pencil => {
      if (
        pencilsGroup === "ATTRIB"
        // &&
        // attributesInUse.includes(pencil.attributeId)
      ) {
        // return false;
        if (pencil.attributeId) {
          if (attributesInUse.includes(pencil.attributeId)) {
            return false;
          }
        } else if (pencil.id) {
          if (attributesInUse.includes(pencil.id)) {
            return false;
          }
        }
      }

      if (
        pencilsGroup === "RELOBJ" &&
        relatedObjectsInUse.includes(pencil.id)
      ) {
        return false;
      }

      if (otherObjectsInUse.includes(pencil.id)) {
        return false;
      }

      return true;
    });
  }

  render() {
    const {
      app: {
        pencils: pencilBoxes,
        ui: { pencilGroupsOpen },
      },
      theme: { PencilClasses, PencilBoxCard },
    } = this.props;

    const orderedPencilBoxes = {};
    map(PENCIL_BOX_ORDER, pencilGroup => {
      if (has(pencilBoxes, pencilGroup)) {
        Object.assign(orderedPencilBoxes, {
          [pencilGroup]: pencilBoxes[pencilGroup],
        });
      }
    });

    return (
      <PencilPanelWrapper
        className={PencilClasses.PencilPanelWrapper || "pencil-panel-wrapper"}
      >
        <PencilBoxesWrapper
          className={PencilClasses.PencilBoxesWrapper || "pencil-boxes-wrapper"}
        >
          {map(orderedPencilBoxes, (pencils, pencilsGroup) => (
            <React.Fragment key={pencilsGroup}>
              <PencilBoxTitle
                role="button"
                onClick={() => {
                  this.toggleHandler(pencilsGroup);
                }}
                onKeyPress={this.handleKeyPress}
                style={{ cursor: "pointer" }}
              >
                {t(pencilsGroup)}
                &nbsp;
                {indexOf(pencilGroupsOpen, pencilsGroup) !== -1 && (
                  <FaAngleDown />
                )}
                {indexOf(pencilGroupsOpen, pencilsGroup) === -1 && (
                  <FaAngleRight />
                )}
              </PencilBoxTitle>
              <Collapse isOpen={indexOf(pencilGroupsOpen, pencilsGroup) !== -1}>
                <Card
                  // inverse
                  style={PencilBoxCard}
                >
                  <PencilBox
                    key={pencilsGroup}
                    {...{
                      pencils: this.checkPencils(pencils, pencilsGroup),
                      pencilsGroup,
                    }}
                    rowItems={2}
                    pencilsCount={pencils.length}
                  />
                </Card>
              </Collapse>
            </React.Fragment>
          ))}
        </PencilBoxesWrapper>
      </PencilPanelWrapper>
    );
  }
};

const mapDispatchToProps = dispatch => ({
  initPencils: defs => dispatch(appActions.initPencils(defs)),
  pencilGroupsToggle: groupTag =>
    dispatch(appActions.pencilGroupsToggle(groupTag)),
});

const mapStateToProps = ({ app, schema }) => ({
  app,
  attributesInUse: schema.attributesInUse,
  relatedObjectsInUse: schema.relatedObjectsInUse,
  otherObjectsInUse: schema.otherObjectsInUse,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTheme(PencilPanel));
