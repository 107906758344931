import React, { Component } from "react";
import { connect } from "react-redux";
import { Card, CardBody, CardGroup } from "reactstrap";
import styled from "styled-components";

import { userActions } from "../../../state/reducers/user";

type Props = {
  history: {
    push: Function
  },
  loggedIn: boolean,
  logout: Function
};

const Wrapper = styled("div")`
  background: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  min-width: 100vw;

  .content {
    background: #294c86 !important;
    width: 80% !important;

    @media (min-width: 786px) {
      width: 50% !important;
    }
  }
`;

class Logout extends Component<Props> {
  static defaultProps = {
    loggedIn: false
  };

  componentDidMount() {
    const { loggedIn, history, logout } = this.props;
    if (!loggedIn) {
      history.push("/");
    } else {
      logout();
    }
  }

  componentDidUpdate() {
    const { loggedIn, history } = this.props;
    if (!loggedIn) {
      history.push("/");
    }
  }

  render() {
    return (
      <Wrapper>
        <div className="app flex-row align-items-center">
          <CardGroup>
            <Card
              className="content text-white bg-primary py-5 d-md-down-none"
              style={{ width: "44%" }}
            >
              <CardBody className="text-center">
                <div>
                  <h2>
                    {/* prettier-ignore */}
                    Logging you out!
                  </h2>
                  <p>
                    We are loggin you out, you will be redirected shortly...
                  </p>
                </div>
              </CardBody>
            </Card>
          </CardGroup>
        </div>
      </Wrapper>
    );
  }
}

function mapStateToProps(state) {
  return {
    loggedIn: state.user.loggedIn
  };
}

export default connect(
  mapStateToProps,
  {
    logout: userActions.logout
  }
)(Logout);
