import React from "react";
import styled from "styled-components";

import Spinner from "../Spinner";

const Skeleton = styled("div")`
  padding: 0.4rem;
  margin: 0.4rem auto;
  height: 4rem;
  background: #f4f7fb;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export default (props: any) => (
  <Skeleton {...props}>
    <Spinner color="primary" />
  </Skeleton>
);
