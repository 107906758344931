import React from "react";
import { uriProvider } from "./utils/uriHelpers";
import {
  MODULE_NAME,
  MODULE_DISPLAY_NAME,
  MODULE_SHORT_DISPLAY_NAME,
} from "./config";

// $FlowFixMe Should be React.lazy<DashboardProps> but causing issue in webpack
const Home = React.lazy(() => import("./Module"));

// https://github.com/ReactTraining/react-router/tree/master/packages/react-router-config
const entry_routes = [
  {
    path: uriProvider(),
    name: MODULE_NAME,
    component: Home,
    Permission: ["module:lms"],
  },
];

export default {
  name: MODULE_NAME,
  displayName: MODULE_DISPLAY_NAME,
  mainNav: {
    name: MODULE_DISPLAY_NAME,
    url: uriProvider(),
    icon: "fa-cogs",
    permissions: ["module:lms"],
    color: "#b57983bd",
    shortName: MODULE_SHORT_DISPLAY_NAME,
    weight: 40,
  },
  preloadPermissions: {
    groups: ["lms", "core"],
  },
  productNav: [
    {
      name: "Compliance",
      header: true,
      permissions: ["module:lms"],
    },
    {
      name: "Check The Tests",
      url: uriProvider("my-compliance-tests"),
      // isExternal: true,
      // href: "/lms/myTests",
      icon: "fa-cogs",
      permissions: ["module:lms"],
    },
    {
      name: "QHSE",
      header: true,
      permissions: ["module:lms"],
    },
    {
      name: "Trainings & Questionnaires",
      url: uriProvider("my-qhse-tests"),
      // isExternal: true,
      // href: "/lms/myTests",
      icon: "fa-cogs",
      permissions: ["module:lms"],
    },
    {
      name: "IT",
      header: true,
      permissions: ["module:lms"],
    },
    {
      name: "Training Materials",
      url: uriProvider("my-it-tests"),
      // isExternal: true,
      // href: "/lms/myTests",
      icon: "fa-cogs",
      permissions: ["module:lms"],
    },
    {
      name: "HR",
      header: true,
      permissions: ["module:lms"],
    },
    {
      name: "Trainings & Tests",
      url: uriProvider("my-hr-tests"),
      // isExternal: true,
      // href: "/lms/myTests",
      icon: "fa-cogs",
      permissions: ["module:lms"],
    },
    {
      name: "Administration",
      header: true,
      permissions: [
        "lmsAdmin:tests",
        "lmsAdmin:questions",
        "lmsAdmin:templates",
        "lmsAdmin:configuration",
      ],
    },
    {
      name: "Tests",
      url: uriProvider("masters/tests"),
      // isExternal: true,
      // href: "/lms/tests",
      icon: "fa-cogs",
      permissions: ["lmsAdmin:tests"],
    },
    {
      name: "Questions",
      url: uriProvider("masters/questions"),
      // isExternal: true,
      // href: "/lms/questions",
      icon: "fa-cogs",
      permissions: ["lmsAdmin:questions"],
    },
    {
      name: "Templates",
      url: uriProvider("masters/testsTemplates"),
      // isExternal: true,
      // href: "/lms/testsTemplates",
      icon: "fa-cogs",
      permissions: ["lmsAdmin:templates"],
    },
    {
      name: "Configuration",
      url: uriProvider("masters/configuration"),
      // isExternal: true,
      // href: "/lms/configuration",
      icon: "fa-cogs",
      permissions: ["lmsAdmin:configuration"],
    },
  ],
  routes: entry_routes,
};
