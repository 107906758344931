import React, { Component } from "react";
import { Button, Card, Modal } from "@swan/themer";
import { ButtonConfirm } from "@swan/helpers/UI";
import { FaRegSave, FaRegTrashAlt, FaRegWindowClose } from "react-icons/fa";
import styled from "styled-components";
import { isEmpty } from "lodash";

import type { ButtonsType } from "../../../../../types";
import Form from "../Form";

type Props = {
  selectedRecord?: { id: number },
  service: Object,
  rowData: Object,
  registerSaveFunction: Function,
  registerDeleteFunction: Function,
  cardClickHandler?: (data: {}) => void,
  editMode: string,
  idx: number,
  form: Object,
  children: any,
  buttons?: ButtonsType,
  reloadRecord?: boolean,
  cardViewForm: Object,
  listId: number,
  object: string,
  readOnly: boolean,
  ServiceFactory: Object,
};

type State = {
  selectedRecord: Object,
  isEditing: boolean,
};

const CardFormRowStyled = styled("div")`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  &.card-form-row {
    padding: 0.3rem;
    border: 1px dashed #cdd1d4;
    border-radius: 3px;
    margin-bottom: 3px;
    transition: all 0.5s cubic-bezier(0.1, 0.7, 1, 0.1);

    &:hover {
      border-style: solid;
      transition: all 0.5s cubic-bezier(0.1, 0.7, 1, 0.1);
    }

    &:last-child {
      margin-bottom: none;
    }

    &.card-form-row-even {
    }

    &.card-form-row-odd {
    }
  }

  .controls {
  }

  .default-card-form {
    flex: 1;
    margin-bottom: 0;

    .container-fluid {
      overflow: hidden;
    }
  }
`;

class CardFormRow extends Component<Props, State> {
  static defaultProps = {
    buttons: [],
    selectedRecord: {},
    cardClick: false,
    cardClickHandler: null,
    reloadRecord: false,
    isLoadingMore: false,
    loadMoreEnabled: true,
  };

  state = { selectedRecord: null, isEditing: false };

  cardClickHandle = (rowData: Object): void => {
    const { cardClickHandler } = this.props;
    if (
      cardClickHandler &&
      typeof cardClickHandler === "function" &&
      !isEmpty(rowData)
    ) {
      cardClickHandler(rowData);
    }
    this.setState({
      selectedRecord: rowData,
      isEditing: true,
    });
  };

  reset = (): void => {
    this.setState({
      selectedRecord: undefined,
    });
  };

  isEditing = () => {
    const { rowData } = this.props;
    const { isEditing, selectedRecord } = this.state;
    return isEditing && (selectedRecord || {}).id === rowData.id;
  };

  handleSaveFunction = (saveFunction: Function) => {
    this.saveRecord = saveFunction;
  };

  handleDeleteFunction = (deleteFunction: Function) => {
    this.deleteRecord = deleteFunction;
  };

  saveRecord: Function;

  deleteRecord: Function;

  render() {
    const {
      idx,
      reloadRecord,
      editMode,
      cardViewForm,
      rowData,
      form,
      listId,
      object,
      service,
      ServiceFactory,
      readOnly,
    } = this.props;

    const { selectedRecord } = this.state;

    const buttons: Array<Object> = [
      ...(!rowData.is_locked
        ? [
            {
              label: `${this.isEditing() ? "Update" : "Save"}`,
              icon: <FaRegSave />,
              color: "primary",
              onClick: () => this.saveRecord(),
            },
          ]
        : []),
      ...(!rowData.is_locked && this.isEditing()
        ? [
            {
              label: "Delete",
              icon: <FaRegTrashAlt />,
              color: "danger",
              type: "ButtonConfirm",
              onClick: () => this.deleteRecord(),
            },
          ]
        : []),
      {
        label: "Close",
        icon: <FaRegWindowClose />,
        onClick: () => this.reset(),
        color: "secondary",
      },
    ];

    return (
      <CardFormRowStyled
        key={idx}
        className={[
          "card-form-row",
          (idx + 1) % 2 === 0 ? "card-form-row-even" : "card-form-row-odd",
        ].join(" ")}
      >
        <Card
          className="default-card-form"
          isMinimal
          key={rowData.id}
          // buttons={buttons}
          buttonsRight
          hideButtons={editMode === "modal" || this.isEditing()}
          cardClickEnabled={!this.isEditing()}
          cardClickHandler={() => {
            this.cardClickHandle(rowData);
          }}
        >
          {(!this.isEditing() || editMode !== "modal") && (
            <Form
              noSave
              disableDelete
              form={!this.isEditing() ? cardViewForm : form}
              listId={listId}
              object={object}
              type="details"
              service={service}
              ServiceFactory={ServiceFactory}
              goBack={this.reset}
              registerSaveFunction={this.handleSaveFunction}
              registerDeleteFunction={this.handleDeleteFunction}
              reloadRecord={reloadRecord}
              record={rowData}
              id={rowData.id}
              readOnly={readOnly || !this.isEditing()}
            />
          )}
          {this.isEditing() && editMode === "modal" && (
            <Modal
              title={rowData.id ? `Add New Item` : `Update Existing Item`}
              isOpen={this.isEditing() && editMode === "modal"}
              toggle={this.reset}
              buttons={buttons.reverse()}
              size="xl"
            >
              <Form
                noSave
                disableDelete
                form={!this.isEditing() ? cardViewForm : form}
                listId={listId}
                object={object}
                type="details"
                service={service}
                ServiceFactory={ServiceFactory}
                goBack={this.reset}
                registerSaveFunction={this.handleSaveFunction}
                registerDeleteFunction={this.handleDeleteFunction}
                reloadRecord={reloadRecord}
                record={rowData}
                id={rowData.id}
                readOnly={readOnly || !this.isEditing()}
              />
            </Modal>
          )}
        </Card>
        {editMode === "modal" ||
        (selectedRecord || {}).id !== rowData.id ||
        !buttons ? null : (
          <div className="default-card-form-controls" style={{ zIndex: 3 }}>
            {buttons.map(({ label, icon, type, ...restBtnProps }) =>
              type === "ButtonConfirm" ? (
                <ButtonConfirm
                  key={`btn${label}`}
                  {...restBtnProps}
                  className="tag-button"
                >
                  {icon || label}
                </ButtonConfirm>
              ) : (
                <Button className="tag-button" {...restBtnProps}>
                  {icon || label}
                </Button>
              )
            )}
          </div>
        )}
      </CardFormRowStyled>
    );
  }
}

export default CardFormRow;
