import { Extension } from "@tiptap/react";

const LineHeight = Extension.create({
  name: "lineHeight",

  defaultOptions: {
    types: ["textStyle"],
  },

  addGlobalAttributes() {
    return [
      {
        types: this.options.types,
        attributes: {
          lineHeight: {
            default: null,
            parseHTML: element => element.style.lineHeight,
            renderHTML: attributes => {
              if (!attributes.lineHeight) {
                return {};
              }

              return {
                style: `line-height: ${attributes.lineHeight}`,
              };
            },
          },
        },
      },
    ];
  },

  addCommands() {
    return {
      setLineHeight: value => ({ chain }) =>
        chain()
          .setMark("textStyle", { lineHeight: value })
          .run(),
      unsetLineHeight: () => ({ chain }) =>
        chain()
          .setMark("textStyle", { lineHeight: null })
          .removeEmptyTextStyle()
          .run(),
    };
  },
});

export default LineHeight;
