import React from "react";
import styled from "styled-components";

import banner from "./assets/banner";
import Crumb, { type CrumbType } from "./Crumb";

type Props = {
  title?: string,
  crumbs: Array<CrumbType>,
  margin?: any,
  width?: any,
  classNames?: Object,
  className?: string,
  disableCrumbBar?: boolean,
};

const Banner = styled.div`
  display: flex;
  min-height: 100px;
  height: calc(200px + 2rem);
  width: 100%;
  ${(props: any) =>
    ((props || {}).margin || {}).left
      ? `margin-left: ${props.margin.left}rem`
      : ""}
  ${(props: any) =>
    ((props || {}).margin || {}).right
      ? `margin-right: ${props.margin.right}rem`
      : ""}
  ${(props: any) =>
    ((props || {}).margin || {}).top
      ? `margin-top: ${props.margin.top}rem`
      : ""}
  ${(props: any) =>
    ((props || {}).margin || {}).bottom
      ? `margin-bottom: ${props.margin.bottom}rem`
      : ""}
  ${(props: any) => (props.width ? `width: ${props.width}` : "")};
  background: #cdced0 url(${banner}) no-repeat left center;
  background-size: 100% 100%;
  justify-content: center;
  align-items: center;
  border-bottom: 2rem solid #cdced0;
`;

const BannerTitle = styled.h2`
  padding-top: 1rem;
  font-size: 2rem;
  font-weight: bold;
  color: white;
  text-align: center;
  @media (max-width: 768px) {
    font-size: 1.3rem !important;
    padding-right: 49px;
    padding-left: 49px;
    padding-top: 51px;
  }
`;

const CrumbBar = styled.div`
  background: #f1f2f2;
  padding-left: 1rem;
  ${(props: any) =>
    ((props || {}).margin || {}).left
      ? `margin-left: ${props.margin.left}rem`
      : ""}
  ${(props: any) =>
    ((props || {}).margin || {}).right
      ? `margin-right: ${props.margin.right}rem`
      : ""}
  ${(props: any) =>
    ((props || {}).margin || {}).bottom
      ? `margin-bottom: ${props.margin.bottom}rem`
      : ""}
  ${(props: any) => (props.width ? `width: ${props.width}` : "")};
  ${(props: any) => (props.disableCrumbBar ? `display:none;` : "")};
  @media (max-width: 768px) {
    .crumb-item-wrapper {
      display: block;

      & > div {
        display: inline;

        > .crumb-item {
            font-size: 0.8rem;
        }
      }
    }

      padding-left: 0;
      margin-right: 0;
      margin-left: 0;
      width: auto;
  }
`;

const CustomBanner = (props: Props) => {
  const {
    title,
    crumbs,
    margin,
    width,
    classNames,
    className,
    disableCrumbBar,
  } = props;
  return (
    <div className={`banner-crumb-wrapper ${className || ""}`}>
      <Banner
        className={`banner ${(classNames || {}).banner || ""}`}
        margin={margin}
        width={width}
      >
        <BannerTitle>
          {title
            ? title.split("\n").map(item => (
                <span key={item}>
                  {item}
                  <br />
                </span>
              ))
            : "SWAN"}
        </BannerTitle>
      </Banner>

      <CrumbBar
        className={`crumb-bar ${(classNames || {}).crumb || ""}`}
        margin={margin}
        width={width}
        disableCrumbBar={disableCrumbBar}
      >
        <Crumb crumbs={crumbs} />
      </CrumbBar>
    </div>
  );
};

CustomBanner.defaultProps = {
  title: "",
  margin: {},
  classNames: {},
  className: "",
  width: "100px",
  disableCrumbBar: false,
};

export default CustomBanner;
