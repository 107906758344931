import {
  call,
  put,
  takeEvery,
  fork,
  select,
  take,
  all,
} from "redux-saga/effects";
import type { Channel } from "redux-saga";
import { eventChannel } from "redux-saga";
import type { Saga } from "redux-saga";
import Axios from "axios";
import { SwanContextManager } from "@swan/state";
import { WebsocketService } from "@swan/api";

import * as types from "./types";
import history from "../../../utils/history";
import type { LoginAction } from "./actions";
import AuthService, { AuthUser } from "../../../services/auth";
import UserService from "../../../services/user";
import { toast } from "@swan/themer";

function loginToApi(token) {
  const Auth = new AuthService();
  return Auth.post("loginWithOpenId", { token });
}

function initEcho() {
  WebsocketService.getEcho();
}

function deleteEcho() {
  WebsocketService.deleteEcho();
}

/**
 *
 * @param object object name
 * @param id instance id e.g : quote id
 * @return {Channel<any>}
 */
function subscribeUserLogin({ id }: { id: number }): Channel {
  return eventChannel(emit => {
    WebsocketService.join(
      `SWAN.USER.${id}`,
      "\\Mazrui\\SwanNotification\\Events\\Websocket\\UserEvent",
      emit
    );
    return () => {};
  });
}

/**
 *
 * @param callback
 * @param id
 */
const unsubscribeUserLogin = ({ id }: { id: number }) =>
  WebsocketService.getEcho().leave(`SWAN.USER.${id}`);

function checkAdminPermissions(permission) {
  const authUserService = new AuthUser();
  return authUserService.get("isallowed", {
    permission,
  });
}

export function* loginWithIdToken(action: LoginAction): Saga<void> {
  yield put({ type: types.LOGIN_WITH_ID_TOKEN_BEGIN });

  const result = yield call(loginToApi, action.token);

  if (result.success) {
    // Set default token in axios
    Axios.defaults.headers.Authorization = `Bearer ${result.token}`;
    Axios.defaults.headers["x-swan-userorg"] =
      result.profile.active_organization;

    SwanContextManager.setValue(
      "sessionId",
      `${result.token}#${result.profile.active_organization}`
    );
    const isAdminPermissions = yield call(checkAdminPermissions, [
      "module:admin",
      "module:superadmin",
      "admin:composer",
      "admin:users",
      "admin:roles",
      "admin:templates",
      "admin:organizations",
      "admin:record_permissions",
    ]);

    SwanContextManager.setValue("userProfile", result.profile);
    if (
      (isAdminPermissions["module:admin"] &&
        isAdminPermissions["module:admin"] === true) ||
      (isAdminPermissions["module:superadmin"] &&
        isAdminPermissions["module:superadmin"] === true)
    ) {
      SwanContextManager.setValue("userIsAdmin", true);
      const adminPermissions = [];
      Object.keys(isAdminPermissions).forEach(p => {
        if (isAdminPermissions[p] === true) {
          adminPermissions.push(p);
        }
      });
      SwanContextManager.setValue("adminPermissions", adminPermissions);
      const adminPermissionRefreshed = new Event("AdminPermissionsRefreshed");
      window.dispatchEvent(adminPermissionRefreshed);
    }
    initEcho();
    yield put({
      type: types.LOGIN_WITH_ID_TOKEN_SUCCESS,
      token: result.token,
      profile: result.profile,
    });
    yield put({
      type: types.LOGIN_SUCCESS,
    });
  } else {
    yield put({
      type: types.LOGIN_WITH_ID_TOKEN_FAILED,
      message: result.message,
    });
  }
}

function* logout(): Saga<void> {
  yield put({ type: types.LOGOUT_BEGINS });
  const Auth = new AuthService();
  try {
    yield call([Auth, Auth.post], "logout");
    deleteEcho();
    SwanContextManager.clearValues();
    yield put({
      type: types.LOGOUT_COMPLETED,
    });
    const event = new Event("logout");
    window.dispatchEvent(event); // dispatch an event of logout, some tools might be listening to it
  } catch (e) {
    yield put({ type: types.LOGOUT_FAILED, message: e.message });
  }
}

function* setLocale({ locale }): Saga<void> {
  const service = new UserService();
  yield call([service, service.put], "preferences", { locale });
}

function* loginSaga(): Saga<void> {
  yield takeEvery(types.LOGIN_WITH_ID_TOKEN, loginWithIdToken);
}

function* logoutSaga(): Saga<void> {
  yield takeEvery(types.LOGOUT, logout);
}

function* setLocaleSaga(): Saga<void> {
  yield takeEvery(types.SET_LOCALE, setLocale);
}

function* loadOrganizations(): Saga<void> {
  const service = new AuthService();
  const orgs = yield call([service, service.get], "getOrganizations");
  yield put({
    type: types.ORGANIZATIONS_LOADED,
    payload: orgs.data,
  });
}

function* loadOrganizationsSaga(): Saga<void> {
  yield takeEvery(types.LOGIN_SUCCESS, loadOrganizations);
}

function* switchOrganization({
  organization,
  organizationName,
  redirectTo,
}): Saga<void> {
  const service = new AuthService();
  deleteEcho();
  try {
    yield call([service, service.post], "switchToOrganization", {
      orgid: organization,
    });
    const profile = yield call([service, service.get], "me");
    Axios.defaults.headers["x-swan-userorg"] = profile.active_organization;
    yield put({
      type: types.SWITCH_ORGANIZATION_SUCCESS,
      profile,
    });
    // Put a logout completed so that all reducers will clear out
    yield put({
      type: types.LOGOUT_COMPLETED,
      switchingOrg: true, // add this flag to detect that it's not a real logout
    });
    const { user } = yield select();
    SwanContextManager.setValue(
      "sessionId",
      `${user.token}#${profile.active_organization}`
    );
    const event = new Event("logout");
    window.dispatchEvent(event); // dispatch an event of logout, some tools might be listening to it
    history.push(`/switch/${organization}`, {
      name: organizationName,
      redirectTo,
    });
  } catch (e) {
    console.log("Failed to switch");
  }
  initEcho();
}

function* switchOrganizationSaga(): Saga<void> {
  yield takeEvery(types.SWITCH_ORGANIZATION, switchOrganization);
}

/**
 *
 * @param object
 * @param id
 * @return {IterableIterator<*>}
 */
function* subscribeUser({ id }: { id: number }): Generator<any, void, any> {
  const echoChannel: Channel = yield call(subscribeUserLogin, {
    id,
  });
  yield put({
    type: "SUBSCRIBE_USER_LOGIN_STARTED",
  });
  while (true) {
    try {
      const result = yield take(echoChannel);
      if (result && result.message) {
        toast(result ? result.message : "", { type: result.type || "info" });
      }
    } catch (errors) {}
  }
}

/**
 *
 * @param object
 * @param id
 * @return {IterableIterator<*>}
 */
function* unsubscribeUser({ id }: { id: number }): Generator<any, void, any> {
  yield put({
    type: "SUBSCRIBE_USER_LOGIN_ENDED",
  });
  yield call(unsubscribeUserLogin, { id });
}

export default function* sagas(): Saga<void> {
  yield fork(loginSaga);
  yield fork(logoutSaga);
  yield fork(setLocaleSaga);
  yield fork(loadOrganizationsSaga);
  yield all([
    takeEvery("SUBSCRIBE_USER_LOGIN", subscribeUser),
    takeEvery("UNSUBSCRIBE_USER_LOGIN", unsubscribeUser),
  ]);
  yield fork(switchOrganizationSaga);
}

// on logout
// Axios.defaults.headers['Authorization'] = null;
