import * as React from "react";
import { compose } from "redux";
import { Form as FormHOC } from "@swan/helpers/CRUD";
import DynamicForm from "./DynamicForm";

export const ConnectedFormWithCustomValidation = (
  validate: (Object, Array<string>, boolean) => {}
) => ({
  object,
  type,
  formId,
  ServiceFactory,
}: {
  object: string,
  type: string,
  formId: number,
  ServiceFactory: (object: string, data?: Object) => Object,
}) => (Component: any) => {
  const Form = (props: any) => (
    <DynamicForm
      {...props}
      object={object}
      type={type}
      formId={formId}
      ServiceFactory={ServiceFactory}
    >
      <Component />
    </DynamicForm>
  );

  const WrappedForm = compose(FormHOC(validate))(Form);

  // Do a second wrap to make sure the object and type reach the Form HOC
  const FinalForm = (props: any) => (
    <WrappedForm {...props} object={object} type={type} />
  );

  return FinalForm;
};

export default ConnectedFormWithCustomValidation(() => ({}));
