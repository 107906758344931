import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { Button, Col, Row } from "reactstrap";

const PanelRowStyled = styled(Row)`
  margin-top: 1rem;
  padding: 0 1rem;
  @media (max-width: 768px) {
    &:not(.custom-panel-order) {
      .panel-left {
        order: 2;
      }

      .panel-right {
        order: 1;
      }

      br {
        display: none;
      }
    }
  }
`;
export const PanelRow = (props: any) => <PanelRowStyled {...props} />;

const PanelStyled = styled(Col)`
  &.panel-left {
    box-shadow: 0px 1px 5px 0px rgb(0 0 0 / 9%);
    -webkit-box-shadow: 0px 1px 5px 0px rgb(0 0 0 / 33%);
    -moz-box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.75);
    border-radius: 13px;
    background: #fff;
    // padding: 0 !important;
  }

  // margin: 1rem 1rem 0 0;
  > div.content-box {
    color: #6e7075;

    // margin: 0 1rem;
    padding: 2.8rem;
    background: transparent;
    height: 100%;
    @media (max-width: 768px) {
      font-size: 1.3rem !important;
      padding: 1.3rem;
    }
    h3 {
      color: #3d70b2;
      font-weight: bold;
      @media (max-width: 768px) {
        font-size: 1.5rem;
      }
    }
    p {
      @media (max-width: 768px) {
        font-size: 0.9rem;
      }
    }
    &.content-box-gray {
      background: #eeeeee !important;
      color: #3b5a92 !important;

      h3 {
        font-weight: bold;
        font-size: 1.2rem !important;
      }
    }

    .content-asset {
      a.block-link {
        display: flex;
        align-items: flex-start;
        &.align-center {
          justify-content: center;
          align-items: center;
        }
      }

      img {
        width: 70%;
        padding: 1rem 0;
      }
    }

    .center-align {
      text-align: center;
    }

    h3 {
      // font-weight: bold;
      font-size: 1.2rem;
      padding-bottom: 1rem;
    }

    h4 {
      .sub-title {
        // text-transform: uppercase;
        font-size: 1.4rem;
        font-weight: normal;
      }

      .bolden-title {
        font-size: 1.3rem;
      }

      .sub-title ~ .bolden-title {
        margin-left: 0.3rem;
      }

      .bolden-title ~ .sub-title {
        margin-left: 0.3rem;
      }
      @media (max-width: 768px) {
        margin-bottom: 0.5rem;
        margin-top: 0.5rem;
      }
    }

    @media (max-width: 768px) {
      p,
      h3 {
        display: none;
      }
    }
  }

  @media (max-width: 768px) {
    font-size: 1.3rem !important;
    margin: 0px 13px;
  }
`;
export const Panel = (props: any) => <PanelStyled {...props} />;

const PanelItemButtonLinkStyled = styled(Link)`
  &:hover {
    text-decoration: none;
  }
`;
export const PanelItemButtonLink = (props: any) => (
  <PanelItemButtonLinkStyled {...props} />
);

const PalletWrapperStyled = styled.div`
  display: flex;
  flex-direction: column;
`;
export const PalletWrapper = (props: any) => <PalletWrapperStyled {...props} />;

const PalletRowStyled = styled.div`
  padding: 1rem;
  border-bottom: 1px solid #d6d6d6;

  &:last-child {
    border-bottom: none;
  }
`;
export const PalletRow = (props: any) => <PalletRowStyled {...props} />;

const PalletItemStyled = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  &.pallet-item-col {
    align-items: flex-start;
    flex-direction: column;
  }

  &.flex-center {
    justify-content: center;
    align-items: center;
  }

  @media (max-width: 768px) {
    text-align: center;

    &.pallet-item-col {
      align-items: center;
    }
  }
`;
export const PalletItem = (props: any) => <PalletItemStyled {...props} />;

const PalletItemIconContainerStyled = styled(Col)`
  justify-content: center;
  display: flex;
`;

export const PalletItemIconContainer = (props: any) => (
  <PalletItemIconContainerStyled {...props} />
);

const PalletItemIconStyled = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  > img {
    width: 6.3rem;
  }
`;
export const PalletItemIcon = (props: any) => (
  <PalletItemIconStyled {...props} />
);

const PalletItemTitleStyled = styled.span`
  font-size: 1.2rem;
  font-weight: 600;
  padding: 0.2rem 0;
  color: #3d70b2;

  @media (max-width: 768px) {
    text-align: center;
    margin-top: 10px;
    margin-bottom: 10px;
    width: 100%;
  }
`;
export const PalletItemTitle = (props: any) => (
  <PalletItemTitleStyled {...props} />
);

const PalletItemDescStyled = styled.div`
  font-size: 0.9rem;
  color: #6e7075;
  padding: 0.2em 0;
  line-height: 16px;
  @media (max-width: 768px) {
    margin-bottom: 10px;
  }
`;
export const PalletItemDesc = (props: any) => (
  <PalletItemDescStyled {...props} />
);

const PalletItemButtonStyled = styled(Button)`
  border-radius: 0.4rem !important;
  margin-bottom: 5px;
  &.white-bg {
    background: white;
    color: #3b5a92;

    &:hover {
      background: #3b5a92;
      border-color: #white;
      color: white;
    }
  }
  &.blue-bg {
    &.btn-primary {
      border-color: white;
      background: #3b5a92;
      color: white;
      &:hover {
        background: #22304c;
        color: #fff;
        text-decoration: none;
      }
    }
  }
`;
export const PalletItemButton = (props: any) => (
  <PalletItemButtonStyled {...props} />
);

const PalletButtonsContainerStyled = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  @media (max-width: 768px) {
    // flex-direction: row;
    width: 100%;
  }
`;
export const PalletButtonsContainer = (props: any) => (
  <PalletButtonsContainerStyled {...props} />
);

export default {};
