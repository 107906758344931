type FileDicType = {
  abbreviation: string,
  fileFormat: string,
  mime: string,
  ext: string | Array<string>,
};

type FilesType = { [string]: { [string]: FileDicType } };

export const dictionary: FilesType = {
  image: {
    APNG: {
      abbreviation: "APNG",
      fileFormat: "Animated Portable Network Graphics",
      mime: "image/apng",
      ext: ".apng",
    },
    AVIF: {
      abbreviation: "AVIF",
      fileFormat: "AV1 Image File Format",
      mime: "image/avif	",
      ext: ".avif",
    },
    GIF: {
      abbreviation: "GIF",
      fileFormat: "Graphics Interchange Format",
      mime: "image/gif",
      ext: ".gif",
    },
    JPEG: {
      abbreviation: "JPEG",
      fileFormat: "Joint Photographic Expert Group image",
      mime: "image/jpeg",
      ext: [".jpg", ".jpeg", ".jfif", ".pjpeg", ".pjp"],
    },
    PNG: {
      abbreviation: "PNG",
      fileFormat: "Portable Network Graphics",
      mime: "image/png",
      ext: ".png",
    },
    SVG: {
      abbreviation: "SVG",
      fileFormat: "Scalable Vector Graphics",
      mime: "image/svg+xml",
      ext: ".svg",
    },
    WebP: {
      abbreviation: "WebP",
      fileFormat: "Web Picture format",
      mime: "image/webp",
      ext: ".webp",
    },
    BMP: {
      abbreviation: "BMP",
      fileFormat: "Bitmap file",
      mime: "image/bmp",
      ext: ".bmp",
    },
    ICO: {
      abbreviation: "ICO",
      fileFormat: "Microsoft Icon",
      mime: "image/x-icon",
      ext: [".ico", ".cur"],
    },
    TIFF: {
      abbreviation: "TIFF",
      fileFormat: "Tagged Image File Format",
      mime: "image/tiff",
      ext: [".tif", ".tiff"],
    },
  },
  application: {
    PDF: {
      abbreviation: "PDF",
      fileFormat: "Portable Document Format",
      mime: "application/pdf",
      ext: ".pdf",
    },
  },
};

export default {
  dictionary,
};
