import styled from "styled-components";
import { EditorContent as TiptapContent } from "@tiptap/react";

const Button = styled.button`
  padding: 5px 8px;
  border-radius: 2px;
  border: none;
  outline: none;
  margin-right: 5px;
  color: #f3f4f6;
  background-color: transparent;

  ${(props: any) => props.active && "background-color: #1f4083"}
  :focus {
    outline: none;
  }

  :hover {
    cursor: pointer;
    background-color: #395998;
  }
`;
const Menu = styled.div`
  padding: 5px;
  background-color: #1f2937;

  .ml {
    margin-left: 10px;
  }
`;

const EditorContent = styled(TiptapContent)`
  .ProseMirror {
    outline: none;
    > * + * {
      margin-top: 0.75em;
    }

    ul,
    ol {
      padding: 0 1rem;
    }

    ol {
      list-style: decimal;
    }

    ul {
      list-style: disc;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      line-height: 1.1;
    }

    code {
      background-color: rgba(#616161, 0.1);
      color: #616161;
    }

    pre {
      background: #0d0d0d;
      color: #fff;
      font-family: "JetBrainsMono", monospace;
      padding: 0.75rem 1rem;
      border-radius: 0.5rem;

      code {
        color: inherit;
        padding: 0;
        background: none;
        font-size: 0.8rem;
      }
    }

    img {
      max-width: 100%;
      height: auto;
    }

    blockquote {
      padding-left: 1rem;
      border-left: 2px solid rgba(#0d0d0d, 0.1);
    }

    hr {
      border: none;
      border-top: 2px solid rgba(#0d0d0d, 0.1);
      margin: 2rem 0;
    }

    table {
      border-collapse: collapse;
      table-layout: fixed;
      width: 100%;
      margin: 0;
      overflow: hidden;

      td,
      th {
        min-width: 1em;
        border: 2px solid #ced4da;
        padding: 3px 5px;
        vertical-align: top;
        box-sizing: border-box;
        position: relative;

        > * {
          margin-bottom: 0;
        }
      }

      th {
        font-weight: bold;
        text-align: left;
        background-color: #f1f3f5;
      }

      .selectedCell:after {
        z-index: 2;
        position: absolute;
        content: "";
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        background: rgba(200, 200, 255, 0.4);
        pointer-events: none;
      }

      .column-resize-handle {
        position: absolute;
        right: -2px;
        top: 0;
        bottom: -2px;
        width: 4px;
        background-color: #adf;
        pointer-events: none;
      }
    }
  }
`;

export { Button, Menu, EditorContent };
