import pluralize from "pluralize";

export default (str: string) => {
  const hasLowerCase = /[a-z]/.test(str);
  const lastChar = str.substr(-1);
  // a word doesn't end with capital letter, goes through pluralized function
  // ending with capital letter considered as special case, whilst having simple cases
  if (hasLowerCase && lastChar !== lastChar.toLowerCase()) {
    return str;
  }
  return pluralize(str);
};

// export
// const pluralize = (str: string): string => {
//   let plural = `${str}s`;
//   if (str.substr(-1) === "y") {
//     plural = `${str.substr(0, str.length - 1)}ies`;
//   }

//   return plural;
// };

// export default pluralize;
