import * as React from "react";

import styled, { withTheme } from "styled-components";
import { Draggable } from "react-beautiful-dnd";
import { keys } from "lodash";

import ConditionalDroppable from "../DragDrop/ConditionalDroppable";
import ElementsList from "./ElementsList";
import type { Props } from "./types";

// import Parts from "../../definitions/parts";
import Pencils from "../../definitions/index";

export const ContainerWrapper = styled.div(({ theme, droppedCount }) =>
  Object.assign(
    {},
    theme.ContainerWrapper ? { ...theme.ContainerWrapper } : {},
    droppedCount === 0
      ? {}
      : {
          paddingBottom: "0.5rem",
        }
  )
);

class Container extends React.Component<Props> {
  render() {
    const { schema, id, index } = this.props;
    return (
      <Draggable
        draggableId={id}
        index={index || 0}
        isDragDisabled={id === "@"}
      >
        {draggableProvided => (
          <ContainerWrapper
            ref={draggableProvided.innerRef}
            {...draggableProvided.draggableProps}
            {...draggableProvided.dragHandleProps}
            droppedCount={keys(schema.properties).length}
          >
            <ConditionalDroppable
              droppableId={id}
              excludeTypes={
                Pencils.pencils.layoutDefinitions.container.drop.excludeTypes
              }
              droppedCount={keys(schema.properties).length}
              droppableWrapper="Container"
            >
              <ElementsList id={id} schema={schema} index={0} />
            </ConditionalDroppable>
          </ContainerWrapper>
        )}
      </Draggable>
    );
  }
}

export default withTheme(Container);
