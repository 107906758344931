export default {
  settings: {},
  drop: {
    excludeTypes: ["row", "tab", "col"],
  },
  options: {
    type: "tab",
    defaultProperties: {},
  },
};
