import React from "react";
import { uriProvider } from "./utils/uriHelpers";
import {
  MODULE_NAME,
  MODULE_DISPLAY_NAME,
  MODULE_SHORT_DISPLAY_NAME,
} from "./config";

// $FlowFixMe Should be React.lazy<DashboardProps> but causing issue in webpack
const Home = React.lazy(() => import("./Module"));

const entry_routes = [
  {
    path: "/analytics",
    name: MODULE_NAME,
    component: Home,
    // Permission: ["module:analytics"],
  },
];

export default {
  name: MODULE_NAME,
  displayName: MODULE_DISPLAY_NAME,
  mainNav: {
    name: MODULE_DISPLAY_NAME,
    url: uriProvider(),
    icon: "fa-cogs",
    permissions: ["module:analytics"],
    color: "#b57983bd",
    shortName: MODULE_SHORT_DISPLAY_NAME,
    weight: 40,
  },
  preloadPermissions: {
    groups: ["analytics", "core"],
  },
  productNav: [
    {
      name: "Analytics",
      header: true,
      permissions: ["module:analytics"],
    },
    {
      name: "Analytics Administrations",
      header: true,
      permissions: ["analytics:administration"],
    },
    {
      name: "Workspaces Configuration",
      url: uriProvider("administrations/workspaces"),
      permissions: ["analyticsWorkspace:view"],
    },
    {
      name: "Reports Configuration",
      url: uriProvider("administrations/reports"),
      permissions: ["analyticsReport:view"],
    },
    {
      name: "Charts Configuration",
      url: uriProvider("administrations/charts"),
      permissions: ["analyticsChart:view"],
    },
  ],
  routes: entry_routes,
};
