import Resource from "./resource";
import ObjectService from "./services/object";
import { type SwanObject } from "./types";

class GenericResource extends Resource {
  objectName: string;

  objectDef: SwanObject;

  setObjectName(name: string) {
    this.objectName = name;
  }

  /**
   * Override the get api function to dynamically load object definition
   */
  getApi() {
    if (!this.objectDef) {
      if (!this.objectName) {
        throw new Error("Object name not defined");
      }
      return ObjectService.getObjectByName(this.objectName).then(def => {
        this.objectDef = def;
        this.prefix = def.api_endpoint;
        return super.getApi();
      });
    }
    return super.getApi();
  }
}

export default GenericResource;
