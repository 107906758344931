import data from "../../../data";

const Schema: { schema: Object, uiSchema: Object } = {
  schema: {
    type: "string",
    title: "Select from Standard Items",
    enum: Array.isArray(data) ? data.map(s => s.value) : [],
    enumNames: Array.isArray(data) ? data.map(s => s.name) : [],
  },
  uiSchema: {},
};

export default Schema;
