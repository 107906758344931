import countryList from "country-list";

const countries: { [code: string]: string } = countryList.getCodeList();

const data: Array<{ value: string, name: mixed }> = Object.entries(
  countries
).map(([code, country]) => ({
  value: code,
  name: country,
}));

export default data;
