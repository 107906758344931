import Axios from "axios";
import { SwanContextManager } from "@swan/state";
import { store } from "../state/store";
import { userActions } from "../state/reducers/user";

export const setupAxios = () => {
  const { user } = store.getState();
  const { token, activeOrganization } = user || {};
  if (token) {
    Axios.defaults.headers.Authorization = `Bearer ${token}`;
    Axios.defaults.headers["x-swan-userorg"] = activeOrganization;
    SwanContextManager.setValue(
      "sessionId",
      `${user.token}#${activeOrganization}`
    );
  }
};

Axios.interceptors.response.use(
  response => response,
  error => {
    if (error.response) {
      if (error.response.status === 401) {
        if (error.response.config.url.indexOf("auth/logout") !== -1) {
          store.dispatch(userActions.logoutCompleted());
        } else {
          store.dispatch(userActions.logout());
        }
      }
    }
    return Promise.reject(error);
  }
);

export default setupAxios;
