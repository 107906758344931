import React, { type Node, type ComponentType } from "react";
import Styled from "styled-components";

import { GLOBAL_NAV_WIDTH, SIDEBAR_NAV_WIDTH__REM } from "./config";
import { LayoutSubscriber } from "./context/layout";

type Props = {
  children: Node,
  Components: { PageHeader: ComponentType<any> },
};

const Wrapper = Styled.div`
  width: calc(100% - ${(props: any) => props.leftOffset});
  margin-left: ${(props: any) => props.leftOffset};
  
  @media(max-width:768px) {
    width: 100%;
    margin-left: 0;
  }
`;

const PageOverlay = Styled.div`
  position: absolute;
  background: #fff;
  opacity: 0.8;
  display: flex;
  bottom: 0;
  top: 0;
  right: 0;
  z-index: 999;
  transition: opacity 220ms ease 0s;
  width: calc(100% - ${(props: any) => props.leftOffset});
`;

export default ({ children, Components, ...restProps }: Props) => {
  const { PageHeader } = Components;
  return (
    <LayoutSubscriber>
      {({
        isProductNavigationOpen,
        toggleProductNavigation,
        isProductNavigationStick,
      }) => (
        <Wrapper
          leftOffset={
            isProductNavigationStick
              ? `calc(${GLOBAL_NAV_WIDTH}px + ${SIDEBAR_NAV_WIDTH__REM}rem)`
              : `${GLOBAL_NAV_WIDTH}px`
          }
        >
          {isProductNavigationOpen && !isProductNavigationStick ? (
            <PageOverlay
              leftOffset={`calc(${GLOBAL_NAV_WIDTH}px + ${SIDEBAR_NAV_WIDTH__REM}rem)`}
              className="page-content-overlay"
              onClick={toggleProductNavigation}
            />
          ) : null}

          <PageHeader {...restProps} />
          <main className="main swan-main">{children}</main>
        </Wrapper>
      )}
    </LayoutSubscriber>
  );
};
