import React, { type Node } from "react";
import {
  Card,
  CardImg,
  CardHeader,
  CardBody,
  CardFooter,
  Button,
} from "reactstrap";
import classNames from "classnames";
import styled from "styled-components";
import COLOURS from "../constants/colours";

type Props = {
  children: Node,
  title?: string | boolean,
  buttonsRight?: boolean,
  buttons?: Array<{
    label: string,
    color?: string,
    onClick?: () => void,
  }>,
  hideButtons?: boolean,
  cardClickEnabled?: boolean,
  cardClickHandler?: (data: Object) => void,
  isMinimal?: boolean,
  bodySpace?: string,
  className?: string,
  imageCap?: {
    src: string,
    alt: string,
    position: "top" | "bottom",
  },
};

const CardStyled = styled(Card)`
  box-shadow: ${({ isMinimal }) =>
    isMinimal ? "none" : `1px 1px 0 0 ${COLOURS.UI_02}`};
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  position: relative;
  border: ${({ isMinimal }) => (isMinimal ? "none" : "inherit")};
`;

const CardBodyStyled = styled(CardBody)`
  padding: ${({ isMinimal }) => (isMinimal ? "0" : "inherit")};
  padding: ${({ bodySpace }) => bodySpace || "inherit"};
`;

const CardOverlay = styled.div(({ transparent }) => ({
  position: "absolute",
  left: 0,
  top: 0,
  right: 0,
  bottom: 0,
  background: `${transparent ? "transparent" : "#ccc"}`,
  opacity: 0.3,
}));

const SwanCard = (props: Props) => {
  const {
    children,
    buttons,
    buttonsRight,
    title,
    hideButtons,
    cardClickEnabled,
    cardClickHandler,
    isMinimal,
    bodySpace,
    className,
    imageCap,
  } = props;
  const { src: imgSrc, alt: imgAlt, position: imgPosition } = imageCap || {};

  return (
    <CardStyled isMinimal={isMinimal} className={className}>
      {title && <CardHeader>{title}</CardHeader>}
      {imgSrc && imgPosition === "top" ? (
        <CardImg alt={imgAlt} src={imgSrc} top width="100%" />
      ) : null}
      <CardBodyStyled isMinimal={isMinimal} bodySpace={bodySpace}>
        {children}
        {buttons && buttons.length > 0 && !hideButtons && (
          <CardFooter className={classNames({ clearfix: buttonsRight })}>
            {buttons.map(button => {
              const { label, color, onClick } = button;
              return (
                <Button
                  color={color || "secondary"}
                  onClick={onClick}
                  className={classNames({ "float-right": buttonsRight })}
                  key={`${label}`}
                >
                  {label}
                </Button>
              );
            })}
          </CardFooter>
        )}
      </CardBodyStyled>
      {imgSrc && imgPosition === "bottom" ? (
        <CardImg src={imgSrc} alt={imgAlt} bottom width="100%" />
      ) : null}
      {cardClickEnabled && (
        <CardOverlay transparent onClick={cardClickHandler} />
      )}
    </CardStyled>
  );
};

SwanCard.defaultProps = {
  title: false,
  buttonsRight: false,
  buttons: [],
  hideButtons: false,
  cardClickEnabled: false,
  cardClickHandler: () => {},
  isMinimal: false,
  bodySpace: undefined,
  className: "",
  imageCap: {},
};

export default SwanCard;
