import React from "react";
import { Link, withRouter } from "react-router-dom";
import Styled from "styled-components";
import BreadcrumbButtons from "./BreadcrumbButtons";

type Props = {
  appRoutes?: Array<any>,
  disable?: boolean,
  match: Object,
  onAfterPathProcess?: (crumbs: Array<any>) => Array<any>,
  activeLabel?: string,
  // @todo props to be renamed without prefix `custom`
  customTitle?: string,
  customEditTitle?: string,
  customCreateTitle?: string,
};

type State = {
  crumbs: Array<Object>,
  title: string,
};

const Wrapper = Styled.div`

position: relative;
display: flex;
justify-content: flex-start;
align-items: center;
padding: 0.6rem 1.9rem;
background: #f1f4f9;
z-index: 2;
border-top: 2px solid #b4c7e785;

.crumb-container {
  display: flex;
  flex-direction: column;
  flex: 1;
  
  .crumb-title {
    h1 {
      font-size: 1.4rem;
      margin: 0;
      color: #1f4083;
      font-weight: 500;
    }
  }
  
  .crumb-links {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    .crumb-item {
      font-size: 0.8rem;
      font-weight: 500;
      color: #515559;
      // &.active-crumb {
      //   font-weight: normal;
      // }
    }

    .crumb-seperator {
      padding-left: 0.5rem;
      padding-right: 0.5rem;
      font-weight: bold;
    }
  }
  
  
  @media(max-width:768px) {
  .crumb-title {
    h1 {
      font-size: 0.8rem;
    }
  }
  
  .crumb-links {
    .crumb-item {
      font-size: 0.55rem;
    }
  }
 }
  
}



`;

class Breadcrumb extends React.Component<Props, State> {
  static defaultProps = {
    appRoutes: [],
    disable: false,
    // onBeforePathProcess: undefined,
    onAfterPathProcess: undefined,
    activeLabel: undefined,
    customTitle: undefined,
    customEditTitle: undefined,
    customCreateTitle: undefined,
  };

  // @todo must revisit
  rootPath = {
    to: "/userzone",
    label: "All Modules",
    active: false,
  };

  constructor(props: Props) {
    super(props);

    this.state = {
      crumbs: [],
      title: "SWAN",
    };
  }

  componentDidMount() {
    this.setCrumbs();
  }

  getSelectedRoutes = () => {
    const {
      appRoutes,
      match,
      activeLabel,
      customEditTitle,
      customCreateTitle,
    } = this.props;

    const currentPathURLArray = (match.path || "").split("/");
    const selectedRoutes = [];
    let iteratorURL = "";
    currentPathURLArray.shift();
    let prevSegment = "";
    currentPathURLArray.forEach((segment, key) => {
      iteratorURL = `${iteratorURL}/${segment}`;
      selectedRoutes.push(
        ...(appRoutes || []).filter(route => iteratorURL === route.path)
      );
      if (currentPathURLArray.length - 1 === key) {
        prevSegment = selectedRoutes[selectedRoutes.length - 1] || {};
        if (activeLabel) {
          prevSegment.name = activeLabel;
        }
        if (segment === ":id") {
          selectedRoutes.push({
            path: match.url,
            name:
              customEditTitle ||
              (prevSegment || {}).customEditTitle ||
              `Edit ${activeLabel || (prevSegment || {}).name || ""}`,
            active: false,
          });
        } else if (segment === "new") {
          selectedRoutes.push({
            path: match.url,
            name:
              customCreateTitle ||
              (prevSegment || {}).customCreateTitle ||
              `Create ${activeLabel || (prevSegment || {}).name}`,
            active: false,
          });
        }
      }
    });
    return selectedRoutes;
  };

  setCrumbs = () => {
    const { onAfterPathProcess, activeLabel, customTitle } = this.props;
    const selectedRoutes = this.getSelectedRoutes();
    let crumbs = [];
    let title = "SWAN";
    selectedRoutes.forEach((item, key) => {
      crumbs.push({
        to: item.path,
        label: item.name,
        active: item.active || true,
      });
      if (selectedRoutes.length - 1 === key) {
        title = customTitle || item.customTitle || activeLabel || item.name;
      }
    });

    if (onAfterPathProcess && onAfterPathProcess instanceof Function) {
      const crumbsProcessed = onAfterPathProcess(crumbs);
      if (crumbsProcessed && Array.isArray(crumbsProcessed)) {
        crumbs = crumbsProcessed;
      }
    }
    this.setState({
      crumbs,
      title,
    });
  };

  getCrumbs = () => {
    const { crumbs } = this.state;
    const { match } = this.props;

    if (this.rootPath.to === (match || {}).path) {
      return crumbs;
    }

    return [this.rootPath, ...crumbs];
  };

  render() {
    const { disable } = this.props;
    const { title } = this.state;

    const crumbsWithRootPath = this.getCrumbs();
    if (disable) return null;
    return (
      <Wrapper className="crumb-bar">
        <div className="crumb-container">
          {title ? (
            <div className="crumb-title">
              <h1>{title}</h1>
            </div>
          ) : null}
          <div className="crumb-links">
            {crumbsWithRootPath.map((crumb, index) => (
              <div key={`${crumb.label}`}>
                <Link
                  to={crumb.to}
                  className={`crumb-item ${crumb.active ? "active-crumb" : ""}`}
                >
                  {crumb.label}
                </Link>
                {crumbsWithRootPath.length - 1 !== index ? (
                  <span className="crumb-seperator">
                    {crumb.seperator || "/"}
                  </span>
                ) : null}
              </div>
            ))}
          </div>
        </div>
        <BreadcrumbButtons />
      </Wrapper>
    );
  }
}

export default withRouter(Breadcrumb);
