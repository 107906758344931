import React, { PureComponent } from "react";
import {
  PagingState,
  SortingState,
  FilteringState,
  IntegratedSorting,
  CustomPaging,
  DataTypeProvider,
  // IntegratedFiltering,
} from "@devexpress/dx-react-grid";
import { FormService } from "@swan/dynamic-ui"; // eslint-disable-line
import {
  Grid,
  Table,
  TableHeaderRow,
  TableFilterRow,
  TableColumnResizing,
  PagingPanel,
} from "@devexpress/dx-react-grid-bootstrap4";

import { AuthorizedComponent } from "@swan/auth";

import { Button } from "reactstrap";
import { withRouter } from "react-router-dom";

import { toast } from "@swan/themer";

// components

import "./grid.css";

import _ from "lodash";
import TableRow from "./TableRow";
import GridNavBar from "./GridNavBar";
import Loading from "./Loading";
// types
import { type GridProps, type Filter, type Sorting } from "./types";
import {
  DateTimeFormatter,
  getMappedColumnsByType,
  SequenceFormatter,
  SingleSelectFormatter,
  NumberFormatter,
  DecimalFieldFormatter,
} from "./DataTypeProviders/SwanDataTypeProvider";
import ExportButton from "../ExportButton";

type State = {
  data: Array<Object>,
  totalCount: number,
  pageSize: number,
  currentPage: number,
  sorting?: Array<{ columnName: string, direction: string }>,
  filter?: Filter,
  isFetching: boolean,
  options: any,
  devaluePaging: boolean,
};

class SwanGrid extends PureComponent<GridProps, State> {
  static defaultProps = {
    data: [],
    currentPage: 1,
    pages: -1,
    isFetching: undefined,
    totalCount: -1,
    pageSize: 20,
    sorting: [],
    filter: [],
    onRowClick: undefined,
    noPaging: false,
    columns: undefined,
    options: {},
    useGlobalSearch: false,
    setButtons: undefined,
    addButtons: undefined,
    removeButtons: undefined,
    isCustomExportEnabled: false,
    customExportObject: undefined,
    object: "",
    customHeaderButtons: [],
  };

  static getDerivedStateFromProps(props: GridProps, prevState: State) {
    const {
      data,
      isFetching,
      totalCount,
      pageSize,
      currentPage,
      sorting,
      options,
    } = props;
    const { sorting: stateSorting } = prevState;
    const cleansedData = (data || []).filter(
      item => typeof item === "object" && item !== null
    );
    // eslint-disable-next-line no-underscore-dangle
    const _pageSize = pageSize || 30;
    // eslint-disable-next-line no-underscore-dangle
    const _data = [...(cleansedData || [])];
    // eslint-disable-next-line no-underscore-dangle
    const _dataCount = _data.length;
    return {
      data: _data,
      isFetching: isFetching || false,
      totalCount: totalCount || 0,
      pageSize: _pageSize,
      sorting: stateSorting || sorting || [],
      options: options || {},
      devaluePaging: currentPage && currentPage === 1 && _dataCount < _pageSize, // do not have more than the size of _pageSize
    };
  }

  loadData = _.debounce((forcePage?: number) => {
    const { loadRecords, ServiceFactory } = this.props;
    const { currentPage, filter: filters, sorting, pageSize } = this.state;
    const filter = [...(filters || [])];
    const globalSearch = this.getGlobalSearch();
    if (globalSearch) {
      const gs = _.findIndex(filter, { columnName: "globalSearch" });
      if (typeof gs === "undefined") {
        filter[gs] = { ...filter[gs], value: globalSearch };
      } else {
        filter.push({ columnName: "globalSearch", value: globalSearch });
      }
    }
    let tempFilters = [];
    if (ServiceFactory) {
      tempFilters = [...(ServiceFactory.defaultFilter || [])];
    }

    loadRecords(
      forcePage || currentPage + 1,
      filter,
      pageSize,
      sorting,
      tempFilters
    );
  }, 1000);

  constructor(props: GridProps) {
    super(props);
    const {
      data,
      isFetching,
      totalCount,
      pageSize,
      sorting,
      filter,
      options,
      currentPage,
    } = props;
    this.state = {
      data: data || [],
      isFetching: isFetching || false,
      totalCount: totalCount || 0,
      pageSize: pageSize || 30,
      sorting: sorting || [],
      currentPage: currentPage ? currentPage - 1 : 1,
      filter: filter || [],
      options: options || {},
      devaluePaging: false,
    };
  }

  componentDidMount() {
    const { currentPage } = this.props;
    this.loadData(currentPage);
    this.refreshOptions();
  }

  componentDidUpdate(prevProps: GridProps, prevState: State) {
    const { currentPage, filter, sorting } = this.state;
    const { useGlobalSearch, options } = this.props;
    if (prevState.currentPage !== currentPage) {
      this.loadData();
    }
    if (JSON.stringify(prevState.filter) !== JSON.stringify(filter)) {
      this.loadData();
    }
    if (JSON.stringify(prevState.sorting) !== JSON.stringify(sorting)) {
      this.loadData();
    }
    if (JSON.stringify(prevProps.options) !== JSON.stringify(options)) {
      this.refreshOptions();
    }
    if (useGlobalSearch) {
      const {
        location: { hash },
      } = this.props;
      if (prevProps.location.hash !== hash) {
        this.loadData();
      }
    }
  }

  changeCurrentPage = (currentPage: number) => {
    const { isFetching } = this.state;
    if (!isFetching) {
      this.setState({
        currentPage,
      });
    }
  };

  changeFilters = (filter: Filter) => {
    this.setState({ filter, currentPage: 0 });
  };

  changeSorting = (sorting: Sorting) => {
    this.setState({ sorting }, () => {
      this.loadData();
    });
  };

  getButtons = () => {
    let { options } = this.props;
    options = options || {};
    return options.buttons || [];
  };

  getGlobalSearch = () => {
    const { useGlobalSearch } = this.props;
    if (useGlobalSearch) {
      const {
        location: { hash },
      } = this.props;
      return hash.replace("#search/", "");
    }
    return null;
  };

  clearFilter = () => {
    this.setState({ sorting: [], filter: [] });
  };

  exportRecords = () => {
    const { exportRecords } = this.props;
    const { filter } = this.state;
    toast(
      "Please wait exporting in progress, you will receive an email once done."
    );
    if (exportRecords) {
      exportRecords(filter);
    }
  };

  shouldDisplayNavbar() {
    const { setButtons } = this.props;
    if (setButtons) {
      return false;
    }
    return true;
  }

  refreshOptions() {
    const { setButtons } = this.props;
    if (setButtons) {
      setButtons(this.getButtons());
    }
  }

  render() {
    const {
      data,
      sorting,
      currentPage,
      pageSize,
      totalCount,
      filter,
      isFetching,
      options,
      devaluePaging,
    } = this.state;

    const {
      columns,
      isCustomExportEnabled,
      customExportObject,
      object,
      customHeaderButtons,
    } = this.props;

    const { noPaging, noFiltering, noHeaderRow, noSortingControls } = options;
    const columnsTypeMapped = getMappedColumnsByType(columns);

    return (
      <div className="swan-list-grid">
        <div className="grid-actions-bar">
          {(customHeaderButtons || []).map(button => (
            <Button
              key={button.key}
              className="custom-list-button"
              onClick={button.onClick}
            >
              {button.label}
            </Button>
          ))}
          {isCustomExportEnabled ? (
            <Button
              className="custom-export-button"
              onClick={this.exportRecords}
            >
              Export
            </Button>
          ) : (
            <>
              {(object || customExportObject) && (
                <AuthorizedComponent
                  Permission={`${object || (customExportObject || "")}:export`}
                >
                  <ExportButton
                    object={object || customExportObject}
                    filter={filter}
                  />
                </AuthorizedComponent>
              )}
            </>
          )}
          {noSortingControls || noFiltering ? null : (
            <Button className="clear-button" onClick={this.clearFilter}>
              Clear Filters
            </Button>
          )}
        </div>
        <Grid rows={data} columns={columns} sorting={sorting}>
          {columnsTypeMapped &&
            (columnsTypeMapped.datetimeField ||
              columnsTypeMapped.dateField ||
              columnsTypeMapped.datetimeField ||
              columnsTypeMapped.dateTime) && (
              <DataTypeProvider
                formatterComponent={DateTimeFormatter}
                for={[
                  ...columnsTypeMapped.datetimeField,
                  ...columnsTypeMapped.dateField,
                  ...columnsTypeMapped.datetimeField,
                  ...columnsTypeMapped.dateTime,
                ]}
              />
            )}
          {columnsTypeMapped && columnsTypeMapped.sequence && (
            <DataTypeProvider
              formatterComponent={SequenceFormatter}
              for={columnsTypeMapped.sequence || []}
            />
          )}
          {columnsTypeMapped &&
            (columnsTypeMapped.singleSelect || columnsTypeMapped.lookup) && (
              <DataTypeProvider
                formatterComponent={SingleSelectFormatter}
                for={
                  [
                    ...columnsTypeMapped.singleSelect,
                    ...columnsTypeMapped.lookup,
                  ] || []
                }
              />
            )}
          {columnsTypeMapped && columnsTypeMapped.numberField && (
            <DataTypeProvider
              formatterComponent={NumberFormatter}
              for={columnsTypeMapped.numberField || []}
            />
          )}
          {columnsTypeMapped && columnsTypeMapped.decimalField && (
            <DataTypeProvider
              formatterComponent={DecimalFieldFormatter}
              for={columnsTypeMapped.decimalField || []}
            />
          )}
          {!noPaging && (
            <PagingState
              currentPage={currentPage}
              onCurrentPageChange={this.changeCurrentPage}
              pageSize={pageSize}
              totalCount={totalCount}
            />
          )}

          <SortingState
            defaultSorting={sorting}
            sorting={sorting}
            onSortingChange={this.changeSorting}
          />

          <FilteringState
            filters={filter}
            columnFilteringEnabled={!isFetching}
            onFiltersChange={this.changeFilters}
          />

          <IntegratedSorting />

          {!noPaging && <CustomPaging totalCount={totalCount} />}
          {/* <IntegratedFiltering /> */}
          {this.shouldDisplayNavbar() && (
            <GridNavBar gridProps={this.props} options={options || {}} />
          )}
          <Table
            rowComponent={props =>
              TableRow({ ...props, gridProps: this.props })
            }
          />
          {columns && columns.length > 0 && options.resizable && (
            <TableColumnResizing
              defaultColumnWidths={options.defaultColumnWidths || []}
            />
          )}

          {!noHeaderRow && (
            <TableHeaderRow showSortingControls={!noSortingControls} />
          )}
          {!noFiltering && <TableFilterRow />}

          {noPaging || devaluePaging ? null : (
            <PagingPanel totalCount={totalCount} />
          )}
        </Grid>
        {isFetching && <Loading />}
      </div>
    );
  }
}

export default withRouter(SwanGrid);
