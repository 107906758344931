import * as React from "react";
// import { withRouter } from "react-router-dom";
import Breadcrumbs from ".";

type ConfigType = {
  renderer?: React.ComponentType<any>,
  routes?: any,
  selector?: string,
};

type Props = ConfigType & {
  children?: void | null | boolean | string | number | React.Element<any>,
};

const Factory = (config: ConfigType) => (props: Props) => {
  const { renderer, routes, children, ...restProps } = props;

  const CrumbRenderer = renderer || (config || {}).renderer;
  const appRoutes = routes || (config || {}).routes;

  const CrumbComponent =
    CrumbRenderer || Breadcrumbs[(config || {}).selector || "default"];

  return (
    <>
      <CrumbComponent appRoutes={appRoutes} {...restProps} />
      {React.Children.map(children, child =>
        React.cloneElement(child, { ...restProps })
      )}
    </>
  );
};

export const withBreadcrumb = (
  config: ConfigType,
  Component: React.ComponentType<any>
) => (props: any) => {
  const Breadcrumb = Factory(config);
  const { breadcrumbOptions, ...restProps } = props;
  return (
    <>
      <Breadcrumb {...breadcrumbOptions} />
      <Component {...restProps} />
    </>
  );
};

export default Factory;

// Must return with withRouter, if breadcrumb is not wrapped with it
