import * as React from "react";
import styled, { withTheme } from "styled-components";

import { Row as DefaultRow } from "reactstrap";
import Col from "./Col";
import { RendererContext } from "../Main";

// utils
import canDisplayElement from "../../utils/canDisplayElement";

import type { ElementProps } from "./types";
import type { Schema } from "../../types";

const RowWrapper = styled(styled(DefaultRow)`
  ${props => (props || {}).styles}
`)({}, ({ theme }) => (theme.RowWrapper ? { ...theme.RowWrapper } : {}));

class Row extends React.Component<ElementProps> {
  getCols = (
    properties: { [string]: Schema },
    rowOptions?: Object,
    formProps?: Object
  ) => {
    // early exit
    if (canDisplayElement(rowOptions || {}, formProps) === false) {
      return null;
    }

    const { id } = this.props;
    if (!properties) return [];
    return Object.keys(properties).map((colName: string, index: number) => {
      if (properties[colName].type !== "col") return null;

      return (
        <Col
          index={index}
          key={`${id}/${colName}`}
          rowId={id}
          id={`${id}/${colName}`}
          schema={properties[colName]}
          formProps={formProps}
          colCount={Object.keys(properties).length}
          childCount={Object.keys(properties).length}
        />
      );
    });
  };

  render() {
    const {
      schema: { properties, options },
    } = this.props;

    return (
      <RendererContext.Consumer>
        {({ isNew, data }) => {
          const cols = this.getCols(properties || {}, options, { isNew, data });
          return (
            <RowWrapper
              className={(options || {}).classNames || ""}
              styles={(options || {}).customStyles || ""}
            >
              {cols}
            </RowWrapper>
          );
        }}
      </RendererContext.Consumer>
    );
  }
}

export default withTheme(Row);
