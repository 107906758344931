import React from "react";
import { uriProvider } from "./utils/uriHelpers";
import {
  MODULE_NAME,
  MODULE_DISPLAY_NAME,
  MODULE_SHORT_DISPLAY_NAME,
} from "./config";

// $FlowFixMe Should be React.lazy<DashboardProps> but causing issue in webpack
const Home = React.lazy(() => import("./Module"));

// https://github.com/ReactTraining/react-router/tree/master/packages/react-router-config
const entry_routes = [
  {
    path: "/hyperion",
    name: MODULE_NAME,
    component: Home,
    Permission: ["module:hyperion"],
  },
];

export default {
  name: MODULE_NAME,
  displayName: MODULE_DISPLAY_NAME,
  mainNav: {
    name: MODULE_DISPLAY_NAME,
    url: uriProvider(),
    // href: "http://financials.mazrui.ae/",
    // isExternal: true,
    icon: "fa-cogs",
    permissions: ["module:hyperion"],
    color: "#2684FF",
    shortName: MODULE_SHORT_DISPLAY_NAME,
    weight: 70,
  },
  preloadPermissions: {
    groups: ["hyperion", "core"],
  },
  productNav: [
    {
      name: "Hyperion",
      isExternal: true,
      href: "https://financials.mazrui.ae",
      url: "",
      icon: "fa-cogs",
      permissions: ["module:hyperion"],
    },
    {
      name: "Journals",
      url: "/hyperion/journals",
      icon: "fa-cogs",
      permissions: ["hyperionJournal:view"],
    },
    {
      name: "Vat",
      url: "/hyperion/vat/vat-return-reports",
      icon: "fa-cogs",
      permissions: ["hyperionVatReturnReport:view"],
    },
    {
      name: "Vat Consolidated Reports",
      url: "/hyperion/vat/vat-consolidated-return-reports",
      icon: "fa-cogs",
      permissions: ["hyperionVatConsolidatedReturnReport:view"],
    },
    {
      name: "Hyperion Budgeting",
      url: "/hyperion/financial/budget",
      icon: "fa-cogs",
      permissions: ["hyperionBudget:create"],
    },
    {
      name: "Hyperion Budgeting",
      url: "/hyperion/financial/consolidated-budgeting",
      icon: "fa-cogs",
      permissions: ["hyperionConsolidatedBudget:view"],
    },
  ],
  routes: entry_routes,
};
