import React, { type ComponentType } from "react";
import styled from "styled-components";

import Elements from ".";

import type { ElementProps } from "./types";

const withCloseOverFieldTag = (closeOverFieldTag: string = "div") => (
  Component: ComponentType<*>
) => (props: any) => {
  const StyledTag = styled(closeOverFieldTag)``;
  return (
    <StyledTag>
      <Component {...props} />
    </StyledTag>
  );
};

const Element = ({
  schema,
  id,
  index,
  closeOverFieldTag,
  rendererComponent,
  ...rest
}: ElementProps) => {
  if (((schema || {}).options || {}).hideField) return null;
  let Component = null;

  if (rendererComponent) {
    Component = rendererComponent;
  } else if (schema.type) {
    Component = Elements[schema.type];
  }

  if (Component) {
    if (closeOverFieldTag) {
      Component = withCloseOverFieldTag(closeOverFieldTag)(Component);
    }
    return <Component schema={schema} id={id} index={index} {...rest} />;
  }

  return null;
};

export default Element;
