import moment from "moment-timezone";

const isoDateFormatString: string = "YYYY-MM-DDThh:mm:ssZ";

const getCurrentTimeZone = () => moment.tz.guess();

export default (
  type: string,
  d?: number | string | Date,
  options?: Object = {}
) => {
  let date;
  // accept Date object
  if (d && typeof d === "object" && d.constructor === Date) {
    date = d;
  }
  // otherwise, we accept only iso string format as above
  // if (typeof d === "number") date = String(d);
  if (typeof d === "number") date = d;
  if (typeof d === "string") {
    if (moment(d, isoDateFormatString).format(isoDateFormatString) === date) {
      // string is in iso format
      date = moment(d, isoDateFormatString);
    } else {
      date = moment.utc(d);
    }
    date = date.isValid() ? date.toDate() : new Date();
  }

  const timezone = (options || {}).tz || getCurrentTimeZone();
  if (!date) return "";
  // if (date) {
  //   date = moment(date)
  //     .tz(timezone)
  //     .toDate();
  // } else {
  //   return "";
  // }

  let defaultFormat;
  if ((options || {}).dateFormat) {
    defaultFormat = options.dateFormat;
  } else {
    // @todo need to address defaultFormat against "type"
    defaultFormat = "DD/MM/YYYY hh:mm a";
  }

  return moment(date)
    .tz(timezone)
    .format(defaultFormat);
};
