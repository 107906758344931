import ServiceGenerator from "./serviceGenerator";

export type Filter = Array<{
  columnName: string,
  value: string,
}>;

export type Sorting = Array<{
  columnName: string,
  direction: string,
}>;

const ResourceGenerator = (
  Axios: any,
  prefix: string = "",
  timeout?: number
) => {
  const Service = ServiceGenerator(Axios, prefix);
  class Resource extends Service {
    defaultFilter = [];

    constructor(...props: any) {
      super(...props);
      if (typeof timeout !== "undefined" && timeout !== null) {
        this.timeout = timeout;
      }
    }

    setDefaultFilters(filter: Filter) {
      this.defaultFilter = filter;
    }

    clearDefaultFilters() {
      this.defaultFilter = [];
    }

    /**
     * CRUD
     */
    find(
      page: number,
      filter: Filter,
      pageSize: number,
      sorting: Sorting,
      extra: Object
    ) {
      return this.get("", {
        page,
        pageSize,
        sorting,
        filter: [...(filter || []), ...this.defaultFilter],
        ...extra,
      });
    }

    /**
     * CRUD
     */
    export(filter: Filter, extra: Object) {
      return this.post("export-records", {
        filter: [...(filter || []), ...this.defaultFilter],
        ...extra,
      });
    }

    /**
     * Create
     */
    create(data: {}) {
      return this.post("", { ...data });
    }

    /**
     * Update
     */
    update(id: number, data: {}) {
      return this.put(`${id}`, { ...data });
    }

    /**
     * Delete
     */
    delete(id: number) {
      return this.deleteRequest(`${id}`);
    }
  }
  return Resource;
};

export default ResourceGenerator;
