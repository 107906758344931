export default (file: any, accessors?: any) => {
  if (file instanceof File) {
    return URL.createObjectURL(file);
  }

  let src;
  if (accessors && Array.isArray(accessors)) {
    // $FlowFixMe
    accessors.some((accessor: string) => {
      if (file[accessor]) {
        src = file[accessor];
        return true;
      }
      return false;
    });
  }
  return src;
};
