import { createStore, applyMiddleware, compose, combineReducers } from "redux";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import createSagaMiddleware from "redux-saga";
import type { Saga } from "redux-saga";
import { swanContextManager } from "../context";

const generateStore = (
  key: string,
  rootSaga: Saga<any>,
  rootReducer: Function
) => {
  const currentSessionId = swanContextManager.getValue("sessionId");
  const persistConfig = {
    key,
    storage,
    migrate: (state: Object) => {
      if (!state) {
        return Promise.resolve({});
      }
      if (!state.SwanReducer) {
        return Promise.resolve({});
      }
      if (
        !state.SwanReducer.sessionId ||
        state.SwanReducer.sessionId !== currentSessionId
      ) {
        return Promise.resolve({
          SwanReducer: {
            sessionId: currentSessionId,
          },
        });
      }
      return Promise.resolve(state);
    },
  };

  const SwanReducer = () => ({
    sessionId: currentSessionId,
  });

  const sagaMiddleware = createSagaMiddleware();
  const persistedReducer = persistReducer(
    persistConfig,
    combineReducers({
      ...rootReducer,
      SwanReducer,
    })
  );

  /* eslint-disable no-underscore-dangle */
  const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
        name: `SWAN | ${key}`,
      })
    : compose;
  // $FlowFixMe Should be createStore<*, *, *>
  const store = createStore(
    persistedReducer,
    // window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__(),
    composeEnhancer(applyMiddleware(sagaMiddleware))
  );
  /* eslint-enable */
  // $FlowFixMe
  sagaMiddleware.run(rootSaga);

  const persistor = persistStore(store);
  return { store, persistor };
};

export default generateStore;
