import { hasIn, map, filter, head, get } from "lodash";
import { getStore } from "../../state/store";

export const getJsonSchemaForRelatedObject = (
  id: string
): {
  jsonSchema: {},
  jsonUiSchema: {},
} => {
  const store = getStore();
  const { RELOBJ } = store.getState().app.pencils;

  const relatedPen = head(filter(RELOBJ, relPen => relPen.id === id));

  const formsAvail = [];
  if (relatedPen && hasIn(relatedPen, ["__relobj", "child", "forms"])) {
    const forms = get(relatedPen, ["__relobj", "child", "forms"]);
    map(forms, form => {
      formsAvail.push({
        formId: form.id,
        formName: form.name,
        formType: form.type,
      });
    });
  }

  const formsEnum = map(formsAvail, formAvail => formAvail.formId);
  const formsEnumNames = map(formsAvail, formAvail => formAvail.formName);

  const jsonSchema = {
    type: "object",
    properties: Object.assign(
      {},
      {
        title: {
          type: "string",
          title: "Field Label",
        },
        listTitle: {
          type: "string",
          title: "List Title",
        },
        showListTitle: {
          type: "boolean",
          title: "Show List Title (default is Hidden)",
        },
        disableAdding: {
          type: "boolean",
          title: "Disable Adding",
        },
        disableFilteration: {
          type: "boolean",
          title: "Disable Filteration",
        },
        disableSorting: {
          type: "boolean",
          title: "Disable Sorting",
        },
        listMode: {
          type: "string",
          title: "List View",
          enum: ["grid", "card"],
          enumNames: ["Grid", "Card"],
        },
        editMode: {
          type: "string",
          title: "Edit Mode",
          enum: ["inline", "modal"],
          enumNames: ["In Line", "Modal"],
        },
        defaultForm: {
          type: "number",
          title: "Edit Form",
          enum: formsEnum,
          enumNames: formsEnumNames,
        },
        validation: {
          type: "object",
          properties: {
            required: {
              type: "boolean",
            },
            minItems: {
              type: "number",
              title: "Minimum items required",
            },
            maxItems: {
              type: "number",
              title: "Maximum items required",
            },
          },
        },
      }
      // type === "OneToMany"
      //   ? {
      //       maxAllowed: {
      //         type: "number",
      //         title: "Number of Entries Allowed",
      //         help: "Enter 0, negative value or leave it empty for infinite",
      //         default: 0,
      //       },
      //     }
      //   : {}
    ),
    required: ["title"],
    dependencies: {
      listMode: {
        oneOf: [
          {
            properties: {
              listMode: {
                enum: ["card"],
              },
              cardViewForm: {
                type: "number",
                title: "Card View Form",
                enum: formsEnum,
                enumNames: formsEnumNames,
              },
            },
          },
        ],
      },
    },
  };
  const jsonUiSchema = {};

  return {
    jsonSchema,
    jsonUiSchema,
  };
};

export default {
  getJsonSchemaForRelatedObject,
};
