// @flow

import { SchemaWalker, objectWalker } from "@swan/form-designer/utils"; // eslint-disable-line

// type
import { type Schema } from "@swan/form-renderer"; // eslint-disable-line
import { SET_VALUE } from "./verbs";

// eslint-disable-next-line no-unused-vars
export default (verb: string, template: string) => (
  {
    data,
    schema,
    updateData,
  }: {
    data: Object,
    schema: Schema,
    updateData: Function, // callback to update the record
  },
  { record }: { record: Object } = { record: undefined }
) => {
  if ([SET_VALUE].indexOf(verb) !== -1) {
    switch (verb) {
      case SET_VALUE:
        {
          const resource = (data || {}).resource || {};
          if (
            updateData &&
            record &&
            record[((resource || {}).data || {}).attributeName] !==
              data.setValue
          ) {
            updateData(
              ((resource || {}).data || {}).attributeName,
              data.setValue
            );
          }
        }
        break;
      default:
        break;
    }
  }

  return schema;
};
