// @flow
import { cloneDeep } from "lodash";
import {
  // ruleEvaluator,
  actionsDictionary,
  provideActions,
} from "@swan/object-conditions"; // eslint-disable-line

// types
// $FlowFixMe
import { type Schema } from "@swan/form-designer/types";
import { type Rules } from "../types";

export default ({
  rules,
  data,
  schema,
  updateData,
}: {
  rules: Rules,
  data: Object,
  schema: Schema,
  updateData: Function,
}) => {
  let schemaCooked = cloneDeep(schema);

  const { layout: layoutRules } = rules;
  if (layoutRules) {
    const resultedActions = provideActions(layoutRules, {
      record: data,
    });

    resultedActions.forEach((action: Object) => {
      const { action: actionFunction } = actionsDictionary.find(
        dictionaryItem => dictionaryItem.id === action.id
      );
      schemaCooked = actionFunction(
        {
          schema: schemaCooked,
          data: action.data,
          updateData,
        },
        {
          schema,
          record: data,
        }
      );
    });
  }

  return schemaCooked;
};
