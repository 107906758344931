import * as React from "react";
import type { Route as RouteType } from "./flowTypes";
import CoreRoutes from "./core";

import RoutesContext from "./routes-context";

export type Route = RouteType;

type State = {
  coreRoutes: Array<Route>,
  modulesRoutes: { [string]: Array<Route> },
  routes: Array<Route>
};

export class RoutesProvider extends React.Component<any, State> {
  constructor(props: any) {
    super(props);

    this.state = {
      coreRoutes: [...CoreRoutes],
      modulesRoutes: {},
      routes: [...CoreRoutes]
    };

    this.registerRoutes = this.registerRoutes.bind(this);
  }

  registerRoutes = (routes: Array<Route>, module: string) => {
    this.setState(state => {
      const modulesRoutes = {
        ...state.modulesRoutes,
        [module]: [...routes]
      };
      return {
        ...state,
        modulesRoutes,
        routes: this.getRoutes(modulesRoutes)
      };
    });
  };

  getRoutes = (newModulesRoutes?: { [string]: Array<Route> }): Array<Route> => {
    const { coreRoutes, modulesRoutes } = this.state;
    let routes = [...coreRoutes];
    const modRoutes = newModulesRoutes || modulesRoutes;
    Object.keys(modRoutes).forEach(
      mod => (routes = [...routes, ...modRoutes[mod]])
    );
    return routes;
  };

  render() {
    const { routes } = this.state;
    const contextValue = {
      routes: routes,
      registerRoutes: this.registerRoutes
    };
    const { children } = this.props;
    return (
      <RoutesContext.Provider value={contextValue}>
        {children}
      </RoutesContext.Provider>
    );
  }
}

export const RoutesConsumer = RoutesContext.Consumer;

export default {
  Provider: RoutesProvider,
  Consumer: RoutesConsumer
};
