import React from "react";
import renderer from "react-test-renderer";
import { IntlProvider } from "react-intl";

const renderComponentWithIntl = (
  children: any,
  props: { locale: string } = { locale: "en" }
) => renderer.create(<IntlProvider {...props}>{children}</IntlProvider>);

export const withIntlProvider = (Component: any) => {
  const WrappedComponent = (props: any) => (
    <IntlProvider {...props}>
      <Component {...props} />
    </IntlProvider>
  );
  return WrappedComponent;
};

export default renderComponentWithIntl;
