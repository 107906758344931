import React, { Component } from "react";
import { Input } from "reactstrap";

import { type AddressType } from "./types";

type Props = {
  elementId: string,
  map: ?Object,
  // marker: Object,
  address?: string,
  onSubmit: (address: AddressType) => any,
  className?: string,
  error?: string,
};

type State = {
  searchQuery?: string,
};

class SearchField extends Component<Props, State> {
  static defaultProps = {
    className: "",
    error: "",
    address: "",
  };

  constructor(props: Props) {
    super(props);
    this.state = {
      searchQuery: props.address || "",
    };
  }

  componentDidMount() {
    const { address } = this.props;
    this.setState(
      {
        searchQuery: address,
      },
      this.searchListener
    );
  }

  searchListener = () => {
    const { map, elementId, onSubmit } = this.props;

    if (!window.google) return;

    const { maps } = window.google;
    const autoComplete =
      maps &&
      new maps.places.Autocomplete(
        document.getElementById(`${elementId}_search`)
      );

    if (!autoComplete) return;

    autoComplete.bindTo("bounds", map);
    autoComplete.addListener("place_changed", () => {
      //   if (marker) {
      //     console.log(marker);
      //     marker.setVisible(false);
      //   }
      const place = autoComplete.getPlace();
      if (!place.geometry) {
        // User entered the name of a Place that was not suggested and
        // pressed the Enter key, or the Place Details request failed.
        return;
      }

      // If the place has a geometry, then present it on a map.
      if (map) {
        if (place.geometry.viewport) {
          map.fitBounds(place.geometry.viewport);
        } else {
          map.setCenter(place.geometry.location);
          map.setZoom(17); // Why 17? Because it looks good.
        }
      }

      const latlng = {
        lat: place.geometry.location.lat(),
        lng: place.geometry.location.lng(),
      };
      const address = place.formatted_address;
      this.setState(
        {
          searchQuery: address,
        },
        () => {
          if (onSubmit) {
            onSubmit({ ...latlng, address });
          }
        }
      );
    });
    autoComplete.setFields([
      "address_components",
      "formatted_address",
      "geometry",
      "icon",
      "name",
    ]);
  };

  onChange = (e: { target: { value: string } }) => {
    this.setState({
      searchQuery: e.target.value,
    });
  };

  searchRef: ?HTMLInputElement;

  render() {
    const { elementId, className, error } = this.props;
    const { searchQuery } = this.state;
    return (
      <div className="form-map-control-input">
        <Input
          type="search"
          id={`${elementId}_search`}
          placeholder="Search..."
          value={searchQuery}
          onChange={this.onChange}
          ref={el => {
            this.searchRef = el;
            return null;
          }}
          className={`${className || ""} ${error !== "" ? "is-invalid" : ""}`}
        />
      </div>
    );
  }
}

export default SearchField;
