/* eslint-disable no-underscore-dangle */
// @flow
import { ObjectService } from "@swan/api"; // eslint-disable-line

// type
type CascadeType = {
  __type: string,
  __relationName?: string,
  label: string | Node,
  value: string,
  children: Array<
    | {|
        __type: "attribute",
        attributeId: number,
        label: string | Node,
        value: string,
        type: string,
      |}
    | {|
        __type: "relatedObject",
        __isCollection: boolean,
        __collection: string, // child_object
        __relationName: string, // alias to __relationMeta.name
        __relationMeta: {
          name?: string,
          reverseName?: string,
          type?: string,
          parentObject?: string,
        },
        key: string,
        label: string | Node,
        value: string,
      |}
    | CascadeType
  >,
};

// cascade preparer
export const prepareCascader = (
  data: Object,
  meta?: Object = { __type: "object" }
) => {
  const cascade: CascadeType = {
    ...{
      label: data.display_name,
      value: data.name,
      key:
        (meta || {}).__type === "relatedObject"
          ? (meta || {}).__relationName
          : data.key || (meta || {}).__key,
      children: [],
    },
    ...meta,
  };

  // list object attributes
  ((data || {}).attributes || []).forEach(
    ({ id: attributeId, label, name: value, type }) => {
      cascade.children.push({
        __type: "attribute",
        attributeId,
        label,
        value,
        type, // @todo type shouldn't be pencil definition type,
        // instead, this should be replaced by storage type i.e. string, number
      });
    }
  );

  // list object relationships
  if (Object.keys(data).indexOf("relationships") !== -1) {
    const objectDataRelations = Object.values(data.relationships);
    const filteredChildObjectRelations = objectDataRelations.filter(
      (objRelation: Object) =>
        (objRelation.type === "OneToMany" ||
          objRelation.type === "Polymorphic") &&
        objRelation.parent_object === data.name
    );

    if (filteredChildObjectRelations.length) {
      filteredChildObjectRelations.forEach((filteredChildObject: Object) => {
        if ((filteredChildObject || {}).child_object) {
          cascade.children.push({
            __type: "relatedObject",
            __relationName: (filteredChildObject || {}).name,
            label: `[${(filteredChildObject || {}).name}]`,
            value: (filteredChildObject || {}).child_object,
            key: (filteredChildObject || {}).name, // relationship names
            __isCollection: true,
            __collection: (filteredChildObject || {}).child_object,
            __relationMeta: {
              name: (filteredChildObject || {}).name,
              reverseName: (filteredChildObject || {}).reverse_name,
              type: (filteredChildObject || {}).type,
              parentObject: (filteredChildObject || {}).parent_object,
            },
          });
        }
      });
    }
  }

  return cascade;
};

// consider I'm receiving an object
export const objectDataCascade = async (
  object: string,
  cascadedData: Object = {},
  cascadePath: Array<string> = [],
  cascadeMeta: Object = { __type: "object" }
) => {
  // let objectData;
  let objectsDataCascaded = { ...cascadedData };
  const pathNow = [...cascadePath];

  const objectData = await ObjectService.getObjectByName(object);

  // []
  if (!pathNow.length)
    objectsDataCascaded = {
      ...objectsDataCascaded,
      ...prepareCascader(objectData, cascadeMeta),
    };

  if (pathNow.length) {
    pathNow.reduce((c: Object, val, i) => {
      if (i === pathNow.length - 1) {
        // $lowFixMe
        c.children.push(prepareCascader(objectData, cascadeMeta));
      }
      const foundIdx = c.children.findIndex(
        child => child.__type === "relatedObject" && child.value === val
      );
      if (foundIdx !== -1) return c.children[foundIdx];
      return c;
    }, objectsDataCascaded);
  }

  // get parent objects and child relationships
  if (Object.keys(objectData).indexOf("relationships") !== -1) {
    const objectDataRelations = Object.values(objectData.relationships);
    const filteredParentObjectRelations = objectDataRelations.filter(
      (objRelation: Object) =>
        (objRelation.type === "OneToMany" ||
          objRelation.type === "Polymorphic") &&
        objRelation.child_object === object
    );
    if (filteredParentObjectRelations.length) {
      filteredParentObjectRelations.forEach((filteredParentObject: Object) => {
        if ((filteredParentObject || {}).parent_object) {
          // pathNow.push((filteredParentObject || {}).parent_object);
          const currentPath = pathNow.concat([
            (filteredParentObject || {}).parent_object,
          ]);
          // relatedObject has attributes
          objectDataCascade(
            (filteredParentObject || {}).parent_object,
            objectsDataCascaded,
            currentPath,
            {
              __type: "relatedObject",
              __relationName: (filteredParentObject || {}).name, // relation "name" is must included column
            }
          );
        }
      });
    }
  }

  return objectsDataCascaded;
};

export default {
  prepareCascader,
  objectDataCascade,
};
