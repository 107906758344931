import * as React from "react";
import { RemoteSelect } from "@swan/themer";
import { ObjectService, Resource } from "@swan/api"; // eslint-disable-line
import { get } from "lodash";

import Field from "./partials/Field";
import type { ElementProps } from "./types";
import { RendererContext } from "../Main";

function onSelectChange(onBatchChange, objectDefinition, schema, value) {
  if (onBatchChange) {
    const v = {};
    Object.keys(value).forEach(attr => {
      if (attr === "value") {
        v[`${(objectDefinition || {}).polymorph_type}_id`] = value[attr];
      } else if (attr === "label") {
        v[`${(objectDefinition || {}).polymorph_type}_id_display`] =
          value[attr];
      } else {
        v[attr] = value[attr];
      }
    });
    onBatchChange(v);
  }
}

class PolymorphParentConfigured extends React.Component<
  ElementProps & {
    object: string,
    recordParentKnown: boolean,
    polymorphParentEndpoint?: string,
    filterAccessors?: Object,
  },
  {
    service?: Object,
    objectDefinition?: Object,
    labelAttribute?: ?string,
    labelExpression?: ?string,
  }
> {
  static defaultProps = {
    polymorphParentEndpoint: undefined,
  };

  state = {
    service: null,
    objectDefinition: null,
    labelAttribute: null,
    labelExpression: null,
  };

  componentDidMount() {
    const { object, recordParentKnown } = this.props;
    if (recordParentKnown) {
      return;
    }

    ObjectService.getObjectByName(object).then(def => {
      if (def) {
        const { polymorphParentEndpoint } = this.props;
        const service = new Resource(
          polymorphParentEndpoint || def.polymorph_parent_endpoint
        );
        this.setState({
          service,
          objectDefinition: def,
          labelAttribute: "name",
        });
      }
    });
  }

  getValueMap() {
    const { objectDefinition } = this.state;
    if (!objectDefinition) {
      return {};
    }
    return {
      type: `${objectDefinition.polymorph_type}_type`,
    };
  }

  getFilters() {
    const { objectDefinition } = this.state;
    const { data, filterAccessors } = this.props;
    if (!objectDefinition) {
      return {};
    }
    const filters = Object.assign(
      {},
      {
        // type: (data || {})[`${objectDefinition.polymorph_type}_type`] || "",
      }
    );
    if (filterAccessors) {
      filterAccessors.forEach(filter => {
        filters[filter.attribute] = {
          value: get(data || {}, filter.accessor || "", ""),
          operator: filter.operator || "=",
        };
      });
    }

    return filters;
  }

  render() {
    const {
      schema,
      // onChange,
      onBatchChange,
      error,
      data,
      readOnly,
      hideLabel,
      recordParentKnown,
    } = this.props;
    // TODO FIND BETTER WAY TO HANDLE THIS
    if (recordParentKnown) {
      return null;
    }
    const {
      service,
      labelAttribute,
      labelExpression,
      objectDefinition,
    } = this.state;
    if (!service) {
      return null;
    }
    const attributeName = `${(objectDefinition || {}).polymorph_type}_id`;
    return (
      <>
        <RemoteSelect
          {...schema.options || {}}
          name={attributeName || ""}
          value={(data || {})[attributeName || ""]}
          displayValue={
            (data || {})[`${schema.attributeName || ""}_display`] ||
            (data || {})[schema.attributeName || ""] ||
            ""
          }
          initialDisplay={
            (data || {})[`${schema.attributeName || ""}_display` || ""]
          }
          label={schema.label}
          hideLabel={hideLabel}
          onChange={e => {
            onSelectChange(onBatchChange, objectDefinition, schema, e);
          }}
          error={(error || {})[attributeName || ""]}
          labelAttribute={labelAttribute}
          labelExpression={labelExpression}
          service={service}
          filters={this.getFilters()}
          valueRetreiveFilter={this.getFilters()}
          valueMap={this.getValueMap()}
          // creatable
          static={readOnly || (schema.options || {}).readOnly}
        />
      </>
    );
  }
}

const PolymorphParent = (props: ElementProps) => (
  <RendererContext.Consumer>
    {({ object, options }) => (
      <PolymorphParentConfigured
        {...props}
        object={object}
        recordParentKnown={options.recordParentKnown || false}
      />
    )}
  </RendererContext.Consumer>
);

export default Field(PolymorphParent);
