import React from "react";

// $FlowFixMe
export const RemoteSelect = React.lazy(() => import("./Form/RemoteSelect"));
// $FlowFixMe
export const SwanCalendar = React.lazy(() => import("./Calendar/SwanCalendar"));
// $FlowFixMe
export const ObjectCalendar = React.lazy(() =>
  import("./Calendar/ObjectCalendar")
);

// $FlowFixMe
export const TaggableSelect = React.lazy(() => import("./Form/TaggableSelect"));

export default {};
