import React, { Component } from "react";
import { RelationshipResourceFactory } from "@swan/api";
import { TaggableSelect } from "@swan/themer";
import CustomComponentWrapper from "../CustomComponentWrapper";

type Props = {
  data: Object,
  name?: string,
  label: string,
  optionLabelAccessor?: string,
  valueLabelAccessor?: string,
};

type TaggableOptionsType = {
  object: string,
  relationName: string,
  service: Object,
  setFilters?: (data: Object) => Array<Object>,
};

export default (options: TaggableOptionsType) => {
  const { object, relationName, service: Service, setFilters } = options;

  const RelationshipService = RelationshipResourceFactory(
    object,
    relationName,
    true
  );

  class Selector extends Component<Props> {
    static defaultProps = {
      name: undefined,
      optionLabelAccessor: "name",
      valueLabelAccessor: "name",
    };

    constructor(props: Props) {
      super(props);
      this.service = new Service();
    }

    service: Object;

    render() {
      const {
        data,
        name,
        label,
        optionLabelAccessor,
        valueLabelAccessor,
      } = this.props;
      if (data.id) {
        const relationshipService = new RelationshipService();
        relationshipService.setParent(data.id);

        if (setFilters && setFilters instanceof Function) {
          const filters = setFilters(data);

          if (Array.isArray(filters) && filters.length) {
            this.service.setDefaultFilters(filters);
          }
        }

        return (
          <TaggableSelect
            label={label}
            optionsProps={{
              service: this.service,
              labelAttribute: optionLabelAccessor || "name",
            }}
            valuesProps={{
              service: relationshipService,
              labelAttribute: valueLabelAccessor || "name",
            }}
            name={name || `${object || ""}_${relationName}`}
          />
        );
      }

      return null;
    }
  }

  return CustomComponentWrapper(Selector);
};
