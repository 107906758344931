/* eslint-disable camelcase */
import React, { Component } from "react";
import { ReorderResourceFactory as ReorderService } from "@swan/api";
import { toast } from "@swan/themer";

type Filter = Array<{
  columnName: string,
  operation: string,
  value: string | number,
}>;

type Sorting = Array<{ columnName: string, direction: string }>;

type Props = {
  listId: string,
  extraParameters?: () => {},
  isLoadMore?: boolean,
};
type State = {
  data?: Array<Object>,
  isFetching?: boolean,
  current_page?: number,
  total?: number,
  per_page?: number,
  sorting?: Sorting,
  filter?: Filter,
  error?: string | Object,
  loadMoreEnabled?: boolean,
  isLoadingMore?: boolean,
};

const ListDataProvider = (Service: Object) => (ListComponent: any) => {
  class WrappedComponent extends Component<Props, State> {
    static defaultProps = {
      extraParameters: undefined,
      isLoadMore: false,
    };

    static resetUpdate() {}

    mounted = false;

    constructor(props: Props) {
      super(props);

      this.state = {
        data: [],
        total: -1,
        per_page: 20,
        loadMoreEnabled: true,
        isLoadingMore: false,
        current_page: 1,
        sorting: [],
        filter: [],
        isFetching: false,
      };
      if (typeof Service === "object") {
        this.service = Service;
      } else {
        this.service = new Service();
      }
    }

    componentDidMount() {
      // If a list ID is provided, register an event listener to trigger update
      const { listId } = this.props;
      this.mounted = true;
      if (listId) {
        window.addEventListener(`${listId}::refresh`, () => {
          this.refresh();
        });
      }
    }

    componentWillUnmount() {
      this.mounted = false;
    }

    /**
     * reorder frontend record in the new index
     */
    reorderLocalRecord = (
      oldOrder: number,
      newOrder: number,
      data: Array<Object>
    ) => {
      const previousData = data;
      if (!previousData || previousData.length === 0) {
        return;
      }
      const item = previousData.splice(oldOrder, 1);
      previousData.splice(newOrder, 0, item[0]);
      this.setState({ data: previousData });
    };

    /**
     * reorder record in the database and frontend
     * @param oldOrder
     * @param objectName
     * @param newOrder
     * @param previousData
     */
    reorderRecord = (
      oldOrder: number,
      objectName: string,
      newOrder: number,
      previousData: Array<Object>
    ) => {
      const reorderService = new (ReorderService(objectName))();
      const id =
        previousData && previousData[oldOrder]
          ? previousData[oldOrder].id
          : null;

      const newItemId =
        previousData && previousData[newOrder]
          ? // eslint-disable-next-line
            previousData[newOrder].id
          : null;
      if (!id) {
        return;
      }
      const oldCopyOfPreviousData = [...previousData];
      this.reorderLocalRecord(oldOrder, newOrder, previousData);
      reorderService.reorderById(id, objectName, newItemId).then(
        () => {},
        e => {
          toast(e && e.message, { type: "error" });
          this.setState({ data: oldCopyOfPreviousData });
        }
      );
    };

    loadRecords = (
      page: number,
      filter?: Filter,
      pageSize?: number,
      sorting?: Sorting,
      previousData?: Array<Object>
    ) => {
      const { extraParameters, isLoadMore } = this.props;
      let extra = {};
      if (extraParameters) {
        extra = extraParameters();
      }
      let prevData = [];
      if (previousData && previousData.length > 0) {
        prevData = previousData;
      }
      if (this.mounted) {
        this.setState(
          {
            isFetching: true,
            isLoadingMore: true,
          },
          () => {
            if (!this.service || typeof this.service.find !== "function") {
              // TODO: Throw exception once exception handling is implemented
              return;
            }
            this.service
              .find(page, filter, pageSize, sorting, extra)
              .then(({ data, ...rest }) => {
                this.setState({
                  isFetching: false,
                  // pages: response.last_page,
                  error: "",
                  filter: [...(filter || [])],
                  sorting: [...(sorting || [])],
                  ...rest,
                });
                if (data.length > 0) {
                  this.setState({
                    data: [...prevData, ...data],
                  });
                }
                if (isLoadMore) {
                  this.setState({
                    loadMoreEnabled: data.length > 0,
                    isLoadingMore: false,
                  });
                }
              })
              .catch(e => {
                this.setState({
                  isFetching: false,
                  error: e.message,
                  isLoadingMore: false,
                });
              });
          }
        );
      }
    };

    refresh() {
      const { current_page, filter, per_page, sorting } = this.state;
      this.loadRecords(current_page || 1, filter, per_page, sorting);
    }

    service: Object;

    render() {
      const {
        data,
        total,
        per_page,
        current_page,
        sorting,
        filter,
        isFetching,
        loadMoreEnabled,
        isLoadingMore,
        error,
      } = this.state;
      return (
        <ListComponent
          {...this.props}
          data={data}
          totalCount={total}
          pageSize={per_page}
          currentPage={current_page}
          loadMoreEnabled={loadMoreEnabled}
          isLoadingMore={isLoadingMore}
          sorting={sorting}
          filter={filter}
          isFetching={isFetching}
          error={error}
          loadRecords={this.loadRecords}
          reorderRecord={this.reorderRecord}
        />
      );
    }
  }

  return WrappedComponent;
};

export default ListDataProvider;
/* eslint-enable camelcase */
